import { createSelector } from 'reselect';
import { getBptPreferenceAnalysisAdRecallDataSelector } from '../../../data/selectors';
import { VALUE_TYPES, AXA_AND_AXABRANDS_ID } from '../../utils/utils';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';

export const getTopFiveAddRecallLOBsIDsComputedSelector = createSelector(
    [getBptPreferenceAnalysisAdRecallDataSelector],
    data => {
        if (data && data.hasOwnProperty('sections') && data.sections && data.sections.length > 0) {
            const source = data.sections[0].kpis;

            const sourceLOBs = source.find(e => e.kpiType === KPI_TYPES.COMBINED);
            const sourceRanking = source.find(e => e.kpiType === KPI_TYPES.RANKING);

            if (sourceLOBs && sourceRanking) {
                const LOBItems = sourceLOBs.items;
                const rankingItems = sourceRanking.items[0].values;
                const axaOrAxaBrandsLabel = LOBItems.filter(el => el.itemId === AXA_AND_AXABRANDS_ID)[0].itemLabel;
                /* Sort ranking */
                const topFive = rankingItems
                    .sort((a, b) => (a.type === VALUE_TYPES.TYPE_AVERAGE ? 1 : a.order - b.order))
                    .slice(0, 5)
                    .map(e => e.label);

                /* Include AXA within the top 5 if not present */
                if (!topFive.includes(axaOrAxaBrandsLabel)) {
                    topFive.push(axaOrAxaBrandsLabel);
                }

                /* Compute intersection between the top five names and their respective itemIds found under LOBOptions */
                return LOBItems.reduce((acc, curr) => {
                    if (topFive.includes(curr.itemLabel)) {
                        acc.push(curr.itemId);
                    }
                    return acc;
                }, []);
            }
        }
        return [];
    }
);
