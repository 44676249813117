import styled from 'styled-components';

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: 20px;
`;
export const RankTableWrapper = styled.div`
    display: flex;
    max-width: 450px;
    flex-direction: column;
    align-items: center;
`;

export const RankingFooter = styled.div`
    width: 100%;
    font-size: 13px;
    float: right;
    padding-top: 15px;
    color: #243b5f;
`;
export const NoDataAdRecall = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 700;
    padding: 4em 0 0 0;
    text-align: center;
`;

export const customStyleLOBLegend = {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    minHeight: '130px',
};
