import React from 'react';
import * as SC from './CountryGroup.styles';
import CombinedBarLineChart from '../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import { basePath } from '../../../../services/utils';
import { MemoizedRankingTable as RankingTable } from '../../../brandPreferenceTracker/subpages/PreferenceAnalysis/components/RankingTable/RankingTable';
import CountryTrend from '../CountryTrend/CountryTrend';
import PropTypes from 'prop-types';
import { DISPLAY_MODE } from '../../utils/utils';
import Tooltip from '../../../brandPreferenceTracker/subpages/PreferenceAnalysis/components/Tooltip/Tooltip';
import { deepEqual } from '../../../../utils/generalUtilities';
import Legend from '../../../../common/components/Legend/Legend';
import { defaultTheme } from '../../../../utils/defaultTheme';

const generateGridPositioning = (row, column) => ({
    gridRowStart: row.start,
    gridRowEnd: row.end,
    gridColumnStart: column.start,
    gridColumnEnd: column.end,
});

/* Fixed sizes for the bar charts wrappers. */
export const COUNTRY_GROUP_OVERALL_YEAR_SIZE = 146;
export const COUNTRY_GROUP_OVERALL_QUARTER_SIZE = 318;
export const COUNTRY_GROUP_OVERALL_ADRECALL_SIZE = 470;

function CountryGroup({
    overallYearData,
    overallQuarterData,
    rankingData,
    trendData,
    countryData,
    displayMode,
    hasLegend,
    sectionLabel,
    overallAdRecall,
}) {
    const generateLegendItems = () => {
        if (hasLegend) {
            const mainBrand = overallYearData.data;
            const marketAverage = overallYearData.lineData;
            return [
                {
                    name: mainBrand.name,
                    color: mainBrand.values[0].color,
                },
                {
                    name: marketAverage.name,
                    color: marketAverage.color,
                },
            ];
        }
        return [];
    };

    const generateLOBs = () => {
        if (sectionLabel === 'AdRecall') {
            overallAdRecall.lineData.map(function (item) {
                return item.name;
            });
        }
    };
    return (
        <SC.CountryGroupWrapper displayMode={displayMode}>
            <SC.CountryHeader style={{ ...generateGridPositioning({ start: 1, end: 1 }, { start: 1, end: 3 }) }}>
                <img src={countryData.countryFlag || `${basePath}/visuals/No_flag-image.svg`} alt={'Country Flag'} />
                <span style={{ whiteSpace: 'pre' }}>{countryData.countryName}</span>
            </SC.CountryHeader>

            {/* Display the CountryTrend as grid item in case displayMode is of type CHARTS or ALL */}
            {displayMode !== DISPLAY_MODE.RANKINGS && (
                <div
                    style={{
                        ...generateGridPositioning({ start: 1, end: 1 }, { start: 2, end: 3 }),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CountryTrend trendValue={trendData.trendValue} title={trendData.trendText} />
                </div>
            )}

            <div
                data-testid={'CountryGroup-Ranking'}
                style={{
                    ...generateGridPositioning({ start: 1, end: 3 }, { start: 3, end: 3 }),
                    display: displayMode === DISPLAY_MODE.CHARTS && 'none',
                }}
            >
                <SC.RankingTableStyleOverride>
                    {/* Display the CountryTrend as child item in case displayMode is of type RANKING */}
                    {displayMode === DISPLAY_MODE.RANKINGS && (
                        <CountryTrend
                            customStyle={{ position: 'absolute', top: 8, left: '45%', transform: 'translate(-45%,0)' }}
                            trendValue={trendData.trendValue}
                            title={trendData.trendText}
                        />
                    )}
                    <RankingTable
                        compactMode={true}
                        rankingData={[rankingData]}
                        lobOptions={null}
                        customTitleStyle={{ justifyContent: 'flex-end' }}
                        styleObject={{
                            width: '100% !important',
                            position: 'relative',
                            top: '12px',
                        }}
                    />
                </SC.RankingTableStyleOverride>
            </div>
            {sectionLabel !== 'AdRecall' ? (
                <>
                    <div
                        data-testid={'CountryGroup-OverallYear'}
                        style={{
                            ...generateGridPositioning({ start: 2, end: 2 }, { start: 1, end: 1 }),
                            position: 'relative',
                            display: displayMode === DISPLAY_MODE.RANKINGS && 'none',
                            flexGrow: '1',
                        }}
                    >
                        <SC.BarChartStyleOverride>
                            <CombinedBarLineChart
                                customBarPadding={0.4}
                                bypassLOBFiltering={true}
                                hideTitleOnSigTestHover={true}
                                customHeight={178}
                                fluidWidth={true}
                                customDomain={105}
                                customWidthOnPage={() => COUNTRY_GROUP_OVERALL_YEAR_SIZE}
                                lineData={overallYearData.lineData ? [overallYearData.lineData] : []}
                                hideLeftLabel={true}
                                hideGridLines={true}
                                showSigTest={true}
                                titleObject={{
                                    customHTML: () => (
                                        <Tooltip tooltipText={overallYearData.kpiLabel}>
                                            <SC.OverallYearTitle label={'Boss'} className={'transversal-chart-title'}>
                                                {overallYearData.kpiLabel.length > 24
                                                    ? overallYearData.kpiLabel.slice(0, 18) + '...'
                                                    : overallYearData.kpiLabel}
                                            </SC.OverallYearTitle>
                                        </Tooltip>
                                    ),
                                    wrapperCustomStyle: {
                                        position: 'absolute',
                                        top: '5px',
                                        left: '50%',
                                        margin: 0,
                                        width: COUNTRY_GROUP_OVERALL_YEAR_SIZE + 'px',
                                        textAlign: 'center',
                                        transform: 'translate(-50%, 0)',
                                    },
                                }}
                                barData={overallYearData.data}
                            />
                            {hasLegend && (
                                <div data-testid={'CountryGroup-Legend'}>
                                    <Legend
                                        customStyle={{
                                            _wrapper: { marginLeft: '20px', width: '320px' },
                                            _individualItem: { marginRight: '20px', color: defaultTheme.blue[950] },
                                        }}
                                        displayDirection={'row'}
                                        legendItems={generateLegendItems()}
                                    />
                                </div>
                            )}
                        </SC.BarChartStyleOverride>
                    </div>
                    <div
                        data-testid={'CountryGroup-OverallQuarter'}
                        style={{
                            ...generateGridPositioning({ start: 2, end: 2 }, { start: 2, end: 2 }),
                            position: 'relative',
                            display: displayMode === DISPLAY_MODE.RANKINGS && 'none',
                            flexGrow: '1',
                        }}
                    >
                        <CombinedBarLineChart
                            customBarPadding={0.3}
                            bypassLOBFiltering={true}
                            customHeight={178}
                            fluidWidth={true}
                            hideTitleOnSigTestHover={true}
                            customDomain={105}
                            customWidthOnPage={() => COUNTRY_GROUP_OVERALL_QUARTER_SIZE}
                            lineData={overallQuarterData.lineData ? [overallQuarterData.lineData] : []}
                            hideLeftLabel={true}
                            hideGridLines={true}
                            showSigTest={true}
                            titleObject={{
                                customHTML: () => (
                                    <SC.OverallYearTitle className={'transversal-chart-title'}>
                                        {' '}
                                        {overallQuarterData.kpiLabel}{' '}
                                    </SC.OverallYearTitle>
                                ),
                                wrapperCustomStyle: {
                                    position: 'absolute',
                                    top: '5px',
                                    left: '50%',
                                    width: COUNTRY_GROUP_OVERALL_QUARTER_SIZE + 'px',
                                    margin: 0,
                                    transform: 'translate(-50%, 0)',
                                    textAlign: 'center',
                                },
                            }}
                            barData={overallQuarterData.data}
                        />
                    </div>
                </>
            ) : (
                <div
                    data-testid="Adrecall-Section"
                    style={{
                        ...generateGridPositioning({ start: 2, end: 2 }, { start: 1, end: 1 }),
                        position: 'relative',
                        display: displayMode === DISPLAY_MODE.RANKINGS && 'none',
                        flexGrow: '1',
                        gridColumn: '1 / span 2',
                    }}
                >
                    <CombinedBarLineChart
                        visibleLOBs={generateLOBs()}
                        customBarPadding={0.3}
                        bypassLOBFiltering={true}
                        customHeight={450}
                        fluidWidth={true}
                        hideTitleOnSigTestHover={true}
                        customWidthOnPage={() => COUNTRY_GROUP_OVERALL_ADRECALL_SIZE}
                        lineData={overallAdRecall.lineData}
                        hideLeftLabel={true}
                        hideGridLines={true}
                        showSigTest={true}
                        showBrandName={true}
                        titleObject={{
                            customHTML: () => (
                                <SC.OverallYearTitle className={'transversal-chart-title'}>
                                    {' '}
                                    {overallAdRecall.kpiLabel}{' '}
                                </SC.OverallYearTitle>
                            ),
                            wrapperCustomStyle: {
                                position: 'absolute',
                                top: '5px',
                                left: '50%',
                                width: COUNTRY_GROUP_OVERALL_ADRECALL_SIZE + 'px',
                                margin: 0,
                                transform: 'translate(-50%, 0)',
                                textAlign: 'center',
                            },
                        }}
                        barData={overallAdRecall.data}
                    />
                </div>
            )}
        </SC.CountryGroupWrapper>
    );
}

CountryGroup.propTypes = {
    displayMode: PropTypes.oneOf([DISPLAY_MODE.RANKINGS, DISPLAY_MODE.ALL, DISPLAY_MODE.CHARTS]),
    overallYearData: PropTypes.object.isRequired,
    overallQuarterData: PropTypes.object.isRequired,
    rankingData: PropTypes.object.isRequired,
    trendData: PropTypes.object.isRequired,
    countryData: PropTypes.object.isRequired,
    hasLegend: PropTypes.bool,
};

export default React.memo(CountryGroup, (prevProps, nextProps) => {
    return (
        prevProps.displayMode === nextProps.displayMode &&
        deepEqual(prevProps.overallYearData, nextProps.overallYearData) &&
        deepEqual(prevProps.overallQuarterData, nextProps.overallQuarterData) &&
        deepEqual(prevProps.rankingData, nextProps.rankingData) &&
        deepEqual(prevProps.trendData && nextProps.trendData) &&
        deepEqual(prevProps.countryData, nextProps.countryData) &&
        prevProps.hasLegend === nextProps.hasLegend
    );
});
