import { TRANSLATION_TEXT } from '../../../utils/translations';

export const SECTIONS_IDS = {};

export const DISPLAY_MODE = {
    ALL: 'All',
    CHARTS: 'Charts',
    RANKINGS: 'Rankings',
};
export const DISPLAY_TOGGLE_BUTTONS = [
    {
        displayMode: DISPLAY_MODE.CHARTS,
        label: TRANSLATION_TEXT.DISPLAY_MODE_SHOW_CHARTS,
    },
    {
        displayMode: DISPLAY_MODE.ALL,
        label: TRANSLATION_TEXT.DISPLAY_MODE_SHOW_ALL,
    },
    {
        displayMode: DISPLAY_MODE.RANKINGS,
        label: TRANSLATION_TEXT.DISPLAY_MODE_SHOW_RANKINGS,
    },
];
