import { asyncActionCreator } from '../../../utils/generalUtilities';

/* User authentication specific actions */
export const LOGIN_INIT = '/pages/login/LOGIN_INIT';
export const LOGIN_ACTION_GROUP = asyncActionCreator('/pages/login/LOGIN');
export const REFRESH_TOKEN_ASYNC_GROUP = asyncActionCreator('/pages/login/REFRESH_TOKEN');
export const LOGOUT = '/pages/login/LOGOUT';
export const SET_LOGGED_USER_DETAILS = '/pages/login/SET_LOGGED_USER';
export const SET_TOKEN = '/pages/login/SET_TOKEN';

export const FORGOT_PASSWORD = '/pages/login/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ASYNC = asyncActionCreator('/pages/login/FORGOT_PASSWORD');
export const CHANGE_LOGIN_PAGE = '/pages/login/CHANGE_LOGIN_PAGE';
export const SET_EMAIL_RECIPIENT = '/pages/login/SET_EMAIL_RECIPIENT';
export const RESET_PASSWORD = '/pages/login/RESET_PASSWORD';
export const RESET_PASSWORD_ASYNC = asyncActionCreator('/pages/login/RESET_PASSWORD');
export const IS_RESET_TOKEN_VALID = '/pages/login/IS_RESET_TOKEN_VALID';
export const IS_RESET_TOKEN_VALID_ASYNC = asyncActionCreator('/pages/login/IS_RESET_TOKEN_VALID');

export const HAS_ON_LOGIN_ERROR = '/pages/login/HAS_ON_LOGIN_ERROR';

export const loginInitActionCreator = userDetails => ({
    type: LOGIN_INIT,
    payload: userDetails,
});
export const logoutActionCreator = () => ({
    type: LOGOUT,
});
export const setLoggedUserDetailsActionCreator = userDetails => ({
    type: SET_LOGGED_USER_DETAILS,
    payload: userDetails,
});
export const setTokenActionCreator = tokensObject => ({
    type: SET_TOKEN,
    payload: tokensObject,
});

export const hasOnLoginErrorActionCreator = loginError => ({
    type: HAS_ON_LOGIN_ERROR,
    payload: loginError,
});

export const forgotPasswordActionCreator = email => ({
    type: FORGOT_PASSWORD,
    payload: email,
});

export const changeLoginPageActionCreator = page => ({
    type: CHANGE_LOGIN_PAGE,
    payload: page,
});

export const setEmailRecipientActionCreator = email => ({
    type: SET_EMAIL_RECIPIENT,
    payload: email,
});

export const resetPasswordActionCreator = payload => ({
    type: RESET_PASSWORD,
    payload, //{token, password}
});

export const isResetTokenValidActionCreator = token => ({
    type: IS_RESET_TOKEN_VALID,
    payload: token,
});
