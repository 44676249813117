// Save to localStorage under different keys based on current environment
import { LOGIN_ACTION_GROUP, SET_LOGGED_USER_DETAILS, SET_TOKEN } from '../pages/login/data/actions';
import { STORE_APPLY_FILTERS, STORE_TOGGLE_SIDE_FILTERS_SECTION } from '../common/components/SideFilters/data/actions';
import {
    CACHE_LAST_APPLIED_TOP_FILTERS_OPTION,
    SET_SELECTED_TOP_FILTERS,
    SET_SELECTED_TOP_OPTION,
} from '../common/components/TopFilter/data/actions';
import { GLOBAL_OVERVIEW_COUNTRIES_SAVE } from '../common/data/actions';
import { SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY } from '../pages/adTester/subpages/Overview/data/actions';

export let TOKEN_STORAGE_KEY = 'AXA';
switch (process.env.REACT_APP_ENV) {
    case 'local':
        TOKEN_STORAGE_KEY += '_local';
        break;
    case 'qa':
        TOKEN_STORAGE_KEY += '_qa';
        break;
    case 'prod':
        TOKEN_STORAGE_KEY += '_prod';
        break;
    case 'dev':
        TOKEN_STORAGE_KEY += '_dev';
        break;
    default:
        break;
}

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(TOKEN_STORAGE_KEY + '_state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(TOKEN_STORAGE_KEY + '_state', serializedState);
    } catch (error) {}
};

export const persistState = store => {
    const lastAction = store.lastAction;
    const localStorageState = loadState();

    let updatedStorage = {};
    switch (lastAction.type) {
        case LOGIN_ACTION_GROUP.actions.SUCCEEDED:
            updatedStorage = { ...localStorageState, tokens: lastAction.payload };
            break;
        case SET_LOGGED_USER_DETAILS:
            updatedStorage = { ...localStorageState, userInformation: lastAction.payload };
            break;
        case STORE_APPLY_FILTERS:
            updatedStorage = {
                ...localStorageState,
                sideFilters: { ...localStorageState.sideFilters, ...lastAction.payload },
            };
            break;
        case STORE_TOGGLE_SIDE_FILTERS_SECTION:
            updatedStorage = {
                ...localStorageState,
                sideFiltersSectionUi: { ...localStorageState.sideFiltersSectionUi, ...lastAction.payload },
            };
            break;
        case SET_SELECTED_TOP_FILTERS: {
            const { key, value } = lastAction.payload;
            updatedStorage = {
                ...localStorageState,
                selectedTopFilters: { ...localStorageState?.selectedTopFilters, [key]: value },
            };
            break;
        }
        case CACHE_LAST_APPLIED_TOP_FILTERS_OPTION: {
            updatedStorage = {
                ...localStorageState,
                lastAppliedTopFilters: { ...localStorageState?.lastAppliedTopFilters, ...lastAction.payload },
            };
            break;
        }
        case SET_SELECTED_TOP_OPTION: {
            const { key, value } = lastAction.payload;
            updatedStorage = {
                ...localStorageState,
                selectedTopOption: { ...localStorageState?.selectedTopOption, [key]: value },
            };
            break;
        }
        case SET_TOKEN:
            updatedStorage = { ...localStorageState, tokens: lastAction.payload };
            break;
        case GLOBAL_OVERVIEW_COUNTRIES_SAVE:
            updatedStorage = { ...localStorageState, globalOverviewSelectedCountries: lastAction.payload };
            break;
        case SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY:
            updatedStorage = { ...localStorageState, adTesterOverviewSelectedCountry: lastAction.payload };
            break;
        default:
            break;
    }

    if (Object.keys(updatedStorage).length !== 0) {
        saveState(updatedStorage);
    }
};

export default persistState;
