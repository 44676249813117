import { connect } from 'react-redux';
import ConsiderationTab from './Consideration.tab';
import { getBptPreferenceAnalysisConsiderationActionCreator } from '../data/actions';
import { getBptPreferenceAnalysisConsiderationSelector } from '../../../data/selectors';
import {
    getBptPreferenceAnalysisDataComputed,
    getCombinedChartDataSelector,
    getLOBAsOptionsComputedSelector,
    getRankingTableDataSelectorComputed,
    getSimpleBarChartDataComputed,
} from '../../../data/computedSelectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../../../../common/components/TopFilter/data/computedSelectors';
import {
    geLineChartDataSelector,
    getLineChartLegendComputed,
    getLineChartSubSectionsComputed,
} from '../data/computedSelectors';
import { getFiltersThrownError } from '../../../../../../../common/data/selectors';
import { getSelectedFiltersComputedSelector } from './../../../../../../../common/components/SelectedFilters/data/computedSelectors';

const mapStateToProps = state => ({
    considerationData: getBptPreferenceAnalysisConsiderationSelector(state),
    rankingTableData: getRankingTableDataSelectorComputed(state),
    combinedChartsData: getCombinedChartDataSelector(state),
    simpleBarChartData: getSimpleBarChartDataComputed(state),
    lineChartData: geLineChartDataSelector(state),
    lobsAsOptions: getLOBAsOptionsComputedSelector(state),
    lineChartSubSections: getLineChartSubSectionsComputed(state),
    lineChartLegend: getLineChartLegendComputed(state),
    considerationSectionIDs: getBptPreferenceAnalysisDataComputed(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
});

const mapDispatchToProps = {
    getBptPreferenceAnalysisConsiderationActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsiderationTab);
