import styled, { keyframes } from 'styled-components';

const moveLines = (minW, maxW) => keyframes`
    0%,5% {
     width:${minW}px;
	}
    10%,20% {
     width:${minW - 7}px;
	}
    30%,100%{
     width:${maxW}px;
	}
`;
const moveDots = (minW, maxW, bounceBack) => keyframes`
    0%,10%{
     width:6px;
	}
    15%,20%{
     width:${bounceBack >= 5 && bounceBack}px;
	}
    30%,40% {
     width:${minW}px;
	}
    45%,50%{
      width:${minW - bounceBack}px;
    }
    60%,100%{
     width:${maxW}px;
	}
`;
const moveMonitor = keyframes`
    0%,5%{
        transform:translate(-100px,250px) rotate(-30deg);
    }
    10%{
        transform:translate(1px,-4px) rotate(0deg);
    }
    20%,80%{
        transform:translate(0,0px) rotate(0deg);
    }
    90%,100%{
        transform:translate(-100px,250px) rotate(-30deg);
    }
 `;
const hideLines = keyframes`
    0%,18%{
        opacity:0;
    }
    25%,100%{
        opacity:1;
    }
 `;
const rotateSpinner = keyframes`
    0%,5%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px); }
      10%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(190deg); }
      15%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(180deg); }
      20%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(10deg); }
  25%,35%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px); }
      40%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(190deg); }
      45%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(180deg); }
      50%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(10deg); } 
  55%,70%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px); }
      75%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(190deg); }
      80%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(180deg); }
      85%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px) rotate(10deg); }
      90%{ transform-origin: 6% 8%; transform:translate(113.5px, 106.147px); }
     100%{ transform-origin: 8% 8%; transform:translate(113.5px, 106.147px); }

 `;
export const SVG = styled.svg`
    position: absolute;
    top: 265px;
    .a,
    .b {
        fill: ${({ theme }) => theme.white.ff};
    }
    .b {
        stroke: ${({ theme }) => theme.blue[900]};
        stroke-width: 1.5px;
    }
    .c {
        clip-path: url(#a);
    }
    .d {
        fill: ${({ theme }) => theme.blue[900]};
    }
    .e {
        fill: ${({ theme }) => theme.blue[50]};
    }
    .f {
        clip-path: url(#e);
    }

    .firstRow {
        animation: ${moveLines(49, 89)} 3s 0.5s forwards ease-in-out infinite,
            ${hideLines} 3s forwards ease-in-out infinite;
        transform: translate(19px, 31px);
    }
    .secondRow {
        animation: ${moveLines(24, 89)} 3s 0.5s forwards ease-in-out infinite,
            ${hideLines} 3s forwards ease-in-out infinite;
        transform: translate(19px, 47px);
    }
    .thirdRow {
        animation: ${moveLines(33, 89)} 3s 0.5s forwards ease-in-out infinite,
            ${hideLines} 3s forwards ease-in-out infinite;
        transform: translate(19px, 63px);
    }
    .firstFilledRow {
        animation: ${moveDots(5, 24, 3)} 3s 0.5s ease-in-out infinite,
            ${moveDots(24, 89, 5)} 3s 0.5s ease-in-out infinite;
    }

    .secondFilledRow {
        animation: ${moveDots(6, 84, 3)} 3s 0.5s ease-in-out infinite;
    }
    .thirdFilledRow {
        animation: ${moveDots(6, 87, 2)} 3s 0.5s ease-in-out infinite;
    }
    .monitorContainer {
        animation: ${moveMonitor} 3s linear infinite;
    }

    .spinner {
        animation: ${rotateSpinner} 3s linear infinite;
    }
    .firstRow,
    .secondRow,
    .thirdRow {
        fill: ${({ theme }) => theme.blue[50]};
    }
    .filledRows {
        fill: ${({ theme }) => theme.blue[900]};
        animation: ${hideLines} 3s forwards ease-in-out infinite;
    }
`;
