import React from 'react';
import { InputField }  from '../../InputField/InputField';
import {StyledInputFieldWrapper, StyledFileUploadLabel} from '../FileUpload.styles'; 

export const InputFileUpload = props => {

    const { placeholder, file, fileInputRef, inputChangeHandler, browseText, width, inputFieldCustomStyle, inputFieldLabelCustomStyle,theme } = props;
    const customStyleOnFocus = {
      width: 'calc(100% - 85px)',
      borderRadius: '3px 0 0 3px',
      ...inputFieldCustomStyle
    };
    
    return (
      <StyledInputFieldWrapper width={width}>
        <InputField
          placeholder={placeholder}
          value={file}
          onChange={inputChangeHandler}
          readOnly={true}
          onClick={()=>{fileInputRef.current.click();}}
          customStyle={customStyleOnFocus}
        />
        <StyledFileUploadLabel
          inputFieldLabelCustomStyle={inputFieldLabelCustomStyle}
          theme={theme}
          onClick={()=>{fileInputRef.current.click();}}
        >
          {browseText}
        </StyledFileUploadLabel>
      </StyledInputFieldWrapper>
    );
};