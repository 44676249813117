import React from 'react';
import styled from 'styled-components';
import { METRICS_VALUE_COLORS_MAP, numFormatterExceptZero } from '../../tabs/utils/utils';
import PropTypes from 'prop-types';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';

const TOP_OFFSET = 40;

const Wrapper = styled.div`
    position: relative;
`;

const Badge = styled.div`
    position: absolute;
    left: ${({ barCoords, barTranslate }) => barCoords && barCoords.coords?.x + barTranslate}px;
    top: -${({ barCoords, totalBarChartHeight }) => barCoords && totalBarChartHeight - barCoords.coords?.y - 5 + TOP_OFFSET}px;
    width: ${({ barCoords }) => barCoords && barCoords.coords.width + 5}px;
    display: flex;
    justify-content: center;
    min-width: 40px;
    border: solid 1px ${({ colorScheme }) => colorScheme.border};
    background: ${({ colorScheme }) => colorScheme.background};
    border-radius: 50px;
    color: ${({ colorScheme }) => colorScheme.textColor};
    font-weight: 600;
    font-size: 12px;
    padding: 2px 1px 2px 1px;
`;

const MetricBadge = ({ data, barCoords, barTranslate, totalBarChartHeight }) => {
    return (
        <Wrapper>
            {data.map((el, idx) => {
                return Number.isFinite(el?.evolutionMetricValue) ? (
                    <Badge
                        key={el?.name + '-' + idx}
                        data-testid={`Metric-Badge-Content-Simple-Chart`}
                        barCoords={barCoords.find(c => c.id === idx)}
                        totalBarChartHeight={totalBarChartHeight}
                        barTranslate={barTranslate}
                        colorScheme={METRICS_VALUE_COLORS_MAP[Math.sign(el.evolutionMetricValue)]}
                    >
                        {numFormatterExceptZero(el.evolutionMetricValue) +
                            ' ' +
                            TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL}
                    </Badge>
                ) : null;
            })}
        </Wrapper>
    );
};

const DataShape = PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    sigtest: PropTypes.number.isRequired,
    tooltip: PropTypes.string,
    evolutionMetricValue: PropTypes.number,
});
const CoordsShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    coords: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
});

MetricBadge.propTypes = {
    data: PropTypes.arrayOf(DataShape),
    barCoords: PropTypes.arrayOf(CoordsShape),
    totalBarChartHeight: PropTypes.number.isRequired,
    barTranslate: PropTypes.number,
};

export default MetricBadge;
