import { connect } from 'react-redux';
import TransversalView from './TransversalView';
import { getTransversalViewActionCreator } from './data/actions';
import { getPageHasOptionLoadedComputedSelector } from '../../../../common/components/TopFilter/data/computedSelectors';
import {
    getFiltersThrownError,
    getPageRelatedToFiltersSelector,
    getTopFiltersRawData,
} from '../../../../common/data/selectors';
import {
    getTransversalViewDataComputedSelector,
    getTransversalViewInfoTextComputedSelector,
} from './data/computedSelectors';
import { getIsGlobalLoadingSelector } from '../../../../common/components/Loader/data/selectors'; //that import will be use to formating data in computed selector.
import { getKPYSTransversalDataSelector } from './data/selectors';
import { OnTransversalSectionToggleActionCreator } from './data/actions';

const mapStateToProps = state => ({
    infoText: getTransversalViewInfoTextComputedSelector(state),
    data: getTransversalViewDataComputedSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    topFiltersRequestSuccessful: getTopFiltersRawData(state).length > 0,
    isGlobalLoading: getIsGlobalLoadingSelector(state),
    transversalViewCheckedSections: getKPYSTransversalDataSelector(state),
    filtersFor: getPageRelatedToFiltersSelector(state),
});

const mapDispatchToProps = {
    getData: getTransversalViewActionCreator,
    onTransversalSectionToggle: OnTransversalSectionToggleActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransversalView);
