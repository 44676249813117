import { connect } from 'react-redux';
import AdDetailsMetrics from './AdDetailsMetrics';
import { getAdDetailsAndMetricsDataActionCreator } from './data/actions';
import { getLineChartsDataSelector, getMetricsDataSectionsSelector } from './data/selectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../common/components/TopFilter/data/computedSelectors';
import { getSideFiltersSelector } from '../../../../common/components/SideFilters/data/selectors';
import { getFiltersThrownError, getPageRelatedToFiltersSelector } from '../../../../common/data/selectors';
import { getUpliftsComputedDataSelector } from '../../data/computedSelectors';

const mapStateToProps = state => ({
    lineChartsData: getLineChartsDataSelector(state),
    upliftsData: getUpliftsComputedDataSelector(state),
    metricsData: getMetricsDataSectionsSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    hasSideFiltersLoaded: Object.keys(getSideFiltersSelector(state)).length > 0,
    filtersThrownError: getFiltersThrownError(state),
    filtersFor: getPageRelatedToFiltersSelector(state),
});

const mapDispatchToProps = {
    getAdDetailsAndMetricsDataActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdDetailsMetrics);
