import styled from 'styled-components';

export const SubSectionMainTitle = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 700;
    margin-bottom: 20px;
    strong {
        background-color: ${({ theme }) => theme.blue[50]};
        padding: 2px 4px;
        border-radius: 5px;
    }
    p {
        font-weight: 500;
        @media (max-width: 1370px) {
            width: 620px;
        }
    }
`;

export const InnerLineChartWrapper = styled.div``;

export const SubSectionDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.grey[200]};
    margin-bottom: 60px;
    margin-top: 50px;
`;
