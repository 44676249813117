import styled from '@emotion/styled';

export const StyledRadioButton = styled.div`
    height: ${props => (props.vertical ? 'fit-content' : '18px')};
    display: flex;
    flex-direction: ${props => (props.vertical ? 'column' : 'row')};
    align-items: center;
    ${props => (props.customStyle && props.customStyle.wrapper ? props.customStyle.wrapper : '')}
`;

export const InputRadioButton = styled.input`
    visibility: hidden;
    margin: 0;
    position: absolute;
`;

export const StyledIcon = styled.span`
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
    font-size: 7px;
    color: ${props => (props.checked ? 'white' : 'transparent')};
    background-color: ${props => (props.checked ? props.theme.lightColor : '#f1f2f7')};
    border: 1px solid ${props => (props.checked ? props.theme.lightColor : '#c8c8c8')};
    border-radius: 50%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.checked || props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
    :hover {
        ${props => (props.checked ? `` : `border: 1px solid #b8b8b8`)};
    }
    ${props => (props.enableKeyboardNavigation ? getKeyboardNavigationStyling(props.checked) : '')}
    ${props => (props.customStyle && props.customStyle.icon ? props.customStyle.icon : '')}
`;

const getKeyboardNavigationStyling = checked => {
    if (checked) {
        return `
            outline: none;
            box-shadow: 0 0 0 9px #D9E1F0;
            border-radius: 50%;
        `;
    } else {
        return `
            outline: none;
            box-shadow: 0 0 0 9px #e7e7e7;
            border-radius: 50%;
        `;
    }
};

export const TextLabel = styled.label`
    display: inline-flex;
    height: 100%;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    font-family: 'MuseoSans-300';
    color: #8292a8;
    margin-bottom: 0;
    margin-left: ${props => (props.vertical ? '0px' : '5px')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
    ${props => (props.customStyle && props.customStyle.label ? props.customStyle.label : '')}
`;
