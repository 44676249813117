import styled, { css } from 'styled-components';
import { SECTION_BREAK_WIDTH } from '../../utils/utils';

export const SectionInfo = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 600;
    margin: 30px 0;
`;

export const ChartTitle = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 600;
    padding-bottom: 10px;
`;

export const SectionRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${SECTION_BREAK_WIDTH}px) {
        flex-direction: column-reverse;
    }
`;

export const SectionItems = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 15px;

    @media (max-width: 1250px) {
        gap: 0;
    }
    ${({ nonResponsiveSection }) =>
        nonResponsiveSection &&
        css`
    margin-right:1%;
    ${SectionSingularItem}{
            overflow-x:auto;
        }
    @media (max-width: 1600px) {
        max-width:1285px;
    `}
`;

export const MediaMixGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const SectionSingularItem = styled.div``;
