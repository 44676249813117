import { all, put, takeLatest, select } from 'redux-saga/effects';
import {
    CREATE_TOP_FILTER_DEPENDENCY_ARRAY,
    createTopFilterDependencyArrayActionCreator,
    processTopFiltersEnded,
    processTopFiltersStarted,
    setSelectedTopFiltersActionCreator,
    setSelectedTopOptionActionCreator,
    setTemporarySelectedTopOptionActionCreator,
    setTopFilterDependencyArrayActionCreator,
    TOP_FILTER_ON_SELECT_ALL,
    TOP_FILTERS_ON_CHANGE,
    topFiltersOnChangeActionCreator,
    cacheLastAppliedTopFilterOptionActionCreator,
    INIT_CACHE_LAST_APPLIED_TOP_FILTERS_OPTION,
    initCacheLastAppliedTopFilterOptionActionCreator,
    TRIGGER_FILTER_ROLLBACK_FROM_CACHE,
    triggerFilterRollbackFromCacheActionCreator,
} from './actions';
import { loadState } from '../../../../services/localStorage.service';
import { arraysEqual, getPropertyValueOrDefault } from '../../../../utils/generalUtilities';
import { getTopFiltersSelector } from '../../SideFilters/data/selectors';
import { getUserDetailsSelector } from '../../../../pages/login/data/selectors';
import {
    areSelectedOptionsValidAsBackEndParametersComputedSelector,
    getCachedSelectedFiltersDataComputedSelector,
    getComputedSelectedTopFiltersFromPage,
    getComputedTopFiltersSelector,
} from './computedSelectors';
import {
    getLocationSelector,
    getTemporarySelectedTopOptionSelector,
    getTopFilterDependencyArraySelector,
    getCachedLastAppliedTopFiltersOptionSelector,
    getSelectedTopFiltersByPageSelector,
} from './selectors';
import { TOP_FILTERS_OPTION_BE_KEY } from '../utils/constants';
import { getParentPathOnPagesWithSubTabs } from '../utils/utils';
import { ADTESTER_PAGE, COMPETITIVE_MEDIA } from '../../../../utils/routes';
import { FILTERS_CONFIG } from '../../../utils/utils';

const removeDependentOptions = (filtersArray, dependencies, location = '') => {
    let filters = [...filtersArray];
    filters.forEach(option => {
        const dependency = dependencies[option.id];
        if (dependency) {
            let keepElement;

            switch (location) {
                case ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path:
                    if (option.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])) {
                        /* On the ADS Comparison page we have AND rule for each parent selected */
                        keepElement = dependency.every(dep => filters.some(f => f.id === dep));
                    } else {
                        keepElement = filters.some(e => dependency.includes(e.id));
                    }
                    break;
                case COMPETITIVE_MEDIA.path:
                    break;
                default:
                    keepElement = filters.some(e => dependency.includes(e.id));
                    break;
            }

            if (!keepElement) {
                filters = filters.filter(e => e.id !== option.id);
            }
        }
    });
    return filters;
};

function* initSelectedTopFiltersWithTemporaryOptionSaga(temporaryOptionForInitializing, topFilters, location) {
    let initialSelectedIDs = [];

    const findOptionIdByCountry = (options, optionCountry) => {
        const { id } = options.find(option => option.label.toLowerCase() === optionCountry.toLowerCase());
        return id;
    };

    topFilters.forEach(filterGroup => {
        const { group, selectType, options, id: groupId } = filterGroup;

        let id = null;

        switch (group.toLowerCase()) {
            case 'year': {
                id = temporaryOptionForInitializing.year_id;
                break;
            }
            case 'country': {
                id = findOptionIdByCountry(options, temporaryOptionForInitializing.country);
            }
        }

        if (id) {
            initialSelectedIDs.push({ id: id, group, selectType, groupId });
        }
    });

    yield put(setSelectedTopFiltersActionCreator({ key: location, value: initialSelectedIDs }));

    const { groupId, id, group, selectType } = temporaryOptionForInitializing;
    yield put(topFiltersOnChangeActionCreator({ groupId, id, group, selectType }));

    /* Reset temporary option */
    yield put(setTemporarySelectedTopOptionActionCreator(null));
}

function* initSelectedTopFiltersSaga(topFilters, location) {
    const { role } = yield select(getUserDetailsSelector);
    const initialSelectedIDs = [];

    const findOptionIdByCountry = (options, optionCountry) => {
        const { id } = options.find(option => option.label.toLowerCase() === optionCountry.toLowerCase());
        return id;
    };

    const findDefaultCountry = (options, partialCountry) => {
        const { label } = options.find(option => option.label.toLowerCase().includes(partialCountry.toLowerCase()));
        return label;
    };

    const yearOption = topFilters.filter(topFilter => topFilter.label.toLowerCase() === 'year')[0];
    const latestYearId = yearOption && yearOption.options.find(f => f.isLatest).id;

    topFilters.forEach(filterGroup => {
        const { group, selectType, options, id: groupId } = filterGroup;

        let id = null;

        switch (group.toLowerCase()) {
            case 'year': {
                id = latestYearId;
                break;
            }
            case 'country': {
                const countriesBasedOnYearOption = options.filter(option =>
                    option.parents.some(yearId => yearId === latestYearId)
                );
                const countryArr = yearOption ? countriesBasedOnYearOption : options;
                const defaultCountry = ['Admin', 'GlobalUser'].some(user => role.includes(user))
                    ? countryArr.some(country => country.label.includes('France'))
                        ? findDefaultCountry(options, 'France')
                        : findDefaultCountry(countryArr, countryArr[0].label)
                    : findDefaultCountry(countryArr, countryArr[0].label);
                id = findOptionIdByCountry(options, defaultCountry);
                break;
            }
            case 'zone': {
                /* Select first option from the zones */
                id = options[0].id;
            }
        }

        if (id) {
            initialSelectedIDs.push({ id: id, group, selectType, groupId });
        }
    });

    /* Perform dependency check on the initialSelectedIDs, check that for each element it's necessary parents are selected */
    let dependencyCheckedInitialOptions = [];
    initialSelectedIDs.forEach(data => {
        const { id, group } = data;
        const optionFromAllFilters = topFilters
            .find(element => element.group === group)
            .options.find(option => option.id === id);
        const parents = optionFromAllFilters.parents;
        if (parents.length === 0) {
            dependencyCheckedInitialOptions.push({ ...data });
        } else {
            const parentElementInList = initialSelectedIDs.find(el => parents.includes(el.id));
            if (parentElementInList) {
                dependencyCheckedInitialOptions.push({ ...data });
            }
        }
    });

    yield put(setSelectedTopFiltersActionCreator({ key: location, value: dependencyCheckedInitialOptions }));

    /* After applying the initial filters, choose which backend parameter option to be selected by default and add it again */
    const visibleFilters = yield select(getComputedTopFiltersSelector);

    const visibleFilterLastIndex = visibleFilters.length - 1;

    const { options, group, selectType } = visibleFilters[visibleFilterLastIndex];
    const groupId = visibleFilters[visibleFilterLastIndex].id;
    const { id } = options[0];

    switch (location) {
        case ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path:
            /* No default selection required on AdsComparison page */
            break;
        case COMPETITIVE_MEDIA.path:
            /* On CompetitiveMedia page the selectedOption represents the user's country */
            const groupKeyForBackEndParameter = TOP_FILTERS_OPTION_BE_KEY[location];
            yield setTopOptions(
                [{ ...dependencyCheckedInitialOptions[0] }],
                groupKeyForBackEndParameter,
                groupId,
                location
            );
            break;
        default:
            yield put(topFiltersOnChangeActionCreator({ groupId, id, group, selectType }));
            break;
    }
}

/*
    Initialize the filters on page load, refresh or filters request
 */
export function* initialTopFiltersSaga() {
    try {
        //Show loader because processing might take a while */
        yield put(processTopFiltersStarted());
        /* Create a dependency array between each filters group */
        yield put(createTopFilterDependencyArrayActionCreator());

        let location = yield select(getLocationSelector);
        location = getParentPathOnPagesWithSubTabs(location);
        const localStorage = loadState();
        const localStorageSelectedTopFilters = getPropertyValueOrDefault(localStorage, 'selectedTopFilters', {});
        const localStorageSelectedTopOption = getPropertyValueOrDefault(localStorage, 'selectedTopOption', {});
        /* If we have data stored in localStorage use that instead of recomputing everything */
        if (
            localStorageSelectedTopFilters.hasOwnProperty(location) ||
            localStorageSelectedTopOption.hasOwnProperty(location)
        ) {
            let cachedSelectedFilters = yield select(getCachedSelectedFiltersDataComputedSelector);
            let areSelectedOptionsValidAsBackendParameter = yield select(
                areSelectedOptionsValidAsBackEndParametersComputedSelector
            );
            /*
                If we have a cached version (in Redux Store) of the option that will be sent as BE parameters, use that to initialize, else gather from localStorage.
                Priority : Cache > LocalStorage
             */
            const lastAppliedFilters = localStorageSelectedTopFilters[location];
            const areLastAppliedFromCacheNotEqualWithCurrentSelection = !arraysEqual(
                lastAppliedFilters,
                cachedSelectedFilters.cachedFilters
            );

            /* If selected options are not valid or there's a difference from the cached version, use the cache, that is guaranteed to be valid */
            if (!areSelectedOptionsValidAsBackendParameter || areLastAppliedFromCacheNotEqualWithCurrentSelection) {
                yield put(triggerFilterRollbackFromCacheActionCreator());
            } else {
                yield put(
                    setSelectedTopFiltersActionCreator({
                        key: location,
                        value: localStorageSelectedTopFilters[location],
                    })
                );
                yield put(
                    setSelectedTopOptionActionCreator({ key: location, value: localStorageSelectedTopOption[location] })
                );
            }
            yield put(processTopFiltersEnded());
            yield put(initCacheLastAppliedTopFilterOptionActionCreator());
        } else {
            const topFilters = yield select(getTopFiltersSelector);
            const temporaryOptionForInitializing = yield select(getTemporarySelectedTopOptionSelector);

            /* If we have a specified option to initialize by use this one, otherwise perform automatic option discovery */
            if (temporaryOptionForInitializing) {
                yield initSelectedTopFiltersWithTemporaryOptionSaga(
                    temporaryOptionForInitializing,
                    topFilters,
                    location
                );
            } else {
                yield initSelectedTopFiltersSaga(topFilters, location);
            }
            yield put(processTopFiltersEnded());
            yield put(initCacheLastAppliedTopFilterOptionActionCreator());
        }
    } catch (error) {
        yield put(processTopFiltersEnded());
    }
}

export function* rollbackToPreviousMatchingFiltersSaga() {
    let location = yield select(getLocationSelector);
    location = getParentPathOnPagesWithSubTabs(location);

    let areSelectedOptionsValidAsBackendParameter = yield select(
        areSelectedOptionsValidAsBackEndParametersComputedSelector
    );

    const cache = yield select(getCachedSelectedFiltersDataComputedSelector);
    /* In some cases we need to force the cache regardless of whether options are valid or not */
    let forceCache = FILTERS_CONFIG[location]?.submitOnlyOnDisplay;

    if (
        (forceCache || !areSelectedOptionsValidAsBackendParameter) &&
        cache.cachedFilters &&
        cache.cachedOptions &&
        cache.cachedOptions?.length > 0
    ) {
        yield put(setSelectedTopFiltersActionCreator({ key: location, value: cache.cachedFilters }));
        yield put(setSelectedTopOptionActionCreator({ key: location, value: cache.cachedOptions }));
    }
}

function* createTopFilterDependencyArraySaga() {
    const topFilters = yield select(getTopFiltersSelector);
    /*
        Used for activation/deactivation of children in TopFilters
        A child is activated/visible if at least one of his parents are checked
     */
    const dependencyObject = {};
    topFilters.forEach(filterGroup => {
        filterGroup.options.forEach(option => {
            const { id, parents } = option;
            if (parents.length === 0) {
                dependencyObject[id] = null;
            } else {
                dependencyObject[id] = [...parents];
            }
        });
    });
    yield put(setTopFilterDependencyArrayActionCreator(dependencyObject));
}

function* setTopOptions(filters, groupKeyForBackEndParameter, groupId, location) {
    /* Extract the BE parameter from the selected top filters */
    const multiTopOptions = filters
        .filter(data => data.group.toLowerCase().includes(groupKeyForBackEndParameter))
        .map(data => ({ ...data, groupId }));

    yield put(setSelectedTopOptionActionCreator({ key: location, value: multiTopOptions || [] }));
}

function* onTopFilterCheckboxChangeSaga(action) {
    const selectedTopFilters = yield select(getComputedSelectedTopFiltersFromPage);
    const dependencyArray = yield select(getTopFilterDependencyArraySelector);
    const { id, group, selectType, groupId } = action.payload;

    let location = yield select(getLocationSelector);
    location = getParentPathOnPagesWithSubTabs(location);

    let updatedFilters = [];

    if (selectedTopFilters.some(e => e.id === id)) {
        /* Remove the option that we clicked */
        updatedFilters = selectedTopFilters.filter(e => e.id !== id);
    } else {
        /* In case of single select, remove previous element */
        if (selectType === 'single') {
            updatedFilters = selectedTopFilters.filter(e => e.group !== group);
            /* Add the new element */
            updatedFilters = [...updatedFilters, { id, group, selectType, groupId }];
        } else {
            /* In case of multi-just add it. We can have lots :D */
            updatedFilters = [...selectedTopFilters, { id, group, selectType, groupId }];
        }
    }
    /* Remove any subsequent options which are dependent on the option above */
    updatedFilters = removeDependentOptions(updatedFilters, dependencyArray, location);

    /* Required to check if the current group is of type copyName (which will be saved as an option to send to BE) */
    const groupKeyForBackEndParameter = TOP_FILTERS_OPTION_BE_KEY[location];
    /* Extract the BE parameter from the selected top filters */
    yield setTopOptions(updatedFilters, groupKeyForBackEndParameter, groupId, location);
    yield put(setSelectedTopFiltersActionCreator({ key: location, value: updatedFilters }));
}

function* onTopFilterSelectAllSaga(action) {
    const topFilters = yield select(getTopFiltersSelector);
    const dependencyArray = yield select(getTopFilterDependencyArraySelector);
    let location = yield select(getLocationSelector);
    location = getParentPathOnPagesWithSubTabs(location);

    const { isChecked, group } = action.payload;

    if (!isChecked) {
        yield put(setSelectedTopFiltersActionCreator({ key: location, value: [] }));
    } else {
        const filterGroup = topFilters.find(e => e.group === group);
        const ids = filterGroup.options.map(e => ({
            id: e.id,
            selectType: 'multi',
            group: group,
            groupId: filterGroup.id,
        }));

        let updatedFilters = [...ids];

        /* Remove any subsequent options which are dependent on the option above */
        updatedFilters = removeDependentOptions(updatedFilters, dependencyArray);

        yield put(setSelectedTopFiltersActionCreator({ key: location, value: updatedFilters }));
    }
}

function* cacheLastAppliedTopFiltersOptionSaga() {
    const topFilters = yield select(getSelectedTopFiltersByPageSelector);
    let location = yield select(getLocationSelector);
    location = getParentPathOnPagesWithSubTabs(location);
    const lastCachedFilters = yield select(getCachedLastAppliedTopFiltersOptionSelector);

    const newTopFilters = {
        ...lastCachedFilters,
        [location]: topFilters[location],
    };
    if (newTopFilters) {
        yield put(cacheLastAppliedTopFilterOptionActionCreator(newTopFilters));
    }
}

export default function* adTesterOverviewSaga() {
    yield all([
        yield takeLatest(TOP_FILTERS_ON_CHANGE, onTopFilterCheckboxChangeSaga),
        yield takeLatest(TOP_FILTER_ON_SELECT_ALL, onTopFilterSelectAllSaga),
        yield takeLatest(CREATE_TOP_FILTER_DEPENDENCY_ARRAY, createTopFilterDependencyArraySaga),
        yield takeLatest(INIT_CACHE_LAST_APPLIED_TOP_FILTERS_OPTION, cacheLastAppliedTopFiltersOptionSaga),
        yield takeLatest(TRIGGER_FILTER_ROLLBACK_FROM_CACHE, rollbackToPreviousMatchingFiltersSaga),
    ]);
}
