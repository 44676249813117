import React, { useRef, useEffect, useMemo } from 'react';
import * as SC from './CombinedStackedBarLineChart.styles';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useWindowSize } from '../../hooks/useWindowSize';
import { defaultTheme } from '../../../utils/defaultTheme';
import { createIdFromGroup } from '../../../utils/generalUtilities';
import { createClassName } from '../../utils/utils';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import {
    numFormatterExceptZero,
    METRICS_VALUE_COLORS_MAP,
} from '../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/utils/utils';

const DEFAULT_HEIGHT = 288;
const PADDING = {
    TOP: 34,
    LEFT: 40,
    RIGHT: 15,
    BOTTOM: 34,
};
const DIMENSIONS = {
    TOOLTIP: {
        width: 36,
        height: 23,
    },
    METRIC_BADGE: {
        width: 40,
        height: 21,
    },
    METRIC_BADGE_DOT: {
        width: 12,
        height: 12,
    },
};
const ELLIPSIS_SHOW_LENGTH = 20;
const AXIS_PADDING = 30;
const MIN_BAR_WIDTH_METRIC_BADGE = 23;

const CombinedStackedBarLineChart = ({
    lineData,
    barData,
    visibleLOBs,
    color,
    normalizedValues,
    bypassLOBFiltering,
    customWidthOnPage,
    customGridStyle,
    customLineTooltip,
    customHeight,
    alwaysOnLineTooltip,
    lineScalingOn,
    showBrandName,
    foreignObjects,
    hideGridLines,
    chartTitle,
    leftLabel,
    bottomLabel,
    customBarPadding,
    showValuesWhenNoSpace,
    computeLabelFormat,
    dataMaxDomain,
    customBarFont,
    customTicks,
    customMidValues,
    customPattern,
    badge,
    numberOfRespondents,
}) => {
    const [width] = useWindowSize();
    const WIDTH = useMemo(() => {
        if (typeof customWidthOnPage === 'function') return customWidthOnPage(width);
        return customWidthOnPage;
    }, [width]);

    const HEIGHT = customHeight ? customHeight : DEFAULT_HEIGHT;

    const chartRef = useRef(null);

    const isCustomValFunc = (customVal, actualVal, defaultVal) => {
        if (typeof customVal === 'function') return customVal(actualVal);

        if (customVal) return customVal;

        return defaultVal;
    };

    const labelFormatter = e => (computeLabelFormat ? computeLabelFormat(e) : e);

    const getSigTestTooltipReference = () => d3.select(chartRef.current).select('.sigTest-foreign-object'); //bar-

    const hideUnnecessaryTickStyle = (d3Element, selector) => {
        if (Array.isArray(d3Element)) {
            d3Element.forEach(el => el.selectAll(selector).attr('opacity', 0));
            return;
        }
        d3Element.selectAll(selector).attr('opacity', 0);
    };

    const calculateLabelPosition = (lowerBound, upperBound, scale) => {
        return scale(upperBound) + (scale(lowerBound) - scale(upperBound)) / 2;
    };

    const calculateMaxValue = (d, val) => (d.value === 0 ? 25 : d.value + d.value / val);

    const hideTextWhenNoSpaceAvailable = (text, d) => {
        if (showValuesWhenNoSpace) return;
        const svg = d3.select(chartRef.current);
        const TEXT_BOUNDS = 2;
        const textDimension = parseInt(window.getComputedStyle(text).fontSize, 10) + TEXT_BOUNDS;

        const stackSeries = svg.selectAll('.stacked-series-' + createClassName(d.data.key + d.data.name));

        let stackSeriesDimension = stackSeries?.node()?.getBBox();

        let shouldHideText = stackSeries && stackSeriesDimension.height < textDimension;
        if (shouldHideText) {
            svg.select('.stacked-series-text-' + createClassName(d.data.key + d.data.name)).attr('display', 'none');
            svg.select('.bg-text-' + createClassName(d.data.key + d.data.name)).attr('display', 'none');
        }
        return shouldHideText;
    };

    const addLineHoverEffect = d3Element => {
        const svg = d3.select(chartRef.current);
        if (alwaysOnLineTooltip) return;
        d3Element
            .on('mouseover', function (_, datum) {
                svg.selectAll('.data-line').style('opacity', 0.2);
                svg.selectAll('.data-line-' + createClassName(datum.name)).style('opacity', 1);
                svg.selectAll('.data-tooltip-' + createClassName(datum.name)).style('display', 'block');
                svg.selectAll('.data-brand-' + createClassName(datum.name)).style('visibility', 'visible');

                svg.selectAll('.data-circle.data-line-' + createClassName(datum.name)).attr('r', 5);
            })
            .on('mouseout', function (_, datum) {
                svg.selectAll('.data-line').style('opacity', 1);
                svg.selectAll('.data-tooltip-' + createClassName(datum.name)).style('display', 'none');
                svg.selectAll('.data-brand-' + createClassName(datum.name)).style('visibility', 'hidden');

                svg.selectAll('.data-circle').attr('r', 2);
            });
    };

    const calculateDomain = subGroups => {
        return d3.max(barData, d => subGroups.reduce((acc, curValue) => acc + d[curValue], 0)) * 1.2;
    };

    const drawSigTestTooltip = () => {
        d3.select(chartRef.current)
            .append('foreignObject')
            .style('display', 'none')
            .attr('class', 'sigTest-foreign-object') //bar-
            .on('mouseenter', () => {
                getSigTestTooltipReference().style('display', 'block');
            })
            .on('mouseout', () => {
                getSigTestTooltipReference().style('display', 'none');
            })
            .append('xhtml:div')
            .attr('class', 'sigTest-div') //bar-
            .style('text-align', 'center')
            .style('font-size', '10px')
            .style('padding', '10px 0')
            .style('word-break', 'break-word');
    };

    const getSigTestTooltipColor = value => {
        switch (value) {
            case -1:
                return defaultTheme.yellow[500];
            case 1:
                return defaultTheme.green[200];
            default:
                return null;
        }
    };

    const returnValueBasedOnSigTest = (d, fulfillValue, defaultValue) => {
        if (d.hasOwnProperty('sigtest')) {
            if (d.sigtest !== 0) {
                return fulfillValue;
            }
        }
        return defaultValue;
    };

    const calculateTooltipPosition = (xPosition, yPosition, positionaldata) => {
        const tooltip = d3.select(chartRef.current).select('.bar-tooltip');
        tooltip.attr('transform', 'translate(' + (xPosition + 20) + ',' + yPosition + ')');
        tooltip.select('.badge_rect').remove();
        tooltip.select('.badge_value').remove();
        tooltip.select('.text_bar_value').attr('dy', 23);
        if (badge && positionaldata.data.evolutionMetric[positionaldata.data.key] !== null) {
            tooltip.select('.text_bar_value').attr('dy', 35);
            tooltip //rect
                .append('rect')
                .attr('class', 'badge_rect')
                .attr('x', 23)
                .attr('y', 20)
                .attr(
                    'transform',
                    `translate(-${DIMENSIONS.METRIC_BADGE.width / 2},-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
                )
                .attr(
                    'fill',
                    METRICS_VALUE_COLORS_MAP[Math.sign(positionaldata.data.evolutionMetric[positionaldata.data.key])]
                        .background
                )
                .attr(
                    'stroke',
                    METRICS_VALUE_COLORS_MAP[Math.sign(positionaldata.data.evolutionMetric[positionaldata.data.key])]
                        .border
                )
                .attr('stroke-width', 1)
                .attr('rx', 10)
                .attr('width', DIMENSIONS.METRIC_BADGE.width)
                .attr('height', DIMENSIONS.METRIC_BADGE.height)
                .style('cursor', 'default');
            tooltip //value
                .append('text')
                .attr('class', 'badge_value')
                .attr('x', 25)
                .attr('y', 20)
                .attr('text-anchor', 'middle')
                .attr('font-size', '12px')
                .attr(
                    'fill',
                    METRICS_VALUE_COLORS_MAP[Math.sign(positionaldata.data.evolutionMetric[positionaldata.data.key])]
                        .textColor
                )
                .style('font-weight', 600)
                .style('cursor', 'default')
                .text(
                    numFormatterExceptZero(positionaldata.data.evolutionMetric[positionaldata.data.key]) +
                        ' ' +
                        TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL
                );
        }
        const textToShow = positionaldata.data[positionaldata.data.key];
        tooltip.select('text').text(labelFormatter(textToShow));
    };

    const dataModifierWithKeyAddition = d =>
        d.map(elem => {
            if (Array.isArray(elem)) {
                elem.data = { ...elem.data, key: d.key };
                return elem;
            }
            return elem;
        });
    const buildTicks = (svg, xScale, yScale, yScaleLine) => {
        const getTicksScale = () => {
            if (barData.length === 0) {
                return yScaleLine;
            }
            return yScale;
        };
        const yaxisLeft = d3
            .axisLeft()
            .scale(getTicksScale())
            .ticks(customTicks?.barChart?.ticksNumber || 5)
            .tickFormat(d =>
                customTicks?.barChart?.ticksFormat
                    ? d3.format(customTicks?.barChart?.ticksFormat)(d)
                    : isCustomValFunc(d + customTicks?.barChart?.ticksCustomLabel, d, d + '%')
            );
        if (lineScalingOn) {
            const yaxisRight = d3
                .axisLeft() //Even though it points to right, is ok to leave it axisLeft as we set the stroke-opacity to 0, hiding domain line
                .scale(yScaleLine)
                .ticks(customTicks?.lineChart?.ticksNumber || 10)
                .tickFormat(d =>
                    customTicks?.lineChart?.ticksFormat
                        ? d3.format(customTicks?.lineChart?.ticksFormat)(d)
                        : isCustomValFunc(d + customTicks?.lineChart?.ticksCustomLabel, d, d + '%')
                );

            const xAxisElementRight = svg
                .append('g')
                .attr('class', 'axis')
                .attr('transform', 'translate(0' + (WIDTH - AXIS_PADDING - 5) + ',0)')
                .call(yaxisRight);

            xAxisElementRight
                .selectAll('text')
                .attr('x', () => AXIS_PADDING)
                .attr('opacity', 0.8);
            hideUnnecessaryTickStyle(xAxisElementRight, 'line, .domain');
        }

        const xaxis = d3.axisBottom().scale(xScale);

        const yAxisElement = svg
            .append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(0,' + (HEIGHT + 5) + ')')
            .call(xaxis);
        const xAxisElementLeft = svg
            .append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(0,0)')
            .call(yaxisLeft);

        if (foreignObjects) {
            yAxisElement.selectAll('text').remove();
            yAxisElement
                .selectAll('.tick')
                .append('foreignObject')
                .attr('class', d => 'bar-label-' + d)
                .attr('width', foreignObjects.width || 60)
                .attr('height', foreignObjects.height || 90)
                .style('word-wrap', 'break-word')
                .style('font-size', 11)
                .style('text-align', 'center')
                .style('font-weight', 600)
                .style('color', defaultTheme.blue[950])
                .style(
                    'transform',
                    `translate(${foreignObjects.translateX || '-30'}px,${foreignObjects.translateY || '0'}px)`
                )
                .html(d => {
                    const textLength = d.length;
                    const elementId = createIdFromGroup('ellipsed', d);

                    if (textLength >= ELLIPSIS_SHOW_LENGTH) {
                        return `
                                <div>
                                    <p id=${elementId}> ${d.substring(0, 17)}...</p>
                                </div>            
                            `;
                    } else {
                        return `<p>${d}</p>`;
                    }
                })
                .on('mouseenter', (_, dt) => {
                    if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                        const ellipsedId = createIdFromGroup('ellipsed', dt);
                        const imageId = createIdFromGroup('emotionGroup', dt);

                        d3.select(`#${ellipsedId}`).text(dt);
                        d3.select(`#${imageId}`).transition().style('opacity', 0.1);
                    }
                })
                .on('mouseleave', (_, dt) => {
                    if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                        const text = dt.length >= 20 ? dt.substring(0, 17) + '...' : dt;
                        const ellipsedId = createIdFromGroup('ellipsed', dt);
                        const imageId = createIdFromGroup('emotionGroup', dt);

                        d3.select(`#${ellipsedId}`).text(text);
                        d3.select(`#${imageId}`).transition().style('opacity', 1);
                    }
                });

            const groups = barData && barData.map(d => ({ group: d.name, image: d.image }));

            yAxisElement
                .selectAll('.tick')
                .data(groups)
                .append('svg:image')
                .attr('xlink:href', function (d) {
                    return d.image;
                })
                .attr('id', d => 'emotionGroup-' + createClassName(d.group))
                .attr('width', 50)
                .attr('height', 43)
                .attr('x', -25)
                .attr('y', 55)
                .style('box-shadow', '0px 2px 2px #00000029');

            yAxisElement
                .selectAll('line')
                .attr('y1', -(HEIGHT - PADDING.TOP))
                .attr('y2', -(PADDING.BOTTOM + 6))
                .style('stroke-dasharray', '2, 3');
        } else {
            yAxisElement
                .selectAll('text')
                .attr('y', () => -AXIS_PADDING)
                .attr('opacity', 0.8);
        }
        if (!hideGridLines) {
            let { paddingLeft, paddingRight } = customGridStyle;

            xAxisElementLeft
                .selectAll('line')
                .attr('x2', () => WIDTH - PADDING.RIGHT + (paddingRight || 0))
                .attr(
                    'x1',
                    (customTicks?.barChart?.ticksHidden ? PADDING.LEFT - 26 : PADDING.LEFT) + (paddingLeft || 0)
                )
                .style('stroke-dasharray', '2, 3');
        }

        xAxisElementLeft
            .selectAll('text')
            .attr('x', () => (leftLabel ? AXIS_PADDING + 30 : AXIS_PADDING))
            .attr('opacity', customTicks?.barChart?.ticksHidden ? 0 : 0.8);

        if (leftLabel) {
            xAxisElementLeft
                .append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 5)
                .attr('x', 0 - HEIGHT / 2)
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(leftLabel);
        }

        if (bottomLabel) {
            yAxisElement
                .append('text')
                .attr('y', -21.5)
                .attr('x', WIDTH / 2)
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(bottomLabel);
        }
        hideUnnecessaryTickStyle(xAxisElementLeft, '.domain');
        hideUnnecessaryTickStyle(yAxisElement, 'line, .domain');
        if (numberOfRespondents && numberOfRespondents.length > 0) {
            const isBarWidthLow = xScale.bandwidth() < MIN_BAR_WIDTH_METRIC_BADGE;
            const numberOfBarsCondition = barData && barData.length > 4;

            yAxisElement
                .selectAll('g')
                .append('foreignObject')
                .attr('width', isBarWidthLow && numberOfBarsCondition ? 30 : 40)
                .attr('height', 15)
                .attr('y', -20)
                .attr('x', isBarWidthLow && numberOfBarsCondition ? -14 : -20)
                .html(d => {
                    let numberOfRespondentsObj = numberOfRespondents.find(data => data.itemLabel === d);
                    if (numberOfRespondentsObj) {
                        const nrRespondents = 'n=' + numberOfRespondentsObj?.value;
                        return `<div class="numberOfRespondentsStack" title=${nrRespondents}>${nrRespondents}</div>`;
                    } else return '';
                });
        }
    };

    const drawBarChart = (svg, yScale, xScale) => {
        if (!barData || !barData.length) return;

        const subGroups = barData[0] && color.map(e => e.name);
        const colors = color.map(el => el.color);
        let colorScale = d3.scaleOrdinal().domain(subGroups).range(colors);
        let stackedData = d3.stack().keys(subGroups)(barData);
        let stackOffset = d3.stack().offset(d3.stackOffsetExpand);

        let stackedSeriesOffset = stackOffset.keys(subGroups)(barData);
        const drawMidvalues = () => {
            svg.select('.midvalues').remove();
            let mid = svg.append('g').attr('class', 'midvalues');
            const getBarValues = obj => {
                let keys = Object.keys(obj.midValue);
                return keys.map(el => obj[el]);
            };
            const midValues = obj => {
                let keys = Object.keys(obj.midValue);
                return keys.map(el => obj.midValue[el]);
            };

            mid.selectAll('g').data(barData).enter().append('g').attr('class', 'mid');

            mid.selectAll('g.mid')
                .append('rect')
                .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2 - 29)
                .attr('y', d => yScale(getBarValues(d)[0] + getBarValues(d)[1]) - 25)
                .attr('width', '30px')
                .attr('height', '20px')
                .attr('fill', customMidValues?.midValueBackgroundColorsInOrder[0] || defaultTheme.white.ff)
                .attr('rx', 8)
                .attr('ry', 8)
                .style('display', function (d) {
                    return midValues(d)[0] === null ? 'none' : null;
                });

            mid.selectAll('g')
                .append('rect')
                .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2 + 3)
                .attr('y', d => yScale(getBarValues(d)[0] + getBarValues(d)[1]) - 25)
                .attr('width', '30px')
                .attr('height', '20px')
                .attr('fill', customMidValues?.midValueBackgroundColorsInOrder[1] || defaultTheme.white.ff)
                .attr('rx', 8)
                .attr('ry', 8)
                .style('stroke-width', 2)
                .style('stroke', customMidValues?.midValueBackgroundColorsInOrder[0] || defaultTheme.white.ff)
                .style('stroke-dasharray', '4')
                .style('display', function (d) {
                    return midValues(d)[1] === null ? 'none' : null;
                });

            mid.selectAll('g')
                .append('text')
                .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2 - 15)
                .attr('y', d => yScale(getBarValues(d)[0] + getBarValues(d)[1]) - 12)
                .attr('text-anchor', 'middle')
                .attr('font-size', '12px')
                .attr('font-weight', 'bold')
                .text(d => midValues(d)[0])
                .attr('fill', customMidValues?.midValuesFontColorsInOrder[0] || defaultTheme.white.ff);

            mid.selectAll('g')
                .append('text')
                .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2 + 17)
                .attr('y', d => yScale(getBarValues(d)[0] + getBarValues(d)[1]) - 12)
                .attr('text-anchor', 'middle')
                .attr('font-size', '12px')
                .attr('font-weight', 'bold')
                .text(d => midValues(d)[1])
                .attr('fill', customMidValues?.midValuesFontColorsInOrder[1] || defaultTheme.white.ff);
        };

        let layer = svg
            .append('g')
            .selectAll('g.series')
            .data(normalizedValues ? stackedSeriesOffset : stackedData)
            .join('g')
            .classed('series', true)
            .style('color', 'blue')
            .style('fill', d => colorScale(d.key));
        layer
            .selectAll('rect')
            .data(dataModifierWithKeyAddition)
            .join('rect')
            .attr('class', d => 'series stacked-series-' + createClassName(d.data.key + d.data.name))
            .attr('width', xScale.bandwidth())
            .attr('y', d => yScale(d[1]))
            .attr('x', d => xScale(d.data.name))
            .attr('height', d => {
                return yScale(d[0]) - yScale(d[1]);
            })
            .on('mouseover', function (_, datum, d) {
                let text = d3
                    .select(chartRef.current)
                    .select('.stacked-series-text-' + createClassName(datum.data.key + datum.data.name))
                    .node();
                if (hideTextWhenNoSpaceAvailable(text, datum)) {
                    d3.select(chartRef.current).select('.bar-tooltip').style('display', 'block');
                }
            })
            .on('mouseout', () => d3.select(chartRef.current).select('.bar-tooltip').style('display', 'none'))
            .on('mousemove', function (d, datum) {
                const xPosition = d3.pointer(d)[0] - 30 / 2 + 5;
                const yPosition = d3.pointer(d)[1] + 30 / 5 + 5;
                calculateTooltipPosition(xPosition, yPosition, datum);
            });

        /* Add text background when there is a background as an url */
        layer
            .selectAll('g')
            .data(dataModifierWithKeyAddition)
            .join('g')
            .attr('class', d => 'bg-text-group-' + createClassName(d.data.key + d.data.name));

        layer //used as a background for text
            .selectAll('g')
            .append('rect')
            .attr('width', xScale.bandwidth() / 2)
            .attr('class', d => 'bg-text-' + createClassName(d.data.key + d.data.name))
            .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - 10)
            .attr('x', d => xScale(d.data.name) + xScale.bandwidth() / 4)
            .attr('height', 20)
            .style('fill', d => {
                const colorAsString = String(colorScale(d.data.key)) || '';
                if (colorAsString?.includes('url')) {
                    return customMidValues?.midValueBackgroundColorsInOrder[1] || defaultTheme.white.ff;
                }
                return 'transparent';
            });

        /* Draw each bar's value */
        layer
            .selectAll('text')
            .data(dataModifierWithKeyAddition)
            .join('text')
            .attr('class', d => 'series stacked-series-text-' + createClassName(d.data.key + d.data.name))
            .attr('y', d => calculateLabelPosition(d[0], d[1], yScale))
            .attr('x', d => xScale(d.data.name) + xScale.bandwidth() / 2)
            .style('font-size', d => isCustomValFunc(customBarFont?.fontSize, d, '12px'))
            .style('font-weight', '600')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('pointer-events', 'none')
            .text(d => labelFormatter(d.data[d.data.key]))
            .style('fill', d => {
                if ('sigtest' in d.data) {
                    if (d.data.sigtest[d.data.key] !== 0) {
                        return defaultTheme.black.ff;
                    }
                }
                return isCustomValFunc(customBarFont?.color, d, defaultTheme.white.ff);
            })
            .each(function (d) {
                if (!d && !d.data.key) return;
                hideTextWhenNoSpaceAvailable(this, d);
            });

        /* Draw the Sig test labels */
        layer
            .selectAll('g')
            .append('rect')
            .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - 10)
            .attr('x', d => xScale(d.data.name) - xScale.bandwidth() * 0.2)
            .attr('class', d => 'sigtestRect bg-sigtest-' + createClassName(d.data.key + d.data.name))
            .attr('width', xScale.bandwidth() * 1.4)
            .attr('display', d => {
                if ('sigtest' in d.data) {
                    if (d.data.sigtest[d.data.key] !== 0) return 'block';
                }
                return 'none';
            })
            .attr('height', 16)
            .style('fill', d => {
                if ('sigtest' in d.data) {
                    const sigTestValue = d.data.sigtest[d.data.key];
                    return getSigTestTooltipColor(sigTestValue);
                } else 'transparent';
            })
            .on('mouseover', (_, dat) => {
                /* Handle showing the sigtest tooltip when hovering */
                const sigTest = dat.data.sigtest[dat.data.key];
                if (sigTest !== 0) {
                    let text = d3
                        .select(chartRef.current)
                        .select('.stacked-series-text-' + createClassName(dat.data.key + dat.data.name))
                        .node();

                    if (hideTextWhenNoSpaceAvailable(text, dat)) {
                        d3.select(chartRef.current).select('.bar-tooltip').style('display', 'block');
                    }
                    getSigTestTooltipReference()
                        .style('display', 'block')
                        .attr('y', () => calculateLabelPosition(dat[0], dat[1], yScale) - 87)
                        .attr('x', () => xScale(dat.data.name) - 35)
                        .attr('width', xScale.bandwidth() + 60)
                        .attr('height', 78)
                        .select('.sigTest-div') //bar-
                        .style('padding', '8px')
                        .style('height', '88px')
                        .style('background-color', () => getSigTestTooltipColor(sigTest))
                        .html(() => {
                            const tooltipText =
                                sigTest === 1
                                    ? TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_HIGHER
                                    : sigTest === -1
                                    ? TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_LOWER
                                    : '';
                            return `                        
                                <span class="sigTestContent">
                                    ${tooltipText}
                                </span>  
                                <span style='border-top : 5px solid ${getSigTestTooltipColor(
                                    sigTest
                                )}' class="sigTestArrow"></span>       
                        `;
                        });
                }
            })
            .on('mouseout', () => {
                getSigTestTooltipReference().style('display', 'none');
                d3.select(chartRef.current).select('.bar-tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const xPosition = d3.pointer(d)[0] - 30 / 2 + 5;
                const yPosition = d3.pointer(d)[1] + 30 / 5 + 5;
                calculateTooltipPosition(xPosition, yPosition, datum);
            });

        svg.select('.bar-tooltip').remove();
        let tooltip = svg.append('g').attr('class', 'bar-tooltip').style('display', 'none');

        tooltip
            .append('rect')
            .attr('width', '46px')
            .attr('height', '45px') //tooltip
            .attr('fill', defaultTheme.white.ff)
            .attr('stroke', defaultTheme.grey[150])
            .attr('rx', 2);

        tooltip
            .append('text')
            .attr('class', 'text_bar_value')
            .attr('x', 25)
            .attr('dy', badge ? 35 : 23) //here change position of text in tooltip
            .attr('dominant-baseline', 'central')
            .style('text-anchor', 'middle')
            .style('fill', defaultTheme.blue[900])
            .attr('font-size', '14px')
            .attr('font-weight', 800)
            .raise();

        if (customMidValues?.enabled) {
            drawMidvalues();
        }
    };

    const drawLineChart = (svg, xScale, yScale, yScaleLine) => {
        let internalLineData;
        if (!bypassLOBFiltering) {
            internalLineData = lineData.filter(
                ld => visibleLOBs.includes(ld.name) || visibleLOBs.includes(ld.name.toLowerCase())
            );
        } else {
            internalLineData = lineData;
        }
        const getBandMiddle = val => xScale(val) + xScale.bandwidth() / 2;
        const getProperScaling = val => {
            if (lineScalingOn) {
                return yScaleLine(val);
            }
            if (barData.length === 0) {
                return yScaleLine(val);
            }
            return yScale(val);
        };
        const getLineScalingFunction = val => getProperScaling(val);
        const lineCreator = d3
            .line()
            .x(d => getBandMiddle(d.group))
            .y(d => getLineScalingFunction(d.value));

        const lineGroups = svg.selectAll('lines').data(internalLineData).join('g').attr('class', 'lines');

        const lines = lineGroups
            .append('path')
            .attr('class', d => 'data-line data-line-' + createClassName(d.name))
            .attr('d', d => lineCreator(d.values))
            .style('fill', 'none')
            .style('stroke', d => d.color)
            .style('stroke-width', 2);
        addLineHoverEffect(lines);

        const lineGroupExtendedHover = lineGroups
            .append('path')
            .attr('d', d => lineCreator(d.values))
            .style('fill', 'none')
            .style('stroke', 'transparent')
            .style('stroke-width', 7);
        addLineHoverEffect(lineGroupExtendedHover);

        const circles = lineGroups
            .append('g')
            .selectAll('circle')
            .data(d => d.values.map(e => ({ ...e, color: d.color, name: d.name })))
            .enter()
            .append('circle')
            .attr('class', d => 'data-circle data-line data-line-' + createClassName(d.name))
            .attr('r', 2)
            .attr('cx', d => getBandMiddle(d.group))
            .attr('cy', d => getLineScalingFunction(d.value))
            .attr('fill', d => d.color);
        addLineHoverEffect(circles);

        if (showBrandName) {
            const brandName = lineGroups
                .append('foreignObject')
                .attr('class', d => 'data-brand-' + createClassName(d.name))
                .style('visibility', 'hidden');

            brandName
                .attr('width', '75px')
                .attr('x', d => getBandMiddle(d.values[0].group) - 88)
                .append('xhtml:div')
                .html(d => `<div class="brandName" style="border-color:${d.color}">${d.name}</div>`)
                .each(function (d) {
                    svg.selectAll('.data-brand-' + createClassName(d.name))
                        .attr('y', yScale(d.values[0].value) - this.offsetHeight / 2) //Brand name always on center with the line, no matter the text height.
                        .attr('height', this.offsetHeight + 5);
                });
        }

        //Create the tooltip
        const tooltipGroup = lineGroups
            .append('g')
            .attr('class', d => 'data-tooltip-' + createClassName(d.name))
            .style('display', alwaysOnLineTooltip ? 'block' : 'none');
        tooltipGroup
            .selectAll('rect')
            .data(d => d.values.map(e => ({ ...e, color: d.color })))
            .enter()
            .append('rect')
            .attr('x', d => getBandMiddle(d.group) - (customLineTooltip?.width || DIMENSIONS.TOOLTIP.width) / 2)
            .attr('y', d => getLineScalingFunction(d.value) - 32)
            .attr('rx', 2)
            .attr('ry', 2)
            .attr('width', customLineTooltip?.width || DIMENSIONS.TOOLTIP.width)
            .attr('height', customLineTooltip?.height || DIMENSIONS.TOOLTIP.height)
            .attr('stroke', d => d.color)
            .attr('stroke-width', d => returnValueBasedOnSigTest(d, 0, 2))
            .attr('stroke-opacity', customLineTooltip?.strokeOpacity)
            .attr('fill', d => {
                if (customLineTooltip && customLineTooltip.hasOwnProperty('backgroundColor'))
                    return customLineTooltip.backgroundColor;

                if (d.hasOwnProperty('sigtest')) {
                    switch (d.sigtest) {
                        case -1:
                            return defaultTheme.yellow[500];
                        case 0:
                            return defaultTheme.white.ff;
                        case 1:
                            return defaultTheme.green[200];
                        default:
                            return defaultTheme.white.ff;
                    }
                } else {
                    return defaultTheme.white.ff;
                }
            });

        tooltipGroup
            .selectAll('text')
            .data(d => d.values.map(e => ({ ...e, color: d.color })))
            .enter()
            .append('text')
            .attr('x', d => getBandMiddle(d.group))
            .attr('y', d => getLineScalingFunction(d.value) - 16)
            .attr('fill', d => {
                if (customLineTooltip && customLineTooltip.hasOwnProperty('textColor'))
                    return customLineTooltip.textColor;
                return returnValueBasedOnSigTest(
                    d,
                    defaultTheme.blue[900],
                    customLineTooltip?.tooltipFontColor || d.color
                );
            })
            .attr('text-anchor', 'middle')
            .attr('font-size', customLineTooltip?.fontSize || '13px')
            .style('font-weight', 600)
            .text(d => customLineTooltip?.labelFormatter + d.value || d.value + '%');
    };

    const drawBadge = (svg, xScale, yScale) => {
        const isBarWidthLow = xScale.bandwidth() < MIN_BAR_WIDTH_METRIC_BADGE;
        const subGroups = barData[0] && color.map(e => e.name);
        let stackedData = d3.stack().keys(subGroups)(barData);
        let stackOffset = d3.stack().offset(d3.stackOffsetExpand);
        let stackedSeriesOffset = stackOffset.keys(subGroups)(barData);
        const shiftXAxis = 0;
        const offsetYAxis = 15;

        //Metrics Badges
        const metricsGroup = svg.append('g').attr('class', 'metrics-group');
        const metricBadgeGroupWithoutKeyAddition = metricsGroup
            .selectAll('g')
            .data(normalizedValues ? stackedSeriesOffset : stackedData)
            .join('g')
            .attr('class', d => {
                return 'badge-' + createClassName(d.key);
            })
            .lower();
        //handler  dot and mouse over and leave
        let metricBadgeGroup = metricBadgeGroupWithoutKeyAddition
            .selectAll('g')
            .data(dataModifierWithKeyAddition)
            .join('g');
        metricBadgeGroup.on('mouseover', (_, datum) => {
            if (xScale.bandwidth() >= MIN_BAR_WIDTH_METRIC_BADGE) return;
            let text = d3
                .select(chartRef.current)
                .select('.stacked-series-text-' + createClassName(datum.data.key + datum.data.name))
                .node();

            let metricBadgeTextSelection = svg
                .select('.bar-chart-metric-badge-text-' + createClassName(datum.data.name + datum.data.key))
                .transition()
                .delay(150)
                .ease(d3.easeLinear)
                .duration(100);
            let metricBadgeRectSelection = svg
                .select('.bar-chart-metric-badge-' + createClassName(datum.data.name + datum.data.key))
                .transition()
                .ease(d3.easeLinear)
                .duration(200);

            metricBadgeTextSelection
                .attr('opacity', d =>
                    d.data.evolutionMetric[d.data.key] === null || hideTextWhenNoSpaceAvailable(text, datum) ? 0 : 1
                ) //if value is null do not display or is not enough space
                .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis); // position over the middle text from bar
            metricBadgeRectSelection
                .attr('width', DIMENSIONS.METRIC_BADGE.width)
                .attr('height', DIMENSIONS.METRIC_BADGE.height);
            metricBadgeRectSelection
                .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis) // position over the middle text from bar
                .attr(
                    'transform',
                    `translate(-${DIMENSIONS.METRIC_BADGE.width / 2},-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
                );
        });
        metricBadgeGroup.on('mouseleave', (_, datum) => {
            if (xScale.bandwidth() >= MIN_BAR_WIDTH_METRIC_BADGE) return;

            let metricBadgeTextSelection = svg
                .select('.bar-chart-metric-badge-text-' + createClassName(datum.data.name + datum.data.key))
                .transition()
                .delay(0)
                .ease(d3.easeLinear)
                .duration(50);
            let metricBadgeRectSelection = svg
                .select('.bar-chart-metric-badge-' + createClassName(datum.data.name + datum.data.key))
                .transition()
                .ease(d3.easeLinear)
                .duration(200);
            metricBadgeTextSelection
                .attr('opacity', 0)
                .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis); // position over the middle text from bar
            metricBadgeRectSelection.attr('width', 12);
            metricBadgeRectSelection.attr('height', 12);

            metricBadgeRectSelection.attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis); // position over the middle text from bar
            metricBadgeRectSelection.attr(
                'transform',
                `translate(-${
                    isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.width / 2 : DIMENSIONS.METRIC_BADGE.width / 2
                },-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
            );
        });
        metricBadgeGroup //rect
            .append('rect')
            .attr('class', d => 'bar-chart-metric-badge-' + createClassName(d.data.name + d.data.key))
            .attr('x', d => {
                return xScale(d.data.name) + xScale.bandwidth() / 2; // + shiftXAxis;// +(isBarWidthLow?5:0) to put on the middle of bar that dot..but after hover..
            }) //shift to the right
            .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis) // position over the middle text from bar
            .attr(
                'transform',
                `translate(-${
                    isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.width / 2 : DIMENSIONS.METRIC_BADGE.width / 2
                },-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
            )
            .attr('fill', d => {
                return METRICS_VALUE_COLORS_MAP[Math.sign(d?.data.evolutionMetric[d.data.key])].background;
            })
            .attr('stroke', d => METRICS_VALUE_COLORS_MAP[Math.sign(d?.data.evolutionMetric[d.data.key])].border)
            .attr('cursor', d => {
                let text = d3
                    .select(chartRef.current)
                    .select('.stacked-series-text-' + createClassName(d.data.key + d.data.name))
                    .node();
                if (hideTextWhenNoSpaceAvailable(text, d) || d.data.evolutionMetric[d.data.key] === null)
                    return 'default';
                else return 'cursor';
            })
            .attr('stroke-width', 1)
            .attr('rx', 10)
            .attr('opacity', d => {
                let text = d3
                    .select(chartRef.current)
                    .select('.stacked-series-text-' + createClassName(d.data.key + d.data.name))
                    .node();
                if (hideTextWhenNoSpaceAvailable(text, d) || d.data.evolutionMetric[d.data.key] === null) return 0;
                else return 1;
            })
            .attr('width', isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.width : DIMENSIONS.METRIC_BADGE.width)
            .attr('height', isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.height : DIMENSIONS.METRIC_BADGE.height);
        metricBadgeGroup //value
            .append('text')
            .attr('class', d => 'bar-chart-metric-badge-text-' + createClassName(d.data.name + d.data.key))
            .attr('x', d => {
                return xScale(d.data.name) + xScale.bandwidth() / 2 + shiftXAxis;
            }) //shift to the right
            .attr('y', d => calculateLabelPosition(d[0], d[1], yScale) - offsetYAxis) // position over the middle text from bar
            .attr('text-anchor', 'middle')
            .attr('font-size', '12px')
            .attr('fill', d => METRICS_VALUE_COLORS_MAP[Math.sign(d?.data.evolutionMetric[d.data.key])].textColor)
            .style('font-weight', 600)
            .attr('opacity', d => {
                let text = d3
                    .select(chartRef.current)
                    .select('.stacked-series-text-' + createClassName(d.data.key + d.data.name))
                    .node();
                if (
                    hideTextWhenNoSpaceAvailable(text, d) ||
                    d.data.evolutionMetric[d.data.key] === null ||
                    isBarWidthLow
                )
                    return 0;
                else return 1;
            })
            .attr('cursor', d => {
                let text = d3
                    .select(chartRef.current)
                    .select('.stacked-series-text-' + createClassName(d.data.key + d.data.name))
                    .node();
                if (hideTextWhenNoSpaceAvailable(text, d) || d.data.evolutionMetric[d.data.key] === null)
                    return 'default';
                else return 'cursor';
            })
            .text(
                d =>
                    numFormatterExceptZero(d.data.evolutionMetric[d.data.key]) +
                    ' ' +
                    TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL
            );
    }; // end of second value function !!!

    const initChart = () => {
        let groupDomain, dataDomain, lineDataDomain;
        dataDomain = color?.map(e => e.name);

        if (lineData.length > 0) {
            lineDataDomain = d3.max(lineData, line =>
                d3.max(line.values, d => calculateMaxValue(d, lineScalingOn ? 1 : 4))
            );
        }

        if (barData.length === 0 && lineData.length > 0) {
            groupDomain = lineData[0].values.map(d => d.group);
        } else if (lineData.length === 0 && barData.length > 0) {
            groupDomain = barData.map(d => d.name);
        } else {
            groupDomain = barData.map(d => d.name);
        }

        if (!groupDomain) return null;
        // Create the scales
        const xScale = d3
            .scaleBand()
            .rangeRound([
                customTicks?.barChart?.ticksHidden ? 0 : PADDING.LEFT,
                WIDTH - (customTicks?.barChart?.ticksHidden ? 0 : PADDING.RIGHT),
            ])
            .padding(customBarPadding || 0.6)
            .domain(groupDomain);

        const yScale = d3
            .scaleLinear()
            .rangeRound([HEIGHT - PADDING.TOP, PADDING.BOTTOM])
            .domain([0, dataMaxDomain ? dataMaxDomain : calculateDomain(dataDomain)]);

        const yScaleLine = d3
            .scaleLinear()
            .rangeRound([HEIGHT - PADDING.TOP, PADDING.BOTTOM])
            .domain([0, lineDataDomain]);

        return {
            xScale,
            yScale,
            yScaleLine,
        };
    };

    useEffect(() => {
        if (barData && lineData) {
            const svg = d3.select(chartRef.current);
            svg.selectAll('*').remove();

            if (visibleLOBs && visibleLOBs.length) {
                barData = barData.filter(el => visibleLOBs.includes(el.name));
            }
            const chartInstance = initChart();
            if (!chartInstance) return;
            const { xScale, yScale, yScaleLine } = chartInstance;

            buildTicks(svg, xScale, yScale, yScaleLine);
            drawBarChart(svg, yScale, xScale);
            drawLineChart(svg, xScale, yScale, yScaleLine);
            if (badge && barData) drawBadge(svg, xScale, yScale);
            drawSigTestTooltip();
        }
    }, [lineData, barData, WIDTH, visibleLOBs, bypassLOBFiltering]);
    return (
        <div>
            {chartTitle && <SC.Title>{chartTitle}</SC.Title>}
            {customPattern}
            <SC.SvgWrapper ref={chartRef} width={WIDTH} height={HEIGHT} showBrandName={showBrandName} />
        </div>
    );
};

const ChartValueType = PropTypes.shape({
    value: PropTypes.number.isRequired,
    group: PropTypes.string.isRequired,
});

CombinedStackedBarLineChart.propTypes = {
    lineData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(ChartValueType),
        })
    ),
    barData: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        PropTypes.array,
    ]),
    color: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    customHeight: PropTypes.number,
    dataMaxDomain: PropTypes.bool,
    customWidthOnPage: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    computeLabelFormat: PropTypes.func,
    visibleLOBs: PropTypes.arrayOf(PropTypes.string),
    customBarPadding: PropTypes.number,
    bypassLOBFiltering: PropTypes.bool,
    showBrandName: PropTypes.bool,
    alwaysOnLineTooltip: PropTypes.bool,
    lineScalingOn: PropTypes.bool,
    hideGridLines: PropTypes.bool,
    badge: PropTypes.bool,
    chartTitle: PropTypes.string,
    leftLabel: PropTypes.string,
    bottomLabel: PropTypes.string,
    customPattern: PropTypes.node,
    normalizedValues: PropTypes.bool,
    foreignObjects: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            translateX: PropTypes.number,
            translateY: PropTypes.number,
        }),
    ]),
    customGridStyle: PropTypes.shape({
        paddingLeft: PropTypes.number,
        paddingRight: PropTypes.number,
    }),
    customTicks: PropTypes.oneOfType([
        PropTypes.shape({
            barChart: {
                ticksHidden: PropTypes.bool,
                ticksNumber: PropTypes.number,
                ticksFormat: PropTypes.string,
                ticksCustomLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            },
        }),
        PropTypes.shape({
            lineChart: {
                ticksNumber: PropTypes.number,
                ticksFormat: PropTypes.string,
                ticksCustomLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            },
        }),
    ]),
    customBarFont: PropTypes.shape({
        fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
        color: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
    }),
    customMidValues: PropTypes.shape({
        enabled: PropTypes.bool,
        midValuesFontColorsInOrder: PropTypes.array,
        midValueBackgroundColorsInOrder: PropTypes.array,
    }),
    showValuesWhenNoSpace: PropTypes.bool,
    customLineTooltip: PropTypes.shape({
        width: PropTypes.number,
        fontSize: PropTypes.number,
        labelFormatter: PropTypes.string,
        strokeOpacity: PropTypes.number,
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        tooltipFontColor: PropTypes.string,
    }),
    numberOfRespondents: PropTypes.array,
};

CombinedStackedBarLineChart.defaultProps = {
    badge: false,
    barData: [],
    normalizedValues: false,
    lineData: null,
    customWidthOnPage: 300,
    visibleLOBs: [],
    foreignObjects: false,
    customGridStyle: { paddingLeft: 0, paddingRight: 0 },
    numberOfRespondents: [],
};

export default CombinedStackedBarLineChart;
