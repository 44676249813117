import { SVG } from './FullPageLoader.styles';
import React from 'react';

const FullPageLoader = () => (
    <SVG width={200} height={200} viewBox="0 0 200 200">
        <g transform="translate(30 30)">
            <circle className="b" cx={69.25} cy={69.25} r={69.25} />
            <g className="monitorContainer">
                <g transform="translate(5 25)" className="monitor">
                    <path className="a" d="M0,85.09A4,4,0,0,0,3.19,88.9,4,4,0,0,1,0,85.09Z" />
                    <path className="a" d="M3.83,0A4,4,0,0,0,0,4,4,4,0,0,1,3.83,0Z" />
                    <path className="a" d="M1.5,81.61V85A2.5,2.5,0,0,0,4,87.48H124.79a2.5,2.5,0,0,0,2.5-2.5V81.61Z" />
                    <path className="a" d="M127.29,20.01H1.5v60.1H127.29Z" />
                    <path
                        className="a"
                        d="M124.86,0a4,4,0,0,1,3.93,4V85a4,4,0,0,1-3.2,3.92,4,4,0,0,0,3.2-3.92V4a4,4,0,0,0-3.93-4Z"
                    />
                    <path className="a" d="M127.29,4a2.5,2.5,0,0,0-2.5-2.5H4A2.5,2.5,0,0,0,1.5,4V18.51H127.29Z" />
                    <path
                        className="d"
                        d="M128.79,85V4a4,4,0,0,0-3.93-4H3.83A4,4,0,0,0,0,4V85.11A4,4,0,0,0,3.19,88.9,4.28,4.28,0,0,0,4,89H54.41v23.53a53.975,53.975,0,0,0,9.18.72,68.326,68.326,0,0,0,8.59-.52L72.34,89h52.45a4.264,4.264,0,0,0,.8-.08,4,4,0,0,0,3.2-3.92Zm-1.5-4.87H1.5V20H127.29ZM1.5,4A2.5,2.5,0,0,1,4,1.5H124.79a2.5,2.5,0,0,1,2.5,2.5V18.51H1.5ZM70.68,111.37c-1.46.15-4.05.36-7.1.36a55.258,55.258,0,0,1-7.68-.5V89H70.84Zm54.11-23.89H4A2.5,2.5,0,0,1,1.5,85V81.61H127.29V85a2.5,2.5,0,0,1-2.5,2.48Z"
                    />
                    <path
                        className="a"
                        d="M55.9,100v11.21a55.256,55.256,0,0,0,7.68.5c3,0,5.64-.21,7.1-.36L70.84,89H55.91C55.9,91.25,55.9,95.63,55.9,100Z"
                    />
                </g>
                <g transform="translate(5 25)" className="rows">
                    <rect className="firstRow" width={49} height={5} rx={2.5} />
                    <rect className="secondRow" width={24} height={5} rx={2.5} />
                    <rect className="thirdRow" width={33} height={5} rx={2.5} />
                </g>
                <g transform="translate(5 25)" className="filledRows">
                    <rect className="firstFilledRow" width={6} height={5} rx={2.5} transform="translate(19 31)" />
                    <rect className="secondFilledRow" width={6} height={5} rx={2.5} transform="translate(19 47)" />
                    <rect className="thirdFilledRow" width={6} height={5} rx={2.5} transform="translate(19 63)" />
                </g>
            </g>
            <g className="spinner">
                <path className="a" d="M1.471,28.677H23.529v2.206H1.471Z" />
                <path className="a" d="M1.471,1.471H23.529V3.676H1.471Z" />
                <path
                    className="d"
                    d="M0,28.507a1.1,1.1,0,0,1,.022-.158A1.427,1.427,0,0,1,.1,28.107a1.47,1.47,0,0,1,1.371-.9H3.7c.232-3.875,2.279-7.835,5.86-9.3V14.441C5.978,12.971,3.93,9.018,3.7,5.147H1.471A1.471,1.471,0,0,1,0,3.676V1.471A1.471,1.471,0,0,1,1.471,0H23.529A1.471,1.471,0,0,1,25,1.471V3.676a1.471,1.471,0,0,1-1.471,1.471H21.3c-.232,3.871-2.279,7.831-5.86,9.294v3.467c3.581,1.471,5.629,5.423,5.86,9.3h2.228a1.471,1.471,0,0,1,1.353.9,1.174,1.174,0,0,1,.051.162,1.32,1.32,0,0,1,.063.368h0v2.206a1.53,1.53,0,0,1-1.471,1.511H1.471A1.471,1.471,0,0,1,0,30.882V28.677a1.266,1.266,0,0,1,0-.166ZM23.529,3.676V1.471H1.471V3.676ZM14.474,19.07a.757.757,0,0,1-.5-.687V13.971a.757.757,0,0,1,.5-.7c3.114-1.015,5.11-4.588,5.357-8.121H5.169c.246,3.533,2.243,7.107,5.353,8.121a.757.757,0,0,1,.507.7v4.412a.758.758,0,0,1-.507.688c-3.11,1.015-5.107,4.6-5.353,8.136H19.831C19.585,23.665,17.588,20.085,14.474,19.07Zm-13,11.813H23.529V28.677H1.471Z"
                />
                <path
                    className="a"
                    d="M10.522,19.07a.757.757,0,0,0,.507-.687V13.971a.757.757,0,0,0-.507-.7C7.412,12.254,5.415,8.68,5.169,5.147H19.831c-.246,3.533-2.243,7.107-5.357,8.121a.757.757,0,0,0-.5.7v4.412a.757.757,0,0,0,.5.688c3.114,1.015,5.11,4.6,5.357,8.136H5.169C5.415,23.665,7.412,20.084,10.522,19.07Z"
                />
                <circle
                    className="d"
                    cx={0.735}
                    cy={0.735}
                    r={0.735}
                    transform="translate(13.235 10.294) rotate(180)"
                />
                <path className="d" d="M7.353,25.735a5.147,5.147,0,1,1,10.294,0H7.353Z" />
            </g>
        </g>
    </SVG>
);

export default FullPageLoader;
