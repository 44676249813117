import React from 'react';
import { ReportOptionWrapper } from '../ReportOption/ReportOption.styles';
import styled from 'styled-components';

const SVG = styled.svg`
    transform: scale(0.8);
    .clock-top-part-1,
    .clock-top-part-2,
    .clock-circle {
        transition: all 0.1s linear 0.05s;
    }
    .playbar-circle {
        transition: transform 0.1s linear 0.05s;
    }
    .play-button {
        transition: all 0.05s linear;
    }
    .a,
    .d,
    .clock-top-part-1,
    .clock-top-part-2,
    .clock-circle,
    .playbar-circle {
        fill: ${({ theme }) => theme.white.ff};
    }
    .a,
    .b,
    .c,
    .clock-top-part-1,
    .clock-top-part-2,
    .clock-circle,
    .playbar-circle,
    .playbar-line {
        stroke: ${({ theme }) => theme.orange[700]};
        stroke-width: 1.5px;
    }
    .b,
    .c,
    .g {
        fill: none;
    }
    .c {
        stroke-linecap: round;
    }
    .playbar-circle,
    .playbar-line {
        opacity: 0;
    }
    .f,
    .h {
        stroke: none;
    }
    .h {
        fill: ${({ theme }) => theme.orange[700]};
    }

    ${ReportOptionWrapper}:hover & {
        .clock-top-part-1,
        .clock-top-part-2,
        .clock-circle {
            fill: ${({ theme }) => theme.red[100]};
        }
        .clock-top-part-1 {
            transform: translate(72.8px, 7.672px) rotate(90deg);
        }
        .clock-top-part-2 {
            transform: translate(61.3px, 3.12px);
        }
        .play-button {
            opacity: 0;
        }
        .playbar-circle {
            opacity: 1;
            transform: translate(98.301px, 96.88px);
        }
        .playbar-line {
            opacity: 1;
        }
    }
`;

const AdtesterComponent = () => (
    <SVG width={140} height={151.159} viewBox="0 0 140 151.159">
        <g transform="translate(-0.3 3.12)">
            <g className="clock-top-part-1" transform="translate(72.8 1.672) rotate(90)">
                <rect className="f" width={17} height={6} rx={2} />
                <rect className="g" x={0.75} y={0.75} width={15.5} height={4.5} rx={1.25} />
            </g>
            <g className="clock-top-part-2" transform="translate(61.3 -3.12)">
                <rect className="f" width={17} height={6} rx={2} />
                <rect className="g" x={0.75} y={0.75} width={15.5} height={4.5} rx={1.25} />
            </g>
            <g className="clock-circle" transform="translate(0.3 7.88)">
                <circle className="f" cx={70} cy={70} r={70} />
                <circle className="g" cx={70} cy={70} r={69.25} />
            </g>
            <g className="a" transform="translate(5.6 35.053)">
                <rect className="f" width={128.787} height={88.98} rx={4} />
                <rect className="g" x={0.75} y={0.75} width={127.287} height={87.48} rx={3.25} />
            </g>
            <g className="b" transform="translate(18.3 58.88)">
                <rect className="f" width={105} height={52} rx={3} />
                <rect className="g" x={0.75} y={0.75} width={103.5} height={50.5} rx={2.25} />
            </g>
            <line className="b" x2={127.671} transform="translate(6.16 54.316)" />
            <line className="b" x2={104.153} transform="translate(18.479 72.235)" />
            <line className="b" x2={127.671} transform="translate(6.16 115.912)" />
            <g transform="translate(61.596 123.615)">
                <line className="c" y2={22.535} />
                <line className="c" y2={22.535} transform="translate(17.919)" />
            </g>
            <g className="play-button">
                <g className="a" transform="translate(55.3 75.88)">
                    <circle className="f" cx={14.5} cy={14.5} r={14.5} />
                    <circle className="g" cx={14.5} cy={14.5} r={13.75} />
                </g>
                <g className="d" transform="translate(77.3 81.88) rotate(90)">
                    <path
                        className="f"
                        d="M 13.1324291229248 11.25000095367432 L 3.867568969726563 11.25000095367432 C 3.391159057617188 11.25000095367432 2.976078987121582 10.9973611831665 2.757249116897583 10.57418060302734 C 2.538408994674683 10.15099048614502 2.572169065475464 9.666240692138672 2.847548961639404 9.277470588684082 L 7.479969024658203 2.7375807762146 C 7.717419147491455 2.402360677719116 8.089199066162109 2.210100650787354 8.499999046325684 2.210100650787354 C 8.910799026489258 2.210100650787354 9.28257942199707 2.402360677719116 9.520029067993164 2.7375807762146 L 14.15245914459229 9.277470588684082 C 14.4278392791748 9.666240692138672 14.46158885955811 10.15099048614502 14.24274921417236 10.57418060302734 C 14.02391910552979 10.9973611831665 13.60883903503418 11.25000095367432 13.1324291229248 11.25000095367432 Z"
                    />
                    <path
                        className="h"
                        d="M 8.5 2.960101127624512 C 8.403440475463867 2.960101127624512 8.222020149230957 2.987510681152344 8.09199047088623 3.171100616455078 L 3.459560394287109 9.710980415344238 C 3.306500434875488 9.927070617675781 3.371230125427246 10.12871074676514 3.423439979553223 10.22967052459717 C 3.475649833679199 10.33063125610352 3.602780342102051 10.50000095367432 3.867569923400879 10.50000095367432 L 13.13243007659912 10.50000095367432 C 13.39721965789795 10.50000095367432 13.5243501663208 10.33063125610352 13.57656002044678 10.22967052459717 C 13.62876987457275 10.12871074676514 13.69350051879883 9.927070617675781 13.54043960571289 9.710990905761719 L 8.90800952911377 3.171091079711914 C 8.777979850769043 2.987510681152344 8.596559524536133 2.960101127624512 8.5 2.960101127624512 M 8.5 1.460103034973145 C 9.116754531860352 1.460103034973145 9.73351001739502 1.741425514221191 10.13204956054688 2.304070472717285 L 14.76448059082031 8.843960762023926 C 15.70267009735107 10.16848087310791 14.75555992126465 12.00000095367432 13.13243007659912 12.00000095367432 L 3.867569923400879 12.00000095367432 C 2.244440078735352 12.00000095367432 1.297329902648926 10.16848087310791 2.235520362854004 8.843960762023926 L 6.867950439453125 2.304070472717285 C 7.26648998260498 1.741425514221191 7.883245468139648 1.460103034973145 8.5 1.460103034973145 Z"
                    />
                </g>
            </g>
            <line className="playbar-line" x2={85.5} transform="translate(28.301 100.23)" />
            <g className="playbar-circle" transform="translate(34.301 96.88)">
                <circle className="f" cx={3.5} cy={3.5} r={3.5} />
                <circle className="g" cx={3.5} cy={3.5} r={2.75} />
            </g>
        </g>
        <path
            className="a"
            d="M-12700.664-14480.435s0-23.259,0-23.259h17.941v23.259A62.715,62.715,0,0,1-12700.664-14480.435Z"
            transform="translate(12762.001 14630.093)"
        />
        <g transform="translate(-0.3 3.12)">
            <line className="c" y2={8.959} transform="translate(69.995 9.52)" />
            <line className="c" y2={8.959} transform="translate(35.838 18.672) rotate(-30)" />
            <line className="c" y2={8.959} transform="translate(104.153 18.672) rotate(30)" />
            <line className="c" y2={8.959} transform="translate(99.674 129.238) rotate(-30)" />
            <line className="c" y2={8.959} transform="translate(40.317 129.238) rotate(30)" />
        </g>
    </SVG>
);

export default AdtesterComponent;
