import styled from '@emotion/styled';
import { BUTTON_STYLE } from './constants';

export const StyledFileUploadInput = styled.input`
    display: none !important;
`;

export const StyledFileUploadLabel = styled.label`
    width: 85px;
    height: 100%;
    color: ${props => props.theme.lightColor};
    background-color: #ffffff;
    -webkit-transition: background-color 350ms ease;
    -ms-transition: background-color 350ms ease;
    transition: background-color 350ms ease;
    font-family: 'MuseoSans-300';
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #cccccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &:hover {
        background-color: #f7f9fa;
    }

    ${props => props.inputFieldLabelCustomStyle || ''}
`;

export const StyledInputFieldWrapper = styled.div`
  width: ${props => props.width};
  display: flex;
  height: auto;
  position: relative;
  flex-direction: column;
`;

export const ButtonContentWrapper = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${({buttonStyle}) => buttonStyle === BUTTON_STYLE.RECTANGLE ? 'flex-start' : 'center'};
  align-items: center;
`;

export const ButtonTextWrapper = styled.span`
  height: 100%;
  padding: 0px 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${({buttonStyle}) => buttonStyle === BUTTON_STYLE.RECTANGLE ? 'center' : 'flex-start'};
  align-items: center;
  white-space: nowrap;
`;

export const StyledButtonFileUpload = styled.label`
  padding: 0px;
  margin: 0px;
  border: 0px;
  outline: none;
  cursor: pointer;
  display:inline-block;
  transition: all 0.35s ease;
  height: 32px;
  border-radius: ${({buttonStyle}) =>  buttonStyle === BUTTON_STYLE.RECTANGLE ? '3px' : '16px'};
  font-family: 'MuseoSans-300';
  font-size: 14px;
  background: #00A3E6;
  color: #ffffff;
  &:hover {
    background: hsl(197,100%,38.25%);
  }
  font-weight: 300;
  ${props => props.customStyle || ''}
`;

export const ButtonIconWrapper = styled.span`
  padding:  ${({iconType}) => iconType === 'font-awesome' ? '0 0 0 15px' : '7px 0 5px 15px'};
  margin-right: -5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items:  ${({iconType}) => iconType === 'font-awesome' ? 'center' : 'flex-end'};
`;
