import { GET_TRANSVERSALVIEW_DATA_ASYNC, SET_ON_TRANSVERSAL_SECTIONS_TOGGLE } from './actions';

export const transversalDataReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TRANSVERSALVIEW_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export const KPYsHandlerReducer = (state = [], action) => {
    switch (action.type) {
        case SET_ON_TRANSVERSAL_SECTIONS_TOGGLE:
            return action.payload;
        default:
            return state;
    }
};
