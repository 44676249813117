import { GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC } from './actions';

const adComparisonDataReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export default adComparisonDataReducer;
