import { all, put, takeLatest, select, takeEvery, take } from 'redux-saga/effects';
import { addAsyncErrorActionCreator } from '../../errorHandling/data/actions';
import { hasValidAdsComparisonSelectedOptionsComputedSelector } from '../../pages/adTester/subpages/AdsComparison/data/computedSelectors';
import { getFiltersData } from '../../services/apiServer.service';
import {
    applySideFilters,
    initSideFilters,
    storeApplyFiltersActionCreator,
    storeClearFiltersActionCreator,
    storeToggleSideFiltersSectionActionCreator,
} from '../components/SideFilters/data/actions';
import { initialTopFiltersSaga } from '../components/TopFilter/data/saga';
import {
    getCachedLastAppliedTopFiltersOptionSelector,
    getLocationSelector,
    getSelectedTopFiltersByPageSelector,
} from '../components/TopFilter/data/selectors';
import { getParentPathOnPagesWithSubTabs } from '../components/TopFilter/utils/utils';
import { FILTERS_CONFIG, splitFilters } from '../utils/utils';
import {
    APPLY_FILTERS,
    GET_FILTERS,
    GET_FILTERS_ASYNC,
    getFiltersActionCreator,
    globalOverviewSelectedCountriesSaveActionCreator,
    INIT_FILTERS_FOR_SUBTABS,
    ON_GLOBAL_OVERVIEW_COUNTRIES_APPLY,
    setRelatedPageForFiltersActionCreator,
} from './actions';
import { getGlobalOverviewSelectedCountriesSelector } from './selectors';
import { initCacheLastAppliedTopFilterOptionActionCreator } from '../components/TopFilter/data/actions';
import {
    getAppliedSideFiltersSelector,
    getSelectedSideFiltersSelector,
    getSideFilterSectionUiSelector,
} from '../components/SideFilters/data/selectors';
import { SIDE_FILTERS_PAGES, SIDE_FILTERS_SUB_PAGES } from '../components/SideFilters/utils/sideFilters.config';

function* onGlobalOverviewSelectedCountriesApplySaga(action) {
    const { countryId } = action.payload;
    const selectedCountries = yield select(getGlobalOverviewSelectedCountriesSelector);

    let updatedSelectedCountries = null;
    if (selectedCountries.includes(countryId)) {
        updatedSelectedCountries = selectedCountries.filter(country => country !== countryId);
    } else {
        updatedSelectedCountries = [...selectedCountries, countryId];
    }

    yield put(globalOverviewSelectedCountriesSaveActionCreator(updatedSelectedCountries));
}

function* filtersOrchestrationSaga(action) {
    const { hasSideFilters, hasTopFilters, requestPageName, additionalParametersExtractor } = action.payload;
    const currentPath = yield select(getLocationSelector);
    try {
        yield put(GET_FILTERS_ASYNC.dispatchers.started());

        const payload = {
            pageName: requestPageName,
            ...(additionalParametersExtractor ? yield select(additionalParametersExtractor) : {}),
        };

        const filterData = yield getFiltersData(payload);

        yield put(GET_FILTERS_ASYNC.dispatchers.succeeded(splitFilters(filterData)));

        if (hasSideFilters) {
            yield put(initSideFilters(filterData));
        }

        if (hasTopFilters) {
            yield initialTopFiltersSaga();
        }

        yield put(setRelatedPageForFiltersActionCreator(currentPath));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        const status = error.hasOwnProperty('response') ? error.response.status : error;
        yield put(GET_FILTERS_ASYNC.dispatchers.failed({ status, errorOnPath: currentPath }));
    }
}

function* appliedFiltersHandlerSaga(action) {
    try {
        const { payload } = action;

        const currentPath = yield select(getLocationSelector);
        const locationAsKey = getParentPathOnPagesWithSubTabs(currentPath);

        /*
            In certain cases we wish to reload the filters after clicking apply.
            One use case for this is that changing some options from TopFilters will bring a new set of top filters.
            For example, changing the country will bring a different set of SideFilters.
         */
        /* For all pages: BPT & ADTESTER we need to reset side filters if the country has changed */
        if (payload.resetSideFiltersIfCountryChanged) {
            const lastAppliedFilters = yield select(getCachedLastAppliedTopFiltersOptionSelector);
            const currentAppliedTopFilters = yield select(getSelectedTopFiltersByPageSelector);

            const lastAppliedFiltersForPage = lastAppliedFilters[locationAsKey];
            const currentAppliedTopFiltersForPage = currentAppliedTopFilters[locationAsKey];
            if (lastAppliedFiltersForPage && currentAppliedTopFiltersForPage) {
                const lastAppliedCountry = lastAppliedFiltersForPage.find(f => f.group.toLowerCase() === 'country');
                const currentAppliedCountry = currentAppliedTopFiltersForPage.find(
                    f => f.group.toLowerCase() === 'country'
                );

                if (lastAppliedCountry && currentAppliedCountry) {
                    if (lastAppliedCountry.id !== currentAppliedCountry.id) {
                        const selectedSideFilters = yield select(getSelectedSideFiltersSelector);
                        const appliedSideFilters = yield select(getAppliedSideFiltersSelector);
                        const sideFiltersSectionUi = yield select(getSideFilterSectionUiSelector);

                        const processedFilters = { ...selectedSideFilters };
                        const processedAppliedSideFilters = { ...appliedSideFilters };
                        const processedSideFiltersSectionUi = { ...sideFiltersSectionUi };

                        if (
                            selectedSideFilters.hasOwnProperty(SIDE_FILTERS_SUB_PAGES.preferenceAnalysis) &&
                            appliedSideFilters.hasOwnProperty(SIDE_FILTERS_SUB_PAGES.preferenceAnalysis)
                        ) {
                            processedFilters[SIDE_FILTERS_SUB_PAGES.preferenceAnalysis] = {};
                            processedAppliedSideFilters[SIDE_FILTERS_SUB_PAGES.preferenceAnalysis] = {};
                            processedSideFiltersSectionUi[SIDE_FILTERS_SUB_PAGES.preferenceAnalysis] = {};
                        }
                        if (
                            selectedSideFilters.hasOwnProperty(SIDE_FILTERS_PAGES.adTester) &&
                            appliedSideFilters.hasOwnProperty(SIDE_FILTERS_PAGES.adTester)
                        ) {
                            processedFilters[SIDE_FILTERS_PAGES.adTester] = {};
                            processedAppliedSideFilters[SIDE_FILTERS_PAGES.adTester] = {};
                            processedSideFiltersSectionUi[SIDE_FILTERS_PAGES.adTester] = {};
                        }
                        if (
                            selectedSideFilters.hasOwnProperty(SIDE_FILTERS_PAGES.adTesterComparison) &&
                            appliedSideFilters.hasOwnProperty(SIDE_FILTERS_PAGES.adTesterComparison)
                        ) {
                            processedFilters[SIDE_FILTERS_PAGES.adTesterComparison] = {};
                            processedAppliedSideFilters[SIDE_FILTERS_PAGES.adTesterComparison] = {};
                            processedSideFiltersSectionUi[SIDE_FILTERS_PAGES.adTesterComparison] = {};
                        }

                        /* Reset all the selected options, applied options and toggle state */
                        yield put(storeClearFiltersActionCreator(processedFilters));
                        yield put(storeApplyFiltersActionCreator(processedAppliedSideFilters));
                        yield put(storeToggleSideFiltersSectionActionCreator(processedSideFiltersSectionUi));
                    }
                }
            }
        }

        if (payload.reloadFilters) {
            let requestFiltersParameters = FILTERS_CONFIG[locationAsKey];
            if (requestFiltersParameters) {
                yield put(getFiltersActionCreator(requestFiltersParameters));
                yield take([GET_FILTERS_ASYNC.actions.SUCCEEDED, GET_FILTERS_ASYNC.actions.FAILED]);
            }
        }

        yield put(applySideFilters());

        const allowRequestingOnOptionValidating = yield select(hasValidAdsComparisonSelectedOptionsComputedSelector);

        const requestAction = FILTERS_CONFIG[locationAsKey]?.requestAction;
        const hasOptionsValidation = FILTERS_CONFIG[locationAsKey]?.hasOptionsValidation;

        if (hasOptionsValidation && !allowRequestingOnOptionValidating) return;

        //const cache = yield select(getCachedSelectedFiltersDataComputedSelector);
        yield put(initCacheLastAppliedTopFilterOptionActionCreator());

        if (requestAction) {
            yield put(requestAction());
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
}

export default function* adTesterOverviewSaga() {
    yield all([
        takeLatest(ON_GLOBAL_OVERVIEW_COUNTRIES_APPLY, onGlobalOverviewSelectedCountriesApplySaga),
        takeLatest(GET_FILTERS, filtersOrchestrationSaga),
        takeEvery(APPLY_FILTERS, appliedFiltersHandlerSaga),
        takeLatest(INIT_FILTERS_FOR_SUBTABS, initialTopFiltersSaga),
    ]);
}
