import React from "react";
import { TRANSLATION_TEXT } from "../../../../utils/translations";
import { Title, Subtitle, Email } from "../../login.styles";
const EmailSent = ({ emailRecipient }) => {
	return (
		<>
			<Title>{TRANSLATION_TEXT.EMAIL_SENT_TEXT}</Title>
			{/* Dummy Email. Will be displayed correctly when the logic is implemented */}
			<Email>{emailRecipient}</Email>
			<Subtitle>
				{TRANSLATION_TEXT.EMAIL_SENT_MESSAGE}
			</Subtitle>
		</>
	);
};

export default EmailSent;
