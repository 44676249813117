import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { DropDownWrapper } from '../../../../pages/adTester/subpages/Overview/components/overview.styles';
import DropDownButton from './DropDownButton';
import SearchInput from '../../SearchInput/components/SearchInput';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import Checkbox from '../../Checkbox/Checkbox';
import RoundCheckbox from '../../RoundCheckbox/RoundCheckbox';
import { defaultTheme } from '../../../../utils/defaultTheme';
import PropTypes from 'prop-types';

const inputStyle = {
    textPadding: '7px',
    textColor: defaultTheme.blue[300],
    bgColor: defaultTheme.grey[50],
    fontSize: '15px',
    opacity: '0.4',
};
export const CountriesDropdown = props => {
    const { onApply, selectedCountries, countries, customStyle } = props;

    const [isMenuShowing, setMenuIsShowing] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');

    /* Save the countries temporarily here before they are applied */
    const [temporarySelectedCountries, setTemporarySelectedCountries] = useState(selectedCountries);
    const [localScopeCountries, setLocalScopeCountries] = useState(countries);

    const isAllSelected = temporarySelectedCountries.length === countries.length;

    const onSelectAll = () => {
        if (isAllSelected) {
            setTemporarySelectedCountries([]);
        } else {
            const allCountries = countries.map(c => c.countryId);
            setTemporarySelectedCountries(allCountries);
        }
    };

    const onCheckboxChange = c => {
        if (temporarySelectedCountries.includes(c.countryId)) {
            const updatedSelectedCountries = temporarySelectedCountries.filter(elem => elem !== c.countryId);
            setTemporarySelectedCountries(updatedSelectedCountries);
        } else {
            setTemporarySelectedCountries([...temporarySelectedCountries, c.countryId]);
        }
    };

    const filterCountriesFunction = useCallback(() => {
        const lowerCasedValue = searchInputValue.toLowerCase().trim();
        if (lowerCasedValue === '') return localScopeCountries;

        return localScopeCountries.filter(country => country.label.toLowerCase().includes(lowerCasedValue));
    }, [localScopeCountries, searchInputValue]);
    const countriesFilteredBySearch = useMemo(
        () => filterCountriesFunction(),
        [localScopeCountries, searchInputValue, temporarySelectedCountries]
    );

    useEffect(() => {
        setTemporarySelectedCountries(selectedCountries);
    }, [selectedCountries]);

    useEffect(() => {
        if (countries) {
            const countriesWithCheckedOptions = countries.map(filteredCountry => ({
                ...filteredCountry,
                checked: temporarySelectedCountries.includes(filteredCountry.countryId),
            }));
            setLocalScopeCountries(countriesWithCheckedOptions);
        }
    }, [countries, temporarySelectedCountries]);

    return (
        <DropDownWrapper
            customStyle={customStyle?._wrapper}
            onClick={() => !isMenuShowing && setMenuIsShowing(!isMenuShowing)}
            showDropDownMenu={isMenuShowing}
        >
            <DropDownButton
                onClick={() => setMenuIsShowing(!isMenuShowing)}
                btnText={TRANSLATION_TEXT.HEADER_TEXT}
                showDropDownMenu={isMenuShowing}
            />
            {isMenuShowing && (
                <>
                    <SearchInput
                        value={searchInputValue}
                        placeholder={TRANSLATION_TEXT.SEARCH_TEXT}
                        onChange={e => setSearchInputValue(e.target.value)}
                        customStyle={inputStyle}
                    />
                    <div className="countriesList">
                        <div className="selectAllSection">
                            <RoundCheckbox
                                textColor={defaultTheme.blue[950]}
                                label={TRANSLATION_TEXT.SELECT_ALL_TEXT}
                                onClick={onSelectAll}
                                value={isAllSelected}
                                padding="4px 0"
                                isSelectedBold={true}
                            />
                        </div>
                        {countriesFilteredBySearch.map(c => {
                            const isSelectedAndApplied = selectedCountries.includes(c.countryId);
                            return (
                                <React.Fragment key={`country-${c.countryId}`}>
                                    <Checkbox
                                        value={c.checked}
                                        label={c.label}
                                        labelFontWeight={isSelectedAndApplied ? 'bold' : 'normal'}
                                        checkedAndApplied={isSelectedAndApplied}
                                        isSelectedBold={isSelectedAndApplied}
                                        onClick={() => onCheckboxChange(c)}
                                        padding="6px 0px"
                                        textColor={defaultTheme.blue[950]}
                                        hoverColor={defaultTheme.grey[50]}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="apply-button" onClick={() => onApply(temporarySelectedCountries)}>
                        {TRANSLATION_TEXT.APPLY_TEXT}
                    </div>
                </>
            )}
        </DropDownWrapper>
    );
};
CountriesDropdown.propTypes = {
    onApply: PropTypes.func.isRequired,
    selectedCountries: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    customStyle: PropTypes.object,
};
export default CountriesDropdown;
