import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getAuthTokensSelector } from '../../../../login/data/selectors';
import { getAdComparisonData } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC, GET_AD_TESTER_AD_COMPARISON_DATA } from './actions';
import { getComputedFiltersParametersForRequest } from '../../../../../common/components/SideFilters/data/computedSelectors';

function* getAdComparisonSaga(action) {
    const tokens = yield select(getAuthTokensSelector);
    if (Object.keys(tokens).length > 0) {
        yield put(GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.dispatchers.started());
        try {
            const filtersAsRequestParameters = yield select(getComputedFiltersParametersForRequest);

            const adComparisonData = yield call(getAdComparisonData, {
                filtersList: filtersAsRequestParameters,
            });

            yield put(GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.dispatchers.succeeded(adComparisonData));
        } catch (error) {
            yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
            yield put(GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.dispatchers.failed(error));
        }
    }
}

export default function* adTesterOverviewSaga() {
    yield all([yield takeLatest(GET_AD_TESTER_AD_COMPARISON_DATA, getAdComparisonSaga)]);
}
