import {
    CACHE_LAST_APPLIED_TOP_FILTERS_OPTION,
    SET_SELECTED_TOP_FILTERS,
    SET_SELECTED_TOP_OPTION,
    SET_TEMPORARY_SELECTED_TOP_OPTION,
    SET_TOP_FILTER_DEPENDENCY_ARRAY,
} from './actions';
import { combineReducers } from 'redux';

const topFilterDependencyArrayReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_TOP_FILTER_DEPENDENCY_ARRAY:
            return action.payload;
        default:
            return state;
    }
};

const selectedTopFiltersByPageReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SELECTED_TOP_FILTERS:
            const { key, value } = action.payload;
            return { ...state, [key]: value };
        default:
            return state;
    }
};

const selectedOptionByPageReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SELECTED_TOP_OPTION:
            const { key, value } = action.payload;
            return { ...state, [key]: value };
        default:
            return state;
    }
};

const temporarySelectedOptionByPageReducer = (state = null, action) => {
    const data = action.payload;
    switch (action.type) {
        case SET_TEMPORARY_SELECTED_TOP_OPTION:
            return data;
        default:
            return state;
    }
};

export const lastAppliedTopFiltersOptionReducer = (state = null, action) => {
    switch (action.type) {
        case CACHE_LAST_APPLIED_TOP_FILTERS_OPTION:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    selectedTopFiltersByPage: selectedTopFiltersByPageReducer,
    selectedOptionByPage: selectedOptionByPageReducer,
    temporarySelectedOption: temporarySelectedOptionByPageReducer,
    dependencyArray: topFilterDependencyArrayReducer,
    lastAppliedTopFiltersOption: lastAppliedTopFiltersOptionReducer,
});
