import { useState, useCallback, useEffect } from 'react';
import { KPI_TYPES } from '../../../../../../common/utils/utils';

export const useCombinedLOBS = ({ sectionIDS, lobsAsOptions }) => {
    const [combinedChartLOBs, setCombinedChartLOBs] = useState(null);

    const onLOBCheckboxChange = useCallback(
        (key, value, sectionID) => {
            setCombinedChartLOBs({
                ...combinedChartLOBs,
                [sectionID]: {
                    ...combinedChartLOBs[sectionID],
                    [KPI_TYPES.COMBINED]: combinedChartLOBs[sectionID][KPI_TYPES.COMBINED].map(lob => {
                        if (lob.key === key) {
                            return {
                                ...lob,
                                isChecked: value,
                            };
                        }
                        return lob;
                    }),
                },
            });
        },
        [combinedChartLOBs]
    );

    const getVisibleLOBs = useCallback(
        sectionID =>
            combinedChartLOBs[sectionID]?.[KPI_TYPES.COMBINED]?.filter(lob => lob.isChecked).map(lob => lob.itemLabel),
        [combinedChartLOBs]
    );

    useEffect(() => {
        const lobsForCombinedChart = lobsAsOptions ? lobsAsOptions : null;
        setCombinedChartLOBs(lobsForCombinedChart);
    }, [lobsAsOptions, sectionIDS]);

    return [combinedChartLOBs, onLOBCheckboxChange, getVisibleLOBs];
};
