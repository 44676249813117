import React, { useCallback, useMemo, useState } from 'react';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../../../utils/translations';
import {
    STATE_KEY,
    BPT_DEPENDENT_OPTION_ID,
    isCountry,
    isDashboard,
    isBPTToggle,
    TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID,
    DEPENDENT_OPTIONS,
    composeAndReturnMessage,
    getAdditionalText,
} from './utils/utils';
import {
    Wrapper,
    FiltersSection,
    FilterInner,
    FiltersOuter,
    FilterTitle,
    FiltersValidation,
} from './ExternalUserType.styles';
import Checkbox from '../../../../../common/components/Checkbox/Checkbox';
import RoundCheckbox from '../../../../../common/components/RoundCheckbox/RoundCheckbox';
export default function useUserType(props) {
    const { state, setState, connectValidationFunction, disabled } = props;
    const [validationWarninig, setValidationWarning] = useState(false);
    const countries = useMemo(() => state[STATE_KEY]?.Permissions.filter(el => el.type === isCountry), [state]);
    const dashboards = useMemo(() => state[STATE_KEY]?.Permissions.filter(el => el.type === isDashboard), [state]);
    const bptToggle = useMemo(() => state[STATE_KEY]?.Permissions.filter(el => el.type === isBPTToggle), [state]);

    const onChange = useCallback(
        (id, type) => {
            let currentFilteringSection = state[STATE_KEY]?.Permissions.filter(el => el.type === type);
            let restOfFilteringSections = state[STATE_KEY]?.Permissions.filter(el => el.type !== type);
            currentFilteringSection = currentFilteringSection.map(el => {
                if (id === el.id) {
                    return { ...el, isChecked: !el.isChecked };
                }

                return { ...el };
            });

            //If dependent option is selected, we should uncheck its children
            if (id === BPT_DEPENDENT_OPTION_ID) {
                restOfFilteringSections = restOfFilteringSections.map(el => {
                    if (el.type === isBPTToggle) {
                        return {
                            ...el,
                            //If BPT is NOT already selected, set all of its children based on the checked status of transversal view
                            isChecked:
                                !isAnyDependentOptionSelected(dashboards, BPT_DEPENDENT_OPTION_ID) &&
                                isAnyDependentOptionSelected(dashboards, TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID),
                        };
                    }
                    return { ...el };
                });
            }
            //If this dependent option is selected, we should check all BPT togles
            if (id === TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID) {
                restOfFilteringSections = restOfFilteringSections.map(el => {
                    //If we had previously selected the current option, return without altering any other options
                    if (isAnyDependentOptionSelected(dashboards, TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID)) {
                        return { ...el };
                    } else {
                        //If we are currently selecting the Transversal View option, make sure we also select BPT
                        currentFilteringSection = currentFilteringSection.map(currentSection => {
                            if (currentSection.id === BPT_DEPENDENT_OPTION_ID) {
                                return { ...currentSection, isChecked: true };
                            }
                            return { ...currentSection };
                        });
                    }
                    //If the current selected option is the Transversal View, and we have BPT selected, make sure we select all of BPT-Country-Brand Period options
                    if (
                        el.type === isBPTToggle &&
                        isAnyDependentOptionSelected(currentFilteringSection, BPT_DEPENDENT_OPTION_ID)
                    ) {
                        return { ...el, isChecked: true };
                    }
                    return { ...el };
                });
            }

            setState({
                key: STATE_KEY,
                value: { ...state[STATE_KEY], Permissions: [...restOfFilteringSections, ...currentFilteringSection] },
            });
        },
        [state]
    );

    const onSelectAll = useCallback(
        type => {
            let currentFilteringSection = state[STATE_KEY]?.Permissions.filter(el => el.type === type);
            let restOfFilteringSections = state[STATE_KEY]?.Permissions.filter(el => el.type !== type);
            currentFilteringSection = currentFilteringSection.map(el => {
                if (areAllSelected(type)) {
                    return { ...el, isChecked: false };
                } else {
                    return { ...el, isChecked: true };
                }
            });
            //We also select all of the BPT dependent options, as Transversal View will be also selected
            restOfFilteringSections = restOfFilteringSections.map(el => {
                if (el.type === isBPTToggle) {
                    return { ...el, isChecked: true };
                }
                return { ...el };
            });

            setState({
                key: STATE_KEY,
                value: { ...state[STATE_KEY], Permissions: [...restOfFilteringSections, ...currentFilteringSection] },
            });
        },
        [state]
    );

    const areAllSelected = useCallback(
        type => state[STATE_KEY]?.Permissions.filter(el => el.type === type).every(el => el.isChecked),
        [state]
    );

    const isAnyDependentOptionSelected = (dependentOption, dependentOptionID) => {
        if (Array.isArray(dependentOptionID)) {
            return dependentOptionID.some(el => isAnyDependentOptionSelected(dependentOption, el));
        }
        return dependentOption?.find(el => el.id === dependentOptionID)?.isChecked;
    };

    const isSelectedDataValid = centralState => {
        const permissionsData = centralState[STATE_KEY]?.Permissions;
        const centralStateCountries = permissionsData.filter(el => el.type === isCountry);
        const centralStateDashboards = permissionsData.filter(el => el.type === isDashboard);
        const centralStateBPTToggle = permissionsData.filter(el => el.type === isBPTToggle);
        const isValid = centralStateCountries.find(e => e.isChecked) && centralStateDashboards.find(e => e.isChecked);

        if (isAnyDependentOptionSelected(centralStateDashboards, DEPENDENT_OPTIONS)) {
            let validData = !!centralStateBPTToggle.find(e => e.isChecked) && !!isValid; //Both should be true for the data to be considered valid;
            setValidationWarning(!validData);
            return validData;
        }

        setValidationWarning(!isValid);
        return !!isValid;
    };

    const getValidationWarningMessage = () => {
        if (countries.every(el => !el.isChecked)) {
            return composeAndReturnMessage(getAdditionalText(isCountry));
        }
        if (dashboards.every(el => !el.isChecked)) {
            return composeAndReturnMessage(getAdditionalText(isDashboard));
        }

        if (isAnyDependentOptionSelected(dashboards, DEPENDENT_OPTIONS) && bptToggle.every(e => !e.isChecked)) {
            return composeAndReturnMessage(getAdditionalText(isBPTToggle));
        }
    };
    const renderUserType = useCallback(() => {
        return (
            <Wrapper>
                <FiltersSection>
                    <FiltersOuter>
                        <FilterTitle>
                            <p>Country</p>
                        </FilterTitle>
                        <FilterInner>
                            <RoundCheckbox
                                disabled={disabled}
                                isSelectedBold={areAllSelected(isCountry)}
                                value={areAllSelected(isCountry)}
                                label={TRANSLATION_TEXT.SELECT_ALL_TEXT}
                                onClick={() => !disabled && onSelectAll(isCountry)}
                                padding="6px 0px"
                                textColor={disabled ? defaultTheme.blue[200] : defaultTheme.blue[950]}
                            />
                            {countries?.map(el => (
                                <Checkbox
                                    key={el.name}
                                    disabled={disabled}
                                    value={el.isChecked}
                                    label={el.name}
                                    isSelectedBold={el.isChecked}
                                    onClick={() => !disabled && onChange(el.id, isCountry)}
                                    padding="6px 0px"
                                    textColor={defaultTheme.blue[950]}
                                />
                            ))}
                        </FilterInner>
                    </FiltersOuter>
                    <FiltersOuter>
                        <FilterTitle>
                            <p>Dashboards</p>
                        </FilterTitle>
                        <FilterInner>
                            <RoundCheckbox
                                disabled={disabled}
                                isSelectedBold={areAllSelected(isDashboard)}
                                value={areAllSelected(isDashboard)}
                                label={TRANSLATION_TEXT.SELECT_ALL_TEXT}
                                onClick={() => !disabled && onSelectAll(isDashboard)}
                                padding="6px 0px"
                                textColor={disabled ? defaultTheme.blue[200] : defaultTheme.blue[950]}
                            />
                            {dashboards?.map(el => (
                                <Checkbox
                                    key={el.name}
                                    disabled={disabled}
                                    value={el.isChecked}
                                    label={el.name}
                                    isSelectedBold={el.isChecked}
                                    onClick={() => !disabled && onChange(el.id, isDashboard)}
                                    padding="6px 0px"
                                    textColor={defaultTheme.blue[950]}
                                />
                            ))}
                        </FilterInner>
                    </FiltersOuter>
                    <FiltersOuter>
                        <FilterTitle title={'Country-Brand-Period'}>
                            <p>BPT Country-Brand-Period</p>
                        </FilterTitle>
                        <FilterInner>
                            {isAnyDependentOptionSelected(dashboards, BPT_DEPENDENT_OPTION_ID) ? (
                                <>
                                    <RoundCheckbox
                                        disabled={disabled}
                                        isSelectedBold={areAllSelected(isBPTToggle)}
                                        value={areAllSelected(isBPTToggle)}
                                        label={TRANSLATION_TEXT.SELECT_ALL_TEXT}
                                        onClick={() => !disabled && onSelectAll(isBPTToggle)}
                                        padding="6px 0px"
                                        textColor={disabled ? defaultTheme.blue[200] : defaultTheme.blue[950]}
                                    />
                                    {bptToggle?.map(el => (
                                        <Checkbox
                                            key={el.name}
                                            disabled={disabled}
                                            value={el.isChecked}
                                            label={el.name}
                                            isSelectedBold={el.isChecked}
                                            onClick={() => !disabled && onChange(el.id, isBPTToggle)}
                                            padding="6px 0px"
                                            textColor={defaultTheme.blue[950]}
                                        />
                                    ))}{' '}
                                </>
                            ) : (
                                <p>{TRANSLATION_TEXT.DEPENDENT_OPTION_TEXT}</p>
                            )}
                        </FilterInner>
                    </FiltersOuter>
                </FiltersSection>
                <FiltersValidation>
                    {connectValidationFunction && validationWarninig && <p>{getValidationWarningMessage()}</p>}
                </FiltersValidation>
            </Wrapper>
        );
    }, [state, countries, disabled, dashboards, validationWarninig]);

    return { renderUserType, isSelectedDataValid };
}
