import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import {
    FILE_UPLOAD_TO_AWS,
    FILE_UPLOAD_TO_AWS_ASYNC,
    INIT_BACKOFFICE_TABLE_REQUESTS,
    INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC,
    RELOAD_BACKOFICE_TABLE_DATA,
    reloadTableDataActionCreator,
    TOGGLE_CONFIRMIT_PROJECT,
    TOGGLE_CONFIRMIT_PROJECT_ASYNC,
} from './actions';
import {
    addAsyncErrorActionCreator,
    setErrorNotificationDisplayActionCreator,
} from '../../../errorHandling/data/actions';
import { handleFileUploadToAWS, toggleConfirmitDataRequest } from '../../../services/apiServer.service';
import { TABLE_HANDLERS_MAP } from '../utils/utils';
import { getBackofficeTablesDataSelector } from './selectors';

function* handleFileUploadSaga(action) {
    try {
        const { file, url, type } = action.payload;

        yield put(FILE_UPLOAD_TO_AWS_ASYNC.dispatchers.started());

        yield call(handleFileUploadToAWS, { url, file, type });

        yield put(FILE_UPLOAD_TO_AWS_ASYNC.dispatchers.succeeded());
    } catch (error) {
        yield put(setErrorNotificationDisplayActionCreator(true));
        yield addAsyncErrorActionCreator({ error, retryAction: action });
        yield put(FILE_UPLOAD_TO_AWS_ASYNC.dispatchers.failed(error));
    }
}

function* backofficeRequestsOrchestrationSaga(action) {
    try {
        yield put(INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.dispatchers.started());
        const [metaDataFileEntries, dataFileEntries, confirmitPidEntries, cachingStatus] = yield all([
            call(TABLE_HANDLERS_MAP.METADATA_UPLOADS.REQUEST),
            call(TABLE_HANDLERS_MAP.DATA_UPLOADS.REQUEST),
            call(TABLE_HANDLERS_MAP.CONFIRMIT_UPLOADS.REQUEST),
            call(TABLE_HANDLERS_MAP.CACHING_STATUS.REQUEST),
        ]);
        const payloadObject = {
            metaDataFileEntries,
            dataFileEntries,
            confirmitPidEntries,
            cachingStatus,
        };
        yield put(INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.dispatchers.succeeded(payloadObject));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.dispatchers.failed(error));
    }
}

export function* backofficeDataReloadSaga(action) {
    const { KEY, REQUEST, ASYNC_ACTION } = action.payload;
    try {
        yield put(ASYNC_ACTION.dispatchers.started());
        const responseForTableData = yield call(REQUEST);
        const currentBackofficeData = yield select(getBackofficeTablesDataSelector);
        const payloadObject = {
            ...currentBackofficeData,
            [KEY]: responseForTableData,
        };
        yield put(ASYNC_ACTION.dispatchers.succeeded(payloadObject));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(ASYNC_ACTION.dispatchers.failed(error));
    }
}

function* toggleConfirmitProjectSaga(action) {
    try {
        yield put(TOGGLE_CONFIRMIT_PROJECT_ASYNC.dispatchers.started());
        yield call(toggleConfirmitDataRequest, action.payload);

        yield put(TOGGLE_CONFIRMIT_PROJECT_ASYNC.dispatchers.succeeded());
        yield put(reloadTableDataActionCreator(TABLE_HANDLERS_MAP.CONFIRMIT_UPLOADS));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(TOGGLE_CONFIRMIT_PROJECT_ASYNC.dispatchers.failed(error));
    }
}
export default function* loginPageSaga() {
    yield all([
        takeLatest(FILE_UPLOAD_TO_AWS, handleFileUploadSaga),
        takeEvery(INIT_BACKOFFICE_TABLE_REQUESTS, backofficeRequestsOrchestrationSaga),
        takeEvery(RELOAD_BACKOFICE_TABLE_DATA, backofficeDataReloadSaga),
        takeLatest(TOGGLE_CONFIRMIT_PROJECT, toggleConfirmitProjectSaga),
    ]);
}
