import styled from 'styled-components';
import { COUNTRY_GROUP_OVERALL_QUARTER_SIZE, COUNTRY_GROUP_OVERALL_YEAR_SIZE } from './CountryGroup';
import { DISPLAY_MODE } from '../../utils/utils';

/*
    Due to different layout requirements based on which part of the country's data we are showing, we need
    to have some adjustments to the grid structure and margins.
 */
const CountryGroupWrapper = styled.div`
    margin: ${({ displayMode }) => {
        return displayMode === DISPLAY_MODE.ALL ? '20px auto' : '20px 0px';
    }};
    display: grid;

    grid-template-rows: 40px 1fr;
    grid-template-columns: ${({ displayMode }) => {
        switch (displayMode) {
            case DISPLAY_MODE.RANKINGS:
                return '0px 0px 487px';
            case DISPLAY_MODE.CHARTS:
                return `${COUNTRY_GROUP_OVERALL_YEAR_SIZE}px ${COUNTRY_GROUP_OVERALL_QUARTER_SIZE}px 0px`;
            default:
                return `${COUNTRY_GROUP_OVERALL_YEAR_SIZE}px ${COUNTRY_GROUP_OVERALL_QUARTER_SIZE}px 290px`;
        }
    }};

    grid-gap: ${({ displayMode }) => {
        return displayMode === DISPLAY_MODE.RANKINGS ? '0px' : '5px 8px';
    }};
`;

const CountryHeader = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
        margin-left: 8px;
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.blue[900]};
    }

    & > img {
        box-shadow: 0px 0px 8px #00000029;
    }
`;

const OverallYearTitle = styled.div`
    color: ${({ theme }) => theme.blue[900]};
    font-size: 12px;
    margin-top: 5px;
    width: 100%;
    z-index: 0;

    &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        position: relative;
        top: 1px;
        background: ${({ theme }) => theme.blue[900]};
    }
`;

const BarChartStyleOverride = styled.div`
    div ~ svg .barTooltipDivWrapp:hover {
        position: relative;
        z-index: 100;
        background: blue;
    }
    div ~ svg foreignObject[class^='bar-tooltip-wrapper']:hover {
        position: relative;
        z-index: 100;
    }
`;

const RankingTableStyleOverride = styled.div`
    position: relative;

    .ranking-table-row {
        width: 100%;
        margin-left: 0;
        & > div:first-child {
            margin-right: 10px;
        }
    }

    .ranking-table-bar {
        justify-content: flex-end;
    }

    rect {
        transform: translate(-18px, 0);
    }
`;
const CombinedChartsWrapper = styled.div`
    display: flex;
`;
export {
    CountryGroupWrapper,
    CountryHeader,
    OverallYearTitle,
    RankingTableStyleOverride,
    BarChartStyleOverride,
    CombinedChartsWrapper,
};
