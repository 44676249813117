import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokenExpiredActionCreator } from '../../errorHandling/data/actions';
import { getTokens } from '../../pages/login/data/selector';
import { decodeAccessToken } from '../../utils/generalUtilities';
import { ROUTES_TO_USE_LOGGING, ROUTES_TO_WATCH_SESSION } from '../../utils/routes';
import { getLocationSelector } from '../components/TopFilter/data/selectors';
import { onUserInteractionAction } from './data/actions';

export function useUserWatcher() {
    const currentLocation = useSelector(getLocationSelector);
    const currentToken = useSelector(getTokens);
    const dispatch = useDispatch();

    const sessionController = () => {
        ROUTES_TO_WATCH_SESSION.forEach(eachRoute => {
            //Perform token decode only on specific routes
            if (eachRoute.PATH === currentLocation) {
                const { access_token } = currentToken;

                const decodedToken = decodeAccessToken(access_token);

                const currentTime = new Date().getTime();
                const tokenExpirationTime = decodedToken?.exp * 1000;
                const timeLeftUntilTokenExpires = (tokenExpirationTime - currentTime) / 60 / 1000;

                //If the token expiration time - the current time is less than 0 (it has reach the point of expiration)
                if (timeLeftUntilTokenExpires <= 0) {
                    dispatch(tokenExpiredActionCreator());
                }
            }
        });
    };

    const loggerController = async () => {
        for (const eachRoute of ROUTES_TO_USE_LOGGING) {
            //Dispatch logging action
            if (eachRoute.PATH === currentLocation) {
                dispatch(onUserInteractionAction(eachRoute.LOG_ID));
            }
        }
    };

    useEffect(() => {
        sessionController();
        loggerController();
    }, [currentLocation]);
}
