import styled from 'styled-components';

export const SectionsBannerWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
    margin: 3rem auto 1rem;
    box-sizing: border-box;
    padding: 0.5rem;
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    border-radius: 2px;
    div {
        //!important used to overwrite a style from the checkbox
        //Thus allowing a vertical centering of the elements
        margin: 0 !important;
    }
`;
