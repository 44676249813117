import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { DropDownWrapper } from '../../../../pages/adTester/subpages/Overview/components/overview.styles';
import DropDownButton from './DropDownButton';
import SearchInput from '../../SearchInput/components/SearchInput';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import RoundCheckbox from '../../RoundCheckbox/RoundCheckbox';
import { defaultTheme } from '../../../../utils/defaultTheme';
import PropTypes from 'prop-types';

const customStyle = {
    textPadding: '7px 7px 7px 2px',
    textColor: defaultTheme.blue[300],
    bgColor: defaultTheme.grey[50],
    fontSize: '15px',
    opacity: '0.4',
};
const SingleCountryDropdown = props => {
    const { onApply, selectedCountry, countries } = props;

    const [isMenuShowing, setMenuIsShowing] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');

    /* Save the countries temporarily here before they are applied */
    const [temporarySelectedCountry, setTemporarySelectedCountry] = useState(selectedCountry);
    const [localScopeCountries, setLocalScopeCountries] = useState(countries);

    const onCheckboxChange = c => {
        if (temporarySelectedCountry !== c.countryId) {
            setTemporarySelectedCountry(c.countryId);
        }
    };

    const filterCountriesFunction = useCallback(() => {
        const lowerCasedValue = searchInputValue.toLowerCase().trim();
        if (lowerCasedValue === '') return localScopeCountries;

        return localScopeCountries.filter(country => country.label.toLowerCase().includes(lowerCasedValue));
    }, [localScopeCountries, searchInputValue]);
    const countriesFilteredBySearch = useMemo(
        () => filterCountriesFunction(),
        [localScopeCountries, searchInputValue, temporarySelectedCountry]
    );

    useEffect(() => {
        setTemporarySelectedCountry(selectedCountry);
    }, [selectedCountry]);

    useEffect(() => {
        if (countries) {
            const countriesWithCheckedOptions = countries.map(filteredCountry => ({
                ...filteredCountry,
                checked: temporarySelectedCountry === filteredCountry.countryId,
            }));
            setLocalScopeCountries(countriesWithCheckedOptions);
        }
    }, [countries, temporarySelectedCountry]);

    return (
        <DropDownWrapper
            onClick={() => !isMenuShowing && setMenuIsShowing(!isMenuShowing)}
            showDropDownMenu={isMenuShowing}
        >
            <DropDownButton
                onClick={() => setMenuIsShowing(!isMenuShowing)}
                btnText={TRANSLATION_TEXT.HEADER_TEXT}
                showDropDownMenu={isMenuShowing}
            />
            {isMenuShowing && (
                <>
                    <SearchInput
                        value={searchInputValue}
                        placeholder={TRANSLATION_TEXT.SEARCH_TEXT}
                        onChange={e => setSearchInputValue(e.target.value)}
                        customStyle={customStyle}
                    />
                    <div className="countriesList">
                        {countriesFilteredBySearch.map(c => {
                            const isSelectedAndApplied = selectedCountry === c.countryId;
                            return (
                                <div key={c.countryId + c.label + 'wrapper'}>
                                    <RoundCheckbox
                                        key={c.countryId + c.label}
                                        value={c.checked}
                                        label={c.label}
                                        labelFontWeight={isSelectedAndApplied ? 'bold' : 'normal'}
                                        checkedAndApplied={isSelectedAndApplied}
                                        isSelectedBold={isSelectedAndApplied}
                                        onClick={() => onCheckboxChange(c)}
                                        padding="6px 0px"
                                        textColor={defaultTheme.blue[950]}
                                        hoverColor={defaultTheme.grey[50]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="apply-button" onClick={() => onApply(temporarySelectedCountry)}>
                        {TRANSLATION_TEXT.APPLY_TEXT}
                    </div>
                </>
            )}
        </DropDownWrapper>
    );
};

SingleCountryDropdown.propTypes = {
    onApply: PropTypes.func.isRequired,
    selectedCountry: PropTypes.number.isRequired,
    countries: PropTypes.array.isRequired,
};

export default SingleCountryDropdown;
