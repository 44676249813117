import React from 'react';
import {
    ErrorCloseButton,
    ErrorIcon,
    ErrorNotificationBar,
    ErrorNotificationWrapper,
    ErrorTextWrapper,
} from './ErrorNotification.styles';
import { connect } from 'react-redux';
import { setErrorNotificationDisplayActionCreator } from '../data/actions';
import { isErrorDisplayingSelector } from '../data/selectors';
import { TRANSLATION_TEXT } from '../../utils/translations';

export const ErrorNotification = ({ showError, onClose }) => {
    return (
        <ErrorNotificationWrapper show={showError}>
            <ErrorNotificationBar />
            <ErrorIcon>&#9888;</ErrorIcon>
            <ErrorTextWrapper>
                <h4>{TRANSLATION_TEXT.ERROR_NOTIFICATION_MAIN_MESSAGE}</h4>
                <p>{TRANSLATION_TEXT.ERROR_NOTIFICATION_SECONDARY_MESSAGE}</p>
            </ErrorTextWrapper>
            <ErrorCloseButton onClick={onClose}>&#x2716;</ErrorCloseButton>
        </ErrorNotificationWrapper>
    );
};

const mapStateToProps = state => ({
    showError: isErrorDisplayingSelector(state),
});

const mapDispatchToProps = {
    onClose: () => setErrorNotificationDisplayActionCreator(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotification);
