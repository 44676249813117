import { createSelector } from 'reselect';
import { INPUT_TYPES } from '../utils/sideFilters.config';
import {
    getAppliedSideFiltersSelector,
    getSelectedSideFiltersSelector,
    getCurrentFilterPageSelector,
    getSideFiltersSelector,
    getTopFiltersSelector,
} from './selectors';
import {
    getCachedLastAppliedTopFiltersOptionSelector,
    getLocationSelector,
    getSelectedOptionByPageSelector,
} from '../../TopFilter/data/selectors';
import { hasValidAdsComparisonSelectedOptionsComputedSelector } from '../../../../pages/adTester/subpages/AdsComparison/data/computedSelectors';
import { getParentPathOnPagesWithSubTabs } from '../../TopFilter/utils/utils';
import { FILTERS_CONFIG } from '../../../utils/utils';
import { TOP_FILTERS_OPTION_BE_KEY } from '../../TopFilter/utils/constants';

export const getComputedFiltersParametersForRequest = createSelector(
    [
        getTopFiltersSelector,
        getCachedLastAppliedTopFiltersOptionSelector,
        getAppliedSideFiltersSelector,
        getCurrentFilterPageSelector,
        getSelectedOptionByPageSelector,
        getLocationSelector,
        getSideFiltersSelector,
    ],
    (topFilters, cachedSelectedOption, filters, currentPage, selectedOptionsByPage, location, sideFilters) => {
        try {
            const filtersToProcess = filters[currentPage];
            let filtersList = [];

            let selectedTopFiltersOption;

            selectedTopFiltersOption = selectedOptionsByPage[location];

            const topFilterGroupId = topFilters?.filter(el =>
                el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
            )[0]?.id;

            /*If current state of the top filters do represent a set of filters with an option selected
                retrieve last working filtres from cache */
            if (!selectedTopFiltersOption.length) {
                const cachedOptions = cachedSelectedOption[location]?.filter(el =>
                    el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
                );
                selectedTopFiltersOption = cachedOptions;
            }

            /* We need to select all the sliders from the side filters data and remove any parameters that have their default values the same */
            let sliders = [];
            Object.keys(sideFilters).forEach(key => {
                const group = sideFilters[key];

                Object.keys(group).forEach(subGroupId => {
                    const subgroup = group[subGroupId];

                    const { id, options, selectType } = subgroup;
                    if (selectType === 'slider') {
                        sliders.push({
                            ID: id,
                            List: [+options[0].label, +options[1].label],
                        });
                    }
                });
            });

            /* Add parameters from side filters */
            if (filtersToProcess) {
                Object.keys(filtersToProcess).forEach(key => {
                    const data = filtersToProcess[key];
                    Object.keys(data).forEach(idKey => {
                        const list = data[idKey];

                        if (list.length > 0) {
                            filtersList.push({
                                ID: idKey,
                                List: data[idKey],
                            });
                        }
                    });
                });
            }
            /* Add parameters from top filters */
            if (selectedTopFiltersOption) {
                let ids = [];
                let groupId = null;

                selectedTopFiltersOption.forEach(el => {
                    ids.push(el.id);
                    if (!groupId) {
                        groupId = topFilterGroupId;
                    }
                });

                filtersList.push({ ID: '' + groupId, List: ids });
            }

            /* Remove all parameters that are sliders and their values are default */
            filtersList = filtersList.filter(element => {
                let hasNoDefaultValues = true;

                const { ID, List } = element;
                sliders.forEach(slider => {
                    const sliderID = slider.ID;
                    const [min, max] = slider.List;

                    if (+sliderID === +ID && List[0] === min && List[1] === max) {
                        hasNoDefaultValues = false;
                    }
                });

                return hasNoDefaultValues;
            });

            return filtersList;
        } catch (error) {}
    }
);

export const getApplyButtonStateComputedSelector = createSelector(
    [
        getSelectedSideFiltersSelector,
        getAppliedSideFiltersSelector,
        getCurrentFilterPageSelector,
        getLocationSelector,
        hasValidAdsComparisonSelectedOptionsComputedSelector,
    ],
    (selectedFilters, appliedFilters, currentPage, currentLocation, hasValidAdsComparisonOptions) => {
        let appliedButtonDisabled = true;
        const selectedPageFilters = selectedFilters[currentPage];
        const currentLocationPath = getParentPathOnPagesWithSubTabs(currentLocation);
        // Iterate through each main filter group on the current page (eg: Profile)
        for (const groupName in selectedPageFilters) {
            if (Object.hasOwnProperty.call(selectedPageFilters, groupName)) {
                const groupFilters = selectedPageFilters[groupName];

                // Iterate through each individual filter and set the correct disabled state for the apply button
                for (const filterId in groupFilters) {
                    if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                        const filter = groupFilters[filterId];
                        const appliedFilter = appliedFilters[currentPage]?.[groupName]?.[filterId];
                        if (filter?.selected.length === appliedFilter?.length) {
                            filter.selected.forEach(selectedElement => {
                                const isFound = !!appliedFilter?.find(
                                    appliedElement => appliedElement === selectedElement
                                );
                                if (!isFound) {
                                    appliedButtonDisabled = false;
                                }
                            });
                        } else {
                            appliedButtonDisabled = false;
                        }
                    }
                }
            }
        }
        if (
            !appliedButtonDisabled &&
            !hasValidAdsComparisonOptions &&
            FILTERS_CONFIG[currentLocationPath]?.hasOptionsValidation
        ) {
            appliedButtonDisabled = true;
        }
        return appliedButtonDisabled;
    }
);

export const getClearButtonStateComputedSelector = createSelector(
    [
        getSelectedSideFiltersSelector,
        getSideFiltersSelector,
        getCurrentFilterPageSelector,
        getLocationSelector,
        hasValidAdsComparisonSelectedOptionsComputedSelector,
    ],
    (selectedSideFilters, sideFilters, currentPage, currentLocation, hasValidAdsComparisonOptions) => {
        let clearButtonDisabled = true;
        const selectedPageFilters = selectedSideFilters[currentPage];
        const currentLocationPath = getParentPathOnPagesWithSubTabs(currentLocation);
        // Iterate through each main filter group on the current page (eg: Profile)
        for (const groupName in selectedPageFilters) {
            if (Object.hasOwnProperty.call(selectedPageFilters, groupName)) {
                const groupFilters = selectedPageFilters[groupName];

                // Iterate through each individual filter and set the correct disabled state for the clear button
                for (const filterId in groupFilters) {
                    if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                        const filter = groupFilters[filterId];

                        // validate if the slider values changed by comparing old values with the default ones
                        if (
                            sideFilters[groupName] &&
                            sideFilters[groupName][filterId].selectType === INPUT_TYPES.SLIDER
                        ) {
                            const minSelected = filter.selected[0];
                            const maxSelected = filter.selected[1];
                            const minDefault = +sideFilters[groupName][filterId].options[0].label;
                            const maxDefault = +sideFilters[groupName][filterId].options[1].label;
                            if (minSelected !== minDefault || maxSelected !== maxDefault) {
                                clearButtonDisabled = false;
                            }
                        } else {
                            //validate the checkbox values changed from the default state
                            if (filter.selected.length > 0) {
                                clearButtonDisabled = false;
                            }
                        }
                    }
                }
            }
        }
        if (
            !clearButtonDisabled &&
            !hasValidAdsComparisonOptions &&
            FILTERS_CONFIG[currentLocationPath]?.hasOptionsValidation
        ) {
            clearButtonDisabled = true;
        }
        return clearButtonDisabled;
    }
);
