import React from 'react';
import { SearchInputWrapper, SearchInputIcon, Input } from './SearchInput.styles';
import PropTypes from 'prop-types';
import { basePath } from '../../../../services/utils';
const SearchInput = props => {
    const {
        value,
        placeholder,
        onChange,
        isActive,
        onFocus,
        onBlur,
        onClick,
        onKeyUp,
        customStyle,
        hasBorder,
        isForTable,
        className,
    } = props;

    return (
        <SearchInputWrapper className={className} isActive={isActive} hasBorder={hasBorder} {...customStyle}>
            <Input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                value={value ?? ''}
                onChange={e => (isForTable ? onChange(e.target.value) : onChange(e))}
                {...customStyle}
            />
            <SearchInputIcon onClick={onClick} isForTable={isForTable}>
                <img src={`${basePath}/visuals/searchIconSmall.png`} alt={'Search Icon'} />
            </SearchInputIcon>
        </SearchInputWrapper>
    );
};
SearchInput.prototypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    customStyle: PropTypes.object,
    hasBorder: PropTypes.bool,
    isForTable: PropTypes.bool,
};
export default SearchInput;
