import styled from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../utils/responsiveness';

const PageWrapper = styled.div`
    margin: 0 auto;
    padding: 32px 0px;
    width: 100%;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    opacity: 1;
    position: relative;
    background: ${({ theme }) => theme.white.ff};
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        min-width: auto;
        width: auto;
        max-width: 100%;
    }
`;

const PageMainWrapper = styled.div`
    min-height: calc(100vh - 252px);
`;

export { PageWrapper, PageMainWrapper };
