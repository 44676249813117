import React from 'react';
import { NonSorted, SortedAsc, SortedDesc } from '../../../../backOffice/backOffice.styles';
import { DataTable } from '@toluna-ui-toolkit/data-table';
import { OverviewTableWrapper } from '../../Overview.styles';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import HeaderTooltip from '../../../../../common/components/HeaderTooltip/HeaderTooltip';
import { addTableHeaderCustomSortFunctions } from '../../../../../utils/generalUtilities';

const FieldworkOverviewTable = ({ data = [], tableScope, tableHead }) => {
    const TABLE_STYLE = {
        tableHead: {
            backgroundColor: 'null',
            borderBottom: `2px solid ${defaultTheme.blue[750]}`,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            color: defaultTheme.blue[750],
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',
        },
        bodyRow: {
            activeColor: null,
            backgroundColor: defaultTheme.white.ff,
            backgroundColorSecondary: defaultTheme.grey[50],
            hoverColor: defaultTheme.blue[50],
        },
        column: { separatorColor: 'none' },
        global: {
            fontFamily: 'Source Sans Pro,Open Sans',
        },
    };

    /* Based on ReactTable specs, this needs to be memoized */
    const tableColumnsSpecs = React.useMemo(() => {
        let headerData = [];
        tableHead.forEach(th => {
            const headerObject = {
                ...th,
                Header: () =>
                    th.tooltip ? <HeaderTooltip tooltip={th.tooltip}>{th.Header}</HeaderTooltip> : th.Header,
            };
            const decoratedHeader = addTableHeaderCustomSortFunctions(th, headerObject);
            headerData.push({ ...decoratedHeader });
        });
        return headerData;
    }, [data]);

    return (
        <OverviewTableWrapper hasBorderOnColumn={tableScope !== 'fieldwork_bpt'} data-testid="OverviewTableWrapper">
            <DataTable
                styles={TABLE_STYLE}
                isPaginated={false}
                data={data}
                isScrollable={false}
                columns={tableColumnsSpecs}
                NonSortedArrow={NonSorted}
                SortedAscArrow={SortedAsc}
                SortedDescArrow={SortedDesc}
            />
        </OverviewTableWrapper>
    );
};

export default FieldworkOverviewTable;
