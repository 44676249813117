import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA =
    '/pages/adTester/subpages/overview/GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA';
export const GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC = asyncActionCreator(
    '/pages/adTester/subpages/overview/GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC'
);

export const getAdDetailsAndMetricsDataActionCreator = () => ({
    type: GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA,
});
