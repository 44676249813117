export default {
    //Add any API that you want to overwrite on success or error.
    //Static text, will be any text you desire to show in the toaster, on success or on error - fromBackendProperty has priority !
    //fromBackendProperty - will get the message from the provided property. You can access any nested property that the messages is in. -
    //fromBackendProperty - If this property fails, staticText will be displayed. If there is no static text, default built in message will appear.
    apiResponseModifiers: [
        {
            apiName: 'createAndSendInvitation',
            _success: {
                staticText: null,
                fromBackendProperty: null,
            },
            _error: {
                staticText: null, //To be removed when PR is approved.
                fromBackendProperty: 'response.data', //Please update this when BE makes changes - ex: response.data.message or response.data.title or anything that BE provides...
            },
        },
        {
            apiName: 'createUser',
            _success: {
                staticText: null,
                fromBackendProperty: null,
            },
            _error: {
                staticText: null,
                fromBackendProperty: 'response.data', //same as above
            },
        },
    ],
};
