import React, { useState, useEffect } from 'react';
import SideFilters from '../../../../common/components/SideFilters/components/SideFilters.container';
import {
    SIDE_FILTERS_SUB_PAGES,
    SIDE_FILTERS_PAGES,
} from '../../../../common/components/SideFilters/utils/sideFilters.config';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import { Wrapper } from '../AdDetailsMetrics/AdDetailsMetrics.styles';
import DataSectionHeader from '../../../../common/components/DataSectionHeader/DataSectionHeader';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import CopyRating from './components/CopyRating/CopyRating';
import CampaignDetails from './components/CampaignDetails/CampaignDetails';
import TopFilterGroup from '../../../../common/components/TopFilter/components/TopFilterGroup.container';
import { useFilters } from '../../../../common/hooks/useFilters';
import ComparisonCampaignDiagnostics from './components/CampaignDiagnostic/ComparisonCampaignDiagnostics';
import SelectedFilters from '../../../../common/components/SelectedFilters/components/SelectedFilters.container';
import UpliftsSection from '../AdDetailsMetrics/components/UpliftsSection/UpliftsSection';
import NoDataAvailable from '../../../../common/components/NoDataAvailable/NoDataAvailable';
import { DISPLAY_BUTTON_POSITION } from '../../../../common/components/TopFilter/utils/constants';
import { ADTESTER_PAGE } from '../../../../utils/routes';

const AdsComparison = ({
    getData,
    hasSideFiltersLoaded,
    hasValidOptionsSelected,
    filtersThrownError,
    data,
    filtersFor,
}) => {
    const {
        combinedChartData,
        combinedChartsLegendData,
        exportDisabled,
        adTesterData,
        campaignDiagnosticsData,
        upliftsData,
    } = data;

    const [dataRetrieved, setDataRetrieved] = useState(false);
    useFilters();
    useEffect(() => {
        /* Data will be retrieved automatically only once on page load */
        if (
            hasSideFiltersLoaded &&
            hasValidOptionsSelected &&
            filtersFor.includes(ADTESTER_PAGE.path) &&
            !dataRetrieved
        ) {
            getData();
            setDataRetrieved(true);
        }
    }, [hasSideFiltersLoaded, dataRetrieved]);

    return (
        <Wrapper>
            <SideFilters
                currentSubPage={SIDE_FILTERS_SUB_PAGES.adTesterComparison}
                currentPage={SIDE_FILTERS_PAGES.adTester}
                showSideBarFilters={!filtersThrownError}
                isSingleSectionSelection={true}
            />
            {!filtersThrownError ? (
                <>
                    <SelectedFilters />
                    <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                        <ExportButton exportDisabled={exportDisabled} type={EXPORT_TYPES.EXCEL} />
                        <ExportButton exportDisabled={exportDisabled} type={EXPORT_TYPES.PPT} />
                    </ExportButtonContainer>
                    <TopFilterGroup
                        displayButtonPosition={DISPLAY_BUTTON_POSITION.BOTTOM}
                        displayButtonInfo={TRANSLATION_TEXT.DISPLAY_BUTTON_ADDITIONAL_INFO_AD_COMPARISON}
                        reloadFiltersOnSubmit={true}
                    />
                    {adTesterData && (
                        <DataSectionHeader title={TRANSLATION_TEXT.CAMPAIGN_DETAILS_TITLE_TEXT}>
                            <CampaignDetails campaignDetailsData={adTesterData} />
                        </DataSectionHeader>
                    )}

                    {campaignDiagnosticsData && (
                        <DataSectionHeader title={TRANSLATION_TEXT.CAMPAIGN_DIAGNOSTICS}>
                            <ComparisonCampaignDiagnostics kpis={campaignDiagnosticsData.kpis} />
                        </DataSectionHeader>
                    )}

                    {combinedChartData && combinedChartData.lineData && combinedChartData.barData && (
                        <DataSectionHeader title={TRANSLATION_TEXT.COPY_RATING_EMOTIONS_TITLE_TEXT}>
                            <CopyRating
                                lineChartData={combinedChartData.lineData}
                                barChartData={combinedChartData.barData}
                                combinedChartLegendData={combinedChartsLegendData}
                            />
                        </DataSectionHeader>
                    )}
                    {upliftsData && (
                        <DataSectionHeader title={upliftsData?.sectionData.sectionLabel}>
                            {/* Enable a small compacted mode when we have more data to compare to avoid a scrollbar on desktop */}
                            <UpliftsSection
                                upliftsData={upliftsData}
                                smallSizeMode={upliftsData.restOfColumns.length > 3}
                                withLegendPerColumn={true}
                            />
                        </DataSectionHeader>
                    )}
                </>
            ) : (
                <NoDataAvailable show={filtersThrownError} />
            )}
            <NoDataAvailable
                show={!hasValidOptionsSelected && !adTesterData}
                text={TRANSLATION_TEXT.MINIMUM_OPTIONS_SELECTED}
            />
            <NoDataAvailable
                show={!adTesterData && hasValidOptionsSelected}
                text={TRANSLATION_TEXT.OPTIONS_WERE_SELECTED}
            />
        </Wrapper>
    );
};

export default AdsComparison;
