import { basePath } from '../../../../../services/utils';

export default {
    'has-search': true,
    'has-additional-filters-button': true,
    'has-reset-filters-buttons': true,
    'has-letters': true,
    'has-user-access-buttons': {
        'create-user': true,
        'delete-user': true,
        'send-invite': true,
        export: false,
    },
    icons: {
        resetPassword: `${basePath}/visuals/resetPass.png`,
        search: `${basePath}/visuals/searchComponent.png`,
        additionalFilters: `${basePath}/visuals/Filter.png`,
        createUser: `${basePath}/visuals/Create.png`,
        deleteUser: `${basePath}/visuals/DeleteUser.png`,
        sendInvite: `${basePath}/visuals/Send%20invite.png`,
    },
    userProfileActionButtonsToggle: true,
};
