import { CLEAR_EXPORT_LINK, GET_EXPORT_LINK_ASYNC } from './actions';

export const exportLinkReducer = (state = null, action) => {
    switch (action.type) {
        case GET_EXPORT_LINK_ASYNC.actions.SUCCEEDED:
            return action.payload;
        case CLEAR_EXPORT_LINK:
            return null;
        default:
            return state;
    }
};
