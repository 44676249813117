import React, { useState } from 'react';
import {
    DisplayButton,
    DisplayButtonAdditionalInfo,
    DisplayButtonWrapper,
    FilterCardWrapper,
    TopFilterHeaderText,
    TopFilterOptionsWrapper,
    TransparentBottomGradient,
} from './TopFilter.styles';
import CheckBox from '../../../components/Checkbox/Checkbox';
import RoundCheckBox from '../../../components/RoundCheckbox/RoundCheckbox';
import { defaultTheme } from '../../../../utils/defaultTheme';
import { PrimaryButton } from '../../SideFilters/components/SideFilters.styles';
import SearchInput from '../../SearchInput/components/SearchInput';
import LoadingShimmer from './LoadingShimmer';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import PropTypes from 'prop-types';
import { TOP_FILTER_CARD_SIZE } from '../utils/constants';

const TopFilter = ({
    filterData,
    isAllSelected,
    onChange,
    onSelectAll,
    hasApplyButton,
    onApply,
    isDisplayButtonDisabled,
    isLoadingTopFilter,
    displayButtonPosition,
    cardSize,
    searchBarDisabled = false,
    displayButtonInfo,
}) => {
    const [isSearchFieldActive, setIsSearchFieldActive] = useState(false);
    const [searchFieldValue, setSearchFieldValue] = useState('');

    const groupId = filterData.id;

    const filteredData = filterData.options.filter(
        e => searchFieldValue === '' || e.label.toLowerCase().includes(searchFieldValue.toLowerCase())
    );

    const customStyle = {
        textPadding: '10px',
        fontSize: '15px',
        bgColor: defaultTheme.blue[60],
        textColor: defaultTheme.blue[950],
        fontWeight: 'normal',
        padding: '13px',
        height: cardSize === TOP_FILTER_CARD_SIZE.SMALL ? '64px' : null,
    };
    return (
        <>
            {isLoadingTopFilter ? (
                <LoadingShimmer />
            ) : (
                <FilterCardWrapper size={cardSize} data-cy="CY-FilterCardWrapper">
                    {searchBarDisabled ? (
                        <TopFilterHeaderText>{filterData.label}</TopFilterHeaderText>
                    ) : (
                        <SearchInput
                            isActive={isSearchFieldActive}
                            onFocus={() => setIsSearchFieldActive(true)}
                            onBlur={() => setIsSearchFieldActive(false)}
                            placeholder={`${TRANSLATION_TEXT.SEARCH_BY_TEXT + ' ' + filterData.label}`}
                            value={searchFieldValue}
                            onChange={e => setSearchFieldValue(e.target.value)}
                            hasBorder={true}
                            customStyle={customStyle}
                        />
                    )}

                    <TopFilterOptionsWrapper size={cardSize}>
                        {filterData.selectType === 'multi' && !hasApplyButton && (
                            <div style={{ margin: '0px 0px 3px 4px' }}>
                                <RoundCheckBox
                                    textColor={defaultTheme.blue[950]}
                                    label={TRANSLATION_TEXT.SELECT_ALL_TEXT}
                                    onClick={() => onSelectAll({ isChecked: !isAllSelected, group: filterData.group })}
                                    value={isAllSelected}
                                    padding="4px 0"
                                    isSelectedBold={true}
                                />
                            </div>
                        )}

                        {filteredData.map((e, i) => (
                            <div
                                key={e.id + e.label + i}
                                style={{ margin: '0px 0px 3px 4px' }}
                                data-cy="CY-FiltersOptions"
                            >
                                {filterData.selectType === 'single' ? (
                                    <RoundCheckBox
                                        textColor={defaultTheme.blue[950]}
                                        label={e.label}
                                        onClick={() =>
                                            onChange({
                                                groupId: groupId,
                                                id: e.id,
                                                group: filterData.group,
                                                selectType: filterData.selectType,
                                            })
                                        }
                                        value={e.isChecked}
                                        padding="4px 0"
                                        isSelectedBold={true}
                                    />
                                ) : (
                                    <CheckBox
                                        textColor={defaultTheme.blue[950]}
                                        label={e.label}
                                        onClick={() =>
                                            onChange({
                                                groupId: groupId,
                                                id: e.id,
                                                group: filterData.group,
                                                selectType: filterData.selectType,
                                            })
                                        }
                                        value={e.isChecked}
                                        padding="4px 0"
                                        isSelectedBold={true}
                                    />
                                )}
                            </div>
                        ))}
                    </TopFilterOptionsWrapper>
                    <TransparentBottomGradient />

                    {hasApplyButton && (
                        <DisplayButtonWrapper>
                            {/*Apply a different position for the display button on smaller card size*/}
                            <DisplayButton position={displayButtonPosition}>
                                <PrimaryButton
                                    data-cy="CY-FilterDisplayButton"
                                    disabled={isDisplayButtonDisabled}
                                    onClick={onApply}
                                >
                                    Display
                                </PrimaryButton>
                            </DisplayButton>
                            {displayButtonInfo && (
                                <DisplayButtonAdditionalInfo>{displayButtonInfo}</DisplayButtonAdditionalInfo>
                            )}
                        </DisplayButtonWrapper>
                    )}
                </FilterCardWrapper>
            )}
        </>
    );
};
TopFilter.propTypes = {
    filterData: PropTypes.object.isRequired,
    isAllSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    hasApplyButton: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    isLoadingTopFilter: PropTypes.bool.isRequired,
    searchBarDisabled: PropTypes.bool,
    cardSize: PropTypes.oneOf([TOP_FILTER_CARD_SIZE.SMALL, TOP_FILTER_CARD_SIZE.LARGE]),
    displayButtonInfo: PropTypes.string,
};

TopFilter.defaultProps = {
    cardSize: TOP_FILTER_CARD_SIZE.LARGE,
    displayButtonInfo: null,
};
export default TopFilter;
