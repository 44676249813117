import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/file';
import {
    CampaignDetailsInfoBox,
    CampaignDetailsInfoWrapper,
    CampaignDetailsVideoWrapper,
    CampaignDetailsWrapper,
} from '../../AdDetailsMetrics.styles';
import { getAdTesterCampaignDetailsSelector } from '../../data/selectors';
import { getSlashSeparatedDate } from '../../../../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import { basePath } from '../../../../../../services/utils';

export const CampaignDetails = ({ data }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.showPreview();
        }
    }, [data]);

    return data ? (
        <CampaignDetailsWrapper>
            <CampaignDetailsVideoWrapper>
                {data.videoLink ? (
                    <ReactPlayer
                        data-testid="CampaignDetails-video"
                        ref={videoRef}
                        width="500px"
                        light={data.imageLink}
                        url={data.videoLink}
                        playing
                        controls
                    />
                ) : (
                    <img
                        alt={'Video Unavailable Warning'}
                        src={`${basePath}/visuals/VideoUnavailable.png`}
                        style={{ width: '500px' }}
                    />
                )}
            </CampaignDetailsVideoWrapper>
            <CampaignDetailsInfoWrapper>
                <h2>{data.label}</h2>
                <CampaignDetailsInfoBox>
                    <div>
                        <h3>{TRANSLATION_TEXT.COPY_INFO_TEXT}</h3>
                        <p>
                            {TRANSLATION_TEXT.COUNTRY_TEXT}: {data.country}
                        </p>
                        <p>
                            {TRANSLATION_TEXT.COPY_NAME_TEXT}: {data.copyName}
                        </p>
                        <p>
                            {TRANSLATION_TEXT.TV_GRP_TEXT}: {data.grp}
                        </p>
                        <p>
                            {TRANSLATION_TEXT.TV_CAMPAIGN_DATES}:{' '}
                            {getSlashSeparatedDate(new Date(data.campaignStartDate))} {TRANSLATION_TEXT.TO_TEXT}{' '}
                            {getSlashSeparatedDate(new Date(data.campaignEndDate))}
                        </p>
                        <p>
                            {TRANSLATION_TEXT.AIRING_TEXT}: {data.airingNr}
                        </p>
                    </div>
                    <div>
                        <h3>{TRANSLATION_TEXT.BPT_INFO_TEXT}</h3>
                        <p>
                            {TRANSLATION_TEXT.NO_INTERVIEWS_TEXT}: {data.nrInterviews}
                        </p>
                        <p>
                            {TRANSLATION_TEXT.BPT_DATES_TEXT}: {getSlashSeparatedDate(new Date(data.bptStartDate))}{' '}
                            {TRANSLATION_TEXT.TO_TEXT} {getSlashSeparatedDate(new Date(data.bptEndDate))}
                        </p>
                    </div>
                </CampaignDetailsInfoBox>
            </CampaignDetailsInfoWrapper>
        </CampaignDetailsWrapper>
    ) : null;
};

const mapStateToProps = state => ({
    data: getAdTesterCampaignDetailsSelector(state),
});

export default connect(mapStateToProps, null)(CampaignDetails);
