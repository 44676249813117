import React from 'react';
import * as SC from './CountryTrend.styles';
import PropTypes from 'prop-types';
import { defaultTheme } from '../../../../utils/defaultTheme';
import { basePath } from '../../../../services/utils';
import { deepEqual } from '../../../../utils/generalUtilities';

const countryTrendSet = [
    { start: -999999, end: -1, icon: 'Red-arrow-transversal.svg', color: defaultTheme.red[70] },
    { start: -1, end: -1, icon: 'Red-arrow-transversal.svg', color: defaultTheme.red[70] },
    { start: -1, end: 0, icon: 'Grey-arrow-2.svg', color: defaultTheme.grey[50] },
    { start: 0, end: 0, icon: 'Grey-arrow-3.svg', color: defaultTheme.grey[50] },
    { start: 0, end: 1, icon: 'Grey-arrow-1.svg', color: defaultTheme.grey[50] },
    { start: 1, end: 1, icon: 'Green-arrow-transversal.svg', color: defaultTheme.green[50] },
    { start: 1, end: 999999, icon: 'Green-arrow-transversal.svg', color: defaultTheme.green[50] },
];

function CountryTrend({ title, trendValue, customStyle }) {
    const getTrendUIBasedOnValue = () => {
        return countryTrendSet.find(elem => {
            if (elem.start === elem.end) {
                return trendValue === elem.start;
            } else if (elem.start < trendValue && trendValue < elem.end) {
                return true;
            }
            return false;
        });
    };

    const selectedTrend = getTrendUIBasedOnValue(trendValue);

    return (
        <SC.TrendWrapper customStyle={customStyle} style={{ background: selectedTrend?.color }}>
            <span>{title + ' ' + trendValue}</span>
            <img src={`${basePath}/visuals/${selectedTrend.icon}`} alt="Trend Arrow" />
        </SC.TrendWrapper>
    );
}

CountryTrend.propTypes = {
    title: PropTypes.string.isRequired,
    trendValue: PropTypes.number.isRequired,
    customStyle: PropTypes.object,
};

export default React.memo(CountryTrend, (prevProps, nextProps) => {
    return (
        prevProps.title === nextProps.title &&
        prevProps.trendValue === nextProps.trendValue &&
        deepEqual(prevProps.customStyle, nextProps.customStyle)
    );
});
