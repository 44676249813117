import React from 'react';
import SurverFieldworkSVG from './components/ReportIcons/SurveyFieldworkSVG';
import AdtesterSVG from './components/ReportIcons/AdtesterSVG';
import BptSVG from './components/ReportIcons/BptSVG';
import TransversalViewSVG from './components/ReportIcons/TransversalViewSVG';
import FilterSegmentSVG from './components/ReportIcons/FilterSegmentSVG';
import { defaultTheme } from '../../utils/defaultTheme';
import * as routes from '../../utils/routes';
import { TRANSLATION_TEXT } from '../../utils/translations';
import CompetitiveMediaSVG from './components/ReportIcons/CompetitiveMediaSVG';
export const reportOptions = [
    {
        reportTitle: TRANSLATION_TEXT.TITLE_SURVEY_TEXT,
        color: defaultTheme.blue[900],
        bgColor: defaultTheme.blue[50],
        icon: <SurverFieldworkSVG />,
        path: routes.SURVEY_FIELDWORK_PAGE.path,
        requiredPermission: routes.SURVEY_FIELDWORK_PAGE.requiredPermission,
    },
    {
        reportTitle: TRANSLATION_TEXT.TITLE_ADTESTER_TEXT,
        color: defaultTheme.orange[700],
        bgColor: defaultTheme.red[100],
        icon: <AdtesterSVG />,
        path: routes.ADTESTER_PAGE.path,
        requiredPermission: routes.ADTESTER_PAGE.requiredPermission,
    },
    {
        reportTitle: TRANSLATION_TEXT.TITLE_BPT_TEXT,
        color: defaultTheme.violet[400],
        bgColor: defaultTheme.violet[100],
        icon: <BptSVG />,
        path: routes.BPT_PAGE.path,
        requiredPermission: routes.BPT_PAGE.requiredPermission,
    },
    {
        reportTitle: TRANSLATION_TEXT.TITLE_FILTER_SEGMENT_LIBRARY_TEXT,
        color: defaultTheme.green[700],
        bgColor: defaultTheme.green[100],
        icon: <FilterSegmentSVG />,
        path: routes.FILTER_SEGMENT_LIBRARY_PAGE.path,
        requiredPermission: routes.FILTER_SEGMENT_LIBRARY_PAGE.requiredPermission,
    },
    {
        reportTitle: TRANSLATION_TEXT.COMPETITVE_MEDIA_TEXT,
        color: defaultTheme.green[700],
        bgColor: defaultTheme.green[100],
        icon: <CompetitiveMediaSVG />,
        path: routes.COMPETITIVE_MEDIA.path,
        requiredPermission: routes.COMPETITIVE_MEDIA.requiredPermission,
    },
    {
        reportTitle: TRANSLATION_TEXT.TRANSVERSAL_VIEW_TEXT,
        color: defaultTheme.brown[400],
        bgColor: defaultTheme.brown[100],
        icon: <TransversalViewSVG />,
        path: routes.TRANSVERSAL_VIEW_PAGE.path,
        requiredPermission: routes.TRANSVERSAL_VIEW_PAGE.requiredPermission,
    },
];
