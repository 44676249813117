import moment from 'moment';
import * as d3 from 'd3';
export const isMaintenance = false;

export const asyncActionCreator = actionName =>
    typeof actionName !== 'string'
        ? new Error('Please provide a string to actionName!')
        : {
              actions: {
                  STARTED: `${actionName}_STARTED`,
                  SUCCEEDED: `${actionName}_SUCCEEDED`,
                  FAILED: `${actionName}_FAILED`,
              },
              dispatchers: {
                  started: () => ({ type: `${actionName}_STARTED` }),
                  succeeded: payload => ({ type: `${actionName}_SUCCEEDED`, payload: payload }),
                  failed: error => ({ type: `${actionName}_FAILED`, payload: error }),
              },
          };

export const validateEmail = email => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const validatePassword = password => {
    // more than 8 characters, 1 number & 1 special character
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*+\\/'?:,(){}[\]~`\-_]).{8,}$/;
    return passwordRegex.test(password);
};

export const getPropertyValueOrDefault = (object, propertyName, defaultValue) => {
    if (object === null) return defaultValue;
    if (object === undefined) return defaultValue;
    if (object.hasOwnProperty(propertyName)) return object[propertyName];
    return defaultValue;
};

export const decodeAccessToken = encodedToken => {
    const base64Url = encodedToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const isUserAllowedAccess = (userPermission, requiredPermissions) => {
    if (requiredPermissions === null || userPermission?.includes('Admin')) {
        return true;
    } else if (requiredPermissions) {
        let parsedRequiredPermission = requiredPermissions;
        if (typeof requiredPermissions === 'string') {
            parsedRequiredPermission = [requiredPermissions];
        }
        return parsedRequiredPermission.every(permission => userPermission?.includes(permission));
    }
    return false;
};

export const getSlashSeparatedDate = date => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
};

export const getSectionBasedOnSectionID = (sections, sectionID) => {
    let returnedSection = null;
    if (sections) {
        returnedSection = sections.find(theSection => theSection.sectionID === sectionID);
    }
    return returnedSection;
};

export const getKPIBasedOnMatchedProperty = (kpis, propertyName, propertyValue) => {
    let returnedKPI = null;
    if (kpis) {
        returnedKPI = kpis.filter(theKPI => theKPI[propertyName] === propertyValue);
    }
    return returnedKPI;
};

export const arraysEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

export const injectAdditionalHTMLTags = basePath => {
    let existentTag = document.querySelector("link[rel*='icon']");
    if (!existentTag) {
        existentTag = document.createElement('link');
        existentTag.rel = 'icon';
        //While there might not be a <head> container tag on QA or PROD
        //The browser knows to contain the script, title, etc. tags
        //So the below append, will not fail.
        document.getElementsByTagName('head')[0].appendChild(existentTag);
    }
    existentTag.href = `${basePath}/visuals/Axa-favicon.png`;
};

export const createIdFromGroup = (idBase, group) => {
    return idBase + '-' + group.replace(/[^a-zA-Z]+/g, '');
};

export const addTableHeaderCustomSortFunctions = (th, headerObject) => {
    const newObject = { ...headerObject };

    if (th.Header.toLowerCase().includes('wave')) {
        newObject.sortType = sortByDate;
    }

    return newObject;
};

export function sortByDate(rowA, rowB, id, desc) {
    let a = moment(rowA.values[id]);
    let b = moment(rowB.values[id]);

    if (!moment.isMoment(a)) {
        a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }

    if (!moment.isMoment(b)) {
        b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }

    if (a.isAfter(b)) return 1;
    if (a.isBefore(b)) return -1;

    return 0;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function deepEqual(x, y) {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y;
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
        : x === y;
}

export function wrapSVGText(text, width, xOffset, yOffset) {
    /* eslint-disable no-shadow */
    text.each(function () {
        let text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1,
            x = text.attr('x') - (xOffset || 0),
            y = text.attr('y') - (yOffset || 0),
            dy = 0,
            tspan = text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em');
        /* eslint-disable no-shadow */
        while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node()?.getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                tspan = text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                    .text(word);
            }
        }
    });
}
