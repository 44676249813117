import { CHART_MARGIN } from './MappingChart';

export class Rect {
    constructor(_x, _y, _width, _height) {
        this.x = _x;
        this.y = _y;
        this.w = _width;
        this.h = _height;
    }

    intersect(rect) {
        return (
            this.x <= rect.x + rect.w &&
            rect.x <= this.x + this.w &&
            this.y <= rect.y + rect.h &&
            rect.y <= this.y + this.h
        );
    }
}

export const initLabeler = () => {
    return function () {
        let lab = [],
            anc = [],
            w = 1,
            h = 1,
            labeler = {};

        const isIntersectingFunc = label => {
            let intersects = false;
            const labelRect = new Rect(label.x, label.y, label.w, label.h + 2);

            lab.forEach((e, i) => {
                const r = new Rect(e.finalX, e.finalY, e.w, e.h);
                if (r.intersect(labelRect)) {
                    intersects = true;
                }
            });

            anc.forEach(e => {
                const r = new Rect(e.x, e.y, e.w, e.h);
                if (r.intersect(labelRect)) {
                    intersects = true;
                }
            });
            return intersects;
        };

        const exceedsBounds = label => {
            if (label.x - 0.05 * label.x < CHART_MARGIN.left || label.x + label.w >= w - CHART_MARGIN.left) return true;
            if (label.y - label.h < CHART_MARGIN.top || label.y + label.h * 3 >= h) return true;
            return false;
        };

        const archimedeanPoint = (degrees, maxWidth, maxHeight) => {
            let a = 0.0;
            let b = 1.0;
            let t = (degrees * Math.PI) / 180;
            let r = a + b * t;
            return { x: maxWidth / 2 + r * Math.cos(t), y: maxHeight / 2 + r * Math.sin(t) };
        };

        const nextPointOnSpiral = (angle, initialPoint) => {
            let spiralWidth = initialPoint.x * 2;
            let spiralHeight = initialPoint.y * 2;
            return archimedeanPoint(angle, spiralWidth, spiralHeight);
        };

        labeler.start = function () {
            // Pre-placement of labels
            lab.forEach((label, index) => {
                label.x += 12;
                label.y += 4;
                let tempLabel = {
                    x: label.x,
                    y: label.y,
                    w: label.w,
                    h: label.h,
                };

                let isIntersecting = true;
                isIntersecting = exceedsBounds(label) || isIntersectingFunc(label, index);

                let rect = new Rect(tempLabel.x, tempLabel.y, tempLabel.w, tempLabel.h);
                //Move to bottom
                if (isIntersecting) {
                    for (let k = 0; k < 20; k++) {
                        rect = new Rect(tempLabel.x - tempLabel.w / 2, tempLabel.y + 10 + k, tempLabel.w, tempLabel.h);
                        isIntersecting = exceedsBounds(rect) || isIntersectingFunc(rect, index);
                        if (!isIntersecting) break;
                    }
                }
                //Move to right
                if (isIntersecting) {
                    for (let k = 0; k < 20; k++) {
                        rect = new Rect(tempLabel.x + 10 + k, tempLabel.y, tempLabel.w, tempLabel.h);
                        isIntersecting = exceedsBounds(rect) || isIntersectingFunc(rect, index);
                        if (!isIntersecting) break;
                    }
                }
                //Move to the left
                if (isIntersecting) {
                    for (let k = 0; k < 20; k++) {
                        rect = new Rect(tempLabel.x - tempLabel.w - 18 - k, tempLabel.y, tempLabel.w, tempLabel.h);
                        isIntersecting = exceedsBounds(rect) || isIntersectingFunc(rect, index);
                        if (!isIntersecting) break;
                    }
                }
                //Move to top
                if (isIntersecting) {
                    for (let k = 0; k < 20; k++) {
                        rect = new Rect(tempLabel.x - tempLabel.w / 2, tempLabel.y - 10 - k, tempLabel.w, tempLabel.h);
                        isIntersecting = exceedsBounds(rect) || isIntersectingFunc(rect, index);
                        if (!isIntersecting) break;
                    }
                }

                if (!isIntersecting) {
                    label.finalX = rect.x;
                    label.finalY = rect.y;
                    label.done = true;
                }
            });

            // Second phase placement : spiral algorithm - archimede spiral
            lab.forEach((label, index) => {
                if (label.done === false) {
                    let tempLabel = { x: label.x, y: label.y, w: label.w, h: label.h };
                    let rect = new Rect(tempLabel.x, tempLabel.y, tempLabel.w, tempLabel.h);
                    let isIntersecting = false;

                    let count = 10000;
                    let degrees = 0;

                    do {
                        isIntersecting = false;
                        let nextPoint = nextPointOnSpiral(degrees, tempLabel);

                        rect = new Rect(nextPoint.x, nextPoint.y, tempLabel.w, tempLabel.h);
                        isIntersecting = exceedsBounds(rect) || isIntersectingFunc(rect, index);
                        degrees += 1;
                        count--;
                    } while (isIntersecting && count > 0);

                    label.finalX = rect.x;
                    label.finalY = rect.y;
                }
            });

            // Update the final positions
            lab.forEach(label => {
                label.x = label.finalX;
                label.y = label.finalY;
            });
        };

        labeler.width = function (x) {
            if (!arguments.length) return w;
            w = x;
            return labeler;
        };

        labeler.height = function (x) {
            if (!arguments.length) return h;
            h = x;
            return labeler;
        };

        labeler.label = function (x) {
            if (!arguments.length) return lab;
            lab = x;
            return labeler;
        };

        labeler.anchor = function (x) {
            if (!arguments.length) return anc;
            anc = x;
            return labeler;
        };

        return labeler;
    };
};
