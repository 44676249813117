import React, { useMemo } from 'react';
import {
    CampaignDiagnosticCardWrapper,
    CampaignDiagnosticContent,
    CampaignDiagnosticTitle,
    CardBarWrapper,
    SupportDiv2_UIOnly,
    SupportDiv_UIOnly,
} from '../../AdDetailsMetrics.styles';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from '@toluna-ui-toolkit/barchart';
import { BAR_CATEGORY, BAR_TYPES } from '../../utils/constants';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { MEDIA_QUERY_SCREEN_SIZE, TOPBAR_CHARTS_RESPONSIVE_WIDTHS } from '../../../../../../utils/responsiveness';
import PropTypes from 'prop-types';
import { TOP_2_TYPE } from '../../../../utils/utils';

const getContrastingColor = itemType => {
    if (itemType === BAR_CATEGORY.MAIN || itemType === BAR_CATEGORY.AVERAGE) return defaultTheme.white.ff;
    return defaultTheme.blue[950];
};

const TopBarsCard = ({ title, label, items, kpiType, noStroke }) => {
    const [width] = useWindowSize();

    const BAR_CHARTS_WIDTH =
        width <= MEDIA_QUERY_SCREEN_SIZE.MEDIUM
            ? TOPBAR_CHARTS_RESPONSIVE_WIDTHS.SMALL
            : TOPBAR_CHARTS_RESPONSIVE_WIDTHS.LARGE;

    const barChartsData = useMemo(
        () =>
            items.reduce((data, currentData) => {
                const { itemType, values, itemLabel } = currentData;

                if ([BAR_CATEGORY.MAIN, BAR_CATEGORY.FILTERED, BAR_CATEGORY.AVERAGE].includes(itemType)) {
                    let barObjectData = null;

                    switch (kpiType) {
                        case BAR_TYPES.SIMPLE: {
                            barObjectData = values[0];
                            break;
                        }
                        case BAR_TYPES.STACKED: {
                            barObjectData = values.find(e => e.type === TOP_2_TYPE);
                            break;
                        }
                    }

                    if (barObjectData) {
                        if (itemType === BAR_CATEGORY.FILTERED) {
                            barObjectData.color = 'url(#filtered-lines-pattern)';
                        }
                        if (itemType === BAR_CATEGORY.AVERAGE) {
                            barObjectData.color = defaultTheme.orange['700'];
                        }

                        data.push({
                            name: itemLabel,
                            value: barObjectData.value,
                            color: barObjectData.color,
                            textColor: getContrastingColor(itemType),
                        });
                    }
                }
                return data;
            }, []),
        [items, BAR_CHARTS_WIDTH]
    );

    return (
        <>
            <CampaignDiagnosticCardWrapper>
                <CampaignDiagnosticContent>
                    <CampaignDiagnosticTitle fontSize={'15px'}>{title}</CampaignDiagnosticTitle>
                    <CampaignDiagnosticTitle style={{ margin: 0 }} fontSize={'13px'}>
                        {label}
                    </CampaignDiagnosticTitle>

                    <CardBarWrapper
                        style={{ paddingRight: BAR_CHARTS_WIDTH === TOPBAR_CHARTS_RESPONSIVE_WIDTHS.LARGE && '5px' }}
                    >
                        <BarChart
                            width={BAR_CHARTS_WIDTH}
                            height={75}
                            domain={100}
                            direction={BARCHART_DIRECTION.VERTICAL}
                            labelFormatter={value => (value > 15 ? value + '%' : '')}
                            labelPosition={BARCHART_LABEL_POSITION.MIDDLE}
                            tooltipFormatter={({ value }) => value + '%'}
                            translateX={barChartsData.length === 1 ? 21 : 0}
                            styling={{
                                tooltipOffsetX: -20,
                                tooltipOffsetY: -27,
                                barWidth: 50,
                                barStroke: noStroke ? 0 : 1,
                                barStrokeColor: defaultTheme.blue[600],
                                fontWeight: 600,
                                barLabelSecondaryAxisOffset: -4,
                                barLabelMainAxisOffset: -5,
                                tooltipWidth: 24,
                                tooltipHeight: 20,
                                tooltipStroke: 1,
                                tooltipStrokeColor: defaultTheme.blue['950-b3'],
                                tooltipFontColor: defaultTheme.blue['600'],
                            }}
                            withNames={false}
                            tooltipShowSize={16}
                            alwaysOnTooltips={false}
                            data={barChartsData}
                        />
                    </CardBarWrapper>
                </CampaignDiagnosticContent>

                {/* UI Only Elements */}
                <SupportDiv_UIOnly />
                <SupportDiv2_UIOnly />
            </CampaignDiagnosticCardWrapper>
        </>
    );
};

TopBarsCard.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            itemType: PropTypes.string.isRequired,
            itemLabel: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.number.isRequired,
                    color: PropTypes.string.isRequired,
                })
            ),
        })
    ),
    kpiType: PropTypes.oneOf([BAR_TYPES.STACKED, BAR_TYPES.SIMPLE]).isRequired,
    noStroke: PropTypes.bool,
};
export default TopBarsCard;
