import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { GET_COMPETITIVE_MEDIA, GET_COMPETITIVE_MEDIA_DATA_ASYNC } from './actions';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { getCompetitiveMediaData } from '../../../../../services/apiServer.service';
import { getComputedFiltersParametersForCompetitiveMedia } from './computedSelectors';

function* getCompetitiveMediaSaga(action) {
    try {
        yield put(GET_COMPETITIVE_MEDIA_DATA_ASYNC.dispatchers.started());

        const filtersAsRequestParameters = yield select(getComputedFiltersParametersForCompetitiveMedia);

        const data = yield call(getCompetitiveMediaData, { filtersList: filtersAsRequestParameters });

        yield put(GET_COMPETITIVE_MEDIA_DATA_ASYNC.dispatchers.succeeded(data));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(GET_COMPETITIVE_MEDIA_DATA_ASYNC.dispatchers.failed(error));
    }
}

export default function* competitiveMediaSaga() {
    yield all([yield takeLatest(GET_COMPETITIVE_MEDIA, getCompetitiveMediaSaga)]);
}
