import { connect } from 'react-redux';
import {
    areSelectedOptionsValidAsBackEndParametersComputedSelector,
    getComputedTopFiltersIsAllYearsSelected,
    getComputedTopFiltersSelector,
} from '../data/computedSelectors';
import { topFilterOnSelectAllActionCreator, topFiltersOnChangeActionCreator } from '../data/actions';
import { getSelectedFiltersComputedSelector } from '../../SelectedFilters/data/computedSelectors';
import TopFilterGroup from './TopFilterGroup';
import { getIsGlobalLoadingSelector } from '../../Loader/data/selectors';
import { applyFiltersActionCreator } from '../../../data/actions';
import { getLocationSelector } from '../data/selectors';

const mapStateToProps = state => ({
    filters: getComputedTopFiltersSelector(state),
    isAllSelected: getComputedTopFiltersIsAllYearsSelected(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
    isDisplayButtonDisabled: !areSelectedOptionsValidAsBackEndParametersComputedSelector(state),
    isLoadingTopFilter: getIsGlobalLoadingSelector(state),
    currentPage: getLocationSelector(state),
});

const mapDispatchToProps = {
    onChange: topFiltersOnChangeActionCreator,
    onSelectAll: topFilterOnSelectAllActionCreator,
    onApply: applyFiltersActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopFilterGroup);
