import styled from 'styled-components';
import { Content } from '../../../landing/components/HomeSection/HomeSection.styles';

export const Wrapper = styled.div`
    margin: 75px 25px;
`;

export const SectionBody = styled.div`
    cursor: context-menu;
    font-size: 16px;
    color: ${({ theme }) => theme.blue['900']};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    @media (max-width: 1150px) {
        flex-direction: column;
    }
`;
export const Description = styled(Content)`
    p {
        font-weight: normal;
    }
`;
export const ContentImage = styled.img`
    object-fit: contain;
`;
