import React, { useEffect, useState, useRef, useMemo } from 'react';
import RoundCheckbox from '../../RoundCheckbox/RoundCheckbox';
import Checkbox from '../../Checkbox/Checkbox';
import SideFiltersSection from './SideFiltersSection';
import SideFiltersSlider from './SideFiltersSlider';
import { INPUT_TYPES } from '../utils/sideFilters.config';
import {
    ClosedFiltersWrapper,
    SideFiltersHeader,
    SideFiltersWrapper,
    SideFiltersContent,
    SideFiltersSubSectionWrapper,
    PrimaryButton,
    SecondaryButton,
    ButtonWrapper,
} from './SideFilters.styles';
import { defaultTheme } from '../../../../utils/defaultTheme';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { basePath } from '../../../../services/utils';
const SideFilters = ({
    updateSideFiltersActionCreator,
    setSideFiltersPageActionCreator,
    toggleSideFiltersSectionActionCreator,
    currentPage,
    sideFilters,
    selectedSideFilters,
    applyFiltersActionCreator,
    clearFiltersActionCreator,
    appliedButtonDisabled,
    clearButtonDisabled,
    sideFiltersSectionUi,
    currentSubPage,
    showSideBarFilters,
    isSingleSectionSelection,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    let sortedSideFilters = {};
    //sort by options id
    for (let groupKey in sideFilters) {
        if (sideFilters.hasOwnProperty(groupKey)) {
            let groupValue = sideFilters[groupKey];
            let groupId = Object.keys(groupValue)[0];
            let sortedOptions = groupValue[groupId].options.slice().sort((a, b) => a.id - b.id);
            sortedSideFilters[groupKey] = { [groupId]: { ...groupValue[groupId], options: sortedOptions } };
        }
    }
    const filterRef = useRef();
    useOnClickOutside(filterRef, () => setIsOpen(false));

    useEffect(() => {
        setSideFiltersPageActionCreator(currentPage);
    }, [currentPage]);

    const filtersDisplayMemoized = useMemo(() => {
        let filtersDisplay = [];

        const selectedPageSideFilters = selectedSideFilters[currentPage];

        for (const groupName in sortedSideFilters) {
            if (Object.hasOwnProperty.call(sortedSideFilters, groupName)) {
                const filterGroup = sortedSideFilters[groupName]; //array with the filters in each group

                // Needed for computation for the last item in the object
                const numberOfItemsInGroup = Object.keys(filterGroup).length;
                const filterSections = [];
                for (const filterId in filterGroup) {
                    if (Object.hasOwnProperty.call(filterGroup, filterId)) {
                        const filter = filterGroup[filterId];
                        const filterOptions = [];

                        if (selectedPageSideFilters?.[groupName]) {
                            //map the filter options to checkboxes
                            if (filter.selectType === INPUT_TYPES.MULTI_CHECKBOX) {
                                filter.options.map(option => {
                                    const isChecked =
                                        !!selectedPageSideFilters &&
                                        selectedPageSideFilters[groupName] &&
                                        (selectedPageSideFilters[groupName][filterId].selected.find(
                                            el => el === option.id
                                        ) !== undefined
                                            ? true
                                            : false);
                                    // for isChecked constant last check need if is different than undefined because value can be 0,
                                    // which in this case will cause  an false in that condition
                                    filterOptions.push(
                                        <Checkbox
                                            key={option.id}
                                            isRound={false}
                                            isTransparent={false}
                                            label={option.label}
                                            hoverColor={defaultTheme.grey[50]}
                                            textColor={defaultTheme.blue[950]}
                                            value={isChecked}
                                            onClick={() =>
                                                updateSideFiltersActionCreator({
                                                    pageName: currentPage,
                                                    groupName,
                                                    filterId: filter.id,
                                                    selectedId: option.id,
                                                    filterType: INPUT_TYPES.MULTI_CHECKBOX,
                                                })
                                            }
                                        />
                                    );
                                });
                                // add the select all checkbox at the beggining
                                filterOptions.unshift(
                                    <RoundCheckbox
                                        key={filter.id + 'select-all'}
                                        isTransparent={true}
                                        textColor={defaultTheme.blue[950]}
                                        label="Select / deselect All"
                                        hoverColor={defaultTheme.grey[50]}
                                        value={
                                            !!selectedPageSideFilters &&
                                            selectedPageSideFilters[groupName] &&
                                            selectedPageSideFilters[groupName][filterId].selected.length ===
                                                selectedPageSideFilters[groupName][filterId].optionsCount
                                        }
                                        onClick={() =>
                                            updateSideFiltersActionCreator({
                                                pageName: currentPage,
                                                groupName,
                                                filterId: filter.id,
                                                filterType: INPUT_TYPES.MULTI_CHECKBOX_SELECT_ALL,
                                            })
                                        }
                                    />
                                );
                                // add the slider input
                            } else if (filter.selectType === INPUT_TYPES.SLIDER) {
                                filterOptions.push(
                                    <SideFiltersSlider
                                        key={filter.id + 'slider'}
                                        minScaleValue={+filter.options[0].label}
                                        maxScaleValue={+filter.options[1].label}
                                        values={{
                                            min: +selectedPageSideFilters?.[groupName][filterId].selected[0],
                                            max: +selectedPageSideFilters?.[groupName][filterId].selected[1],
                                        }}
                                        onChangeCompleted={data =>
                                            updateSideFiltersActionCreator({
                                                ...data,
                                                pageName: currentPage,
                                                groupName,
                                                filterId: filter.id,
                                                filterType: INPUT_TYPES.SLIDER,
                                            })
                                        }
                                    />
                                );
                            }

                            // Needed in CSS for displaying the border
                            const islastItem = filterSections.length + 1 === numberOfItemsInGroup;

                            // If main group name is same as filter group it means that there is no nesting involved (no subsections).
                            const noSubsections = groupName === filter.label;

                            if (noSubsections) {
                                filterSections.push(
                                    <SideFiltersSubSectionWrapper isSubmenu={true} isLastItem={islastItem}>
                                        {filterOptions}
                                    </SideFiltersSubSectionWrapper>
                                );
                            } else {
                                // add each type of filter to the group
                                filterSections.push(
                                    <SideFiltersSection
                                        title={filter.label}
                                        key={filter.id}
                                        isSubmenu={true}
                                        isLastItem={islastItem}
                                        isOpen={sideFiltersSectionUi[currentPage]?.[groupName]?.[filter.id]?.isOpen}
                                        onToggle={() =>
                                            toggleSideFiltersSectionActionCreator({
                                                isSubsection: true,
                                                groupName,
                                                pageName: currentPage,
                                                filterId: filter.id,
                                            })
                                        }
                                    >
                                        <SideFiltersSubSectionWrapper isSubmenu={true} isLastItem={islastItem}>
                                            {filterOptions}
                                        </SideFiltersSubSectionWrapper>
                                    </SideFiltersSection>
                                );
                            }
                        }
                    }
                }
                // add each group to the UI
                const isSectionDisabled = () => {
                    const currentPageFilters = selectedSideFilters[currentPage];
                    if (!currentPageFilters || !isSingleSectionSelection) return false;

                    let sectionWithOptionsSelected = null;

                    /* If no options are selected, we don't need to disable any section */
                    const noneSelected = Object.keys(currentPageFilters).reduce((acc, key) => {
                        const groupNameObject = currentPageFilters[key];
                        const idKey = Object.keys(groupNameObject).length > 0 ? Object.keys(groupNameObject)[0] : null;

                        if (!idKey) return false;

                        /* If any section has at least one options selected, noneSelected gets assigned to false.
                        We store the group that has selection for additional checks. */
                        if (groupNameObject[idKey].selected.length > 0) {
                            sectionWithOptionsSelected = key;
                            return false;
                        }
                        return acc;
                    }, true);

                    if (noneSelected) return false;
                    else {
                        /* If some options are selected, return isDisabled for groups which are different from the one with selected items. */
                        return sectionWithOptionsSelected !== groupName;
                    }
                };

                filtersDisplay.push(
                    <SideFiltersSection
                        title={groupName}
                        key={groupName}
                        disabled={isSectionDisabled()}
                        isSingleSectionSelection={isSingleSectionSelection}
                        isSubmenu={false}
                        isOpen={sideFiltersSectionUi[currentPage]?.[groupName]?.isOpen}
                        onToggle={() =>
                            toggleSideFiltersSectionActionCreator({
                                isSubsection: false,
                                groupName,
                                pageName: currentPage,
                                isSingleSectionSelection,
                            })
                        }
                    >
                        {filterSections}
                    </SideFiltersSection>
                );
            }
        }
        return filtersDisplay;
    }, [sideFilters, selectedSideFilters, currentPage, currentSubPage, sideFiltersSectionUi]);

    return (
        <SideFiltersWrapper isOpen={isOpen} ref={filterRef} showSideBarFilters={showSideBarFilters}>
            <SideFiltersHeader onClick={() => setIsOpen(oldValue => !oldValue)} isOpen={isOpen}>
                <img src={`${basePath}/visuals/close_arrow_menu.png`} alt="Side Filter Arrow" />
                <span>Filters</span>
            </SideFiltersHeader>
            {isOpen ? (
                <SideFiltersContent>
                    {filtersDisplayMemoized}
                    <ButtonWrapper>
                        <SecondaryButton onClick={clearFiltersActionCreator} disabled={clearButtonDisabled}>
                            Clear
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() =>
                                applyFiltersActionCreator({
                                    reloadFilters: false,
                                    resetSideFiltersIfCountryChanged: true,
                                })
                            }
                            disabled={appliedButtonDisabled}
                        >
                            Apply
                        </PrimaryButton>
                    </ButtonWrapper>
                </SideFiltersContent>
            ) : (
                <ClosedFiltersWrapper>
                    <img src={`${basePath}/visuals/filter_icon.png`} alt="Filter Icon" />
                    <span>Filters</span>
                </ClosedFiltersWrapper>
            )}
        </SideFiltersWrapper>
    );
};
SideFilters.propTypes = {
    updateSideFiltersActionCreator: PropTypes.func.isRequired,
    setSideFiltersPageActionCreator: PropTypes.func.isRequired,
    toggleSideFiltersSectionActionCreator: PropTypes.func.isRequired,
    currentPage: PropTypes.string.isRequired,
    selectedSideFilters: PropTypes.object.isRequired,
    applyFiltersActionCreator: PropTypes.func.isRequired,
    clearFiltersActionCreator: PropTypes.func.isRequired,
    appliedButtonDisabled: PropTypes.bool.isRequired,
    clearButtonDisabled: PropTypes.bool.isRequired,
    sideFiltersSectionUi: PropTypes.object.isRequired,
    sideFilters: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
    currentSubPage: PropTypes.string.isRequired,
    showSideBarFilters: PropTypes.bool.isRequired,
};
SideFilters.defaultProps = {
    showSideBarFilters: true,
};
export default SideFilters;
