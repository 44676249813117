import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const IconWrapper = styled.div`
    padding: 0 24px;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1150px) {
        padding: 0 24px;
        height: 190px;
    }
`;

export const Title = styled.p`
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 4px;
    transition: all 0.15s linear;
    position: relative;

    span {
        position: relative;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: ${({ bgColor }) => bgColor};
        transition: all 0.15s linear;
    }
    
    @media (max-width: 1280px) {
        font-size: 13px;
    }
`;
export const ReportOptionWrapper = styled(NavLink)`
    background-color: ${({ theme }) => theme.white.ff};
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    box-sizing: border-box;
    width: 300px;
    margin: 0 12px;

    &:hover ${Title} {
        color: ${({ bgColor }) => bgColor};
        background-color: ${({ color }) => color};
        &::before {
            background-color: ${({ color }) => color};
            width: 100%;
        }
    }
`;