import React from 'react';
import SubMenuNavigation from '../../common/components/Navigation/SubMenuNavigation';
import * as routes from '../../utils/routes';
import { Route } from 'react-router-dom';
import Homepage from './subpages/Homepage/Homepage';
import PreferenceAnalysis from './subpages/PreferenceAnalysis/PreferenceAnalysis';
import TopLines from './subpages/TopLines/TopLines.container';
import FlatFile from './subpages/FlatFile/FlatFile.container';
import * as SC from '../../common/General.styles';
const BrandPreferenceTrackerPage = () => {
    return (
        <SC.PageMainWrapper>
            <SC.PageWrapper>
                <SubMenuNavigation
                    subRouteWithComponents={routes.BPT_PAGE.SUB_ROUTES}
                    themeConfig={routes.BPT_PAGE.config}
                />
                <div>
                    <Route exact path={routes.BPT_PAGE.SUB_ROUTES.HOME_PAGE.path} component={Homepage} />
                    <Route
                        path={routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path}
                        component={PreferenceAnalysis}
                    />
                    <Route exact path={routes.BPT_PAGE.SUB_ROUTES.TOP_LINES_PAGE.path} component={TopLines} />
                    <Route exact path={routes.BPT_PAGE.SUB_ROUTES.FLAT_FILE_PAGE.path} component={FlatFile} />
                </div>
            </SC.PageWrapper>
        </SC.PageMainWrapper>
    );
};
export default BrandPreferenceTrackerPage;
