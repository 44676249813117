import React from 'react';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import * as SC from './SigTestFlag.styles';
import PropTypes from 'prop-types';

const SigTestFlag = ({ data, sigTestCoords, totalBarChartHeight, barTranslate }) => {
    return sigTestCoords && sigTestCoords.length > 0 ? (
        <SC.SigTestFlagWrapper>
            {data.map((barData, id) => {
                if (barData.sigtest !== 0) {
                    return (
                        <SC.SigTestTooltip
                            data-testid={'SigTestFlag-Tooltip'}
                            key={barData.order + barData.name}
                            sigTestCoords={sigTestCoords.find(el => el.id === id)}
                            totalBarChartHeight={totalBarChartHeight}
                            showFlag={true}
                            marginBottom={barData.value}
                            backgroundSigTest={barData.sigtest}
                            barTranslate={barTranslate ? barTranslate : 0}
                        >
                            <SC.ExtendedSigTestTooltip key={barData.name} backgroundSigTest={barData.sigtest}>
                                {barData.sigtest > 0
                                    ? TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_HIGHER
                                    : TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_LOWER}
                                <hr />
                            </SC.ExtendedSigTestTooltip>
                            {barData.value}%
                        </SC.SigTestTooltip>
                    );
                } else return <SC.SigTestTooltip key={barData.order + barData.name} showFlag={false} />;
            })}
        </SC.SigTestFlagWrapper>
    ) : null;
};

const DataShape = PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    sigtest: PropTypes.number.isRequired,
    tooltip: PropTypes.string,
    evolutionMetricValue: PropTypes.number,
});

const CoordsShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    coords: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
});

SigTestFlag.propTypes = {
    data: PropTypes.arrayOf(DataShape),
    sigTestCoords: PropTypes.arrayOf(CoordsShape),
    totalBarChartHeight: PropTypes.number.isRequired,
    barTranslate: PropTypes.number,
};

export default SigTestFlag;
