import { SET_NOTIFICATION_ERROR_DISPLAY } from './actions';

export const errorNotificationReducer = (state = false, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_ERROR_DISPLAY:
            return action.payload;
        default:
            return state;
    }
};
