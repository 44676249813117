import { connect } from 'react-redux';
import { getComputedAdTesterOverviewTableData, getComputedAdTesterOverviewTableHead } from '../data/computedSelectors';
import AdTesterOverviewTable from './AdTesterOverviewTable';

const mapStateToProps = state => ({
    data: getComputedAdTesterOverviewTableData(state),
    tableHead: getComputedAdTesterOverviewTableHead(state),
});

const AdTesterOverviewTableContainer = connect(mapStateToProps, null)(AdTesterOverviewTable);
export default AdTesterOverviewTableContainer;
