import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    BackOfficeErrorButton,
    BackOfficePageWrapper,
    BackOfficeSection,
    BackOfficeTableWrapper,
    BackOfficeTitle,
    NonSorted,
    SortedAsc,
    SortedDesc,
    UploadWrapper,
    HighlightWrapper,
} from './backOffice.styles';
import { DataTable } from '@toluna-ui-toolkit/data-table';
import { defaultTheme } from '../../utils/defaultTheme';
import FileUpload from './components/FileUpload';
import { logFileEntryData, logFileEntryMedia, logFileEntryMetaData } from '../../services/apiServer.service';
import { initBackofficeRequestsActionCreator, toggleConfirmitProjectActionCreator } from './data/actions';
import { PRESIGNED_IMPORTY_TYPE_ID, TABLE_HANDLERS_MAP } from './utils/utils';
import { getBackofficeTablesDataSelector } from './data/selectors';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import ReusableModal from '../../common/components/ReusableModal/ReusableModal';
import { TRANSLATION_TEXT, BROWSE_FILE } from '../../utils/translations';
import { Toggle } from '@toluna-ui-toolkit/inputs';
import SearchInput from '../../common/components/SearchInput/components/SearchInput';
import { basePath } from '../../services/utils';
import { sortByDate } from '../../utils/generalUtilities';
import { logoutActionCreator } from '../login/data/actions';

const getToggleCustomStyle = checked => {
    return {
        button: `
            width: 17px;
            height: 17px;
            background-color: white;
            margin-left: ${checked ? '0px' : '4px'};
        `,
        slider: `
            background-color: ${checked ? defaultTheme.blue['600'] : defaultTheme.grey['100']};
            height: 23px;
        `,
    };
};

const TABLE_STYLE = {
    tableHead: {
        backgroundColor: 'null',
        borderBottom: `2px solid ${defaultTheme.blue[300]}`,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        color: defaultTheme.blue[950],
        borderTopRightRadius: '0',
        borderTopLeftRadius: '0',
        fontSize: '15px',
    },
    bodyRow: {
        activeColor: null,
        backgroundColor: defaultTheme.grey[50],
        backgroundColorSecondary: defaultTheme.white.ff,
        hoverColor: 'null',
    },
    column: { separatorColor: 'none' },
    global: {
        fontFamily: 'Source Sans Pro,Open Sans',
    },
};
const customStyle = {
    textColor: defaultTheme.blue[950],
    opacity: 0.4,
};
export const BackOfficePage = ({ initBackofficeTablesData, initConfirmItToggle, tablesData, logout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [logoutOnModalClose, setLogoutOnModalClose] = useState(false);

    const handleModalOpen = (errTxt, logoutEnabled) => {
        setIsOpen(true);
        setErrorText(errTxt);

        if (logoutEnabled) {
            setLogoutOnModalClose(logoutEnabled);
        }
    };

    const handleModalClose = () => {
        setIsOpen(false);
        setErrorText('');

        if (logoutOnModalClose) {
            logout();
            setLogoutOnModalClose(false);
        }
    };

    const modalRef = useRef();
    useOnClickOutside(modalRef, handleModalClose);

    useEffect(() => {
        initBackofficeTablesData();
    }, [initBackofficeTablesData]);

    const hasTableData = React.useMemo(() => tablesData && Object.keys(tablesData).length, [tablesData]);

    const TextHighlighter = ({ entireString, stringToHighlight, title }) => {
        const isNeedOfHighlight = stringToHighlight?.length;
        const invalidChars = /[°"§\[\]\\?´`'<>|]+/g;
        const decomposeAndRebuild = () => {
            const validStringToHighlight = stringToHighlight?.replace(invalidChars, '');
            const decomposed = entireString?.split(new RegExp(`(${validStringToHighlight})`, 'gi'));
            return decomposed?.map((part, i) => (
                <span className="highlight_wrapper" key={i}>
                    {validStringToHighlight?.length && part.toLowerCase() === validStringToHighlight?.toLowerCase() ? (
                        <strong data-testid={`TextHighlight-${part}`}>{part}</strong>
                    ) : (
                        <span className="highlight_span">{part}</span>
                    )}
                </span>
            ));
        };
        return (
            <HighlightWrapper title={title} isRequiringHighlight={stringToHighlight?.length}>
                {isNeedOfHighlight ? decomposeAndRebuild() : entireString}
            </HighlightWrapper>
        );
    };
    const columns = React.useMemo(
        () => [
            {
                Header: 'Project Name',
                accessor: 'fileName',
                minWidth: 70,
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Timestamp',
                accessor: 'timeStamp',
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'User',
                accessor: 'user',
                disableSortBy: true,
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Errors',
                accessor: 'error',
                disableSortBy: true,
                disableGlobalFilter: true,
                Cell: props =>
                    props.value ? (
                        <BackOfficeErrorButton onClick={() => handleModalOpen(props.value)}>
                            {TRANSLATION_TEXT.ERROR_BUTTON_TEXT}
                        </BackOfficeErrorButton>
                    ) : (
                        <strong style={{ color: defaultTheme.blue[950] }}>N/A</strong>
                    ),
            },
        ],
        []
    );
    const confirmIT_columns = React.useMemo(
        () => [
            {
                Header: 'Project Name',
                accessor: 'fileName',
                minWidth: 60,
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Country',
                accessor: 'countryName',
                maxWidth: 30,
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Wave',
                accessor: 'wave',
                sortType: sortByDate,
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Confirmit Pid',
                accessor: 'pid',
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Last Synced',
                accessor: 'lastSynched',
                Cell: ({ cell: { value }, state: { globalFilter } }) => {
                    return <TextHighlighter title={value} entireString={value} stringToHighlight={globalFilter} />;
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableGlobalFilter: true,
                sortType: 'basic',
                Cell: props => (
                    <div data-testid={`ToggleButton-${props.row.values.pid}`}>
                        <Toggle
                            checked={props.value}
                            customStyle={getToggleCustomStyle(props.value)}
                            onChange={() => initConfirmItToggle(props.row.values.fileName, props.row.values.pid)}
                            text={''}
                        />
                    </div>
                ),
            },
        ],
        []
    );

    const renderModal = () => {
        return (
            <ReusableModal
                modalWrapperRef={modalRef}
                text={errorText}
                modalWidth={'600px'}
                modalHeight={'auto'}
                overflowText={'auto'}
                titleColor={defaultTheme.red[400]}
                onApply={() => handleModalClose()}
                yesText={TRANSLATION_TEXT.GENERAL_SUBMIT_OK_TEXT}
                title={TRANSLATION_TEXT.ERROR_DETAILS_TEXT}
                singleButton={true}
                customStyle={{ marginTopText: '40px', titleFontSize: '18px', textFontSize: '16px', textMargin: '40px' }}
            />
        );
    };
    return (
        <div>
            {isOpen && renderModal()}
            <BackOfficePageWrapper>
                <BackOfficeTitle>{TRANSLATION_TEXT.BACKOFFICE_TITLE}</BackOfficeTitle>
                <p style={{ color: defaultTheme.blue[950] }}>
                    {hasTableData
                        ? '*' +
                          tablesData.cachingStatus.numberOfCachedItems +
                          TRANSLATION_TEXT.BACKOFFICE_SUBTITLE_OUT_OF +
                          tablesData.cachingStatus.numberOfItemsToCache +
                          TRANSLATION_TEXT.BACKOFFICE_SUBTITLE_CACHED_STATUS
                        : TRANSLATION_TEXT.LOADING_DATA}
                </p>
                <BackOfficeSection>
                    <div>
                        <BackOfficeTitle style={{ fontSize: '16px', marginTop: '40px' }}>
                            {TRANSLATION_TEXT.METADATA_TITLE}
                        </BackOfficeTitle>
                        <p style={{ color: defaultTheme.blue[950] }}>{BROWSE_FILE}</p>
                        <UploadWrapper>
                            <FileUpload
                                type={'text/csv, text/plain'}
                                customStyle={{ marginLeft: '30px' }}
                                importTypeId={PRESIGNED_IMPORTY_TYPE_ID.METADATA}
                                loggerFunction={logFileEntryMetaData}
                                tableToRequestDataFor={[
                                    TABLE_HANDLERS_MAP.METADATA_UPLOADS,
                                    TABLE_HANDLERS_MAP.CONFIRMIT_UPLOADS,
                                ]}
                                errorModalCallback={handleModalOpen}
                                iconUrl={`${basePath}/visuals/MetaData%20upload.png`}
                            />
                        </UploadWrapper>

                        <BackOfficeTableWrapper hasDisabledColumns>
                            <DataTable
                                styles={TABLE_STYLE}
                                minRows={0}
                                isLoading={!hasTableData}
                                isPaginated={false}
                                data={hasTableData ? tablesData.metaDataFileEntries : []}
                                columns={columns}
                                FilterControlComponent={
                                    <SearchInput
                                        customStyle={customStyle}
                                        placeholder={TRANSLATION_TEXT.SEARCH_BY_PROJECT_NAME_TEXT}
                                        className={'table-search'}
                                        isForTable
                                    />
                                }
                                isFilterable={true}
                                isScrollable={false}
                                NonSortedArrow={NonSorted}
                                SortedAscArrow={SortedAsc}
                                SortedDescArrow={SortedDesc}
                            />
                        </BackOfficeTableWrapper>
                    </div>

                    <div>
                        <BackOfficeTitle style={{ fontSize: '16px', marginTop: '40px' }}>
                            {TRANSLATION_TEXT.DATA_TITLE}
                        </BackOfficeTitle>
                        <p style={{ color: defaultTheme.blue[950] }}>{BROWSE_FILE}</p>
                        <UploadWrapper>
                            <FileUpload
                                customStyle={{ marginLeft: '30px' }}
                                importTypeId={PRESIGNED_IMPORTY_TYPE_ID.DATA}
                                loggerFunction={logFileEntryData}
                                errorModalCallback={handleModalOpen}
                                tableToRequestDataFor={TABLE_HANDLERS_MAP.DATA_UPLOADS}
                                type={'text/csv, text/plain'}
                                iconUrl={`${basePath}/visuals/Data%20Upload.png`}
                            />
                        </UploadWrapper>
                        <BackOfficeTableWrapper hasDisabledColumns>
                            <DataTable
                                styles={TABLE_STYLE}
                                isPaginated={false}
                                minRows={0}
                                isLoading={!hasTableData}
                                data={hasTableData ? tablesData.dataFileEntries : []}
                                columns={columns}
                                FilterControlComponent={
                                    <SearchInput
                                        customStyle={customStyle}
                                        placeholder={TRANSLATION_TEXT.SEARCH_BY_PROJECT_NAME_TEXT}
                                        className={'table-search'}
                                        isForTable
                                    />
                                }
                                isFilterable={true}
                                isScrollable={false}
                                NonSortedArrow={NonSorted}
                                SortedAscArrow={SortedAsc}
                                SortedDescArrow={SortedDesc}
                            />
                        </BackOfficeTableWrapper>
                    </div>
                </BackOfficeSection>

                <BackOfficeSection>
                    <div>
                        <BackOfficeTitle style={{ fontSize: '16px', marginTop: '40px' }}>
                            {TRANSLATION_TEXT.CONFIRMIT_TITLE}
                        </BackOfficeTitle>
                        {/* As search comes from the data table and its positioned absolute, it needs a place to fit in
                            And we make some between the title and the table itself*/}
                        <span className="search-spacer" style={{ display: 'block', height: '90px' }} />
                        <BackOfficeTableWrapper>
                            <DataTable
                                styles={TABLE_STYLE}
                                isPaginated={false}
                                minRows={0}
                                isLoading={!hasTableData}
                                data={hasTableData ? tablesData.confirmitPidEntries : []}
                                columns={confirmIT_columns}
                                FilterControlComponent={
                                    <SearchInput
                                        customStyle={customStyle}
                                        placeholder={TRANSLATION_TEXT.SEARCH_BY_PROJECT_NAME_TEXT}
                                        className={'table-search'}
                                        isForTable
                                    />
                                }
                                isFilterable={true}
                                isScrollable={false}
                                NonSortedArrow={NonSorted}
                                SortedAscArrow={SortedAsc}
                                SortedDescArrow={SortedDesc}
                            />
                        </BackOfficeTableWrapper>
                    </div>

                    <div>
                        <BackOfficeTitle style={{ fontSize: '16px', marginTop: '40px' }}>
                            {TRANSLATION_TEXT.MEDIA_TITLE}
                        </BackOfficeTitle>
                        <p style={{ color: defaultTheme.blue[950] }}>{BROWSE_FILE}</p>
                        <FileUpload
                            customStyle={{ marginLeft: '0px' }}
                            importTypeId={PRESIGNED_IMPORTY_TYPE_ID.IMAGES}
                            loggerFunction={logFileEntryMedia}
                            type={'image/png'}
                            errorModalCallback={handleModalOpen}
                            iconUrl={`${basePath}/visuals/Media%20Upload.png`}
                        />
                        <p style={{ marginTop: '50px', color: defaultTheme.blue[950] }}>{BROWSE_FILE}</p>
                        <FileUpload
                            customStyle={{ marginLeft: '0px' }}
                            importTypeId={PRESIGNED_IMPORTY_TYPE_ID.VIDEOS}
                            loggerFunction={logFileEntryMedia}
                            errorModalCallback={handleModalOpen}
                            type={'video/mp4'}
                            iconUrl={`${basePath}/visuals/Media%20Upload%20-%20Video.png`}
                        />
                    </div>
                </BackOfficeSection>
            </BackOfficePageWrapper>
        </div>
    );
};
const mapStateToProps = state => ({
    tablesData: getBackofficeTablesDataSelector(state),
});

const mapDispatchToProps = {
    initBackofficeTablesData: initBackofficeRequestsActionCreator,
    initConfirmItToggle: toggleConfirmitProjectActionCreator,
    logout: logoutActionCreator,
};

const BackOfficePageContainer = connect(mapStateToProps, mapDispatchToProps)(BackOfficePage);
export default BackOfficePageContainer;
