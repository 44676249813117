import { ADTESTER_PAGE } from '../../../utils/routes';

export const UPLIFTS_SELECTORS_BASED_ON_ROUTE = {
    [ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path]: 'adDetailsData',
    [ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path]: 'adsCompData',
};

export const TOP_2_TYPE = 'top 2';

export const checkAndConvertToNAValue = (value, additionalCharacters = '') => {
    if (+value === 0) return '-';
    return value + additionalCharacters;
};
