import React from 'react';
import { basePath } from '../../../../../services/utils';
import {
    ToggleSectionWrapper,
    OverviewImage,
    ToggleSectionOptions,
} from '../../../../adTester/subpages/Overview/components/overview.styles';

const ToggleSection = props => {
    const { tableScope, changeScope } = props;

    const onToggleClick = () => {
        tableScope === 'fieldwork_bpt' ? changeScope('fieldwork_adtester') : changeScope('fieldwork_bpt');
    };

    return (
        <ToggleSectionWrapper>
            <ToggleSectionOptions style={{ fontWeight: tableScope === 'fieldwork_adtester' ? 'bold' : 'normal' }}>
                {' '}
                ADTESTER{' '}
            </ToggleSectionOptions>
            <OverviewImage
                src={
                    tableScope === 'fieldwork_bpt'
                        ? `${basePath}/visuals/toggle-right.png`
                        : `${basePath}/visuals/toggle-left.png`
                }
                onClick={onToggleClick}
                cursor="pointer"
                position="relative"
                top="7px"
                width="36px"
                padding="0 5px"
                alt={'Overview Toggle Button'}
            />

            <ToggleSectionOptions style={{ fontWeight: tableScope === 'fieldwork_bpt' ? 'bold' : 'normal' }}>
                {' '}
                BPT{' '}
            </ToggleSectionOptions>
        </ToggleSectionWrapper>
    );
};

export default ToggleSection;
