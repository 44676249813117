import React, { useState, useRef } from 'react';
import { ContentWrapper, Title, EditorialWrapper, ExpandButton } from './Editorial.styles';
import { useOnClickOutside } from '../../../../common/hooks/useOnClickOutside';
import { EDITORIAL_TEXT_JSX, TRANSLATION_TEXT } from '../../../../utils/translations';
import ReusableModal from '../../../../common/components/ReusableModal/ReusableModal';
import { defaultTheme } from '../../../../utils/defaultTheme';
const Editorial = () => {
    const [show, setShow] = useState(false);

    const modalRef = useRef();
    useOnClickOutside(modalRef, () => setShow(false));

    const EditorialText = EDITORIAL_TEXT_JSX;
    const customStyle = {
        buttonBackground: defaultTheme.white.ff,
        buttonTextColor: defaultTheme.blue[900],
        titleFontSize: '18px',
        textFontSize: '16px',
        buttonMargin: '15px',
        textMargin: '25px 70px 20px 70px',
        overflowText: 'scroll',
        textAlign: 'left',
    };
    return (
        <EditorialWrapper>
            <Title>{TRANSLATION_TEXT.EDITORIAL_TITLE_TEXT}</Title>
            <ContentWrapper data-testid="ContentWrapper">{EditorialText}</ContentWrapper>
            <ExpandButton onClick={() => setShow(prev => !prev)}>Read more</ExpandButton>
            {show && (
                <ReusableModal
                    modalWrapperRef={modalRef}
                    text={EditorialText}
                    modalWidth={'800px'}
                    modalHeight={'555px'}
                    overflowText={'auto'}
                    onApply={() => setShow(false)}
                    onClose={() => setShow(false)}
                    yesText={TRANSLATION_TEXT.GOT_IT_TEXT}
                    title={TRANSLATION_TEXT.EDITORIAL_TITLE_TEXT}
                    titleAsHeader={true}
                    isAbleToDismiss={true}
                    singleButton={true}
                    customStyle={customStyle}
                />
            )}
        </EditorialWrapper>
    );
};

export default Editorial;
