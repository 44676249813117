import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';
import { StyledInput } from './InputField.styles';

export const InputField = React.forwardRef((props, ref) => {
    const { customStyle, ...rest } = props;
    return (
        <ThemeConsumer>
            {value => {
                const inputProps = {
                    theme: value,
                    customStyle,
                    ...rest,
                };
                return <StyledInput {...inputProps} ref={ref} />;
            }}
        </ThemeConsumer>
    );
});

InputField.propTypes = {
    /** Is input disabled */
    disabled: PropTypes.bool,
    /** Error style*/
    error: PropTypes.bool,
    /** custom style */
    customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

InputField.defaultProps = {
    disabled: false,
    error: false,
};
