import React, { Fragment } from 'react';
import { basePath } from '../../../../../../../services/utils';
import ReactPlayer from 'react-player/file';
import PropTypes from 'prop-types';
import * as SC from './VideoModal.styles';

const VideoModal = props => {
    const { videoProps, onClose } = props;

    return (
        <SC.BlurBackground onClick={onClose}>
            <SC.VideoWrapper onClick={e => e.stopPropagation()}>
                {videoProps.videoLink ? (
                    <Fragment>
                        <ReactPlayer
                            data-testid="VideoModal-video"
                            light={videoProps.imageLink}
                            url={videoProps.videoLink}
                            playing
                            controls
                        />
                    </Fragment>
                ) : (
                    <SC.MissingVideoImg
                        alt={'Video Unavailable Warning'}
                        src={`${basePath}/visuals/VideoUnavailable.png`}
                    />
                )}
                <SC.VideoCloseButton
                    alt={'Close Icon'}
                    onClick={onClose}
                    src={`${basePath}/visuals/close-icon-white.svg`}
                />
            </SC.VideoWrapper>
        </SC.BlurBackground>
    );
};

VideoModal.propTypes = {
    videoProps: PropTypes.shape({
        videoLink: PropTypes.string,
        imageLink: PropTypes.string.isRequired,
    }),
    onClose: PropTypes.func.isRequired,
};

export default VideoModal;
