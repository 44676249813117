import { asyncActionCreator } from '../../../utils/generalUtilities';

export const FILE_UPLOAD_TO_AWS = 'pages/backOffice/FILE_UPLOAD_TO_AWS';
export const FILE_UPLOAD_TO_AWS_ASYNC = asyncActionCreator('/pages/backOffice/FILE_UPLOAD_TO_AWS_ASYNC');

export const TOGGLE_CONFIRMIT_PROJECT = 'pages/backOffice/TOGGLE_CONFIRMIT_PROJECT';
export const TOGGLE_CONFIRMIT_PROJECT_ASYNC = asyncActionCreator('pages/backOffice/TOGGLE_CONFIRMIT_PROJECT_ASYNC');

export const INIT_BACKOFFICE_TABLE_REQUESTS = 'pages/backOffice/INIT_BACKOFFICE_TABLE_REQUESTS';
export const INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC = asyncActionCreator(
    'pages/backOffice/INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC'
);

export const FILE_UPLOAD_PROCESS_STARTED = 'pages/backOffice/FILE_UPLOAD_PROCESS_STARTED';
export const FILE_UPLOAD_PROCESS_ENDED = 'pages/backOffice/FILE_UPLOAD_PROCESS_ENDED';

export const RELOAD_BACKOFICE_TABLE_DATA = 'pages/backOffice/RELOAD_BACKOFICE_TABLE_DATA';

export const TABLE_REQUEST_METADATA_ASYNC = asyncActionCreator('pages/backOffice/TABLE_REQUEST_METADATA_ASYNC');
export const TABLE_REQUEST_DATA_UPLOAD_ASYNC = asyncActionCreator('pages/backOffice/TABLE_REQUEST_DATA_UPLOAD_ASYNC');
export const TABLE_REQUEST_CONFIRMIT_ASYNC = asyncActionCreator('pages/backOffice/TABLE_REQUEST_CONFIRMIT_ASYNC');
export const CACHING_STATUS = asyncActionCreator('pages/backOffice/CACHING_STATUS');

export const fileUploadToAwsActionCreator = payload => ({
    type: FILE_UPLOAD_TO_AWS,
    payload,
});

export const initBackofficeRequestsActionCreator = () => ({
    type: INIT_BACKOFFICE_TABLE_REQUESTS,
});

export const reloadTableDataActionCreator = tableToRequest => ({
    type: RELOAD_BACKOFICE_TABLE_DATA,
    payload: tableToRequest,
});
export const toggleConfirmitProjectActionCreator = (filename, pid) => ({
    type: TOGGLE_CONFIRMIT_PROJECT,
    payload: { filename, pid },
});

export const fileUploadProcessStartedActionCreator = () => ({
    type: FILE_UPLOAD_PROCESS_STARTED,
});
export const fileUploadProcessEndedctionCreator = () => ({
    type: FILE_UPLOAD_PROCESS_ENDED,
});
