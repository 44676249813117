import { defaultTheme } from '../../../utils/defaultTheme';
export const SECTIONS_IDS = {
    MARKET_OVERVIEW: 1,
    MARKET_INVESTMENT: 2,
    AXA_VS_COMPETITORS: 3,
    INVESTMENT_SEASONALITY: 4,
    INVESTMENT_SEASONALITY_LAST: 5,
};

export const ITEM_TYPE = {
    BAR: 'bar',
    LINE: 'line',
};
export const SPECIFIC_SORT_STRINGS = {
    AXA: 'axa',
    OTHERS: 'others',
};
export const shortenYearLabel = label => {
    const labelAsWords = label.split(' ');
    const shortenedLabel = labelAsWords[0].substring(0, 3);
    const shortenedYear = labelAsWords[1].substring(labelAsWords[1].length - 2);

    return { shortenedLabel, shortenedYear };
};

export const SECTION_BREAK_WIDTH = 1500;

export const sectionsHeadColor = {
    backgroundColor: defaultTheme.green[100],
    color: defaultTheme.green[700],
};
