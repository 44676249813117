import { combineReducers } from 'redux';
import { awarenessTabReducer } from '../tabs/awareness/data/reducer';
import { adRecallTabReducer } from '../tabs/adRecall/data/reducer';
import { considerationTabReducer } from '../tabs/consideration/data/reducer';
import { imageTabReducer } from '../tabs/image/data/reducer';
import { valueForMoneyTabReducer } from '../tabs/valueformoney/data/reducer';

export default combineReducers({
    awarenessData: awarenessTabReducer,
    adRecallData: adRecallTabReducer,
    valueForMoneyData: valueForMoneyTabReducer,
    considerationData: considerationTabReducer,
    imageData: imageTabReducer,
});
