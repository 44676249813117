import React from 'react';
import SubMenuNavigation from '../../common/components/Navigation/SubMenuNavigation';
import { Route } from 'react-router-dom';
import * as routes from '../../utils/routes';
import * as SC from '../../common/General.styles';
import OverviewContainer from './subpages/Overview/Overview.container';
import Homepage from './subpages/Homepage/Homepage';
import AdsComparison from './subpages/AdsComparison/AdsComparison.container';
import AdDetailsMetrics from './subpages/AdDetailsMetrics/AdDetailsMetrics.container';

const AdTesterPage = () => {
    return (
        <SC.PageMainWrapper>
            <SC.PageWrapper>
                <SubMenuNavigation
                    subRouteWithComponents={routes.ADTESTER_PAGE.SUB_ROUTES}
                    themeConfig={routes.ADTESTER_PAGE.config}
                />
                <div>
                    <Route exact path={routes.ADTESTER_PAGE.SUB_ROUTES.HOME_PAGE.path} component={Homepage} />
                    <Route
                        exact
                        path={routes.ADTESTER_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path}
                        component={OverviewContainer}
                    />
                    <Route
                        exact
                        path={routes.ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path}
                        component={AdsComparison}
                    />
                    <Route
                        exact
                        path={routes.ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path}
                        component={AdDetailsMetrics}
                    />
                </div>
            </SC.PageWrapper>
        </SC.PageMainWrapper>
    );
};

export default AdTesterPage;
