import { combineReducers } from 'redux';
import { GET_FIELDWORK_OVERVIEW_DATA_ASYNC, CHANGE_FIELDWORK_OVERVIEW_SCOPE } from './actions';

export const overviewReducer = (state = null, action) => {
    switch (action.type) {
        case GET_FIELDWORK_OVERVIEW_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export const fieldworkTableScopeReducer = (state = 'fieldwork_bpt', action) => {
    switch (action.type) {
        case CHANGE_FIELDWORK_OVERVIEW_SCOPE:
            return action.payload;
        default:
            return state;
    }
};

export const surveyFieldWorkReducer = combineReducers({
    data: overviewReducer,
    tableScope: fieldworkTableScopeReducer,
});
