import styled from '@emotion/styled';

export const customComponentsStyles = (themeValue, props) => ({
    control: (base, state) => controlCustomStyles(base, state, props, themeValue),
    singleValue: (base, state) => valueContainerCustomStyles(base, state, props),
    // multiValue:(base, state) => valueContainerCustomStyles(base,state,props),
    dropdownIndicator: (base, state) => indicatorsContainerCustomStyles(base, state, props),
    clearIndicator: (base, state) => clearIndicatorContainerCustomStyles(base, state, props),
    placeholder: (base, state) => placeholderCustomStyles(base, state, props),
    menu: (base, state) => menuCustomStyles(base, state, props),
    option: (base, state) => optionCustomStyles(base, state, props),
    group: (base, state) => groupCustomStyles(base, state, props),
    groupHeading: (base, state) => groupHeadingCustomStyles(base, state, props),
    indicatorSeparator: () => null,
    input: (base, state) => inputCustomStyles(base, state, props),
    container: (base, state) => containerCustomStyles(base, state, props),
});

const containerCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        ...(customStyle && customStyle.container ? customStyle.container : {}),
    };
};

const controlCustomStyles = (base, state, props, theme) => {
    const { hasError, customStyle } = props;
    const { menuIsOpen, disabled, isFocused } = state;
    // console.log(state,props);
    const hasErrorProps = hasError
        ? {
              backgroundColor: '#ffe3e3',
              borderColor: '#e57a7a',
          }
        : {};
    return {
        ...base,
        fontFamily: 'MuseoSans-300',
        borderRadius: menuIsOpen ? '3px 3px 0 0' : '3px',
        boxShadow: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: isFocused && theme && theme.lightColor ? theme.lightColor : '#ccc',
        cursor: disabled ? 'default' : 'pointer',
        minHeight: '34px',
        height: '34px',
        ...hasErrorProps,
        ':hover': {
            borderColor: theme && theme.lightColor ? theme.lightColor : '#ccc',
        },
        ...(customStyle && customStyle.control ? customStyle.control : {}),
    };
};

const valueContainerCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        ...(customStyle && customStyle.value ? customStyle.value : {}),
    };
};

const placeholderCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        ...(customStyle && customStyle.placeholder ? customStyle.placeholder : {}),
    };
};

export const IndecatorSpan = styled.span`
    padding-right: 6px;
`;

const indicatorsContainerCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        padding: '6px 8px',
        ...(customStyle && customStyle.indicator ? customStyle.indicator : {}),
    };
};

const clearIndicatorContainerCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        padding: '6px 8px',
        ...(customStyle && customStyle.clearIndicator ? customStyle.clearIndicator : {}),
    };
};

const menuCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    const menuCustomProps = customStyle && customStyle.menu ? { ...customStyle.menu } : {};

    const menuPlacementStyles =
        state.selectProps.menuPlacement === 'top'
            ? {
                  borderTop: '1px solid #ccc',
                  borderBottom: 'none',
                  marginBottom: 0,
                  boxShadow: '0 4px 4px -3px rgba(0,0,0,0.36)',
              }
            : {};

    return {
        ...base,
        fontFamily: 'MuseoSans-300',
        marginTop: '0px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: 'none',
        boxShadow: '0 5px 4px -3px rgba(0,0,0,0.36)',
        zIndex: '5',
        ...menuPlacementStyles,
        ...menuCustomProps,
    };
};

const groupCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        ...(customStyle && customStyle.group
            ? customStyle.group
            : {
                  '&:not(last-child)': {
                      borderBottom: '1px solid #ECF0F1',
                  },
              }),
    };
};

const groupHeadingCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        textAlign: 'left',
        color: '#60738F',
        lineHeight: '30px',
        fontFamily: 'MuseoSans-700',
        fontSize: '15px',
        textTransform: 'none',
        ...(customStyle && customStyle.groupHeading ? customStyle.groupHeading : {}),
    };
};

const inputCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    return {
        ...base,
        input: {
            fontFamily: 'MuseoSans-300',
            ...(customStyle && customStyle.input ? customStyle.input : {}),
        },
        ...(customStyle && customStyle.inputWrapper ? customStyle.inputWrapper : {}),
    };
};

const groupedOptionsDefaultStyles = {
    fontSize: '15px',
    paddingLeft: '25px',
    backgroundColor: '#ffffff',
    color: '#8292A8',
    fontFamily: 'MuseoSans-300',
};

const optionSelectedAndFocusedDefaultStyles = (isGrouped, isFake) => {
    return isGrouped
        ? {
              selected: {
                  backgroundColor: '#ffffff',
                  color: '#003399',
                  fontFamily: 'MuseoSans-500',
              },
              focused: {
                  backgroundColor: '#f0f0f0',
              },
              focusedAndSelected: {
                  backgroundColor: '#f0f0f0',
                  color: '#003399',
                  fontFamily: 'MuseoSans-500',
              },
          }
        : {
              selected: {
                  backgroundColor: isFake ? 'transparent' : '#1EA7FD',
                  ...(isFake ? { color: 'inherit' } : {}),
              },
              focused: {
                  backgroundColor: '#f0f0f0',
              },
              focusedAndSelected: {
                  backgroundColor: isFake ? '#f0f0f0' : '#0d81ca',
                  ...(isFake ? { color: 'inherit' } : {}),
              },
          };
};

const optionCustomStyles = (base, state, props) => {
    const { customStyle } = props;
    const { options } = state;
    const isGroupedOption = options && options.length > 0 && options.find(option => !!option.options);
    const { currentValueIsFake } = props;

    const optionDefaultProps = optionSelectedAndFocusedDefaultStyles(isGroupedOption, currentValueIsFake);
    const currentCustomStyle = customStyle && JSON.parse(JSON.stringify(customStyle));
    if (currentCustomStyle && currentValueIsFake) {
        // set eslint-disabled becuse we want to extarct the unused params from the ...rest object
        /* eslint-disable no-unused-vars */
        const { selected, focused, selectedAndFocused, ...rest } = currentCustomStyle.option;
        /* eslint-enable no-unused-vars */
        currentCustomStyle.option.selectedAndFocused = focused;
        currentCustomStyle.option.selected = rest;
    }
    const defaultSelectedProps = state.isSelected
        ? state.isFocused
            ? optionDefaultProps.focusedAndSelected
            : optionDefaultProps.selected
        : state.isFocused
        ? optionDefaultProps.focused
        : {};
    const focusedCustomProps =
        state.isFocused && currentCustomStyle && currentCustomStyle.option && currentCustomStyle.option.focused
            ? {
                  ...currentCustomStyle.option.focused,
              }
            : {};
    const selectedCustomProps =
        state.isSelected && currentCustomStyle && currentCustomStyle.option && currentCustomStyle.option.selected
            ? {
                  ...(state.isFocused && currentCustomStyle.option.selectedAndFocused
                      ? currentCustomStyle.option.selectedAndFocused
                      : currentCustomStyle.option.selected),
              }
            : {};
    const withCheckboxProps = props.withCheckboxes
        ? {
              color: (currentCustomStyle && currentCustomStyle.option && currentCustomStyle.option.color) || 'black',
              backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
          }
        : {};

    return {
        ...base,
        display: 'flex',
        ...(isGroupedOption ? groupedOptionsDefaultStyles : {}),
        ...defaultSelectedProps,
        ...(customStyle && customStyle.option ? customStyle.option : {}),
        ':active': {
            ...defaultSelectedProps,
        },
        ...withCheckboxProps,
        ...focusedCustomProps,
        ...selectedCustomProps,
    };
};

const defaultGroupStyles = {
    textAlign: 'left',
    color: '#60738F',
    lineHeight: '30px',
    fontFamily: 'MuseoSans-700',
    fontSize: '15px',
    textTransform: 'none',
};

export const customGroupStyles = ({ customStyle }) => {
    const customGroupStyleProps = customStyle && customStyle.groupStyle ? customStyle.groupStyle : {};
    const { textAlign, color, lineHeight, fontFamily, fontSize, textTransform } = customGroupStyleProps;
    const {
        textAlign: defaultTextAlign,
        color: defaultColor,
        lineHeight: defaultLineHeight,
        fontFamily: defaultFontFamily,
        fontSize: defaultFontSize,
        textTransform: defaultTextTransform,
    } = defaultGroupStyles;
    return {
        textAlign: textAlign || defaultTextAlign,
        color: color || defaultColor,
        lineHeight: lineHeight || defaultLineHeight,
        fontFamily: fontFamily || defaultFontFamily,
        fontSize: fontSize || defaultFontSize,
        textTransform: textTransform || defaultTextTransform,
    };
};

export const CustomDisplayedValue = styled.div`
    ${props =>
        props.customStyle && props.customStyle.value
            ? {
                  ...props.customStyle.value,
              }
            : ``}
`;

export const OptionWithlinkTextWrapper = styled.span`
    flex: 1;
`;

export const CheckBoxWrapper = styled.div`
    width: ${props => (props.customStyle && props.customStyle.width) || '15px'};
    height: ${props => (props.customStyle && props.customStyle.height) || '15px'};
    padding-top: 2px;
    box-sizing: border-box;
    border: 1px solid
        ${props =>
            props.isSelected
                ? props.customStyle?.selected?.borderColor || props.theme?.lightColor
                : props.customStyle?.borderColor || '#c8c8c8'};
    border-radius: 3px;
    margin-right: 8px;
    flex-shrink: 0;
    background-color: ${props =>
        props.isSelected
            ? props.customStyle?.selected?.backgroundColor || props.theme?.lightColor
            : props.customStyle?.backgroundColor || '#f1f2f7'};
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: ${props => props.customStyle?.selected?.iconFontSize || '12px'};
        color: ${props => props.customStyle?.selected?.color || '#fff'};
    }

    :hover {
        ${props =>
            props.isSelected
                ? props.customStyle?.selected?.hover || ''
                : props.customStyle?.hover || 'border: 1px solid #b8b8b8;'}
    }

    ${props => props.customStyle || ''}
`;
