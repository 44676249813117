import { GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC } from './actions';

export const imageTabReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.actions.SUCCEEDED:
            return action.payload;
        case GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.actions.FAILED:
            return {};
        default:
            return state;
    }
};
