import React from 'react';
import { ScrollPanel } from '@toluna-ui-toolkit/scroll-panel';
import { components } from 'react-select';
import { OptionWithlinkTextWrapper, CheckBoxWrapper, CustomDisplayedValue, IndecatorSpan } from './CustomStyles';

const wrapperStyles = {
    '.ScrollbarsCustom-Content': {
        display: 'block!important',
    },
};

export const customMenu = (props, scrollBarRef, customProps) => {
    const scrollPanelProps = {
        floatBar: false,
        customStyle: {
            contentWrapperWithScroll: {
                marginRight: '5px',
            },
        },
        ...customProps.scrollProps,
    };
    return (
        // </ScrollPanel>
        <ScrollPanel maxHeight={250} ref={scrollBarRef} wrapperStyles={wrapperStyles} {...scrollPanelProps}>
            {/* <SimpleBar autoHide={false} style={{ maxHeight: 200 }} ref={scrollBarRef}> */}
            {customProps.topContent}
            {props.children}
            {/* </SimpleBar> */}
        </ScrollPanel>
    );
};

export const customInput = (props, customProps) => {
    return <components.Input {...props} {...customProps} />;
};

export const customStyleDropdownIndicator = (props, customProps) => {
    const arrowClass = props.selectProps.menuIsOpen ? 'up' : 'down';
    return <IndecatorSpan className={`fa fa-caret-${arrowClass} fa-fw`} />;
};

export const customFakeSingleValue = props => {
    const { placeholder } = props.selectProps;
    return <components.SingleValue {...props}>{placeholder}</components.SingleValue>;
};

export const customDropdownIndicator = (props, customProps) => {
    // console.log(props,customProps)
    return (
        <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen
                ? customProps.customIndicator.opened
                    ? customProps.customIndicator.opened()
                    : customProps.customIndicator.default()
                : customProps.customIndicator.default()}
        </components.DropdownIndicator>
    );
};

export const customOption = (props, customProps) => {
    const { withLinks, withCheckboxes, checkboxCustomStyles, translations, themeValue } = customProps;
    return withLinks ? (
        <components.Option {...props}>
            <OptionWithlinkTextWrapper>{props.children}</OptionWithlinkTextWrapper>
            <a
                href={props.data.link}
                target="_blank"
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {translations.view}
            </a>
        </components.Option>
    ) : withCheckboxes ? (
        <components.Option {...props}>
            <CheckBoxWrapper isSelected={props.isSelected} customStyle={checkboxCustomStyles} theme={themeValue}>
                {props.isSelected && <span className={'fa fa-check'} />}

                {/* <input type="checkbox" checked={props.isSelected}></input> */}
            </CheckBoxWrapper>
            {props.children}
        </components.Option>
    ) : (
        <components.Option {...props} />
    );
};

export const customValueContainer = ({ children, getValue, ...props }, { customStyle, translations }) => {
    const values = getValue();

    // solution for menu is not close on multi selection, we use multiValue instead fo ValueContainer so we render only the first item
    if (values && values.length && values[0].value === props.data.value) {
        var valueLength = values.length;
        var optionsLength = props.selectProps.options.length;

        // Check if values are equal to options and display `All` instead of number.
        valueLength = optionsLength === valueLength ? translations.all : valueLength;
        return (
            <components.ValueContainer {...props}>
                {!props.selectProps.inputValue && (
                    //   `${valueLength} Item${valueLength != 1 ? "s" : ""} selected`}
                    <CustomDisplayedValue customStyle={customStyle}>
                        {valueLength} {translations.selected}
                    </CustomDisplayedValue>
                )}
                {React.Children.map(children, child => {
                    return child && child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    } else return null;
};
