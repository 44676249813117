import React, { useEffect } from 'react';
import MappingChart from '../../../components/MappingChart/MappingChart';
import SectionsHead from '../../../../../../../common/components/SectionsHead/SectionsHead';
import { TRANSLATION_TEXT } from '../../../../../../../utils/translations';
import HorizontalGroupedBarChart from '../../../components/HorizontalGroupedBarChart/HorizontalGroupedBarChart';
import * as TabLayout from '../../Tabs.styles';
import * as SC_PA from '../../../PreferanceAnalysis.styles';
import {
    LegendItem,
    ValueForMoneyChartLabel,
    StackedChartGroupWrapper,
    StackedChartsBox,
    StackedChartLegend,
    SeparatorLine,
    SectionTitle,
} from './ValueForMoney.styles';
import TopLegend from '../../../../../../../common/components/TopLegend/TopLegend';
import EvolutionLegend from '../../../components/EvolutionLegend/EvolutionLegend';
import {
    calculateWidthValueForMoneyBarChart,
    calculateWidthvalueForMoneyStackedBarSectionQuarter,
    calculateWidthvalueForMoneyStackedBarSectionFY,
} from '../../../../../../../utils/responsiveness';
import { useWindowSize } from '../../../../../../../common/hooks/useWindowSize';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { basePath } from '../../../../../../../services/utils';
import NoDataAvailable from '../../../../../../../common/components/NoDataAvailable/NoDataAvailable';
import { sectionsHeadColor, SECTION_TYPES } from '../../utils/utils';
import CombinedStackedBarLineChart from '../../../../../../../common/components/CombinedStackedBarLineChart/CombinedStackedBarLineChart';

const ValueForMoneyTab = ({
    valueForMoneyFetchData,
    combineStackedChartsData,
    data,
    horizontalChartsData,
    hasTopFilterOptionLoaded,
    filtersThrownError,
    appliedFilters,
}) => {
    const [width] = useWindowSize();
    useEffect(() => {
        if (hasTopFilterOptionLoaded) {
            valueForMoneyFetchData();
        }
    }, [hasTopFilterOptionLoaded]);

    const computeWidth = (chartOrd, chartWidth) => {
        if (chartOrd === 1) {
            return calculateWidthvalueForMoneyStackedBarSectionQuarter(chartWidth);
        } else {
            return calculateWidthvalueForMoneyStackedBarSectionFY(chartWidth);
        }
    };
    const customBarPaddingModifier = itemsLength => {
        switch (itemsLength) {
            case 1:
            case 2:
            case 3:
                return 0;
            case 4:
                return 0.45;
            default:
                return 0.35;
        }
    };
    const mappingSectionData = data && data?.sections?.filter(el => el.sectionType === SECTION_TYPES.BENCHMARK);
    const chartSectionData = data && data?.sections?.filter(el => el.sectionType === SECTION_TYPES.KPI);

    const hasSideFiltersApplied = appliedFilters && appliedFilters?.length > 0;
    return (
        !filtersThrownError && (
            <div>
                <TopLegend rankingLegendIsVisible={false} />
                {hasSideFiltersApplied && <EvolutionLegend />}
                <NoDataAvailable show={!data || !data.sections} />

                {data && data.sections && (
                    <>
                        {mappingSectionData && mappingSectionData.length > 0 && (
                            <SectionsHead
                                title={mappingSectionData.length ? mappingSectionData[0].sectionLabel : ''}
                                isResponsive={false}
                                customStyle={sectionsHeadColor}
                            >
                                <SectionTitle>
                                    {mappingSectionData.length ? mappingSectionData[0].benchmark.benchmarkLabel : null}
                                    <Tooltip
                                        tooltipText={TRANSLATION_TEXT.INFO_PICTOGRAM_TEXT}
                                        customStyle={{
                                            _content: {
                                                width: '230px',
                                                marginLeft: '3px',
                                                whiteSpace: 'pre-line',
                                                wordWrap: 'break-word',
                                                top: '-100px',
                                            },
                                        }}
                                    >
                                        <SC_PA.InfoPic style={{ marginRight: '5px' }}>
                                            <img src={`${basePath}/visuals/InfoIconYTD.png`} />
                                        </SC_PA.InfoPic>
                                    </Tooltip>
                                    {mappingSectionData[0].benchmark.nrRespondents !== null
                                        ? ' n=' + mappingSectionData[0].benchmark.nrRespondents
                                        : ' '}
                                </SectionTitle>
                                <MappingChart
                                    data={mappingSectionData.length ? mappingSectionData[0].benchmark : null}
                                />
                            </SectionsHead>
                        )}
                        {chartSectionData && chartSectionData.length > 0 && (
                            <SectionsHead
                                title={chartSectionData.length ? chartSectionData[0].sectionLabel : ''}
                                isResponsive={false}
                                customStyle={sectionsHeadColor}
                            >
                                <TabLayout.SectionRow style={{ justifyContent: 'space-around' }}>
                                    {horizontalChartsData &&
                                        horizontalChartsData.map((t, i) => (
                                            <div key={t.name + 'horizontal-grouped-chart'}>
                                                <ValueForMoneyChartLabel key={t.name + 'title'}>
                                                    {t.name}
                                                    <Tooltip
                                                        tooltipText={TRANSLATION_TEXT.INFO_PICTOGRAM_TEXT}
                                                        customStyle={{
                                                            _content: {
                                                                width: '230px',
                                                                marginLeft: '3px',
                                                                whiteSpace: 'pre-line',
                                                                wordWrap: 'break-word',
                                                                top: '-100px',
                                                                textAlign: 'center',
                                                                lineHeight: 'normal',
                                                            },
                                                        }}
                                                    >
                                                        <SC_PA.InfoPic style={{ marginTop: '5px', marginRight: '5px' }}>
                                                            <img src={`${basePath}/visuals/InfoIconYTD.png`} />
                                                        </SC_PA.InfoPic>
                                                    </Tooltip>
                                                    {t.respondents !== null ? ' n=' + t.respondents : ''}
                                                </ValueForMoneyChartLabel>
                                                <HorizontalGroupedBarChart
                                                    key={t.name}
                                                    data={t.chart}
                                                    colors={t.colors}
                                                />
                                                {i === 0 &&
                                                    t.colors.map((elem, j) => (
                                                        <LegendItem
                                                            key={t.name + 'color' + i + j}
                                                            barColor={elem.color}
                                                        >
                                                            <span />
                                                            {elem.name}
                                                        </LegendItem>
                                                    ))}
                                            </div>
                                        ))}
                                </TabLayout.SectionRow>
                                <TabLayout.SectionRow>
                                    {combineStackedChartsData?.length > 0 && horizontalChartsData?.length > 0 && (
                                        <SeparatorLine />
                                    )}
                                </TabLayout.SectionRow>
                                <TabLayout.SectionRow style={{ justifyContent: 'space-around' }}>
                                    {combineStackedChartsData &&
                                        combineStackedChartsData.map((chartGroupArray, index) => (
                                            <StackedChartGroupWrapper key={`group-${index}`}>
                                                <ValueForMoneyChartLabel>
                                                    {chartGroupArray[0]?.title}
                                                </ValueForMoneyChartLabel>
                                                <StackedChartsBox
                                                    style={{
                                                        width:
                                                            calculateWidthValueForMoneyBarChart(width) +
                                                            calculateWidthValueForMoneyBarChart(width) * 0.1 +
                                                            'px',
                                                    }}
                                                >
                                                    {chartGroupArray.map((t, i) => (
                                                        <div key={t.chart[0].kpiID + i + 'div'} className={i}>
                                                            <CombinedStackedBarLineChart
                                                                barData={t.chart}
                                                                lineData={[]}
                                                                badge={true}
                                                                bypassLOBFiltering
                                                                color={t.colors.map(el => ({ ...el, name: el.label }))}
                                                                customHeight={300}
                                                                customBarPadding={customBarPaddingModifier(
                                                                    t.chart.length
                                                                )}
                                                                customWidthOnPage={_width => computeWidth(i, _width)}
                                                                computeLabelFormat={d => d + '%'}
                                                                customBarFont={{
                                                                    fontSize: 10,
                                                                }}
                                                                customTicks={{
                                                                    barChart: {
                                                                        ticksHidden: true,
                                                                    },
                                                                }}
                                                                numberOfRespondents={
                                                                    t.numberOfRespondents ? t.numberOfRespondents : []
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                </StackedChartsBox>
                                                <StackedChartLegend>
                                                    {chartGroupArray[0]?.legend.map(
                                                        (elem, j) =>
                                                            index === 0 && (
                                                                <LegendItem
                                                                    key={elem.name + 'color' + index + j}
                                                                    barColor={elem.color}
                                                                >
                                                                    <span />
                                                                    {elem.label}
                                                                </LegendItem>
                                                            )
                                                    )}
                                                </StackedChartLegend>
                                            </StackedChartGroupWrapper>
                                        ))}
                                </TabLayout.SectionRow>
                            </SectionsHead>
                        )}
                    </>
                )}
            </div>
        )
    );
};
export default ValueForMoneyTab;
