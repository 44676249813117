import React from 'react';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import CombinedBarLineChart from '../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import { LegendItem, LegendItemColor } from '../../../AdDetailsMetrics/AdDetailsMetrics.styles';
import * as SC from './CopyRating.styles';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { CUSTOM_LINECHART_WIDTH_PAGE_FLAG } from '../../../../../brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/utils/utils';
import { calculateWidthLargeLineChartAdComparison } from '../../../../../../utils/responsiveness';

const CopyRating = ({ lineChartData, barChartData, combinedChartLegendData }) => {
    const [width] = useWindowSize();

    const getLineChartLOBS = () => {
        return [barChartData.name, ...lineChartData.map(line => line.name)];
    };

    return (
        <>
            <SC.ChartWrapper data-testid="ChartWrapper">
                <CombinedBarLineChart
                    visibleLOBs={getLineChartLOBS()}
                    lineData={lineChartData}
                    barData={barChartData}
                    customWidthOnPage={CUSTOM_LINECHART_WIDTH_PAGE_FLAG.ADCOMPARISON}
                    customHeight={400}
                    showBrandName={true}
                    customLineTooltip={{ tooltipFontColor: defaultTheme.blue[900] }}
                    foreignObjects={true}
                />
            </SC.ChartWrapper>

            {combinedChartLegendData && (
                <SC.LegendWrapper width={calculateWidthLargeLineChartAdComparison(width)} data-testid="LegendWrapper">
                    {combinedChartLegendData.map(item => (
                        <LegendItem key={item.name + 'color'} fontSize={'14px'} fontWeight={'400'}>
                            <LegendItemColor style={{ background: item.color }} />
                            {item.name}
                        </LegendItem>
                    ))}
                </SC.LegendWrapper>
            )}
        </>
    );
};

export default CopyRating;
