import React from 'react';
import { ReportOptionWrapper } from '../ReportOption/ReportOption.styles';
import styled from 'styled-components';

const SVG = styled.svg`
    transform: scale(0.8);
    .outer-circle,
    .second-row-checkmark,
    .second-row-line {
        transition: all 0.15s linear;
    }
    .outer-circle {
        fill: ${({ theme }) => theme.white.ff};
    }
    .outer-circle,
    .sfb,
    .sfc,
    .sfd {
        stroke: ${({ theme }) => theme.blue[900]};
        stroke-width: 1.5px;
    }
    .sfb,
    .sfe {
        fill: ${({ theme }) => theme.white.ff};
    }
    .sfc,
    .sfd,
    .sfg,
    .second-row-checkmark,
    .second-row-line {
        fill: none;
    }
    .sfd,
    .second-row-checkmark {
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .sff,
    .sfh {
        stroke: none;
    }
    .sfh {
        fill: ${({ theme }) => theme.blue[900]};
    }
    .second-row-line {
        transform: scaleX(0.75);
    }

    ${ReportOptionWrapper}:hover & {
        .outer-circle {
            fill: ${({ theme }) => theme.blue[50]};
        }
        .second-row-checkmark {
            stroke: ${({ theme }) => theme.blue[900]};
            stroke-width: 1.5px;
        }
        .second-row-line {
            transform: scaleX(1);
        }
    }
`;

const SurverFieldworkComponent = () => (
    <SVG width={140} height={140.043} viewBox="0 0 140 140.043">
        <g className="outer-circle">
            <circle className="sff" cx={70} cy={70} r={70} />
            <circle className="sfg" cx={70} cy={70} r={69.25} />
        </g>
        <g className="sfb" transform="translate(5.979 26.813)">
            <rect className="sff" width={128.787} height={88.98} rx={4} />
            <rect className="sfg" x={0.75} y={0.75} width={127.287} height={87.48} rx={3.25} />
        </g>
        <g className="sfc" transform="translate(40 61)">
            <rect className="sff" width={84} height={9} rx={4.5} />
            <rect className="sfg" x={0.75} y={0.75} width={82.5} height={7.5} rx={3.75} />
        </g>
        <g className="sfc" transform="translate(40 85)">
            <rect className="sff" width={84} height={9} rx={4.5} />
            <rect className="second-row-line" x={0.75} y={0.75} width={82.5} height={7.5} rx={3.75} />
        </g>
        <g className="sfc" transform="translate(16.001 57)">
            <rect className="sff" width={18} height={18} rx={3} />
            <rect className="sfg" x={0.75} y={0.75} width={16.5} height={16.5} rx={2.25} />
        </g>
        <g className="sfc" transform="translate(16.001 80)">
            <rect className="sff" width={18} height={18} rx={3} />
            <rect className="sfg" x={0.75} y={0.75} width={16.5} height={16.5} rx={2.25} />
        </g>
        <path className="sfd" d="M-3287.531,8944.3l2.935,2.782,5.862-6.833" transform="translate(3308.032 -8877.75)" />
        <path
            className="second-row-checkmark"
            d="M-3287.531,8944.3l2.935,2.782,5.862-6.833"
            transform="translate(3308.032 -8854.75)"
        />
        <line className="sfc" x2={127.671} transform="translate(6.25 46.076)" />
        <line className="sfc" x2={127.671} transform="translate(6.25 107.672)" />
        <g className="sfe" transform="translate(12761.001 14619.693)">
            <path
                className="sff"
                d="M -12691.439453125 -14480.400390625 C -12694.4892578125 -14480.400390625 -12697.3232421875 -14480.603515625 -12699.8759765625 -14481.005859375 L -12699.875 -14481.4443359375 C -12699.875 -14482.1279296875 -12699.8740234375 -14483.1044921875 -12699.8740234375 -14484.275390625 C -12699.8720703125 -14486.6181640625 -12699.87109375 -14489.7421875 -12699.8701171875 -14492.865234375 C -12699.8681640625 -14497.8173828125 -12699.8671875 -14502.7685546875 -12699.8662109375 -14504.609375 L -12683.4296875 -14504.609375 L -12683.59375 -14480.837890625 C -12684.84765625 -14480.6923828125 -12687.8232421875 -14480.400390625 -12691.439453125 -14480.400390625 Z"
            />
            <path
                className="sfh"
                d="M -12691.439453125 -14481.150390625 C -12688.3896484375 -14481.150390625 -12685.8017578125 -14481.3603515625 -12684.33984375 -14481.5107421875 L -12684.185546875 -14503.859375 L -12699.1162109375 -14503.859375 C -12699.1171875 -14501.634765625 -12699.1181640625 -14497.25 -12699.1201171875 -14492.865234375 C -12699.12109375 -14489.7421875 -12699.1220703125 -14486.6181640625 -12699.1240234375 -14484.275390625 C -12699.1240234375 -14483.2177734375 -12699.125 -14482.3193359375 -12699.125 -14481.65234375 C -12696.7783203125 -14481.318359375 -12694.19921875 -14481.150390625 -12691.439453125 -14481.150390625 M -12691.439453125 -14479.650390625 C -12694.2578125 -14479.650390625 -12697.4775390625 -14479.8232421875 -12700.626953125 -14480.3701171875 C -12700.623046875 -14480.369140625 -12700.6162109375 -14505.359375 -12700.6162109375 -14505.359375 L -12682.6748046875 -14505.359375 L -12682.8486328125 -14480.1748046875 C -12682.8486328125 -14480.1748046875 -12686.5380859375 -14479.650390625 -12691.439453125 -14479.650390625 Z M -12700.626953125 -14480.3701171875 C -12700.626953125 -14480.3701171875 -12700.626953125 -14480.3701171875 -12700.626953125 -14480.3701171875 C -12700.626953125 -14480.3701171875 -12700.626953125 -14480.3701171875 -12700.626953125 -14480.3701171875 Z"
            />
        </g>
    </SVG>
);

export default SurverFieldworkComponent;
