import { all, put, takeEvery } from 'redux-saga/effects';
import { CLEAR_ALL_SELECTED_FILTERS, CLEAR_SINGLE_SELECTED_FILTER } from './actions';
import { clearFiltersActionCreator, updateSideFiltersActionCreator } from '../../SideFilters/data/actions';
import { applyFiltersActionCreator } from '../../../data/actions';

function* clearAllSelectedFiltersSaga() {
    yield put(clearFiltersActionCreator());
}

function* clearSingleSelectedFilterSaga(action) {
    const { type } = action.payload;

    if (type === 'Filter') {
        yield put(updateSideFiltersActionCreator(action.payload));
        yield put(applyFiltersActionCreator({ reloadFilters: false, resetSideFiltersIfCountryChanged: true }));
    }
}

export default function* selectedFiltersSaga() {
    yield all([
        takeEvery(CLEAR_ALL_SELECTED_FILTERS, clearAllSelectedFiltersSaga),
        takeEvery(CLEAR_SINGLE_SELECTED_FILTER, clearSingleSelectedFilterSaga),
    ]);
}
