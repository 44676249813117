import React, { useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { geoNaturalEarth1, geoPath } from 'd3-geo';
import './map.css';
import { defaultTheme } from '../../../../utils/defaultTheme';
import { feature } from 'topojson-client';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import PropTypes from 'prop-types';
import { createClassName } from '../../../utils/utils';
import { ADDITIONAL_MAP_COMPONENTS, ADDITIONAL_MAP_COMPONENTS_KEYS } from './utils/utils';
import { basePath } from '../../../../services/utils';
const projection = geoNaturalEarth1()
    .scale(150)
    .translate([800 / 2, 450 / 2]);

/*
    selectedCountries, onCountryClick <- connected from WorldMap.container.js
    overviewData, countries <- connected through the page's container where this component is used
 */
const WorldMap = ({ overviewData, countries, selectedCountries, onCountryClick, onError }) => {
    const [geographies, setGeographies] = useState(null);
    const [additionalMaps, setAdditionalMaps] = useState([]);

    const getNumberOfProductsByType = useCallback(
        (countryLabel, type) => {
            let values = [];
            overviewData.map.filter(j => j.label === countryLabel).forEach(country => values.push(country[type]));

            if (values.reduce((a, b) => a + b, 0) > 0) {
                return values.reduce((a, b) => a + b, 0);
            } else {
                return 'No';
            }
        },
        [overviewData]
    );

    useEffect(() => {
        async function fetchAPI() {
            const urls = [
                `${basePath}/Json/world-110m.json`,
                `${basePath}/visuals/HK.svg`,
                `${basePath}/visuals/SG.svg`,
            ];
            const responseFromURLS = await Promise.all(urls.map(url => fetch(url)));
            await Promise.all(
                responseFromURLS.map((response, index) => {
                    if (response.status !== 200) {
                        onError(true);
                        return;
                    }
                    const contentType = response.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return response.json().then(worldData => {
                            setGeographies(feature(worldData, worldData.objects.countries).features);
                        });
                    } else {
                        return response.text().then(additionalMapData => {
                            const dataForMap = { id: index, additionalMap: additionalMapData };
                            setAdditionalMaps(prevState => [...prevState, dataForMap]);
                        });
                    }
                })
            );
        }
        fetchAPI();
    }, []);
    useEffect(() => {
        if (geographies && countries && additionalMaps?.length && selectedCountries) {
            const mapDataSorted = [...additionalMaps].sort((a, b) => a.id - b.id);
            d3.select('svg').selectAll('*').remove();

            d3.select('svg').append('g').attr('class', 'additionalMap').attr('transform', `translate(650, 100)`);
            mapDataSorted.map(({ additionalMap }, i) => {
                d3.select('.additionalMap')
                    .append('g')
                    .attr(
                        'class',
                        `additionalMapGroup_${
                            i === 0 ? ADDITIONAL_MAP_COMPONENTS_KEYS.HK : ADDITIONAL_MAP_COMPONENTS_KEYS.SG
                        }`
                    )
                    .attr('transform', `translate(${i === 0 ? '55' : '125'}, ${i === 0 ? '44' : '96'})`)
                    .append('g')
                    .html(
                        `<line stroke=${defaultTheme.blue[300]} fill="white" stroke-width="0.4"  x1=${
                            i === 0 ? '-55' : '-140'
                        } y1="24"   y2="24" /> 
                        ${additionalMap}`
                    );
            });

            const injectDataIntoAdditionalMapComponents = depedencies => {
                const { label, countryId } = depedencies;

                const currentCountryData = countries.find(el => el.countryId === countryId);

                let parentNode = d3.selectAll('.additionalMap');
                let additionalMapGroup = d3.select(`.additionalMapGroup_${label}`);

                additionalMapGroup
                    .attr('cursor', 'pointer')
                    .on('mouseover', element => {
                        const offsetX = 590; //Need this offset because tooltip hooks to the root svg element, to avoid map in front of the tooltip..
                        const offsetY = 10;
                        const pointerPos = d3.pointer(element, parentNode.node());

                        const positionData = {
                            newPositionX: pointerPos[0] + offsetX,
                            newPositionY: pointerPos[1] + offsetY,
                        };
                        d3.select('.tooltipMap').remove();
                        d3.selectAll('.countries').append('foreignObject').attr('class', 'tooltipMap');
                        renderToolTip({ currentCountryData, positionData, geoData: { name: label } });
                    })
                    .on('mouseout', () => d3.select('.tooltipMap').style('visibility', 'hidden'))
                    .on('click', () => currentCountryData && onCountryClick(currentCountryData));

                additionalMapGroup
                    .select(`#Group_${label}`)
                    .selectAll('path')
                    .attr('class', `c-label-${createClassName(label)}`)
                    .attr('fill', () => {
                        if (currentCountryData) {
                            if (selectedCountries.includes(currentCountryData.countryId)) {
                                return defaultTheme.red[300];
                            } else {
                                return currentCountryData.color;
                            }
                        }
                        return defaultTheme.grey[150];
                    });
            };

            const drawEntireMap = () => {
                geographies.forEach((geoData, index) => {
                    const currentCountryData = countries.find(elem => elem.label === geoData.properties.name);
                    d3.select('.countries')
                        .append('path')
                        .attr('key', `path-${index}`)
                        .attr('d', geoPath().projection(projection)(geoData))
                        .attr('class', `allCountries c-label-${createClassName(geoData.properties.name)}`)
                        .attr('fill', () => {
                            if (currentCountryData) {
                                if (selectedCountries.includes(currentCountryData.countryId)) {
                                    return defaultTheme.red[300];
                                } else {
                                    return currentCountryData.color;
                                }
                            }
                            return defaultTheme.grey[150];
                        })
                        .style('stroke-width', 0.5)
                        .style('stroke', defaultTheme.white.ff)
                        .on('mouseover', element => {
                            const pointerPos = d3.pointer(element);
                            d3.select('.tooltipMap').remove();
                            d3.select('.countries').append('foreignObject').attr('class', 'tooltipMap');
                            const positionData = {
                                newPositionX: pointerPos[0] - 75, // (aprox width of the tooltip)/2
                                newPositionY: pointerPos[1] - 95, //height of the tooltip + the bottom arrow
                            };
                            renderToolTip({ currentCountryData, positionData, geoData });
                        })
                        .on('mouseout', () => d3.select('.tooltipMap').style('visibility', 'hidden'))
                        .on('click', () => currentCountryData && onCountryClick(currentCountryData));
                });
                ADDITIONAL_MAP_COMPONENTS.map(depedencies => injectDataIntoAdditionalMapComponents(depedencies));
            };

            const renderToolTip = payload => {
                const { currentCountryData, positionData, geoData } = payload;
                if (currentCountryData) {
                    let tooltipElement = d3.select('.tooltipMap');

                    tooltipElement
                        .style('visibility', 'visible')
                        .style(
                            'transform',
                            'translate3d(' + positionData.newPositionX + 'px,' + positionData.newPositionY + 'px,0px)'
                        )
                        .text('')
                        .append('xhtml:div')
                        .attr('class', 'tooltipSpan')
                        .append('xhtml:div')
                        .attr('class', 'tooltipTitle');

                    d3.select('.tooltipTitle')
                        .append('xhtml:img')
                        .attr('src', currentCountryData && currentCountryData.image)
                        .attr('class', 'tooltipImage');
                    d3.select('.tooltipTitle')
                        .append('xhtml:div')
                        .attr('class', 'tooltipText')
                        .text(currentCountryData ? currentCountryData.label : geoData.properties.name);

                    tooltipElement
                        .append('xhtml:div')
                        .attr('class', 'tooltipText textArea')
                        .text(
                            getNumberOfProductsByType(currentCountryData.label, 'adTesterProducts') +
                                TRANSLATION_TEXT.AD_TESTER_PROD_TEXT
                        );
                    tooltipElement
                        .append('xhtml:div')
                        .attr('class', 'tooltipText')
                        .text(
                            getNumberOfProductsByType(currentCountryData.label, 'brandPreferenceProducts') +
                                TRANSLATION_TEXT.BPT_PRODUCTS_TEXT
                        );
                }
            };
            drawEntireMap();
        }
    }, [countries, geographies, additionalMaps, selectedCountries]);

    return (
        <div>
            <svg width={1000} height={600} viewBox="70 0 800 400" className="countries" overflow="visible">
                <g className="countries" transform="translate(100,100)" />
            </svg>
        </div>
    );
};

WorldMap.propTypes = {
    overviewData: PropTypes.shape({
        map: PropTypes.array.isRequired,
    }),
    countries: PropTypes.array.isRequired,
    selectedCountries: PropTypes.array.isRequired,
    onCountryClick: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

WorldMap.defaultProps = {
    countries: [],
    selectedCountries: [],
};

export default WorldMap;
