import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
    width:100%;
    margin: 0 auto;
    padding-bottom: 32px;
`;

export const HeroSection = styled.header`
    width: 100%;
    position: relative;
`;

export const BackgroundImage = styled.img`
    width: 100%;
`;

export const ContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.white.ff};
    padding: 2.2vw 2.6vw;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding: 22px 26px;
    }
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    img {
        width: 4%;
        max-width: 45px;
    }

    span {
        padding-left: 6px;

        @media (max-width: 1400px) {
            font-size: 13px;
        }
    }
`;

export const TextWrapper = styled.div`
    width: 70%;
    margin-top: 6vw;
    line-height: 1.5;

    h1 {
        font-size: 3.5vw;
        font-weight: normal;
        margin: 0;
    }

    h3 {
        margin: 0;
        font-size: 1.8vw;
        font-weight: normal;
    }

    @media (min-width: 1500px) {
        margin-top: 80px;

        h1 {
            font-size: 56px;
        }

        h3 {
            font-size: 28px;
        }
    }

    @media (max-width: 1280px) {
        margin-top: 61px;

        h1 {
            font-size: 38px;
        }

        h3 {
            font-size: 20px;
        }
    }
`;

export const UserPanelWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px 17px;
`;

export const ReportOptionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    transform: translateY(-50px);
    margin: 0 4px;
    margin-bottom: -16px;
`;
