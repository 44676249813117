import styled, { css } from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../../../utils/responsiveness';

export const Wrapper = styled.div``;

/* Campaign Details */
export const CampaignDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding: 25px 10px;
    width: 85%;
    margin: 20px auto;
`;

export const CampaignDetailsVideoWrapper = styled.div``;
export const StackedChartsWrapper = styled.div`
    display: flex;
    & svg {
        g:nth-of-type(3),
        g:nth-of-type(2) {
            text {
                fill: ${({ theme }) => theme.blue[900]}!important;
            }
        }
    }
    .tooltip {
        font-size: 14px;
    }
`;
export const BarChartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    .tooltip {
        display: none;
    }
`;
export const BaseLowColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 30px;
    div {
        width: 17px;
        height: 20px;
    }
    div > img {
        width: 17px;
    }
`;
export const CampaignDetailsInfoWrapper = styled.div`
    flex-grow: 3;
    box-sizing: border-box;
    padding: 4px;
    margin-left: 40px;
    margin-top: 15px;

    h2 {
        font-size: 20px;
        color: ${({ theme }) => theme.blue[950]};
        font-weight: 500;
        margin: 0;
    }
`;

export const CampaignDetailsInfoBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    grid-gap: 50px;

    h3 {
        font-size: 14px;
        color: ${({ theme }) => theme.blue[950]};
        font-weight: 700;
    }

    p {
        font-size: 14px;
        margin: 5px 0px;
        color: ${({ theme }) => theme.blue[950]};
    }
`;

/* Campaign Diagnostic */
export const CampaignDiagnosticWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    margin: 20px auto;
`;

export const CampaignDiagnosticColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &.diagnosticsLabels {
        width: 200px;
        @media screen and (max-width: 1140px) {
            width: 130px;
        }
    }
`;

export const CampaignDiagnosticCardWrapper = styled.div`
    height: 145px;
    width: 180px;
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0 3px 5px ${({ theme }) => theme.black[29]};

    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.MEDIUM}px) {
        width: 160px;
        height: 145px;
    }
`;

export const CampaignDiagnosticContent = styled.div`
    height: 100%;
    border: 1px solid ${({ theme }) => theme.blue['950-b3']};
    background: ${({ theme }) => theme.grey[50]};
    position: relative;
`;

export const CampaignDiagnosticIcon = styled.div`
    z-index: 1;
    position: relative;
    img {
        position: absolute;
        top: -10px;
        right: 56px;
        width: 50px;
        height: 50px;
        @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.MEDIUM}px) {
            right: 47px;
        }
    }
`;

export const CampaignDiagnosticTitle = styled.h3`
    margin: 4px 0px 0px 0px;
    font-size: ${props => props.fontSize};
    color: ${({ theme }) => theme.blue[950]};
    text-align: center;
`;

export const SupportDiv_UIOnly = styled.div`
    width: 22px;
    height: 13px;
    background: ${({ theme }) => theme.white.ff};
    margin: 3px auto 0px auto;
    box-shadow: 0 3px 5px ${({ theme }) => theme.black[29]};
`;

export const SupportDiv2_UIOnly = styled.div`
    width: 90px;
    height: 5px;
    background: ${({ theme }) => theme.white.ff};
    margin: 0px auto;
    box-shadow: 0 3px 5px ${({ theme }) => theme.black[29]};
`;

export const CardBarWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
`;

export const StackedBarChartLegend = styled.div`
    display: grid;
    height: 40px;
    grid-template-columns: 50% 50%;
`;

export const LegendItem = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    font-size: ${({ fontSize }) => fontSize || '11px'};
    text-align: center;
    justify-self: start;
    font-weight: ${({ fontWeight }) => fontWeight || '600'};
`;

export const LegendItemColor = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 1px;
    display: inline-block;
    margin-right: 4px;
`;

export const CampaignDiagnosticBarName = styled.div`
    display: flex;
    justify-content: left;
    font-weight: ${props => (props.isMain ? '800' : '600')};
    font-size: 13px;
    height: 52px;
    text-align: left;
    position: relative;
    color: ${props => (props.isMain ? props.theme.blue[600] : props.theme.blue[950])};
    ${({ isAnyLowBaseSize }) =>
        //If there is any element with low size in our data array
        //we set the itemLabel text that has not been affected by our 'low base size styiling'
        //and we adjust the margin so the text is on the same vertical align with the low base size elements (low base size has an image so our margin is more to the left)
        isAnyLowBaseSize &&
        css`
            .itemLabel {
                margin: 0px 0px 0px 7px;
                @media screen and (max-width: 1100px) {
                    margin: 0px 0px 12px 29px;
                }
                @media screen and (max-width: 1420px) and (min-width: 1100px) {
                    margin: 0px 0px 12px 15px;
                }
            }
        `}
    .innerCampaignWarningImage {
        margin-left: -17px;
        width: 17px;
        height: 17px;
        object-fit: contain;
        //If the element is lowBaseSize, we adjust it on different resolutions so that the image stays in the container
        ${({ isLowBaseSize }) =>
            isLowBaseSize &&
            css`
                @media screen and (max-width: 1110px) {
                    margin-left: 4px;
                    margin-top: 3px;
                }
                @media screen and (max-width: 1420px) and (min-width: 1110px) {
                    margin-left: -5px;
                }
            `}
    }
`;

export const CampaignDiagnosticStackLabels = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin: 10px 0px;

    li {
        height: ${({ heightOfLabels }) => heightOfLabels && `${heightOfLabels}px`};
        // margin: 7px 0;
        font-size: 13px;
        position: relative;
        right: 5px;
        color: ${({ theme }) => theme.blue[950]};
        font-weight: 600;
    }
`;
export const LowBaseLegend = styled.div`
    margin-top: 5px;
    margin-right: 5px;
    text-align: center;
    span {
        font-size: 14px;
        color: ${({ theme }) => theme.blue[950]};
    }
    .innerLegendWarningImage {
        width: 17px;
        margin-left: -5px;
        margin-right: 5px;
        object-fit: contain;
        vertical-align: sub;
    }
`;
/* Copy Ratings section */
