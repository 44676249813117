import React from 'react';
import UserDropdownPanelContainer from './components/UserDropdownPanel/UserDropdownPanel.container';
import ReportOption from './components/ReportOption/ReportOption';
import Editorial from './components/Editorial/Editorial';
import HomeSection from './components/HomeSection/HomeSection';
import {
    LandingPageWrapper,
    HeroSection,
    LogoWrapper,
    TextWrapper,
    BackgroundImage,
    ContentWrapper,
    UserPanelWrapper,
    ReportOptionsWrapper,
} from './LandingPage.styles';
import { connect } from 'react-redux';
import { reportOptions } from './reportOptions.config';
import { homeSections } from './homeSections.config';
import UsageOfThisTool from './components/UsageOfThisTool/UsageOfThisTool';
import { TRANSLATION_TEXT } from '../../utils/translations';
import { defaultTheme } from '../../utils/defaultTheme';
import { getUserDetailsSelector } from '../login/data/selectors';
import mapDispatchToProps from 'react-redux/lib/connect/mapDispatchToProps';
import { isUserAllowedAccess } from '../../utils/generalUtilities';
import { basePath } from '../../services/utils';

const LandingPage = ({ userDetails }) => {
    const isPageAvailable = requiredPermission => {
        const roles = userDetails.role;
        if (roles) {
            return isUserAllowedAccess(roles, requiredPermission);
        }
        return false;
    };
    return (
        <div>
            <LandingPageWrapper>
                <HeroSection>
                    <BackgroundImage src={`${basePath}/visuals/Landing_Page_BG.png`} alt="Landing Page Background" />
                    <ContentWrapper>
                        <LogoWrapper>
                            <img src={`${basePath}/visuals/AXA_Logo.png`} alt="AXA Logo" />
                        </LogoWrapper>
                        <TextWrapper>
                            <h1>{TRANSLATION_TEXT.LOGO_WRAPPER_TITLE_TEXT}</h1>
                            <h3>{TRANSLATION_TEXT.LOGO_WRAPPER_CONTENT_TEXT}</h3>
                        </TextWrapper>
                    </ContentWrapper>

                    <UserPanelWrapper>
                        <UserDropdownPanelContainer />
                    </UserPanelWrapper>
                </HeroSection>

                <ReportOptionsWrapper>
                    {reportOptions.map(option => (
                        <ReportOption
                            key={option.reportTitle}
                            icon={option.icon}
                            reportTitle={option.reportTitle}
                            color={option.color}
                            bgColor={option.bgColor}
                            path={option.path}
                            show={isPageAvailable(option.requiredPermission)}
                        />
                    ))}
                </ReportOptionsWrapper>

                <Editorial />

                {homeSections.map((section, index) => (
                    <HomeSection
                        key={section.sectionTitle}
                        sectionID={section.sectionID}
                        icon={section.icon}
                        innerIcons={section.innerIcons}
                        sectionTitle={section.sectionTitle}
                        color={section.color}
                        bgColor={section.bgColor}
                        sectionImage={section.sectionImage}
                        paragraphs={section.paragraphs}
                        outerElements={section.outerElements}
                        isImageOnRight={index % 2 !== 0}
                    />
                ))}
                <UsageOfThisTool
                    icon={`${basePath}/visuals/howToUse_icon.png`}
                    sectionTitle={TRANSLATION_TEXT.HOW_TO_TEXT}
                    color={defaultTheme.white.ff}
                    bgColor={defaultTheme.blue[300]}
                />
            </LandingPageWrapper>
        </div>
    );
};

const mapStateToProps = state => ({
    userDetails: getUserDetailsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
