import styled from 'styled-components';

export const SectionsBannerWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
    margin: 3rem auto 1rem;
    box-sizing: border-box;
    padding: 1.5rem;
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    border-radius: 2px;
    div {
        //!important used to overwrite a style from the checkbox
        //Thus allowing a vertical centering of the elements
        margin: 0 !important;
    }
`;

export const TransversalSectionsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 850px);
    justify-content: center;
`;

export const TransversalSectionsWrapperCompact = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 653px);
    align-items: center;
    justify-content: center;
    justify-items: center;
`;

export const InfoText = styled.div`
    text-align: center;
    width: 750px;
    display: block;
    margin: 20px auto;
    font-size: 14px;
`;

export const NoStyleElement = styled.div``;
