import createSagaMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import rootReducer from './reducer';
import rootSaga from './saga';
import { routerMiddleware } from 'connected-react-router';
import persistState, { loadState } from '../services/localStorage.service';
import { getPropertyValueOrDefault } from '../utils/generalUtilities';
import registerStore from 'cypress-redux/registerStore';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];

// The store is initialized with these values
const stateFromLocalStorage = loadState() || {};
const initialState = {
    authenticationInfo: {
        tokens: getPropertyValueOrDefault(stateFromLocalStorage, 'tokens', {}),
        userInformation: getPropertyValueOrDefault(stateFromLocalStorage, 'userInformation', {}),
    },
    topFiltersData: {
        selectedTopFiltersByPage: getPropertyValueOrDefault(stateFromLocalStorage, 'selectedTopFilters', {}),
        selectedOptionByPage: getPropertyValueOrDefault(stateFromLocalStorage, 'selectedTopOption', {}),
        lastAppliedTopFiltersOption: getPropertyValueOrDefault(stateFromLocalStorage, 'lastAppliedTopFilters', {}),
    },
    sideFiltersData: {
        appliedSideFilters: getPropertyValueOrDefault(stateFromLocalStorage, 'sideFilters'),
        sideFiltersSectionUi: getPropertyValueOrDefault(stateFromLocalStorage, 'sideFiltersSectionUi'),
    },
    globalOverviewSelectedCountries: getPropertyValueOrDefault(
        stateFromLocalStorage,
        'globalOverviewSelectedCountries',
        []
    ),
    adTesterOverview: {
        selectedCountry: getPropertyValueOrDefault(stateFromLocalStorage, 'adTesterOverviewSelectedCountry', null),
    },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

/* Additional Services */
store.subscribe(() => {
    const state = store.getState();
    persistState(state);
});

sagaMiddleware.run(rootSaga);

if (window.Cypress) {
    registerStore(store);
}
export default store;
