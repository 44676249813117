import { createSelector } from 'reselect';
import { getFieldworkOverviewDataSelector, getFieldworkTableScopeSelector } from './selectors';
import { getGlobalOverviewSelectedCountriesSelector } from '../../../../../common/data/selectors';
import { OVERVIEW_UTILS } from '../../../../../common/utils/utils';

export const getComputedFieldWorkOverviewTableData = createSelector(
    [getFieldworkOverviewDataSelector, getGlobalOverviewSelectedCountriesSelector, getFieldworkTableScopeSelector],
    (overviewData, selectedCountries, scope) => {
        if (overviewData) {
            const { table } = overviewData;

            /* Change the data based on the table scope */
            const dataSource = table.find(src => src.scope === scope);
            return OVERVIEW_UTILS.createFilteredOverviewTableData(dataSource.data, selectedCountries);
        }
        return [];
    }
);

export const getComputedFieldworkOverviewTableHead = createSelector(
    [getFieldworkOverviewDataSelector, getFieldworkTableScopeSelector],
    (overviewData, scope) => {
        if (overviewData) {
            const { table } = overviewData;

            /* Change the data based on the table scope */
            const dataSource = table.find(src => src.scope === scope);

            const headers = [];
            dataSource.columns.forEach(column => {
                if (!column.isHidden) {
                    headers.push({
                        Header: column.label,
                        accessor: column.id,
                        tooltip: column.tooltip,
                        disableSortBy: !column.isSortable,
                    });
                }
            });
            return headers;
        }
        return [];
    }
);

export const getFieldworkOverviewComputedMapData = createSelector(
    [getFieldworkOverviewDataSelector, getGlobalOverviewSelectedCountriesSelector],
    (overviewData, selectedCountriesList) => {
        return OVERVIEW_UTILS.createMapWithSelectedCountries(overviewData, selectedCountriesList);
    }
);
