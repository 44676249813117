import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loginReducer, authenticationReducer } from '../pages/login/data/reducers';
import { adTesterOverviewReducer } from '../pages/adTester/subpages/Overview/data/reducer';
import adTesterAdDetailsMetrics from '../pages/adTester/subpages/AdDetailsMetrics/data/reducer';
import bptPreferenceAnalysis from '../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/data/reducers';
import { isGlobalLoadingReducer } from '../common/components/Loader/data/reducer';
import { surveyFieldWorkReducer } from '../pages/surveyFieldwork/subpages/Overview/data/reducer';
import { errorNotificationReducer } from '../errorHandling/data/reducer';
import topFiltersData from '../common/components/TopFilter/data/reducer';
import { exportLinkReducer } from '../common/components/ExportButton/data/reducer';
import { displaySessionExpiredModalReducer } from '../common/components/SessionExpiredModal/data/reducer';
import { filtersReducer, globalOverviewSelectedCountriesReducer } from '../common/data/reducer';
import { backofficeReducer } from '../pages/backOffice/data/reducers';
import competitiveMedia from '../pages/competitiveMedia/subpages/CompetitiveMedia/data/reducer';
import adComparisonReducer from '../pages/adTester/subpages/AdsComparison/data/reducer';
import { sideFiltersDataReducer } from '../common/components/SideFilters/data/reducer';
import {
    transversalDataReducer,
    KPYsHandlerReducer,
} from '../pages/transversalView/subpages/TransversalView/data/reducers';
export const lastActionReducer = (state = null, action) => {
    return action;
};

export default history =>
    combineReducers({
        authenticationInfo: authenticationReducer,
        router: connectRouter(history),
        lastAction: lastActionReducer,
        login: loginReducer,
        adTesterOverview: adTesterOverviewReducer,
        adTesterAdDetailsMetrics,
        adComparison: adComparisonReducer,
        bptPreferenceAnalysis,
        competitiveMedia,
        isGlobalLoading: isGlobalLoadingReducer,
        filtersReducer,
        surveyFieldworkOverview: surveyFieldWorkReducer,
        displayErrorNotification: errorNotificationReducer,
        displaySessionExpiredModal: displaySessionExpiredModalReducer,
        topFiltersData,
        sideFiltersData: sideFiltersDataReducer,
        exportLink: exportLinkReducer,
        globalOverviewSelectedCountries: globalOverviewSelectedCountriesReducer,
        backoffice: backofficeReducer,
        transversalView: transversalDataReducer,
        KPYsTransversalViewHandler: KPYsHandlerReducer,
    });
