import React from 'react';
import { TopFiltersWrapper, FilteredDataWrapper } from './TopFilter.styles';
import TopFilter from './TopFilter';
import PropTypes from 'prop-types';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { basePath } from '../../../../services/utils';
import { getParentPathOnPagesWithSubTabs } from '../utils/utils';
import { ADTESTER_PAGE } from '../../../../utils/routes';
import { DISPLAY_BUTTON_POSITION } from '../utils/constants';
const TopFilterGroup = ({
    filters,
    isAllSelected,
    onChange,
    onSelectAll,
    onApply,
    appliedFilters,
    isDisplayButtonDisabled,
    isLoadingTopFilter,
    lastFilterSearchBarDisabled,
    disableAllSearchBars,
    cardSize,
    displayButtonPosition,
    currentPage,
    displayButtonInfo,
    reloadFiltersOnSubmit,
    forceHideLegend,
}) => {
    const currentPath = getParentPathOnPagesWithSubTabs(currentPage);

    const onFilterApply = () => {
        onApply({
            reloadFilters: reloadFiltersOnSubmit,
            resetSideFiltersIfCountryChanged: true,
        });
    };
    return (
        <div>
            <TopFiltersWrapper size={cardSize}>
                {filters.length > 0 &&
                    filters.map((filter, i) => (
                        <TopFilter
                            key={i + '-filtercard'}
                            onChange={onChange}
                            onApply={onFilterApply}
                            onSelectAll={onSelectAll}
                            hasApplyButton={i === filters.length - 1}
                            filterData={filter}
                            isAllSelected={isAllSelected}
                            cardSize={cardSize}
                            isDisplayButtonDisabled={isDisplayButtonDisabled}
                            isLoadingTopFilter={isLoadingTopFilter}
                            displayButtonInfo={displayButtonInfo}
                            displayButtonPosition={displayButtonPosition}
                            searchBarDisabled={
                                disableAllSearchBars
                                    ? true
                                    : i === filters.length - 1
                                    ? lastFilterSearchBarDisabled
                                    : false
                            }
                        />
                    ))}
            </TopFiltersWrapper>
            {appliedFilters.length > 0 &&
                currentPath !== ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path &&
                !forceHideLegend && (
                    <FilteredDataWrapper>
                        <img
                            src={`${basePath}/visuals/filteredDataLegend.png`}
                            title="Filtered Data Pattern"
                            alt="Filtered Data Pattern"
                        />
                        {TRANSLATION_TEXT.FILTERED_DATA_TEXT}
                    </FilteredDataWrapper>
                )}
            {currentPath === ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path && (
                <FilteredDataWrapper
                    customStyle={{
                        width: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <span />
                    {TRANSLATION_TEXT.FILTERED_DATA_AVERAGE_TEXT}
                </FilteredDataWrapper>
            )}
        </div>
    );
};

TopFilterGroup.propTypes = {
    filters: PropTypes.array.isRequired,
    isAllSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    isLoadingTopFilter: PropTypes.bool.isRequired,
    disableAllSearchBars: PropTypes.bool,
    cardSize: PropTypes.string,
    displayButtonInfo: PropTypes.string,
    displayButtonPosition: PropTypes.oneOf([DISPLAY_BUTTON_POSITION.BOTTOM, DISPLAY_BUTTON_POSITION.RIGHT]),
    reloadFiltersOnSubmit: PropTypes.bool,
    forceHideLegend: PropTypes.bool,
};

TopFilterGroup.defaultProps = {
    displayButtonPosition: DISPLAY_BUTTON_POSITION.RIGHT,
    reloadFiltersOnSubmit: false,
    forceHideLegend: false,
};

export default TopFilterGroup;
