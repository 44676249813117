import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserDetailsSelector } from '../../../pages/login/data/selectors';
import PropTypes from 'prop-types';
import { isUserAllowedAccess } from '../../../utils/generalUtilities';

export function ProtectedRoute({ component: Component, userPermissions, requiredPermissions, ...restOfProps }) {
    const isAllowed = isUserAllowedAccess(userPermissions.role, requiredPermissions);

    return <Route {...restOfProps} render={props => (isAllowed ? <Component {...props} /> : <Redirect to="/" />)} />;
}

const mapStateToProps = state => ({
    userPermissions: getUserDetailsSelector(state),
});

ProtectedRoute.propTypes = {
    userPermissions: PropTypes.object.isRequired,
    requiredPermissions: PropTypes.string.isRequired,
};

ProtectedRoute.defaultProps = {
    requiredPermissions: 'Admin',
};

export default connect(mapStateToProps, null)(ProtectedRoute);
