import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { apiBindings } from '../../services/apiBindings.service';
import { setErrorNotificationDisplayActionCreator } from '../../../../../errorHandling/data/actions';
import useUserType from './useUserType';
import { STATE_KEY, SHOW_NOTIFICATIONS_ON_CODE, isNotUsedAnymore } from './utils/utils';

const ExternalUserTypeProfile = props => {
    const { state, setState, setInitialState, connectValidationFunction } = props;
    const userType = useUserType(props);
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const hasUserPermissions = useMemo(() => state.hasOwnProperty('Permissions'), [state]);
    const hasDataLoaded = useCallback(() => state.hasOwnProperty(STATE_KEY), [state]);

    useEffect(() => {
        if (hasDataLoaded()) {
            setPermissions(state[STATE_KEY]?.Permissions);
        }
    }, []);

    useEffect(() => {
        if (permissions.length && hasUserPermissions) {
            const userPermissions = state?.Permissions;
            let permissionsDataBasedOnUser = permissions?.map(role => {
                const isPermissionOnUserOnIDX = userPermissions?.findIndex(
                    userPermision => userPermision.id === role.id
                );
                if (isPermissionOnUserOnIDX !== -1) {
                    return { ...userPermissions[isPermissionOnUserOnIDX] };
                }
                return { ...role, isChecked: false };
            });
            const objectToSave = {
                key: STATE_KEY,
                value: {
                    Permissions: permissionsDataBasedOnUser.filter(el => el.type !== isNotUsedAnymore),
                },
            };
            setState(objectToSave);
            setInitialState && setInitialState(objectToSave);
            setDataLoaded(true);
        }
    }, [hasUserPermissions, permissions]);

    useEffect(() => {
        if (!hasDataLoaded()) {
            (async function () {
                try {
                    const permissionsData = await apiBindings.getRoles();
                    setPermissions(permissionsData.data);
                } catch (error) {
                    const status = error.hasOwnProperty('response') ? error.response.status : null;
                    if (SHOW_NOTIFICATIONS_ON_CODE.includes(status) || status === null) {
                        dispatch(setErrorNotificationDisplayActionCreator(true));
                    }
                }
            })();
        }
    }, []);

    useEffect(() => {
        connectValidationFunction && connectValidationFunction(userType.isSelectedDataValid);
    }, [connectValidationFunction]);
    return dataLoaded && userType.renderUserType();
};

ExternalUserTypeProfile.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
};

export default ExternalUserTypeProfile;
