import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { addAsyncErrorActionCreator } from '../../../../errorHandling/data/actions';
import { getExportDataUrl } from '../../../../services/apiServer.service';
import { GET_EXPORT_LINK, GET_EXPORT_LINK_ASYNC } from './actions';
import {
    getAdTesterOverviewExportDataComputed,
    getSurveyFieldworkOverviewExportDataComputed,
    getAdTesterExportDataComputed,
    getExportDataBptAndCompetitiveAndTransversal,
} from './computedSelectors';
import * as routes from '../../../../utils/routes';
function* exportDataSaga(action) {
    try {
        yield put(GET_EXPORT_LINK_ASYNC.dispatchers.started());
        let currentPath = action.payload;
        let exportTypeOverwrite = 'excel';

        if ('path' in action.payload) {
            currentPath = action.payload.path;
        }

        if ('exportTypeOverwrite' in action.payload) {
            exportTypeOverwrite = action.payload.exportTypeOverwrite;
        }

        if (
            currentPath
                .toLowerCase()
                .indexOf(routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path.toLowerCase()) === 0
        ) {
            currentPath = routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path.toLowerCase();
        }
        //Call the computed selector for each page..
        let payloadResponseBasedOnPage;
        switch (currentPath.toLowerCase()) {
            case routes.SURVEY_FIELDWORK_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getSurveyFieldworkOverviewExportDataComputed);
                break;
            case routes.ADTESTER_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getAdTesterOverviewExportDataComputed);
                break;
            case routes.ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getAdTesterExportDataComputed);
                payloadResponseBasedOnPage.exportType = exportTypeOverwrite;
                break;
            case routes.ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getAdTesterExportDataComputed);
                payloadResponseBasedOnPage.exportType = exportTypeOverwrite;
                break;
            case routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getExportDataBptAndCompetitiveAndTransversal);
                break;
            case routes.COMPETITIVE_MEDIA.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getExportDataBptAndCompetitiveAndTransversal);
                break;
            case routes.TRANSVERSAL_VIEW_PAGE.path.toLowerCase():
                payloadResponseBasedOnPage = yield select(getExportDataBptAndCompetitiveAndTransversal);
                break;
            default:
                break;
        }

        const exportUrl = yield call(getExportDataUrl, { currentPath, payload: payloadResponseBasedOnPage });

        yield put(GET_EXPORT_LINK_ASYNC.dispatchers.succeeded(exportUrl));
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(GET_EXPORT_LINK_ASYNC.dispatchers.failed(error));
    }
}

export default function* filtersSaga() {
    yield all([takeLatest(GET_EXPORT_LINK, exportDataSaga)]);
}
