/* eslint-disable react/react-in-jsx-scope */
import { createSelector } from 'reselect';
import {
    getBptPreferenceAnalysisDataSelector,
    getBptPreferenceAnalysisTableSectionSelector,
} from '../../../data/selectors';
import { getLocationSelector } from '../../../../../../../common/components/TopFilter/data/selectors';
import { STORE_NAME_ROUTE_BASED } from '../../utils/utils';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';

export const getImageTabTableDataComputed = createSelector(getBptPreferenceAnalysisTableSectionSelector, table => {
    if (table && table.length) {
        const { tables } = table[0];
        const { data: tableData } = tables[0];

        const formattedTableData = tableData.reduce((acc, el) => {
            const { row } = el;
            const tempRow = {};
            row.forEach(rowElement => {
                tempRow[rowElement.columnId] = rowElement.value;
            });

            acc.push(tempRow);
            return acc;
        }, []);
        return formattedTableData;
    } else {
        return null;
    }
});

export const getImageTabTableHeaderComputed = createSelector([getBptPreferenceAnalysisTableSectionSelector], table => {
    if (table && table.length) {
        const { tables } = table[0];

        if (tables) {
            const { columns: tableHeader } = tables[0];

            const formattedHeaderData = tableHeader.reduce((acc, el, idx) => {
                const firstColumnIndex = 0;

                const firstColumnWidth = 20;
                const restOfColumnsWidth = 3;

                const isFirstColumn = firstColumnIndex === idx;
                const headerLabel = !isFirstColumn && 'n=' + el.nrRespondents + '\n' + el.label;

                const tempCol = {
                    Header: () => (
                        <span style={{ display: 'inline', textAlign: 'center', whiteSpace: 'pre-line' }}>
                            {headerLabel}
                        </span>
                    ),
                    Cell: row => <span title={row.value}>{row.value}</span>,
                    accessor: el.id,
                    width: isFirstColumn ? firstColumnWidth : restOfColumnsWidth,
                    disableSortBy: !el.isSortable,
                };
                acc.push(tempCol);
                return acc;
            }, []);
            return formattedHeaderData;
        } else {
            return null;
        }
    } else {
        return null;
    }
});

export const getImageTabTableComputed = createSelector(
    [getImageTabTableHeaderComputed, getImageTabTableDataComputed],
    (tableHeader, tableData) => ({ tableHeader, tableData })
);

export const getSectionIDToShowTableComputed = createSelector(getBptPreferenceAnalysisTableSectionSelector, section => {
    if (section && section.length) {
        const { sectionID } = section[0];
        return sectionID ?? -1;
    } else {
        return null;
    }
});

export const getLineChartDescriptionKpi = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        const kpiDescriptionObject = {};
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            subTabStoreData.sections.forEach(section => {
                if (!section.kpis || !section.sectionID) return;
                kpiDescriptionObject[section.sectionID] = section.kpis.map(kpi => kpi.kpiDescription);
            });
        }
        return kpiDescriptionObject;
    }
);

export const getLineChartLegendWithoutSubsectionComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];

        const processLegend = items => {
            return items.map(eachItem => {
                return {
                    name: eachItem.itemLabel,
                    color: eachItem.values[0].color,
                };
            });
        };

        const legendItems = {};
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                legendItems[section.sectionID] = section.kpis.reduce((data, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.LINE_CHART || currentKpy.kpiType === KPI_TYPES.COMBINED) {
                        data = processLegend(currentKpy.items);
                        return data;
                    }
                    return data;
                }, []);
            });
            return legendItems;
        }
    }
);
