import React from 'react';
import PropTypes from 'prop-types';
import { ToggleWrapper, Slider, Label } from './Toggle.styles';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';

export const Toggle = props => {
    const { text, checked, onChange, disabled, width, customStyle } = props;

    return (
        <ThemeConsumer>
            {theme => {
                return (
                    <ToggleWrapper onClick={() => !disabled && onChange(!checked)}>
                        <Slider
                            checked={checked}
                            disabled={disabled}
                            width={width}
                            customStyle={customStyle}
                            theme={theme}
                        />
                        <Label checked={checked} disabled={disabled} customStyle={customStyle} theme={theme}>
                            {text}
                        </Label>
                    </ToggleWrapper>
                );
            }}
        </ThemeConsumer>
    );
};

Toggle.propTypes = {
    /** Toggle text */
    text: PropTypes.string,
    /** Toggle width */
    width: PropTypes.string,
    /** Toggle Custom Style */
    customStyle: PropTypes.object,
    /** On change function */
    onChange: PropTypes.func,
    /** Toggle is checked */
    checked: PropTypes.bool,
    /** Toggle is disabled */
    disabled: PropTypes.bool,
};

Toggle.defaultProps = {
    width: '22px',
    text: '',
    checked: false,
    disabled: false,
};
