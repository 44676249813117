import React, { useEffect } from 'react';
import SideFilters from '../../../../common/components/SideFilters/components/SideFilters.container';
import {
    SIDE_FILTERS_SUB_PAGES,
    SIDE_FILTERS_PAGES,
} from '../../../../common/components/SideFilters/utils/sideFilters.config';
import DataSectionHeader from '../../../../common/components/DataSectionHeader/DataSectionHeader';
import CampaignDetails from './components/CampaignDetails/CampaignDetails';
import SelectedFilters from '../../../../common/components/SelectedFilters/components/SelectedFilters.container';
import CampaignDiagnostic from './components/CampaignDiagnostics/CampaignDiagnostic';
import CopyRatings from './components/CopyRating/CopyRatings';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import TopFilterGroup from '../../../../common/components/TopFilter/components/TopFilterGroup.container';
import { Wrapper } from './AdDetailsMetrics.styles';
import UpliftsSection from './components/UpliftsSection/UpliftsSection';
import LineGraphGroupProvider from './components/LineGraphGroup.provider';
import { SECTIONS_IDS } from './utils/utils';
import { getSectionBasedOnSectionID } from '../../../../utils/generalUtilities';
import { useFilters } from '../../../../common/hooks/useFilters';
import NoDataAvailable from '../../../../common/components/NoDataAvailable/NoDataAvailable';
import { DISPLAY_BUTTON_POSITION } from '../../../../common/components/TopFilter/utils/constants';
import { ADTESTER_PAGE } from '../../../../utils/routes';

const AdDetailsMetrics = ({
    getAdDetailsAndMetricsDataActionCreator,
    metricsData,
    hasTopFilterOptionLoaded,
    hasSideFiltersLoaded,
    upliftsData,
    filtersThrownError,
    filtersFor,
}) => {
    useFilters();

    useEffect(() => {
        if (
            hasTopFilterOptionLoaded &&
            hasSideFiltersLoaded &&
            filtersFor === ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path
        ) {
            getAdDetailsAndMetricsDataActionCreator();
        }
    }, [hasTopFilterOptionLoaded, hasSideFiltersLoaded, filtersFor]);

    const campaignDiagnosticData =
        metricsData && getSectionBasedOnSectionID(metricsData, SECTIONS_IDS.CAMPAIGN_DIAGNOSTICS);
    const copyRatingsData = metricsData && getSectionBasedOnSectionID(metricsData, SECTIONS_IDS.COPY_RATINGS);
    const lineChartsData = metricsData && getSectionBasedOnSectionID(metricsData, SECTIONS_IDS.LINE_CHART);

    return (
        <Wrapper>
            <SideFilters
                currentSubPage={SIDE_FILTERS_SUB_PAGES.adTesterDetailsAndMetrics}
                currentPage={SIDE_FILTERS_PAGES.adTester}
                showSideBarFilters={!filtersThrownError}
                isSingleSectionSelection={true}
            />

            {!filtersThrownError ? (
                <>
                    <SelectedFilters />
                    <TopFilterGroup
                        displayButtonPosition={DISPLAY_BUTTON_POSITION.BOTTOM}
                        reloadFiltersOnSubmit={true}
                    />
                    <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                        <ExportButton type={EXPORT_TYPES.EXCEL} />
                        <ExportButton type={EXPORT_TYPES.PPT} />
                    </ExportButtonContainer>
                    <DataSectionHeader title={TRANSLATION_TEXT.CAMPAIGN_DETAILS_TITLE_TEXT}>
                        <CampaignDetails />
                    </DataSectionHeader>

                    <DataSectionHeader title={campaignDiagnosticData?.sectionLabel.toUpperCase()}>
                        <CampaignDiagnostic kpis={campaignDiagnosticData?.kpis} />
                    </DataSectionHeader>

                    {copyRatingsData && (
                        <DataSectionHeader title={copyRatingsData?.sectionLabel.toUpperCase()}>
                            <CopyRatings data={copyRatingsData?.kpis} />
                        </DataSectionHeader>
                    )}
                    {upliftsData && (
                        <DataSectionHeader title={upliftsData?.sectionData?.sectionLabel?.toUpperCase()}>
                            <UpliftsSection upliftsData={upliftsData} />
                        </DataSectionHeader>
                    )}
                    {lineChartsData && (
                        <DataSectionHeader title={lineChartsData?.sectionLabel}>
                            <LineGraphGroupProvider />
                        </DataSectionHeader>
                    )}
                </>
            ) : (
                <NoDataAvailable show={filtersThrownError} />
            )}
        </Wrapper>
    );
};

export default AdDetailsMetrics;
