import { defaultTheme } from '../../../../../../utils/defaultTheme';

export const getChartAreasData = (limits, dataRanges, colors) => {
    const { meanX, maxX, minY, meanY } = dataRanges;
    return [
        {
            x: limits.left,
            y: limits.top,
            width: meanX - limits.left,
            height: meanY - limits.top,
            color: colors.GOOD_VALUE,
        },
        {
            x: meanX,
            y: limits.top,
            width: maxX - meanX,
            height: meanY - limits.top,
            color: colors.PREMIUM,
        },
        {
            x: limits.left,
            y: meanY,
            width: meanX - limits.left,
            height: minY - meanY,
            color: colors.LOW_COST,
        },
        {
            x: meanX,
            y: meanY,
            width: maxX - meanX,
            height: minY - meanY,
            color: colors.POOR_VALUE,
        },
    ];
};

export const getMappingMainAxisLines = (limits, dataRanges) => {
    const { maxX, minY, maxY } = dataRanges;
    return [
        {
            lines: [
                [limits.left, minY],
                [limits.left, limits.top - 10],
            ],
            text: {
                text: 'Quality of Offer',
                x: limits.left - 10,
                y: maxY + 96,
                rotate: '-90deg',
            },
        },
        {
            lines: [
                [limits.left, minY],
                [maxX + 10, dataRanges.minY],
            ],
            text: {
                text: 'Price',
                x: maxX - 31,
                y: minY + 19,
                rotate: '0deg',
            },
        },
    ];
};

export const getMappingLabelsData = (limits, dataRanges, colors, unScaledData) => {
    const { meanX, maxX, minY, meanY } = dataRanges;
    return [
        {
            text: 'Good Value for Money',
            x: 0,
            y: (meanY + 35) / 2,
            height: 70,
            width: limits.left,
            color: colors.GOOD_VALUE,
            textColor: colors.GOOD_VALUE_TEXT,
            fontSize: '14px',
            tooltip: null,
        },
        {
            text: 'Low Cost',
            x: 0,
            y: (minY + meanY - 35) / 2,
            height: 70,
            width: limits.left,
            color: colors.LOW_COST,
            textColor: colors.LOW_COST_TEXT,
            fontSize: '14px',
            tooltip: null,
        },
        {
            text: 'Poor Value For Money',
            x: maxX,
            y: (minY + meanY - 35) / 2,
            height: 70,
            width: limits.left,
            color: colors.POOR_VALUE,
            textColor: colors.POOR_VALUE_TEXT,
            fontSize: '14px',
            tooltip: null,
        },
        {
            text: 'Premium',
            x: maxX,
            y: (meanY + 50) / 2,
            height: 48,
            width: limits.left,
            color: colors.PREMIUM,
            textColor: colors.PREMIUM_TEXT,
            fontSize: '14px',
            tooltip: null,
        },
        {
            text: 'Mean Score for price',
            x: meanX - 100,
            y: (limits.top - 2) / 2 + 1,
            height: limits.top / 2,
            width: 200,
            color: defaultTheme.white.ff,
            textColor: defaultTheme.blue['800'],
            fontSize: '13px',
            tooltip: {
                xPos: meanX + 6,
                yPos: (limits.top - 2) / 2 + 47,
                x: unScaledData.xAxisMean,
                paddindValue: 30,
                lineClass: '.mean-y-line',
                triangleId: '#Triangle3',
            },
        },
        {
            text: 'Mean Score for quality of offer',
            x: maxX,
            y: meanY - 27,
            height: 48,
            width: limits.left,
            color: defaultTheme.white.ff,
            textColor: defaultTheme.blue['800'],
            fontSize: '13px',
            tooltip: {
                xPos: maxX,
                yPos: meanY,
                x: unScaledData.yAxisMean,
                paddindValue: 30,
                lineClass: '.mean-x-line',
                triangleId: '#Triangle2',
            },
        },
    ];
};

export const getLineLength = (x1, x2, y1, y2) => {
    const a = parseFloat(x1) - parseFloat(x2);
    const b = parseFloat(y1) - parseFloat(y2);

    return Math.sqrt(a * a + b * b);
};
