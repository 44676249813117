import { asyncActionCreator } from '../../../../../../../utils/generalUtilities';

export const GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC = asyncActionCreator(
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC'
);

export const GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_DATA =
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_DATA';

export const getBptPreferenceAnalysisAdRecallActionCreator = payload => ({
    type: GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_DATA,
    payload,
});
