import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
export {TOLUNA_ICONS} from './constants';


const BasicIcon = styled.span`
    ${props=>`
        display: inline-block;
        font-size: ${props.size ? props.size+'px' : 'inherit'};
        line-height: ${props.size ? props.size+'px' : 'inherit'};
        ${props.fixedWidth ? `
            text-align: center;
            width: 1.25em;
        ` : ``}
        ${props.isTolunaFont ? `
        &::before{
            vertical-align: middle;
        }` : ``}
    `}  
`


export const Icon = (props) => {
    const { name, size, fixedWidth , ...rest } = props;
    const isTolunaFont = name.indexOf('toluna-icon') === 0;
    let classNames = isTolunaFont ? [name] : ['fas',`fa-${name}`];
    // if (fixedWidth){
    //     classNames.push('fa-fw') 
    // }

    const iconProps = {
        size,
        fixedWidth,
        isTolunaFont,
        ...rest
    }
    return ( 
        <BasicIcon className={classNames.join(' ')} {...iconProps} />
    )
}

// Icon.TOLUNA_ICONS = toluna_icons; 

Icon.propTypes = {
    /** Name of the icon to be used */
    /** By default we are using Font-awesome, Toluna icons start with toluna-icon */
    name: PropTypes.string.isRequired,
    /** Size of the font the be applied to the icon */
    size: PropTypes.string,
    /** Set fixed width to all icons not depend on icon width */
    fixedWidth: PropTypes.bool,
};