import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    width: 99%;
    margin: 0 auto;
    @media screen and (max-width: 1280px) {
        width: 95%;
    }
`;
export const TablesWrapper = styled.div`
    display: flex;
    overflow: auto;
    align-content: space-between;
    justify-content: space-between;
    padding-top: 92px;
    padding-bottom: 90px;
    position: relative;
    ${({ isBenchmarkSection }) =>
        isBenchmarkSection &&
        css`
            padding-top: 92px;
        `}
`;
export const SectionTitle = styled.div`
    position: absolute;
    top: 50px;
    color: ${({ theme }) => theme.blue[950]};
    font-family: 'Source Sans Pro';
    font-size: 14px;
    width: 130px;
    white-space: pre-wrap;
    transform: translateX(-30%);
    font-weight: 600;
    ${({ secondaryTitle }) =>
        secondaryTitle &&
        css`
            top: 60px;
            margin-left: 115px;
            width: 100px;
            font-weight: 400;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding: 0;
                margin: 0;
            }
        `}
`;

export const MainSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const SideHeader = styled.div`
    display: flex;
    min-width: 150px;
    padding-left: 10px;
    font-size: 16px;
    left: 20px;
    display: flex;
    font-weight: 600;
    color: ${({ theme }) => theme.blue[950]};
    align-items: center;
    @media screen and (max-width: 1400px) {
        font-size: 14.5px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
    div {
        width: 100%;
        text-align: center;
    }
`;
export const Cell = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.blue[950]};
    padding-right: 20px;
    font-weight: 500;
    left: 190px;
    right: 50px;
    z-index: ${({ descriptionScope }) => descriptionScope && '20'};
    min-width: ${({ descriptionScope }) => descriptionScope && '300'}px;
    width: ${({ descriptionScope, scoreScope, chartScope, additionalWidth }) =>
        descriptionScope ? '300' : scoreScope ? '50' : chartScope && (additionalWidth ? 350 : 200)}px;
    padding-left: ${({ descriptionScope, scoreScope, chartScope }) =>
        descriptionScope ? '0' : scoreScope ? '50' : chartScope && '0'}px;

    //* Start Of Aditional styiling when there are only 2 columns - Could be left to be handled dynamically by flex, but for a nicer UI, breakpoints were needed
    @media screen and (min-width: 2000px) {
        width: ${({ descriptionScope, scoreScope, chartScope, additionalWidth }) =>
            descriptionScope ? '300' : scoreScope ? '50' : chartScope && (additionalWidth ? 450 : 200)}px;
    }
    @media screen and (max-width: 1650px) {
        width: ${({ descriptionScope, scoreScope, chartScope, additionalWidth }) =>
            descriptionScope ? '300' : scoreScope ? '50' : chartScope && (additionalWidth ? 300 : 200)}px;
    }
    @media screen and (max-width: 1540px) {
        width: ${({ descriptionScope, scoreScope, chartScope, additionalWidth }) =>
            descriptionScope ? '300' : scoreScope ? '50' : chartScope && (additionalWidth ? 230 : 200)}px;
    } //* End of additional styiling for 2 columns only

    @media screen and (max-width: 1400px) {
        min-width: ${({ descriptionScope }) => descriptionScope && '250'}px;
        width: ${({ descriptionScope, scoreScope, chartScope }) =>
            descriptionScope ? '200' : scoreScope ? '50' : chartScope && '150'}px;
    }
    @media screen and (max-width: 1110px) {
        font-size: 13px;
        min-width: ${({ descriptionScope }) => descriptionScope && '190'}px;
        width: ${({ descriptionScope, scoreScope, chartScope }) =>
            descriptionScope ? '190' : scoreScope ? '50' : chartScope && '150'}px;
    }
    .innerUpliftsWarningImage {
        width: 17px;
        margin-left: -20px;
        margin-right: 5px;
        object-fit: contain;
        vertical-align: sub;
    }
`;
export const InnerRow = styled.div`
    display: flex;
    border-bottom: dashed 1px ${({ theme }) => theme.blue[800]};
    &:last-of-type {
        border-bottom: none;
    }
`;

export const LegendRow = styled.section`
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: row;
`;
export const Row = styled.div`
    margin: 0 auto;
    z-index: 999;

    &:nth-child(odd) {
        background-color: ${({ theme }) => theme.grey[50]};
        ${Cell} {
            background-color: ${({ theme }) => theme.grey[50]};
        }
        ${SideHeader} {
            background-color: ${({ theme }) => theme.grey[50]};
        }
    }
    &:nth-child(even) {
        background-color: ${({ theme }) => theme.white.ff};
        ${Cell} {
            background-color: ${({ theme }) => theme.white.ff};
        }
        ${SideHeader} {
            background-color: ${({ theme }) => theme.white.ff};
        }
    }
    display: flex;
    border-bottom: dashed 1px ${({ theme }) => theme.blue[800]};
    &:last-of-type {
        border-bottom: none;
    }
    &:first-of-type {
        border-top: solid 2px ${({ theme }) => theme.blue[950]};
    }
`;
export const MainTableWrapper = styled.div`
    flex: ${({ isBenchmarkSection }) => (!isBenchmarkSection ? 0.5 : 1)};
    position: sticky;
    left: 0;
    background: white;
`;
export const SecondaryTableWrapper = styled.div`
    flex: 1;
    margin-left: -2px;
    ${Row} {
        @media screen and (min-width: 1160px) {
            box-shadow: inset -25px 0px 40px -5px white;
        }
    }
`;
export const BenchmarkSection = styled.div`
    border-left: ${({ areFiltered, theme }) => areFiltered && `dashed 1px ${theme.blue[800]}`};
    flex: ${({ areFiltered }) => areFiltered && `1`};
`;

export const UpliftValue = styled.div`
    width: 65px;
    white-space: nowrap;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    height: 18px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: ${({ isPositive, theme, valueIsZero }) =>
        isPositive === 'isZero' || valueIsZero ? theme.black.ff : theme.white.ff};
    font-size: 14px;
    text-align: center;
    background-color: ${({ theme, isPositive, valueIsZero }) =>
        isPositive === 'isZero' || valueIsZero
            ? 'none'
            : isPositive
            ? theme.green[400]
            : !isPositive && theme.orange[400]};
`;

export const SectionTitleCover = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.white.ff};
    box-shadow: 1px 0 5px -2px ${({ theme }) => theme.white.ff};
    opacity: 0.95;
    width: 480px;
    z-index: 90;
    height: ${({ isBenchmarkSection }) => (isBenchmarkSection ? '92px' : '60px')};
    left: 0;
    @media screen and (max-width: 1400px) {
        width: 420px;
    }
    @media screen and (max-width: 1100px) {
        width: 370px;
    }
`;

export const Legend = styled.div`
    position: absolute;
    bottom: ${({ isBenchmark }) => (isBenchmark ? 9 : 19)}px;
    right: 0;
    color: ${({ theme }) => theme.blue[950]};
    font-size: 14px;
    bottom: 15px;
    padding-top: 5px;
    @media screen and (max-width: 1400px) {
        font-size: 13px;
        right: 10px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 13px;
        right: 10px;
    }
    .isOuterLegend {
        position: absolute;
        left: -220px;
        top: 5px;
    }
    .innerLegendWarningImage {
        width: 17px;
        margin-left: -5px;
        margin-right: 5px;
        object-fit: contain;
        vertical-align: sub;
    }
`;
export const LegendItem = styled.div`
    display: flex;
    padding: 0px 5px 5px 5px;
    align-items: center;
`;
export const LegendItemColor = styled.div`
    height: 10px;
    width: 10px;
    border: solid 1px ${({ theme, border }) => (border ? border : theme.blue[600])};
    border-radius: 1px;
    margin-right: 5px;
    background-color: ${({ color }) => color};
    background-image: ${({ isUrl }) => isUrl && `url(${isUrl}) `};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
