import styled from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../../utils/responsiveness';
export const AppRouterWrapper = styled.div`
    width:90%;
    margin: 0 auto;
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {{
        width: 100%;
    }
    
`;
