import styled from 'styled-components';
import { SECTION_BREAK_WIDTH } from '../utils/utils';

const SectionInnerDetails = styled.div`
    width: 120px;
    font-size: 12px;
    color: ${({ theme }) => theme.blue[950]};
    ${({ customStyle }) => customStyle};

    @media (max-width: ${SECTION_BREAK_WIDTH}px) {
        margin: 0 0 20px;
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: center;
    }
`;

const SectionInnerSideNoteText = styled(SectionInnerDetails)``;

const SectionWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${SECTION_BREAK_WIDTH}px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;

        ${SectionInnerSideNoteText} {
            position: absolute;
            bottom: -60px;
        }
    }
`;

const SectionList = styled.div``;
const SectionKey = styled.span`
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 600;
    font-size: 12px;
`;
const SectionVal = styled.span`
    color: ${({ theme }) => theme.blue[950]};
    font-size: 12px;
    padding-right: 8px;

    @media (max-width: ${SECTION_BREAK_WIDTH}px) {
        margin-right: 23px;
    }
`;

export { SectionWrapper, SectionInnerDetails, SectionInnerSideNoteText, SectionList, SectionKey, SectionVal };
