import styled, { css } from 'styled-components';

const toolTipArrowScheleton = css`
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    border-width: 4px;
    border-style: solid;
`;

export const Wrapper = styled.div`
    position: relative;
    z-index: 10000;
    ${({ customStyle }) => customStyle};
`;

export const TooltipContent = styled.div`
    position: absolute;
    font-weight: 400;
    top: -38px;
    background-color: ${({ theme }) => theme.white.ff};
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    white-space: nowrap;
    left: 50%;
    padding: 7px;
    border-radius: 3px;
    transform: translateX(-50%);
    &::before {
        ${toolTipArrowScheleton}
        border-color: white transparent transparent transparent;
        z-index: 10;
    }

    &::after {
        ${toolTipArrowScheleton}
        border-color: ${({ theme }) => theme.white.ff} transparent transparent transparent;
        margin-top: 0px;
        z-index: 1;
    }
    ${({ customStyle }) => customStyle};
`;
