import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { apiBindings } from './usermanagement_configuration/services/apiBindings.service';
import { theme } from './usermanagement_configuration/default.theme';
import configs from './usermanagement_configuration/configs/app.config';
import { AlwaysOnLoader } from '../../common/components/Loader/components/Loader';
import { logoutActionCreator } from '../login/data/actions';
import * as SC from './userManagement.styles';
import { UserManagement } from '@toluna-dashboarding/usermanagement';
import { TRANSLATION_TEXT } from '../../utils/translations';
import ExportButtonContainer from '../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../common/components/ExportButton/components/ExportButton';

const UserManagementPage = ({ dispatch }) => {
    const logoutFunc = useCallback(() => {
        dispatch(logoutActionCreator());
    }, [dispatch]);

    return (
        <SC.UserManagementPageWrapper>
            <SC.UserManagementPageTitle>{TRANSLATION_TEXT.USER_MANAGEMENT_TITLE}</SC.UserManagementPageTitle>
            <UserManagement
                theme={theme}
                loaderComponent={AlwaysOnLoader}
                apiBindings={apiBindings}
                configs={configs}
                onTokenExpiredAction={logoutFunc}
            />
            <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_UM_TEXT} isUserManagement>
                <ExportButton type={EXPORT_TYPES.EXCEL} />
            </ExportButtonContainer>
        </SC.UserManagementPageWrapper>
    );
};

const UserManagementPageContainer = connect()(UserManagementPage);
export default UserManagementPageContainer;
