/* Reducers for shared data across different components and pages */
import { combineReducers } from 'redux';
import { GET_FILTERS_ASYNC, GLOBAL_OVERVIEW_COUNTRIES_SAVE, SET_RELATED_PAGE_FOR_FILTERS } from './actions';

export const globalOverviewSelectedCountriesReducer = (state = [], action) => {
    switch (action.type) {
        case GLOBAL_OVERVIEW_COUNTRIES_SAVE:
            return action.payload;
        default:
            return state;
    }
};

export const topFiltersReducer = (state = [], action) => {
    switch (action.type) {
        case GET_FILTERS_ASYNC.actions.SUCCEEDED:
            return action.payload.topFilters;
        default:
            return state;
    }
};

export const sideFiltersReducer = (state = [], action) => {
    switch (action.type) {
        case GET_FILTERS_ASYNC.actions.SUCCEEDED:
            return action.payload.sideFilters;
        default:
            return state;
    }
};

export const filtersThrownErrorReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FILTERS_ASYNC.actions.SUCCEEDED:
            return { status: false, errorOnPath: null };
        case GET_FILTERS_ASYNC.actions.FAILED:
            return action.payload;
        default:
            return state;
    }
};

export const filtersRelatedToPageReducer = (state = '', action) => {
    switch (action.type) {
        case SET_RELATED_PAGE_FOR_FILTERS:
            return action.payload;
        default:
            return state;
    }
};

export const filtersReducer = combineReducers({
    topFilters: topFiltersReducer,
    sideFilters: sideFiltersReducer,
    filtersThrownError: filtersThrownErrorReducer,
    filtersFor: filtersRelatedToPageReducer,
});
