import { TOGGLE_SESSION_EXPIRED_MODAL } from './actions';

export const displaySessionExpiredModalReducer = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_SESSION_EXPIRED_MODAL:
            return action.payload;
        default:
            return state;
    }
};
