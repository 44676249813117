import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NumberRespondentsTagWrapper = styled.div`
    position: relative;
`;

const NumberRespondentsTagValue = styled.div`
    position: absolute;
    left: ${({ barCoords, barTranslate }) => barCoords && barCoords.coords?.x + barTranslate - 4}px;
    top: -25px;
    width: ${({ barCoords }) => barCoords && barCoords.coords.width + 5}px;
    text-align: center;
    min-width: 15px;
    font-weight: 400;
    font-size: 10px;
    padding: 2px 1px;
    color: ${({ theme }) => theme.blue[950]};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const NumberRespondentsTag = ({ data, barCoords, barTranslate }) => {
    return (
        <NumberRespondentsTagWrapper>
            {data.map((el, idx) => {
                const numberOfRespondents = 'n=' + el.nrRespondents;
                return (
                    <NumberRespondentsTagValue
                        key={el.name}
                        barCoords={barCoords.find(c => c.id === idx)}
                        barTranslate={barTranslate}
                        title={numberOfRespondents}
                    >
                        {numberOfRespondents}
                    </NumberRespondentsTagValue>
                );
            })}
        </NumberRespondentsTagWrapper>
    );
};

const DataShape = PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    sigtest: PropTypes.number.isRequired,
    tooltip: PropTypes.string,
    evolutionMetricValue: PropTypes.number,
});
const CoordsShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    coords: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
});

NumberRespondentsTag.propTypes = {
    data: PropTypes.arrayOf(DataShape),
    barCoords: PropTypes.arrayOf(CoordsShape),
    barTranslate: PropTypes.number,
};

export default NumberRespondentsTag;
