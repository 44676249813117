import styled from 'styled-components';
import { defaultTheme } from '../../../../../../utils/defaultTheme';

export const CSSWrapper = styled.div`
    .yAxisTextSov {
        transform-box: fill-box;
        transform-origin: center;
        transform: rotate(-90deg);
        opacity: 0.8;
        font-size: 145%;
        fill: ${defaultTheme.blue[900]};
    }

    .xAxisSov {
        text {
            color: ${defaultTheme.blue[900]};
            font-weight: 600;
            font-size: 120%;
        }
    }

    .lineTextSov {
        color: ${defaultTheme.blue[900]};
        font-weight: 600;
        font-size: 14px;
    }

    .barSovText {
        font-weight: 600;
        font-size: 12px;
        alignment-baseline: middle;
        color: ${defaultTheme.blue[900]};
    }

    .sovText {
        color: white;
        font-size: 12px;
    }

    @media (max-width: 1250px) {
        .barSovText {
            font-size: 10px;
            paint-order: stroke;
            stroke: #e8e8e8;
            stroke-width: 2px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
        }

        .lineTextSov {
            font-size: 12px;
            paint-order: stroke;
            stroke: #e8e8e8;
            stroke-width: 2px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
        }
    }
`;
export const SovSvg = styled.svg`
    background: ${defaultTheme.grey[50]};
    border: 2px dotted ${defaultTheme.blue[900]};
`;

export const SOVGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const SOVChartWrapper = styled.div`
    margin: 0 10px 22px 10px;

    @media (max-width: 1250px) {
        margin: 0 2px 22px 2px;
    }
`;
