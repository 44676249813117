import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_FIELDWORK_OVERVIEW_DATA_ACTION_CREATOR, GET_FIELDWORK_OVERVIEW_DATA_ASYNC } from './actions';
import { getSurveyFieldWorkOverviewData } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { getAuthTokensSelector } from '../../../../login/data/selectors';

function* getOverviewData(action) {
    const tokens = yield select(getAuthTokensSelector);

    if (Object.keys(tokens).length > 0) {
        yield put(GET_FIELDWORK_OVERVIEW_DATA_ASYNC.dispatchers.started());
        try {
            const fieldworkOverviewData = yield call(getSurveyFieldWorkOverviewData);
            yield put(GET_FIELDWORK_OVERVIEW_DATA_ASYNC.dispatchers.succeeded(fieldworkOverviewData));
        } catch (error) {
            yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
            yield put(GET_FIELDWORK_OVERVIEW_DATA_ASYNC.dispatchers.failed(error));
        }
    }
}

export default function* surveyFieldworkOverviewSaga() {
    yield all([yield takeLatest(GET_FIELDWORK_OVERVIEW_DATA_ACTION_CREATOR, getOverviewData)]);
}
