import { createSelector } from 'reselect';
import { getLineChartsDataSelector } from './selectors';
import {
    getLocationSelector,
    getSelectedTopFiltersByPageSelector,
} from '../../../../../common/components/TopFilter/data/selectors';
import { getUserDetailsSelector } from '../../../../login/data/selectors';
import { ADTESTER_PAGE } from '../../../../../utils/routes';

export const getLineChartOptionsComputedSelector = createSelector(getLineChartsDataSelector, kpiSection => {
    let options = [];
    const optionsObject = {};

    if (kpiSection) {
        kpiSection.kpis.forEach(elem => {
            const { items } = elem;

            items.forEach((item, index) => {
                const key = item.itemLabel.replace(/\s/g, '');
                if (!optionsObject.hasOwnProperty(key)) {
                    optionsObject[key] = {
                        id: key + index,
                        name: item.itemLabel,
                        color: item.values[0].color,
                        checked: true,
                    };
                }
            });
        });
        /* Turn object back into an array */
        Object.keys(optionsObject).forEach(key => {
            options.push({ ...optionsObject[key] });
        });
    }
    return options;
});
export const getSelectedCountryFromTopFiltersComputedSelectorAdDetails = createSelector(
    [getSelectedTopFiltersByPageSelector, getLocationSelector, getUserDetailsSelector],
    (selectedFilters, location, userDetails) => {
        const COUNTRY_GROUP_ID = 2;
        if (selectedFilters) {
            /* If filters have loaded it means that we have a property with the page path in the selected filters */
            if (
                Object.keys(selectedFilters).find(el =>
                    el.includes(ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path)
                )
            ) {
                const currentCountry = selectedFilters[ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path].find(
                    el => el.groupId === COUNTRY_GROUP_ID
                );
                return { countryId: currentCountry.id };
            } else {
                /* If filters have not loaded yet, get the user's country from userInformation */
                return { countryId: userDetails.countryId[0] };
            }
        }
        return {};
    }
);
