import { basePath } from '../../../../../../../services/utils';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../../../../../utils/translations';

export const LEGEND_COLORS = {
    SAMPLE_RECOGNIZED: defaultTheme.blue[600],
    SAMPLE_RECOGNIZED_ADS_COMP: defaultTheme.orange[700],
    SAMPLE_NOT_RECOGNIZED: defaultTheme.white.ff,
    SAMPLE_NOT_RECOGNIZED_BENCHMARK: defaultTheme.blue[50],
    SAMPLE_SIZE_LOW: defaultTheme.blue[50],
    SAMPLE_NOT_RECOGNIZED_ADS_COMP: defaultTheme.orange[700],
}; //LEGEND Color with lines to be fixed at chart task
export const LEGEND_MAP = [
    {
        name: TRANSLATION_TEXT.SAMPLE_RECOGNIZED_TEXT,
        color: [
            LEGEND_COLORS.SAMPLE_NOT_RECOGNIZED_BENCHMARK,
            LEGEND_COLORS.SAMPLE_RECOGNIZED,
            LEGEND_COLORS.SAMPLE_RECOGNIZED_ADS_COMP,
        ],
        hasIcon: false,
    },
    {
        name: TRANSLATION_TEXT.SAMPLE_NOT_RECOGNIZED_TEXT,
        color: [LEGEND_COLORS.SAMPLE_NOT_RECOGNIZED, LEGEND_COLORS.SAMPLE_NOT_RECOGNIZED_ADS_COMP],
        hasIcon: false,
    },
    {
        name: TRANSLATION_TEXT.SAMPLE_SIZE_LOW,
        color: LEGEND_COLORS.SAMPLE_SIZE_LOW,
        hasIcon: `${basePath}/visuals/lowBaseWarning.png`,
    },
];

export const MIN_SAME_TYPE_LENGTH = 2; //If there are only two columns of the same type, add extra spacing for a improved UI. In other cases (>3, leave flex layout to do its job.)

export const LEGEND_ID = 3;
