import styled, { css } from 'styled-components';
import { defaultTheme } from '../../../../../../utils/defaultTheme';

const toolTipArrowScheleton = css`
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    border-width: 8px;
    border-style: solid;
`;

export const Container = styled.div`
    margin: 10px;
`;

export const ButtonEvolutionMetrics = styled.button`
    display: block;
    margin: 0 auto;
    background: transparent;
    border: 0;
    border-radius: 15px;
    width: 220px;
    height: 30px;
    font-size: 14px;
    color: ${defaultTheme.brown[400]};
    font-weight: 500;
    &:hover {
        background: ${defaultTheme.brown[300]};
        cursor: pointer;
        font-weight: 600;
    }
`;

export const DataWrapper = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    padding: 10px 0 0 0;
    margin: 0 auto;
    background: white;
    border: 0;
    border-radius: 4px;
    width: 400px;
    height: ${props => props.length + 'px'};
    font-size: 14px;
    color: ${defaultTheme.brown[400]};
    font-weight: 500;
    text-align: center;
    z-index: 100000;
    position: absolute;
    box-shadow: 0px 0px 9px #00000054;
    left: 50%;
    margin-left: -200px;
    margin-top: 10px;
    &::before {
        ${toolTipArrowScheleton}
        border-color: transparent transparent white transparent;
        margin-top: -15px;
        z-index: 10;
    }
`;

export const CountryHeader = styled.div`
    height: 40px;
    width: 150px;
    /* width: fit-content; */
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
        margin-left: 8px;
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.blue[900]};
    }

    & > img {
        box-shadow: 0px 0px 4px ${defaultTheme.black['29']};
    }
`;

export const ElementContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
`;
