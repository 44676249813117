import React from 'react';
import TopFilterGroup from '../../../../common/components/TopFilter/components/TopFilterGroup.container';
import * as SC from './PreferanceAnalysis.styles';
import AwarenessTabContainer from './tabs/awareness/components/Awareness.tab.container';
import AdRecallTabContainer from './tabs/adRecall/components/AdRecall.tab.container';
import ConsiderationTabContainer from './tabs/consideration/components/Consideration.tab.container';
import ImageTabContainer from './tabs/image/components/Image.tab.container';
import ValueForMoneyTabContainer from './tabs/valueformoney/components/ValueForMoney.tab.container';
import { Route } from 'react-router-dom';
import PreferenceAnalysisTabs from '../../../brandPreferenceTracker/subpages/PreferenceAnalysis/PreferenceAnalysisTabs';
import * as routes from '../../../../utils/routes';
import { TOP_FILTER_CARD_SIZE } from '../../../../common/components/TopFilter/utils/constants';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { useFilters } from '../../../../common/hooks/useFilters';
import { getFiltersThrownError } from '../../../../common/data/selectors';
import { connect } from 'react-redux';
import NoDataAvailable from '../../../../common/components/NoDataAvailable/NoDataAvailable';
import {
    SIDE_FILTERS_PAGES,
    SIDE_FILTERS_SUB_PAGES,
} from '../../../../common/components/SideFilters/utils/sideFilters.config';
import SideFilters from '../../../../common/components/SideFilters/components/SideFilters.container';
import SelectedFilters from '../../../../common/components/SelectedFilters/components/SelectedFilters.container';
import { getSideFiltersSelector } from './../../../../common/components/SideFilters/data/selectors';

const PreferenceAnalysis = ({ filtersThrownError, hasSideFilters }) => {
    useFilters();
    return (
        <SC.Wrapper>
            <PreferenceAnalysisTabs tabsComponents={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS} />
            {!filtersThrownError ? (
                <>
                    <SelectedFilters />
                    <TopFilterGroup
                        cardSize={TOP_FILTER_CARD_SIZE.SMALL}
                        lastFilterSearchBarDisabled={true}
                        reloadFiltersOnSubmit={true}
                        forceHideLegend={true}
                    />
                    <SideFilters
                        currentSubPage={SIDE_FILTERS_SUB_PAGES.preferenceAnalysis}
                        currentPage={SIDE_FILTERS_PAGES.brandPreferenceTracker}
                        showSideBarFilters={hasSideFilters}
                        isSingleSectionSelection={true}
                    />
                    <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                        <ExportButton type={EXPORT_TYPES.PPT} text={TRANSLATION_TEXT.EXPORT_TEXT} />
                    </ExportButtonContainer>
                </>
            ) : (
                <NoDataAvailable show={filtersThrownError} />
            )}
            <>
                <Route
                    exact
                    path={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path}
                    component={AwarenessTabContainer}
                />
                <Route
                    path={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path}
                    component={ConsiderationTabContainer}
                />
                <Route path={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path} component={ImageTabContainer} />
                <Route
                    path={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path}
                    component={AdRecallTabContainer}
                />
                <Route
                    path={routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.VALUE_FOR_MONEY.path}
                    component={ValueForMoneyTabContainer}
                />
            </>
        </SC.Wrapper>
    );
};

const mapStateToProps = state => ({
    filtersThrownError: getFiltersThrownError(state),
    hasSideFilters: Object.keys(getSideFiltersSelector(state)).length > 0,
});

const PreferenceAnalysisContainer = connect(mapStateToProps, null)(PreferenceAnalysis);

export default PreferenceAnalysisContainer;
