import styled from 'styled-components';
import { defaultTheme } from '../../../../../../utils/defaultTheme';

export const MappingChartWrapper = styled.div`
    margin: 0 auto;

    /* Mapping Component */
    .mapping-tooltip {
        display: inline-flex;
        height: auto;
        margin: 0 auto;
        padding: 0.5rem;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: white;
        color: black;
        pointer-events: none;
        font-size: 0.7rem;
        box-shadow: 0px 0px 8px #00000026;

        h6 {
            text-align: center;
            font-size: 13px;
            color: ${defaultTheme.blue[800]};
            font-weight: 600;
            margin: 0px 2px 14px 0px;
        }

        h6:before {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            background: ${defaultTheme.blue[800]};
            transform: rotate(45deg);
            margin-left: 7px;
            margin-right: 7px;
        }

        p {
            font-size: 13px;
            margin: 0;
            color: ${defaultTheme.blue[800]};
        }
    }
    .mapping-tooltip:after {
        content: ' ';
        height: 0;
        position: absolute;
        width: 0;
        left: 95px;
        bottom: -6px;

        border: 10px solid transparent;
        border-top-color: white;
    }
    .mappingLabelGroup {
        padding: 4px 3px;
        box-sizing: border-box;
        display: flex;
        text-align: center;
    }
    .mappingLabelGroupText {
        margin: auto;
        font-weight: 600;
    }
`;
export const SvgContainer = styled.svg`
    position: relative;

    .mapping-tooltip-foreign-object {
        text-align: center;
        padding-top: 16px;
    }
`;
