import styled from 'styled-components';

const SOVGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const SOVChartWrapper = styled.div`
    margin: 0 10px 22px 10px;

    @media (max-width: 1250px) {
        margin: 0 2px 22px 2px;
    }
`;

export { SOVGroupWrapper, SOVChartWrapper };
