/* @@@ General responsiveness */
export const MEDIA_QUERY_SCREEN_SIZE = {
    SMALL: 1300,
    MEDIUM: 1350,
};
export const BAR_CHARTS_RESPONSIVE_WIDTHS = {
    SMALL: 140,
    MEDIUM: 160,
    LARGE: 280,
    EXTRA_LARGE: 360,
};
export const TOPBAR_CHARTS_RESPONSIVE_WIDTHS = {
    SMALL: 130,
    LARGE: 130,
};
export const calculateForResolution = width => {
    if (width < 1024) return 922;
    else if (width > 1300) return width - 0.15 * width;
    else if (width < 1300) return width - 0.04 * width;
    else return width - 0.5 * width;
};

/* @@@ BPT Page Responsiveness */
export const RK_TABLE_MEDIA_QUERY_SCREEN = {
    BREAKING_POINTS: {
        LARGE: 1520,
    },
    BAR_CHARTS_RESPONSIVE_WIDTHS: {
        SMALL: 100,
        LARGE: 120,
    },
};

export const calculateWidthMarketInvestmentStackedBar = width => {
    if (width > 1820) return 570;
    else if (width >= 1700 && width <= 1820) return 460;
    else if (width < 1700 && width > 1500) return width - 0.71 * width - 7;
    else if (width <= 1500 && width > 1300) return width - 0.72 * width;
    else if (width <= 1300 && width > 1069) return width - 0.65 * width;
    return 350;
};

export const calculateWidthMediaMixStackedBarSection = width => {
    const additionalPadding = 60;
    if (width > 1820) return 585 + additionalPadding;
    else if (width >= 1700 && width <= 1820) return 485 + additionalPadding;
    else if (width < 1700 && width > 1500) return width - 0.7 * width - 3 + additionalPadding;
    else if (width <= 1500 && width > 1300) return width - 0.71 * width + additionalPadding;
    else if (width <= 1300 && width > 1069) return width - 0.63 * width + additionalPadding;
    return 360 + additionalPadding;
};
export const calculateWidthAwarenessTabSection = width => {
    if (width > 1700) return 450;
    else if (width < 1700 && width > 1300) return width - 0.78 * width;
    else if (width < 1024) return 267; //it will remain at this width the entire time
    return width - 0.74 * width;
};
export const calculateWidthvalueForMoneyStackedBarSectionFY = width => {
    const additionalPadding = 60;
    if (width > 1820) return 300;
    else if (width >= 1700 && width <= 1820) return 280;
    else if (width < 1700 && width > 1300) return width - 0.9 * width + additionalPadding;
    else if (width <= 1300 && width > 1130) return width - 0.88 * width + additionalPadding;
    else if (width <= 1024) return 120 + additionalPadding; //it will remain at this width the entire time
    return 120 + additionalPadding;
};
export const calculateWidthvalueForMoneyStackedBarSectionQuarter = width => {
    const additionalPadding = 60;
    if (width > 1820) return 450;
    else if (width >= 1700 && width <= 1820) return 400;
    else if (width < 1700 && width > 1300) return width - 0.8 * width + additionalPadding;
    else if (width <= 1300 && width > 1130) return width - 0.78 * width + additionalPadding;
    else if (width <= 1024) return 180 + 60; //it will remain at this width the entire time
    return 210 + additionalPadding;
};
export const calculateWidthValueForMoneyBarChart = width => {
    if (width > 1820) return 700;
    else if (width >= 1700 && width <= 1820) return 640;
    else if (width < 1700 && width > 1300) return width - 0.64 * width;
    else if (width < 1024) return 400; //it will remain at this width the entire time
    return width - 0.59 * width;
};
export const calculateWidthValueForSOVChart = width => {
    if (width > 1820) return 620;
    else if (width >= 1700 && width <= 1820) return 520;
    else if (width < 1700 && width > 1300) return width - 0.68 * width;
    else if (width < 1069) return 400; //it will remain at this width the entire time
    return width - 0.6 * width;
};
export const calculateWidthForStackedChartWithMidvalues = width => {
    if (width > 1820) return 490;
    else if (width >= 1700 && width <= 1820) return 350;
    else if (width < 1700 && width > 1300) return width - 0.68 * width;
    else if (width < 1024) return 400;
    return width - 0.59 * width;
};
export const calculateWidthLargeLineChartAdRecall = (width, fluidWidth) => {
    if (fluidWidth) {
        if (width > 1300) return width * 0.85 - 10;
        if (width < 1024) return 900;
        return width * 0.93;
    } else if (width < 1700 && width > 1300) return width - 0.55 * width;
    else if (width < 1024) return 520;
    return width - 0.49 * width;
};
export const calculateWidthLargeLineChartImage = width => {
    if (width > 1700) return 550;
    else if (width < 1700 && width > 1300) return width - 0.64 * width;
    else if (width < 1024) return 400; //it will remain at this width the entire time
    return width - 0.64 * width;
};
export const calculateWidthLargeLineChartAdComparison = width => {
    if (width > 1300) return width - 0.2 * width;
    else if (width < 1024) return 900; //it will remain at this width the entire time
    return width - 0.1 * width;
};

export const calculateWidthLargeCombinedChartMarketOverviewCompetitive = width => {
    if (width > 1700) return 1000;
    else if (width < 1700 && width > 1300) return width - 0.45 * width;
    return 700;
};

export const calculateWidthCombinedMarketInvestmentCompetitive = width => {
    if (width > 1820) return 650;
    else if (width >= 1700 && width <= 1820) return 550;
    else if (width < 1700 && width > 1300) return width - 0.66 * width;
    else if (width <= 1300 && width > 1024) return width - 0.58 * width;
    return 420;
};

export const calculateWidthInsurersInvestmentCompetitive = width => {
    if (width > 1800) return 1400;
    else if (width < 1650 && width > 1500) return 1150;
    return 1280;
};
