import React, { useState, useLayoutEffect } from 'react';
import { Form, InputWrapper, ErrorLabel, Input, SubmitButton, SecondaryAction } from '../login.styles';
import { validateEmail } from '../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../utils/translations';

const Login = ({ onLogin, onLoginError, hasOnLoginError, goToForgotPassword }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const submitHandler = e => {
        e.preventDefault();

        if (validateEmail(email)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }

        if (password.length > 0) {
            setIsPasswordValid(true);
        } else {
            setIsPasswordValid(false);
        }

        if (validateEmail(email) && password.length > 0) {
            onLogin({
                username: email,
                password: password,
            });
            setEmail('');
            setPassword('');
        }
    };
    //In case the user starts typing again after having an login error, clear the error
    //Using layoutEffect to avoid the text being displayed for a fraction of a second when switching from Forgot password, back to login
    useLayoutEffect(() => {
        if (onLoginError) {
            hasOnLoginError(false);
        }
    }, [email, password]);
    return (
        <>
            <Form onSubmit={submitHandler}>
                <InputWrapper>
                    <ErrorLabel validation={!onLoginError} isAboveInputs>
                        {onLoginError}
                    </ErrorLabel>
                    <Input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        placeholder={TRANSLATION_TEXT.LOGIN_EMAIL_INPUT_TEXT}
                        name="email"
                        withError={!isEmailValid}
                        onFocus={() => setIsEmailValid(true)}
                    />
                    <ErrorLabel validation={isEmailValid} htmlFor="email">
                        <p>{TRANSLATION_TEXT.LOGIN_ERROR_LABEL_USER_TEXT}</p>
                    </ErrorLabel>
                </InputWrapper>
                <InputWrapper>
                    <Input
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        placeholder={TRANSLATION_TEXT.LOGIN_PASSWORD_INPUT_TEXT}
                        name="password"
                    />
                    <ErrorLabel validation={isPasswordValid} htmlFor="password">
                        <p>{TRANSLATION_TEXT.LOGIN_ERROR_LABEL_PASSWORD_TEXT}</p>
                    </ErrorLabel>
                </InputWrapper>
                <SubmitButton type="submit">{TRANSLATION_TEXT.LOGIN_SUBMIT_TEXT}</SubmitButton>
            </Form>
            <SecondaryAction onClick={goToForgotPassword}>
                {TRANSLATION_TEXT.LOGIN_RESET_PASS_BUTTON_TEXT}
            </SecondaryAction>
        </>
    );
};

export default Login;
