import styled from 'styled-components';

export const SideFiltersWrapper = styled.div`
    z-index: 99999;
    position: absolute;
    top: 118px;
    left: 0;
    height: ${({ isOpen }) => (isOpen ? 'auto' : '290px')};
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    width: ${({ isOpen }) => (isOpen ? '280px' : '46px')};
    box-shadow: ${({ theme, isOpen }) =>
        isOpen ? `0px 6px 6px ${theme.black[29]}` : `-2px 1px 6px 0px ${theme.black[29]}`};
    background-color: ${({ theme }) => theme.white.ff};
    display: ${({ showSideBarFilters }) => (showSideBarFilters ? 'auto' : 'none')};
    @media (max-width: 1300px) {
        transform: translateX(0);
    }
`;

export const SideFiltersHeader = styled.div`
    background-color: ${({ theme }) => theme.blue[950]};
    color: ${({ theme }) => theme.white.ff};
    display: flex;
    align-items: center;
    justify-content: ${({ isOpen }) => (isOpen ? 'flex-start' : 'center')};
    padding: 6px 10px;
    cursor: pointer;

    img {
        margin-right: ${({ isOpen }) => (isOpen ? '8px' : '0px')};
        padding: 6px 0;
        transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
    }
    span {
        text-transform: uppercase;
        display: ${({ isOpen }) => (isOpen ? 'inline-block' : 'none')};
        font-size: 15px;
    }
`;

export const ClosedFiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 35px 0;
    height: 260px;
    span {
        text-transform: uppercase;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-size: 15px;
        font-weight: 600;
        width: 18px;
        color: ${({ theme }) => theme.blue[950]};
    }
    img {
        margin-bottom: 8px;
    }
`;

export const SideFiltersContent = styled.div`
    padding: 23px 8px;
    /* bold for the first option in the filter dropdown */
    > div > div:first-child {
        font-weight: 600;
    }
`;

export const SideFilterSectionWrapper = styled.div`
    margin: ${({ isSubmenu }) => (isSubmenu ? '0' : '12px')} 0;
    box-shadow: ${({ isSubmenu, theme }) =>
        !isSubmenu ? `0px 0px 8px ${theme.black[29]}` : `0px 0px 0px ${theme.black['00']}`};
`;

export const SideFilterSectionHeader = styled.div`
    height: 51px;
    padding: 0 10px;

    background: ${({ theme, disabled }) => (disabled ? theme.grey[50] : 'white')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    border: ${({ theme, isSubmenu, disabled }) =>
        isSubmenu ? 'none' : disabled ? `1px solid ${theme.blue[200]}` : `1px solid ${theme.blue[950]}`};

    &:hover {
        background-color: ${({ theme }) => theme.grey[50]};
    }
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        height: 100%;

        /* On submenu item the border bottom shall only be displayed: */
        /* - Items if they are not the last */
        /* - Last Items if they are open (if closed it overlaps wrapper border) */
        border-bottom: ${({ theme, isSubmenu, isLastItem, isOpen }) =>
            isSubmenu && (!isLastItem || isOpen) ? `1px solid ${theme.grey[200]}` : 'none'};

        p {
            color: ${({ theme }) => theme.blue[950]};
            user-select: ${({ disabled }) => (disabled ? 'none' : 'auto')};
        }

        img {
            transform: rotate(${({ isOpen }) => (isOpen ? '0deg' : '180deg')});
        }
    }
`;

export const SideFiltersSectionContent = styled.div`
    border: ${({ theme, isSubmenu }) => (isSubmenu ? 'none' : `1px solid ${theme.blue[950]}`)};
    border-top-width: 0px;
`;

export const SideFiltersSubSectionWrapper = styled.div`
    position: relative;
    padding: 5px 0 5px 0;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: calc(100% - 20px);
        background-color: ${({ theme, isSubmenu, isLastItem, isOpen }) =>
            isSubmenu && (!isLastItem || isOpen) ? theme.grey[200] : 'transparent'};
    }
`;

export const SideFilterSliderWrapper = styled.div`
    padding: 10px;
`;

export const SliderInputWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    input {
        max-width: 30px;
        padding: 2px 6px;
        text-align: center;
        border: 1px solid ${({ theme }) => theme.blue[950]};
        background-color: ${({ theme }) => theme.grey[50]};
        color: ${({ theme }) => theme.blue[950]};
        font-size: 16px;

        &:focus {
            outline: 1px solid ${({ theme }) => theme.blue[600]};
            border-color: ${({ theme }) => theme.blue[600]};
        }
    }

    /* remove the arrows from the input number */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const SliderWrapper = styled.div`
    margin: 28px 9px;

    .input-range__track--background {
        background-color: ${({ theme }) => theme.grey[50]} !important;
    }

    .input-range__track--background,
    .input-range__track--active {
        height: 8px;
    }

    .input-range__track--active,
    .input-range__slider {
        background: ${({ theme }) => theme.blue[600]} !important;
        border-radius: 0 !important;
        border: none;
        box-shadow: 0px 1px 2px ${({ theme }) => theme.violet[600]};
        position: relative;
    }

    .input-range__slider {
        height: 20px;
        width: 20px;
        bottom: 3px;

        &::before,
        &::after {
            box-sizing: border-box;
            content: '';
            position: absolute;
            top: 20%;
            width: 20%;
            height: 60%;
            border: solid ${({ theme }) => theme.grey[50]};
            border-width: 0px 0.3px 0px 0.3px;
            opacity: 0.55;
        }
        &::before {
            left: 25%;
        }

        &::after {
            right: 25%;
        }

        &:first-child {
            left: 50%;
        }
    }

    .input-range__label,
    .input-range__label--max,
    .input-range__label,
    .input-range__label--min {
        display: none;
    }

    .input-range__slider:active {
        transform: scale(1.2);
    }
`;

const Button = styled.button`
    padding: 12px;
    width: 110px;
    outline: none;
    background: transparent;
    box-shadow: 0px 2px 4px ${({ disabled, theme }) => (disabled ? theme.black['00'] : theme.black[40])};
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    transition: all 0.2s ease-in;
    border: 1px solid ${({ disabled, theme }) => (disabled ? theme.blue[200] : theme.blue[600])};
    font-size: 16px;
    font-family: Source Sans Pro, Open sans-serif;
    &:hover {
        box-shadow: 0 0 0 ${({ theme }) => theme.black['00']};
    }
`;

export const PrimaryButton = styled(Button)`
    background-color: ${({ disabled, theme }) => (disabled ? theme.grey[50] : theme.blue[600])};
    color: ${({ disabled, theme }) => (disabled ? theme.blue[200] : theme.white.ff)};
`;

export const SecondaryButton = styled(Button)`
    background-color: ${({ disabled, theme }) => (disabled ? theme.grey[50] : theme.white.ff)};
    color: ${({ disabled, theme }) => (disabled ? theme.blue[200] : theme.blue[950])};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 28px;
`;
