import {
    FlexColumnWrapper,
    UserDetails,
    ReusableInput,
    InputErrorMessageLabel,
    LastLoginComponent,
    SectionWrapper,
    ModalBuilder,
    MComponent,
} from '@toluna-dashboarding/usermanagement';
import ExternalUserTypeProfile from '../external-components/ExternalUserTypeProfile';
import { defaultTheme } from '../../../../../utils/defaultTheme';

const userProfileModalSpecs = new ModalBuilder('User Profile');

const leftSide = new MComponent(FlexColumnWrapper, 'user-profile-left-side-wrapper');
const rightSide = new MComponent(FlexColumnWrapper, 'user-profile-right-side-wrapper');

userProfileModalSpecs.addChildren([leftSide, rightSide]);

const userDetails = new MComponent(UserDetails, 'create-user-user-details').setProps({
    overwriteStyleObject: {
        width: '300px',
        height: '365px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 6px',
        backgroundColor: defaultTheme.white.ff,
        color: defaultTheme.blue[950],
        fontSize: '15px',
        opacity: 1,
        borderRadius: '3px',
        margin: '20px 10px 0 -90px',
    },
});

const lastLoginComponent = new MComponent(LastLoginComponent, 'user-profile-last-login')
    .setStyle({
        margin: '-115px -62px 0',
    })
    .setProps({
        styleObject: {
            fontSize: '15px',
            fontWeight: 'normal',
            justifyContent: 'start',
            alignItems: 'center',
            border: 'none',
            color: defaultTheme.blue[950],
        },
    });
leftSide.addChild(userDetails);
leftSide.addChild(lastLoginComponent);

const inputs = [
    {
        id: 'user-profile-first-name-input',
        input: {
            label: 'First name',
            id: 'first_name_input',
            useFor: 'FirstName',
            width: '250px',
        },
        error: {
            label: 'Please enter the first name',
        },
    },
    {
        id: 'user-profile-last-name-input',
        input: {
            label: 'Last name',
            id: 'last_name_input',
            useFor: 'LastName',
            width: '250px',
        },
        error: {
            label: 'Please enter the last name',
        },
    },
    {
        id: 'user-profile-email-input',
        input: {
            label: 'E-mail',
            id: 'email_input',
            input_type: 'email',
            useFor: 'Email',
            width: '250px',
        },
        error: {
            label: 'Please enter the email',
        },
    },
];

inputs.forEach(({ id, input, error }) => {
    const inputComponent = new MComponent(ReusableInput, id).setProps({ ...input });
    const errorComponent = new MComponent(InputErrorMessageLabel, id + '-error').setProps({ ...error });

    inputComponent.addChild(errorComponent);
    userDetails.addChild(inputComponent);
});

rightSide.addChild(
    new MComponent(SectionWrapper, 'user-profile-section-wrapper').setProps({
        title: 'User Type',
        overwriteStyleObject: {
            width: '650px',
            height: '365px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 6px',
            backgroundColor: defaultTheme.white.ff,
            color: defaultTheme.blue[950],
            fontSize: '15px',
            opacity: 1,
            borderRadius: '3px',
            margin: '20px -120px 0 0',
        },
    })
);

userProfileModalSpecs
    .findChildById('user-profile-section-wrapper')
    .addChild(new MComponent(ExternalUserTypeProfile, 'external-update-user-type'));

export const userProfileConfig = userProfileModalSpecs;
