import React from 'react';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';
import PropTypes from 'prop-types';
import { StyledRadioButton, StyledIcon, InputRadioButton, TextLabel } from './RadioButton.styles';

export const RadioButton = React.forwardRef((props, ref) => {
    const {
        checked,
        onChange,
        value,
        disabled,
        name,
        vertical,
        label,
        enableKeyboardNavigation,
        customStyle,
        ...rest
    } = props;

    const onClick = () => {
        !disabled && onChange && onChange(value);
    };

    return (
        <ThemeConsumer>
            {theme => {
                return (
                    <StyledRadioButton vertical={vertical} customStyle={customStyle} {...rest}>
                        {/*
                                Adding empty onChange function because of React warning
                                Warning: Failed prop type: You provided a `checked` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultChecked`. Otherwise, set either `onChange` or `readOnly`.
                            */}
                        <InputRadioButton
                            type="radio"
                            disabled={disabled}
                            name={name}
                            ref={ref}
                            checked={checked}
                            value={value}
                            onChange={() => {}}
                        />
                        <StyledIcon
                            checked={checked}
                            theme={theme}
                            onClick={onClick}
                            disabled={disabled}
                            customStyle={customStyle}
                            enableKeyboardNavigation={enableKeyboardNavigation}
                        >
                            <i className="fas fa-circle" />
                        </StyledIcon>
                        <TextLabel vertical={vertical} onClick={onClick} customStyle={customStyle} disabled={disabled}>
                            {label}
                        </TextLabel>
                    </StyledRadioButton>
                );
            }}
        </ThemeConsumer>
    );
});

RadioButton.propTypes = {
    /** Is checked */
    checked: PropTypes.bool,
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Custom style */
    customStyle: PropTypes.object,
    /** On change function */
    onChange: PropTypes.func,
    /** Radio label */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /** Place radio label under button */
    vertical: PropTypes.bool,
    /** Radio button name */
    name: PropTypes.string,
};

RadioButton.defaultProps = {
    checked: false,
    disabled: false,
    label: '',
    vertical: false,
    name: '',
};
