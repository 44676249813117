import React from 'react';
import ReactDOM from 'react-dom';
import AxaDashboard from './AxaDashboard';
import { Provider } from 'react-redux';
import store from './store/store';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './utils/defaultTheme';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={defaultTheme}>
                    <AxaDashboard />
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
