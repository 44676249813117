import { connect } from 'react-redux';
import Overview from './Overview';
import { getFieldworkOverviewDataActionCreator } from './data/actions';
import { onGlobalOverviewSelectedCountriesApplyActionCreator } from '../../../../common/data/actions';
import { getFieldworkOverviewComputedMapData } from './data/computedSelectors';
import { getFieldworkOverviewDataSelector } from './data/selectors';

const mapStateToProps = state => ({
    countries: getFieldworkOverviewComputedMapData(state),
    overviewData: getFieldworkOverviewDataSelector(state),
});

const mapDispatchToProps = {
    getFieldworkOverviewData: getFieldworkOverviewDataActionCreator,
    onMapCountryClick: onGlobalOverviewSelectedCountriesApplyActionCreator,
};

const OverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);
export default OverviewContainer;
