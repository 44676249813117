import { tableConfig } from './components-configs/table.config';
import searchConfig from './components-configs/search.config';
import managementOptions from './components-configs/managementOptions.config';
import toastrConfig from './components-configs/toastr.config.js';
import { userProfileConfig } from './modal-configs/userProfile.config';
import { additionalFiltersConfig } from './modal-configs/additionalFilters.config';
import { createUserConfig } from './modal-configs/createUser.config';

const configs = {
    table: tableConfig,
    searchConfigs: searchConfig,
    managementOptions: managementOptions,
    userProfileConfig,
    additionalFiltersConfig,
    createUserConfig,
    toastrConfig,
};

export default configs;
