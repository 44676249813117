import {
    ADTESTER_PAGE,
    COMPETITIVE_MEDIA,
    PREFERENCE_ANALYSIS_TABS,
    TRANSVERSAL_VIEW_PAGE,
} from '../../../../utils/routes';

/* When selecting the option to send as parameter to back-end,
    a different key is selected on each page, thus we need to store which key we need to use */
export const TOP_FILTERS_OPTION_BE_KEY = {
    [ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path]: 'copy',
    [ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path]: 'copy',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path]: 'period',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path]: 'period',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path]: 'period',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path]: 'period',
    [COMPETITIVE_MEDIA.path]: 'country',
    [TRANSVERSAL_VIEW_PAGE.path]: 'period',
};

export const DISPLAY_BUTTON_POSITION = {
    RIGHT: 'right',
    BOTTOM: 'bottom',
};
export const TOP_FILTER_CARD_SIZE = {
    SMALL: 'small',
    LARGE: 'large',
};
