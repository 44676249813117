import styled from 'styled-components';

export const SigTestFlagWrapper = styled.div`
    width: 96%;
    position: relative;
    margin-left: 6px;
    bottom: 35px;
    border-bottom: 1px dashed ${({ theme }) => theme.blue[70]};
`;
export const SigTestTooltip = styled.div`
    width: ${({ sigTestCoords }) => sigTestCoords && sigTestCoords.coords.width - 5}px;
    height: 20px;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.blue[900]};
    left: ${({ sigTestCoords, barTranslate }) => sigTestCoords && sigTestCoords.coords.x + barTranslate + 2}px;
    top: -${({ sigTestCoords, totalBarChartHeight }) => sigTestCoords && totalBarChartHeight - sigTestCoords.coords.y - 5}px; //last added value is offset - 'top' uses the same logic as d3 when setting barcharts across y axis.
    transform: translateY(100%);
    background-color: ${({ backgroundSigTest, theme }) =>
        backgroundSigTest > 0 ? theme.green[200] : theme.yellow[500]};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom + 5 + 'px' : '0px')};
    margin-left: -6px;
    display: flex;
    text-align: center;
    z-index: 10;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    visibility: ${({ showFlag }) => (showFlag ? 'visible' : 'hidden')};
    &:hover {
        z-index: 100;
    }
    &:after {
        content: ' ';
        position: absolute;
        right: ${({ sigTestCoords }) => sigTestCoords && sigTestCoords.coords.width / 2}px;
        transform: translateX(75%);
        bottom: -4px;
        border-top: 5px solid
            ${({ backgroundSigTest, theme }) => (backgroundSigTest > 0 ? theme.green[200] : theme.yellow[500])};
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: none;
    }
`;
export const ExtendedSigTestTooltip = styled.div`
    width: 150px;
    font-weight: 100;
    height: 55px;
    background-color: ${({ backgroundSigTest, theme }) =>
        backgroundSigTest > 0 ? theme.green[200] : theme.yellow[500]};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom + 'px' : '0px')};
    color: ${({ theme }) => theme.blue[900]};
    display: none;
    text-align: center;
    position: absolute;
    top: -44.5px;
    left: -67px;
    padding: 5px 7px;
    font-size: 10px;
    z-index: 100;

    > hr {
        border: 0.1px solid ${({ theme }) => theme.grey[800]};
        opacity: 0.1;
        width: 90%;
    }
    ${SigTestTooltip}:hover & {
        display: block;
        z-index: -1;
    }
`;
