import { combineReducers } from 'redux';
import { GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC } from './actions';

const adDetailsAndMetricsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    metricsData: adDetailsAndMetricsReducer,
});
