import styled from 'styled-components';

export const EditorialWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    color: ${({ theme }) => theme.blue[900]};
    background: ${({ theme }) => theme.white.ff};
`;

export const Title = styled.h3`
    font-size: 17px;
    width: 100%;
    text-transform: uppercase;
    border-bottom: 1px solid ${({ theme }) => theme.grey[200]};
    text-align: center;
    padding-bottom: 22px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.blue[900]};
`;

export const ContentWrapper = styled.div`
    height: ${({ isExpanded }) => (isExpanded ? '100%' : '160px')};
    overflow-y: hidden;
    position: relative;
    margin-bottom: ${({ isExpanded }) => (isExpanded ? '4px' : '12px')};
    font-size: 16px;
    width: 90%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 -24px 14px -10px ${({ isExpanded, theme }) => (isExpanded ? 'rgba(0,0,0,0)' : theme.white.ff)}
            inset;
    }
`;

export const ExpandButton = styled.button`
    border: none;
    outline: none;
    color: ${({ theme }) => theme.blue[900]};
    font-family: inherit;
    background: transparent;
    padding: 6px 8px 8px 8px;
    font-size: 15px;
    cursor: pointer;
`;
