import React from 'react';
import { createSelector } from 'reselect';
import { getAdTesterOverviewDataSelector, getAdTesterOverviewSelectedCountrySelector } from './selectors';
import { getGlobalOverviewSelectedCountriesSelector } from '../../../../../common/data/selectors';
import { OVERVIEW_UTILS } from '../../../../../common/utils/utils';

export const getComputedAdTesterOverviewTableData = createSelector(
    [getAdTesterOverviewDataSelector, getAdTesterOverviewSelectedCountrySelector],
    (overviewData, selectedCountries) => {
        if (overviewData) {
            const { table } = overviewData;
            return OVERVIEW_UTILS.createFilteredOverviewTableData(table[0].data, [selectedCountries]);
        }
        return [];
    }
);
export const getComputedAdTesterOverviewTableHead = createSelector([getAdTesterOverviewDataSelector], overviewData => {
    if (overviewData) {
        const { table } = overviewData;

        const headers = [];
        table[0].columns.forEach(column => {
            /* Remove columns that we don't need to show */
            if (!['comparison_ad', 'individual_ad'].includes(column.id) && !column.isHidden) {
                headers.push({
                    Header: column.label,
                    accessor: column.id,
                    lowbaseacc: column.id + '_lowBase',
                    tooltip: column.tooltip,
                    disableSortBy: !column.isSortable,
                    Cell: row => <span title={row.value}>{row.value}</span>,
                });
            }
        });
        return headers;
    }
    return [];
});
export const getMapDataWithSelectedCountries = createSelector(
    [getAdTesterOverviewDataSelector, getGlobalOverviewSelectedCountriesSelector],
    (overviewData, selectedCountriesList) => {
        return OVERVIEW_UTILS.createMapWithSelectedCountries(overviewData, selectedCountriesList);
    }
);
