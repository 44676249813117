import React, { useCallback, useEffect } from 'react';
import SectionsHead from '../../../../../../../common/components/SectionsHead/SectionsHead';
import TopLegend from '../../../../../../../common/components/TopLegend/TopLegend';
import EvolutionLegend from '../../../components/EvolutionLegend/EvolutionLegend';
import RankingTable from '../../../components/RankingTable/RankingTable';
import { SECTION_IDS, sectionsHeadColor } from '../../utils/utils';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';
import { useCombinedLOBS } from '../../hooks/useCombinedLOBS';
import * as SC_PA from '../../../PreferanceAnalysis.styles';
import LOBLegend from '../../../components/LOBLegend/LOBLegend';
import CombinedBarLineChart from '../../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import {
    TRANSLATION_TEXT,
    CONSIDERATION_SUBSECTIONS_TITLE,
    CONSIDERATION_SUBSECTIONS_SUBTITLE_TEXT,
} from '../../../../../../../utils/translations';
import * as SC from './Consideration.styles';
import SimpleBarChartSection from '../../../components/SimpleBarChart/SimpleBarChart.section';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';
import * as TabLayout from '../../Tabs.styles';
import NoDataAvailable from '../../../../../../../common/components/NoDataAvailable/NoDataAvailable';
import Legend from '../../../../../../../common/components/Legend/Legend';

const ConsiderationTab = ({
    considerationSectionIDs,
    combinedChartsData,
    considerationData,
    simpleBarChartData,
    lobsAsOptions,
    rankingTableData,
    lineChartData,
    lineChartSubSections,
    lineChartLegend,
    hasTopFilterOptionLoaded,
    getBptPreferenceAnalysisConsiderationActionCreator,
    filtersThrownError,
    appliedFilters,
}) => {
    useEffect(() => {
        if (hasTopFilterOptionLoaded) getBptPreferenceAnalysisConsiderationActionCreator();
    }, [hasTopFilterOptionLoaded]);

    const considerationDataLoaded = () =>
        considerationData &&
        considerationData.hasOwnProperty('sections') &&
        considerationData.sections &&
        !filtersThrownError;

    const lobsForRankingTable = sectionID => lobsAsOptions && lobsAsOptions[sectionID][KPI_TYPES.RANKING];

    const [combinedChartLOBs, onLOBCheckboxChange, getVisibleLOBs] = useCombinedLOBS({
        sectionIDS: considerationSectionIDs,
        lobsAsOptions,
    });

    const hasSideFiltersApplied = appliedFilters && appliedFilters?.length > 0;

    const renderSubSection = useCallback(
        mainSectionID => {
            //The "getLineChartLOBS" function will only return each name for each chart line, in an array -
            //it is needed for the combined chart component to see what LOBs ar visible and filter internal data based on it
            //We want all lines to be visible 👁👁 - so all the names will be in our returned array .
            const getLineChartLOBS = (sectionID, eachSub, i) => {
                return lineChartData[sectionID][eachSub][i]?.lineData.map(line => line.name);
            };

            return lineChartData && lineChartSubSections ? (
                <>
                    {lineChartSubSections?.map((eachSub, idx) => {
                        const renderTableOrder = idx === 0 ? 2 : 3;
                        const renderLegendForTableIndex = 0;
                        const hasTitle = mainSectionID === SECTION_IDS.CONSIDERATION; //Only for the first section - ok as for now - to not overcomplicate things
                        const isSourceValid = lineChartData[mainSectionID]?.[eachSub]?.length;

                        if (!isSourceValid) return null;
                        return (
                            <>
                                {hasTitle && (
                                    <>
                                        <SC.SubSectionDivider />
                                        <SC.SubSectionMainTitle>
                                            {CONSIDERATION_SUBSECTIONS_TITLE(
                                                lineChartData[mainSectionID]?.[eachSub]?.[idx]?.brandName,
                                                lineChartData[mainSectionID]?.[eachSub]?.[idx]?.considerText
                                            )}
                                            <p>
                                                {CONSIDERATION_SUBSECTIONS_SUBTITLE_TEXT(
                                                    lineChartData[mainSectionID]?.[eachSub]?.[idx]?.brandName,
                                                    lineChartData[mainSectionID]?.[eachSub]?.[idx]?.considerText
                                                )}
                                            </p>
                                        </SC.SubSectionMainTitle>
                                    </>
                                )}

                                <TabLayout.SectionRow>
                                    <TabLayout.SectionItem style={{ flexDirection: 'column' }}>
                                        <SC_PA.CombinedChartsWrapper
                                            data-testid={`Consideration-Tab-LineChart-${eachSub}-${idx}`}
                                        >
                                            {lineChartData[mainSectionID]?.[eachSub]?.map((d, index) => (
                                                <>
                                                    <CombinedBarLineChart
                                                        visibleLOBs={getLineChartLOBS(mainSectionID, eachSub, index)}
                                                        lineData={d.lineData}
                                                        barData={[]}
                                                        numberOfRespondents={d?.numberOfRespondents || []}
                                                    />
                                                </>
                                            ))}
                                        </SC_PA.CombinedChartsWrapper>
                                        {lineChartData[mainSectionID]?.[eachSub]?.map((d, index) => (
                                            <>
                                                {lineChartLegend && index === renderLegendForTableIndex && (
                                                    <Legend
                                                        displayDirection={'column'}
                                                        legendItems={lineChartLegend[mainSectionID]?.[eachSub]}
                                                        customStyle={{
                                                            _wrapper: { marginTop: '20px' },
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </TabLayout.SectionItem>
                                    <TabLayout.SectionItem>
                                        {rankingTableData[mainSectionID][renderTableOrder] &&
                                            rankingTableData[mainSectionID].length > 0 && (
                                                <RankingTable
                                                    rankingData={[rankingTableData[mainSectionID][renderTableOrder]]}
                                                    lobOptions={lobsForRankingTable(mainSectionID)}
                                                    styleObject={{ margin: '-45px 0px 0px 20px' }}
                                                    hasNumberOfRespondents={true}
                                                    hasSideFiltersApplied={hasSideFiltersApplied}
                                                />
                                            )}
                                    </TabLayout.SectionItem>
                                </TabLayout.SectionRow>
                            </>
                        );
                    })}
                </>
            ) : null;
        },
        [lineChartData, lineChartSubSections]
    );

    return (
        <>
            {!filtersThrownError && <TopLegend />}
            {!filtersThrownError && hasSideFiltersApplied && <EvolutionLegend />}
            <NoDataAvailable show={!considerationDataLoaded() && !filtersThrownError} />

            {considerationDataLoaded() &&
                considerationData.sections.map(({ sectionID, sectionLabel }) => (
                    <SectionsHead
                        key={sectionID}
                        title={sectionLabel}
                        isResponsive={false}
                        customStyle={sectionsHeadColor}
                    >
                        <TabLayout.SectionRow style={{ margin: '70px 0px 0px 0px' }}>
                            <TabLayout.SectionItem>
                                {simpleBarChartData[sectionID] && (
                                    <SimpleBarChartSection
                                        barChartDataYear={simpleBarChartData[sectionID].year}
                                        barChartDataPeriod={simpleBarChartData[sectionID].period}
                                        hasNumberOfRespondents={true}
                                    />
                                )}
                            </TabLayout.SectionItem>
                            <TabLayout.SectionItem>
                                {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                    <RankingTable
                                        rankingData={[rankingTableData[sectionID][0]]}
                                        lobOptions={lobsForRankingTable(sectionID)}
                                        styleObject={{ margin: '-40px 0px 40px 20px' }}
                                        hasNumberOfRespondents={true}
                                        hasSideFiltersApplied={hasSideFiltersApplied}
                                    />
                                )}
                            </TabLayout.SectionItem>
                        </TabLayout.SectionRow>
                        <TabLayout.SectionRow>
                            <TabLayout.SectionItem>
                                {combinedChartsData && combinedChartLOBs ? (
                                    <SC_PA.LOBAreaWrapper>
                                        <SC_PA.CombinedChartsWrapper>
                                            {combinedChartsData[sectionID].map(d => (
                                                <CombinedBarLineChart
                                                    visibleLOBs={getVisibleLOBs(sectionID)}
                                                    lineData={d.lineData}
                                                    barData={d.barData}
                                                    customLineTooltip={{
                                                        tooltipFontColor: defaultTheme.blue[900],
                                                    }}
                                                />
                                            ))}
                                        </SC_PA.CombinedChartsWrapper>
                                        {combinedChartLOBs[sectionID][KPI_TYPES.COMBINED].length > 0 ? (
                                            <LOBLegend
                                                LOBs={combinedChartLOBs[sectionID][KPI_TYPES.COMBINED]}
                                                sectionID={sectionID}
                                                onChangeHandler={onLOBCheckboxChange}
                                            />
                                        ) : (
                                            <SC_PA.NoDataText>
                                                {TRANSLATION_TEXT.NO_DATA_AVAILABLE_TEXT}
                                            </SC_PA.NoDataText>
                                        )}
                                    </SC_PA.LOBAreaWrapper>
                                ) : null}
                            </TabLayout.SectionItem>
                            <TabLayout.SectionItem>
                                {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                    <RankingTable
                                        rankingData={[rankingTableData[sectionID][1]]}
                                        noTitle
                                        lobOptions={lobsForRankingTable(sectionID)}
                                        styleObject={{ margin: '10px 0px 40px 20px' }}
                                        hasNumberOfRespondents={true}
                                        hasSideFiltersApplied={hasSideFiltersApplied}
                                    />
                                )}
                            </TabLayout.SectionItem>
                        </TabLayout.SectionRow>
                        <>{renderSubSection(sectionID)}</>
                    </SectionsHead>
                ))}
        </>
    );
};

export default ConsiderationTab;
