import { React } from 'react';
import { CenteredAbsolute, UseThisToolImage, UseThisToolVideo, UseThisToolWrapper } from './UsageOfThisTool.styles';
import { usageOfThisToolSections } from './usageOfThisToolSections.config';

const SHOW_WIP_IMAGE = true;

const HomePageVideo = () => {
    return (
        <>
            {usageOfThisToolSections
                .filter(item => item.isActive)
                .map(section =>
                    SHOW_WIP_IMAGE ? (
                        <UseThisToolWrapper key={section.title}>
                            <UseThisToolImage src={section.poster} alt={'AXA Work In Progress image'} />
                            <CenteredAbsolute>WORK IN PROGRESS</CenteredAbsolute>
                        </UseThisToolWrapper>
                    ) : (
                        <UseThisToolVideo
                            controls
                            key={section.id}
                            poster={section.poster}
                            style={{ objectFit: 'cover' }}
                        >
                            <source src={section.videoSource} type="video/webm" />
                        </UseThisToolVideo>
                    )
                )}
        </>
    );
};

export default HomePageVideo;
