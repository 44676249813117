import { connect } from 'react-redux';
import { onGlobalOverviewSelectedCountriesApplyActionCreator } from '../../../data/actions';
import WorldMap from './WorldMap';
import { getGlobalOverviewSelectedCountriesSelector } from '../../../data/selectors';
import { setErrorNotificationDisplayActionCreator } from '../../../../errorHandling/data/actions';

const mapStateToProps = state => ({
    selectedCountries: getGlobalOverviewSelectedCountriesSelector(state),
});
const mapDispatchToProps = {
    onCountryClick: onGlobalOverviewSelectedCountriesApplyActionCreator,
    onError: setErrorNotificationDisplayActionCreator,
};

const WorldMapContainer = connect(mapStateToProps, mapDispatchToProps)(WorldMap);
export default WorldMapContainer;
