import React from 'react';

const trackYCustomStyles = (props) => ({
    backgroundColor: props.trackY.color,
    width: props.trackY.width,
    right: props.trackY.distanceRight,
    top: props.trackY.distanceTopBottom,
    height: `calc(100% - ${props.trackY.distanceTopBottom*2}px`,
    borderRadius: props.borderRadius
})

export const trackYProps = (combinedTheme) => ({
    renderer: props => {
        const {elementRef, ...restProps} = props;
        restProps.style = {
            ...restProps.style,
            ...trackYCustomStyles(combinedTheme)
        }
        return <div {...restProps} ref={elementRef}  className="tff-scroll-panel-trackY" />;
    }
})

const thumbYCustomStyles = (props) => ({
    backgroundColor: props.thumbYColor,
    borderRadius: props.borderRadius
})

export const thumbYProps = (combinedTheme) => ({
    renderer: props => {
        const {elementRef, ...restProps} = props;
        restProps.style = {
            ...restProps.style,
            ...thumbYCustomStyles(combinedTheme)
        }
        return <div {...restProps} ref={elementRef}  className="tff-scroll-panel-thumbY" />;
    }
})

const wrapperCustomStyles = (props,floatBar) => ({
    right: props.trackY.width + props.trackY.distanceRight > 0 && !floatBar ? props.trackY.width + props.trackY.distanceRight : 0,
    ...props.contentWrapperWithScroll
})

export const wrapperProps = (combinedTheme,floatBar) => ({
    renderer: props => {
        const {elementRef, ...restProps} = props;
        // if restProp right is 0 , 
        // it's mean that react-scrollbars-custom detict that there is no scroll
        // in that case we don't want to apply our right custom style
        if (restProps.style.right !== 0){
            restProps.style = {
                ...restProps.style,
                ...wrapperCustomStyles(combinedTheme,floatBar)
            }
        }
        return <div {...restProps} ref={elementRef}  className="tff-scroll-panel-contnet-wrapper" />;
    }
})

// fix bug of 1px shows from the native scroll - change to -18 instead of -17
export const scrollerProps = () => ({
    renderer: props => {
        const {elementRef, ...restProps} = props;
        if (restProps.style.marginRight === -17){
            restProps.style.marginRight = -18
        }
        return <div {...restProps} ref={elementRef}  className="tff-scroll-panel-scrollerContent" />;
    }
})


