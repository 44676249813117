import React, { useCallback } from 'react';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { BAR_CHARTS_RESPONSIVE_WIDTHS, MEDIA_QUERY_SCREEN_SIZE } from '../../../../../../utils/responsiveness';
import { BAR_CATEGORY, BAR_TYPES } from '../../../AdDetailsMetrics/utils/constants';
import {
    BarChartWrapper,
    CampaignDiagnosticBarName,
    CampaignDiagnosticCardWrapper,
    CampaignDiagnosticColumn,
    CampaignDiagnosticIcon,
    CampaignDiagnosticStackLabels,
    CampaignDiagnosticWrapper,
    LegendItem,
    LegendItemColor,
    StackedBarChartLegend,
    StackedChartsWrapper,
} from '../../../AdDetailsMetrics/AdDetailsMetrics.styles';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION, StackedBarChart } from '@toluna-ui-toolkit/barchart';
import { BARCHART_SETTINGS } from './constants';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { useStackedBarsSpacing } from '../../../../../../common/hooks/useStackedBarsSpacing';
import TopBarsCard from '../../../AdDetailsMetrics/components/CampaignDiagnostics/TopBarsCard';
import { checkAndConvertToNAValue, TOP_2_TYPE } from '../../../../utils/utils';

const isEmphasizedItem = itemType => [BAR_CATEGORY.AVERAGE].includes(itemType);

export default function ComparisonCampaignDiagnostics({ kpis }) {
    const [width] = useWindowSize();
    const [spacing, chartRef] = useStackedBarsSpacing(kpis);

    const BAR_CHARTS_WIDTH =
        width <= MEDIA_QUERY_SCREEN_SIZE.MEDIUM
            ? BAR_CHARTS_RESPONSIVE_WIDTHS.SMALL
            : BAR_CHARTS_RESPONSIVE_WIDTHS.MEDIUM;

    const renderChartByType = useCallback(
        (kpiType, items) => {
            switch (kpiType) {
                case BAR_TYPES.SIMPLE: {
                    const data = items.map((e, i) => ({
                        name: e.itemLabel,
                        value: e.values[0].value,
                        color: i === 0 ? defaultTheme.orange[700] : e.values[0].color,
                        textColor: i === 0 ? defaultTheme.orange[700] : e.values[0].color,
                    }));

                    const normalData = data.slice(1);
                    const averageData = [data[0]];
                    normalData.reverse();

                    return (
                        <BarChartWrapper
                            data-testid={'ComparisonCampaignDiagnosticTester-SimpleBarWrapper'}
                            style={{ flexDirection: 'column' }}
                        >
                            <BarChart
                                width={BAR_CHARTS_WIDTH - 5}
                                height={BARCHART_SETTINGS.HEIGHT}
                                marginWidth={30}
                                domain={BARCHART_SETTINGS.DOMAIN}
                                direction={BARCHART_DIRECTION.HORIZONTAL}
                                labelFormatter={BARCHART_SETTINGS.FORMATTER}
                                labelPosition={BARCHART_LABEL_POSITION.END_OF_DOMAIN}
                                styling={{
                                    ...BARCHART_SETTINGS.STYLE,
                                    backgroundColor: defaultTheme.red[100],
                                }}
                                withNames={false}
                                alwaysOnTooltips={false}
                                data={averageData}
                            />
                            <BarChart
                                width={BAR_CHARTS_WIDTH - 5}
                                height={BARCHART_SETTINGS.HEIGHT * normalData.length}
                                marginWidth={30}
                                domain={BARCHART_SETTINGS.DOMAIN}
                                direction={BARCHART_DIRECTION.HORIZONTAL}
                                labelFormatter={BARCHART_SETTINGS.FORMATTER}
                                labelPosition={BARCHART_LABEL_POSITION.END_OF_DOMAIN}
                                styling={BARCHART_SETTINGS.STYLE}
                                withNames={false}
                                alwaysOnTooltips={false}
                                data={normalData}
                            />
                        </BarChartWrapper>
                    );
                }
                case BAR_TYPES.STACKED: {
                    let stackLabels = [];
                    const colors = items[0].values.map(v => v.color);
                    const data = items
                        .map((item, index) => {
                            const dataObject = {
                                name: index,
                            };

                            item.values.forEach(valueObject => {
                                const { value, type, tooltip } = valueObject;
                                if (type !== TOP_2_TYPE) {
                                    dataObject[tooltip] = value;
                                } else if (type === TOP_2_TYPE) {
                                    stackLabels.push(value);
                                }
                            });

                            return dataObject;
                        })
                        .reverse();
                    const stackKeys = Object.keys(data[0]).filter(k => k !== 'name');

                    return (
                        <div>
                            <StackedChartsWrapper ref={chartRef}>
                                <StackedBarChart
                                    data={data}
                                    stackColors={colors}
                                    stackKeys={stackKeys}
                                    translateX={-10}
                                    width={BAR_CHARTS_WIDTH + 25}
                                    height={BARCHART_SETTINGS.HEIGHT * items.length}
                                    direction={BARCHART_DIRECTION.HORIZONTAL}
                                    labelFormatter={BARCHART_SETTINGS.FORMATTER}
                                    labelPosition={BARCHART_LABEL_POSITION.MIDDLE}
                                    tooltipFormatter={BARCHART_SETTINGS.FORMATTER}
                                    marginHeight={10}
                                    styling={{
                                        barWidth: 19,
                                        barLabelSecondaryAxisOffset: -2,
                                        tooltipOffsetX: -14,
                                        tooltipOffsetY: -10,
                                        tooltipWidth: 24,
                                        tooltipHeight: 20,
                                        tooltipStroke: 1,
                                        tooltipStrokeColor: defaultTheme.blue['950-b3'],
                                        tooltipFontColor: defaultTheme.blue['600'],
                                    }}
                                />
                                <CampaignDiagnosticStackLabels heightOfLabels={spacing}>
                                    {stackLabels.map((e, i) => (
                                        <li key={`${e}-${i}`}>{checkAndConvertToNAValue(e, '%')}</li>
                                    ))}
                                </CampaignDiagnosticStackLabels>
                            </StackedChartsWrapper>

                            <StackedBarChartLegend data-testid={'ComparisonCampaignDiagnosticTester-StackedBarLegend'}>
                                {stackKeys.map((key, index) => (
                                    <LegendItem key={`legendItem-${key}`}>
                                        <LegendItemColor style={{ background: colors[index] }} />
                                        {key}
                                    </LegendItem>
                                ))}
                            </StackedBarChartLegend>
                        </div>
                    );
                }
            }
        },
        [kpis, BAR_CHARTS_WIDTH]
    );

    return (
        <CampaignDiagnosticWrapper>
            <CampaignDiagnosticColumn className="diagnosticsLabels">
                <CampaignDiagnosticCardWrapper style={{ boxShadow: 'none' }} />
                <div style={{ marginTop: '50px', alignSelf: 'flex-start', width: '100%' }}>
                    {kpis &&
                        kpis[0].items.map((e, i) => (
                            <CampaignDiagnosticBarName
                                key={i + '-' + e.itemLabel}
                                isLowBaseSize={false}
                                isAnyLowBaseSize={false}
                                isMain={isEmphasizedItem(e.itemType)}
                            >
                                <span className="itemLabel">{e.itemLabel}</span>
                            </CampaignDiagnosticBarName>
                        ))}
                </div>
            </CampaignDiagnosticColumn>
            {kpis &&
                kpis.map((kpi, i) => (
                    <CampaignDiagnosticColumn key={i + '-' + kpi.kpiLabel}>
                        <CampaignDiagnosticIcon>
                            <img alt="Image of KPI" src={kpi.kpiImageLink} />
                        </CampaignDiagnosticIcon>
                        <TopBarsCard
                            kpiType={kpi.kpiType}
                            noStroke={true}
                            items={kpi.items.filter(item => item.itemType === BAR_CATEGORY.AVERAGE)}
                            title={kpi.kpiLabel}
                            label={kpi.kpiDescription}
                        />
                        <div style={{ marginTop: '40px', alignSelf: 'flex-start' }}>
                            {renderChartByType(kpi.kpiType, kpi.items)}
                        </div>
                    </CampaignDiagnosticColumn>
                ))}
        </CampaignDiagnosticWrapper>
    );
}
