import styled, { keyframes } from 'styled-components';

const ShimmerKeyframe = keyframes`
0% {
  background-color:#e9e8e8;
}
100% {
  background-color:#f3f3f3;
}
`;

export const ShimmerSkeleton = styled.div`
    width: 297px;
    height: 290px;
    border-radius: 2px;
    margin: 0px 10px 0px 10px;
    opacity: 0.7;
    animation: ${ShimmerKeyframe} 1.4s linear infinite;
`;
