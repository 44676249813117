import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from '../Header/Header';
import * as routes from '../../../utils/routes';
import LandingPage from '../../../pages/landing/landing.page';
import AdTesterPage from '../../../pages/adTester/adTester.page';
import BrandPreferenceTrackerPage from '../../../pages/brandPreferenceTracker/brandPreferenceTracker.page';
import SurveyFieldworkPage from '../../../pages/surveyFieldwork/surveyFieldwork.page';
import FilterSegmentLibraryPage from '../../../pages/filterSegmentLibrary/filterSegmentLibrary.page';

import UserManagementPage from '../../../pages/userManagement/userManagement.page';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.container';
import BackOfficePage from '../../../pages/backOffice/backOffice.page';
import SessionExpiredModalContainer from '../SessionExpiredModal/SessionExpiredModal.container';
import ErrorNotification from '../../../errorHandling/components/ErrorNotification';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Footer from '../Footer/Footer';
import { AppRouterWrapper } from './AppRouter.styles';
import { useUserWatcher } from '../../hooks/useUserWatcher';
import CompetitiveMediaPage from '../../../pages/competitiveMedia/CompetitiveMedia.page';
import TransversalViewPage from './../../../pages/transversalView/TransversalView.page';

const AppRouter = () => {
    useUserWatcher();
    return (
        <>
            <AppRouterWrapper>
                <ConfirmationModal />
                <SessionExpiredModalContainer />
                <Header />
                <Switch>
                    <Route path={routes.DASHBOARD_PAGE.path} exact component={LandingPage} />

                    <ProtectedRoute
                        path={routes.SURVEY_FIELDWORK_PAGE.path}
                        requiredPermissions={routes.SURVEY_FIELDWORK_PAGE.requiredPermission}
                        component={SurveyFieldworkPage}
                    />

                    <ProtectedRoute
                        path={routes.ADTESTER_PAGE.path}
                        requiredPermissions={routes.ADTESTER_PAGE.requiredPermission}
                        component={AdTesterPage}
                    />

                    <ProtectedRoute
                        path={routes.BPT_PAGE.path}
                        requiredPermissions={routes.BPT_PAGE.requiredPermission}
                        component={BrandPreferenceTrackerPage}
                    />

                    <ProtectedRoute
                        path={routes.FILTER_SEGMENT_LIBRARY_PAGE.path}
                        requiredPermissions={routes.FILTER_SEGMENT_LIBRARY_PAGE.requiredPermission}
                        component={FilterSegmentLibraryPage}
                    />

                    <ProtectedRoute
                        path={routes.COMPETITIVE_MEDIA.path}
                        requiredPermissions={routes.COMPETITIVE_MEDIA.requiredPermission}
                        component={CompetitiveMediaPage}
                    />

                    <ProtectedRoute
                        path={routes.TRANSVERSAL_VIEW_PAGE.path}
                        requiredPermissions={routes.TRANSVERSAL_VIEW_PAGE.requiredPermission}
                        component={TransversalViewPage}
                    />

                    <ProtectedRoute path={routes.USER_MANAGEMENT_PAGE.path} component={UserManagementPage} />

                    <ProtectedRoute path={routes.BACK_OFFICE_PAGE.path} component={BackOfficePage} />

                    <Redirect to={routes.DASHBOARD_PAGE.path} />
                </Switch>
                <ErrorNotification />
            </AppRouterWrapper>
            <Footer />
        </>
    );
};

export default AppRouter;
