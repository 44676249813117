import React from 'react';
import * as colorUtils from './colorTransformation';

//export const createTheme = (lightColor,accentColor) => ({
export const createTheme = (lightColor) => ({
    lightColor,
    lightestColor: colorUtils.convertLightToLightestColor(lightColor),
    lighterColor: colorUtils.convertLightToLighterColor(lightColor),
    mediumColor: colorUtils.convertLightToMediumColor(lightColor),
    darkColor: colorUtils.convertLightToDarkColor(lightColor),
    disabledColor: colorUtils.convertLightToDisabledColor(lightColor),
    // accent : {
    //     lightColor: accentColor,
    //     lightestColor: colorUtils.convertLightToLightestColor(accentColor),
    //     lighterColor: colorUtils.convertLightToLighterColor(accentColor),
    //     mediumColor: colorUtils.convertLightToMediumColor(accentColor),
    //     darkColor: colorUtils.convertLightToDarkColor(accentColor),
    //     disabledColor: colorUtils.convertLightToDisabledColor(accentColor),
    // }
});

// const lighrColor = '#00A3E6';
// const accentColor = '#fc0d0d';
// export const defaultTheme = createTheme(lighrColor,accentColor);
// const ThemeContext = React.createContext(createTheme(lighrColor,accentColor));
// export const ThemeProvider = (props) => {
//     return ( <ThemeContext.Provider value={ createTheme(props.lightColor || lighrColor, props.accentColor || accentColor)} >
//         {props.children}
//     </ThemeContext.Provider>)
// }
const lighrColor = '#00A3E6';
const ThemeContext = React.createContext(createTheme(lighrColor));
export const ThemeProvider = (props) => {
    return ( <ThemeContext.Provider value={ createTheme(props.color || props.lightColor || lighrColor)} >
        {props.children}
    </ThemeContext.Provider>)
}

export const ThemeConsumer = (props) => {
    return ( 
        <ThemeContext.Consumer>{value=>(
            props.children(value)
        )}</ThemeContext.Consumer>
    )
}