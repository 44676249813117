export const ADDITIONAL_MAP_COMPONENTS_KEYS = {
    HK: 'Hong-Kong',
    SG: 'Singapore',
};

export const ADDITIONAL_MAP_COMPONENTS = [
    {
        label: ADDITIONAL_MAP_COMPONENTS_KEYS.HK,
        countryId: 11,
    },
    {
        label: ADDITIONAL_MAP_COMPONENTS_KEYS.SG,
        countryId: 26,
    },
];
