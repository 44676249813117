import { PREFERENCE_ANALYSIS_TABS } from '../../../../../../utils/routes';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
export const SECTION_IDS = {
    AWARENESS: 1,
    CONSIDERATION: 2,
    AD_RECALL: 1,
};

export const AXA_AND_AXABRANDS_ID = 50;

export const SECTION_TYPES = {
    TABLE: 'Table',
    KPI: 'KPI',
    BENCHMARK: 'Benchmark',
};

export const VALUE_TYPES = {
    TYPE_AVERAGE: 'average',
};

export const CUSTOM_LINECHART_WIDTH_PAGE_FLAG = {
    IMAGE: 'Image',
    ADRECALL: 'AdRecall',
    ADCOMPARISON: 'AdComparison',
};

export const PAGES_NEED_SEPARATE_LOBS_LEGENDS = [
    PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path,
    PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path,
    PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path,
    PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path,
];

export const STORE_NAME_ROUTE_BASED = {
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path]: 'awarenessData',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path]: 'considerationData',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path]: 'adRecallData',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.VALUE_FOR_MONEY.path]: 'valueForMoneyData',
    [PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path]: 'imageData',
};

export const sectionsHeadColor = {
    backgroundColor: defaultTheme.violet[100],
    color: defaultTheme.violet[500],
};

export const METRICS_VALUE_COLORS_MAP = {
    1: {
        border: defaultTheme.green[300],
        background: defaultTheme.green[60],
        textColor: defaultTheme.green[500],
    },
    '-1': {
        border: defaultTheme.red[350],
        background: defaultTheme.red[70],
        textColor: defaultTheme.red[600],
    },
    0: {
        border: defaultTheme.blue[70],
        background: defaultTheme.grey[50],
        textColor: defaultTheme.blue[900],
    },
};
export const numFormatterExceptZero = number => {
    if (!Number.isFinite(number)) return null;
    return number === 0
        ? number
        : new Intl.NumberFormat('en-GB', { style: 'decimal', signDisplay: 'always' }).format(number);
};
