import { getAdDetailsAndMetricsDataActionCreator } from '../../pages/adTester/subpages/AdDetailsMetrics/data/actions';
import { getAdComparisonActionCreator } from '../../pages/adTester/subpages/AdsComparison/data/actions';
import { getBptPreferenceAnalysisAwarenessActionCreator } from '../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/awareness/data/actions';
import * as routes from '../../utils/routes';
import { processSideFilters } from '../components/SideFilters/utils/utils';
import { getCompetitiveMediaActionCreator } from '../../pages/competitiveMedia/subpages/CompetitiveMedia/data/actions';
import { getTransversalViewActionCreator } from '../../pages/transversalView/subpages/TransversalView/data/actions';
import { getSelectedCountryFromTopFiltersComputedSelector } from '../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/data/computedSelectors';
import { getSelectedCountryFromTopFiltersComputedSelectorAdDetails } from '../../pages/adTester/subpages/AdDetailsMetrics/data/computedSelectors';
import { getSelectedCountryFromTopFiltersComputedSelectorAdsComparison } from '../../pages/adTester/subpages/AdsComparison/data/computedSelectors';
const createMapWithSelectedCountries = (mapData, selectedCountries) => {
    if (mapData && mapData.map && mapData.map.length > 0) {
        return mapData.map.map(c => {
            if (selectedCountries.includes(c.countryId)) {
                return { ...c, checked: true };
            }
            return { ...c };
        });
    }
    return [];
};
export const createClassName = name => (name ? name.replace(/[^a-zA-Z0-9]/g, '-') : '');
const createFilteredOverviewTableData = (data, selectedCountries) => {
    if (data && data.length > 0) {
        /* Filter the data based on the selected countries */
        const filteredData = data.filter(element => {
            const { row } = element;

            const countryColumn = row.find(r => r.columnId === 'country_id');
            const { value } = countryColumn;

            return selectedCountries.includes(+value);
        });

        /* Transform it into a friendly format */
        let friendlyFormatted = [];
        filteredData.forEach(e => {
            const { row } = e;
            const dataObject = {};
            row.forEach(elem => {
                if (elem.columnId !== 'country_id') {
                    dataObject[elem.columnId] = elem.value;
                    dataObject[elem.columnId + '_lowBase'] = elem.lowBase;
                }
            });
            friendlyFormatted.push(dataObject);
        });
        return friendlyFormatted;
    }

    return [];
};

export const splitFilters = allFilters => {
    const topFilters = [];
    const sideFilters = [];
    allFilters.forEach(element => {
        if (element.type === 'Filter') {
            sideFilters.push(element);
        } else if (element.type === 'Top filter') {
            topFilters.push(element);
        }
    });

    return {
        topFilters,
        sideFilters: processSideFilters(sideFilters),
    };
};

export const FILTERS_CONFIG = {
    [routes.ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path]: {
        hasTopFilters: true,
        hasSideFilters: true,
        hasOptionsValidation: false,
        hasSubTabs: false,
        requestPageName: 'adTesterDetailsAndMetrics',
        submitOnlyOnDisplay: false,
        requestAction: getAdDetailsAndMetricsDataActionCreator,
        additionalParametersExtractor: getSelectedCountryFromTopFiltersComputedSelectorAdDetails,
    },
    [routes.ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path]: {
        hasTopFilters: true,
        hasSideFilters: true,
        hasOptionsValidation: true,
        hasSubTabs: false,
        requestPageName: 'adTesterComparison',
        submitOnlyOnDisplay: false,
        requestAction: getAdComparisonActionCreator,
        additionalParametersExtractor: getSelectedCountryFromTopFiltersComputedSelectorAdsComparison,
    },
    [routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path]: {
        //Filters are global, for all of the subtabs
        hasTopFilters: true,
        hasSideFilters: true,
        hasOptionsValidation: false,
        hasSubTabs: true,
        requestPageName: 'bptpreferenceanalysis',
        submitOnlyOnDisplay: true,
        requestAction: getBptPreferenceAnalysisAwarenessActionCreator,
        additionalParametersExtractor: getSelectedCountryFromTopFiltersComputedSelector,
    },
    [routes.COMPETITIVE_MEDIA.path]: {
        hasTopFilters: true,
        hasSideFilters: false,
        hasOptionsValidation: false,
        hasSubTabs: false,
        requestPageName: 'competitivemedia',
        submitOnlyOnDisplay: false,
        requestAction: getCompetitiveMediaActionCreator,
        additionalParametersExtractor: null,
    },
    [routes.TRANSVERSAL_VIEW_PAGE.path]: {
        hasTopFilters: true,
        hasSideFilters: false,
        hasOptionValidation: false,
        hasSubTabs: false,
        requestPageName: 'transversalview',
        submitOnlyOnDisplay: true,
        requestAction: getTransversalViewActionCreator,
        additionalParametersExtractor: null,
    },
};

/* Used in Overview pages to get map data/table data with the currently selected countries */
export const OVERVIEW_UTILS = {
    createMapWithSelectedCountries,
    createFilteredOverviewTableData,
};

/* Used in BPT and CM */
export const KPI_TYPES = {
    RANKING: 'Ranking',
    SIMPLE_BARCHART: 'BarChart',
    COMBINED: 'Combined',
    LINE_CHART: 'LineChart',
    STACKED_VERTICAL: 'StackedVerticalBarChart',
    LOBS: 'LOBS',
};
