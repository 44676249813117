import styled from 'styled-components';

export const SelectedFiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 44px;
    padding: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.grey[200]};
    width: 905px;
`;

export const SelectedFilter = styled.div`
    display: flex;
    align-items: center;
    margin: 0 6px;

    span {
        color: ${({ theme }) => theme.blue[950]};
        margin-right: 4px;
    }

    button {
        outline: none;
        border: none;
        background-color: ${({ theme }) => theme.red[700]};
        color: ${({ theme }) => theme.white.ff};
        border-radius: 50%;
        font-size: 6px;
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.1s ease-in;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const ClearAllButton = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.red[700]};
    font-weight: bold;
    padding-top: 3px;
    margin-left: 4px;
    cursor: pointer;
    transition: all 0.1s ease-in;

    &:hover {
        opacity: 0.7;
    }
`;
