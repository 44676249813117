import styled from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../../utils/responsiveness';

export const LegendWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.1rem;
    margin-bottom: 1.5rem;
    ${({ customStyle }) => customStyle}
`;

export const LegendTooltipContainer = styled.div`
    display: ${props => (props.sigLegendIsVisible ? 'flex' : 'none')};
    align-items: center;
    flex-direction: row;
    margin-right: 3rem;
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        flex-direction: column;
    }
`;
export const LegendRowContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
export const LegendTooltipItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0.25rem 0rem 0.25rem 1.25rem;
`;

export const LegendTooltipImage = styled.img`
    display: inline-block;
    margin-right: 0.25rem;
`;

export const LegendTooltipLabel = styled.span`
    display: inline-block;
    font-size: 14px;
`;

export const LegendRankSortContainer = styled.div`
    display: ${props => (props.rankingLegendIsVisible ? 'flex' : 'none')};
    align-items: center;
`;

export const LegendRankGreenArrow = styled.img`
    margin-top: -4px;
`;
export const LegendRankRedArrow = styled.img`
    margin-left: -3px;
    margin-top: 1rem;
    margin-right: 0.25rem;
`;

export const LegendRankLabel = styled.span``;

export const InfoPic = styled.div`
    margin-left: 5px;
    cursor: pointer;
    img {
        width: 20px;
        object-fit: contain;
    }
`;

export const TooltipText = styled.span`
    font-size: 15px;
`;

export const TooltipContainer = styled.div`
    display: ${props => (props.YTDIsVisbile ? 'flex' : 'none')};
    flex-direction: row;
    margin-left: 20px;
    margin-top: 10px;
`;
