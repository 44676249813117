import { createSelector } from 'reselect';
import {
    getAppliedSideFiltersSelector,
    getCurrentFilterPageSelector,
    getSideFiltersSelector,
    getTopFiltersSelector,
} from '../../../../../common/components/SideFilters/data/selectors';
import {
    getCachedLastAppliedTopFiltersOptionSelector,
    getLocationSelector,
    getSelectedOptionByPageSelector,
} from '../../../../../common/components/TopFilter/data/selectors';
import { TOP_FILTERS_OPTION_BE_KEY } from '../../../../../common/components/TopFilter/utils/constants';
import { getSectionBasedOnSectionID } from '../../../../../utils/generalUtilities';
import { KPI_TYPES } from '../../../../../common/utils/utils';
import { SECTIONS_IDS, ITEM_TYPE, shortenYearLabel, SPECIFIC_SORT_STRINGS } from '../../../utils/utils';
import { getCompetitiveMediaSectionsSelector } from './selectors';
import { TRANSLATION_TEXT } from './../../../../../utils/translations';
const ComputedDataInstance = (() => {
    const getInfoTextComputedSelector = createSelector([getCompetitiveMediaSectionsSelector], data => {
        const selectData = getSectionBasedOnSectionID(data, SECTIONS_IDS.INVESTMENT_SEASONALITY);
        if (selectData && selectData.hasOwnProperty('kpis') && selectData.kpis && selectData.infoText) {
            /*Prepare data for section info part*/
            return selectData.infoText;
        } else return null;
    });
    const getMediaMixChartComputedSelector = createSelector([getCompetitiveMediaSectionsSelector], mediaMixData => {
        const selectData = getSectionBasedOnSectionID(mediaMixData, SECTIONS_IDS.INVESTMENT_SEASONALITY);
        if (selectData && selectData.hasOwnProperty('kpis') && selectData.kpis) {
            /*Prepare data for section info part*/
            const sectionInfo = {
                scope: selectData.scope,
                source: selectData.source,
                currency: selectData.currency,
            };
            const accumulatedData = [];
            selectData.kpis.forEach(section => {
                if (!section.kpiID) return;
                if (section.kpiType === KPI_TYPES.STACKED_VERTICAL) {
                    const processedObject = { title: section.kpiDescription, kpiID: section.kpiID };
                    processedObject.chart = section.items.map(element => {
                        const barData = { name: element.itemLabel };
                        const colorContainer = [];
                        element.values.forEach(valueObject => {
                            /*Create an array of object with color data. */
                            if (colorContainer.findIndex(el => el.color === valueObject.color) < 0)
                                colorContainer.push({ color: valueObject.color, name: valueObject.label });
                            processedObject.nameColorGroup = colorContainer;

                            let extractedKey = valueObject.label.replace(/\s/g, '');
                            barData[extractedKey] =
                                valueObject.value === 0 ? valueObject.valueDouble : valueObject.value;
                        });
                        return barData; /*Return an object which is understand by D3 library e.g. : {name: label, key1: value,key2: value,...}*/
                    });
                    accumulatedData.push(processedObject);
                }
            });
            return {
                sectionSideNote: TRANSLATION_TEXT.COMPETITIVE_MEDIA_NOT_AGGREGATORS_INVESTMENT_NOTE,
                sectionInfo: sectionInfo,
                sectionLabel: selectData.sectionLabel,
                chartsData: accumulatedData,
            };
        }
        return null;
    });
    const getCombinedChartMarketOverviewDataSelector = createSelector(
        [getCompetitiveMediaSectionsSelector],
        competitiveMediaData => {
            const subTabStoreData = getSectionBasedOnSectionID(competitiveMediaData, SECTIONS_IDS.MARKET_OVERVIEW);
            if (subTabStoreData && subTabStoreData.hasOwnProperty('kpis') && subTabStoreData.kpis) {
                const subTabStoreDatakpis = subTabStoreData.kpis;
                const concatArr = subTabStoreDatakpis[0].items[0].values.concat(subTabStoreDatakpis[0].items[1].values);

                const stackedBarsData = Array.from(new Set(concatArr.map(s => s.label))).map((lab, k) => {
                    return {
                        name: lab,
                        H1: subTabStoreDatakpis[0].items[0].values[k].value,
                        H2: subTabStoreDatakpis[0].items[1].values[k].value,
                    };
                });
                const stackedColorsData = Array.from(new Set(concatArr.map(s => s.color))).map((lab, k) => {
                    return {
                        color: lab,
                        name: subTabStoreDatakpis[0].items[k].itemLabel,
                    };
                });
                const processLegend = items => {
                    return items.map(eachItem => {
                        return {
                            name: eachItem.itemLabel,
                            color: eachItem.values[0].color,
                        };
                    });
                };
                const processLineData = source =>
                    source.map(item => {
                        return {
                            name: item.itemLabel || item.label,
                            color: item.values[0].color,
                            values: item.values.map(({ value, label }) => ({
                                value,
                                group: label,
                            })),
                        };
                    });
                let processedData = subTabStoreData.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.COMBINED) {
                        const lineProcessedData = processLineData(
                            currentKpy.items.filter(item => item.itemType === ITEM_TYPE.LINE)
                        );
                        const legendProcessedData = processLegend(currentKpy.items);
                        const { scope, source, currency } = subTabStoreData;
                        accumulatedData = {
                            stackedData: stackedBarsData,
                            color: stackedColorsData,
                            lineData: lineProcessedData,
                            chartTitle: currentKpy.kpiLabel,
                            sectionLabel: subTabStoreData.sectionLabel,
                            sectionSideNote: TRANSLATION_TEXT.COMPETITIVE_MEDIA_NOT_AGGREGATORS_INVESTMENT_NOTE,
                            sectionInfo: {
                                scope,
                                source,
                                currency,
                            },
                            legend: legendProcessedData,
                        };
                        return accumulatedData;
                    }

                    return accumulatedData;
                }, {});
                return processedData;
            }
            return null;
        }
    );

    const getCombinedInsurersInvestmentDataDataSelector = createSelector(
        [getCompetitiveMediaSectionsSelector],
        competitiveMediaData => {
            const subTabStoreData = getSectionBasedOnSectionID(
                competitiveMediaData,
                SECTIONS_IDS.INVESTMENT_SEASONALITY_LAST
            );
            if (subTabStoreData && subTabStoreData.hasOwnProperty('kpis') && subTabStoreData.kpis) {
                const getShortenedLabel = label => {
                    const { shortenedLabel, shortenedYear } = shortenYearLabel(label.split('-').join(' '));
                    return `${shortenedLabel} ${shortenedYear}`;
                };

                const processLegend = items => {
                    return items.map(eachItem => {
                        return {
                            name: eachItem.itemLabel,
                            color: eachItem.values[0].color,
                        };
                    });
                };
                const processBarData = source => {
                    return source.map(barValue => {
                        const { color, value, label, midValue } = barValue;

                        return {
                            color,
                            value,
                            midValue,
                            group: getShortenedLabel(label.split('-').join(' ')),
                            name: label,
                        };
                    });
                };
                const processLineData = source =>
                    source.map(item => {
                        return {
                            name: item.itemLabel || item.label,
                            color: item.values[0].color,
                            values: item.values.map(({ value, label }) => ({
                                value,
                                group: getShortenedLabel(label.split('-').join(' ')),
                            })),
                        };
                    });

                let processedData = subTabStoreData.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.COMBINED) {
                        const extractedBarData = currentKpy.items.find(item => item.itemType !== ITEM_TYPE.LINE);
                        let barProcessedData = [];

                        if (extractedBarData) {
                            barProcessedData = processBarData(extractedBarData.values);
                        }

                        const lineProcessedData = processLineData(
                            currentKpy.items.filter(item => item.itemType === ITEM_TYPE.LINE)
                        );
                        const legendProcessedData = processLegend(currentKpy.items);
                        const { scope, source, currency } = subTabStoreData;
                        accumulatedData = {
                            barData: { values: barProcessedData, name: 'TotalMarketBarData' },
                            lineData: lineProcessedData,
                            chartTitle: currentKpy.kpiLabel,
                            sectionLabel: subTabStoreData.sectionLabel,
                            sectionSideNote: TRANSLATION_TEXT.COMPETITIVE_MEDIA_NOT_AGGREGATORS_INVESTMENT_NOTE,
                            sectionInfo: {
                                scope,
                                source,
                                currency,
                            },
                            legend: legendProcessedData,
                        };
                        return accumulatedData;
                    }

                    return accumulatedData;
                }, {});

                return processedData;
            }
            return null;
        }
    );

    const getSOVDataComputedSelector = createSelector([getCompetitiveMediaSectionsSelector], competitiveMediaData => {
        const subTabStoreData = getSectionBasedOnSectionID(competitiveMediaData, SECTIONS_IDS.AXA_VS_COMPETITORS);
        if (subTabStoreData && subTabStoreData.hasOwnProperty('kpis') && subTabStoreData.kpis) {
            const { kpis, sectionLabel, currency, scope, source } = subTabStoreData;
            let brands = [];

            kpis.forEach(kpi => {
                const { kpiDescription, items, kpiColor } = kpi;
                const brand = {
                    sectionLabel,
                    name: kpiDescription,
                    color: kpiColor,
                    data: [],
                };

                /* Extract data in a neat yearly format */
                const yearlyData = items[0].values.reduce((prev, curr) => ({ ...prev, [curr.label]: {} }), {});
                items.forEach(item => {
                    const { values, itemType, itemLabel } = item;

                    values.forEach(value => {
                        const selectedYear = yearlyData[value.label];
                        switch (itemType) {
                            case 'box':
                                selectedYear.sov = value.value;
                                break;
                            case ITEM_TYPE.LINE:
                                selectedYear.line = {
                                    color: value.color,
                                    value: value.valueDouble,
                                };
                                break;
                            case ITEM_TYPE.BAR:
                                if (!selectedYear.hasOwnProperty('bars')) {
                                    selectedYear.bars = {};
                                }
                                selectedYear.bars = {
                                    ...selectedYear.bars,
                                    [itemLabel.toLowerCase()]: {
                                        color: value.color,
                                        value: value.valueDouble,
                                    },
                                };
                                break;
                        }
                    });
                });

                /* Transform object into array of years*/
                brand.data = Object.keys(yearlyData).map(key => {
                    return {
                        ...yearlyData[key],
                        year: key,
                    };
                });

                brands.push(brand);
            });

            return {
                sectionInfo: {
                    scope,
                    source,
                    currency,
                },
                sectionSideNote: TRANSLATION_TEXT.COMPETITIVE_MEDIA_NOT_AGGREGATORS_INVESTMENT_NOTE,
                chartData: brands,
            };
        }
    });
    const getStackedBarchartWithMidvaluesAndCombineChartDataSelector = createSelector(
        [getCompetitiveMediaSectionsSelector],
        data => {
            const selectData = getSectionBasedOnSectionID(data, SECTIONS_IDS.MARKET_INVESTMENT);
            if (selectData && selectData.hasOwnProperty('kpis') && selectData.kpis) {
                /*Prepare data for section info part */
                const sectionInfo = {
                    scope: selectData.scope,
                    source: selectData.source,
                    currency: selectData.currency,
                };

                const dataCollector = {};
                selectData.kpis.forEach(section => {
                    if (!section.kpiID) return;
                    if (section.kpiType === KPI_TYPES.STACKED_VERTICAL) {
                        const stackedBarChartData = { chartTitle: section.kpiDescription, kpiID: section.kpiID };
                        stackedBarChartData.chart = section.items.map(element => {
                            const barData = {
                                name: element.itemLabel,
                                midValue: {},
                                kpiID: section.kpiID,
                            };
                            const colorArray = [];
                            const legendItems = [];
                            element.values.forEach(valueObject => {
                                /*Create an array of object with color data. */
                                if (colorArray.findIndex(el => el.color === valueObject.color) < 0)
                                    colorArray.push({
                                        color:
                                            valueObject.label === 'Aggregators'
                                                ? 'url(#filtered-lines-pattern)'
                                                : valueObject.color,
                                        name: valueObject.label,
                                    }); //replace the hex code for transparent
                                stackedBarChartData.color = colorArray;
                                /*Create an array of object with legend data. */
                                if (legendItems.findIndex(el => el.color === valueObject.color) < 0)
                                    legendItems.push({ color: valueObject.color, name: valueObject.label });
                                stackedBarChartData.legend = legendItems;

                                let extractedKey = valueObject.label.replace(/\s/g, '');
                                barData[extractedKey] =
                                    valueObject.value === 0 ? valueObject.valueDouble : valueObject.value;
                                barData.midValue[extractedKey] = valueObject.midValue;
                            });
                            return barData; /*Return an object which is understand by D3 library e.g. : {name: label, midValue: {...}, key1: value, key2: value,...}*/
                        });
                        dataCollector.stackedBarChartData = stackedBarChartData;
                    }
                    if (section.kpiType === KPI_TYPES.COMBINED) {
                        const { items } = section;
                        const combinedChartData = { chartTitle: section.kpiLabel };
                        const lineChartDataAuxArray = [];
                        const lineChartLegendArray = [];
                        items.forEach(item => {
                            if (item.itemType === ITEM_TYPE.BAR) {
                                combinedChartData.barData = {
                                    name: item.itemLabel,
                                    values: item.values.map(({ color, valueDouble, label }) => ({
                                        color,
                                        value: valueDouble,
                                        group: label,
                                    })),
                                };
                            } else {
                                const lineChartItem = {
                                    name: item.itemLabel,
                                    color: item.values[0].color,
                                    itemOrder: item.itemOrder,
                                    values: item.values.map(({ value, label }) => ({
                                        value,
                                        group: label,
                                    })),
                                };
                                lineChartDataAuxArray.push(lineChartItem); // ask if data comes sorted from be
                                combinedChartData.lineData = lineChartDataAuxArray;
                            }

                            const lineChartLegendItem = {
                                name: item.itemLabel,
                                color: item.values[0].color,
                            };
                            lineChartLegendArray.push(lineChartLegendItem); // ask if data comes sorted from be
                            combinedChartData.legend = lineChartLegendArray;
                        });
                        dataCollector.combinedChartData = combinedChartData;
                    }
                });

                return {
                    stackedBarChartMidValues: dataCollector.stackedBarChartData,
                    combinedChart: dataCollector.combinedChartData,
                    sectionInfo: sectionInfo,
                    sectionLabel: selectData.sectionLabel,
                    sectionSideNote: TRANSLATION_TEXT.COMPETITIVE_MEDIA_AGGREGATORS_INVESTMENT_NOTE,
                };
            }
            return null;
        }
    );
    const getKeyCompetitorsComputedSelector = createSelector([getCompetitiveMediaSectionsSelector], data => {
        const subTabStoreData = getSectionBasedOnSectionID(data, SECTIONS_IDS.INVESTMENT_SEASONALITY_LAST);

        if (subTabStoreData) {
            const keyCompetitors = subTabStoreData.kpis.find(kpi => kpi.kpiID === 1);
            return extractCompetitorsData(keyCompetitors);
        }
        return null;
    });
    const getTopCompetitorsComputedSelector = createSelector([getCompetitiveMediaSectionsSelector], data => {
        const subTabStoreData = getSectionBasedOnSectionID(data, SECTIONS_IDS.INVESTMENT_SEASONALITY_LAST);

        if (subTabStoreData) {
            const keyCompetitors = subTabStoreData.kpis.find(kpi => kpi.kpiID === 2);
            return extractCompetitorsData(keyCompetitors);
        }
        return null;
    });

    const combinedSelectors = createSelector(
        [
            getMediaMixChartComputedSelector,
            getCombinedChartMarketOverviewDataSelector,
            getCombinedInsurersInvestmentDataDataSelector,
            getSOVDataComputedSelector,
            getStackedBarchartWithMidvaluesAndCombineChartDataSelector,
            getKeyCompetitorsComputedSelector,
            getTopCompetitorsComputedSelector,
            getInfoTextComputedSelector,
        ],
        (
            mediaMixEvolutionData,
            marketOverviewData,
            totalMarketData,
            competitorsEvolutionData,
            marketInvestmentData,
            keyCompetitorsData,
            topTwentyCompetitorsData,
            infoTextData
        ) => {
            return {
                mediaMixEvolutionData,
                marketOverviewData,
                totalMarketData,
                competitorsEvolutionData,
                marketInvestmentData,
                keyCompetitorsData,
                topTwentyCompetitorsData,
                infoTextData,
            };
        }
    );

    return combinedSelectors;
})();

export const getComputedFiltersParametersForCompetitiveMedia = createSelector(
    [
        getTopFiltersSelector,
        getCachedLastAppliedTopFiltersOptionSelector,
        getAppliedSideFiltersSelector,
        getCurrentFilterPageSelector,
        getSelectedOptionByPageSelector,
        getLocationSelector,
        getSideFiltersSelector,
    ],
    (topFilters, cachedSelectedOption, filters, currentPage, selectedOptionsByPage, location) => {
        try {
            let filtersList = [];

            let selectedTopFiltersOption;

            selectedTopFiltersOption = selectedOptionsByPage[location];

            const topFilterGroupId = topFilters?.filter(el =>
                el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
            )[0]?.id;
            /*If current state of the top filters do represent a set of filters with an option selected
                retrieve last working filtres from cache */
            if (!selectedTopFiltersOption.length) {
                const cachedOptions = cachedSelectedOption[location]?.filter(el =>
                    el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
                );
                selectedTopFiltersOption = cachedOptions;
            }

            /* Add parameters from top filters */
            if (selectedTopFiltersOption) {
                let ids = [];
                let groupId = null;

                selectedTopFiltersOption.forEach(el => {
                    ids.push(el.id);
                    if (!groupId) {
                        groupId = topFilterGroupId || el.groupId; //Apply the last working group from cache if user comes from a section that has a different group BE key
                    }
                });

                filtersList.push({ ID: '' + groupId, List: ids });
            }
            return filtersList;
        } catch (error) {}
    }
);

const extractCompetitorsData = data => {
    const computedData = data.items.map(item => {
        const computedItem = {
            name: item.itemLabel,
        };
        item.values.forEach(value => {
            computedItem[value.label] = value.value;
        });
        return computedItem;
    });

    const computedColors = data.items[0].values.map(item => ({
        color: item.color,
        label: item.label,
    }));

    computedColors.sort((a, b) => {
        const aLowerToLocale = a.label.toLowerCase();
        const bLowerToLocale = b.label.toLowerCase();

        if (aLowerToLocale === SPECIFIC_SORT_STRINGS.AXA) {
            return -1;
        }
        if (bLowerToLocale === SPECIFIC_SORT_STRINGS.AXA) {
            return 1;
        }
        if (aLowerToLocale === SPECIFIC_SORT_STRINGS.OTHERS) {
            return 1;
        }
        if (bLowerToLocale === SPECIFIC_SORT_STRINGS.OTHERS) {
            return -1;
        }

        return a.label.localeCompare(b.label);
    });

    return {
        data: computedData,
        colors: computedColors,
    };
};

export default ComputedDataInstance;
