import React from 'react';
import { capitalizeFirstLetter } from '../../../utils/generalUtilities';
import * as SC from './SectionInfo.styles';
import PropTypes from 'prop-types';

const SectionInfo = ({ sectionSideNote, sectionData, customStyle }) => {
    const sectionDataKeys = Object.keys(sectionData);

    return (
        <SC.SectionWrapper>
            <SC.SectionInnerDetails customStyle={customStyle?._topSection}>
                {sectionDataKeys.map((el, i) => (
                    <SC.SectionList key={el + i}>
                        <SC.SectionKey>{capitalizeFirstLetter(el)}</SC.SectionKey> :{' '}
                        <SC.SectionVal>{sectionData[el]}</SC.SectionVal>
                    </SC.SectionList>
                ))}
            </SC.SectionInnerDetails>
            <SC.SectionInnerSideNoteText customStyle={customStyle?._bottomSection}>
                {sectionSideNote}
            </SC.SectionInnerSideNoteText>
        </SC.SectionWrapper>
    );
};

SectionInfo.propTypes = {
    sectionData: PropTypes.shape({
        scope: PropTypes.string,
        source: PropTypes.string,
        currency: PropTypes.string,
    }),
    sectionSideNote: PropTypes.string,
    customStyle: PropTypes.shape({
        _topSection: PropTypes.object,
        _bottomSection: PropTypes.object,
    }),
};

export default SectionInfo;
