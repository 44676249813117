import { createSelector } from 'reselect';
import { getBptPreferenceAnalysisDataSelector } from '../../../data/selectors';
import { getLocationSelector } from '../../../../../../../common/components/TopFilter/data/selectors';
import { STORE_NAME_ROUTE_BASED } from '../../utils/utils';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';

export const getLineChartSubSectionsComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        const mainSubSections = [];
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            subTabStoreData.sections.forEach(section =>
                section.kpis?.forEach(el => {
                    if (el.kpiType === KPI_TYPES.LINE_CHART) {
                        const idx = mainSubSections.indexOf(el.items[0].itemLabel);
                        if (idx === -1) {
                            mainSubSections.push(el.items[0].itemLabel);
                        }
                    }
                })
            );
        }
        return mainSubSections;
    }
);
export const getLineChartLegendComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];

        const processLegend = items => {
            return items.map(eachItem => {
                return {
                    name: eachItem.itemLabel,
                    color: eachItem.values[0].color,
                };
            });
        };

        const legendItems = {};
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                legendItems[section.sectionID] = section.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.LINE_CHART) {
                        const data = processLegend(currentKpy.items);
                        //Is ok to rewrite last data on each iteration, it is similar for multiple kpiTypes in the same sectionID
                        accumulatedData[currentKpy.items[0].itemLabel] = data;
                        return accumulatedData;
                    }
                    return accumulatedData;
                }, {});
            });
            return legendItems;
        }
    }
);
export const geLineChartDataSelector = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            const dataBySectionID = {};

            const processLineData = source => {
                return source.map(item => {
                    return {
                        name: item.itemLabel,
                        color: item.values[0].color,
                        values: item.values.map(({ value, label, sigtest, evolutionMetricValue, nrRespondents }) => ({
                            value,
                            group: label,
                            sigtest,
                            evolutionMetricValue,
                            numberOfRespondents: nrRespondents,
                        })),
                    };
                });
            };

            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                dataBySectionID[section.sectionID] = section.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.LINE_CHART) {
                        if (!currentKpy.items || !currentKpy.items.length) return [];
                        const lineProcessedData = processLineData(currentKpy.items);

                        let kpiData = accumulatedData[currentKpy.items[0].itemLabel];
                        accumulatedData[currentKpy.items[0].itemLabel] = kpiData || [];
                        accumulatedData[currentKpy.items[0].itemLabel].push({
                            lineData: lineProcessedData,
                            brandName: currentKpy?.kpiDescription,
                            considerText: currentKpy?.kpiLabel,
                            numberOfRespondents: lineProcessedData[0].values.map(value => ({
                                group: value.group,
                                numberOfRespondents: value.numberOfRespondents,
                            })),
                        });
                        return accumulatedData;
                    }
                    return accumulatedData;
                }, {});
            });

            return dataBySectionID;
        }
        return null;
    }
);
