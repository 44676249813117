import {
    getConfirmitPidEntries,
    getDataFileEntries,
    getMetadataFileEntries,
    getCachingStatus,
} from '../../../services/apiServer.service';
import {
    TABLE_REQUEST_METADATA_ASYNC,
    TABLE_REQUEST_DATA_UPLOAD_ASYNC,
    TABLE_REQUEST_CONFIRMIT_ASYNC,
    CACHING_STATUS,
} from '../data/actions';

export const TABLE_HANDLERS_MAP = {
    METADATA_UPLOADS: {
        KEY: 'metaDataFileEntries',
        REQUEST: getMetadataFileEntries,
        ASYNC_ACTION: TABLE_REQUEST_METADATA_ASYNC,
    },
    DATA_UPLOADS: {
        KEY: 'dataFileEntries',
        REQUEST: getDataFileEntries,
        ASYNC_ACTION: TABLE_REQUEST_DATA_UPLOAD_ASYNC,
    },
    CONFIRMIT_UPLOADS: {
        KEY: 'confirmitPidEntries',
        REQUEST: getConfirmitPidEntries,
        ASYNC_ACTION: TABLE_REQUEST_CONFIRMIT_ASYNC,
    },
    CACHING_STATUS: {
        KEY: 'cachingStatus',
        REQUEST: getCachingStatus,
        ASYNC_ACTION: CACHING_STATUS,
    },
};
export const getSelectedFileContentType = currentSelection => {
    let fileNameSplited = currentSelection.name.split('.');
    let fileExtension = fileNameSplited[fileNameSplited.length - 1];
    switch (fileExtension) {
        case 'csv':
            return 'text/csv';
        case 'txt':
            return 'text/plain';
        case 'png':
            return 'image/png';
        case 'mp4':
            return 'video/mp4';
        default:
            //Default text/plain - BE will throw an error because the actual file extension will not match the selected file - so the request will fail - showing the error modal.
            return 'text/plain';
    }
};
export const PRESIGNED_IMPORTY_TYPE_ID = {
    DATA: 1,
    METADATA: 2,
    IMAGES: 3,
    VIDEOS: 4,
};
