import { all, put, takeEvery } from 'redux-saga/effects';
import { addAsyncErrorActionCreator } from '../../../errorHandling/data/actions';
import { logNavigationRequest } from '../../../services/apiServer.service';
import { ON_USER_INTERACTION } from './actions';

function* logUserInteractionSaga(action) {
    const currentLoggedActionID = action.payload;
    try {
        yield logNavigationRequest(currentLoggedActionID);
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action, disableNotification: true }));
    }
}

export default function* userWatcherSaga() {
    yield all([takeEvery(ON_USER_INTERACTION, logUserInteractionSaga)]);
}
