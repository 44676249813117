import { GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC } from './actions';

export const valueForMoneyTabReducer = (state = null, action) => {
    switch (action.type) {
        case GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.actions.SUCCEEDED:
            return action.payload;
        case GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.actions.FAILED:
            return null;
        default:
            return state;
    }
};
