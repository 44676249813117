/* eslint-disable no-const-assign */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { basePath } from '../../../../../services/utils';
import { onCompareSelectedAdsActionCreator, onSelectAdAdsComparisonActionCreator } from '../data/actions';
import { getAdTesterOverviewSelectedAdsSelector } from '../data/selectors';
import { OverviewImage, CompareAds, OverviewAdsComparisonWrapper } from './overview.styles';
import { TRANSLATION_TEXT } from '../../../../../utils/translations';
import { MAX_ADS_COMPARISON, MIN_ADS_COMPARISON } from '../../../constants';

export const ViewAdsComparisonButton = ({ data, rowID, selectedAdsIds, setSelectedAdsId, onCompareSelecteAds }) => {
    const [isHovered, setIsHovered] = useState(false);

    const onAdsClick = () => {
        setSelectedAdsId({ rowID, ...data });
    };
    const lastCheckedElement = selectedAdsIds[selectedAdsIds.length - 1];
    const isLastElement = rowID === lastCheckedElement?.rowID;
    const isSelected = selectedAdsIds.findIndex(el => el.rowID === rowID) !== -1;
    const isMaximum = selectedAdsIds.length >= MAX_ADS_COMPARISON;
    const displayConfig =
        isSelected &&
        isLastElement &&
        selectedAdsIds.length >= MIN_ADS_COMPARISON &&
        selectedAdsIds.length <= MAX_ADS_COMPARISON
            ? 'flex'
            : 'none';

    const imgSrcConfig = (() => {
        if (isSelected) {
            return `${basePath}/visuals/View_ads_comparison_on.svg`;
        } else if (isHovered) {
            return `${basePath}/visuals/View_ads_comparison_hover.svg`;
        } else if (isMaximum) {
            return `${basePath}/visuals/View_ads_comparison_disabled.svg`;
        } else {
            return `${basePath}/visuals/View_ads_comparison_off.svg`;
        }
    })();
    return (
        <OverviewAdsComparisonWrapper key={rowID}>
            <OverviewImage
                src={imgSrcConfig}
                width="42px"
                cursor="pointer"
                height="22px"
                onClick={onAdsClick}
                isSelected={isSelected}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                title={isMaximum ? TRANSLATION_TEXT.MAX_ADS_SELECTED_WARNING_HOVER : ''}
                data-testid={'img'+rowID}
            />

            <CompareAds display={displayConfig} onClick={() => onCompareSelecteAds()}>
                {TRANSLATION_TEXT.COMPARE_ADS} {selectedAdsIds.length}
                <br />
                {'>>>'}
            </CompareAds>
        </OverviewAdsComparisonWrapper>
    );
};

const mapStateToProps = state => ({
    selectedAdsIds: getAdTesterOverviewSelectedAdsSelector(state),
});

const mapDispatchToProps = {
    setSelectedAdsId: onSelectAdAdsComparisonActionCreator,
    onCompareSelecteAds: onCompareSelectedAdsActionCreator,
};

const ViewAdsComparisonButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ViewAdsComparisonButton);
export default ViewAdsComparisonButtonContainer;
