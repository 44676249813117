export const defaultTheme = {
    black: {
        ['ff']: '#000000',
        ['00']: '#00000000',
        [29]: '#00000029',
        [40]: '#00000040',
        [78]: '#00000078',
    },
    white: {
        ['ff']: '#ffffff',
    },
    grey: {
        [25]: '#f8f8f8',
        [30]: '#f9f9f9',
        [50]: '#f6f9fe',
        [70]: '#e6e9ef',
        [100]: '#ececec',
        [150]: '#e0e0e0',
        [200]: '#dbe1ee',
        [250]: '#d3d3d3',
        [300]: '#b7b7b7',
        [350]: '#aaaaaa',
        [400]: '#a0a0a0',
        [600]: '#999999',
        [800]: '#454545',
        [8080]: '#808080',
        [900]: '#2b2929',
    },
    red: {
        [1]: '#fff9f9',
        [50]: '#fff1f1',
        [70]: '#FEE8EC',
        [100]: '#ffdede',
        [300]: '#ffbfbf',
        [350]: '#FBABB8',
        [400]: '#ff4161',
        [500]: '#ff0000',
        [550]: '#F93253',
        [600]: '#ff3131',
        [700]: '#c91432',
        [800]: '#be1531',
    },
    orange: {
        [400]: '#ec4d33',
        [700]: '#ac463b',
    },
    yellow: {
        [500]: '#FFEA30',
    },
    green: {
        [50]: '#ECFFF2',
        [60]: '#E4FFEC',
        [100]: '#dbf3eb',
        [200]: '#58FF8A',
        [300]: '#9CE1A3',
        [400]: '#32bc71',
        [500]: '#1FAA2D',
        [700]: '#166e5b',
    },
    violet: {
        [100]: '#efd7fb',
        [400]: '#7e5894',
        [500]: '#A157CC',
        [600]: '#62377b',
    },
    blue: {
        [25]: '#F0F2F7',
        [50]: '#e2ecfc',
        [55]: '#e8f0fe',
        [60]: '#edf1f6',
        [70]: '#C1C9D6',
        [100]: '#c3d8f9',
        [200]: '#909cae',
        [300]: '#555a6d',
        [400]: '#4f658d',
        ['400-4d']: '#4d5d774d',
        [500]: '#0000ff',
        [600]: '#00008f',
        [700]: '#203582',
        [750]: '#4f5d69',
        [800]: '#3b4f70',
        [900]: '#243B5F',
        ['950-b3']: '#243b5fb3',
        ['950-52']: '#243B5F52',
        [950]: '#243b5f',
    },
    brown: {
        [100]: '#F0E8D8',
        [300]: '#f7f2ea',
        [400]: '#8A7333',
    },
};
