import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC, GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA } from './actions';
import { getAdDetailsMetricsData } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { getAuthTokensSelector } from '../../../../login/data/selectors';
import { getComputedFiltersParametersForRequest } from '../../../../../common/components/SideFilters/data/computedSelectors';

function* getAddDetailsMetricsSaga(action) {
    const tokens = yield select(getAuthTokensSelector);
    if (Object.keys(tokens).length > 0) {
        yield put(GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.dispatchers.started());
        try {
            const filtersAsRequestParameters = yield select(getComputedFiltersParametersForRequest);

            const adTesterOverviewData = yield call(getAdDetailsMetricsData, {
                filtersList: filtersAsRequestParameters,
            });
            yield put(GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.dispatchers.succeeded(adTesterOverviewData));
        } catch (error) {
            yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
            yield put(GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.dispatchers.failed(error));
        }
    }
}

export default function* adTesterOverviewSaga() {
    yield all([yield takeLatest(GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA, getAddDetailsMetricsSaga)]);
}
