import React from 'react';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import { TRANSLATION_TEXT } from '../../../../utils/translations';

const FlatFile = () => {
    return (
        <div>
            hi from FlatFile - BPT
            <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                <ExportButton type={EXPORT_TYPES.EXCEL} />
            </ExportButtonContainer>
        </div>
    );
};

export default FlatFile;
