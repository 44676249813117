import React, { useRef, useEffect, useMemo } from 'react';
import * as d3 from 'd3';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { HorizontalGroupedChartSvg, HorizontalGroupedChartWrapper } from './HorizontalGroupedChart.style';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import { calculateWidthValueForMoneyBarChart } from '../../../../../../utils/responsiveness';
import { createClassName } from '../../../../../../common/utils/utils';
import { numFormatterExceptZero } from '../../tabs/utils/utils';
import { wrapSVGText } from '../../../../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';

const HEIGHT = 330;
const BREAK_TEXT_AT_WIDTH = 80;

const HorizontalGroupedBarChart = ({ data, colors }) => {
    const [width] = useWindowSize();
    const svgRef = useRef(null);

    const WIDTH = useMemo(() => {
        return calculateWidthValueForMoneyBarChart(width);
    }, [width]);
    const CHART_X_AXIS_START = WIDTH < 420 ? 0.3 * WIDTH : 0.25 * WIDTH;

    useEffect(() => {
        if (svgRef && svgRef.current) {
            const svg = d3.select(svgRef.current);
            svg.selectAll('*').remove();

            const subgroups = Object.keys(data[0]);
            subgroups.splice(subgroups.indexOf('group'), 1);

            const groups = data
                .reduce((arr, curr) => {
                    arr.push(curr.group);
                    return arr;
                }, [])
                .reverse();

            /* Add axis */
            let xScale = d3
                .scaleLinear()
                .domain([0, 100])
                .range([CHART_X_AXIS_START, WIDTH - 20]);
            const xAxis = d3
                .axisBottom()
                .scale(xScale)
                .ticks(5)
                .tickFormat(d => d + '%');
            const xAxisElement = svg.append('g').attr('transform', `translate(0,${HEIGHT})`).call(xAxis);

            xAxisElement
                .selectAll('.tick line')
                .attr('y2', -20)
                .attr('y1', -HEIGHT)
                .attr('stroke-dasharray', '2.5,2.5')
                .attr('stroke', (_, i) => (i > 0 ? defaultTheme.grey['250'] : defaultTheme.blue[900]))
                .attr('stroke-width', 1);
            xAxisElement
                .selectAll('.tick text')
                .attr('y', (_, i) => (i > 0 ? -8 : 8))
                .style('color', defaultTheme.blue[750])
                .style('font-size', '11.5px')
                .attr('text-anchor', 'middle')
                .style('font-weight', 600);
            xAxisElement.selectAll('.domain').remove();

            let yScale = d3
                .scaleBand()
                .domain(groups.map(g => g))
                .range([HEIGHT, 0])
                .padding([0.4]);
            let yAxis = d3.axisLeft(yScale).ticks(5);

            const yAxisElement = svg
                .append('g')
                .style('transform', `translate(${CHART_X_AXIS_START}px, 0px)`)
                .call(yAxis);
            yAxisElement.selectAll('.domain').remove();
            yAxisElement.selectAll('.tick line').remove();
            yAxisElement.selectAll('.tick path').remove();

            yAxisElement
                .selectAll('.tick text')
                .call(wrapSVGText, BREAK_TEXT_AT_WIDTH, CHART_X_AXIS_START - 10)
                .style('color', defaultTheme.blue[750])
                .style('font-weight', 600)
                .style('font-size', '13px')
                .attr('text-anchor', 'start');

            const ySubgroup = d3.scaleBand().domain(subgroups).range([0, yScale.bandwidth()]).padding([0.05]);
            const color = d3
                .scaleOrdinal()
                .domain(subgroups)
                .range(colors.map(c => c.color));

            /* Add bars */
            const barGroups = svg
                .append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr('data-testid', d => `Horizontal-Gr-BarChart-${createClassName(d.group)}`)
                .attr('transform', function (d) {
                    return `translate(0,${yScale(d.group)})`;
                });

            barGroups
                .selectAll('rect')
                .data(d => subgroups.map(key => ({ key: key, value: d[key] })))
                .enter()
                .append('rect')
                .attr('x', CHART_X_AXIS_START)
                .attr('y', d => ySubgroup(d.key))
                .attr('width', d => xScale(d.value[0]) - CHART_X_AXIS_START)
                .attr('height', d3.min([20, ySubgroup.bandwidth() / 1.2]))
                .attr('fill', d => (d.key === 'filtered' ? 'url(#filtered-lines-pattern)' : color(d.key)));

            barGroups
                .selectAll('text')
                .data(d => subgroups.map(key => ({ key: key, value: d[key] })))
                .enter()
                .append('text')
                .attr('x', d => xScale(d.value[0]) + 6)
                .attr('y', d => ySubgroup(d.key))
                .attr('dy', () => ySubgroup.bandwidth() / 2)
                .style('color', defaultTheme.blue[900])
                .style('font-weight', 600)
                .style('font-size', '14px')
                .text(d => d.value[0] + '%');
            barGroups //second value
                .selectAll('foreignObject')
                .data(d => subgroups.map(key => ({ key: key, value: d[key] })))
                .enter()
                .append('foreignObject')
                .attr('x', d => xScale(d.value[0]) + 35) //distance between first and second value
                .attr('y', d => ySubgroup(d.key))
                .attr('dy', () => ySubgroup.bandwidth() / 2)
                .attr('width', '60px')
                .attr('height', '24px')

                .append('xhtml:div') //put value

                .attr('class', 'bar-secondValue-div')
                .style('display', d => (d.value[1] === null ? 'none' : 'block'))
                .style('text-align', 'center')
                .style('min-width', '30px')
                .style('width', 'fit-content')
                .style('padding', '2px 4px')
                .style('background-color', d => {
                    if (d.value[1] > 0) return defaultTheme.green[60];
                    if (d.value[1] === 0) return defaultTheme.grey[50];
                    else return defaultTheme.red[70];
                })
                .style('border', d => {
                    if (d.value[1] > 0) return '1px solid ' + defaultTheme.green[300];
                    if (d.value[1] === 0) return '1px solid ' + defaultTheme.blue[70];
                    else return '1px solid ' + defaultTheme.red[350];
                })
                .style('border-radius', '50px')
                .style('color', d => {
                    if (d.value[1] > 0) return defaultTheme.green[500];
                    if (d.value[1] === 0) return defaultTheme.blue[900];
                    else return defaultTheme.red[600];
                })
                .style('font-weight', 600)
                .style('font-size', '12px')
                .text(d => numFormatterExceptZero(d.value[1]) + ' ' + TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL);
        }
    }, [WIDTH, data]);
    return (
        <HorizontalGroupedChartWrapper
            style={{ width: WIDTH + WIDTH * 0.1 + 'px', height: HEIGHT + 0.1 * HEIGHT + 'px' }}
        >
            <HorizontalGroupedChartSvg width={WIDTH} height={HEIGHT} ref={svgRef} />
        </HorizontalGroupedChartWrapper>
    );
};

HorizontalGroupedBarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.string,
        })
    ),
    colors: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

HorizontalGroupedBarChart.defaultProps = {
    data: [],
    colors: [],
};

export default HorizontalGroupedBarChart;
