import React, { useRef, useEffect, useMemo } from 'react';
import * as d3 from 'd3';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { MappingChartWrapper, SvgContainer } from './MappingChart.styles';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { getChartAreasData, getLineLength, getMappingLabelsData, getMappingMainAxisLines } from './utilities';
import { initLabeler } from './Labeler';
import { calculateForResolution } from '../../../../../../utils/responsiveness';

const SVG_AREA_SIZE = {
    width: 1235,
    height: 895,
};
export const CHART_MARGIN = {
    top: 90,
    left: 90,
};

const AREA_COLORS = {
    GOOD_VALUE: '#FAF1FF',
    PREMIUM: '#E8F9ED',
    POOR_VALUE: '#FFECE6',
    LOW_COST: '#ECF6FF',
    GOOD_VALUE_TEXT: '#A157CC',
    PREMIUM_TEXT: '#1CC54E',
    POOR_VALUE_TEXT: '#FF6E40',
    LOW_COST_TEXT: '#243B5F',
};

const POINT_LINE_TO_LABEL_CUTOFF = 30;
const POINT_SIZE = 8;
const TOOLTIP_SIZE = {
    height: 107,
    width: 210,
};

const MappingChart = ({ data }) => {
    const svgRef = useRef(null);

    let labelsArray = [],
        anchorArray = [];

    const [width] = useWindowSize();

    const calculatedWidth = useMemo(() => {
        const calculated = calculateForResolution(width);
        return calculated > 1400 ? 1400 : calculated - 15;
    }, [width]);

    const calculatedHeight = useMemo(() => {
        const calculated = calculateForResolution(width);
        return calculated > 1400 ? SVG_AREA_SIZE.height : SVG_AREA_SIZE.height * 0.8;
    }, [width]);

    const drawAxis = (parent, xAxis, yAxis, size) => {
        parent
            .append('g')
            .attr('transform', `translate(0,${size.innerHeight})`)
            .attr('id', 'x-axis')
            .attr('class', 'axis')
            .call(xAxis);

        parent
            .append('g')
            .attr('transform', `translate(${CHART_MARGIN.left}, 0)`)
            .attr('id', 'y-axis')
            .attr('class', 'axis')
            .call(yAxis);

        parent
            .append('g')
            .attr('transform', `translate(${size.innerWidth}, 0)`)
            .attr('id', 'y-right-axis')
            .attr('class', 'axis')
            .call(yAxis);

        parent
            .append('g')
            .attr('transform', `translate(0,${CHART_MARGIN.top})`)
            .attr('id', 'x-right-axis')
            .attr('class', 'axis')
            .call(xAxis);

        parent.selectAll('.axis text').remove();
        parent.selectAll('.axis path').attr('stroke', defaultTheme.grey[800]);
    };

    const drawHelperAxis = (parent, dataRanges) => {
        const mainAxisData = getMappingMainAxisLines(CHART_MARGIN, dataRanges);

        const axisLines = parent.append('g').attr('class', 'mapping-helper-axis-lines');
        const gg2 = axisLines.selectAll('g').data(mainAxisData).enter().append('g');

        gg2.append('path')
            .attr('d', d => d3.line()(d.lines))
            .attr('stroke', defaultTheme.blue[900])
            .attr('stroke-width', 2)
            .style('marker-end', 'url(#Triangle)');

        gg2.append('text')
            .text(d => d.text.text)
            .style('font-size', '15px')
            .style('font-weight', 400)
            .style('color', defaultTheme.blue[900])
            .style('text-shadow', `0 0 1px ${defaultTheme.blue[900]}`)
            .style('transform', d => `translate(${d.text.x}px,${d.text.y}px) rotate(${d.text.rotate})`);
    };

    const drawAreas = (parent, dataRanges, areasData) => {
        const { meanX, maxX, minY, meanY } = dataRanges;
        parent
            .selectAll('rect')
            .data(areasData)
            .enter()
            .append('rect')
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .attr('width', d => d.width)
            .attr('height', d => d.height)
            .attr('fill', d => d.color);

        parent
            .append('line')
            .style('stroke', defaultTheme.grey[800])
            .style('stroke-dasharray', '3.3')
            .attr('x1', CHART_MARGIN.left)
            .attr('class', 'mean-x-line')
            .attr('y1', meanY)
            .attr('x2', maxX)
            .attr('y2', meanY)
            .style('marker-end', 'url(#Triangle)');

        parent
            .append('line')
            .style('stroke-width', 2.3)
            .attr('x1', maxX - 10)
            .attr('y1', meanY)
            .attr('x2', maxX)
            .attr('y2', meanY)
            .style('marker-end', 'url(#Triangle2)');

        parent
            .append('line')
            .style('stroke', defaultTheme.grey[800])
            .style('stroke-dasharray', '3.3')
            .attr('class', 'mean-y-line')
            .attr('x1', meanX)
            .attr('y1', CHART_MARGIN.top)
            .attr('x2', meanX)
            .attr('y2', minY);

        parent
            .append('line')
            .style('stroke-width', 2.3)
            .attr('x1', meanX)
            .attr('y1', CHART_MARGIN.top)
            .attr('x2', meanX)
            .attr('y2', CHART_MARGIN.top)
            .style('marker-end', 'url(#Triangle3)');
    };

    const drawLabels = (svg, parent, dataRanges, unscaledData) => {
        const labelsData = getMappingLabelsData(CHART_MARGIN, dataRanges, AREA_COLORS, unscaledData);

        const labels = parent.append('g');
        const labelGroups = labels.selectAll('g').data(labelsData).enter().append('g');

        labelGroups
            .append('foreignObject')
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .attr('height', d => d.height + 2)
            .attr('width', d => d.width)
            .append('xhtml:div')
            .style('width', d => d.width)
            .style('height', d => d.height + 'px')
            .attr('class', 'mappingLabelGroup')
            .style('background', d => d.color)
            .style('border', d => `1px solid ${d.textColor}`)
            .append('xhtml:span')
            .attr('class', 'mappingLabelGroupText')
            .style('font-size', d => d.fontSize)
            .style('color', d => d.textColor)
            .html(d => d.text)
            .on('mouseover', function (_, datum) {
                if (datum.tooltip) {
                    const { lineClass, triangleId, x, xPos, yPos, paddindValue } = datum.tooltip;
                    svg.select(lineClass).style('stroke-width', 2.1);
                    svg.select(triangleId).select('path').style('fill', defaultTheme.black.ff);

                    const tool = drawTooltip(
                        svg,
                        xPos - TOOLTIP_SIZE.width / 2 - 6,
                        yPos - TOOLTIP_SIZE.height,
                        paddindValue
                    );

                    tool.transition().duration(500).style('opacity', 1);
                    tool.html(`
                                 <h6>Mean Score</h6>
                                 <p>${parseFloat(x).toFixed(4)}</p>
                        `);
                }
            })
            .on('mouseout', function (_, datum) {
                if (datum.tooltip) {
                    const { lineClass, triangleId } = datum.tooltip;
                    svg.select(triangleId)
                        .select('path')
                        .style('fill', defaultTheme.white.ff)
                        .style('stroke', defaultTheme.black.ff);
                    svg.select(lineClass).style('stroke-width', null);
                    svg.select('.mapping-tooltip-foreign-object').remove();
                }
            });
    };

    const drawPoints = (svg, xScale, yScale) => {
        const { points } = data;

        //Convert the data to a format which can be easily modified by the labeler
        points.forEach(p => {
            labelsArray.push({
                finalX: 0,
                finalY: 0,
                x: xScale(p.xValue),
                y: yScale(p.yValue),
                w: 0,
                h: 0,
                productName: p.productName,
                done: false,
                Image: p.imageUrl,
                xValue: parseFloat(p.xValue).toFixed(10),
                yValue: parseFloat(p.yValue).toFixed(10),
            });
            anchorArray.push({
                x: xScale(p.xValue),
                y: yScale(p.yValue),
                w: 0,
                h: 0,
                productName: p.productName,
            });
        });

        const gDots = svg
            .append('g')
            .attr('id', 'points-area')
            .selectAll('g.dot')
            .data(labelsArray)
            .enter()
            .append('g');

        // Add the diamond points and hover/click functionality
        let gPoints = gDots
            .append('rect')
            .attr('class', 'dot')
            .attr('id', d => d.productName.replace(/[/'\s+]/g, ''))
            .attr('width', POINT_SIZE)
            .attr('height', POINT_SIZE)
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .style('fill', defaultTheme.blue[900])
            .style('transform-box', 'fill-box')
            .style('transform', 'rotate(45deg)')
            .style('transform-origin', 'center')
            .style('cursor', 'pointer')
            .style('stroke-width', '1.6')
            .style('opacity', 0)
            .on('mouseover', function (_, datum) {
                const { xValue, yValue, productName } = datum;
                const paddingValue = 16;
                const tool = drawTooltip(
                    svg,
                    xScale(xValue) - TOOLTIP_SIZE.width / 2 + POINT_SIZE / 2,
                    yScale(yValue) - TOOLTIP_SIZE.height * 1.02,
                    paddingValue
                );
                tool.transition().duration(500).style('opacity', 1);
                tool.html(`
                         <h6>${productName.length > 25 ? productName.substring(0, 20) + '...' : productName}</h6>
                         <p>x: ${parseFloat(xValue).toFixed(4)}</p>
                         <p>y: ${parseFloat(yValue).toFixed(4)}</p>
                `);

                /* Point animation */
                d3.select(this)
                    .transition()
                    .duration('100')
                    .attr('width', POINT_SIZE + 1)
                    .attr('height', POINT_SIZE + 1)
                    .style('fill', defaultTheme.red[400]);
                d3.select(this).transition().duration('300').style('fill', defaultTheme.red[400]);
            })
            .on('mouseout', function () {
                /* Clear any effects applied to tooltip and point */
                svg.select('.mapping-tooltip-foreign-object').remove();

                d3.select(this)
                    .transition()
                    .duration('300')
                    .attr('width', POINT_SIZE)
                    .attr('height', POINT_SIZE)
                    .style('fill', defaultTheme.blue[800]);
            });

        // Add the floating labels for each point
        let labels = gDots
            .append(d => {
                if (d.Image) {
                    return document.createElementNS('http://www.w3.org/2000/svg', 'image');
                } else {
                    return document.createElementNS('http://www.w3.org/2000/svg', 'text');
                }
            })
            .text(d => d.productName)
            .attr('class', 'floating-label')
            .attr('xlink:href', d => d.Image && d.Image)
            .attr('width', d => (d.Image ? 30 : null))
            .attr('x', d => d.x + 12)
            .attr('y', d => d.y + 4)
            .attr('fill', defaultTheme.blue[900])
            .style('font-weight', 600)
            .style('opacity', 0)
            .style('font-size', '12px');

        //Add the connection lines between each point and its floating label
        let links = gDots
            .append('line')
            .attr('class', 'link')
            .attr('x1', d => d.x)
            .attr('y1', d => d.y + POINT_SIZE / 2)
            .attr('x2', d => d.x)
            .attr('y2', d => d.y)
            .attr('stroke-width', 2)
            .attr('stroke', defaultTheme.grey[600]);

        //After display get each label's and point's  width and height. Info needed for the labeler
        let index = 0;
        labels.each(function () {
            labelsArray[index].w = this.getBBox().width;
            labelsArray[index].h = this.getBBox().height * 2;
            index += 1;
        });

        let index2 = 0;
        gPoints.each(function () {
            anchorArray[index2].w = this.getBoundingClientRect().width;
            anchorArray[index2].h = this.getBoundingClientRect().height * 2;
            index2 += 1;
        });

        const labeler = initLabeler();
        //Run the labeler which will modify the positions of the labels
        labeler().label(labelsArray).anchor(anchorArray).width(calculatedWidth).height(calculatedHeight).start();

        //Transition the labels into their proper positions
        gPoints.transition().duration(1000).style('opacity', 1);
        labels
            .transition()
            .duration(2000)
            .style('opacity', 1)
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .style('pointer-events', 'none');
        links
            .transition()
            .duration(2300)
            .attr('x2', function (d) {
                const prevX1 = d3.select(this).attr('x2');
                const prevY1 = d3.select(this).attr('y1');

                //Find optimal position of the line between the point and the label
                const beginLineLength = getLineLength(prevX1, d.x, prevY1, d.y);
                const midLineLength = getLineLength(prevX1, d.x + d.w / 2, prevY1, d.y);
                const endLineLength = getLineLength(prevX1, d.x + d.w, prevY1, d.y);

                const distancePointMapping = [
                    { size: beginLineLength, point: d.x },
                    { size: midLineLength, point: d.x + d.w / 2 },
                    { size: endLineLength, point: d.x + d.w },
                ];

                if (
                    beginLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    endLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    midLineLength < POINT_LINE_TO_LABEL_CUTOFF
                )
                    return prevX1;

                const min = Math.min(beginLineLength, midLineLength, endLineLength);

                return distancePointMapping.find(e => e.size === min).point;
            })
            .attr('y2', function (d) {
                const prevX1 = d3.select(this).attr('x1');
                const prevY1 = d3.select(this).attr('y1');

                //Find optimal position of the line between the point and the label
                const beginLineLength = getLineLength(prevX1, d.x, prevY1, d.y);
                const midLineLength = getLineLength(prevX1, d.x + d.w / 2, prevY1, d.y);
                const endLineLength = getLineLength(prevX1, d.x + d.w, prevY1, d.y);

                if (
                    beginLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    endLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    midLineLength < POINT_LINE_TO_LABEL_CUTOFF
                )
                    return prevY1;

                return d.y;
            })
            .style('pointer-events', 'none')
            .attr('marker-end', function (d) {
                const prevX1 = d3.select(this).attr('x1');
                const prevY1 = d3.select(this).attr('y1');

                //Find optimal position of the line between the point and the label
                const beginLineLength = getLineLength(prevX1, d.x, prevY1, d.y);
                const midLineLength = getLineLength(prevX1, d.x + d.w / 2, prevY1, d.y);
                const endLineLength = getLineLength(prevX1, d.x + d.w, prevY1, d.y);

                if (
                    beginLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    endLineLength < POINT_LINE_TO_LABEL_CUTOFF ||
                    midLineLength < POINT_LINE_TO_LABEL_CUTOFF
                )
                    return '';
                return 'url(#Triangle)';
            });
    };

    const drawTooltip = (
        svg,
        x = SVG_AREA_SIZE.width / 2,
        y = SVG_AREA_SIZE.height / 2,
        paddingvalue,
        customWidth = TOOLTIP_SIZE.width
    ) => {
        /* Append the tooltip */
        return svg
            .append('foreignObject')
            .attr('class', 'mapping-tooltip-foreign-object')
            .style('pointer-events', 'none')
            .style('padding-top', paddingvalue)
            .attr('x', x)
            .attr('y', y)
            .attr('height', TOOLTIP_SIZE.height)
            .attr('width', customWidth)
            .append('xhtml:div')
            .attr('class', 'mapping-tooltip')
            .style('opacity', 0);
    };

    useEffect(() => {
        if (svgRef && svgRef.current && data) {
            const svg = d3.select(svgRef.current);
            svg.selectAll('*')
                .filter(function () {
                    return !this.classList.contains('defs');
                })
                .remove();

            const innerWidth = calculatedWidth - CHART_MARGIN.left;
            const innerHeight = calculatedHeight - CHART_MARGIN.top;

            /* Create the scales */
            const xScale = d3
                .scaleLinear()
                .domain([data.mainXAxisMin, data.mainXAxisMax])
                .range([CHART_MARGIN.left, innerWidth]);
            const yScale = d3
                .scaleLinear()
                .domain([data.mainYAxisMin, data.mainYAxisMax])
                .range([innerHeight, CHART_MARGIN.top]);

            /* Important object that will be used to draw and compute the size and positioning of elements on screen */
            const scaledDataRanges = {
                minX: xScale(data.mainXAxisMin),
                meanX: xScale(data.xAxisMean),
                maxX: xScale(data.mainXAxisMax),
                minY: yScale(data.mainYAxisMin),
                meanY: yScale(data.yAxisMean),
                maxY: yScale(data.mainYAxisMax),
            };

            /* Create Axis builder */
            let xAxis = d3.axisBottom(xScale).tickSize(0);
            let yAxis = d3.axisLeft(yScale).tickSize(0);

            /* Draw the colored areas the the mean lines */
            const areas = svg.append('g').attr('class', 'chart-areas');
            const areasData = getChartAreasData(CHART_MARGIN, scaledDataRanges, AREA_COLORS);
            drawAreas(areas, scaledDataRanges, areasData, svg, data);

            /* Draw the axis of the chart */
            drawAxis(svg, xAxis, yAxis, { innerWidth, innerHeight });
            drawHelperAxis(svg, scaledDataRanges);

            /* Draw the labels at the side of the chart */
            drawLabels(svg, areas, scaledDataRanges, data);

            drawPoints(svg, xScale, yScale, scaledDataRanges.meanX, scaledDataRanges.meanY);
        }
    }, [data, calculatedWidth]);
    return (
        <MappingChartWrapper>
            <SvgContainer ref={svgRef} width={calculatedWidth} height={SVG_AREA_SIZE.height}>
                <defs className={'defs'}>
                    <marker className={'defs'} id="Triangle" viewBox="0 0 10 10" refX="0" refY="5" orient="auto">
                        <path
                            className={'defs'}
                            d="M 0 0 L 10 5 L 0 10 z"
                            style={{ fill: defaultTheme.black.ff, stroke: 'none' }}
                        />
                    </marker>
                    <marker className={'defs'} id="Triangle2" viewBox="0 0 10 10" refX="0" refY="5" orient="180">
                        <path
                            className={'defs'}
                            d="M 0 0 L 10 5 L 0 10 z"
                            style={{ fill: defaultTheme.white.ff, stroke: defaultTheme.black.ff }}
                        />
                    </marker>
                    <marker className={'defs'} id="Triangle3" viewBox="0 0 10 10" refX="0" refY="5" orient="90">
                        <path
                            className={'defs'}
                            d="M 0 0 L 10 5 L 0 10 z"
                            style={{ fill: defaultTheme.white.ff, stroke: defaultTheme.black.ff }}
                        />
                    </marker>
                </defs>
            </SvgContainer>
        </MappingChartWrapper>
    );
};

export default MappingChart;
