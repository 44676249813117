import { connect } from "react-redux";
import ForgotPassword from "./ForgotPassword";
import { forgotPasswordActionCreator } from "../../data/actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	forgotPasswordActionCreator,
};

const ForgotPasswordContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordContainer;
