const get = state => state.topFiltersData;

export const getSelectedTopFiltersByPageSelector = state => get(state).selectedTopFiltersByPage;
export const getSelectedOptionByPageSelector = state => get(state).selectedOptionByPage;
export const getTopFilterDependencyArraySelector = state => get(state).dependencyArray;
export const getLocationSelector = state => state.router.location.pathname;
export const getTemporarySelectedTopOptionSelector = state => get(state).temporarySelectedOption;
export const getTopFiltersInitStatusSelector = state => {
    const filters = getSelectedTopFiltersByPageSelector(state);
    return Object.keys(filters).length > 0;
};
export const getCachedLastAppliedTopFiltersOptionSelector = state => get(state).lastAppliedTopFiltersOption;
