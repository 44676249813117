import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';
import { StyledTextArea } from './TextArea.styles';

export const TextArea = React.forwardRef((props, ref) => {
    const { customStyle, ...rest } = props;
    return (
        <ThemeConsumer>
            {value => {
                const textAreaProps = {
                    theme: value,
                    customStyle,
                    ...rest,
                };
                return <StyledTextArea {...textAreaProps} ref={ref} />;
            }}
        </ThemeConsumer>
    );
});

TextArea.propTypes = {
    /** TextArea is disabled */
    disabled: PropTypes.bool,
    /** Error style */
    error: PropTypes.bool,
    /** Custom style */
    customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TextArea.defaultProps = {
    disabled: false,
    error: false,
};
