import styled from 'styled-components';

export const LineGraphWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 0;
    overflow: auto;
    // ** Custom styiling for ticks and text **
    & .tick {
        line {
            stroke-dasharray: 2.5 !important;
        }
        text {
            fill: ${({ theme }) => theme.blue[950]} !important;
            font-size: 13px !important;
        }
    }
`;

export const GraphTitleWrapper = styled.div`
    border: 2px solid ${({ theme }) => theme.blue[950]};
    height: 35px;
    width: fit-content;
    box-sizing: border-box;
    margin-bottom: 7px;
    padding: 0px 10px;
`;

export const GraphTitle = styled.h1`
    text-align: center;
    padding: 0;
    font-size: 16px;
    align-self: start;
    margin: 5px 0px;
    color: ${({ theme }) => theme.blue[950]};
`;

export const LineGraphOptionsWrapper = styled.div`
    min-height: 100px;
    width: 70%;
    flex-wrap: wrap;
    margin: 35px auto;
    box-sizing: border-box;
    padding: 50px;
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export const LineGraphOptionLine = styled.div`
    width: 40px;
    height: 4px;
    background: ${props => props.color};
    display: inline-block;
    position: relative;
    top: 3px;
`;
