import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { apiBindings } from '../../services/apiBindings.service';
import { setErrorNotificationDisplayActionCreator } from '../../../../../errorHandling/data/actions';
import useUserType from './useUserType';
import { STATE_KEY, SHOW_NOTIFICATIONS_ON_CODE, isNotUsedAnymore } from './utils/utils';

const ExternalUserTypeFilter = props => {
    const { state, setState, setInitialState } = props;
    const userTypeProps = { state, setState, setInitialState };
    const userType = useUserType(userTypeProps);

    const dispatch = useDispatch();
    const hasDataLoaded = useCallback(() => state.hasOwnProperty(STATE_KEY), [state]);

    useEffect(() => {
        if (!hasDataLoaded()) {
            (async function () {
                try {
                    const permissionsData = await apiBindings.getRoles();
                    const objectToSave = {
                        key: STATE_KEY,
                        value: {
                            Permissions: permissionsData.data.filter(el => el.type !== isNotUsedAnymore),
                        },
                    };
                    setState(objectToSave);
                    setInitialState && setInitialState(objectToSave);
                } catch (error) {
                    const status = error.hasOwnProperty('response') ? error.response.status : null;
                    if (SHOW_NOTIFICATIONS_ON_CODE.includes(status) || status === null) {
                        dispatch(setErrorNotificationDisplayActionCreator(true));
                    }
                }
            })();
        }
    }, [setInitialState, setState, hasDataLoaded]);

    return userType.renderUserType();
};

ExternalUserTypeFilter.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
};

export default ExternalUserTypeFilter;
