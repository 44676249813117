import React from 'react';
import { ReportOptionWrapper } from '../ReportOption/ReportOption.styles';
import styled from 'styled-components';

const SVG = styled.svg`
    transform: scale(0.8);
    .main-circle,
    .bottom-part,
    .top-part {
        transition: all 0.15s linear;
    }
    .fsa,
    .fsb,
    .fsc,
    .fsd,
    .main-circle {
        fill: ${({ theme }) => theme.white.ff};
        stroke: ${({ theme }) => theme.green[700]};
        stroke-width: 1.5px;
    }
    .fsb,
    .fsd {
        stroke-linecap: round;
    }
    .fsb {
        stroke-linejoin: round;
    }
    .fsc,
    .fsd {
        stroke-miterlimit: 10;
    }

    ${ReportOptionWrapper}:hover & {
        .main-circle {
            fill: ${({ theme }) => theme.green[100]};
        }
        .bottom-part {
            transform-origin: 30% 80%;
            transform: rotate(6deg);
        }
        .top-part {
            transform: translateY(-5px) translateX(60px) rotate(45deg);
        }
    }
`;

const FiltersegmentComponent = () => (
    <SVG width={140.998} height={150.329} viewBox="0 0 140.998 150.329">
        <g transform="translate(0.75 0.9)">
            <path
                className="main-circle"
                d="M70.469,147.778a69.749,69.749,0,1,1,69.749-69.749,69.749,69.749,0,0,1-69.749,69.749Z"
                transform="translate(-0.72 0.901)"
            />

            <g className="bottom-part">
                <ellipse
                    className="fsa "
                    cx={56.444}
                    cy={12.308}
                    rx={56.444}
                    ry={12.308}
                    transform="translate(13.693 59.545)"
                />
            </g>
            <g className="top-part">
                <rect
                    className="fsb "
                    width={51.595}
                    height={66.344}
                    rx={4.17}
                    transform="translate(37.133 11.685) rotate(-13.09)"
                />
                <rect
                    className="fsb"
                    width={33.598}
                    height={17.056}
                    rx={2.7}
                    transform="translate(54.122 42.974) rotate(-13.09)"
                />
                <path
                    className="fsb"
                    d="M58.914,4.67l5.342,22.936L69.99,23.8l6.552.952L71.2,1.814A1.378,1.378,0,0,0,69.553.784l-9.609,2.24a1.378,1.378,0,0,0-1.03,1.646Z"
                    transform="translate(6.255 -0.002)"
                />
            </g>
            <g className="bottom-part">
                <path
                    className="fsc"
                    d="M15496.908,15322.806a1.434,1.434,0,0,1-.677-.17,1.414,1.414,0,0,1-.758-1.276v-25.222a.432.432,0,0,0-.125-.3l-39.553-39.557c9.035,4.456,29.309,7.336,51.649,7.336a223.594,223.594,0,0,0,30.834-2c8.938-1.274,16.015-3.062,20.464-5.171l-40.009,39.51a.463.463,0,0,0-.125.3v11.331a2.421,2.421,0,0,1-1.117,2l-19.78,12.968A1.422,1.422,0,0,1,15496.908,15322.806Z"
                    transform="translate(-15437.104 -15179.456)"
                />
                <path
                    className="fsd"
                    d="M56.67,104.532a31.156,31.156,0,0,0,13.652-.2"
                    transform="translate(6.023 12.42)"
                />
            </g>
        </g>
    </SVG>
);

export default FiltersegmentComponent;
