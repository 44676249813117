import { all, call, put, takeLatest, select, take } from 'redux-saga/effects';
import {
    GET_ADTESTER_OVERVIEW_DATA_ACTION_CREATOR,
    GET_ADTESTER_OVERVIEW_DATA_ASYNC,
    ON_COMPARE_SELECTED_ADS_COMP_ADS,
    ON_SELECT_ADS_COMP_AD_CLICK,
    ON_VIEW_AD_CLICK,
    setSelectedAdsAdComparisonActionCreator,
} from './actions';
import { getAdTesterOverviewData } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { getAuthTokensSelector } from '../../../../login/data/selectors';
import { ADTESTER_PAGE } from '../../../../../utils/routes';
import { getTopFiltersSelector } from '../../../../../common/components/SideFilters/data/selectors';
import {
    initCacheLastAppliedTopFilterOptionActionCreator,
    setSelectedTopFiltersActionCreator,
    setSelectedTopOptionActionCreator,
    topFiltersOnChangeActionCreator,
} from '../../../../../common/components/TopFilter/data/actions';
import { getAdTesterOverviewSelectedAdsSelector } from './selectors';
import { GET_FILTERS_ASYNC } from '../../../../../common/data/actions';
import { push } from 'connected-react-router';
import { getAdComparisonActionCreator } from '../../AdsComparison/data/actions';
import { MAX_ADS_COMPARISON } from '../../../constants';

function* getOverviewData(action) {
    const tokens = yield select(getAuthTokensSelector);
    yield put(setSelectedAdsAdComparisonActionCreator([]));
    if (Object.keys(tokens).length > 0) {
        yield put(GET_ADTESTER_OVERVIEW_DATA_ASYNC.dispatchers.started());
        try {
            const adTesterOverviewData = yield call(getAdTesterOverviewData);
            yield put(GET_ADTESTER_OVERVIEW_DATA_ASYNC.dispatchers.succeeded(adTesterOverviewData));
        } catch (error) {
            yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
            yield put(GET_ADTESTER_OVERVIEW_DATA_ASYNC.dispatchers.failed(error));
        }
    }
}

function* onViewAdClickSaga(action) {
    try {
        const adData = action.payload;

        /* Wait for filters to be loaded on this page */
        yield take(GET_FILTERS_ASYNC.actions.SUCCEEDED);

        const topFilters = yield select(getTopFiltersSelector);

        let selectedTopFilters = [];
        const years = topFilters.find(t => t.label.toLowerCase() === 'year');
        const countries = topFilters.find(t => t.label.toLowerCase() === 'country');

        if (years && countries) {
            /* Select Year and the Country the ad belongs to */
            selectedTopFilters.push({
                id: adData.year_id,
                group: years.group,
                selectType: years.selectType,
                groupId: years.id,
            });

            const { id } = countries.options.find(
                option => option.label.toLowerCase() === adData.country.toLowerCase()
            );
            selectedTopFilters.push({
                id: id,
                group: countries.group,
                selectType: countries.selectType,
                groupId: countries.id,
            });
            selectedTopFilters.push({
                id: adData.id,
                group: adData.group,
                selectType: adData.selectType,
                groupId: adData.groupId,
            });
            yield put(
                setSelectedTopFiltersActionCreator({
                    key: ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path,
                    value: selectedTopFilters,
                })
            );
        }
        yield put(topFiltersOnChangeActionCreator(adData));
        yield put(initCacheLastAppliedTopFilterOptionActionCreator());
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error }));
    }
}

function* onSelectAdAdsComparisonSaga(action) {
    const { rowID } = action.payload;

    const currentSelectedAds = yield select(getAdTesterOverviewSelectedAdsSelector);
    const isAlreadySelectedIndex = currentSelectedAds.findIndex(el => el.rowID === rowID);
    const updatedSelection = [...currentSelectedAds];

    if (isAlreadySelectedIndex === -1 && currentSelectedAds.length < MAX_ADS_COMPARISON) {
        updatedSelection.push(action.payload);
    }

    if (isAlreadySelectedIndex !== -1) {
        updatedSelection.splice(isAlreadySelectedIndex, 1);
    }

    yield put(setSelectedAdsAdComparisonActionCreator(updatedSelection));
}

function* onCompareSelectedAdsSaga() {
    try {
        //Setting the top options to an empty array at begining so the effect on AdsComparison page points to an not valid option
        yield put(
            setSelectedTopOptionActionCreator({ key: ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path, value: [] })
        );
        yield put(push(ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path));

        yield take(GET_FILTERS_ASYNC.actions.SUCCEEDED);

        const currentSelectedAds = yield select(getAdTesterOverviewSelectedAdsSelector);
        const topFilters = yield select(getTopFiltersSelector);

        const countries = topFilters.find(t => t.label.toLowerCase() === 'country');
        const years = topFilters.find(t => t.label.toLowerCase() === 'year');
        const copyNames = topFilters.find(t => t.label.toLowerCase() === 'copy name');

        const { id: countryID } = countries.options.find(
            option => option.label.toLowerCase() === currentSelectedAds[0].Country.toLowerCase()
        );
        const selectedTopFilters = [];
        currentSelectedAds.forEach(currentAd => {
            const yearDoesNotExistIndex = selectedTopFilters.findIndex(ad => ad.id === +currentAd.year_id);
            if (yearDoesNotExistIndex === -1) {
                selectedTopFilters.push({
                    id: +currentAd?.year_id,
                    group: years.group,
                    selectType: years.selectType,
                    groupId: years.id,
                });
            }
        });
        selectedTopFilters.push({
            id: countryID,
            group: countries.group,
            selectType: countries.selectType,
            groupId: countries.id,
        });
        currentSelectedAds.forEach(currentAd => {
            selectedTopFilters.push({
                id: +currentAd.copy_responseoption_id,
                group: copyNames.group,
                selectType: copyNames.selectType,
                groupId: copyNames.id,
            });
        });

        const topOptions = selectedTopFilters.filter(el => el.group.toLowerCase() === 'copy name');
        yield put(
            setSelectedTopOptionActionCreator({
                key: ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path,
                value: topOptions || [],
            })
        );
        yield put(
            setSelectedTopFiltersActionCreator({
                key: ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path,
                value: selectedTopFilters,
            })
        );
        /*We need to dispatch the action of pulling the data from here
        as the Ads Comparison page will not trigger the getData() action due to the hasValidOption depdendency pointing to 
        an older value (empty array set at the begging of this saga)
        And if we add the dependency to the effect(hasValidOption) so it has the latest value, we'll trigger an request when selecting manualy more than 2 filters.*/
        yield put(getAdComparisonActionCreator());

        /*Needed the above 'workaround' as the existing values on Ads Comp Top filter will trigger a request before the above actions have been finished
        and to not overcomplicate things due to the multiple async calls, found this solutions the most realible and simple */
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error }));
    }
}

export default function* adTesterOverviewSaga() {
    yield all([
        yield takeLatest(GET_ADTESTER_OVERVIEW_DATA_ACTION_CREATOR, getOverviewData),
        yield takeLatest(ON_VIEW_AD_CLICK, onViewAdClickSaga),
        yield takeLatest(ON_SELECT_ADS_COMP_AD_CLICK, onSelectAdAdsComparisonSaga),
        yield takeLatest(ON_COMPARE_SELECTED_ADS_COMP_ADS, onCompareSelectedAdsSaga),
    ]);
}
