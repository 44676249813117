import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_COMPETITIVE_MEDIA = '/pages/competitiveMedia/GET_COMPETITIVE_MEDIA';
export const GET_COMPETITIVE_MEDIA_DATA_ASYNC = asyncActionCreator(
    '/pages/competitiveMedia/GET_COMPETITIVE_MEDIA_DATA_ASYNC'
);

export const getCompetitiveMediaActionCreator = () => ({
    type: GET_COMPETITIVE_MEDIA,
});
