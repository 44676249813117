import React, { useEffect, Fragment } from 'react';
import TopFilterGroup from '../../../../common/components/TopFilter/components/TopFilterGroup.container';
import { useFilters } from '../../../../common/hooks/useFilters';
import { TOP_FILTER_CARD_SIZE } from '../../../../common/components/TopFilter/utils/constants';
import MarketOverviewGroup from './components/MarketOverviewGroup/MarketOverviewGroup';
import MarketInvestmentGroup from './components/MarketInvestmentGroup/MarketInvestmentGroup';
import CompetitorsEvolutionGroup from './components/CompetitorsEvolutionGroup/CompetitorsEvolutionGroup';
import MediaMixEvolutionGroup from './components/MediaMixEvolutionGroup/MediaMixEvolutionGroup';
import InvestmentSeasonalityGroup from './components/InvestmentSeasonalityGroup/InvestmentSeasonalityGroup';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import NoDataAvailable from '../../../../common/components/NoDataAvailable/NoDataAvailable';
import * as SC from './CompetitiveMedia.styles';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { COMPETITIVE_MEDIA } from '../../../../utils/routes';

const CompetitiveMedia = ({
    data,
    existingStoreDataUnprocessed,
    filtersThrownError,
    hasTopFilterOptionLoaded,
    topFiltersRequestSuccessful,
    getData,
    filtersFor,
}) => {
    useFilters();

    useEffect(() => {
        if (hasTopFilterOptionLoaded && topFiltersRequestSuccessful && filtersFor === COMPETITIVE_MEDIA.path) {
            getData();
        }
    }, [hasTopFilterOptionLoaded, topFiltersRequestSuccessful, filtersFor]);

    const competitiveMediaDataLoaded = () =>
        existingStoreDataUnprocessed &&
        existingStoreDataUnprocessed.hasOwnProperty('sections') &&
        existingStoreDataUnprocessed.sections;

    const {
        marketOverviewData,
        mediaMixEvolutionData,
        totalMarketData,
        competitorsEvolutionData,
        marketInvestmentData,
        keyCompetitorsData,
        topTwentyCompetitorsData,
        infoTextData,
    } = data;

    return (
        <Fragment>
            {!filtersThrownError ? (
                <>
                    <TopFilterGroup cardSize={TOP_FILTER_CARD_SIZE.SMALL} />
                    <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                        <ExportButton type={EXPORT_TYPES.PPT} text={TRANSLATION_TEXT.EXPORT_TEXT} />
                    </ExportButtonContainer>
                </>
            ) : (
                <NoDataAvailable show={filtersThrownError && competitiveMediaDataLoaded()} />
            )}
            {competitiveMediaDataLoaded() ? (
                <>
                    <SC.SectionInfo>{TRANSLATION_TEXT.REPORT_UPDATE_STATS}</SC.SectionInfo>
                    <SC.SectionInfo>{infoTextData !== null ? infoTextData : ''}</SC.SectionInfo>
                    {marketOverviewData && <MarketOverviewGroup marketOverviewData={marketOverviewData} />}
                    {marketInvestmentData && Object.keys(marketInvestmentData).length > 0 && (
                        <MarketInvestmentGroup marketInvestmentData={marketInvestmentData} />
                    )}
                    {competitorsEvolutionData && (
                        <CompetitorsEvolutionGroup competitorsEvolutionData={competitorsEvolutionData} />
                    )}
                    {mediaMixEvolutionData && <MediaMixEvolutionGroup mediaMixEvolutionData={mediaMixEvolutionData} />}
                    {totalMarketData && keyCompetitorsData && topTwentyCompetitorsData && (
                        <InvestmentSeasonalityGroup
                            investmentSeasonalityData={{
                                keyCompetitorsData,
                                totalMarketData,
                                topTwentyCompetitorsData,
                            }}
                        />
                    )}
                </>
            ) : (
                <div style={{ marginBottom: '25px' }}>
                    <NoDataAvailable show={!competitiveMediaDataLoaded()} />
                </div>
            )}
        </Fragment>
    );
};

export default CompetitiveMedia;
