import styled from 'styled-components';
import { defaultTheme } from '../../../utils/defaultTheme';

export const OverviewTableWrapper = styled.div`
    div[role='table'] {
        border: none;
        box-shadow: none;
    }
    div[role='columnheader'] {
        padding: 5px;
        min-height: 55px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    div[role='cell'] {
        justify-content: center;
    }

    div[role='rowgroup'] div[role='row'] {
        div:nth-child(5) {
            border-left: ${props => props.hasBorderOnColumn && `1px dashed ${defaultTheme.blue[950]}`};
            border-right: ${props => props.hasBorderOnColumn && `1px dashed ${defaultTheme.blue[950]}`};
        }
    }
    div[role='row'] {
        font-size: 13px;
        border-bottom: 0;
    }
    div[role='table'] > div {
        min-height: 55px;
    }
    div[role='table'] > div > div {
        border-bottom: none;
    }
    /**The second child div of the rowgroup has overflow set as inline-style, which can be overwriten only with important. after fixing this in TUT, this should be removed**/
    div[role='rowgroup'] > div > div {
        overflow-x: hidden !important;
    }
`;
