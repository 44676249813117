import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100002;

    h1 {
        color: ${({ theme }) => theme.white.ff};
    }
`;
