import React from 'react';
import { connect } from 'react-redux';
import { getComputedTopFiltersGetCountry } from '../TopFilter/data/computedSelectors';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createClassName } from '../../utils/utils';

const SectionWrapper = styled.div`
    margin: 0.5rem 0;
    position: ${props => (props.isResponsive ? 'relative' : '')};
`;
const SectionHeader = styled.h3`
    background-color: ${({ theme }) => theme.green[100]};
    padding: 0.5rem 40px;
    font-size: 16px;
    color: ${({ theme }) => theme.green[700]};
    display: flex;
    justify-content: space-between;

    ${({ customStyle }) => customStyle}
`;

const SectionCountry = styled.span``;

const SectionContent = styled.div`
    margin: 2rem 0;
    padding: 0 40px;
    @media (max-width: 1500px) {
        margin: ${props => (props.isResponsive ? '2rem 0 4rem' : '')};
    }
`;

export const SectionsHead = ({ title, children, country, isResponsive, customStyle }) => {
    return (
        <SectionWrapper data-testid={`SectionsHead-Wrapper-${createClassName(title)}`} isResponsive={isResponsive}>
            <SectionHeader customStyle={customStyle}>
                {title}
                <SectionCountry>{country?.toUpperCase()}</SectionCountry>
            </SectionHeader>
            <SectionContent isResponsive={isResponsive}>{children}</SectionContent>
        </SectionWrapper>
    );
};

SectionsHead.propTypes = {
    title: PropTypes.string.isRequired,
    country: PropTypes.string,
    children: PropTypes.node,
    isResponsive: PropTypes.bool,
    customStyle: PropTypes.object,
};

SectionsHead.defaultProps = {
    isResponsive: true,
};

const mapStateToProps = state => ({
    country: getComputedTopFiltersGetCountry(state),
});

export default connect(mapStateToProps, {})(SectionsHead);
