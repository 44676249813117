
import styled from '@emotion/styled';


export const ScrollbarWrapper = styled.div`
    ${props=>`
        position:relative;
        ${props.height ? `
          height: ${props.height}px;
        ` : ( props.maxHeight ? `` : `
          height: 100%;
        `)}
        ${props.maxHeight && props.maxHeight !== '100%' ? `
          max-height: ${props.maxHeight}px;
        ` : `
          max-height:100%;
        `}
        ${props.width ? `
            width: ${props.width}px;
        ` : ``}
    `}
    ${props=>props.wrapperStyles ? {
      ...props.wrapperStyles
    } : ``}
`;

export const scrollBarStyles = (height, maxHeight) => ({
    width: '100%', 
    height: '100%',
    maxHeight : height || maxHeight || 'initial',
});

export const shadowTopStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '10px',
  opacity: 0,
  background: 'linear-gradient(rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0) 100%)'
}

export const shadowBottomStyles = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '10px',
  opacity: 0,
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0) 100%)'
}
