import React, { useCallback, useMemo } from 'react';
import {
    CampaignDiagnosticCardWrapper,
    CampaignDiagnosticWrapper,
    CampaignDiagnosticColumn,
    StackedBarChartLegend,
    StackedChartsWrapper,
    BarChartWrapper,
    LegendItem,
    LegendItemColor,
    LowBaseLegend,
    CampaignDiagnosticBarName,
    CampaignDiagnosticStackLabels,
    CampaignDiagnosticIcon,
    BaseLowColumn,
} from '../../AdDetailsMetrics.styles';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION, StackedBarChart } from '@toluna-ui-toolkit/barchart';
import TopBarsCard from './TopBarsCard';
import { BAR_CATEGORY, BAR_TYPES, RENDER_LOW_BASE_LEGEND } from '../../utils/constants';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { basePath } from '../../../../../../services/utils';
import { BAR_CHARTS_RESPONSIVE_WIDTHS, MEDIA_QUERY_SCREEN_SIZE } from '../../../../../../utils/responsiveness';
import { useStackedBarsSpacing } from '../../../../../../common/hooks/useStackedBarsSpacing';
import { checkAndConvertToNAValue, TOP_2_TYPE } from '../../../../utils/utils';
const isEmphasizedItem = itemType => [BAR_CATEGORY.MAIN, BAR_CATEGORY.FILTERED].includes(itemType);
const HORIZONTAL_BARS_WIDTH = 18;
const CHART_HEIGHT_MULTIPLIER = 55;

const CampaignDiagnostic = ({ kpis }) => {
    const [width] = useWindowSize();
    const [spacing, chartRef] = useStackedBarsSpacing(kpis);

    const BAR_CHARTS_WIDTH =
        width <= MEDIA_QUERY_SCREEN_SIZE.MEDIUM
            ? BAR_CHARTS_RESPONSIVE_WIDTHS.SMALL
            : BAR_CHARTS_RESPONSIVE_WIDTHS.MEDIUM;
    const isAnyLowBaseSize = useMemo(() => {
        return (
            kpis &&
            kpis.some(kpi => {
                if (kpi.kpiType === BAR_TYPES.SIMPLE) {
                    return kpi.items.some(item => item.isLowBase);
                }
                return false;
            })
        );
    }, [kpis]);

    const renderChartByType = useCallback(
        (kpiType, items) => {
            switch (kpiType) {
                case BAR_TYPES.SIMPLE: {
                    const data = items.map(e => ({
                        name: e.itemLabel,
                        value: e.values[0].value,
                        color: e.itemType === 'filtered' ? 'url(#filtered-lines-pattern)' : e.values[0].color,
                        textColor: isEmphasizedItem(e.itemType) ? defaultTheme.blue[600] : defaultTheme.blue[950],
                        isLowBase: e.isLowBase,
                    }));
                    data.reverse();
                    return (
                        <BarChartWrapper data-testid={'CampaignDiagnosticTester-SimpleBarWrapper'}>
                            <BarChart
                                width={BAR_CHARTS_WIDTH - 5}
                                height={CHART_HEIGHT_MULTIPLIER * items.length}
                                marginWidth={30}
                                domain={100}
                                direction={BARCHART_DIRECTION.HORIZONTAL}
                                labelFormatter={value => checkAndConvertToNAValue(value, '%')}
                                labelPosition={BARCHART_LABEL_POSITION.END_OF_DOMAIN}
                                styling={{
                                    barWidth: HORIZONTAL_BARS_WIDTH,
                                    backgroundColor: defaultTheme.blue[50],
                                    barStroke: 1,
                                    barStrokeColor: defaultTheme.blue[600],
                                    barLabelMainAxisOffset: 28,
                                    barLabelSecondaryAxisOffset: -2,
                                    fontWeight: 600,
                                }}
                                withNames={false}
                                alwaysOnTooltips={false}
                                data={data}
                            />
                            {
                                <BaseLowColumn height={CHART_HEIGHT_MULTIPLIER * items.length - 30}>
                                    {items.map((d, id) => {
                                        return (
                                            <div>
                                                {d.isLowBase && (
                                                    <img
                                                        alt="low base legend"
                                                        className="innerLegendWarningImage"
                                                        src={`${basePath}/visuals/lowBaseWarning.png`}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </BaseLowColumn>
                            }
                        </BarChartWrapper>
                    );
                }
                case BAR_TYPES.STACKED: {
                    let stackLabels = [];
                    const colors = items[0].values.map(v => v.color);
                    const data = items
                        .map((item, index) => {
                            const dataObject = {
                                name: index,
                            };

                            item.values.forEach(valueObject => {
                                const { tooltip, value, type } = valueObject;
                                if (type !== TOP_2_TYPE) {
                                    dataObject[tooltip] = value;
                                } else if (type === TOP_2_TYPE) {
                                    /* Top 2 represents the item shown in the Top Card */
                                    stackLabels.push(value);
                                }
                            });

                            return dataObject;
                        })
                        .reverse();
                    const stackKeys = Object.keys(data[0]).filter(k => k !== 'name');

                    return (
                        <div>
                            <StackedChartsWrapper
                                ref={chartRef}
                                data-testid={'CampaignDiagnosticTester-StackedBarWrapper'}
                            >
                                <StackedBarChart
                                    data={data}
                                    stackColors={colors}
                                    stackKeys={stackKeys}
                                    translateX={-10}
                                    width={BAR_CHARTS_WIDTH + 25}
                                    height={CHART_HEIGHT_MULTIPLIER * items.length}
                                    direction={BARCHART_DIRECTION.HORIZONTAL}
                                    labelFormatter={e => e + '%'}
                                    labelPosition={BARCHART_LABEL_POSITION.MIDDLE}
                                    tooltipFormatter={e => e + '%'}
                                    marginHeight={10}
                                    styling={{
                                        barWidth: HORIZONTAL_BARS_WIDTH,
                                        barLabelSecondaryAxisOffset: -2,
                                        tooltipOffsetX: -14,
                                        tooltipOffsetY: -10,
                                        tooltipWidth: 24,
                                        tooltipHeight: 20,
                                        tooltipStroke: 1,
                                        tooltipStrokeColor: defaultTheme.blue['950-b3'],
                                        tooltipFontColor: defaultTheme.blue['600'],
                                    }}
                                />
                                <CampaignDiagnosticStackLabels heightOfLabels={spacing}>
                                    {stackLabels.map((e, i) => (
                                        <li key={i}>{checkAndConvertToNAValue(e, '%')}</li>
                                    ))}
                                </CampaignDiagnosticStackLabels>
                            </StackedChartsWrapper>

                            <StackedBarChartLegend data-testid={'CampaignDiagnosticTester-StackedBarLegend'}>
                                {stackKeys.map((key, index) => (
                                    <LegendItem>
                                        <LegendItemColor style={{ background: colors[index] }} />
                                        {key}
                                    </LegendItem>
                                ))}
                            </StackedBarChartLegend>
                        </div>
                    );
                }
            }
        },
        [kpis, spacing, BAR_CHARTS_WIDTH]
    );
    const renderLowBaseLegend = kpiID => {
        if (kpiID !== RENDER_LOW_BASE_LEGEND.SECTION_ID) return;
        return (
            <LowBaseLegend>
                <span>
                    <img
                        alt="low base legend"
                        className="innerLegendWarningImage"
                        src={`${basePath}/visuals/lowBaseWarning.png`}
                    />
                    {TRANSLATION_TEXT.SAMPLE_SIZE_LOW}
                </span>
            </LowBaseLegend>
        );
    };
    return (
        <CampaignDiagnosticWrapper>
            <CampaignDiagnosticColumn className="diagnosticsLabels">
                {kpis && kpis.map(kpi => <> {isAnyLowBaseSize ? renderLowBaseLegend(kpi.kpiID) : <LowBaseLegend />}</>)}
                <CampaignDiagnosticCardWrapper style={{ boxShadow: 'none' }} />
                <div style={{ marginTop: '25px', alignSelf: 'flex-start', width: '100%' }}>
                    {kpis &&
                        kpis[0].items.map(e => (
                            <CampaignDiagnosticBarName
                                isLowBaseSize={e.isLowBase}
                                isAnyLowBaseSize={isAnyLowBaseSize}
                                isMain={isEmphasizedItem(e.itemType)}
                            >
                                <span className="itemLabel">{e.itemLabel}</span>
                            </CampaignDiagnosticBarName>
                        ))}
                </div>
            </CampaignDiagnosticColumn>

            {kpis &&
                kpis.map(kpi => (
                    <CampaignDiagnosticColumn>
                        <CampaignDiagnosticIcon>
                            <img alt="Image of KPI" src={kpi.kpiImageLink} />
                        </CampaignDiagnosticIcon>
                        <TopBarsCard
                            kpiType={kpi.kpiType}
                            items={kpi.items}
                            title={kpi.kpiLabel}
                            label={kpi.kpiDescription}
                        />
                        <div style={{ marginTop: '40px', alignSelf: 'flex-start' }}>
                            {renderChartByType(kpi.kpiType, kpi.items)}
                        </div>
                    </CampaignDiagnosticColumn>
                ))}
        </CampaignDiagnosticWrapper>
    );
};

export default CampaignDiagnostic;
