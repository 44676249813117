export const INIT_SIDE_FILTERS = '/common/components/SideFilters/INIT_SIDE_FILTERS';
export const UPDATE_SIDE_FILTERS = '/common/components/SideFilters/UPDATE_SIDE_FILTERS';
export const STORE_UPDATE_SIDE_FILTERS = '/common/components/SideFilters/STORE_UPDATE_SIDE_FILTERS';
export const INIT_SELECTED_SIDE_FILTERS = '/common/components/SideFilters/INIT_SELECTED_SIDE_FILTERS';
export const INIT_APPLIED_SIDE_FILTERS = '/common/components/SideFilters/INIT_APPLIED_SIDE_FILTERS';
export const SET_SIDE_FILTER_PAGE = '/common/components/SideFilters/SET_SIDE_FILTER_PAGE';

export const APPLY_SIDE_FILTERS = '/common/components/SideFilters/APPLY_SIDE_FILTERS';
export const STORE_APPLY_FILTERS = '/common/components/SideFilters/STORE_APPLY_FILTERS';
export const CLEAR_FILTERS = '/common/components/SideFilters/CLEAR_FILTERS';
export const STORE_CLEAR_FILTERS = '/common/components/SideFilters/STORE_CLEAR_FILTERS';
export const INIT_SIDE_FILTERS_SECTION = '/common/components/SideFilters/INIT_SIDE_FILTERS_SECTION';
export const TOGGLE_SIDE_FILTERS_SECTION = '/common/components/SideFilters/TOGGLE_SIDE_FILTERS_SECTION';
export const STORE_TOGGLE_SIDE_FILTERS_SECTION = '/common/components/SideFilters/STORE_TOGGLE_SIDE_FILTERS_SECTION';

export const initSideFilters = payload => ({
    type: INIT_SIDE_FILTERS,
    payload,
});

export const updateSideFiltersActionCreator = payload => ({
    type: UPDATE_SIDE_FILTERS,
    payload,
});
export const applySideFilters = () => ({
    type: APPLY_SIDE_FILTERS,
});
export const storeUpdateSideFiltersActionCreator = payload => ({
    type: STORE_UPDATE_SIDE_FILTERS,
    payload,
});

export const initSelectedSideFiltersActionCreator = payload => ({
    type: INIT_SELECTED_SIDE_FILTERS,
    payload,
});
export const initAppliedSideFiltersActionCreator = payload => ({
    type: INIT_APPLIED_SIDE_FILTERS,
    payload,
});

export const setSideFiltersPageActionCreator = pageName => ({
    type: SET_SIDE_FILTER_PAGE,
    payload: pageName,
});

export const storeApplyFiltersActionCreator = appliedFilters => ({
    type: STORE_APPLY_FILTERS,
    payload: appliedFilters,
});

export const clearFiltersActionCreator = () => ({ type: CLEAR_FILTERS });
export const storeClearFiltersActionCreator = restoredFilters => ({
    type: STORE_CLEAR_FILTERS,
    payload: restoredFilters,
});

export const initSideFiltersSectionActionCreator = payload => ({
    type: INIT_SIDE_FILTERS_SECTION,
    payload,
});
export const toggleSideFiltersSectionActionCreator = payload => ({
    type: TOGGLE_SIDE_FILTERS_SECTION,
    payload,
});
export const storeToggleSideFiltersSectionActionCreator = payload => ({
    type: STORE_TOGGLE_SIDE_FILTERS_SECTION,
    payload,
});
