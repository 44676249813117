import { createSelector } from 'reselect';
import { getBptPreferenceAnalysisValueForMoneySelector } from '../../../data/selectors';

export const getValueForMoneyHorizontalChartsComputedSelector = createSelector(
    [getBptPreferenceAnalysisValueForMoneySelector],
    data => {
        if (data && data.hasOwnProperty('sections') && data.sections && data.sections.length > 0) {
            const kpiSections = data.sections.find(e => e.sectionType === 'KPI');
            if (!kpiSections) return [];

            const horizontalBarChartsData = kpiSections.kpis.filter(e => e.kpiType === 'StackedHorizontalBarChart');
            if (horizontalBarChartsData.length === 0) return [];

            let computedData = [];
            horizontalBarChartsData.forEach(element => {
                const { items, kpiDescription, nrRespondents } = element;
                const dataObject = {
                    name: kpiDescription,
                    respondents: nrRespondents,
                    chart: [],
                    colors: [],
                };

                items.forEach(item => {
                    dataObject.colors.push({ color: item.values[0].color, name: item.itemLabel });
                });

                items.forEach(item => {
                    const { values, itemLabel } = item;
                    values.forEach(value => {
                        dataObject.chart[value.order - 1] = {
                            ...dataObject.chart[value.order - 1],
                            group: value.label,
                            [itemLabel.replace(/\s/g, '')]: [
                                value.value,
                                value.evolutionMetricValue !== undefined ? value.evolutionMetricValue : null,
                            ],
                        };
                    });
                });
                computedData.push(dataObject);
            });
            return computedData;
        }
    }
);

export const getCombinedStackedBarChartDataSelector = createSelector(
    [getBptPreferenceAnalysisValueForMoneySelector],
    valueForMoneyData => {
        if (
            valueForMoneyData &&
            valueForMoneyData.hasOwnProperty('sections') &&
            valueForMoneyData.sections &&
            valueForMoneyData.sections.length > 0
        ) {
            const kpiSections = valueForMoneyData.sections.find(e => e.sectionType === 'KPI');
            if (!kpiSections) return [];

            const horizontalBarChartsData = kpiSections.kpis.filter(e => e.kpiType === 'StackedVerticalBarChart');
            if (horizontalBarChartsData.length === 0) return [];

            let computedData = [];
            horizontalBarChartsData.forEach(element => {
                const { items, kpiDescription } = element;
                const dataObject = {
                    title: kpiDescription,
                    chart: [],
                    colors: [],
                    legend: [],
                    numberOfRespondents: [],
                };

                dataObject.colors = items[0].values.map(item => ({
                    color: item.color,
                    label: item.label.replace(/\s/g, ''),
                }));

                dataObject.legend = items[0].values.map(item => ({
                    color: item.color,
                    label: item.label,
                }));
                items.forEach(item => {
                    const { values, itemLabel } = item;
                    const tempObj = { name: itemLabel, sigtest: {} };
                    dataObject.numberOfRespondents.push({ itemLabel, value: values[0].nrRespondents });
                    values.forEach(val => {
                        tempObj[val.label.replace(/\s/g, '')] = val.value;
                        tempObj.evolutionMetric = {
                            ...tempObj.evolutionMetric,
                            [[val.label.replace(/\s/g, '')]]:
                                val.evolutionMetricValue !== undefined ? val.evolutionMetricValue : null,
                        };
                        tempObj.sigtest = { ...tempObj.sigtest, [val.label.replace(/\s/g, '')]: val.sigtest };
                    });

                    dataObject.chart.push(tempObj);
                });

                computedData.push(dataObject);
            });

            let finalData = [];
            const chunkedData = {};
            if (!computedData.length) return null;
            for (const section of computedData) {
                const chunkId = section.title;
                if (!chunkedData.hasOwnProperty(chunkId)) {
                    chunkedData[chunkId] = [section];
                } else {
                    chunkedData[chunkId].push(section);
                }
            }

            Object.keys(chunkedData).forEach(key => {
                finalData.push(chunkedData[key]);
            });

            return finalData;
        }
    }
);
