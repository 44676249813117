import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationSelector } from '../components/TopFilter/data/selectors';
import { getParentPathOnPagesWithSubTabs } from '../components/TopFilter/utils/utils';
import { getFiltersActionCreator, initFiltersForSubTabs } from '../data/actions';
import { FILTERS_CONFIG } from '../utils/utils';

export function useFilters() {
    const initialLoadDone = useRef(false);

    const dispatch = useDispatch();
    const currentPath = useSelector(getLocationSelector);

    const locationAsKey = getParentPathOnPagesWithSubTabs(currentPath);

    useEffect(() => {
        /* 
            In some cases, when we have sub-tabs, filters are stored at the main page level
            so for filters to do their work to initialize properly between tabs 
            ( like select the last applied option if we only select one and to not apply it ),
            we will dispatch an action for those kind of pages 
        */
        if (FILTERS_CONFIG[locationAsKey]?.hasSubTabs && initialLoadDone.current) {
            dispatch(initFiltersForSubTabs());
        }
    }, [currentPath]);

    useEffect(() => {
        let requestFiltersParameters = FILTERS_CONFIG[locationAsKey];
        if (requestFiltersParameters) {
            dispatch(getFiltersActionCreator(requestFiltersParameters));
        }
        initialLoadDone.current = true;
    }, []);
}
