import { requestWithPayload, request, createAxiosInstance } from './utils';
import * as routes from '../utils/routes';
import axios from 'axios';

export const axiosInstance = createAxiosInstance(true);

const loadAdTesterOverviewData = () => axiosInstance.get(`AdTester/Overview`);
export const getAdTesterOverviewData = () => {
    return request(loadAdTesterOverviewData);
};

const fetchFiltersData = payload => {
    return axiosInstance.post(`Filter/Filters`, payload);
};
export const getFiltersData = payload => requestWithPayload(fetchFiltersData, payload);

const fetchAdDetailsMetricsData = data => axiosInstance.post(`AdTester/AdTesterDetails`, data);
export const getAdDetailsMetricsData = data => {
    return requestWithPayload(fetchAdDetailsMetricsData, data);
};

const fetchAdComparisonData = data => axiosInstance.post(`AdTester/AdTesterComparison`, data);
export const getAdComparisonData = data => {
    return requestWithPayload(fetchAdComparisonData, data);
};

const loadSurveyFieldWorkOverviewData = () => axiosInstance.get(`SurveyFieldwork/Overview`);
export const getSurveyFieldWorkOverviewData = () => {
    return request(loadSurveyFieldWorkOverviewData);
};

const fetchCompetitiveMediaData = data => axiosInstance.post(`/CompetitiveMedia/GetCompetitiveMedia`, data);
export const getCompetitiveMediaData = data => {
    return requestWithPayload(fetchCompetitiveMediaData, data);
};
const fetchCompetitiveMediaExport = payload => axiosInstance.post(`Export/AdCompetitiveMediaExport`, payload);
const fetchTransversalViewExport = payload => axiosInstance.post(`Export/TransversalViewExport`, payload); //TrsnsversalView

const fetchAdTesterOverviewExport = payload => axiosInstance.post(`Export/AdTesterOverviewExport`, payload);
const fetchAdTesterDetailsExport = payload => axiosInstance.post(`Export/AdTesterDetailsExport`, payload);
const fetchAdTesterAdComparisonExcelExport = payload =>
    axiosInstance.post(`Export/AdTesterAdsComparisonExport`, payload);
const fetchSurveyFieldworkOverviewExport = payload =>
    axiosInstance.post(`Export/SurveyFieldworkOverviewExport`, payload);
const fetchBPTPreferenceAnalysisExport = payload =>
    axiosInstance.post(`Export/BPTPreferenceAnalysisPptExport`, payload);
const fetchUserManagementActionLogsExport = () => axiosInstance.get('Export/ActionLogs');
export const getExportDataUrl = requestData => {
    const { currentPath, payload } = requestData;
    switch (currentPath.toLowerCase()) {
        case routes.USER_MANAGEMENT_PAGE.path.toLowerCase():
            return request(fetchUserManagementActionLogsExport);
        case routes.ADTESTER_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path.toLowerCase():
            return requestWithPayload(fetchAdTesterOverviewExport, payload);
        case routes.ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path.toLowerCase():
            return requestWithPayload(fetchAdTesterDetailsExport, payload);
        case routes.ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path.toLowerCase():
            return requestWithPayload(fetchAdTesterAdComparisonExcelExport, payload);
        case routes.SURVEY_FIELDWORK_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path.toLowerCase():
            return requestWithPayload(fetchSurveyFieldworkOverviewExport, payload);
        case routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path.toLowerCase():
            return requestWithPayload(fetchBPTPreferenceAnalysisExport, payload);
        case routes.COMPETITIVE_MEDIA.path.toLowerCase():
            return requestWithPayload(fetchCompetitiveMediaExport, payload);
        case routes.TRANSVERSAL_VIEW_PAGE.path.toLowerCase():
            return requestWithPayload(fetchTransversalViewExport, payload); //EXport Transversal
        default:
            break;
    }
};

export const fetchPreferenceAnalysisAwarenessData = payload =>
    axiosInstance.post('/bpt/PreferenceAnalysis/Awareness', payload);
export const fetchPreferenceAnalysisConsiderationData = payload =>
    axiosInstance.post('/bpt/PreferenceAnalysis/Consideration', payload);
export const fetchPreferenceAnalysisImageData = payload => axiosInstance.post('/bpt/PreferenceAnalysis/Image', payload);
export const fetchPreferenceAnalysisAdRecallData = payload =>
    axiosInstance.post('/bpt/PreferenceAnalysis/AdRecall', payload);
export const fetchPreferenceAnalysisValueForMoneyData = payload =>
    axiosInstance.post('/bpt/PreferenceAnalysis/ValueForMoney', payload);

export const fetchTransversalView = payload => axiosInstance.post('/TransversalView/GetTransversalView', payload);
export const getTransversalViewData = payload => {
    return requestWithPayload(fetchTransversalView, payload);
};

export const getBPTPreferenceAnalysisDataUrl = (currentPath, payload) => {
    switch (currentPath.toLowerCase()) {
        case routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path.toLowerCase():
            return requestWithPayload(fetchPreferenceAnalysisAwarenessData, payload);
        case routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path.toLowerCase():
            return requestWithPayload(fetchPreferenceAnalysisConsiderationData, payload);
        case routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path.toLowerCase():
            return requestWithPayload(fetchPreferenceAnalysisImageData, payload);
        case routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path.toLowerCase():
            return requestWithPayload(fetchPreferenceAnalysisAdRecallData, payload);
        case routes.PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.VALUE_FOR_MONEY.path.toLowerCase():
            return requestWithPayload(fetchPreferenceAnalysisValueForMoneyData, payload);
        default:
            break;
    }
};

//*** Backoffice APIs ***//
//* File Uploads *//
export const handleFileUploadToAWS = ({ url, file, type }) => {
    return axios.put(url, file, { headers: { 'Content-Type': type } });
};

export const getAWSUploadUrl = ({ fileName, importTypeId }) => {
    return axiosInstance.post(`BackOffice/GetPreSignedURL`, { fileName: fileName, importTypeId });
};

export const logFileEntryMetaData = fileName => {
    return axiosInstance.post(`BackOffice/LogFileEntryMetadata`, { fileName: fileName });
};
export const logFileEntryData = fileName => {
    return axiosInstance.post(`BackOffice/LogFileEntryData`, { fileName });
};
export const logFileEntryMedia = fileName => {
    return axiosInstance.post(`BackOffice/LogFileEntryMedia`, { fileName });
};

const toggleConfirmitData = data =>
    axiosInstance.post(`BackOffice/TogglePID`, { filename: data.filename, pid: data.pid });
export const toggleConfirmitDataRequest = data => requestWithPayload(toggleConfirmitData, data);

//* Table Requests *//

const loadMetadataFileEntries = () => axiosInstance.get(`BackOffice/MetadataFileEntries`);
const loadDataFileEntries = () => axiosInstance.get(`BackOffice/DataFileEntries`);
const loadConfirmitPidEntries = () => axiosInstance.get(`BackOffice/ConfirmitPidEntries`);
const loadCachingStatus = () => axiosInstance.get(`BackOffice/CachingStatus`);

export const getMetadataFileEntries = () => request(loadMetadataFileEntries);
export const getDataFileEntries = () => request(loadDataFileEntries);
export const getConfirmitPidEntries = () => request(loadConfirmitPidEntries);
export const getCachingStatus = () => request(loadCachingStatus);

//* Logging *//
const loggingRequest = navigationParams => {
    return axiosInstance.post(`ActionLog/LogPageVisit`, navigationParams);
};

export const logNavigationRequest = navigationParams => {
    return requestWithPayload(loggingRequest, navigationParams);
};

/* Request for toolbox protected URLs */
const loadToolboxSecuredUrlRequest = id => {
    return axiosInstance.get(`Homepage/GetHomepageUrl?fileid=${id}`);
};

export const getToolBoxSecuredUrlRequest = id => {
    return requestWithPayload(loadToolboxSecuredUrlRequest, id);
};
