import { connect } from 'react-redux';
import { logoutActionCreator } from '../../../login/data/actions';
import UserDropdownPanel from './UserDropdownPanel';
import { getUserDetailsSelector } from '../../../login/data/selectors';

const mapStateToProps = state => ({
    userDetails: getUserDetailsSelector(state),
});

const mapDispatchToProps = {
    logoutActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdownPanel);
