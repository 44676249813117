import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './ExportButtonContainer.style';

const ExportButtonContainer = ({ text, children, customStyle, isUserManagement }) => {
    return isUserManagement ? (
        <SC.ExportButtonUserManagementWrapper customStyle={customStyle?._wrapper}>
            {children}
            <span>{text}</span>
        </SC.ExportButtonUserManagementWrapper>
    ) : (
        <SC.ExportButtonContainerWrapper customStyle={customStyle?._wrapper}>
            <span>{text}</span>
            <SC.ExportIconsWrapper>{children}</SC.ExportIconsWrapper>
        </SC.ExportButtonContainerWrapper>
    );
};

ExportButtonContainer.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    customStyle: PropTypes.object,
    isUserManagement: PropTypes.bool,
};

export default ExportButtonContainer;
