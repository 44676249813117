import React, { useState } from 'react';
import {
    ErrorLabel,
    Input,
    InputWrapper,
    Title,
    Subtitle,
    Form,
    SubmitButton,
    SecondaryAction,
} from '../../login.styles';
import { validateEmail } from '../../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../../utils/translations';

export const ForgotPassword = ({ goToLogin, forgotPasswordActionCreator }) => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const submitHandler = e => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setIsEmailValid(false);
            return;
        }
        setIsEmailValid(true);
        forgotPasswordActionCreator(email);
        setEmail('');
    };

    return (
        <>
            <Title>{TRANSLATION_TEXT.FORGOT_PASSWORD_TEXT}</Title>
            <Subtitle>{TRANSLATION_TEXT.FORGOT_PASSWORD_MESSAGE_TEXT}</Subtitle>
            <Form onSubmit={submitHandler}>
                <InputWrapper>
                    <Input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        placeholder="Email"
                        name="email"
                        withError={!isEmailValid}
                        onFocus={() => setIsEmailValid(true)}
                    />
                    <ErrorLabel data-testid="ForgotPassword-ErrorLabel" validation={isEmailValid} htmlFor="email">
                        <p>{TRANSLATION_TEXT.FORGOT_PASSWORD_INVALID_EMAIL_TEXT}</p>
                    </ErrorLabel>
                </InputWrapper>
                <SubmitButton>{TRANSLATION_TEXT.FORGOT_PASSWORD_SUBMIT_TEXT}</SubmitButton>
            </Form>
            <SecondaryAction onClick={goToLogin}>{TRANSLATION_TEXT.FORGOT_PASSWORD_BACK_LOGIN_TEXT}</SecondaryAction>
        </>
    );
};

export default ForgotPassword;
