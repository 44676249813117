import React from 'react';
import { default as CheckBox } from '../../../../../common/components/Checkbox/Checkbox';
import { basePath } from '../../../../../services/utils';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../../../utils/translations';

const CustomCheckbox = ({ onChange, checked, isHeader }) => {
    const onChangeCallback = e => {
        onChange({
            target: { checked: !checked },
        });
    };

    return (
        <>
            <CheckBox
                value={checked}
                label={''}
                isSelectedBold={checked}
                onClick={onChangeCallback}
                textColor={defaultTheme.blue[950]}
            />
            {isHeader && TRANSLATION_TEXT.SELECT_ALL_TEXT}
        </>
    );
};

export const tableConfig = {
    columnsSpecifications: [
        {
            Header: 'First Name',
            accessor: 'FirstName',
        },
        {
            Header: 'Last Name',
            accessor: 'LastName',
        },
        {
            Header: 'E-mail',
            accessor: 'Email',
        },
        {
            Header: 'Country',
            accessor: 'Country',
        },
        {
            Header: 'Dashboards',
            accessor: 'Dashboards',
        },
        {
            Header: 'Last Login',
            accessor: 'LastLogin',
        },
        {
            Header: 'Number of logins',
            accessor: 'NumberOfLogins',
        },
    ],
    body: {
        dateColumns: ['LastLogin'],
        dateFormat: 'DD-MM-YYYY',
    },
    icons: {
        sortAsc: <img style={{ marginRight: '4px' }} src={`${basePath}/visuals/Sort_on_up.png`} />,
        sortDesc: <img style={{ marginRight: '4px' }} src={`${basePath}/visuals/Sort_on_down.png`} />,
        nonSorted: <img style={{ marginRight: '4px' }} src={`${basePath}/visuals/Sort_off.png`} />,
    },
    checkbox: CustomCheckbox,
};
