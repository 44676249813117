import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { basePath } from '../../../../services/utils';

const Button = styled.div`
    width: 100%;
    height: 24px;
    display: flex;
    text-align: left;
    color: ${({ theme }) => theme.blue[950]};
    margin-left: 0px;
    margin-bottom: ${props => (props.showDropDownMenu ? '10px' : '0px')};
    user-select: none;
`;

const Icon = styled.img`
    position: absolute;
    right: 25px;
    margin-top: ${props => (props.showDropDownMenu ? '7px' : '10px')};
    width: 9px;
`;

const DropDownButton = ({ onClick, btnText, showDropDownMenu }) => {
    return (
        <>
            <Button onClick={onClick} showDropDownMenu={showDropDownMenu}>
                {btnText}
                <Icon
                    showDropDownMenu={showDropDownMenu}
                    src={
                        showDropDownMenu
                            ? `${basePath}/visuals/Icon_contry_up.png`
                            : `${basePath}/visuals/Icon_contry_down.png`
                    }
                />
            </Button>
        </>
    );
};
DropDownButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired,
    showDropDownMenu: PropTypes.bool.isRequired,
};
export default DropDownButton;
