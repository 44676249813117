import { createSelector } from 'reselect';
import {
    getAppliedSideFiltersSelector,
    getSideFiltersSelector,
    getCurrentFilterPageSelector,
} from '../../SideFilters/data/selectors';
import { INPUT_TYPES } from '../../SideFilters/utils/sideFilters.config';

export const getSelectedFiltersComputedSelector = createSelector(
    [getSideFiltersSelector, getAppliedSideFiltersSelector, getCurrentFilterPageSelector],
    (sideFilters, appliedSideFilters, currentPage) => {
        const selectedFiltersData = [];
        for (const groupName in appliedSideFilters[currentPage]) {
            if (Object.hasOwnProperty.call(appliedSideFilters[currentPage], groupName)) {
                const groupFilters = appliedSideFilters[currentPage][groupName];

                // iterate through each selected filter and generate the applied filters object
                for (const filterId in groupFilters) {
                    if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                        const filter = groupFilters[filterId];

                        if (filter.length > 0) {
                            const sideFilterData = sideFilters[groupName]?.[filterId];

                            if (sideFilterData) {
                                if (sideFilterData.selectType === INPUT_TYPES.SLIDER) {
                                    const defaultMin = +sideFilterData.options[0].label;
                                    const defaultMax = +sideFilterData.options[1].label;
                                    if (defaultMin !== filter[0] || defaultMax !== filter[1]) {
                                        selectedFiltersData.push({
                                            pageName: currentPage,
                                            groupName,
                                            filterId: +filterId,
                                            filterType: INPUT_TYPES.SLIDER,
                                            type: sideFilterData.type,
                                            filterText: `${filter[0]}-${filter[1]}`,
                                            min: defaultMin,
                                            max: defaultMax,
                                        });
                                    }
                                } else {
                                    filter.forEach(id => {
                                        const optionData = sideFilterData.options.find(el => el.id === id);
                                        selectedFiltersData.push({
                                            pageName: currentPage,
                                            groupName,
                                            filterId: +filterId,
                                            selectedId: id,
                                            filterType: INPUT_TYPES.MULTI_CHECKBOX,
                                            type: sideFilterData.type,
                                            filterText: `${optionData.label}`,
                                        });
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        // Data for the selected top filter to be added here to the same array

        return selectedFiltersData;
    }
);
