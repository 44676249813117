export const CLEAR_ALL_SELECTED_FILTERS = '/common/components/SelectedFilters/CLEAR_ALL_SELECTED_FILTERS';
export const CLEAR_SINGLE_SELECTED_FILTER = '/common/components/SelectedFilters/CLEAR_SINGLE_SELECTED_FILTER';

export const clearAllSelectedFiltersActionCreator = () => ({
    type: CLEAR_ALL_SELECTED_FILTERS
})

export const clearSingleSelectedFilterActionCreator = (filterData) => ({
    type: CLEAR_SINGLE_SELECTED_FILTER,
    payload: filterData
})
