import styled, { css } from 'styled-components';
import React from 'react';
import { defaultTheme } from '../../utils/defaultTheme';
import { basePath } from '../../services/utils';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../utils/responsiveness';

export const BackOfficePageWrapper = styled.div`
    margin: 0 auto;
    padding: 32px 30px;
    width: 100%;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    opacity: 1;
    position: relative;
    box-sizing: border-box;
    background: ${({ theme }) => theme.white.ff};

    div[role='columnheader'] {
        padding: 5px;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        min-width: auto;
        width: auto;
        max-width: 100%;
    }
`;

export const BackOfficeTitle = styled.div`
    margin: 30px 0px 0px 0px;
    font-size: 20px;
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 600;
`;

export const BackOfficeSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > div {
        width: 47%;
    }
    @media screen and (max-width: 1250px) {
        & > div {
            width: 49%;
        }
    }
`;

export const UploadWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
export const BackOfficeTableWrapper = styled.div`
    ${({ hasDisabledColumns }) =>
        hasDisabledColumns &&
        css`
            div[role='row']:first-of-type div:nth-child(n + 3) {
                //Disable only 'User' And 'Errors' column's span that has an image
                //And avoid hiding other spans that are bellow the user and columns arrows.
                span:not(.highlight_wrapper, .highlight_span) {
                    margin-left: -6px;
                    visibility: hidden;
                }
            }
        `}
    //Centering the data in the cells and the table head
    div[role='cell'] {
        min-width: 50px;
        overflow: visible;
        word-break: break-all;
        padding: 10px 5px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    div[role='cell']:nth-child(1) {
        div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    div[role='cell']:nth-child(5) {
        word-break: break-word;
    }
    div[role='row'] {
        text-align: center;
    }
    div[role='rowgroup'] {
        height: 450px;
        overflow-y: scroll;
    }
    width: 100%;

    @media screen and (max-width: 1492px) {
        div[role='row'] > div:nth-of-type(2) {
            //Selecting 'Timestamp' and fixing it not having the sorting arrow above
            width: 50px !important;
            flex: 50 0 auto !important;
        }
    }
    div[role='table'] {
        border: none;
        box-shadow: none;
    }

    div[role='columnheader'] {
        font-size: 15px;
        &:last-of-type {
            margin-right: 20px;
        }
    }

    position: relative;
    //Table Search
    .table-search {
        top: -67px;
        position: absolute;
        width: 40%;
        height: 50px;
        border: solid 6px ${({ theme }) => theme.white.ff};
        box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
        border-radius: 2px;
        min-width: 210px;
        & input,
        div {
            height: 35px;
            padding-left: 10px;
            font-weight: 600;
        }
    }
`;
const highlightSkeleton = css`
    strong {
        padding: 1px;
        border-radius: 3px;
        font-weight: normal;
        background-color: ${({ theme }) => theme.blue['100']};
    }
`;

export const HighlightWrapper = styled.div`
    ${({ isRequiringHighlight }) => isRequiringHighlight && highlightSkeleton};
`;
export const BackOfficeErrorButton = styled.div`
    width: 76px;
    height: 25px;
    border: 1px solid ${({ theme }) => theme.red[400]};
    border-radius: 20px;
    color: ${({ theme }) => theme.red[400]};
    box-sizing: border-box;
    padding: 5px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    background-color: ${({ theme }) => theme.white.ff};
    line-height: 1;
    cursor: pointer;

    &:active {
        border: 1px solid ${({ theme }) => theme.red[800]};
        color: ${({ theme }) => theme.red[400]};
        background: ${({ theme }) => theme.red[100]};
    }
`;
export const SortIconWrapper = styled.div`
    display: block;
    width: 9px;
    margin-right: 4px;
    & span {
        height: 14px;
        width: 9px;
        display: inline-block;
        vertical-align: middle;
    }
    @media (max-width: 1500px) {
        display: inline-block;
    }
`;
export const NonSorted = (
    <SortIconWrapper className="sortIconWrapper">
        <span
            style={{
                backgroundImage: `url(${basePath}/visuals/Sort_off.png)`,
            }}
        >
            {' '}
        </span>
    </SortIconWrapper>
);
export const SortedAsc = (
    <SortIconWrapper className="sortIconWrapper">
        <span
            style={{
                backgroundImage: `url(${basePath}/visuals/Sort_on_up.png)`,
            }}
        >
            {' '}
        </span>
    </SortIconWrapper>
);
export const SortedDesc = (
    <SortIconWrapper className="sortIconWrapper">
        <span
            style={{
                backgroundImage: `url(${basePath}/visuals/Sort_on_down.png)`,
            }}
        >
            {' '}
        </span>
    </SortIconWrapper>
);

export const BrowseButton = styled.div`
    width: 160px;
    height: 47px;
    border: 1px solid ${defaultTheme.blue[950]};
    color: ${defaultTheme.blue[950]};
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0px 0px 4px ${defaultTheme.black[29]};

    transition: box-shadow 0.1s ease-in-out;
    user-select: none;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 10px ${defaultTheme.black[29]};
    }

    &:active {
        background: ${defaultTheme.grey[50]};
    }

    p {
        margin-left: 8px;
        text-align: center;
        width: 100%;
        font-size: 14px;
    }
    @media screen and (max-width: 1500px) {
        width: 100px;
        p {
            margin-left: 0px;
        }
    }
`;

export const FileUploadWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-top: 14px;
    margin-bottom: 20px;
`;

export const UploadButton = styled.div`
    width: 160px;
    height: 47px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-left: 12px;
    align-items: center;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    user-select: none;
    justify-content: center;
    border-radius: 3px;
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    background: ${props => (props.disabled ? defaultTheme.grey[70] : defaultTheme.blue[600])};
    font-weight: 600;
    color: ${props => (props.disabled ? defaultTheme.blue['950-52'] : defaultTheme.white.ff)};

    &:hover {
        background: ${props => (props.disabled ? defaultTheme.grey[300] : '#2121ac')};
    }

    &:active {
        background: ${props => (props.disabled ? defaultTheme.grey[300] : defaultTheme.blue[800])};
    }
    @media screen and (max-width: 1500px) {
        width: 100px;
    }
`;

export const FileUploadIcon = styled.img`
    width: 27px;
    padding-right: 8px;
    border-right: 1px solid ${defaultTheme.blue[950]};
    margin-left: 12px;
    @media screen and (max-width: 1500px) {
        display: none;
        margin-left: 0px;
        padding-right: 0px;
    }
`;
