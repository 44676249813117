import { createSelector } from 'reselect';
import {
    getCachedLastAppliedTopFiltersOptionSelector,
    getLocationSelector,
    getSelectedOptionByPageSelector,
    getSelectedTopFiltersByPageSelector,
} from '../components/TopFilter/data/selectors';
import { getParentPathOnPagesWithSubTabs } from '../components/TopFilter/utils/utils';
import { getAppliedSideFiltersSelector, getCurrentFilterPageSelector } from '../components/SideFilters/data/selectors';

/*
    Computed selector used in situations where we want to send multiple parameters to the BE
 */
export const getComputedFiltersMultipleParametersForRequest = createSelector(
    [
        getSelectedTopFiltersByPageSelector,
        getCachedLastAppliedTopFiltersOptionSelector,
        getSelectedOptionByPageSelector,
        getLocationSelector,
        getCurrentFilterPageSelector,
        getAppliedSideFiltersSelector,
    ],
    (selectedTopFilters, cachedFilters, optionByPage, unprocessedLocation, filterPage, sideFilters) => {
        const currentPage = getParentPathOnPagesWithSubTabs(unprocessedLocation);

        const processedFilters = [];
        let selectedFiltersBasedOnpage = cachedFilters[currentPage];
        if (!selectedFiltersBasedOnpage) {
            selectedFiltersBasedOnpage = selectedTopFilters[currentPage];
        }

        const selectedOptionByPage = optionByPage[currentPage];

        const processFilters = eachFilter => {
            const minFiltersToSendAsLength = 2;

            const setFilters = _filterData => {
                const { id, groupId } = _filterData;
                processedFilters.push({
                    ID: groupId,
                    List: [id],
                });
            };
            setFilters(eachFilter);
            if (selectedFiltersBasedOnpage.length < minFiltersToSendAsLength && selectedOptionByPage) {
                //If the user deselects the first filter (Country) and then reselects it wihout interacting with the last option(Period)
                //We retrieve his previous stored option, as we have it stored separately
                //This is how top filters work and we take care of this aspect here.

                //The last item is always considered 'the option'.
                //While visually we see it checked (we see it because we have a verifyCheckStatus that checks also the option - last item)
                //We need to compose our final filters from the previous selections and the options if we had the action of deselecting the last filter(Period) parents(Country)
                setFilters(selectedOptionByPage);
            }
        };

        if (selectedFiltersBasedOnpage) {
            selectedFiltersBasedOnpage.forEach(eachFilter => {
                processFilters(eachFilter);
            });
        }

        /* Add the side filters (if we have any on this page) */
        const sideFiltersForPage = sideFilters.hasOwnProperty(filterPage) ? sideFilters[filterPage] : null;
        if (sideFiltersForPage) {
            Object.keys(sideFiltersForPage).forEach(key => {
                const data = sideFiltersForPage[key];
                Object.keys(data).forEach(idKey => {
                    const list = data[idKey];

                    if (list.length > 0) {
                        processedFilters.push({
                            ID: +idKey,
                            List: data[idKey],
                        });
                    }
                });
            });
        }
        return processedFilters;
    }
);
