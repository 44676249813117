import { INPUT_TYPES } from './sideFilters.config';

export const processSideFilters = filters => {
    const sideFilters = {};

    filters.sort((a, b) => a.order - b.order);
    filters.forEach(filter => {
        sideFilters[filter.group] = {
            ...sideFilters[filter.group],
            [filter.id]: filter,
        };
    });

    return sideFilters;
};

export const initializeSideFilters = (allFilters, filtersfromLocalStorage, currentPageName) => {
    const selectedSideFilters = {};
    const appliedSideFilters = {};
    allFilters.forEach(filter => {
        if (filter.type === 'Filter') {
            const initFilter = {
                optionsCount: filter.options.length,
                selected: [],
            };

            let initAppliedFilter = [];

            if (filtersfromLocalStorage[currentPageName]?.[filter.group]?.[filter.id]) {
                initFilter.selected = [...filtersfromLocalStorage[currentPageName][filter.group][filter.id]];
                initAppliedFilter = [...filtersfromLocalStorage[currentPageName][filter.group][filter.id]];
            } else {
                if (filter.selectType === INPUT_TYPES.SLIDER) {
                    initFilter.selected = initAppliedFilter = [+filter.options[0].label, +filter.options[1].label];
                }
            }

            selectedSideFilters[filter.group] = {
                ...selectedSideFilters[filter.group],
                [filter.id]: initFilter,
            };
            appliedSideFilters[filter.group] = {
                ...appliedSideFilters[filter.group],
                [filter.id]: initAppliedFilter,
            };
        }
    });
    return { selectedSideFilters, appliedSideFilters };
};

export const initializeSideFiltersSectionUI = (sideFilters, currentPageName, sectionUiFromLocalStorage) => {
    const sectionUI = {};
    // Iterate through each main filter group on the current page (eg: Profile)
    for (const groupName in sideFilters) {
        if (Object.hasOwnProperty.call(sideFilters, groupName)) {
            const groupFilters = sideFilters[groupName];
            sectionUI[groupName] = {
                isOpen: sectionUiFromLocalStorage[currentPageName]?.[groupName]?.isOpen || false,
            };

            // iterate through each selected filter and generate the values for the open sections
            for (const filterId in groupFilters) {
                if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                    sectionUI[groupName] = {
                        ...sectionUI[groupName],
                        [filterId]: {
                            isOpen: sectionUiFromLocalStorage[currentPageName]?.[groupName]?.[filterId].isOpen || false,
                        },
                    };
                }
            }
        }
    }
    return sectionUI;
};
