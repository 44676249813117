export const defaultTheme = {
    trackY: {
        distanceTopBottom: 5,
        width: 10,
        color: '#f5f6f6',
        distanceRight: 5,
    },
    thumbYColor: '#d0d6df',
    trackX: {
        distanceRightLeft: 5,
        height: 10,
        color: '#f5f6f6',
        distanceBottom: 5,
    },
    thumbXColor: '#d0d6df',
    borderRadius: 4
};