export const loginPages = {
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_SUCCESS: 'RESET_SUCCESS',
    EMAIL_SENT: 'EMAIL_SENT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SET_PASSWORD: 'SET_PASSWORD',
    LINK_EXPIRED: 'LINK_EXPIRED',
};

export const RESET_PASSWORD_PATHNAME = '/ResetPassword';
export const SET_PASSWORD_PATHNAME = '/SetPassword';

const pagesWithPathnamesMap = [
    {
        PATHNAME: RESET_PASSWORD_PATHNAME,
        PAGE: loginPages.RESET_PASSWORD,
    },
    {
        PATHNAME: SET_PASSWORD_PATHNAME,
        PAGE: loginPages.SET_PASSWORD,
    },
];

export const pathnamesPagesMatcher = currentPage => {
    const pageToReturn = pagesWithPathnamesMap.find(el => el.PATHNAME === currentPage)?.PAGE;
    return pageToReturn;
};
