import React, { useCallback, useState } from 'react';
import { calculateWidthInsurersInvestmentCompetitive } from '../../../../../../utils/responsiveness';
import LargeStackedChart from '../LargeStackedChart/LargeStackedChart';
import { ChartPickerWrapper } from './InvestmentSeasonalityGroup.styles';
import CombinedBarLineChart from '../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import Legend from '../../../../../../common/components/Legend/Legend';
import RoundCheckbox from '../../../../../../common/components/RoundCheckbox/RoundCheckbox';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import PropTypes from 'prop-types';
import * as SC from '../../CompetitiveMedia.styles';
import SectionInfo from '../../../../components/SectionInfo';
import SectionsHead from '../../../../../../common/components/SectionsHead/SectionsHead';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { sectionsHeadColor } from '../../../../utils/utils';

export const CHARTS = {
    KEY_COMPETITOR: 1,
    TOP_20: 2,
    TOTAL_MARKET: 3,
};

const InvestmentSeasonalityGroup = ({ investmentSeasonalityData }) => {
    const [activeChart, setActiveChart] = useState(CHARTS.KEY_COMPETITOR);
    const { keyCompetitorsData, totalMarketData, topTwentyCompetitorsData } = investmentSeasonalityData;
    const [width] = useWindowSize();

    const renderCompetitors = useCallback(
        (renderData, chartID) => {
            return (
                <LargeStackedChart
                    height={450}
                    calculateWidth={calculateWidthInsurersInvestmentCompetitive}
                    brands={renderData.colors.map(color => color.label)}
                    colors={renderData.colors.map(color => color.color)}
                    data={renderData.data}
                    chartID={chartID}
                />
            );
        },
        [activeChart, investmentSeasonalityData]
    );

    const renderLegend = useCallback(() => {
        if (activeChart === CHARTS.TOTAL_MARKET) return null;
        const renderData = activeChart === CHARTS.TOP_20 ? topTwentyCompetitorsData : keyCompetitorsData;
        return (
            <SC.SectionRow>
                <SC.SectionItems>
                    <SC.SectionSingularItem>
                        <Legend
                            customStyle={{
                                _wrapper: {
                                    maxWidth: `${calculateWidthInsurersInvestmentCompetitive(width) + 100}px`,
                                    paddingRight: width > 1700 ? '80px' : 0,
                                    marginTop: '10px',
                                    flexWrap: 'wrap',
                                },
                                _individualItem: {
                                    marginRight: '20px',
                                    marginTop: '22px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                },
                                _legendShape: renderData.colors.map((c, i) => ({
                                    _legendIndex: i,
                                    _legendStyle: {
                                        display: 'inline-block',
                                        wordBreak: 'keep-all',
                                        width: 10,
                                        height: 10,
                                        minWidth: 10,
                                        borderRadius: 0,
                                    },
                                })),
                            }}
                            displayDirection={'row'}
                            legendItems={renderData.colors.map(e => ({ ...e, name: e.label }))}
                        />
                    </SC.SectionSingularItem>
                </SC.SectionItems>
            </SC.SectionRow>
        );
    }, [activeChart, investmentSeasonalityData]);

    const renderTotalMarket = useCallback(() => {
        return (
            <div data-testid={'CombinedBarLineChart-Total Market'}>
                <CombinedBarLineChart
                    bypassLOBFiltering
                    customHeight={450}
                    foreignObjects={{
                        width: 30,
                        translateX: -15,
                        translateY: -45,
                    }}
                    customGridStyle={{
                        paddingLeft: 25,
                    }}
                    lineData={totalMarketData.lineData}
                    barData={totalMarketData.barData}
                    customWidthOnPage={calculateWidthInsurersInvestmentCompetitive}
                    leftAxisLabelFormatter={''}
                    leftLabel={TRANSLATION_TEXT.CM_AGGREGATOR_STACKEDBARCHART_YAXIS_TITLE}
                    hideBarLabel
                    barLabelFormatter={''}
                    alwaysOnLineTooltip
                    customLineTooltip={{
                        width: 31,
                        fontSize: 10,
                        labelFormatter: '',
                        strokeOpacity: 0,
                        backgroundColor: defaultTheme.blue[600],
                        textColor: defaultTheme.white.ff,
                    }}
                    customBarTooltip={{
                        width: 65,
                        height: 40,
                        backgroundColor: defaultTheme.white.ff,
                        boxShadow: `0px 0px 8px ${defaultTheme.black[29]}`,
                        textContentForKey: 'group',
                    }}
                />
                <Legend
                    customStyle={{
                        _wrapper: { marginLeft: '20px', position: 'absolute' },
                        _individualItem: { marginRight: '23px', marginTop: 0, fontSize: '11px' },
                        _legendShape: [
                            {
                                _legendIndex: 0,
                                _legendStyle: {
                                    width: 30,
                                    height: 10,
                                    borderRadius: 0,
                                },
                            },
                        ],
                    }}
                    displayDirection={'row'}
                    legendItems={totalMarketData.legend}
                />
            </div>
        );
    }, [totalMarketData, activeChart]);

    const renderChart = () => {
        switch (activeChart) {
            case CHARTS.KEY_COMPETITOR:
                return renderCompetitors(keyCompetitorsData, CHARTS.KEY_COMPETITOR);
            case CHARTS.TOP_20:
                return renderCompetitors(topTwentyCompetitorsData, CHARTS.TOP_20);
            case CHARTS.TOTAL_MARKET:
                return renderTotalMarket();
            default:
                return null;
        }
    };

    return (
        <SectionsHead
            title={totalMarketData?.sectionLabel?.toUpperCase()}
            isResponsive={true}
            customStyle={sectionsHeadColor}
        >
            <SC.SectionRow>
                <SC.SectionItems>
                    <SC.SectionSingularItem>
                        <ChartPickerWrapper>
                            <RoundCheckbox
                                textColor={defaultTheme.blue[950]}
                                label={TRANSLATION_TEXT.INVESTMENT_SEASONALITY_KEY_COMPETITORS}
                                onClick={() => setActiveChart(CHARTS.KEY_COMPETITOR)}
                                value={activeChart === CHARTS.KEY_COMPETITOR}
                                padding="0"
                                isSelectedBold={true}
                            />
                            <RoundCheckbox
                                textColor={defaultTheme.blue[950]}
                                label={TRANSLATION_TEXT.INVESTMENT_SEASONALITY_TOP_20}
                                onClick={() => setActiveChart(CHARTS.TOP_20)}
                                value={activeChart === CHARTS.TOP_20}
                                padding="0"
                                isSelectedBold={true}
                            />
                            <RoundCheckbox
                                textColor={defaultTheme.blue[950]}
                                label={TRANSLATION_TEXT.INVESTMENT_SEASONALITY_TOTAL_MARKET}
                                onClick={() => setActiveChart(CHARTS.TOTAL_MARKET)}
                                value={activeChart === CHARTS.TOTAL_MARKET}
                                padding="0"
                                isSelectedBold={true}
                            />
                        </ChartPickerWrapper>
                    </SC.SectionSingularItem>
                </SC.SectionItems>
            </SC.SectionRow>
            <SC.SectionRow style={{ marginBottom: activeChart === CHARTS.TOTAL_MARKET ? '70px' : 0 }}>
                <SC.SectionItems nonResponsiveSection>
                    <SC.SectionSingularItem>{renderChart()}</SC.SectionSingularItem>
                </SC.SectionItems>
                <SC.SectionSingularItem>
                    <SectionInfo
                        sectionData={totalMarketData?.sectionInfo}
                        sectionSideNote={totalMarketData?.sectionSideNote}
                    />
                </SC.SectionSingularItem>
            </SC.SectionRow>
            {renderLegend()}
        </SectionsHead>
    );
};

InvestmentSeasonalityGroup.propTypes = {
    investmentSeasonalityData: PropTypes.shape({
        keyCompetitorsData: PropTypes.object.isRequired,
        totalMarketData: PropTypes.object.isRequired,
        topTwentyCompetitorsData: PropTypes.object.isRequired,
    }),
};

InvestmentSeasonalityGroup.defaultProps = {
    investmentSeasonalityData: null,
};

export default InvestmentSeasonalityGroup;
