import React from 'react';
import styled, { keyframes } from 'styled-components';

const load8 = () => keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const SmallLoaderWrapper = styled.div`
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgb(217, 217, 217);
    border-right: 5px solid rgb(217, 217, 217);
    border-bottom: 5px solid rgb(217, 217, 217);
    border-left: 5px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: ${load8} 1.1s infinite linear;
    animation: ${load8} 1.1s infinite linear;
    border-radius: 50%;
    width: 13px;
    height: 13px;

    &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
        background: transparent;
    }
`;

const SmallLoader = () => {
    return <SmallLoaderWrapper />;
};

export default SmallLoader;
