import React, { useCallback, useEffect, useState } from 'react';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from '@toluna-ui-toolkit/barchart';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import * as SC from './RankingTable.styles';
import * as SC_PA from '../../PreferanceAnalysis.styles';
import PropTypes from 'prop-types';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import RankingTableDropdown from './RankingTableDropdown';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import Tooltip from '../Tooltip/Tooltip';
import { basePath } from '../../../../../../services/utils';
import { RK_TABLE_MEDIA_QUERY_SCREEN } from '../../../../../../utils/responsiveness';
import { VALUE_TYPES, numFormatterExceptZero, METRICS_VALUE_COLORS_MAP } from '../../tabs/utils/utils';
import { KPI_TYPES } from '../../../../../../common/utils/utils';
import { arraysEqual, deepEqual } from '../../../../../../utils/generalUtilities';

//Component can accept an array with just one or multiple table data elements;
//For example, in the usage on Awareness tab, there is an array of two items passed, where we have extra functionality on the second table
//But if the item ID does not trigger the extra functionality, this table just renders data without any aditional functionalities that are baked in.
const RankingTable = ({
    rankingData,
    styleObject,
    hasPictogram,
    noTitle,
    lobOptions,
    compactMode,
    customTitleStyle,
    hasNumberOfRespondents,
    hasSideFiltersApplied,
}) => {
    const [currentLOB, setSelectedLOB] = useState(-1);

    const [width] = useWindowSize();
    const BAR_CHARTS_WIDTH =
        width <= RK_TABLE_MEDIA_QUERY_SCREEN.BREAKING_POINTS.LARGE
            ? RK_TABLE_MEDIA_QUERY_SCREEN.BAR_CHARTS_RESPONSIVE_WIDTHS.SMALL
            : RK_TABLE_MEDIA_QUERY_SCREEN.BAR_CHARTS_RESPONSIVE_WIDTHS.LARGE;

    useEffect(() => {
        if (lobOptions && lobOptions.length) {
            const firstSelection = lobOptions[0].key;
            setSelectedLOB(firstSelection);
        }
    }, [lobOptions]);

    const renderBarChart = useCallback(
        (item, isDynamicTable) => {
            const data = [
                {
                    name: item.label,
                    value: item.value,
                    color: item.color,
                },
            ];
            let chartRef = null;
            const isMarketAverage = item.type === VALUE_TYPES.TYPE_AVERAGE;
            return (
                <SC.Container>
                    <div ref={chartRef}>
                        <BarChart
                            key={BAR_CHARTS_WIDTH}
                            height={30}
                            width={compactMode ? 50 : BAR_CHARTS_WIDTH} // 120
                            data={data}
                            marginWidth={75}
                            translateX={38}
                            translateY={5}
                            domain={100}
                            direction={BARCHART_DIRECTION.HORIZONTAL}
                            labelFormatter={value => value + '%'}
                            labelPosition={BARCHART_LABEL_POSITION.END_OF_DOMAIN}
                            styling={{
                                barWidth: 10,
                                backgroundColor: defaultTheme.blue[50],
                                barStroke: !isMarketAverage ? 1 : 0,
                                barStrokeColor: !isDynamicTable && defaultTheme.blue[600],
                                barLabelMainAxisOffset: 45,
                                barLabelSecondaryAxisOffset: -5,
                                barLabelColor: defaultTheme.blue[900],
                                fontWeight: item.type === 'full' ? 600 : 500,
                                barFontSize: 16,
                                barBorderRadius: 1,
                            }}
                            withNames={false}
                            alwaysOnTooltips={false}
                            tooltipShowSize={0}
                        />
                    </div>
                </SC.Container>
            );
        },
        [rankingData, BAR_CHARTS_WIDTH]
    );
    const renderTable = useCallback(
        (tableData, tableKey) => {
            if (tableData) {
                const isDynamicTable = tableData.kpiLabel === KPI_TYPES.LOBS;

                let rankingValues =
                    isDynamicTable && currentLOB
                        ? tableData.items.find(el => el.itemId === currentLOB)?.values
                        : tableData.items[0]?.values;
                const tableTitle = (() => tableKey === 0 && tableData?.kpiDescription)(); //to be modified after BE clarifications
                const dropdownOptions = isDynamicTable && lobOptions;
                const numberOfRespondentsRanking = hasNumberOfRespondents && ' - n=' + tableData?.nrRespondents;

                //Sort by order
                if (rankingValues) {
                    rankingValues = rankingValues.filter(item => item !== null);
                    rankingValues = rankingValues.sort((a, b) =>
                        a.type === VALUE_TYPES.TYPE_AVERAGE ? 1 : a.order - b.order
                    );

                    return (
                        <SC.Wrapper key={tableData.kpiID + tableData.kpiLabel} styleObject={styleObject}>
                            <SC.Title isDynamicTable={isDynamicTable} customTitleStyle={customTitleStyle}>
                                {!noTitle && tableTitle}
                                {!noTitle && numberOfRespondentsRanking}
                                {hasPictogram && (
                                    <Tooltip
                                        tooltipText={TRANSLATION_TEXT.INFO_PICTOGRAM_TEXT}
                                        customStyle={{
                                            _content: {
                                                width: '230px',
                                                marginLeft: '3px',
                                                whiteSpace: 'pre-line',
                                                wordWrap: 'break-word',
                                                top: '-100px',
                                                textAlign: 'center',
                                            },
                                        }}
                                    >
                                        <SC_PA.InfoPic>
                                            <img src={`${basePath}/visuals/InfoIconYTD.png`} alt={'Info Icon'} />
                                        </SC_PA.InfoPic>
                                    </Tooltip>
                                )}
                            </SC.Title>
                            {!compactMode && (
                                <SC.Header
                                    isDynamicTable={isDynamicTable}
                                    hasSideFiltersApplied={hasSideFiltersApplied}
                                >
                                    <SC.Col>
                                        <SC.RankArrow alt={'Header Rank Arrow'} src={`${basePath}/visuals/rank.png`} />{' '}
                                        {TRANSLATION_TEXT.HEADER_RANK_TEXT}{' '}
                                    </SC.Col>
                                    <SC.Col />
                                    {/*Dropdown menu to be added here */}
                                    <SC.Col>
                                        {isDynamicTable ? (
                                            <RankingTableDropdown
                                                options={dropdownOptions}
                                                selectedOption={currentLOB}
                                                onChange={setSelectedLOB}
                                            />
                                        ) : (
                                            TRANSLATION_TEXT.HEADER_OVERALL_TEXT
                                        )}
                                    </SC.Col>
                                    <SC.Col />
                                </SC.Header>
                            )}
                            {rankingValues.map(item => (
                                <SC.Row
                                    className={'ranking-table-row'}
                                    isAverage={item.type === VALUE_TYPES.TYPE_AVERAGE}
                                    key={item.order + item.label + item.value}
                                    hasSideFiltersApplied={hasSideFiltersApplied}
                                >
                                    <SC.Col compactMode={compactMode}>
                                        <SC.RankWrapper isTopTen={item.order < 10}>
                                            {item.type !== VALUE_TYPES.TYPE_AVERAGE && `#${item.order}`}
                                            {(item.isLower || item.isHigher) && (
                                                <SC.RankType
                                                    src={
                                                        item.isLower
                                                            ? `${basePath}/visuals/lowDownArrow.png`
                                                            : item.isHigher && `${basePath}/visuals/greenArrow.svg`
                                                    }
                                                    alt="Rank Type Arrow"
                                                />
                                            )}
                                        </SC.RankWrapper>
                                    </SC.Col>
                                    <SC.Col bold={item.type === 'full'}>
                                        <Tooltip tooltipText={item.label}>
                                            <SC.LabelWrapper>{item.label}</SC.LabelWrapper>
                                        </Tooltip>
                                    </SC.Col>
                                    <SC.Col className={'ranking-table-bar'}>
                                        {renderBarChart(item, isDynamicTable)}
                                    </SC.Col>

                                    {item.evolutionMetricValue !== undefined && item.evolutionMetricValue !== null ? (
                                        <SC.Col className={'ranking-table-bar-optional'}>
                                            <SC.EvolutionMetric
                                                colorScheme={
                                                    METRICS_VALUE_COLORS_MAP[Math.sign(item.evolutionMetricValue)]
                                                }
                                            >
                                                {numFormatterExceptZero(item.evolutionMetricValue) +
                                                    ' ' +
                                                    TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL}
                                            </SC.EvolutionMetric>
                                        </SC.Col>
                                    ) : (
                                        ''
                                    )}
                                </SC.Row>
                            ))}
                        </SC.Wrapper>
                    );
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        [rankingData, currentLOB, BAR_CHARTS_WIDTH]
    );
    return (
        <>
            {rankingData &&
                rankingData.map((tableData, tableKey) => {
                    return renderTable(tableData, tableKey);
                })}
        </>
    );
};

const tableDataShapeValidator = (propValue, _, componentName, __, propFullName) => {
    let hasError;
    const requiredKeys = ['kpiID', 'items'];
    let foundKeys = [];
    propValue.map(rankingTablePropData => {
        Object.keys(rankingTablePropData).forEach(el => {
            if (requiredKeys.includes(el)) {
                foundKeys.push(el);
            }
        });
    });
    //If any of the tables do not have the 'requiredKeys', the component will throw an error. One table in the array might have the correct data structure
    //but the component requires all of them to be right, as it can render individual tables or multiple ones.
    if (!requiredKeys.every(reqKeys => foundKeys.some(foundOnes => reqKeys === foundOnes))) {
        hasError = new Error(
            'Invalid prop `' + propFullName + '` supplied for' + ' `' + componentName + '`. Validation failed.'
        );
    }
    return hasError;
};

RankingTable.defaultProps = {
    hasNumberOfRespondents: false,
    hasSideFiltersApplied: false,
};

RankingTable.propTypes = {
    rankingData: PropTypes.arrayOf(tableDataShapeValidator),
    styleObject: PropTypes.object,
    lobOptions: PropTypes.array,
    compactMode: PropTypes.bool,
    customTitleStyle: PropTypes.object,
    hasNumberOfRespondents: PropTypes.bool,
    hasSideFiltersApplied: PropTypes.bool,
};

export const MemoizedRankingTable = React.memo(RankingTable, (prevProps, nextProps) => {
    return (
        deepEqual(prevProps.styleObject, nextProps.styleObject) &&
        prevProps.compactMode === nextProps.compactMode &&
        deepEqual(prevProps.customTitleStyle, nextProps.customTitleStyle) &&
        arraysEqual(prevProps.lobOptions, nextProps.lobOptions) &&
        arraysEqual(prevProps.rankingData, nextProps.rankingData)
    );
});

export default RankingTable;
