import React from 'react';
import { connect } from 'react-redux';
import { getIsGlobalLoadingSelector } from '../data/selectors';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { LoaderWrapper } from './Loader.styles';
import PropTypes from 'prop-types';
export const Loader = ({ isLoading }) => {
    return (
        isLoading && (
            <LoaderWrapper>
                <FullPageLoader />
            </LoaderWrapper>
        )
    );
};
Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export const AlwaysOnLoader = () => {
    return (
        <LoaderWrapper>
            <FullPageLoader />
        </LoaderWrapper>
    );
};

const mapStateToProps = state => ({
    isLoading: getIsGlobalLoadingSelector(state),
});

export default connect(mapStateToProps, null)(Loader);
