import { getKPIBasedOnMatchedProperty, getPropertyValueOrDefault } from '../../../../../utils/generalUtilities';
import { SECTION_TYPES } from '../tabs/utils/utils';

const get = state => state.bptPreferenceAnalysis;
export const getBptPreferenceAnalysisDataSelector = state => get(state);
export const getBptPreferenceAnalysisAdRecallDataSelector = state => get(state).adRecallData;
export const getBptPreferenceAnalysisAwarenessDataSelector = state => get(state).awarenessData;
export const getBptPreferenceAnalysisConsiderationSelector = state => get(state).considerationData;
export const getBptPreferenceAnalysisImageDataSelector = state => get(state).imageData;
export const getBptPreferenceAnalysisValueForMoneySelector = state => get(state).valueForMoneyData;

export const getBptPreferenceAnalysisTableSectionSelector = state => {
    const imageData = getPropertyValueOrDefault(get(state).imageData, 'sections', null);
    const sectionWithTableData = getKPIBasedOnMatchedProperty(imageData, 'sectionType', SECTION_TYPES.TABLE);

    return sectionWithTableData;
};
