import styled from 'styled-components';

export const Input = styled.input`
    visibility: hidden;
    margin: 0;
    position: absolute;
`;
export const StyledInput = styled.span`
    width: 18px;
    height: 18px;
    min-width: 18px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 11px;
    background-color: ${({ checked, theme, disabled, checkedAndApplied }) =>
        checked && !disabled
            ? checkedAndApplied
                ? theme.blue[600]
                : theme.white.ff
            : checked && disabled
            ? theme.blue[200]
            : disabled
            ? theme.grey[200]
            : theme.white.ff};
    color: ${({ theme }) => theme.white.ff};
    border: 1px solid
        ${({ theme, checked, disabled, checkedAndApplied }) =>
            checked && !disabled ? (checkedAndApplied ? theme.blue[600] : theme.grey[600]) : theme.grey[600]};
    color: ${({ theme }) => theme.white.ff};
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
export const Label = styled.label`
    margin-left: 8px;
    cursor: pointer;
    color: ${({ textColor }) => textColor};
    opacity: ${({ isTransparent }) => (isTransparent ? '0.5' : '1')};
    font-weight: ${({ isSelectedBold, checked }) => (isSelectedBold && checked ? '800' : 'normal')};
    font-size: 15px;
`;
export const StyledIcon = styled.div`
    width: 5px;
    height: 10px;
    margin: 0px 4px 2px 4px;
    border-bottom: 1px solid
        ${({ checked, theme, disabled, checkedAndApplied }) =>
            checked && !disabled
                ? checkedAndApplied
                    ? theme.white.ff
                    : theme.blue[600]
                : !checked && disabled
                ? theme.grey[200]
                : theme.white.ff};
    border-right: 1px solid
        ${({ checked, theme, disabled, checkedAndApplied }) =>
            checked && !disabled
                ? checkedAndApplied
                    ? theme.white.ff
                    : theme.blue[600]
                : !checked && disabled
                ? theme.grey[200]
                : theme.white.ff};
    transform: rotate(45deg);
`;
export const CheckboxWrapper = styled.div`
    padding: ${({ padding }) => (padding ? padding : '6px 12px')};
    display: flex;
    align-items: center;
    &:hover ${StyledIcon} {
        border-bottom: 1px solid
            ${({ theme, checked, checkedAndApplied }) =>
                checked ? (checkedAndApplied ? theme.white.ff : theme.blue[600]) : theme.blue[600]};
        border-right: 1px solid
            ${({ theme, checked, checkedAndApplied }) =>
                checked ? (checkedAndApplied ? theme.white.ff : theme.blue[600]) : theme.blue[600]};
    }

    &:nth-child(1) {
        margin-top: 4px;
    }
    &:nth-last-child(1) {
        margin-bottom: 4px;
    }
`;
