import styled from "@emotion/styled";
import {keyframes} from "@emotion/core";

const loading = () => {
    return keyframes`
        to {
            transform: rotate(360deg);
        }`;
};

export const Spinner = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    
    border-top: 4px solid ${props => props.outterColor?props.outterColor: '#fff'};
    border-right: 4px solid ${props => props.outterColor?props.outterColor: '#fff'};
    border-bottom: 4px solid ${props => props.innerColor? props.innerColor: props.theme.lightColor };
    border-left: 4px solid ${props => props.innerColor? props.innerColor: props.theme.lightColor};
    
    -webkit-animation: ${loading} 1.2s infinite linear;
    -moz-animation: ${loading} 1.2s infinite linear;
    -ms-animation: ${loading} 1.2s infinite linear;
    animation: ${loading} 1.2s infinite linear;
    
    ${props => props.customStyle || ''}
`;