import React, { useState } from 'react';
import { validatePassword } from '../../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { Form, InputWrapper, Input, ErrorLabel, SubmitButton, Title, Subtitle } from '../../login.styles';
import { pathnamesPagesMatcher } from '../../utils/pageConfig';

const passwordErrorMessage =
    'Please make sure your password has at least 8 characters with a digit and a special character.';
const confirmErrorMessage = 'The values in the two fields did not match';

const ResetPassword = ({ resetPasswordActionCreator }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(null);

    const submitHandler = e => {
        e.preventDefault();
        if (!validatePassword(password)) {
            setErrorPassword(passwordErrorMessage);
            return;
        }
        if (password !== confirmPassword) {
            setErrorPassword(confirmErrorMessage);
            setErrorConfirmPassword(confirmErrorMessage);
            return;
        }
        const searchParams = new URL(window.location).searchParams;
        const resetToken = searchParams.get('tkn');
        resetPasswordActionCreator({ token: resetToken, password: password });
        setPassword('');
        setConfirmPassword('');
    };
    const locationFromWindowPathname = window.location.pathname;
    const pathnamePageMatch = pathnamesPagesMatcher(locationFromWindowPathname);
    return (
        <>
            <Title>{TRANSLATION_TEXT.RESET_PASSWORD_TEXT[pathnamePageMatch]}</Title>
            <Subtitle>{TRANSLATION_TEXT.RESET_PASSWORD_MESSAGE_TEXT}</Subtitle>
            <Form onSubmit={submitHandler}>
                <InputWrapper>
                    <Input
                        placeholder={TRANSLATION_TEXT.RESET_PASSWORD_INPUT_TEXT}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        name="password"
                        withError={errorPassword}
                        onFocus={() => {
                            errorPassword === confirmErrorMessage && setErrorConfirmPassword(null);
                            setErrorPassword(null);
                        }}
                    />
                    <ErrorLabel data-testid="ResetPassword-ErrorLabel1" htmlFor="password">
                        <p>{errorPassword}</p>
                    </ErrorLabel>
                </InputWrapper>
                <InputWrapper>
                    <Input
                        placeholder={TRANSLATION_TEXT.RESET_PASSWORD_REPEAT_INPUT_TEXT}
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        type="password"
                        name="confirmPassword"
                        withError={errorConfirmPassword}
                        onFocus={() => {
                            // only reset the error for the password field if the error was for the passwords that didn't match
                            errorConfirmPassword && setErrorPassword(null);
                            setErrorConfirmPassword(null);
                        }}
                    />
                    <ErrorLabel data-testid="ResetPassword-ConfirmErrorLabel1" htmlFor="confirmPassword">
                        <p>{errorConfirmPassword}</p>
                    </ErrorLabel>
                </InputWrapper>
                <SubmitButton type="submit">
                    {TRANSLATION_TEXT.RESET_PASSOWRD_SUBMIT_TEXT[pathnamePageMatch]}
                </SubmitButton>
            </Form>
        </>
    );
};

export default ResetPassword;
