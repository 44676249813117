import React, { useRef, useEffect, useMemo } from 'react';
import * as d3 from 'd3';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { calculateWidthValueForSOVChart } from '../../../../../../utils/responsiveness';
import { CSSWrapper, SovSvg } from './SOVChart.styles';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import PropTypes from 'prop-types';

/* Chart sizing controls */
const HEIGHT = 350;
const PADDING_LEFT = 64;
const PADDING_BOTTOM = 30;
const PADDING_TOP = 94;

/* Chart labels controls */
const SOV_WIDTH = 42;

/* Chart labels */
export const SOV_LABEL = 'SOV';

const SOVChart = ({ data, brand }) => {
    const [width] = useWindowSize();
    const svgRef = useRef(null);

    const WIDTH = useMemo(() => {
        return calculateWidthValueForSOVChart(width);
    }, [width]);

    const getPaddingBasedOnDataSize = () => {
        switch (data.length) {
            case 2:
                return 0.6;
            case 3:
                return 0.55;
            case 4:
                return 0.45;
            case 5:
            case 6:
                return 0.3;
            default:
                return 0.4;
        }
    };

    useEffect(() => {
        if (svgRef && svgRef.current) {
            const svg = d3.select(svgRef.current);
            svg.selectAll('*').remove();

            /* Extract necessary data */
            const groups = data.map(d => d.year);
            const subgroups = Object.keys(data[0].bars);

            /* Add Y axis */
            const maxDomain = d3.max([
                ...data.map(d => d.line.value),
                ...data.map(d => Object.keys(d.bars).map(key => d.bars[key].value)),
            ]);

            let yScale = d3
                .scaleLinear()
                .domain([0, maxDomain + 0.2 * maxDomain])
                .rangeRound([HEIGHT - PADDING_BOTTOM, PADDING_TOP]);

            let yAxis = d3.axisLeft().scale(yScale).ticks(10);

            const yAxisElement = svg
                .append('g')
                .attr('class', 'axis')
                .style('transform', `translate(${PADDING_LEFT}px,0px)`)
                .call(yAxis);

            /* Remove unnecessary Y axis elements */
            yAxisElement.selectAll('.tick line').remove();
            yAxisElement.selectAll('text').style('fill', defaultTheme.blue[900]).style('opacity', 0.8);
            yAxisElement.select('.tick').remove();
            yAxisElement.selectAll('path').remove();

            yAxisElement
                .append('text')
                .attr('class', 'yAxisTextSov')
                .attr('x', -9)
                .attr('y', HEIGHT / 2 + 6)
                .text(TRANSLATION_TEXT.CM_AGGREGATOR_STACKEDBARCHART_YAXIS_TITLE);

            /* Add X axis */
            let xScale = d3
                .scaleBand()
                .domain(groups.map(g => g))
                .range([PADDING_LEFT, WIDTH])
                .padding([getPaddingBasedOnDataSize()]);
            let xAxis = d3.axisBottom(xScale).tickSizeOuter(0);

            const xAxisElement = svg
                .append('g')
                .attr('class', 'xAxisSov')
                .style('transform', `translate(0px,${HEIGHT - PADDING_BOTTOM}px)`)
                .call(xAxis);
            xAxisElement.selectAll('path').attr('stroke-dasharray', '3,3').attr('stroke', defaultTheme.grey['350']);
            xAxisElement.selectAll('.tick line').remove();

            /* Add the grouped bars */
            const xSubgroup = d3.scaleBand().domain(subgroups).range([0, xScale.bandwidth()]).padding([0.01]);

            const barGroups = svg
                .append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr('transform', d => `translate(${xScale(d.year)},-${PADDING_BOTTOM})`);

            barGroups
                .selectAll('rect')
                .data(d => subgroups.map(key => ({ key: key, value: d.bars[key] })))
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', d => xSubgroup(d.key))
                .attr('y', d => PADDING_BOTTOM + yScale(d.value.value))
                .attr('width', xSubgroup.bandwidth())
                .attr('height', d => HEIGHT - PADDING_BOTTOM - yScale(d.value.value))
                .attr('fill', d => d.value.color)
                .attr('stroke', d => {
                    const c = d.value.color.substring(1); // strip #
                    const rgb = parseInt(c, 16); // convert rrggbb to decimal
                    // eslint-disable-next-line no-bitwise
                    const r = (rgb >> 16) & 0xff; // extract red
                    // eslint-disable-next-line no-bitwise
                    const g = (rgb >> 8) & 0xff; // extract green
                    // eslint-disable-next-line no-bitwise
                    const b = (rgb >> 0) & 0xff; // extract blue

                    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

                    /* Luma range 0..255, where 255 is brightest. If the color is white, balance it with a border */
                    if (luma >= 254) {
                        return defaultTheme.blue[900];
                    }
                    return null;
                });

            barGroups
                .selectAll('text')
                .data(d => subgroups.map(key => ({ key: key, value: d.bars[key] })))
                .enter()
                .append('text')
                .attr('x', d => xSubgroup(d.key) + xSubgroup.bandwidth() / 2)
                .attr('y', d => PADDING_BOTTOM + yScale(d.value.value) - 7)
                .attr('text-anchor', 'middle')
                .attr('class', 'barSovText')
                .text(d => (+d.value.value > 0 ? d.value.value : null));

            /* Add the line */
            const lineGroup = svg.append('g');
            lineGroup
                .append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', d => d[0].line.color)
                .attr('stroke-width', 2)
                .attr(
                    'd',
                    d3
                        .line()
                        .x(d => xScale(d.year) + xSubgroup.bandwidth())
                        .y(d => yScale(d.line.value))
                );
            lineGroup
                .selectAll('circle')
                .data(data)
                .enter()
                .append('circle')
                .style('fill', d => d.line.color)
                .attr('r', 3.5)
                .attr('cx', d => xScale(d.year) + xSubgroup.bandwidth())
                .attr('cy', d => yScale(d.line.value));
            lineGroup
                .selectAll('text')
                .data(data)
                .enter()
                .append('text')
                .attr('class', 'lineTextSov')
                .attr('x', d => xScale(d.year) + xSubgroup.bandwidth() - 20)
                .attr('y', d => yScale(d.line.value) - 10)
                .text(d => d.line.value);

            /* Add the SOVs */
            const sovGroup = svg.append('g');
            sovGroup
                .selectAll('rect')
                .data(data)
                .enter()
                .append('rect')
                .style('fill', defaultTheme.blue['950'])
                .style('opacity', 0.7)
                .attr('rx', 4)
                .attr('x', d => xScale(d.year) + xScale.bandwidth() / 4)
                .attr('y', 20)
                .attr('height', '23px')
                .attr('width', SOV_WIDTH);

            sovGroup
                .selectAll('text')
                .data(data)
                .enter()
                .append('text')
                .style('fill', 'white')
                .attr('class', 'sovText')
                .attr('x', d => xScale(d.year) + xScale.bandwidth() / 2.3)
                .attr('y', 35)
                .text(d => `${d.sov}%`);

            /* Add the SOV Static Labels */
            sovGroup
                .append('rect')
                .style('fill', defaultTheme.grey[250])
                .attr('rx', 4)
                .attr('x', 30)
                .attr('y', 20)
                .attr('height', '23px')
                .attr('width', SOV_WIDTH);

            sovGroup
                .append('text')
                .style('fill', defaultTheme.blue[800])
                .style('font-size', '13px')
                .style('font-weight', 600)
                .attr('class', 'sovText')
                .attr('x', 40)
                .attr('y', 36)
                .text(SOV_LABEL);

            /* Add the SOV Title Labels */
            const sovRect = sovGroup
                .append('rect')
                .style('fill', brand.color)
                .attr('class', 'brand-name-class')
                .attr('rx', 4)
                .attr('x', 30)
                .attr('y', 50)
                .attr('height', '23px')
                .attr('width', 0);

            const sovText = sovGroup
                .append('text')
                .style('fill', defaultTheme.white.ff)
                .style('font-size', '13px')
                .style('text-anchor', 'start')
                .attr('class', 'sovText sovMainBrand')
                .attr('x', 35)
                .attr('y', 66)
                .text(brand.name);

            /* Center the text in the rectangle */
            const sovTextLabelWidth = sovText.node().getBoundingClientRect().width;
            sovRect.attr('width', sovTextLabelWidth + 10);
        }
    }, [WIDTH, data]);
    return (
        <CSSWrapper>
            <SovSvg ref={svgRef} style={{ width: WIDTH + WIDTH * 0.05 + 'px', height: HEIGHT + 'px' }} />
        </CSSWrapper>
    );
};

SOVChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            sov: PropTypes.number.isRequired,
            bars: PropTypes.object.isRequired,
            line: PropTypes.shape({
                color: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            }),
        })
    ),
    brand: PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string.isRequired,
    }),
};

export default SOVChart;
