import styled from 'styled-components';

export const StyledInput = styled.span`
    width: 18px;
    height: 18px;
    min-width: 18px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 11px;
    background-color: ${({ theme }) => theme.white.ff};
    border: 1px solid
        ${({ theme, checked, disabled, checkedAndApplied }) =>
            checked && !disabled
                ? checkedAndApplied
                    ? theme.blue[600]
                    : theme.grey[600]
                : checked && disabled
                ? theme.blue[200]
                : theme.grey[600]};
    color: ${({ theme }) => theme.white.ff};
    border-radius: 50%;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
export const Input = styled.input`
    visibility: hidden;
    margin: 0;
    position: absolute;
`;
export const StyledIcon = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme, checked, disabled, checkedAndApplied }) =>
        checked && !disabled
            ? checkedAndApplied
                ? theme.blue[600]
                : theme.blue[600]
            : checked && disabled
            ? theme.blue[200]
            : theme.white.ff};
`;
export const RoundCheckboxWrapper = styled.div`
    padding: ${({ padding }) => (padding ? padding : '6px 12px')};
    display: flex;
    align-items: center;
    cursor: default;
    &:hover {
        background-color: ${({ hoverColor }) => hoverColor};
    }

    &:nth-child(1) {
        margin-top: 4px;
    }
    &:nth-last-child(1) {
        margin-bottom: 4px;
    }
    &:hover ${StyledIcon} {
        background-color: ${({ theme, disabled, checked, checkedAndApplied }) =>
            checked && !disabled
                ? checkedAndApplied && theme.blue[600]
                : checked && disabled
                ? theme.blue[200]
                : theme.blue[600]};
    }
`;

export const Label = styled.label`
    margin-left: 8px;
    cursor: pointer;
    color: ${({ textColor }) => textColor};
    opacity: ${({ isTransparent }) => (isTransparent ? '0.5' : '1')};
    font-weight: ${({ isSelectedBold, checked }) => (isSelectedBold && checked ? '800' : 'normal')};
`;
