import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    img {
        opacity: ${({ exportDisabled }) => (exportDisabled ? '0.4' : '1')};
        cursor: ${({ exportDisabled }) => (exportDisabled ? 'not-allowed' : 'pointer')};
    }

    ${({ customStyle }) => customStyle}
`;
