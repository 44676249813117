import { combineReducers } from 'redux';
import {
    INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC,
    TABLE_REQUEST_CONFIRMIT_ASYNC,
    TABLE_REQUEST_DATA_UPLOAD_ASYNC,
    TABLE_REQUEST_METADATA_ASYNC,
    CACHING_STATUS,
} from './actions';

const tablesDataReducer = (state = {}, action) => {
    switch (action.type) {
        case INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_METADATA_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_DATA_UPLOAD_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_CONFIRMIT_ASYNC.actions.SUCCEEDED:
        case CACHING_STATUS.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export const backofficeReducer = combineReducers({
    tablesData: tablesDataReducer,
});
