import React from 'react';
import { IconWrapper, Title, ReportOptionWrapper } from './ReportOption.styles';

const ReportOption = ({ icon, color, bgColor, reportTitle, path, show }) => {
    return (
        show && (
            <ReportOptionWrapper to={path} color={color} bgColor={bgColor}>
                <IconWrapper>{icon}</IconWrapper>
                <Title color={color} bgColor={bgColor}>
                    <span>{reportTitle}</span>
                </Title>
            </ReportOptionWrapper>
        )
    );
};

export default ReportOption;
