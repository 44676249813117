import React from 'react';
import { PropTypes } from 'prop-types';
import SOVChart from './../SOVChart/SOVChart';
import Legend from './../../../../../../common/components/Legend/Legend';
import SectionInfo from './../../../../components/SectionInfo';
import SectionsHead from '../../../../../../common/components/SectionsHead/SectionsHead';
import * as SC from './../../CompetitiveMedia.styles';
import * as CEG from './CompetitorsEvolutionGroup.styles';
import { defaultTheme } from './../../../../../../utils/defaultTheme';
import { sectionsHeadColor } from '../../../../utils/utils';

export const extractLegendData = data => {
    const { bars, line } = data[0];

    const legendItems = [];
    const whiteColor = ['#FFF', '#fff', '#ffffff', '#FFFFFF', 'white'];

    Object.keys(bars).forEach(key =>
        legendItems.push({
            color: bars[key].color,
            name: key.toUpperCase(),
            borderColor: whiteColor.some(item => item === bars[key].color) ? defaultTheme.blue[900] : bars[key].color,
        })
    );
    legendItems.push({ color: line.color, name: 'YEAR' });

    return legendItems;
};

const legendStyle = {
    width: 40,
    height: 10,
    borderRadius: 0,
};

const CompetitorsEvolutionGroup = ({ competitorsEvolutionData }) => {
    const { sectionSideNote, sectionInfo, chartData } = competitorsEvolutionData || {};

    return (
        <SectionsHead
            title={chartData[0]?.sectionLabel?.toUpperCase()}
            isResponsive={true}
            customStyle={sectionsHeadColor}
        >
            <SC.SectionRow>
                <SC.SectionItems>
                    <SC.SectionSingularItem>
                        <CEG.SOVGroupWrapper>
                            {chartData.map(({ data, name, color }, index) => (
                                <CEG.SOVChartWrapper key={name + index}>
                                    <SOVChart data={data} brand={{ name, color }} />
                                    <Legend
                                        customStyle={{
                                            _wrapper: { marginLeft: '20px' },
                                            _individualItem: { marginRight: '20px', marginTop: 0 },
                                            _legendShape: [
                                                {
                                                    _legendIndex: 0,
                                                    _legendStyle: legendStyle,
                                                },
                                                {
                                                    _legendIndex: 1,
                                                    _legendStyle: legendStyle,
                                                },
                                            ],
                                        }}
                                        displayDirection={'row'}
                                        legendItems={extractLegendData(data)}
                                    />
                                </CEG.SOVChartWrapper>
                            ))}
                        </CEG.SOVGroupWrapper>
                    </SC.SectionSingularItem>
                </SC.SectionItems>
                <SC.SectionSingularItem>
                    <SectionInfo
                        customStyle={{
                            _bottomSection: { marginBottom: 65 },
                        }}
                        sectionData={sectionInfo}
                        sectionSideNote={sectionSideNote}
                    />
                </SC.SectionSingularItem>
            </SC.SectionRow>
        </SectionsHead>
    );
};

CompetitorsEvolutionGroup.propTypes = {
    competitorsEvolutionData: PropTypes.object.isRequired,
};

CompetitorsEvolutionGroup.defaultProps = {
    competitorsEvolutionData: null,
};

export default CompetitorsEvolutionGroup;
