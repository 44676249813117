import React from 'react';
import SearchInput from '../../../../../common/components/SearchInput/components/SearchInput';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../../../utils/translations';
import * as SC from '../../../userManagement.styles';

const customStyle = {
    textPadding: '10px',
    fontSize: '15px',
    bgColor: defaultTheme.blue[60],
    textColor: defaultTheme.blue[950],
    fontWeight: 'normal',
};

const CustomSearchInput = ({ value, onChange, onApply }) => {
    return (
        <SC.UserManagementSearchInputWrapper>
            <SearchInput
                customStyle={customStyle}
                placeholder={TRANSLATION_TEXT.SEARCH_BY_PROJECT_NAME_TEXT}
                onFocus={onApply}
                onBlur={onApply}
                onKeyUp={e => e.key === 'Enter' && onApply()}
                value={value}
                onChange={onChange}
            />
        </SC.UserManagementSearchInputWrapper>
    );
};

export default {
    customSearch: CustomSearchInput,
    searchableColumns: ['FirstName', 'LastName', 'Email'],
};
