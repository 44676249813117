import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../../../../common/components/Checkbox/Checkbox';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';
import * as SC_PA from '../../../PreferanceAnalysis.styles';
import * as SC_AW from './Awareness.styles';
import RankingTable from '../../../components/RankingTable/RankingTable';
import SimpleBarChartSection from '../../../components/SimpleBarChart/SimpleBarChart.section';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';
import CombinedBarLineChart from '../../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import LOBLegend from '../../../components/LOBLegend/LOBLegend';
import SectionsHead from '../../../../../../../common/components/SectionsHead/SectionsHead';
import EvolutionLegend from '../../../components/EvolutionLegend/EvolutionLegend';
import { useCombinedLOBS } from '../../hooks/useCombinedLOBS';
import { TRANSLATION_TEXT } from '../../../../../../../utils/translations';
import * as TabLayout from '../../Tabs.styles';
import NoDataAvailable from '../../../../../../../common/components/NoDataAvailable/NoDataAvailable';
import { capitalizeFirstLetter } from '../../../../../../../utils/generalUtilities';
import { sectionsHeadColor } from '../../utils/utils';
import TopLegend from './../../../../../../../common/components/TopLegend/TopLegend';

const AwarenessTab = ({
    combinedChartsData,
    awarenessData,
    awarenessSectionIDs,
    rankingTableData,
    lobsAsOptions,
    simpleBarChartData,
    getBptPreferenceAnalysisAwarenessActionCreator,
    hasTopFilterOptionLoaded,
    filtersThrownError,
    appliedFilters,
}) => {
    const [checkedSections, setCheckedSections] = useState([]);
    const [combinedChartLOBs, onLOBCheckboxChange, getVisibleLOBs] = useCombinedLOBS({
        sectionIDS: awarenessSectionIDs,
        lobsAsOptions,
    });

    const onCheckboxChange = sectionID => {
        const founded = checkedSections.includes(sectionID);
        if (!founded) {
            setCheckedSections([...checkedSections, sectionID]);
        } else {
            setCheckedSections(checkedSections.filter(elem => elem !== sectionID));
        }
    };
    const lobsForRankingTable = sectionID => lobsAsOptions && lobsAsOptions[sectionID][KPI_TYPES.RANKING];
    const awarenessDataLoaded = () =>
        awarenessData && awarenessData.hasOwnProperty('sections') && awarenessData.sections && !filtersThrownError;

    useEffect(() => {
        if (hasTopFilterOptionLoaded) {
            getBptPreferenceAnalysisAwarenessActionCreator();
        }
    }, [hasTopFilterOptionLoaded]);

    useEffect(() => {
        setCheckedSections(awarenessSectionIDs);
    }, [awarenessSectionIDs]);

    const hasSideFiltersApplied = appliedFilters && appliedFilters?.length > 0;

    return (
        <>
            {awarenessDataLoaded() && (
                <>
                    <SC_AW.SectionsBannerWrapper data-testid={'Awareness-Tab-Section-Toggle-Sections'}>
                        {awarenessData?.sections?.map(section => (
                            <Checkbox
                                key={section.sectionID}
                                value={checkedSections.includes(section.sectionID)}
                                label={capitalizeFirstLetter(section.sectionLabel)}
                                onClick={() => onCheckboxChange(section.sectionID)}
                                padding="6px 0px"
                                textColor={defaultTheme.blue[900]}
                            />
                        ))}
                    </SC_AW.SectionsBannerWrapper>
                    <TopLegend customStyle={{ _wrapper: { marginTop: '1.5rem' } }} />
                    {hasSideFiltersApplied && <EvolutionLegend />}
                </>
            )}

            <NoDataAvailable show={!awarenessDataLoaded() && !filtersThrownError} />

            {awarenessDataLoaded() &&
                awarenessData?.sections?.map(
                    ({ sectionID, sectionLabel }) =>
                        checkedSections.includes(sectionID) && (
                            <SectionsHead
                                key={sectionID}
                                title={sectionLabel}
                                isResponsive={false}
                                customStyle={sectionsHeadColor}
                            >
                                <TabLayout.SectionRow style={{ margin: '70px 0px 0px 0px' }}>
                                    <TabLayout.SectionItem data-testid={'Awareness-Tab-Section-Item-Simple-Charts'}>
                                        {simpleBarChartData[sectionID] && (
                                            <SimpleBarChartSection
                                                barChartDataYear={simpleBarChartData[sectionID].year}
                                                barChartDataPeriod={simpleBarChartData[sectionID].period}
                                                hasNumberOfRespondents={true}
                                            />
                                        )}
                                    </TabLayout.SectionItem>
                                    <TabLayout.SectionItem
                                        data-testid={'Awareness-Tab-Section-Item-No-Dropdown-Ranking'}
                                    >
                                        {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                            <RankingTable
                                                rankingData={[rankingTableData[sectionID][0]]}
                                                lobOptions={lobsForRankingTable(sectionID)}
                                                styleObject={{ margin: '-40px 0px 40px 20px' }}
                                                hasNumberOfRespondents={true}
                                                hasSideFiltersApplied={hasSideFiltersApplied}
                                            />
                                        )}
                                    </TabLayout.SectionItem>
                                </TabLayout.SectionRow>
                                <TabLayout.SectionRow>
                                    <TabLayout.SectionItem data-testid={'Awareness-Tab-Section-Item-Combined-Charts'}>
                                        {combinedChartsData && combinedChartLOBs ? (
                                            <SC_PA.LOBAreaWrapper>
                                                <SC_PA.CombinedChartsWrapper>
                                                    {combinedChartsData[sectionID]?.map(
                                                        d =>
                                                            d.lineData.length > 0 &&
                                                            Object.keys(d.barData).length > 0 && (
                                                                <CombinedBarLineChart
                                                                    key={sectionID + d.chartTitle}
                                                                    visibleLOBs={getVisibleLOBs(sectionID)}
                                                                    lineData={d.lineData}
                                                                    barData={d.barData}
                                                                    customLineTooltip={{
                                                                        tooltipFontColor: defaultTheme.blue[900],
                                                                    }}
                                                                />
                                                            )
                                                    )}
                                                </SC_PA.CombinedChartsWrapper>
                                                {combinedChartLOBs[sectionID][KPI_TYPES.COMBINED].length > 0 ? (
                                                    <LOBLegend
                                                        LOBs={combinedChartLOBs[sectionID][KPI_TYPES.COMBINED]}
                                                        sectionID={sectionID}
                                                        onChangeHandler={onLOBCheckboxChange}
                                                    />
                                                ) : (
                                                    <SC_PA.NoDataText>
                                                        {TRANSLATION_TEXT.NO_DATA_AVAILABLE_TEXT}
                                                    </SC_PA.NoDataText>
                                                )}
                                            </SC_PA.LOBAreaWrapper>
                                        ) : null}
                                    </TabLayout.SectionItem>
                                    <TabLayout.SectionItem data-testid={'Awareness-Tab-Section-Item-Dropdown-Ranking'}>
                                        {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                            <RankingTable
                                                noTitle={true}
                                                rankingData={[rankingTableData[sectionID][1]]}
                                                lobOptions={lobsForRankingTable(sectionID)}
                                                styleObject={{ margin: '0px 0px 40px 20px' }}
                                                hasNumberOfRespondents={true}
                                                hasSideFiltersApplied={hasSideFiltersApplied}
                                            />
                                        )}
                                    </TabLayout.SectionItem>
                                </TabLayout.SectionRow>
                            </SectionsHead>
                        )
                )}
        </>
    );
};

export default AwarenessTab;
