import { connect } from "react-redux";
import SelectedFilters from "./SelectedFilters";
import { getSelectedFiltersComputedSelector } from '../data/computedSelectors';
import { clearAllSelectedFiltersActionCreator , clearSingleSelectedFilterActionCreator } from '../data/actions';

const mapStateToProps = (state) => ({
    appliedFilters: getSelectedFiltersComputedSelector(state)
});

const mapDispatchToProps = {    
    clearAllSelectedFiltersActionCreator,
    clearSingleSelectedFilterActionCreator
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilters);
