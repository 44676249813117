import styled from 'styled-components';
import { defaultTheme } from '../../../../utils/defaultTheme';

export const UseContent = styled.div`
    padding: 36px;
    display: block;
    align-items: center;
    flex-direction: row;

    @media (max-width: 1350px) {
        flex-direction: column;
    }
`;

export const MenuHeader = styled.div`
    background-color: ${({ theme }) => theme.white.ff};
    box-shadow: 0px 3px 8px ${({ theme }) => theme.black[29]};
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding: 25px 3px;

    @media (max-width: 1350px) {
        margin: 0;
        margin-bottom: 20px;
    }
    > a {
        text-decoration: none;
    }
`;

export const MenuItem = styled.button`
    display: inline-flex;
    border: none;
    background-color: ${({ theme }) => theme.white.ff};

    &:hover,
    &.isActive {
        > p {
            text-shadow: 0 0 0.09px ${({ theme }) => theme.blue[950]}, 0 0 0.09px ${({ theme }) => theme.blue[950]},
                0 0 0.09px ${({ theme }) => theme.blue[950]};
        }
    }

    @media (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const MenuItemText = styled.p`
    color: ${({ theme }) => theme.blue[900]};
    display: inline-block;
    margin-left: 7px;
    cursor: pointer;
    font-size: 16px;
    font-family: system-ui;
`;
export const MenuItemImage = styled.img`
    cursor: pointer;
    @media (max-width: 1350px) {
        max-width: 100%;
    }
`;
export const UseThisToolVideo = styled.video`
    width: 100%;
    height: 450px;
    border: none;
`;
export const UseThisToolImage = styled.img`
    width: 100%;
    border: none;
`;
export const UseThisToolWrapper = styled.div`
    position: relative;
`;
export const CenteredAbsolute = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 170px;
    height: 40px;
    text-align: center;
    color: ${defaultTheme.blue[900]};
    font-weight: 600;
    padding-top: 10px;
    user-select: none;
    font-size: 20px;
`;
export const UseThisToolDoc = styled.iframe`
    width: 100%;
    height: 450px;
    border: none;
`;
export const ToolBoxInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 50px 50px 50px;
    @media screen and (max-width: 1093px) {
        padding: 40px 10px 50px 10px;
    }
`;
export const LinkToDocument = styled.span`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.blue[950]};
    margin-right: 30px;
    margin-left: 30px;
    cursor: pointer;

    &:visited {
        color: ${({ theme }) => theme.blue[950]};
    }
    &:hover {
        text-shadow: 0 0 0.09px ${({ theme }) => theme.blue[950]}, 0 0 0.09px ${({ theme }) => theme.blue[950]},
            0 0 0.09px ${({ theme }) => theme.blue[950]};
        > img {
            transform: translateY(-2px);
        }
    }
    img {
        padding-right: 10px;
        transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 250ms;
    }
`;

export const ToolBoxOuterWrapper = styled.div`
    border: solid 1px ${({ theme }) => theme.blue[300]};
    border-top: solid 6px ${({ theme }) => theme.blue[300]};
    margin-top: 50px;
    text-align: center;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    h1 {
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 20px;
        color: ${({ theme }) => theme.blue[950]};
    }
`;
