import React from 'react';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import { Title, Subtitle, ButtonAsLink } from '../login.styles';
import { pathnamesPagesMatcher } from '../utils/pageConfig';

const LinkExpired = ({ goToForgotPassword }) => {
    const locationFromWindowPathname = window.location.pathname;
    const pathnamePageMatch = pathnamesPagesMatcher(locationFromWindowPathname);
    return (
        <>
            <Title>{TRANSLATION_TEXT.LINK_EXPIRED_TITLE_TEXT[pathnamePageMatch]}</Title>
            <Subtitle>
                {TRANSLATION_TEXT.LINK_EXPIRED_MESSAGE_TEXT[pathnamePageMatch]}
                <span>
                    <ButtonAsLink onClick={goToForgotPassword}>
                        {TRANSLATION_TEXT.LINK_EXPIRED_SUBMIT_TEXT}{' '}
                    </ButtonAsLink>
                    {TRANSLATION_TEXT.LINK_EXPIRED_ATTENTION_TEXT[pathnamePageMatch]}
                </span>
            </Subtitle>
        </>
    );
};

export default LinkExpired;
