import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_AD_TESTER_AD_COMPARISON_DATA = '/pages/adTester/subpages/overview/GET_AD_TESTER_AD_COMPARISON_DATA';
export const GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC = asyncActionCreator(
    '/pages/adTester/subpages/overview/GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC'
);

export const getAdComparisonActionCreator = () => ({
    type: GET_AD_TESTER_AD_COMPARISON_DATA,
});
