import React, { useState } from 'react';
import * as SC from './Tooltip.styles';
import PropTypes from 'prop-types';
const Tooltip = ({ tooltipText, children, customStyle }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    let _timer;
    let _delay = 500;

    const handleMouseEnter = () => {
        _timer = setTimeout(() => {
            setShowTooltip(true);
        }, _delay);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        clearTimeout(_timer);
    };

    return (
        <SC.Wrapper onMouseEnter={handleMouseEnter} customStyle={customStyle?._wrapper} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && <SC.TooltipContent customStyle={customStyle?._content}>{tooltipText}</SC.TooltipContent>}
        </SC.Wrapper>
    );
};
Tooltip.propTypes = {
    tooltipText: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    customStyle: PropTypes.object,
};
export default Tooltip;
