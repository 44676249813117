import React, { useMemo, useRef } from 'react';
import { GraphTitle, GraphTitleWrapper, LineGraphWrapper } from './LineGraph.styles';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import { GROUP_TYPE, HOVER_MODE, LineChart } from '@toluna-ui-toolkit/linechart';
import moment from 'moment';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { calculateForResolution } from '../../../../../utils/responsiveness';

const HEIGHT = 400;

const customStyle = {
    lines: {
        width: 2.4,
    },
    points: {
        radius: 2,
        valueColor: defaultTheme.black.ff,
        valueFontWeight: 600,
        valueFontSize: '12px',
    },
    grid: {
        width: 0.5,
        color: defaultTheme.grey['300'],
    },
    container: {
        background: defaultTheme.grey['50'],
        border: `1px dashed ${defaultTheme.blue[950]}`,
    },
};

/* Custom hook to gather screen sizes */

const LineGraph = ({ data, options = [] }) => {
    let chartWrapperRef = useRef(null);
    const [width] = useWindowSize();

    const { kpiLabel, items, kpiID } = data;

    const processedData = useMemo(() => {
        return items.map(d => {
            return {
                name: d.itemLabel.replace(/[^a-zA-Z0-9]/g, '') + '-kpiID-' + kpiID,
                visible: options.includes(d.itemLabel),
                color: d.values[0].color,
                values: d.values.map(v => ({ value: v.value, group: v.label })),
            };
        });
    }, [data, options]);
    const WIDTH = useMemo(() => {
        return calculateForResolution(width);
    }, [width]);

    return (
        <LineGraphWrapper>
            <div ref={chartWrapperRef}>
                <GraphTitleWrapper>
                    <GraphTitle>{kpiLabel}</GraphTitle>
                </GraphTitleWrapper>
                <LineChart
                    height={HEIGHT}
                    width={WIDTH}
                    data={processedData}
                    hoverMode={HOVER_MODE.LINES}
                    groupType={GROUP_TYPE.ORDINAL}
                    tickFormatX={d => moment(d).format('MMM Y')}
                    tickFormatY={d => d + '%'}
                    styling={customStyle}
                />
            </div>
        </LineGraphWrapper>
    );
};

export default LineGraph;
