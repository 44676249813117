import styled from 'styled-components';

export const SectionRow = styled.div`
    display: flex;
    justify-content: center;
`;

export const SectionItem = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`;
