import styled from 'styled-components';
import { defaultTheme } from '../../../utils/defaultTheme';

export const HeaderTooltipCellWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const HeaderTooltipWrapper = styled.div`
    position: absolute;
    min-height: 30px;
    min-width: 160px;
    background: ${defaultTheme.white.ff};
    justify-content: center;
    visibility: hidden;
    align-items: center;
    border-radius: 2px;
    text-align: center;
    display: flex;
    z-index: 10;
    box-shadow: 0px 2px 3px #0000004d;
    padding: 9px 7px;
    left: 50%;
    top: -10px;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: ease-in opacity 0.2s;

    &:after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        border-top: 5px solid #ffffff;
        border-right: 5px solid transparent;
        border-bottom: 0px solid transparent;
        border-left: 5px solid transparent;
        top: 99%;
        right: 50%;
    }
`;

export const TooltipHover = styled.span`
    font-weight: 800;
    &:hover {
        & + div {
            opacity: 1;
            visibility: visible;
        }
    }
`;
