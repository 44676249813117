import styled from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../../../../../utils/responsiveness';

const EvolutionLegendWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    column-gap: 15px;
    row-gap: 5px;
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        flex-direction: column;
    }
`;

const EvolutionLegendMetricInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const EvolutionLegendMetricValueWrapper = styled.div`
    text-align: center;
    width: 30px;
    padding: 2px 4px;
    border-radius: 50px;
    height: 15px;
`;

const EvolutionLegendMetricValueWrapperGreen = styled(EvolutionLegendMetricValueWrapper)`
    border: solid 1px ${({ theme }) => theme.green[300]};
    background-color: ${({ theme }) => theme.green[60]};
    color: ${({ theme }) => theme.green[500]};
`;

const EvolutionLegendMetricValueWrapperRed = styled(EvolutionLegendMetricValueWrapper)`
    border: 1px solid ${({ theme }) => theme.red[350]};
    background-color: ${({ theme }) => theme.red[70]};
    color: ${({ theme }) => theme.red[600]};
`;

const EvolutionLegendText = styled.span`
    font-size: 14px;
`;

export {
    EvolutionLegendWrapper,
    EvolutionLegendMetricInfoWrapper,
    EvolutionLegendMetricValueWrapperGreen,
    EvolutionLegendMetricValueWrapperRed,
    EvolutionLegendText,
};
