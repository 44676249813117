import { combineReducers } from 'redux';
import {
    INIT_APPLIED_SIDE_FILTERS,
    INIT_SELECTED_SIDE_FILTERS,
    INIT_SIDE_FILTERS_SECTION,
    SET_SIDE_FILTER_PAGE,
    STORE_APPLY_FILTERS,
    STORE_CLEAR_FILTERS,
    STORE_TOGGLE_SIDE_FILTERS_SECTION,
    STORE_UPDATE_SIDE_FILTERS,
} from './actions';

export const selectedSideFiltersReducer = (state = {}, action) => {
    switch (action.type) {
        case INIT_SELECTED_SIDE_FILTERS:
        case STORE_UPDATE_SIDE_FILTERS:
        case STORE_CLEAR_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const currentPageNameReducer = (state = '', action) => {
    switch (action.type) {
        case SET_SIDE_FILTER_PAGE:
            return action.payload;
        default:
            return state;
    }
};

export const appliedSideFiltersReducer = (state = {}, action) => {
    switch (action.type) {
        case INIT_APPLIED_SIDE_FILTERS:
        case STORE_APPLY_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const sideFiltersSectionUiReducer = (state = {}, action) => {
    switch (action.type) {
        case INIT_SIDE_FILTERS_SECTION:
        case STORE_TOGGLE_SIDE_FILTERS_SECTION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const sideFiltersDataReducer = combineReducers({
    selectedSideFilters: selectedSideFiltersReducer,
    appliedSideFilters: appliedSideFiltersReducer,
    sideFiltersSectionUi: sideFiltersSectionUiReducer,
    currentPageName: currentPageNameReducer,
});
