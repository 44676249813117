import React, { useEffect } from 'react';
import {
    FieldworkTitle,
    OverviewPageWrapper,
    WarningInfoText,
} from '../../../adTester/subpages/Overview/components/overview.styles';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import FieldworkOverviewTableContainer from './components/FieldworkOverviewTable.container';
import ToggleSectionContainer from './components/ToggleSection.container';
import WorldMapContainer from '../../../../common/components/WorldMap/component/WorldMap.container';
import CountriesDropdownContainer from '../../../../common/components/CountriesDropdown/components/CountriesDropdown.container';
import { TRANSLATION_TEXT } from '../../../../utils/translations';

const Overview = ({ getFieldworkOverviewData, overviewData, countries }) => {
    useEffect(() => {
        getFieldworkOverviewData();
    }, [getFieldworkOverviewData]);

    return (
        <OverviewPageWrapper>
            <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT} customStyle={{ _wrapper: { top: '70px' } }}>
                <ExportButton type={EXPORT_TYPES.EXCEL} />
            </ExportButtonContainer>
            <CountriesDropdownContainer customStyle={{ _wrapper: { top: '70px' } }} countries={countries} />

            <WorldMapContainer overviewData={overviewData} countries={countries} />

            <FieldworkTitle style={{ display: 'none' }}>Overview</FieldworkTitle>
            <ToggleSectionContainer />

            <WarningInfoText>
                <strong style={{ marginRight: '5px' }}>{TRANSLATION_TEXT.WARNING}</strong>
                {TRANSLATION_TEXT.SURVEY_FIELDWORK_INFO}
            </WarningInfoText>

            <FieldworkOverviewTableContainer />
        </OverviewPageWrapper>
    );
};

export default Overview;
