import { connect } from 'react-redux';
import { getExportLinkSelector } from '../ExportButton/data/selectors';
import ConfirmationModal from './ConfirmationModal';
import { clearExportLinkActionCreator } from '../ExportButton/data/actions';

const mapStateToProps = state => ({
    exportLink: getExportLinkSelector(state),
});

const mapDispatchToProps = {
    clearExportLinkActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
