import styled from 'styled-components';
import { dropdownLegendTemplate } from '../RankingTable/RankingTable.styles';
import { defaultTheme } from '../../../../../../utils/defaultTheme';

const LegendWrapper = styled.div`
    border-radius: 3px;
    box-shadow: 0px 0px 8px #00000029;
    min-height: 50px;
    font-size: 14px;
    margin: 23px 0px 0px 0;
    align-items: center;
    justify-content: space-evenly;
    ${({ styleObject }) => styleObject};
`;

const OptionGroupWrapper = styled.div`
    padding: 3px 10px 9px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-evenly;
    ${({ styleObject }) => styleObject};
`;

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    flex-basis: calc(100% / 8);

    label {
        display: block;
        max-width: ${props => (props.labelWidth ? props.labelWidth : '80px')};
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: ${props => props.theme.blue[900]};
    }
`;

const LegendLine = styled.div`
    margin-bottom: 0px;
    line-height: 10px;
    ${dropdownLegendTemplate};
`;

const GlobalOptionsWrapper = styled.div`
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid ${defaultTheme.grey['200']};
`;

export { LegendWrapper, OptionWrapper, LegendLine, OptionGroupWrapper, GlobalOptionsWrapper };
