import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MetricsHeaderWrapper = styled.div`
    width: 100%;
    background-color: ${({ color, theme }) => color || theme.red[100]};
    height: 41px;
    display: flex;
    align-items: center;
`;

const MetricsHeaderTitle = styled.h3`
    color: ${({ textColor, theme }) => textColor || theme.orange[700]};
    font-weight: 700;
    margin: 0 0 0 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DataSectionHeader = ({ title, children, color, textColor }) => {
    return (
        <div data-cy={'cy-data-section-header-wrapper'}>
            <MetricsHeaderWrapper color={color}>
                <MetricsHeaderTitle data-cy="cy-data-section-header-title" textColor={textColor}>
                    {title}
                </MetricsHeaderTitle>
            </MetricsHeaderWrapper>
            <div style={{ width: '100%' }} data-cy={'cy-data-section-children'}>
                {children}
            </div>
        </div>
    );
};

DataSectionHeader.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    color: PropTypes.string,
    textColor: PropTypes.string,
};

export default DataSectionHeader;
