import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../common/components/Checkbox/Checkbox';
import TopLegend from './../../../../common/components/TopLegend/TopLegend';
import { capitalizeFirstLetter } from '../../../../utils/generalUtilities';
import { defaultTheme } from '../../../../utils/defaultTheme';
import * as SC from './TransversalView.styles';
import { useFilters } from '../../../../common/hooks/useFilters';
import TopFilterGroup from '../../../../common/components/TopFilter/components/TopFilterGroup.container';
import { DISPLAY_BUTTON_POSITION, TOP_FILTER_CARD_SIZE } from '../../../../common/components/TopFilter/utils/constants';
import NoDataAvailable from '../../../../common/components/NoDataAvailable/NoDataAvailable';
import DisplayMode from './components/DisplayMode/DisplayMode';
import { DISPLAY_MODE } from '../../utils/utils';
import DataSectionHeader from '../../../../common/components/DataSectionHeader/DataSectionHeader';
import CountryGroup from '../../components/CountryGroup/CountryGroup';
import { TransversalSectionsWrapper, TransversalSectionsWrapperCompact } from './TransversalView.styles';
import ErrorBoundary from '../../../../common/components/ErrorBoundary/ErrorBoundary';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import ViewAllMetrics from './components/ViewAllMetrics/ViewAllMetrics';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from './../../../../common/components/ExportButton/components/ExportButton';
import { TRANSVERSAL_VIEW_PAGE } from '../../../../utils/routes';

const TransversalView = ({
    infoText,
    data,
    filtersThrownError,
    hasTopFilterOptionLoaded,
    topFiltersRequestSuccessful,
    getData,
    isGlobalLoading,
    onTransversalSectionToggle,
    transversalViewCheckedSections,
    filtersFor,
}) => {
    useFilters();
    useEffect(() => {
        if (hasTopFilterOptionLoaded && topFiltersRequestSuccessful && filtersFor === TRANSVERSAL_VIEW_PAGE.path) {
            getData();
        }
    }, [hasTopFilterOptionLoaded, topFiltersRequestSuccessful, filtersFor]);

    const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.ALL);
    const transversalViewDataLoaded = () => data && data.length > 0 && !filtersThrownError && !isGlobalLoading;

    const renderSection = (section, sectionDisplayMode) => {
        return (
            <>
                {section &&
                    section.countries.map(country => (
                        <ErrorBoundary
                            text={TRANSLATION_TEXT.ERROR_BOUNDARY_MESSAGE}
                            key={'error-boundary' + section.sectionLabel + country.countryData.countryName}
                            customStyle={{
                                background: defaultTheme.red[1],
                                borderRadius: '4px',
                            }}
                        >
                            <CountryGroup
                                key={section.sectionLabel + country.countryData.countryName}
                                displayMode={sectionDisplayMode}
                                countryData={country.countryData}
                                rankingData={country.overall}
                                overallQuarterData={country.overallQuarter}
                                overallAdRecall={country.overallAdRecall}
                                overallYearData={country.overallYear}
                                trendData={country.trend}
                                hasLegend={section.sectionId === 4} // Image Section with ID of 4 has a legend attached to it
                                sectionLabel={section.sectionLabel}
                            />
                        </ErrorBoundary>
                    ))}
            </>
        );
    };
    return (
        <>
            {!filtersThrownError ? (
                <>
                    <div style={{ marginBottom: '90px' }}>
                        <TopFilterGroup
                            displayButtonPosition={DISPLAY_BUTTON_POSITION.BOTTOM}
                            disableAllSearchBars={true}
                            cardSize={TOP_FILTER_CARD_SIZE.SMALL}
                        />
                        <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                            <ExportButton type={EXPORT_TYPES.PDF} text={TRANSLATION_TEXT.EXPORT_TEXT} />
                        </ExportButtonContainer>
                    </div>
                    {transversalViewDataLoaded() && (
                        <SC.NoStyleElement data-testid="TransversalViewBannerWrapper">
                            <SC.SectionsBannerWrapper data-cy={'CY-SectionsToggler'}>
                                {data &&
                                    data?.map(section => (
                                        <Checkbox
                                            key={section.sectionId}
                                            value={transversalViewCheckedSections.includes(section.sectionId)}
                                            label={capitalizeFirstLetter(section.sectionLabel)}
                                            onClick={() => onTransversalSectionToggle(section.sectionId)}
                                            padding="6px 0px"
                                            textColor={defaultTheme.blue[900]}
                                        />
                                    ))}
                            </SC.SectionsBannerWrapper>

                            <TopLegend customStyle={{ _wrapper: { marginTop: '1.5rem' } }} />
                            <DisplayMode
                                displayMode={displayMode}
                                passDisplayMode={setDisplayMode}
                                widthBreakpoint={1300}
                            />
                            <SC.InfoText className="infoText">{infoText}</SC.InfoText>
                        </SC.NoStyleElement>
                    )}
                    <SC.NoStyleElement data-testid="TransversalViewSectionsWrapper">
                        {transversalViewDataLoaded() &&
                            data.map(
                                section =>
                                    transversalViewCheckedSections.includes(section.sectionId) && (
                                        <DataSectionHeader
                                            key={'section-header' + section.sectionId}
                                            title={section.sectionLabel}
                                            color={defaultTheme.brown[100]}
                                            textColor={defaultTheme.brown[400]}
                                        >
                                            <ViewAllMetrics data={section.countries} />
                                            {displayMode === DISPLAY_MODE.ALL ? (
                                                <TransversalSectionsWrapper
                                                    key={'transversal-section-wrapper' + section.sectionLabel}
                                                >
                                                    {renderSection(section, displayMode)}
                                                </TransversalSectionsWrapper>
                                            ) : (
                                                <TransversalSectionsWrapperCompact
                                                    key={'transversal-section-wrapper-compact' + section.sectionLabel}
                                                >
                                                    {renderSection(section, displayMode)}
                                                </TransversalSectionsWrapperCompact>
                                            )}
                                        </DataSectionHeader>
                                    )
                            )}
                    </SC.NoStyleElement>
                </>
            ) : (
                <NoDataAvailable show={filtersThrownError} />
            )}
        </>
    );
};

export default TransversalView;
