import styled from 'styled-components';

export const ErrorNotificationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 500px;
    height: 100px;
    box-sizing: border-box;
    border-radius: 8px;
    background: ${({ theme }) => theme.white.ff};
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    position: fixed;
    bottom: 30px;
    right: ${props => (props.show ? '10px' : '-1000px')};
    z-index: 10001;

    transition: right 0.5s ease, box-shadow 0.2s ease-in-out;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
        background: ${({ theme }) => theme.white.ff};
    }
`;

export const ErrorNotificationBar = styled.div`
    height: 70px;
    width: 7px;
    background: ${({ theme }) => theme.red[600]};
    margin: 0px 5px 0px 4px;
    border-radius: 14px;
`;

export const ErrorTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: flex-end;

    h4 {
        margin: 0;
        font-size: 22px;
        color: ${({ theme }) => theme.grey[800]};
    }

    p {
        margin: 0;
        color: gray;
        font-size: 18px;
    }
`;

export const ErrorIcon = styled.div`
    color: ${({ theme }) => theme.red[500]};
    font-size: 38px;
    line-height: 1.2;
    margin: 0px 10px 0px 10px;
`;

export const ErrorCloseButton = styled.div`
    color: ${({ theme }) => theme.grey[400]};
    font-size: 18px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    &:hover {
        opacity: 0.7;
    }
`;
