import React from 'react';
import * as SC from './EvolutionLegend.styles';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';

const EvolutionLegend = () => {
    return (
        <SC.EvolutionLegendWrapper>
            <SC.EvolutionLegendText>{TRANSLATION_TEXT.BPT_EVOLUTION_LEGEND_DESCRIPTION}</SC.EvolutionLegendText>
            <SC.EvolutionLegendMetricInfoWrapper>
                <SC.EvolutionLegendMetricValueWrapperGreen />
                <SC.EvolutionLegendText>
                    {TRANSLATION_TEXT.BPT_EVOLUTION_LEGEND_SIGNIFICANTLY_HIGHER}
                </SC.EvolutionLegendText>
            </SC.EvolutionLegendMetricInfoWrapper>
            <SC.EvolutionLegendMetricInfoWrapper>
                <SC.EvolutionLegendMetricValueWrapperRed />
                <SC.EvolutionLegendText>
                    {TRANSLATION_TEXT.BPT_EVOLUTION_LEGEND_SIGNIFICANTLY_LOWER}
                </SC.EvolutionLegendText>
            </SC.EvolutionLegendMetricInfoWrapper>
        </SC.EvolutionLegendWrapper>
    );
};

export default EvolutionLegend;
