import React , {useRef} from 'react';
import PropTypes from 'prop-types';
import { Scrollbar } from 'react-scrollbars-custom';
import { mergeDeep } from './themes/utils';
import { THEMES , THEMES_STYLES } from './constants';
import {wrapperProps, scrollerProps, trackYProps, thumbYProps} from './ScrollPanel.components';
import {ScrollbarWrapper, scrollBarStyles, shadowTopStyles, shadowBottomStyles} from './ScrollPanel.style';



// export const ScrollPanel = (props) => { 
export const ScrollPanel = React.forwardRef((props, forwardRef) => { 
    
    const { children, height, maxHeight, width, customStyle, forceVisibleY, wrapperStyles, wrappercClassName, withShadow, floatBar } = props;
    
    // const scrollableNodeRef = useRef(null);
    const shadowTopRef = useRef(null);
    const shadowBottomRef = useRef(null);
    
    const combinedTheme={...mergeDeep(THEMES[THEMES_STYLES.DEFAULT],customStyle)};

    const scrollProps = { 
        ref: forwardRef,
        wrapperProps: wrapperProps(combinedTheme,floatBar),
        scrollerProps: scrollerProps(),
        trackYProps: trackYProps(combinedTheme),
        thumbYProps: thumbYProps(combinedTheme),
        permanentTrackY :forceVisibleY
    }

    if (maxHeight || (!height && maxHeight)){
        scrollProps.translateContentSizeYToHolder = true
    }

    // if (floatBar) {
    //     scrollProps.disableTrackYWidthCompensation = true;
    // }

    const handleUpdateWithShadow = (e) => {
        const per = (e.scrollTop/(e.scrollHeight - e.clientHeight)*100);
        const topOpacity = per === 0 ? 0 : Math.max(0.4,per/100) ;
        const bottomOpacity = e.scrollHeight > e.clientHeight && per !== 100 ? (Math.max(0.4,1 - topOpacity)) : 0;
        shadowTopRef.current.style.opacity = topOpacity;
        shadowBottomRef.current.style.opacity = bottomOpacity;
    };
    if (withShadow) {
        scrollProps.onUpdate = handleUpdateWithShadow;
    }

    return (
        <ScrollbarWrapper className={'tff-scroll-panel-wrapper' + ( wrappercClassName ? (' '+wrappercClassName) : '')} 
        wrapperStyles={wrapperStyles} height={height} maxHeight={maxHeight} width={width} >
            { withShadow && <div style={shadowTopStyles} ref={shadowTopRef}/> } 
            <Scrollbar {...scrollProps} style={scrollBarStyles(height,maxHeight)} >
            {/* {typeof children === 'function' ? children({scrollBarRef:scrollBarRef.current}) : children} */}
                {children}
            </Scrollbar>
            { withShadow && <div style={shadowBottomStyles} ref={shadowBottomRef}/> } 
         </ScrollbarWrapper>
    )
});

ScrollPanel.propTypes = {
    /** Children */
  children: PropTypes.node.isRequired
}

ScrollPanel.defaultProps = {
    // placement : 'top',
    // popperWrapperStyles: {}
}