import axios from 'axios';
import { loadState } from './localStorage.service';
import { getPropertyValueOrDefault } from '../utils/generalUtilities';
import { refreshTokenCall } from './login.service';
import { TOKEN_STORAGE_KEY } from './localStorage.service';

export const basePath = (() => {
    let src;
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            src = 'https://dbhubbkkm6uku.cloudfront.net';
            break;
        case 'qa':
            src = 'https://dbhubbkkm6uku.cloudfront.net';
            break;
        case 'prod':
            src = 'https://d1jnh6afu6gmo2.cloudfront.net';
            break;
        case 'dev':
            src = 'https://d9b88lp8becxt.cloudfront.net';
            break;
        default:
            src = 'https://dbhubbkkm6uku.cloudfront.net';
    }
    return src;
})();

export function createAxiosInstance(hasToken) {
    const axiosInstance = axios.create();
    axiosInstance.defaults.timeout = 1000 * 60 * 10;

    switch (process.env.REACT_APP_ENV) {
        case 'local':
            axiosInstance.defaults.baseURL = 'https://localhost:44348/api';
            break;
        case 'qa':
            axiosInstance.defaults.baseURL = 'https://axa.qa.bptinsights.com/api';
            break;
        case 'prod':
            axiosInstance.defaults.baseURL = 'https://www.bptinsights.com/api';
            break;
        case 'dev':
            axiosInstance.defaults.baseURL = 'http://axa.custdashdev.tolunainsights-internal.com/api';
            break;
        default:
            axiosInstance.defaults.baseURL = 'https://localhost:44348/api';
    }

    axiosInstance.interceptors.request.use(
        config => {
            config.headers['Content-Type'] = 'application/json';
            return config;
        },
        error => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        /* @@ Request is for the user management in the case of an expired token */
        /* Normaly we dispatch the action of refreshing a token from SAGA, UM doesn't have the posibility of doing that, as being a separate module*/
        async function (error) {
            const originalRequest = error.config;
            const requestParams = originalRequest.url.split('/');
            const isUserManagementRequest = USER_MANAGEMENT_REQUESTS.includes(requestParams[requestParams.length - 1]);

            if (isUserManagementRequest && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const localStorage = loadState();
                const tokens = getPropertyValueOrDefault(localStorage, 'tokens', {});

                let data = new URLSearchParams();
                data.append('grant_type', 'refresh_token');
                data.append('refresh_token', tokens.refresh_token);

                await refreshTokenCall(tokens.refresh_token).then(token => {
                    saveTokens(token);
                });

                return axiosInstance(originalRequest);
            }
            return Promise.reject(error);
        }
    );

    if (hasToken) {
        /* @@ Add Authorization Token to the request */
        axiosInstance.interceptors.request.use(
            config => {
                const localStorage = loadState();
                const tokens = getPropertyValueOrDefault(localStorage, 'tokens', {});

                if (tokens && tokens.access_token) {
                    config.headers.authorization = `Bearer ${tokens.access_token}`;
                }

                return config;
            },
            error => Promise.reject(error)
        );
    }
    return axiosInstance;
}

export function request(call) {
    return call()
        .then(response => response.data)
        .catch(error => Promise.reject(error));
}
export function requestWithPayload(call, payload) {
    return call(payload)
        .then(response => response.data)
        .catch(error => Promise.reject(error));
}
export function requestResponseObject(call, payload) {
    return call(payload)
        .then(response => response)
        .catch(error => Promise.reject(error));
}

const saveTokens = tokens => {
    const serializedState = localStorage.getItem(TOKEN_STORAGE_KEY + '_state');
    if (serializedState === null) {
        return undefined;
    }
    const parsedState = JSON.parse(serializedState);
    localStorage.setItem(TOKEN_STORAGE_KEY + '_state', JSON.stringify({ ...parsedState, tokens: tokens.data.json }));
};

export const USER_MANAGEMENT_REQUESTS = [
    'SendInvite',
    'AddUserAndSendInvite',
    'AddUser',
    'DeleteUser',
    'UpdateUser',
    'GetUserDetails',
    'GetPermissions',
    'GetAllUsers',
];
//For testing various situations where the access and refresh token is no longer valid
function TokenActions() {
    if (process.env.REACT_APP_ENV === 'prod') return;

    const mockJWT =
        'eyAgImV4cCI6IDE2MzQzMTU5NTF9.eyAgImV4cCI6IDE2MzQzMTU5NTF9.eyAgImV4cCI6IDE2MzQzMTU5NTF9-eyAgImV4cCI6IDE2MzQzMTU5NTF9-eyAgImV4cCI6IDE2MzQzMTU5NTF9-eyAgImV4cCI6IDE2MzQzMTU5NTF9';
    let localStorageData;
    const saveToken = () => {
        localStorage.setItem(TOKEN_STORAGE_KEY + '_state', JSON.stringify(localStorageData));
    };
    const loadToken = () => {
        localStorageData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY + '_state'));
    };
    const invalidateTokens = () => {
        loadToken();
        localStorageData.tokens.access_token = mockJWT; //base64 encoded object to simulate an real jwt token.
        localStorageData.tokens.refresh_token = 'thereIsNoRefreshToken';
        saveToken();
    };
    const backInTime = hasJWT => {
        loadToken();
        localStorageData.tokens.access_token = hasJWT ? hasJWT : mockJWT; //base64 encoded object to simulate an real jwt token.
        saveToken();
    };
    return {
        invalidateTokens,
        backInTime,
    };
}

window.tokenActions = new TokenActions();
