import styled from '@emotion/styled';

export const StyledTextArea = styled.textarea`
    width: 100%;
    resize: none;
    margin: 0;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid ${props => (props.error ? `#e57a7a` : `#cccccc`)};
    color: #2d3643;
    background-color: ${props => (props.error ? `#ffe3e3` : `#ffffff`)};
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
    outline: none;
    font-family: MuseoSans-300;
    font-size: 15px;
    box-sizing: border-box;
    transition: ${props => (props.customStyle?.resize ? '' : 'all 0.2s')};

    :focus {
        border: 1px solid ${props => (props.error ? `#e57a7a` : props.theme.lightColor)};
    }

    ::placeholder {
        color: ${props => (props.error ? `#2d3643` : `#b3c3da`)};
    }

    :disabled {
        color: #7e8389;
        box-shadow: none;
        background-color: #f7f9fa;
    }

    ${props => props.customStyle || ''}
`;
