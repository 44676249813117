import { connect } from 'react-redux';
import { globalOverviewSelectedCountriesSaveActionCreator } from '../../../data/actions';
import { getGlobalOverviewSelectedCountriesSelector } from '../../../data/selectors';
import CountriesDropdown from './CountriesDropdown';

const mapStateToProps = state => ({
    selectedCountries: getGlobalOverviewSelectedCountriesSelector(state),
});
const mapDispatchToProps = {
    onApply: globalOverviewSelectedCountriesSaveActionCreator,
};

const CountriesDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(CountriesDropdown);
export default CountriesDropdownContainer;
