import React from 'react';
import { ButtonWrapper } from './ExportButton.styles';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { basePath } from '../../../../services/utils';
export const EXPORT_TYPES = {
    EXCEL: 'EXCEL',
    PPT: 'PPT',
    PDF: 'PDF',
};

const ExportButton = ({ type, getExportLinkActionCreator, exportDisabled, customStyle }) => {
    const location = useLocation();

    let icon;
    switch (type) {
        case EXPORT_TYPES.EXCEL:
            icon = `${basePath}/visuals/XLSX_export_icon.svg`;
            break;
        case EXPORT_TYPES.PPT:
            icon = `${basePath}/visuals/PPT_export_icon.svg`;
            break;
        case EXPORT_TYPES.PDF:
            icon = `${basePath}/visuals/PDF_export_icon.svg`;
            break;
        default:
            break;
    }
    return (
        <ButtonWrapper
            customStyle={customStyle?._wrapper}
            onClick={() =>
                !exportDisabled &&
                getExportLinkActionCreator({ path: location.pathname, exportTypeOverwrite: type.toLowerCase() })
            }
            exportDisabled={exportDisabled}
        >
            <img src={icon} alt={`Export Button ${type}`} />
        </ButtonWrapper>
    );
};
ExportButton.propTypes = {
    type: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
    getExportLinkActionCreator: PropTypes.func.isRequired,
    customStyle: PropTypes.object,
};
export default ExportButton;
