import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    initSelectedSideFiltersActionCreator,
    UPDATE_SIDE_FILTERS,
    storeUpdateSideFiltersActionCreator,
    CLEAR_FILTERS,
    storeApplyFiltersActionCreator,
    storeClearFiltersActionCreator,
    initAppliedSideFiltersActionCreator,
    initSideFiltersSectionActionCreator,
    TOGGLE_SIDE_FILTERS_SECTION,
    storeToggleSideFiltersSectionActionCreator,
    INIT_SIDE_FILTERS,
    APPLY_SIDE_FILTERS,
} from './actions';
import { initializeSideFilters, initializeSideFiltersSectionUI } from '../utils/utils';
import { INPUT_TYPES } from '../utils/sideFilters.config';
import {
    getAppliedSideFiltersSelector,
    getCurrentFilterPageSelector,
    getSelectedSideFiltersSelector,
    getSideFilterSectionUiSelector,
    getSideFiltersSelector,
} from './selectors';
import { applyFiltersActionCreator } from '../../../data/actions';

function* getFiltersSaga(action) {
    const filterData = action.payload;
    // selected side filter initialization
    const filtersFromLocalStorage = yield select(getAppliedSideFiltersSelector);
    const currentPageName = yield select(getCurrentFilterPageSelector);
    const { selectedSideFilters, appliedSideFilters } = yield call(
        initializeSideFilters,
        ...[filterData, filtersFromLocalStorage, currentPageName]
    );
    yield put(
        initSelectedSideFiltersActionCreator({
            [currentPageName]: selectedSideFilters,
        })
    );
    yield put(
        initAppliedSideFiltersActionCreator({
            [currentPageName]: appliedSideFilters,
        })
    );
    const sectionUiFromLocalStorage = yield select(getSideFilterSectionUiSelector);
    const initialSideFiltersSectionUI = yield call(
        initializeSideFiltersSectionUI,
        ...[appliedSideFilters, currentPageName, sectionUiFromLocalStorage]
    );
    yield put(
        initSideFiltersSectionActionCreator({
            [currentPageName]: initialSideFiltersSectionUI,
        })
    );
}

function* sideFiltersUpdate(action) {
    const { min, max, filterType, pageName, groupName, filterId, selectedId } = action.payload;
    const selectedSideFilters = yield select(getSelectedSideFiltersSelector);
    const sideFilters = yield select(getSideFiltersSelector);

    const filterToUpdate = selectedSideFilters[pageName][groupName][filterId];
    const sideFilterOptions = sideFilters[groupName][filterId].options;

    switch (filterType) {
        case INPUT_TYPES.MULTI_CHECKBOX:
            // if the modified checkbox is in the selected array remove it else add it to the array
            //here we need to check if undefined because in special case this could return 0 value which is evaluate as false which is wrong
            if (undefined !== filterToUpdate?.selected.find(el => el === selectedId)) {
                selectedSideFilters[pageName][groupName][filterId].selected = filterToUpdate.selected.filter(
                    el => el !== selectedId
                );
            } else {
                selectedSideFilters[pageName][groupName][filterId].selected.push(selectedId);
            }
            break;
        case INPUT_TYPES.MULTI_CHECKBOX_SELECT_ALL:
            // if the select all is clicked and all the checkboxes are checked deselect all
            // else iterate through and select all of them
            if (filterToUpdate.optionsCount === filterToUpdate.selected.length) {
                selectedSideFilters[pageName][groupName][filterId].selected = [];
            } else {
                selectedSideFilters[pageName][groupName][filterId].selected = [];
                for (const key in sideFilterOptions) {
                    if (Object.hasOwnProperty.call(sideFilterOptions, key)) {
                        const { id } = sideFilterOptions[key];
                        selectedSideFilters[pageName][groupName][filterId].selected.push(id);
                    }
                }
            }
            break;
        case INPUT_TYPES.SLIDER:
            selectedSideFilters[pageName][groupName][filterId].selected = [min, max];
            break;
        default:
            break;
    }
    yield put(storeUpdateSideFiltersActionCreator(selectedSideFilters));
}

function* applyFiltersSaga() {
    try {
        const selectedSideFilters = yield select(getSelectedSideFiltersSelector);
        const currentPageName = yield select(getCurrentFilterPageSelector);
        const proccessedSelectedSideFilters = {};
        const selectedPageFilters = selectedSideFilters[currentPageName];

        // Iterate through each main filter group on the current page (eg: Profile)
        for (const groupName in selectedPageFilters) {
            if (Object.hasOwnProperty.call(selectedPageFilters, groupName)) {
                const groupFilters = selectedPageFilters[groupName];

                // iterate through each selected filter and generate the applied filters object
                for (const filterId in groupFilters) {
                    if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                        const filter = groupFilters[filterId];
                        proccessedSelectedSideFilters[currentPageName] = {
                            ...proccessedSelectedSideFilters[currentPageName],
                            [groupName]: {
                                ...proccessedSelectedSideFilters[currentPageName]?.[groupName],
                                [filterId]: [...filter.selected],
                            },
                        };
                    }
                }
            }
        }

        yield put(storeApplyFiltersActionCreator(proccessedSelectedSideFilters));
    } catch (error) {}
}

function* clearFiltersSaga() {
    const sideFilters = yield select(getSideFiltersSelector);
    const selectedSideFilters = yield select(getSelectedSideFiltersSelector);
    const currentPageName = yield select(getCurrentFilterPageSelector);
    const selectedPageFilters = selectedSideFilters[currentPageName];

    const processedRestoredFilters = {};

    // Iterate through each main filter group on the current page (eg: Profile)
    for (const groupName in selectedPageFilters) {
        if (Object.hasOwnProperty.call(selectedPageFilters, groupName)) {
            const groupFilters = selectedPageFilters[groupName];

            // iterate through each selected filter and generate the restored initial filter values
            for (const filterId in groupFilters) {
                if (Object.hasOwnProperty.call(groupFilters, filterId)) {
                    const filter = groupFilters[filterId];

                    const newValue = [];
                    if (sideFilters[groupName][filterId].selectType === INPUT_TYPES.SLIDER) {
                        sideFilters[groupName][filterId].options.forEach(option => {
                            newValue.push(+option.label);
                        });
                    }

                    processedRestoredFilters[currentPageName] = {
                        ...processedRestoredFilters[currentPageName],
                        [groupName]: {
                            ...processedRestoredFilters[currentPageName]?.[groupName],
                            [filterId]: {
                                ...filter,
                                selected: newValue,
                            },
                        },
                    };
                }
            }
        }
    }
    yield put(storeClearFiltersActionCreator(processedRestoredFilters));
    yield put(applyFiltersActionCreator({ reloadFilters: false, resetSideFiltersIfCountryChanged: true }));
}

function* sideFiltersToggle(action) {
    const { filterId, groupName, pageName, isSubsection, isSingleSectionSelection } = action.payload;
    const sectionUI = yield select(getSideFilterSectionUiSelector);
    const updatedSectionUI = {};

    if (isSubsection) {
        updatedSectionUI[pageName] = {
            ...sectionUI[pageName],
            [groupName]: {
                ...sectionUI[pageName][groupName],
                [filterId]: {
                    isOpen: !sectionUI[pageName][groupName][filterId].isOpen,
                },
            },
        };
    } else {
        updatedSectionUI[pageName] = {
            ...sectionUI[pageName],
            [groupName]: {
                ...sectionUI[pageName][groupName],
                isOpen: !sectionUI[pageName][groupName].isOpen,
            },
        };

        if (isSingleSectionSelection) {
            Object.keys(updatedSectionUI[pageName]).forEach(key => {
                const group = updatedSectionUI[pageName][key];
                if (key !== groupName) {
                    group.isOpen = false;
                }
            });
        }
    }

    yield put(storeToggleSideFiltersSectionActionCreator(updatedSectionUI));
}

export default function* filtersSaga() {
    yield all([
        takeEvery(INIT_SIDE_FILTERS, getFiltersSaga),
        takeEvery(UPDATE_SIDE_FILTERS, sideFiltersUpdate),
        takeLatest(APPLY_SIDE_FILTERS, applyFiltersSaga),
        takeLatest(CLEAR_FILTERS, clearFiltersSaga),
        takeEvery(TOGGLE_SIDE_FILTERS_SECTION, sideFiltersToggle),
    ]);
}
