import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LineGraph from './LineGraph';
import LineGraphOptions from './LineGraphOptions';
import { getLineChartsDataSelector } from '../data/selectors';
import { getLineChartOptionsComputedSelector } from '../data/computedSelectors';

export const LineGraphGroupProvider = ({ sectionData, lineChartOptions }) => {
    const [localOptions, setLocalOptions] = useState(lineChartOptions);

    const optionsAsArray = localOptions.filter(e => e.checked && e.name).map(e => e.name);

    const onOptionChange = optionId => {
        const newOptions = localOptions.map(oldOption => {
            if (optionId === oldOption.id) {
                return { ...oldOption, checked: !oldOption.checked };
            }
            return { ...oldOption };
        });
        setLocalOptions(newOptions);
    };

    useEffect(() => {
        setLocalOptions(lineChartOptions);
    }, [lineChartOptions]);

    return (
        <div data-testid="LineGraphGroup">
            <LineGraphOptions options={localOptions} onOptionChange={onOptionChange} />
            {sectionData &&
                sectionData.kpis.map(kpi => (
                    <LineGraph key={kpi.kpiLabel + '-graph'} data={kpi} options={optionsAsArray} />
                ))}
        </div>
    );
};

const mapStateToProps = state => ({
    sectionData: getLineChartsDataSelector(state),
    lineChartOptions: getLineChartOptionsComputedSelector(state),
});

export default connect(mapStateToProps, null)(LineGraphGroupProvider);
