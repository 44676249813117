import styled from 'styled-components';

const ExportButtonContainerWrapper = styled.div`
    position: absolute;
    top: 118px;
    right: 29px;
    width: 118px;
    height: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.white.ff};
    color: ${({ theme }) => theme.blue[900]};

    span {
        margin-bottom: 0.5rem;
    }

    @media (max-width: 1400px) {
        right: 5px;
    }

    @media (max-width: 1200px) {
        width: auto;
        right: 29px;
    }

    @media (max-width: 1100px) {
        right: 15px;

        span {
            display: none;
        }
    }

    ${({ customStyle }) => customStyle}
`;

const ExportIconsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 1200px) {
        flex-direction: column;

        & * {
            margin-bottom: 0.25rem;
        }
    }
`;

const ExportButtonUserManagementWrapper = styled.button`
    border: ${({ theme }) => `1px solid ${theme.blue[900]}`};
    background-color: ${({ theme }) => theme.white.ff};
    color: ${({ theme }) => theme.blue[900]};
    position: absolute;
    top: 92px;
    right: 2.45%;
    width: 145px;
    padding: 0 12px 0 10px;
    height: 42px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.grey[50]};
    }
`;

export { ExportButtonContainerWrapper, ExportIconsWrapper, ExportButtonUserManagementWrapper };
