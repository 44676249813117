import { combineReducers } from 'redux';
import { loginPages } from '../utils/pageConfig';
import { CHANGE_LOGIN_PAGE, REFRESH_TOKEN_ASYNC_GROUP, SET_EMAIL_RECIPIENT, HAS_ON_LOGIN_ERROR } from './actions';
import { LOGIN_ACTION_GROUP, SET_LOGGED_USER_DETAILS, SET_TOKEN } from './actions';
import { RESET_PASSWORD_PATHNAME } from '../utils/pageConfig';

export const authTokensReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_ACTION_GROUP.actions.SUCCEEDED:
        case SET_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

const userInformationReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_LOGGED_USER_DETAILS:
            return action.payload;
        default:
            return state;
    }
};

// Have to set the initial state like this in order to avoid the flickering of the UI
// Because the normal path needs the Login page initially, but the Reset Password path
// shall only display the branding while the token is checked
const currentLoginPageInitialState = window.location.pathname === RESET_PASSWORD_PATHNAME ? null : loginPages.LOGIN;
const currentLoginPageReducer = (state = currentLoginPageInitialState, action) => {
    switch (action.type) {
        case CHANGE_LOGIN_PAGE:
            return action.payload;
        default:
            return state;
    }
};

const emailRecipientReducer = (state = '', action) => {
    switch (action.type) {
        case SET_EMAIL_RECIPIENT:
            return action.payload;
        default:
            return state;
    }
};

const onLoginErrorReducer = (state = false, action) => {
    switch (action.type) {
        case HAS_ON_LOGIN_ERROR:
            return action.payload;
        default:
            return state;
    }
};

export const pendingRefreshTokenReducer = (state = false, action) => {
    switch (action.type) {
        case REFRESH_TOKEN_ASYNC_GROUP.actions.STARTED:
            return true;
        case REFRESH_TOKEN_ASYNC_GROUP.actions.SUCCEEDED:
        case REFRESH_TOKEN_ASYNC_GROUP.actions.FAILED:
            return false;
        default:
            return state;
    }
};

export const loginReducer = combineReducers({
    currentLoginPage: currentLoginPageReducer,
    emailRecipient: emailRecipientReducer,
    onLoginError: onLoginErrorReducer,
});

export const authenticationReducer = combineReducers({
    tokens: authTokensReducer,
    userInformation: userInformationReducer,
    isPendingTokenRefreshing: pendingRefreshTokenReducer,
});
