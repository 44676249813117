export const BAR_TYPES = {
    SIMPLE: 'simpleHorizontalBarChart',
    STACKED: 'stackedHorizontalBarChart',
};

export const BAR_CATEGORY = {
    NORMAL: 'normal',
    FILTERED: 'filtered',
    MAIN: 'main',
    AVERAGE: 'average',
};

export const RENDER_LOW_BASE_LEGEND = {
    SECTION_ID: 2,
};
