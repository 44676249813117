import styled, { css } from 'styled-components';
//*** Ranking Table */

const mediaQueryCommonMethod = (maxWidth, gridTemplateColumn) => css`
    @media only screen and (max-width: ${maxWidth}) {
        grid-template-columns: ${gridTemplateColumn};
    }
`;

export const Wrapper = styled.div`
    width: 550px;
    ${({ styleObject }) => styleObject};
    color: ${({ theme }) => theme.blue[950]};

    @media only screen and (max-width: 1520px) {
        width: 450px;
    }
    @media only screen and (max-width: 1200px) {
        width: 400px;
    }
    @media only screen and (max-width: 1090px) {
        width: 380px;
    }
`;

export const Col = styled.div`
    display: flex;
    flex: 1;
    height: auto;
    min-height: 25px;
    position: relative;
    align-items: center;
    font-weight: ${({ bold }) => bold && '600'};
    & .barChartLabels {
        text-anchor: end;
    }
`;

export const Row = styled.div`
    width: 100%;
    display: grid;
    ${({ hasSideFiltersApplied }) =>
        hasSideFiltersApplied
            ? css`
                  grid-template-columns: 90px auto 35% 15%;
                  ${mediaQueryCommonMethod('1520px', '80px auto 40% 15%')};
                  ${mediaQueryCommonMethod('1200px', '70px auto 45% 15%')};
                  ${mediaQueryCommonMethod('1090px', '65px auto 45% 15%')};
              `
            : css`
                  grid-template-columns: 75px auto 40% 20px;
                  ${mediaQueryCommonMethod('1520px', '75px auto 40% 20px')};
                  ${mediaQueryCommonMethod('1200px', '75px auto 40% 30px')};
                  ${mediaQueryCommonMethod('1090px', '75px auto 40% 35px')};
              `}
    grid-template-rows: 30px;
    grid-template-areas: 'rankingRowValue brandRowValue barAndValue metricBadge';
    &:hover {
        background-color: ${({ theme }) => theme.grey[50]};
        cursor: pointer;
    }
    ${Col} {
        &:nth-child(1) {
            grid-area: rankingRowValue;
            justify-content: center;
        }
        &:nth-child(2) {
            grid-area: brandRowValue;
        }
        &:nth-child(3) {
            grid-area: barAndValue;
            justify-content: center;
        }
        &:nth-child(4) {
            grid-area: metricBadge;
            justify-content: center;
        }
    }
    ${({ hasSideFiltersApplied, isAverage }) =>
        isAverage &&
        css`
            grid-template-rows: 50px;
            grid-template-columns: 0px auto 50% ${hasSideFiltersApplied ? 'auto' : '0px'};
            ${mediaQueryCommonMethod('1520px', `0px auto 50% ${hasSideFiltersApplied ? 'auto' : '0px'}`)};
            ${mediaQueryCommonMethod('1200px', `0px auto 50% ${hasSideFiltersApplied ? 'auto' : '0px'}`)};
            ${mediaQueryCommonMethod('1090px', `0px auto 50% ${hasSideFiltersApplied ? 'auto' : '0px'}`)};
            gap: ${hasSideFiltersApplied ? '15px' : '30px'};
            width: ${hasSideFiltersApplied ? '83%' : '75%'};
            box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
            border-radius: 3px;
            margin: 15px auto 0;
            ${Col} {
                &:last-child {
                    margin-right: ${hasSideFiltersApplied ? '15px' : '0px'};
                }
            }
        `}
`;

export const Header = styled.div`
    display: grid;
    ${({ hasSideFiltersApplied }) =>
        hasSideFiltersApplied
            ? css`
                  grid-template-columns: 90px auto 30% 15%;
                  ${mediaQueryCommonMethod('1520px', '80px auto 35% 15%')};
                  ${mediaQueryCommonMethod('1200px', '70px auto 45% 10%')};
                  ${mediaQueryCommonMethod('1090px', '65px auto 45% 10%')};
              `
            : css`
                  grid-template-columns: 75px auto 30% 35px;
                  ${mediaQueryCommonMethod('1520px', '75px auto 30% 30px')};
                  ${mediaQueryCommonMethod('1200px', '75px auto 40% 20px')};
                  ${mediaQueryCommonMethod('1090px', '75px auto 40% 20px')};
              `}
    grid-template-rows: 40px;
    grid-template-areas: 'ranking emptySpace dropDown';
    margin-bottom: 10px;
    ${Col} {
        &:nth-child(1) {
            grid-area: ranking;
            justify-content: center;
            background-color: ${({ theme }) => theme.grey[50]};
        }
        &:nth-child(2) {
            grid-area: emptySpace;
        }
        &:nth-child(3) {
            grid-area: dropDown;
            justify-content: center;
            background-color: ${({ theme, isDynamicTable }) => !isDynamicTable && theme.grey[50]};
        }
        &:nth-child(4) {
            grid-area: emptySpace;
        }
    }
`;

export const RankType = styled.img`
    width: 16px;
    margin-left: 5px;
    object-fit: contain;
`;

export const RankWrapper = styled.div`
    display: flex;
    width: ${props => (props.isTopTen ? '25px' : '40px')};
    align-items: center;
`;

export const LabelWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const RankArrow = styled.img`
    width: 12px;
    margin-right: 3px;
    object-fit: contain;
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ isDynamicTable }) => !isDynamicTable && '20px'};
    font-weight: 600;
    font-size: 16px;

    ${({ customTitleStyle }) => customTitleStyle};
`;
//*** */
//----------------------------//
//*** Ranking Table Dropdown */
export const dropdownLegendTemplate = css`
    span {
        background-color: ${({ legendColor }) => legendColor};
        width: 20px;
        height: 4px;
        border-radius: 10px;
        display: inline-block;
        margin: 0 8px 0 0;
    }
`;
export const DropdownWrapper = styled.div`
    position: relative;
    width: 195px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;
export const DropdownHeader = styled.div`
    background-color: ${({ theme }) => theme.white.ff};
    ${({ isOpen }) =>
        isOpen
            ? css`
                  z-index: 15;
                  box-shadow: 0px 0px 6px ${({ theme }) => theme.black[29]};
                  border-bottom: 1px solid ${({ theme }) => theme.grey[200]};
              `
            : css`
                  border: 1px solid ${({ theme }) => theme.blue[900]};
              `}

    width: 175px;
    height: 39px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${dropdownLegendTemplate};
    div {
        display: flex;
        align-items: center;
        margin-left: 14px;
    }

    img {
        right: 17px;
        position: absolute;
    }
`;

export const DropdownMenu = styled.div`
    position: relative;
`;

export const DropdownItemsWrapper = styled.ul`
    position: absolute;
    background-color: ${({ theme }) => theme.white.ff};
    box-shadow: 0px 0px 6px ${({ theme }) => theme.black[29]};
    margin: 0;
    padding: 0;
    z-index: 10;
    list-style: none;
    width: 100%;
    top: 0.5px;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 10px;
`;

export const DropdownItem = styled.li`
    ${dropdownLegendTemplate};
    display: flex;
    align-items: center;
    padding-left: 14px;
    height: 35px;
    &:hover {
        background-color: ${({ theme }) => theme.grey[50]};
        cursor: pointer;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const EvolutionMetric = styled.div`
    text-align: center;
    min-width: 30px;
    padding: 2px 4px;
    border: solid 1px ${({ colorScheme }) => colorScheme.border};
    background: ${({ colorScheme }) => colorScheme.background};
    border-radius: 50px;
    color: ${({ colorScheme }) => colorScheme.textColor};
    font-weight: 600;
    font-size: 12px;
`;
