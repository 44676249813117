import React from 'react';
import { PropTypes } from 'prop-types';
import Legend from './../../../../../../common/components/Legend/Legend';
import SectionInfo from './../../../../components/SectionInfo';
import SectionsHead from '../../../../../../common/components/SectionsHead/SectionsHead';
import * as SC from './../../CompetitiveMedia.styles';
import { calculateWidthMediaMixStackedBarSection } from './../../../../../../utils/responsiveness';
import CombinedStackedBarLineChart from '../../../../../../common/components/CombinedStackedBarLineChart/CombinedStackedBarLineChart';
import { sectionsHeadColor } from '../../../../utils/utils';

const MediaMixEvolutionGroup = ({ mediaMixEvolutionData }) => {
    const { sectionSideNote, sectionInfo, sectionLabel, chartsData } = mediaMixEvolutionData || {};

    return (
        <SectionsHead title={sectionLabel?.toUpperCase()} isResponsive={true} customStyle={sectionsHeadColor}>
            <SC.SectionRow>
                <SC.SectionItems>
                    {chartsData.map((chartGroupArray, index) => (
                        <SC.MediaMixGroupWrapper
                            style={{ marginRight: `${index === 0 ? '5px' : '0px'}` }}
                            key={`group-${index}`}
                        >
                            <SC.ChartTitle>{chartGroupArray?.title}</SC.ChartTitle>
                            <CombinedStackedBarLineChart
                                barData={chartGroupArray?.chart}
                                lineData={[]}
                                color={chartGroupArray?.nameColorGroup}
                                customHeight={410}
                                normalizedValues
                                customBarPadding={0.45}
                                customWidthOnPage={calculateWidthMediaMixStackedBarSection}
                                dataMaxDomain={1}
                                bypassLOBFiltering
                                customTicks={{
                                    barChart: {
                                        ticksNumber: 5,
                                        ticksFormat: '.0%',
                                    },
                                }}
                            />
                            <Legend
                                customStyle={{
                                    _wrapper: { marginLeft: '20px' },
                                    _individualItem: {
                                        marginRight: '14px',
                                        marginTop: 0,
                                        fontSize: '14px',
                                        fontWeight: '600',
                                    },
                                    _legendShape: chartGroupArray?.nameColorGroup.map((c, i) => ({
                                        _legendIndex: i,
                                        _legendStyle: {
                                            display: 'inline-block',
                                            wordBreak: 'keep-all',
                                            width: 14,
                                            height: 14,
                                            minWidth: 14,
                                            marginRight: '6px',
                                            borderRadius: 0,
                                        },
                                    })),
                                }}
                                displayDirection={'row'}
                                legendItems={chartGroupArray?.nameColorGroup}
                            />
                        </SC.MediaMixGroupWrapper>
                    ))}
                </SC.SectionItems>
                <SC.SectionSingularItem>
                    <SectionInfo
                        customStyle={{
                            _topSection: { marginTop: 25 },
                            _bottomSection: { marginBottom: 35 },
                        }}
                        sectionData={sectionInfo}
                        sectionSideNote={sectionSideNote}
                    />
                </SC.SectionSingularItem>
            </SC.SectionRow>
        </SectionsHead>
    );
};

MediaMixEvolutionGroup.propTypes = {
    mediaMixEvolutionData: PropTypes.object.isRequired,
};

MediaMixEvolutionGroup.defaultProps = {
    mediaMixEvolutionData: null,
};

export default MediaMixEvolutionGroup;
