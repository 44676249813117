import { connect } from 'react-redux';
import { hasOnLoginErrorActionCreator, loginInitActionCreator } from '../data/actions';
import { getOnLoginErrorSelector } from '../data/selectors';
import Login from './Login';

const mapStateToProps = state => ({
    onLoginError: getOnLoginErrorSelector(state),
});

const mapDispatchToProps = {
    onLogin: loginInitActionCreator,
    hasOnLoginError: hasOnLoginErrorActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
