import { createAxiosInstance, requestResponseObject } from './utils';

const axiosInstance = createAxiosInstance(false);

const forgotPasswordEmail = email => axiosInstance.post(`User/ResetPassword`, { email });
export const sendForgotPasswordEmail = email => requestResponseObject(forgotPasswordEmail, email);

const resetPassword = ({ token, password }) => axiosInstance.post(`User/UpdatePassword`, { token, password });
export const submitResetPassword = resetData => requestResponseObject(resetPassword, resetData);

const isResetTokenValid = token => axiosInstance.post(`User/IsResetTokenValid`, { token });
export const checkIsResetTokenValid = token => requestResponseObject(isResetTokenValid, token);

const loginUser = userInfo => {
    let data = {
        email: userInfo.username,
        password: userInfo.password,
    };
    return axiosInstance.post(`User/auth`, data);
};
export const onLoginSubmit = userInfo => requestResponseObject(loginUser, userInfo);

export const refreshTokenCall = token => {
    let data = {
        refreshToken: token,
    };
    return axiosInstance.post(`User/Token`, data);
};
export const onRefreshTokenCall = token => requestResponseObject(refreshTokenCall, token);
