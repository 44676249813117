import React, { useState } from 'react';
import { connect } from 'react-redux';
import { OverviewImage } from './overview.styles';
import {
    setSelectedTopOptionActionCreator,
    setTemporarySelectedTopOptionActionCreator,
} from '../../../../../common/components/TopFilter/data/actions';
import { ADTESTER_PAGE } from '../../../../../utils/routes';
import { push } from 'connected-react-router';
import { getTopFiltersInitStatusSelector } from '../../../../../common/components/TopFilter/data/selectors';
import { onViewAdClickActionCreator } from '../data/actions';
import { basePath } from '../../../../../services/utils';

const COPY_NAME_GROUP_ID = 127;

export const ViewIndividualAdButton = ({
    data,
    onAdClick,
    setAdOption,
    onPreloadAdClick,
    changePage,
    topFiltersInitialized,
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const onAdButtonClick = () => {
        const { copy_responseoption_id, Country, year_id } = data;

        const objectToSend = {
            groupId: COPY_NAME_GROUP_ID,
            id: +copy_responseoption_id,
            group: 'Copy name',
            selectType: 'single',
            country: Country,
            year_id: +year_id,
        };

        /* If top filters have been previously initialized just select the AdTester option,
            otherwise store the option temporarily and then use it in the initializing process later after the filter request has finished
        */
        if (!topFiltersInitialized) {
            onPreloadAdClick(objectToSend);
            changePage(ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path);
        } else {
            /* Set the ad as current option, change the page and then also trigger selecting the other filter groups */
            setAdOption({ key: ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path, value: [{ ...objectToSend }] });
            changePage(ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path);
            onAdClick(objectToSend);
        }
    };
    return (
        <OverviewImage
            src={
                isHovering
                    ? `${basePath}/visuals/View%20individual%20add_hover.png`
                    : `${basePath}/visuals/View%20individual%20add_off.png`
            }
            cursor="pointer"
            width="42px"
            margin="0px auto"
            onClick={onAdButtonClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        />
    );
};

const mapStateToProps = state => ({
    topFiltersInitialized: getTopFiltersInitStatusSelector(state),
});

const mapDispatchToProps = {
    onAdClick: onViewAdClickActionCreator,
    setAdOption: setSelectedTopOptionActionCreator,
    onPreloadAdClick: setTemporarySelectedTopOptionActionCreator,
    changePage: push,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewIndividualAdButton);
