import React from 'react';
import * as SC from '../../common/General.styles';
import CompetitiveMediaContainer from './subpages/CompetitiveMedia/CompetitiveMedia.container';

export default function CompetitiveMediaPage() {
    return (
        <SC.PageMainWrapper>
            <SC.PageWrapper>
                <div>
                    <CompetitiveMediaContainer />
                </div>
            </SC.PageWrapper>
        </SC.PageMainWrapper>
    );
}
