import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_TOOLBOX_URL, GET_TOOLBOX_URL_ASYNC } from './actions';
import { addAsyncErrorActionCreator } from '../../../errorHandling/data/actions';
import { getToolBoxSecuredUrlRequest } from '../../../services/apiServer.service';

function* getToolBoxUrlSaga(action) {
    try {
        yield put(GET_TOOLBOX_URL_ASYNC.dispatchers.started());

        if (action.payload) {
            const url = yield call(getToolBoxSecuredUrlRequest, action.payload);
            if (url) {
                window.open(url, '_blank').focus();
            }
        }

        yield put(GET_TOOLBOX_URL_ASYNC.dispatchers.succeeded());
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        yield put(GET_TOOLBOX_URL_ASYNC.dispatchers.failed(error));
    }
}

export default function* loginPageSaga() {
    yield all([takeLatest(GET_TOOLBOX_URL, getToolBoxUrlSaga)]);
}
