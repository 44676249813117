import React, { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import ReusableModal from '../ReusableModal/ReusableModal';
import PropTypes from 'prop-types';
const ConfirmationModal = ({ exportLink, clearExportLinkActionCreator }) => {
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => setIsOpen(true);
    const hideModal = () => setIsOpen(false);
    const downloadLink = () => {
        window.open(exportLink, '_blank', 'noopener,noreferrer');
        clearExportLinkActionCreator();
    };

    const modalRef = useRef();
    useOnClickOutside(modalRef, clearExportLinkActionCreator);

    useEffect(() => {
        exportLink ? showModal() : hideModal();
    }, [exportLink]);

    const customStyle = {
        marginTopText: '50px',
        textMargin: '60px 20px',
        buttonAlign: 'space-around',
        primaryButtonWidth: '130px',
        secondaryButtonWidth: '130px',
        closeButtonTopPosition: '25px',
        closeButtonRightPosition: '35px',
    };

    return (
        <>
            {isOpen ? (
                <ReusableModal
                    modalWrapperRef={modalRef}
                    yesText={TRANSLATION_TEXT.YES_TEXT}
                    cancelText={TRANSLATION_TEXT.CANCEL_TEXT}
                    text={TRANSLATION_TEXT.DOWNLOAD_CONFIRM_TEXT}
                    isAbleToDismiss={true}
                    title={TRANSLATION_TEXT.EXPORT_READY_TEXT}
                    onClose={clearExportLinkActionCreator}
                    onApply={downloadLink}
                    modalWidth={'850px'}
                    modalHeight={'350px'}
                    customStyle={customStyle}
                />
            ) : null}
        </>
    );
};
ConfirmationModal.propTypes = {
    exportLink: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
    clearExportLinkActionCreator: PropTypes.func.isRequired,
};

export default ConfirmationModal;
