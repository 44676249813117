import { all, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import {
    tokenExpiredActionCreator,
    ADD_ASYNC_ERROR,
    SET_NOTIFICATION_ERROR_DISPLAY,
    setErrorNotificationDisplayActionCreator,
} from './actions';
import { setTokenActionCreator } from '../../pages/login/data/actions';
import { getAuthTokensSelector } from '../../pages/login/data/selectors';
const ACCESS_TOKEN_EXPIRE_CODE = 401;

const NOTIFICATION_SHOW_ERRORS = [400, 404, 500, 501, 502, 503, 504, 505, 506, 511];

export function* centralizedAsyncErrorHandle(action) {
    const { error, retryAction, disableNotification } = action.payload;

    let status = null;

    if (error.hasOwnProperty('response') && error?.response) {
        status = error.response.status;
    }

    const tokens = yield select(getAuthTokensSelector);

    //If tokens exists it means we are logged in
    const isAuthenticated = Object.keys(tokens).length > 0;

    if (NOTIFICATION_SHOW_ERRORS.includes(status) || status === null) {
        if (disableNotification) return;
        yield put(setErrorNotificationDisplayActionCreator(true));
    }

    //Handle the token expiration error code here
    if (status === ACCESS_TOKEN_EXPIRE_CODE && retryAction && isAuthenticated) {
        const usedTokenInRequest = error.config.headers.authorization;
        const oldToken = usedTokenInRequest ? usedTokenInRequest.split(' ')[1] : '';
        const currentToken = tokens.access_token;
        /*
          In case a request A has failed with expiredToken but in the meantime request B managed to refresh the token, but A is still aware only of the old one
          We don't need to re-trigger another refresh token, just re-try the action because the token had refreshed in the meantime
        */
        if (oldToken !== currentToken && oldToken !== '') {
            yield put(setTokenActionCreator(tokens));
            yield put(retryAction);
        } else {
            yield put(tokenExpiredActionCreator(retryAction));
        }
    }
}

function* handleErrorNotificationSaga(action) {
    const status = action.payload;

    if (status) {
        yield delay(5500);
        yield put(setErrorNotificationDisplayActionCreator(false));
    }
}

export default function* saga() {
    yield all([
        takeEvery(ADD_ASYNC_ERROR, centralizedAsyncErrorHandle),
        takeLatest(SET_NOTIFICATION_ERROR_DISPLAY, handleErrorNotificationSaga),
    ]);
}
