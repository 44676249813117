import styled from 'styled-components';

export const ShowAllContainer = styled.div`
    width: fit-content;
    margin: 20px auto;
    background: #f6f9fe 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 2px 3px #00000029;
    border-radius: 3px 3px 3px 3px;
    opacity: ${props => (props.isDisable ? '1' : '0.5')};
    cursor: ${props => (props.isDisable ? 'pointer' : '')};
`;

export const ToggleButton = styled.button`
    background: ${props => (!props.isDisabled ? ' #00008F 0% 0% no-repeat padding-box' : 'transparent')};
    box-shadow: ${props => (!props.isDisabled ? '0px 0px 6px #00000063' : '')};
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    color: ${props => (!props.isDisabled ? '#F6F9FE' : '#243B5F')};
    width: 130px;
    height: 40px;
    font-size: 14px;
    border: none;
    cursor: ${props => (props.cursorStatus ? 'no-drop' : 'pointer')};
`;
