import React, { useState } from 'react';
import * as SC from './ViewAllMetrics.style';
import { PropTypes } from 'prop-types';
import { ButtonEvolutionMetrics, DataWrapper, CountryHeader, ElementContainer } from './ViewAllMetrics.style';
import CountryTrend from '../../../../components/CountryTrend/CountryTrend';
import { basePath } from '../../../../../../services/utils';
import { TRANSLATION_TEXT } from './../../../../../../utils/translations';

const ViewAllMetrics = props => {
    const { data } = props;
    const [show, setShow] = useState(false);
    return (
        <>
            <SC.Container>
                {data && (
                    <>
                        <ButtonEvolutionMetrics onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
                            <img src={`${basePath}/visuals/Eye-icon-transversal-view.svg`} />{' '}
                            {TRANSLATION_TEXT.VIEW_ALL_EVOLUTION_METRICS}
                        </ButtonEvolutionMetrics>
                        <DataWrapper show={show} length={data.length * 45} data-cy={'cy-view-all-metrics'}>
                            {data &&
                                data.map(el => (
                                    <ElementContainer key={el.countryData.countryName}>
                                        <CountryHeader>
                                            <img
                                                src={
                                                    el.countryData.countryFlag ||
                                                    `${basePath}/visuals/No_flag-image.svg`
                                                }
                                                alt={'Country Flag'}
                                            />
                                            <span>{el.countryData.countryName}</span>
                                        </CountryHeader>
                                        <CountryTrend trendValue={el.trend.trendValue} title={el.trend.trendText} />
                                    </ElementContainer>
                                ))}
                        </DataWrapper>
                    </>
                )}
            </SC.Container>
        </>
    );
};
ViewAllMetrics.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            countryData: PropTypes.object,
            trend: PropTypes.object,
        })
    ).isRequired,
};

ViewAllMetrics.defaultProps = {
    data: null,
};
export default ViewAllMetrics;
