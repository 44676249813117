import React from 'react';
import { Container, NavigationItems, NavItem, NavDetails, NavIcon } from './Navigation.styles';
import * as routes from '../../../utils/routes';
import { isUserAllowedAccess } from '../../../utils/generalUtilities';
import { connect } from 'react-redux';
import { getUserDetailsSelector } from '../../../pages/login/data/selectors';
import mapDispatchToProps from 'react-redux/lib/connect/mapDispatchToProps';

const NavLinkGenerator = role => {
    const mainNavRoutes = Object.keys(routes);

    return mainNavRoutes
        .filter(key => routes[key].hasOwnProperty('order'))
        .sort((aKey, bKey) => routes[aKey].order - routes[bKey].order)
        .map((key, index) => {
            const isNavigationItem = routes[key]?.config;
            if (!isNavigationItem) return false;
            const mainRoutePath = routes[key].path;
            const mainRouteName = routes[key].name;
            const primaryColour = routes[key]?.config?.primaryColor;
            const mainRouteIconPath = routes[key]?.config?.icon;
            const mainRouteIconOnPath = routes[key]?.config?.iconON;
            const isDashboard = mainRoutePath === routes.DASHBOARD_PAGE.path;
            const mainRouteIcon = isOnTypeRequired =>
                !isDashboard && isOnTypeRequired ? mainRouteIconOnPath : mainRouteIconPath;
            const isAvailable = isUserAllowedAccess(role, routes[key]?.requiredPermission);

            return (
                !isDashboard &&
                isAvailable && (
                    <NavItem
                        key={index}
                        activeClassName={'activeLink'}
                        to={mainRoutePath}
                        primaryColor={primaryColour}
                        icon={mainRouteIcon()}
                        iconOn={mainRouteIcon('on')}
                    >
                        <NavDetails>
                            <NavIcon /> <p>{mainRouteName}</p>{' '}
                        </NavDetails>
                        <span />
                    </NavItem>
                )
            );
        });
};

export const Navigation = ({ userDetails }) => {
    return (
        <>
            <Container>
                <NavigationItems data-testid={'Navigation-NavigationItems'}>
                    {NavLinkGenerator(userDetails.role)}
                </NavigationItems>
            </Container>
        </>
    );
};

const mapStateToProps = state => ({
    userDetails: getUserDetailsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
