import { connect } from 'react-redux';
import AdRecallTab from './AdRecall.tab';
import { getBptPreferenceAnalysisAdRecallActionCreator } from '../data/actions';
import {
    getBptPreferenceAnalysisDataComputed,
    getCombinedChartDataSelector,
    getLOBAsOptionsComputedSelector,
    getRankingTableDataSelectorComputed,
} from '../../../data/computedSelectors';
import { getBptPreferenceAnalysisAdRecallDataSelector } from '../../../data/selectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../../../../common/components/TopFilter/data/computedSelectors';
import { getTopFiveAddRecallLOBsIDsComputedSelector } from '../data/computedSelectors';
import { getFiltersThrownError } from '../../../../../../../common/data/selectors';
import { getSelectedFiltersComputedSelector } from './../../../../../../../common/components/SelectedFilters/data/computedSelectors';

const mapStateToProps = state => ({
    adRecallData: getBptPreferenceAnalysisAdRecallDataSelector(state),
    rankingTableData: getRankingTableDataSelectorComputed(state),
    combinedChartsData: getCombinedChartDataSelector(state),
    lobsAsOptions: getLOBAsOptionsComputedSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    topFiveLOBIDs: getTopFiveAddRecallLOBsIDsComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    adRecallSectionIDS: getBptPreferenceAnalysisDataComputed(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
});

const mapDispatchToProps = {
    getBptPreferenceAnalysisAdRecallActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdRecallTab);
