import React, { useState, Fragment } from 'react';
import { getSlashSeparatedDate } from '../../../../../../utils/generalUtilities';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import { basePath } from '../../../../../../services/utils';
import VideoModal from './components/VideoModal';
import * as SC from './CampaignDetails.styles';

const CampaignDetails = ({ campaignDetailsData }) => {
    const [activeImage, setActiveImage] = useState(null);
    const paragraphElement = (leftSide, rightSide) => {
        return (
            <p>
                {leftSide}: {rightSide}
            </p>
        );
    };

    return campaignDetailsData ? (
        <SC.CampaignDetailsWrapper>
            {campaignDetailsData.map(campaignDetailsItem => (
                <SC.CampaignDetailsElementWrapper key={campaignDetailsItem.copyID}>
                    <div>
                        {campaignDetailsItem.imageLink && campaignDetailsItem.videoLink ? (
                            <Fragment>
                                <SC.CampaignDetailsImg
                                    alt={'Image with Possible Error Warning'}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${basePath}/visuals/VideoUnavailable.png`;
                                    }}
                                    onClick={() => setActiveImage(campaignDetailsItem.copyID)}
                                    src={campaignDetailsItem.imageLink}
                                />
                                {activeImage === campaignDetailsItem.copyID && (
                                    <VideoModal
                                        videoProps={{
                                            videoLink: campaignDetailsItem.videoLink,
                                            imageLink: campaignDetailsItem.imageLink,
                                        }}
                                        onClose={() => setActiveImage(null)}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <SC.CampaignDetailsImg
                                alt={'Video Unavailable Warning'}
                                src={`${basePath}/visuals/VideoUnavailable.png`}
                            />
                        )}
                    </div>
                    <Fragment>
                        <SC.CampaignDetailsElementH3>{campaignDetailsItem.copyName}</SC.CampaignDetailsElementH3>
                        <SC.CampaignDetailsInfoBox>
                            <Fragment>
                                <h3>{TRANSLATION_TEXT.COPY_INFO_TEXT}</h3>
                                {paragraphElement(TRANSLATION_TEXT.COUNTRY_TEXT, campaignDetailsItem.country)}
                                {paragraphElement(TRANSLATION_TEXT.COPY_NAME_TEXT, campaignDetailsItem.copyName)}
                                {paragraphElement(TRANSLATION_TEXT.TV_GRP_TEXT, campaignDetailsItem.grp)}
                                <p>
                                    {TRANSLATION_TEXT.TV_CAMPAIGN_DATES}:{' '}
                                    {getSlashSeparatedDate(new Date(campaignDetailsItem.campaignStartDate))}{' '}
                                    {TRANSLATION_TEXT.TO_TEXT}{' '}
                                    {getSlashSeparatedDate(new Date(campaignDetailsItem.campaignEndDate))}
                                </p>
                                {paragraphElement(TRANSLATION_TEXT.AIRING_TEXT, campaignDetailsItem.airingNr)}
                                <h3>{TRANSLATION_TEXT.BPT_INFO_TEXT}</h3>
                                {paragraphElement(
                                    TRANSLATION_TEXT.NO_INTERVIEWS_TEXT,
                                    campaignDetailsItem.nrInterviews
                                )}
                                <p>
                                    {TRANSLATION_TEXT.BPT_DATES_TEXT}:{' '}
                                    {getSlashSeparatedDate(new Date(campaignDetailsItem.bptStartDate))}{' '}
                                    {TRANSLATION_TEXT.TO_TEXT}{' '}
                                    {getSlashSeparatedDate(new Date(campaignDetailsItem.bptEndDate))}
                                </p>
                            </Fragment>
                        </SC.CampaignDetailsInfoBox>
                    </Fragment>
                </SC.CampaignDetailsElementWrapper>
            ))}
        </SC.CampaignDetailsWrapper>
    ) : null;
};

export default CampaignDetails;
