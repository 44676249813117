import React from 'react';
import * as SC from './DisplayMode.style';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { DISPLAY_TOGGLE_BUTTONS } from '../../../../utils/utils';
import PropTypes from 'prop-types';

const DisplayMode = props => {
    const [width] = useWindowSize();
    const { displayMode, passDisplayMode, widthBreakpoint } = props;
    const isDisableForBreakpoint = width < widthBreakpoint;
    return (
        <>
            <SC.ShowAllContainer isDisable={!isDisableForBreakpoint}>
                {DISPLAY_TOGGLE_BUTTONS.map(element => (
                    <SC.ToggleButton
                        key={element.label}
                        onClick={() => !isDisableForBreakpoint && passDisplayMode(element.displayMode)}
                        isDisabled={isDisableForBreakpoint || displayMode !== element.displayMode}
                        cursorStatus={isDisableForBreakpoint}
                    >
                        {element.label}
                    </SC.ToggleButton>
                ))}
            </SC.ShowAllContainer>
        </>
    );
};
DisplayMode.propTypes = {
    displayMode: PropTypes.string.isRequired,
    passDisplayMode: PropTypes.func,
    widthBreakpoint: PropTypes.number,
};

DisplayMode.defaultProps = {
    displayMode: 'All',
    passDisplayMode: () => null,
    widthBreakpoint: 1300,
};

export default DisplayMode;
