import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as d3 from 'd3';
import { CopyRatingsWrapper, SVG } from './CopyRatings.styles';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { calculateForResolution } from '../../../../../../utils/responsiveness';
import { createIdFromGroup } from '../../../../../../utils/generalUtilities';

const HEIGHT = 400;
const BOTTOM_SECTION_CHART_HEIGHT = 120;
const ELLIPSIS_SHOW_LENGTH = 20;
const BAR_WIDTH = 30;
const OFFSET_XAXIS = 20;
const PADDING = 5;

const CopyRatings = ({ data }) => {
    const mainSvg = useRef(null);
    const [localData, setLocalData] = useState(null);
    const [localColors, setLocalColors] = useState(null);

    const [width] = useWindowSize();

    const calculatedWidth = useMemo(() => {
        return calculateForResolution(width);
    }, [width]);

    /* Process the raw data */
    useEffect(() => {
        let processedData = [];
        let colors = [];

        data &&
            data.forEach((kpi, index) => {
                const { kpiLabel, kpiImageLink, items } = kpi;

                let groupObject = {};
                groupObject.group = kpiLabel;
                groupObject.Image = kpiImageLink;

                items.forEach(item => {
                    const { itemType, values } = item;
                    groupObject[itemType] = values[0].value;

                    if (index === 0) {
                        colors.push(values[0].color);
                    }
                });

                processedData.push(groupObject);
            });

        /* For test only to look more like the mocks
        const DEV_DATA = [
            { group: 'Delighted', main: 18, filtered: 15 },
            { group: 'Cool', main: 17, filtered: 15 },
            { group: 'Wide/Haha', main: 11, filtered: 10 },
            { group: 'Sleepy', main: 17, filtered: 11 },
            { group: 'Disturbed/Disappointed', main: 4, filtered: 3 },
            { group: 'Surprised', main: 3, filtered: 3 },
            { group: 'Confused', main: 3, filtered: 3 },
            { group: 'Hateful', main: 12, filtered: 10 },
            { group: 'Awful', main: 14, filtered: 11 },
            { group: 'Bland', main: 21, filtered: 7 },
            { group: 'Too Much', main: 4, filtered: 8 },
            { group: 'Loved', main: 20, filtered: 11 },
            { group: 'Disgusted', main: 24, filtered: 18 },
        ];
         */

        setLocalData([...processedData]);
        setLocalColors(colors);
    }, [data]);

    /* Draw the chart */
    useEffect(() => {
        if (localData && localData?.length > 0) {
            const hasFilteredData = localData.some(el => el.filtered);
            const svg = d3.select(mainSvg.current);
            svg.selectAll('*').remove();

            /* Main or Filtered bars */
            const subgroups = Object.keys(localData[0]);
            subgroups.splice(subgroups.indexOf('group'), 1);
            subgroups.splice(subgroups.indexOf('Image'), 1);

            /* Groups based on the different emotions */
            const groups = localData.reduce((arr, curr) => {
                arr.push({
                    group: curr.group,
                    img: curr.Image,
                });
                return arr;
            }, []);

            /* Calculate domain and add padding to it */
            let domain = localData.reduce((max, curr) => {
                let maxOfGroup = 0;
                Object.keys(curr).forEach(key => {
                    if (parseInt(curr[key], 10) > maxOfGroup) {
                        maxOfGroup = curr[key];
                    }
                });

                return maxOfGroup > max ? maxOfGroup : max;
            }, 0);
            domain = 0.15 * domain + domain;

            /* Append the blue background */
            svg.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('height', HEIGHT)
                .attr('width', calculatedWidth)
                .style('fill', defaultTheme.grey[50])
                .attr('stroke', defaultTheme.blue[950])
                .attr('stroke-dasharray', '2,3')
                .attr('stroke-width', '3');

            /* Add X axis */
            let xScale = d3
                .scaleBand()
                .domain(groups.map(g => g.group))
                .range([25, calculatedWidth])
                .padding([0.2]);
            const xAxis = d3.axisBottom().scale(xScale).tickSize(0);
            const xAxisElement = svg.append('g').attr('transform', `translate(0,${HEIGHT})`).call(xAxis);

            xAxisElement.selectAll('text').remove();
            xAxisElement.selectAll('.domain').remove();
            xAxisElement
                .selectAll('.tick')
                .append('foreignObject')
                .attr('width', 60)
                .attr('height', BOTTOM_SECTION_CHART_HEIGHT)
                .style('word-break', 'break-word')
                .style('font-size', 11)
                .style('padding-top', 15)
                .style('text-align', 'center')
                .style('font-weight', 600)
                .style('color', defaultTheme.blue[950])
                .style('transform', 'translateX(-27px)')
                .html(d => {
                    const textLength = d.length;
                    const elementId = createIdFromGroup('ellipsed', d);

                    if (textLength >= ELLIPSIS_SHOW_LENGTH) {
                        return `
                            <div>
                                <p id=${elementId}> ${d.substring(0, 17)}...</p>
                            </div>            
                        `;
                    } else {
                        return `<p>${d}</p>`;
                    }
                })
                .on('mouseenter', (element, dt) => {
                    if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                        const ellipsedId = createIdFromGroup('ellipsed', dt);
                        const imageId = createIdFromGroup('emotionGroup', dt);

                        d3.select(`#${ellipsedId}`).text(dt);
                        d3.select(`#${imageId}`).transition().style('opacity', 0.1);
                    }
                })
                .on('mouseleave', (element, dt) => {
                    if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                        const text = dt.length >= ELLIPSIS_SHOW_LENGTH ? dt.substring(0, 17) + '...' : dt;
                        const ellipsedId = createIdFromGroup('ellipsed', dt);
                        const imageId = createIdFromGroup('emotionGroup', dt);

                        d3.select(`#${ellipsedId}`).text(text);
                        d3.select(`#${imageId}`).transition().style('opacity', 1);
                    }
                });

            xAxisElement
                .selectAll('.tick')
                .data(groups)
                .append('svg:image')
                .attr('xlink:href', function (d) {
                    return d.img;
                })
                .attr('id', d => createIdFromGroup('emotionGroup', d.group))
                .attr('width', 50)
                .attr('height', 43)
                .attr('x', -25)
                .attr('y', 65);

            /* Add Y Axis */
            let yScale = d3.scaleLinear().domain([0, domain]).range([HEIGHT, 20]);
            let yAxis = d3
                .axisLeft(yScale)
                .ticks(5)
                .tickFormat(d => d + '%');
            const yAxisElement = svg.append('g').style('transform', 'translate(37px, 0px)').call(yAxis);

            yAxisElement
                .selectAll('line')
                .attr('x2', () => calculatedWidth - 45)
                .style('stroke-dasharray', '2.4')
                .style('stroke-opacity', 0.3)
                .style('display', (d, i) => (i <= 0 ? 'none' : 'block'));
            yAxisElement.selectAll('.domain').style('stroke-opacity', 0);
            yAxisElement
                .selectAll('text')
                .style('display', (d, i) => (i <= 0 ? 'none' : 'block'))
                .style('font-size', 12)
                .style('font-weight', 600)
                .style('opacity', 0.7)
                .style('color', defaultTheme.blue[950]);

            /* Create scale for subgroup position */

            //Limiting the bar width to minimum value between what each bandwidth allocates and the BAR_WIDTH (basically we allow a max of BAR_WIDTH and a minimum of what the bandwidth allocates)
            //In the case of filtered data, we allow a bandwith that will fit two bars + additional offset for spacing between them
            const rangeForLimitedBarWidth = Math.min(
                xScale.bandwidth() + PADDING,
                hasFilteredData ? BAR_WIDTH * 2 + OFFSET_XAXIS : BAR_WIDTH + OFFSET_XAXIS / 2
            );
            const xSubgroup = d3.scaleBand().domain(subgroups).range([0, rangeForLimitedBarWidth]).padding([0.05]);
            const color = d3.scaleOrdinal().domain(subgroups).range(localColors);

            /* Add bars */
            svg.append('g')
                .selectAll('g')
                .data(localData)
                .enter()
                .append('g')
                .attr('class', 'bars')
                .attr('transform', function (d) {
                    return 'translate(' + xScale(d.group) + ',0)';
                })
                .selectAll('rect')
                .data(d => subgroups.map(key => ({ key: key, value: d[key] })))
                .enter()
                .append('rect')
                .attr(
                    'x',
                    d =>
                        xSubgroup(d.key) +
                        (xScale.bandwidth() - (hasFilteredData ? BAR_WIDTH + OFFSET_XAXIS + PADDING : BAR_WIDTH)) / 2
                ) //In the case we have two bars, we withdraw their dimmensions and padding from the allocated bandwidth, and divide by two to keep the plot centered with the axis labels. If not, we only withdraw the width of a bar and divide.
                .attr('y', d => yScale(d.value))
                .attr('width', xSubgroup.bandwidth() / 1.2)
                .attr('height', d => HEIGHT - yScale(d.value))
                .attr('fill', d => (d.key === 'filtered' ? 'url(#filtered-lines-pattern)' : color(d.key)))
                .attr('stroke', defaultTheme.blue[950])
                .attr('stroke-width', '1');

            /* Add text */
            svg.append('g')
                .selectAll('g')
                .data(localData)
                .enter()
                .append('g')
                .attr('transform', function (d) {
                    return 'translate(' + xScale(d.group) + ',0)';
                })
                .selectAll('text')
                .data(d =>
                    subgroups.map(key => {
                        return { key: key, value: d[key] };
                    })
                )
                .enter()
                .append('text')
                .attr('x', d => xSubgroup(d.key) + (xScale.bandwidth() - (hasFilteredData ? BAR_WIDTH - 4 : 0)) / 2) //Centering the text and having that extra bar of width, withdrawn everytime we have an additional bar.
                .attr('y', d => yScale(d.value) - 4)
                .text(d => d.value + '%')
                .style('font-size', 13)
                .style('color', defaultTheme.blue[950])
                .style('font-weight', 600)
                .style('text-anchor', 'middle');
        }
    }, [localData, calculatedWidth]);

    return (
        <CopyRatingsWrapper>
            <SVG>
                <defs>
                    <pattern
                        id="filtered-lines-pattern"
                        patternUnits="userSpaceOnUse"
                        width="50"
                        height="10"
                        patternTransform="scale(2) rotate(0)"
                    >
                        <g class="filtered-lines-b" transform="translate(0 18.609) rotate(-90)">
                            <rect class="filtered-lines-g" width="18.609" height="50.354" />
                        </g>
                        <g class="filtered-lines-c">
                            <g class="d" transform="translate(1.693 -0.345)">
                                <path
                                    class="filtered-lines-e"
                                    d="M-10283.55-8369.407l-3.821,3.174-45.091,37.448"
                                    transform="translate(10332.547 8369.407)"
                                />
                                <path
                                    class="filtered-lines-e"
                                    d="M-10278.487-8369.407l-3.821,3.174-33.863,28.145"
                                    transform="translate(10327.484 8378.648)"
                                />
                                <path
                                    class="filtered-lines-e"
                                    d="M-10273.234-8369.407l-3.821,3.174-22.214,18.445"
                                    transform="translate(10322.231 8388.297)"
                                />
                                <path
                                    class="filtered-lines-f"
                                    d="M-10268.975-8369.407l-16.586,13.5"
                                    transform="translate(10317.972 8396.568)"
                                />
                                <path
                                    class="filtered-lines-e"
                                    d="M-10264.445-8369.407l-3.821,3.174-2.721,2.158"
                                    transform="translate(10313.442 8404.839)"
                                />
                                <path
                                    class="filtered-lines-f"
                                    d="M-10278.553-8369.407l-37.831,31.419"
                                    transform="translate(10316.522 8369.407)"
                                />
                                <path
                                    class="filtered-lines-f"
                                    d="M-10274.246-8369.407l-28.277,23.484"
                                    transform="translate(10302.565 8369.407)"
                                />
                                <path
                                    class="filtered-lines-f"
                                    d="M-10269.594-8369.407l-17.96,14.916"
                                    transform="translate(10287.575 8369.407)"
                                />
                                <path
                                    class="filtered-lines-f"
                                    d="M-10264.942-8369.407l-7.643,6.348"
                                    transform="translate(10272.585 8369.407)"
                                />
                            </g>
                        </g>
                    </pattern>
                </defs>
            </SVG>
            <svg ref={mainSvg} width={calculatedWidth} height={HEIGHT + BOTTOM_SECTION_CHART_HEIGHT} data-testid="copy-ratings-svg" />
        </CopyRatingsWrapper>
    );
};

export default CopyRatings;
