import React from 'react';
import { connect } from 'react-redux';
import { NonSorted, SortedAsc, SortedDesc } from '../../../../backOffice/backOffice.styles';
import { DataTable } from '@toluna-ui-toolkit/data-table';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import ViewIndividualAdButton from './ViewIndividualAdButton';
import HeaderTooltip from '../../../../../common/components/HeaderTooltip/HeaderTooltip';
import ViewAdsComparisonButton from './ViewAdsComparisonButton';
import {
    CompareAdsSmallScreenWrapper,
    CompareAdsSmallScreen,
    OverviewTableWrapper,
    BaseSizeLowWrapper,
} from './overview.styles';
import { getAdTesterOverviewSelectedAdsSelector } from '../data/selectors';
import { onCompareSelectedAdsActionCreator } from '../data/actions';
import { TRANSLATION_TEXT } from '../../../../../utils/translations';
import { MAX_ADS_COMPARISON, MIN_ADS_COMPARISON } from '../../../constants';
import { addTableHeaderCustomSortFunctions } from '../../../../../utils/generalUtilities';
import { basePath } from '../../../../../services/utils';
export const AdTesterOverviewTable = ({ data = [], tableHead, selectedAdsIds, onCompareSelectedAds }) => {
    const isAnyBaseSizeLow = data.some(item => {
        for (let key in item) {
            if (item[key] === true) {
                return true;
            }
        }
        return false;
    });
    const TABLE_STYLE = {
        tableHead: {
            backgroundColor: 'null',
            borderBottom: `2px solid ${defaultTheme.blue[750]}`,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            fontSize: '8px',
            color: defaultTheme.blue[750],
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',
        },
        bodyRow: {
            activeColor: null,
            backgroundColor: defaultTheme.white.ff,
            backgroundColorSecondary: defaultTheme.grey[50],
            hoverColor: defaultTheme.blue[50],
        },
        column: { separatorColor: 'none' },
        global: {
            fontFamily: 'Source Sans Pro,Open Sans',
        },
    };
    const renderBaseSizeLowIcon = value => {
        if (value === true) {
            return (
                <img
                    alt={'low base size'}
                    className="innerCampaignWarningImage"
                    src={`${basePath}/visuals/lowBaseWarning.png`}
                />
            );
        }
        return null;
    };
    /* Based on ReactTable specs, this needs to be memoized */
    const tableColumnsSpecs = React.useMemo(() => {
        let headerData = [];
        if (tableHead && tableHead?.length > 0) {
            tableHead.forEach((th, i) => {
                const headerObject = {
                    ...th,
                    Header: () =>
                        th.tooltip ? <HeaderTooltip tooltip={th.tooltip}>{th.Header}</HeaderTooltip> : th.Header,
                    Cell: cell => {
                        const lowBaseProp = cell.column.lowbaseacc;
                        const lowBaseValue = cell.row.original[lowBaseProp];
                        return (
                            <>
                                {cell.value}
                                {renderBaseSizeLowIcon(lowBaseValue)}
                            </>
                        );
                    },
                };
                const decoratedHeader = addTableHeaderCustomSortFunctions(th, headerObject);

                headerData.push({ ...decoratedHeader });
            });
            headerData.push(
                {
                    Header: TRANSLATION_TEXT.OVERVIEW_TABLE_INDIVIDUAL_ADS,
                    accessor: '22',
                    Cell: cellData => <ViewIndividualAdButton data={cellData.row.original} />,
                    disableSortBy: true,
                },
                {
                    Header: TRANSLATION_TEXT.OVERVIEW_TABLE_ADS_COMPARISON,
                    accessor: '23',
                    Cell: cellData => (
                        <ViewAdsComparisonButton data={cellData.row.original} rowID={cellData.row.index} />
                    ),
                    disableSortBy: true,
                }
            );
        }
        return headerData;
    }, [data]);

    return (
        <OverviewTableWrapper>
            {isAnyBaseSizeLow && (
                <BaseSizeLowWrapper>
                    <img
                        alt={'low base size'}
                        className="innerCampaignWarningImage"
                        src={`${basePath}/visuals/lowBaseWarning.png`}
                    />
                    <span className="itemLabel">{TRANSLATION_TEXT.SAMPLE_SIZE_LOW}</span>
                </BaseSizeLowWrapper>
            )}
            {selectedAdsIds.length >= MIN_ADS_COMPARISON && selectedAdsIds.length <= MAX_ADS_COMPARISON && (
                <CompareAdsSmallScreenWrapper>
                    <CompareAdsSmallScreen onClick={() => onCompareSelectedAds()}>
                        {TRANSLATION_TEXT.COMPARE_ADS} {selectedAdsIds.length}
                        <br />
                        {'>>>'}
                    </CompareAdsSmallScreen>
                </CompareAdsSmallScreenWrapper>
            )}
            <DataTable
                styles={TABLE_STYLE}
                isPaginated={false}
                data={data}
                cellWidth={60}
                isScrollable={false}
                columns={tableColumnsSpecs}
                NonSortedArrow={NonSorted}
                SortedAscArrow={SortedAsc}
                SortedDescArrow={SortedDesc}
            />
        </OverviewTableWrapper>
    );
};

const mapStateToProps = state => ({
    selectedAdsIds: getAdTesterOverviewSelectedAdsSelector(state),
});

const mapDispatchToProps = {
    onCompareSelectedAds: onCompareSelectedAdsActionCreator,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdTesterOverviewTable);
