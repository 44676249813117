import {
    FORGOT_PASSWORD_ASYNC,
    IS_RESET_TOKEN_VALID_ASYNC,
    LOGIN_ACTION_GROUP,
    REFRESH_TOKEN_ASYNC_GROUP,
    RESET_PASSWORD_ASYNC,
} from '../../../../pages/login/data/actions';
import { GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC } from '../../../../pages/adTester/subpages/AdDetailsMetrics/data/actions';
import { GET_ADTESTER_OVERVIEW_DATA_ASYNC } from '../../../../pages/adTester/subpages/Overview/data/actions';
import { GET_FIELDWORK_OVERVIEW_DATA_ASYNC } from '../../../../pages/surveyFieldwork/subpages/Overview/data/actions';
import { PROCESS_TOP_FILTERS_ENDED, PROCESS_TOP_FILTERS_STARTED } from '../../TopFilter/data/actions';
import {
    FILE_UPLOAD_PROCESS_ENDED,
    FILE_UPLOAD_PROCESS_STARTED,
    FILE_UPLOAD_TO_AWS_ASYNC,
    INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC,
    TABLE_REQUEST_CONFIRMIT_ASYNC,
    TABLE_REQUEST_DATA_UPLOAD_ASYNC,
    TABLE_REQUEST_METADATA_ASYNC,
    TOGGLE_CONFIRMIT_PROJECT_ASYNC,
} from '../../../../pages/backOffice/data/actions';
import { GET_EXPORT_LINK_ASYNC } from '../../ExportButton/data/actions';
import { GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC } from '../../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/awareness/data/actions';
import { GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC } from '../../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/adRecall/data/actions';
import { GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC } from '../../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/consideration/data/actions';
import { GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC } from '../../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/image/data/actions';
import { GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC } from '../../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/valueformoney/data/actions';
import { GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC } from '../../../../pages/adTester/subpages/AdsComparison/data/actions';
import { GET_FILTERS_ASYNC } from '../../../data/actions';
import { GET_COMPETITIVE_MEDIA_DATA_ASYNC } from '../../../../pages/competitiveMedia/subpages/CompetitiveMedia/data/actions';
import { GET_TOOLBOX_URL_ASYNC } from '../../../../pages/landing/data/actions';
import { GET_TRANSVERSALVIEW_DATA_ASYNC } from './../../../../pages/transversalView/subpages/TransversalView/data/actions';

export const isGlobalLoadingReducer = (state = [], action) => {
    switch (action.type) {
        case LOGIN_ACTION_GROUP.actions.STARTED:
        case RESET_PASSWORD_ASYNC.actions.STARTED:
        case FORGOT_PASSWORD_ASYNC.actions.STARTED:
        case IS_RESET_TOKEN_VALID_ASYNC.actions.STARTED:
        case REFRESH_TOKEN_ASYNC_GROUP.actions.STARTED:
        case GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.actions.STARTED:
        case GET_ADTESTER_OVERVIEW_DATA_ASYNC.actions.STARTED:
        case GET_FIELDWORK_OVERVIEW_DATA_ASYNC.actions.STARTED:
        case PROCESS_TOP_FILTERS_STARTED:
        case GET_FILTERS_ASYNC.actions.STARTED:
        case FILE_UPLOAD_TO_AWS_ASYNC.actions.STARTED:
        case GET_EXPORT_LINK_ASYNC.actions.STARTED:
        case INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.actions.STARTED:
        case TABLE_REQUEST_DATA_UPLOAD_ASYNC.actions.STARTED:
        case TABLE_REQUEST_METADATA_ASYNC.actions.STARTED:
        case TOGGLE_CONFIRMIT_PROJECT_ASYNC.actions.STARTED:
        case TABLE_REQUEST_CONFIRMIT_ASYNC.actions.STARTED:
        case GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.actions.STARTED:
        case GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.actions.STARTED:
        case GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.actions.STARTED:
        case GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.actions.STARTED:
        case GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.actions.STARTED:
        case GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.actions.STARTED:
        case GET_COMPETITIVE_MEDIA_DATA_ASYNC.actions.STARTED:
        case GET_TRANSVERSALVIEW_DATA_ASYNC.actions.STARTED:
        case FILE_UPLOAD_PROCESS_STARTED:
        case GET_TOOLBOX_URL_ASYNC.actions.STARTED:
            return [...state, true];
        case LOGIN_ACTION_GROUP.actions.SUCCEEDED:
        case LOGIN_ACTION_GROUP.actions.FAILED:
        case RESET_PASSWORD_ASYNC.actions.SUCCEEDED:
        case RESET_PASSWORD_ASYNC.actions.FAILED:
        case FORGOT_PASSWORD_ASYNC.actions.SUCCEEDED:
        case FORGOT_PASSWORD_ASYNC.actions.FAILED:
        case IS_RESET_TOKEN_VALID_ASYNC.actions.SUCCEEDED:
        case IS_RESET_TOKEN_VALID_ASYNC.actions.FAILED:
        case REFRESH_TOKEN_ASYNC_GROUP.actions.SUCCEEDED:
        case REFRESH_TOKEN_ASYNC_GROUP.actions.FAILED:
        case GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.actions.SUCCEEDED:
        case GET_AD_TESTER_AD_DETAILS_AND_METRIC_DATA_ASYNC.actions.FAILED:
        case GET_ADTESTER_OVERVIEW_DATA_ASYNC.actions.SUCCEEDED:
        case GET_ADTESTER_OVERVIEW_DATA_ASYNC.actions.FAILED:
        case GET_FIELDWORK_OVERVIEW_DATA_ASYNC.actions.SUCCEEDED:
        case GET_FIELDWORK_OVERVIEW_DATA_ASYNC.actions.FAILED:
        case GET_FILTERS_ASYNC.actions.SUCCEEDED:
        case GET_FILTERS_ASYNC.actions.FAILED:
        case PROCESS_TOP_FILTERS_ENDED:
        case FILE_UPLOAD_TO_AWS_ASYNC.actions.SUCCEEDED:
        case FILE_UPLOAD_TO_AWS_ASYNC.actions.FAILED:
        case GET_EXPORT_LINK_ASYNC.actions.SUCCEEDED:
        case GET_EXPORT_LINK_ASYNC.actions.FAILED:
        case INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_DATA_UPLOAD_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_METADATA_ASYNC.actions.SUCCEEDED:
        case INIT_BACKOFFICE_TABLE_REQUESTS_ASYNC.actions.FAILED:
        case TABLE_REQUEST_DATA_UPLOAD_ASYNC.actions.FAILED:
        case TABLE_REQUEST_METADATA_ASYNC.actions.FAILED:
        case TOGGLE_CONFIRMIT_PROJECT_ASYNC.actions.SUCCEEDED:
        case TOGGLE_CONFIRMIT_PROJECT_ASYNC.actions.FAILED:
        case TABLE_REQUEST_CONFIRMIT_ASYNC.actions.SUCCEEDED:
        case TABLE_REQUEST_CONFIRMIT_ASYNC.actions.FAILED:
        case GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.actions.SUCCEEDED:
        case GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.actions.FAILED:
        case GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.actions.SUCCEEDED:
        case GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.actions.FAILED:
        case GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.actions.SUCCEEDED:
        case GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.actions.FAILED:
        case GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.actions.SUCCEEDED:
        case GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.actions.FAILED:
        case GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.actions.SUCCEEDED:
        case GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.actions.FAILED:
        case GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.actions.SUCCEEDED:
        case GET_AD_TESTER_AD_COMPARISON_DATA_ASYNC.actions.FAILED:
        case FILE_UPLOAD_PROCESS_ENDED:
        case GET_COMPETITIVE_MEDIA_DATA_ASYNC.actions.SUCCEEDED:
        case GET_COMPETITIVE_MEDIA_DATA_ASYNC.actions.FAILED:
        case GET_TRANSVERSALVIEW_DATA_ASYNC.actions.SUCCEEDED:
        case GET_TRANSVERSALVIEW_DATA_ASYNC.actions.FAILED:
        case GET_TOOLBOX_URL_ASYNC.actions.SUCCEEDED:
        case GET_TOOLBOX_URL_ASYNC.actions.FAILED:
            if (state.length > 0) {
                return state.slice(0, -1);
            }
            return state;
        default:
            return state;
    }
};
