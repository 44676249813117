import React from 'react';
import ReusableModal from '../ReusableModal/ReusableModal';
import { defaultTheme } from '../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import PropTypes from 'prop-types';
const SessionExpiredModal = ({ isModalActive, onApplyAction }) => {
    const titleColor = defaultTheme.red[400];

    return (
        <>
            {isModalActive && (
                <ReusableModal
                    title={TRANSLATION_TEXT.SESSION_EXPIRED_MODAL_TITLE_TEXT}
                    titleColor={titleColor}
                    singleButton={true}
                    yesText={TRANSLATION_TEXT.GENERAL_SUBMIT_OK_TEXT}
                    isAbleToDismiss={false}
                    modalHeight={'380px'}
                    onApply={onApplyAction}
                    text={TRANSLATION_TEXT.SESSION_EXPIRED_MODAL_BODY_TEXT}
                />
            )}
        </>
    );
};
SessionExpiredModal.propTypes = {
    isModalActive: PropTypes.bool.isRequired,
    onApplyAction: PropTypes.func.isRequired,
};
export default SessionExpiredModal;
