import styled from 'styled-components';
import { UserPanelWrapper } from '../../../pages/landing/LandingPage.styles';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../../utils/responsiveness';

export const Container = styled.header`
    width: 100%;
    height: 145px;
    margin: 0 auto 15px auto;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme }) => theme.white.ff};
    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        width: 100%;
        min-width: auto;
    }
`;

export const PanelWrapper = styled(UserPanelWrapper)`
    z-index: 1;
    position: absolute;
    margin: 0 0 0 0;

    padding-right: 15px;
    @media (max-width: 1280px) {
        margin: 0 0 0 0;
    }
    @media (max-width: 1280px) {
        padding: 4px 14px 12px 13px;
    }
`;
export const UserMenu = styled.div`
    position: relative;
    height: 75px;
    width: 170px;
`;
export const Branding = styled.div`
    height: 50px;
    width: 50px;
    background-image: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 3px ${({ theme }) => theme.violet[100]};
    border-radius: 2px;
    margin-left: 40px;
    @media (max-width: 1280px) {
        height: 48px;
        width: 48px;
    }
    @media (max-width: 1024px) {
        height: 40px;
        width: 40px;
    }
`;
