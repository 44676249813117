import React from 'react';
import * as SC from './Legend.styles';
import PropTypes from 'prop-types';
const Legend = ({ legendItems, displayDirection, customStyle }) => {
    const { _legendShape } = customStyle;
    return (
        <SC.Wrapper data-testid="LegendWrapper" customStyle={customStyle?._wrapper} displayDirection={displayDirection}>
            {legendItems &&
                legendItems.map((eachLegendItem, i) => {
                    const appliedStyleForLegendItem = _legendShape?.find(el => el._legendIndex === i)?._legendStyle;
                    return (
                        <SC.LegendItem
                            data-testid="LegendItem"
                            customStyle={{
                                ...customStyle?._individualItem,
                                _legendStyle: { ...appliedStyleForLegendItem },
                            }}
                            key={i + eachLegendItem.name}
                            displayDirection={displayDirection}
                            legendColor={eachLegendItem.color}
                            legendBorderColor={eachLegendItem.borderColor}
                        >
                            <div>
                                <span />
                                {eachLegendItem.name}
                            </div>
                        </SC.LegendItem>
                    );
                })}
        </SC.Wrapper>
    );
};

Legend.propTypes = {
    legendItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string,
        })
    ),
    displayDirection: PropTypes.oneOf(['row', 'column']).isRequired,
    customStyle: PropTypes.shape({
        _wrapper: PropTypes.object,
        _individualItem: PropTypes.object,
        _legendShape: PropTypes.arrayOf(
            PropTypes.shape({
                _legendIndex: PropTypes.number.isRequired,
                _legendStyle: PropTypes.object.isRequired,
            })
        ),
    }),
};

export default Legend;
