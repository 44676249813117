import styled from 'styled-components';

export const FooterWrapper = styled.div`
    background-color: ${({ theme }) => theme.blue[300]};
    width: 100%;
    height: 72px;
    margin: 20px auto 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const FooterContent = styled.div`
    color: ${({ theme }) => theme.white.ff};
    font-size: 13px;
    padding: 0 5%;

    a {
        color: ${({ theme }) => theme.white.ff};
    }
`;
