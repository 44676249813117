import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_ADTESTER_OVERVIEW_DATA_ACTION_CREATOR = '/pages/overview/GET_AD_TESTER_OVERVIEW_DATA_ACTION_CREATOR';
export const GET_ADTESTER_OVERVIEW_DATA_ASYNC = asyncActionCreator('/pages/overview/GET_AD_TESTER_OVERVIEW_DATA_ASYNC');
export const ON_OVERVIEW_TABLE_CHECKBOX_CHANGE_ACTION_CREATOR =
    '/pages/overview/ON_OVERVIEW_TABLE_CHECKBOX_CHANGE_ACTION_CREATOR';
export const ON_VIEW_AD_CLICK = '/pages/overview/ON_VIEW_AD_CLICK';
export const SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY = '/pages/overview/SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY';

export const ON_SELECT_ADS_COMP_AD_CLICK = '/pages/overview/ON_SELECT_ADS_COMP_AD_CLICK';
export const SET_SELECTED_ADS_COMPARISON_ADS = '/pages/overview/SET_SELECTED_ADS_COMPARISON_ADS';

export const ON_COMPARE_SELECTED_ADS_COMP_ADS = '/pages/overview/ON_COMPARE_SELECTED_ADS_COMP_ADS';

export const onCompareSelectedAdsActionCreator = () => ({
    type: ON_COMPARE_SELECTED_ADS_COMP_ADS,
});

export const onSelectAdAdsComparisonActionCreator = currentAd => ({
    type: ON_SELECT_ADS_COMP_AD_CLICK,
    payload: currentAd,
});

export const setSelectedAdsAdComparisonActionCreator = selectedAds => ({
    type: SET_SELECTED_ADS_COMPARISON_ADS,
    payload: selectedAds,
});

export const setAdTesterOverviewSelectedCountryActionCreator = countryId => ({
    type: SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY,
    payload: countryId,
});
export const onViewAdClickActionCreator = adData => ({
    type: ON_VIEW_AD_CLICK,
    payload: adData,
});

export const getAdTesterOverviewDataActionCreator = () => ({
    type: GET_ADTESTER_OVERVIEW_DATA_ACTION_CREATOR,
});

export const onOverviewTableCheckboxChangeActionCreator = (id, checked) => ({
    type: ON_OVERVIEW_TABLE_CHECKBOX_CHANGE_ACTION_CREATOR,
    payload: { id, checked },
});
