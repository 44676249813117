import { createSelector } from 'reselect';
import {
    getLocationSelector,
    getSelectedTopFiltersByPageSelector,
} from '../../../../../common/components/TopFilter/data/selectors';
import { PAGES_NEED_SEPARATE_LOBS_LEGENDS, STORE_NAME_ROUTE_BASED, AXA_AND_AXABRANDS_ID } from '../tabs/utils/utils';
import { KPI_TYPES } from '../../../../../common/utils/utils';
import { getBptPreferenceAnalysisDataSelector } from '../data/selectors';
import { getKPIBasedOnMatchedProperty } from '../../../../../utils/generalUtilities';
import { defaultTheme } from '../../../../../utils/defaultTheme';
import { BPT_PAGE, PREFERENCE_ANALYSIS_TABS } from '../../../../../utils/routes';
import { getUserDetailsSelector } from '../../../../login/data/selectors';

export const getBptPreferenceAnalysisDataComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        let sectionsChecked = [];
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            sectionsChecked = subTabStoreData.sections.map(s => s.sectionID);
        }
        return sectionsChecked;
    }
);

export const getLOBAsOptionsComputedSelector = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        //This computed selector generates the LOBS as they are needed to construct the legends for Combined KPI Type and Ranking KPI type components.

        //We have different LOBs in the Combined and Ranking KPI's (for eg. Combined has one more and the IDS differ)

        //So to avoid creating issues, the 'mappedLOBs' object it is used to inject the needed LOBs data for the Combined and Raking types. More can be added :)

        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];

        if (
            subTabStoreData &&
            subTabStoreData.hasOwnProperty('sections') &&
            subTabStoreData.sections &&
            subTabStoreData.sections.length > 0
        ) {
            const lobsToMap = [KPI_TYPES.COMBINED, KPI_TYPES.RANKING, KPI_TYPES.LINE_CHART];
            const mappedLOBs = {};
            subTabStoreData.sections.forEach(section => {
                mappedLOBs[section.sectionID] = lobsToMap.reduce((acc, key) => {
                    return { ...acc, [key]: [] };
                }, {});
            }); //Returned for each section, a key - value pair with the key as the kpiTipe and an array as a value.

            subTabStoreData.sections.forEach(section => {
                const sectionWithKPIS = section.kpis;
                let lobsThatNeedMapping = [];

                const lobsForKPIRanking = getKPIBasedOnMatchedProperty(sectionWithKPIS, 'kpiLabel', KPI_TYPES.LOBS);
                const lobsForKPICombined = getKPIBasedOnMatchedProperty(sectionWithKPIS, 'kpiType', KPI_TYPES.COMBINED);
                const lobsForKPILineChart = getKPIBasedOnMatchedProperty(
                    sectionWithKPIS,
                    'kpiType',
                    KPI_TYPES.LINE_CHART
                );

                if (lobsForKPICombined) {
                    lobsThatNeedMapping.push(...lobsForKPICombined);
                }
                if (lobsForKPIRanking) {
                    lobsThatNeedMapping.push(...lobsForKPIRanking);
                }
                if (lobsForKPILineChart) {
                    lobsThatNeedMapping.push(...lobsForKPILineChart);
                }
                const { sectionID } = section;
                Object.keys(mappedLOBs[sectionID]).forEach(mappedSection => {
                    //We loop through the keys of the mappedLobs object
                    //And for each element in the array that keeps the LOBS that we need
                    //We check if the kpiType property is the same as the property in our mapping object(mappedLOBs)
                    //And if so, we construct our object that we will use in our components to render legends and dropdown menus(..so far)
                    lobsThatNeedMapping.forEach(elToMap => {
                        if (elToMap.kpiType === mappedSection) {
                            elToMap.items.forEach(({ itemId, itemLabel, values }) => {
                                //Better check if for a specific object property that its value is an array, we do not push an already existing LOB.
                                if (mappedLOBs[sectionID][mappedSection].findIndex(el => el.key === itemId) === -1) {
                                    mappedLOBs[sectionID][mappedSection].push({
                                        key: itemId,
                                        itemLabel: itemLabel,
                                        color:
                                            mappedSection === KPI_TYPES.RANKING
                                                ? values.find(
                                                      el =>
                                                          el.color.toLowerCase() !==
                                                          defaultTheme.blue[600].toLowerCase()
                                                  )?.color
                                                : values[values.length - 1].color,
                                        isChecked: true,
                                    });
                                }
                            });
                        }
                    });
                });
            });

            let retunedMappedLOBs = {};
            //Special case where each sectionID has a separate legend.
            if (PAGES_NEED_SEPARATE_LOBS_LEGENDS.includes(location)) {
                retunedMappedLOBs = { ...mappedLOBs };
                //The final data in the object can be accessed using the [sectionID] and KPI_TYPES.COMBINED or KPI_TYPES.RANKING keys (or others if this computed selector is extended).
                return retunedMappedLOBs;
            } else {
                let firstLegendElement = mappedLOBs[Object.keys(mappedLOBs)[0]];
                retunedMappedLOBs = { ...firstLegendElement };
                //The final data in the object can be accessed using the KPI_TYPES.COMBINED or KPI_TYPES.RANKING keys (or others if this computed selector is extended).
                return retunedMappedLOBs;
            }
        }
        return null;
    }
);

export const getRankingTableDataSelectorComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey]; // sections = null;
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            const rankingKPISBySectionID = {};
            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                rankingKPISBySectionID[section.sectionID] = section.kpis.reduce((acc, eachSection) => {
                    if (eachSection.kpiType === KPI_TYPES.RANKING) {
                        acc.push({ ...eachSection });
                        return acc;
                    }
                    return acc;
                }, []);
            });
            return rankingKPISBySectionID;
        }
        return [];
    }
);

export const getCombinedChartDataSelector = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            const dataBySectionID = {};
            const bptSizesMap = {};

            const composedLabel = (label, composeWithData, sectionID) => {
                if (location === PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path && sectionID === 2) {
                    if (composeWithData) {
                        bptSizesMap[label] = `(n=${composeWithData})`;
                        return `${label} ${bptSizesMap[label]}`;
                    }

                    if (bptSizesMap.hasOwnProperty(label)) {
                        //If already in cache, return from there - linecharts for exemple will take the bptBaseSize from the barchart, cached in the bptSizeMap.
                        return `${label} ${bptSizesMap[label]}`;
                    }
                }
                return label;
            };

            const processBarData = (source, sectionID) =>
                source.map(barValue => {
                    const {
                        color,
                        value,
                        label,
                        sigtest,
                        itemLabel,
                        bptBaseSize,
                        evolutionMetricValue,
                        nrRespondents,
                    } = barValue;
                    return {
                        color,
                        value,
                        group: composedLabel(label, Math.round(bptBaseSize), sectionID),
                        sigtest,
                        name: itemLabel,
                        evolutionMetricValue,
                        numberOfRespondents: nrRespondents,
                    };
                });

            const processLineData = (source, sectionID) =>
                source.map(item => {
                    return {
                        name: item.itemLabel,
                        color: item.values[0].color,
                        values: item.values.map(({ value, label, sigtest, evolutionMetricValue, nrRespondents }) => ({
                            value,
                            group: composedLabel(label, null, sectionID),
                            sigtest,
                            evolutionMetricValue,
                            nrRespondents,
                        })),
                    };
                });

            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                dataBySectionID[section.sectionID] = section.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.COMBINED) {
                        const extractedBarData = currentKpy.items.find(item => item.itemId === AXA_AND_AXABRANDS_ID);
                        let barProcessedData = [];

                        if (extractedBarData) {
                            barProcessedData = processBarData(extractedBarData.values, section.sectionID);
                        }

                        const lineProcessedData = processLineData(
                            currentKpy.items.filter(item => item.itemId !== AXA_AND_AXABRANDS_ID),
                            section.sectionID
                        );

                        accumulatedData.push({
                            barData: { values: barProcessedData, name: extractedBarData && extractedBarData.itemLabel },
                            lineData: lineProcessedData,
                            chartTitle: currentKpy.kpiLabel,
                            numberOfRespondents: barProcessedData.map(value => ({
                                group: value.group,
                                numberOfRespondents: value.numberOfRespondents,
                            })),
                        });
                        return accumulatedData;
                    }
                    return accumulatedData;
                }, []);
            });

            return dataBySectionID;
        }
        return null;
    }
);

export const getLineChartDataWithoutSubsectionComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];
        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            const dataBySectionID = {};

            const processLineData = source =>
                source.map(item => {
                    return {
                        name: item.itemLabel,
                        color: item.values[0].color,
                        values: item.values.map(({ value, label, sigtest, evolutionMetricValue, nrRespondents }) => ({
                            value,
                            group: label,
                            sigtest,
                            evolutionMetricValue,
                            numberOfRespondents: nrRespondents,
                        })),
                    };
                });

            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                dataBySectionID[section.sectionID] = section.kpis.reduce((accumulatedData, currentKpy) => {
                    if (currentKpy.kpiType === KPI_TYPES.LINE_CHART) {
                        const lineProcessedData = processLineData(currentKpy.items);
                        accumulatedData.push({
                            lineData: lineProcessedData,
                            numberOfRespondents: lineProcessedData[0].values.map(value => ({
                                group: value.group,
                                numberOfRespondents: value.numberOfRespondents,
                            })),
                        });
                        return accumulatedData;
                    }
                    return accumulatedData;
                }, []);
            });
            return dataBySectionID;
        }
        return null;
    }
);

export const getSimpleBarChartDataComputed = createSelector(
    [getBptPreferenceAnalysisDataSelector, getLocationSelector],
    (preferenceAnalysisData, location) => {
        const _storeKey = STORE_NAME_ROUTE_BASED[location];
        const subTabStoreData = preferenceAnalysisData[_storeKey];

        const processChartData = inputData => {
            let ouputData = [];

            if (!inputData || !inputData.length) return [];

            ouputData = inputData.reduce((acc, el) => {
                const { items } = el;
                if (!items.length || !items[0].values.length) return acc;

                items[0].values.forEach(row => {
                    acc.push({
                        value: row.value,
                        name: row.label,
                        color: row.color,
                        sigtest: row.sigtest,
                        title: el.kpiLabel,
                        order: row.order,
                        tooltip: row.tooltip,
                        evolutionMetricValue: row.evolutionMetricValue,
                        nrRespondents: row.nrRespondents,
                    });
                });
                return acc;
            }, []);
            return ouputData;
        };

        if (subTabStoreData && subTabStoreData.hasOwnProperty('sections') && subTabStoreData.sections) {
            const simpleBarChartDataBySectionID = {};
            subTabStoreData.sections.forEach(section => {
                if (!section.kpis) return;
                simpleBarChartDataBySectionID[section.sectionID] = section.kpis.reduce((acc, eachSection) => {
                    if (eachSection.kpiType === KPI_TYPES.SIMPLE_BARCHART) {
                        acc.push([{ ...eachSection }]);
                    }
                    return acc;
                }, []);
            });

            const simpleBarChartData = {};
            for (const sectionId in simpleBarChartDataBySectionID) {
                simpleBarChartData[sectionId] = {};
                simpleBarChartData[sectionId].year = processChartData(simpleBarChartDataBySectionID[sectionId][0]);
                simpleBarChartData[sectionId].period = processChartData(simpleBarChartDataBySectionID[sectionId][1]);
            }
            return simpleBarChartData;
        }
        return [];
    }
);

/*
    Used on BrandPreferenceTracker to extract the selected country from the filter group to be passed as a parameter
    to also retrieve the side filters data. If no data is selected
 */
export const getSelectedCountryFromTopFiltersComputedSelector = createSelector(
    [getSelectedTopFiltersByPageSelector, getLocationSelector, getUserDetailsSelector],
    (selectedFilters, location, userDetails) => {
        const COUNTRY_GROUP_ID = 2;
        if (selectedFilters) {
            /* If filters have loaded it means that we have a property with the page path in the selected filters */
            if (
                Object.keys(selectedFilters).find(el => el.includes(BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path))
            ) {
                const currentCountry = selectedFilters[BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path].find(
                    el => el.groupId === COUNTRY_GROUP_ID
                );
                return { countryId: currentCountry.id };
            } else {
                /* If filters have not loaded yet, get the user's country from userInformation */
                return { countryId: userDetails.countryId[0] };
            }
        }
        return {};
    }
);
