import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { checkAndConvertToNAValue } from '../../../../utils/utils';

export const BARCHART_SETTINGS = {
    STYLE: {
        backgroundColor: defaultTheme.blue[50],
        barWidth: 20,
        barLabelMainAxisOffset: 28,
        barLabelSecondaryAxisOffset: -3,
        fontWeight: 600,
        barFontSize: 13,
    },
    HEIGHT: 55,
    DOMAIN: 100,
    FORMATTER: value => checkAndConvertToNAValue(value, '%'),
};
