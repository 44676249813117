import { connect } from 'react-redux';
import AdsComparison from './AdsComparison';
import { hasValidAdsComparisonSelectedOptionsComputedSelector } from './data/computedSelectors';
import { getSideFiltersSelector } from '../../../../common/components/SideFilters/data/selectors';
import { getAdComparisonActionCreator } from './data/actions';
import { getFiltersThrownError, getPageRelatedToFiltersSelector } from '../../../../common/data/selectors';
import ComputedDataInstance from './data/computedSelectors';

const mapStateToProps = state => ({
    data: ComputedDataInstance(state),
    hasSideFiltersLoaded: Object.keys(getSideFiltersSelector(state)).length > 0,
    hasValidOptionsSelected: hasValidAdsComparisonSelectedOptionsComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    filtersFor: getPageRelatedToFiltersSelector(state),
});

const mapDispatchToProps = {
    getData: getAdComparisonActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsComparison);
