import styled from 'styled-components';

export const ChartPickerWrapper = styled.div`
    margin: 0 auto 18px auto;
    width: 612px;
    height: 85px;
    background: white;
    box-sizing: border-box;
    justify-content: space-around;
    padding: 0px 22px;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 8px #00000029;
    font-size: 14px;
`;
