export const TOLUNA_ICONS = {
    CALCULATE: 'toluna-icon-calculate',
    CLOSE: 'toluna-icon-close',
    CLOSE_THIN: 'toluna-icon-close-thin',
    COMMUNITY: 'toluna-icon-community',
    COMPUTED_ICON: 'toluna-icon-computed-icon',
    DELETE: 'toluna-icon-delete',
    DRAG: 'toluna-icon-drag',
    DUPLICATE: 'toluna-icon-duplicate',
    EDIT_HOVER: 'toluna-icon-edit-hover',
    EXPORT_ICON: 'toluna-icon-export-icon',
    FILTER: 'toluna-icon-filter',
    FONT_TEST_SIZE: 'toluna-icon-font-test-size',
    IF_PIN: 'toluna-icon-if-pin-216360',
    MENU: 'toluna-icon-menu',
    MERGE: 'toluna-icon-merge',
    MINUS: 'toluna-icon-minus',
    MULTIPLE: 'toluna-icon-multiple',
    PLUS: 'toluna-icon-plus',
    REVIEW_WORK_ORDER: 'toluna-icon-review-work-order',
    SCRIPTING: 'toluna-icon-scripting',
    SINGLE: 'toluna-icon-single-02',
    SMALL_MINUS: 'toluna-icon-small-minus',
    SMALL_PLUS: 'toluna-icon-small-plus',
    SURVEY: 'toluna-icon-survey',
    TAG_REMOVE: 'toluna-icon-tag-remove',
    TIP: 'toluna-icon-tip',
    VIEW_REPORT: 'toluna-icon-view-report',
    WAVES: 'toluna-icon-waves',
    AVATAR: 'toluna-icon-avatar',
    DATA_01: 'toluna-icon-data-01',
    DATA_02: 'toluna-icon-data-02',
    DATA_03: 'toluna-icon-data-03',
    DATA_04: 'toluna-icon-data-04',
    BEHAVIORAL_DATA: 'toluna-icon-behavioral-data',
    PROJECTS: 'toluna-icon-projects',
    REPORTS: 'toluna-icon-reports',
    ADMIN: 'toluna-icon-admin',
    DOTS: 'toluna-icon-dots',
    REVIEW_PROJECT_CHANGE: 'toluna-icon-review-project-change',
    CROWN:'toluna-icon-crown-icon',
    MESSEAGES: 'toluna-icon-messages'
}