import React from 'react';
import { LineGraphOptionLine, LineGraphOptionsWrapper } from './LineGraph.styles';
import Checkbox from '../../../../../common/components/Checkbox/Checkbox';
import { defaultTheme } from '../../../../../utils/defaultTheme';

const LineGraphOptions = ({ options, onOptionChange }) => {
    return (
        <LineGraphOptionsWrapper data-testid="LineGraphOptionsWrapper">
            {options.map(e => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox
                        onClick={() => onOptionChange(e.id)}
                        value={e.checked}
                        label={e.name}
                        textColor={defaultTheme.blue[950]}
                    />
                    <LineGraphOptionLine color={e.color} />
                </div>
            ))}
        </LineGraphOptionsWrapper>
    );
};

export default LineGraphOptions;
