import styled from '@emotion/styled';

export const StyledInput = styled.input`
    width: 100%;
    height: 34px;
    margin: 0px;
    padding: 0 15px;
    border: 1px solid ${props => props.error? `#e57a7a` : `#cccccc`};
    border-radius: 3px;
    color: ${props => props.disabled ? `#7e8389` : `#2d3643`};
    background-color: ${props => props.error? `#ffe3e3` : `#fff`};
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.1);
    outline: none;
    text-overflow: ellipsis;
    font-family: MuseoSans-300;
    font-size: 15px;
    box-sizing: border-box;
    transition: all 0.2s;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button {
        display: none;
        -webkit-appearance: none;
    }
    :focus {
        border: 1px solid ${props => props.error ? `#e57a7a` : props.theme.lightColor};
    }
    ::placeholder {
        color: ${props => props.error ? `#2d3643` : `#b3c3da`};
    }
    :disabled{
        box-shadow: none;
        background-color: #f7f9fa;
    }
    ${props => props.customStyle || ''}
`;
