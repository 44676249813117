import React from 'react';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 700;
    padding: 2em 0 0 0;
    text-align: center;
    margin: 10px 0px;
`;

const NoDataAvailable = ({ show, text }) => {
    return show ? <Wrapper>{text}</Wrapper> : null;
};

NoDataAvailable.propTypes = {
    show: PropTypes.bool,
    text: PropTypes.string,
};

NoDataAvailable.defaultProps = {
    text: TRANSLATION_TEXT.NO_DATA_AVAILABLE_TEXT,
};

export default NoDataAvailable;
