import React, { Fragment, useMemo } from 'react';
import {
    Wrapper,
    MainSection,
    Row,
    MainTableWrapper,
    TablesWrapper,
    SideHeader,
    SectionTitle,
    InnerRow,
    Cell,
    Legend,
    SectionTitleCover,
    SecondaryTableWrapper,
    BenchmarkSection,
    UpliftValue,
    LegendItem,
    ChartWrapper,
    LegendItemColor,
    LegendRow,
} from './UpliftsSection.styles';
import { LEGEND_ID, LEGEND_MAP, MIN_SAME_TYPE_LENGTH } from './utils/utils';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from '@toluna-ui-toolkit/barchart';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { basePath } from '../../../../../../services/utils';
import { BAR_CHARTS_RESPONSIVE_WIDTHS, MEDIA_QUERY_SCREEN_SIZE } from '../../../../../../utils/responsiveness';
import Tooltip from '../../../../../brandPreferenceTracker/subpages/PreferenceAnalysis/components/Tooltip/Tooltip';
import { checkAndConvertToNAValue } from '../../../../utils/utils';

const ChartComponent = ({ values, type, hasMultipleColumns }) => {
    const [width] = useWindowSize();
    const isNotFirstColumn = type !== 'average' && type !== 'main';
    const data = values.map(e => ({
        name: e.label,
        value: e.value,
        color: isNotFirstColumn && e.order === 0 ? 'url(#filtered-lines-pattern)' : e.color,
    }));
    const HORIZONTAL_BARS_WIDTH = 18;
    const CHART_HEIGHT = 20;
    const BAR_CHARTS_WIDTH =
        width <= MEDIA_QUERY_SCREEN_SIZE.MEDIUM
            ? BAR_CHARTS_RESPONSIVE_WIDTHS.SMALL
            : BAR_CHARTS_RESPONSIVE_WIDTHS.MEDIUM - 20;
    return (
        <ChartWrapper>
            {data.map((dataElement, i) => {
                return (
                    <BarChart
                        key={i + '-chart-' + dataElement.value}
                        width={BAR_CHARTS_WIDTH - 10}
                        height={CHART_HEIGHT}
                        marginWidth={35}
                        marginHeight={2}
                        domain={100}
                        direction={BARCHART_DIRECTION.HORIZONTAL}
                        labelFormatter={d => checkAndConvertToNAValue(d, '')}
                        labelPosition={BARCHART_LABEL_POSITION.END_OF_DOMAIN}
                        styling={{
                            barWidth: HORIZONTAL_BARS_WIDTH,
                            backgroundColor:
                                isNotFirstColumn || !hasMultipleColumns ? defaultTheme.blue[50] : defaultTheme.red[100],
                            barStroke: 1,
                            barStrokeColor:
                                isNotFirstColumn || !hasMultipleColumns
                                    ? defaultTheme.blue[600]
                                    : defaultTheme.orange[700],
                            barLabelMainAxisOffset: 28,
                            barLabelSecondaryAxisOffset: -4,
                            fontWeight: 600,
                            barFontSize: 14,
                            barLabelColor: defaultTheme.blue[950],
                        }}
                        withNames={false}
                        data={[dataElement]}
                        tooltipShowSize={0}
                    />
                );
            })}
        </ChartWrapper>
    );
};

const calculateUplifts = (isTheFirstRow, values, itemLabel, hasMultipleColumns) => {
    let value = values.reduce((acc, el) => acc.value - el.value);
    let isPositive = value > 0 || (value === 0 && 'isZero');
    let valueIsZero = values.filter(element => element.value === 0).length ? true : false;

    return (
        <UpliftValue isPositive={isPositive} valueIsZero={valueIsZero}>
            {isTheFirstRow && (
                <SectionTitle isBenchmarkSection={hasMultipleColumns}>Overall campaign Uplifts</SectionTitle>
            )}
            {isTheFirstRow && (
                <SectionTitle secondaryTitle>
                    <Tooltip tooltipText={itemLabel || ''} customStyle={{ _content: { top: '-35px' } }}>
                        <p>{itemLabel}</p>{' '}
                    </Tooltip>
                </SectionTitle>
            )}
            {/*Title of the section only for the first row*/}
            {isPositive > 0 && !valueIsZero && '+'}
            {isPositive === 'isZero' && !valueIsZero ? '<1' : valueIsZero ? '-' : value}
            {(value > 1 || value < -1) && !valueIsZero ? 'pts' : isPositive !== 'isZero' && !valueIsZero ? 'pt' : ''}
        </UpliftValue>
    );
};

const calculateLegend = (dataForLegend, forTheItemType, legendIndexOrder) => {
    const hasItemTypeData = dataForLegend.items.filter(({ itemType }) => itemType === forTheItemType);
    if (!hasItemTypeData.length) return;
    switch (forTheItemType) {
        case 'main':
            return `(n = ${hasItemTypeData[0].values[legendIndexOrder].value})`;
        case 'filtered':
            return `filtered: (n = ${hasItemTypeData[0].values[legendIndexOrder].value})`;
        default:
            return;
    }
};

const UpliftsSection = ({ upliftsData, smallSizeMode, withLegendPerColumn = false }) => {
    const { kpis } = upliftsData?.sectionData;
    const { restOfColumns, kpiLabelWithGroupPair } = upliftsData;

    const hasMultipleColumns = restOfColumns && restOfColumns?.length > 1;

    const isAnyLowBase = useMemo(() => !!kpis.find(kpi => kpi.items.some(item => item.isLowBase)), [kpis]);
    const hasFilters = useMemo(() => !!kpis.find(kpi => kpi.items.some(item => item.itemType === 'filtered')), [kpis]);
    const hasAverage = useMemo(() => !!kpis.find(kpi => kpi.items.some(item => item.itemType === 'average')), [kpis]);
    const legendData = useMemo(() => kpis.find(kpi => kpi.kpiID === LEGEND_ID), [kpis]);

    const getSmallSizeOverrideStyle = widthOverride => {
        if (smallSizeMode) {
            switch (widthOverride) {
                case 'auto':
                    return {
                        width: widthOverride,
                        minWidth: widthOverride,
                    };
                default:
                    return {
                        width: widthOverride,
                    };
            }
        }
        return null;
    };

    const extractBaseSizePerKpi = () => {
        if (!kpis) return null;
        if (kpis.length === 0) return null;

        const baseSizes = {};

        kpis[0].items.forEach(({ itemLabel, values }) => {
            if (!baseSizes.hasOwnProperty(itemLabel)) {
                baseSizes[itemLabel] = {
                    recognizedSample: {
                        value: values[0].bptBaseSize,
                        color: defaultTheme.blue[600],
                        border: defaultTheme.blue[600],
                    },
                    unRecognizedSample: {
                        value: values[1].bptBaseSize,
                        color: defaultTheme.white.ff,
                        border: defaultTheme.blue[600],
                    },
                };
            }
        });

        return baseSizes;
    };

    const renderBenchmarkSection = (table, rowID) => {
        if (hasMultipleColumns) {
            return restOfColumns.map((eachCol, idx) => (
                <BenchmarkSection data-testid="Uplifts-BenchmarkSection" key={idx + rowID}>
                    {eachCol.map(
                        (el, i) =>
                            el.itemType === 'main' &&
                            el.kpiLabel === table.kpiLabel && (
                                <InnerRow key={i + el.itemType}>
                                    <Cell scoreScope>
                                        {el.isLowBase && (
                                            <img
                                                className="innerUpliftsWarningImage"
                                                src={`${basePath}/visuals/lowBaseWarning.png`}
                                            />
                                        )}
                                        {calculateUplifts(
                                            rowID === 0 && i === 0,
                                            el.values,
                                            el.itemLabel,
                                            hasMultipleColumns
                                        )}
                                    </Cell>
                                    {/* additionalWidth: If there are only two columns of the same type, add extra spacing for a improved UI. 
                                    In other cases (>3, leave flex layout to do its job.)*/}
                                    <Cell
                                        style={getSmallSizeOverrideStyle('145px')}
                                        chartScope
                                        additionalWidth={!hasAverage && restOfColumns?.length === MIN_SAME_TYPE_LENGTH}
                                    >
                                        {<ChartComponent type={el.itemType} values={el.values} />}
                                    </Cell>
                                </InnerRow>
                            )
                    )}
                </BenchmarkSection>
            ));
        } else {
            return (
                <BenchmarkSection
                    data-testid="Uplifts-BenchmarkSection"
                    areFiltered={table.items.some(el => el.itemType === 'filtered')}
                >
                    {table.items.map((el, i) => {
                        return (
                            el.itemType === 'filtered' && (
                                <InnerRow key={i + el.itemType}>
                                    <Cell scoreScope>
                                        {el.isLowBase && (
                                            <img
                                                className="innerUpliftsWarningImage"
                                                src={`${basePath}/visuals/lowBaseWarning.png`}
                                            />
                                        )}
                                        {calculateUplifts(rowID === 0 && i === 1, el.values, hasMultipleColumns)}
                                    </Cell>
                                    <Cell chartScope>
                                        {
                                            <ChartComponent
                                                hasMultipleColumns={hasMultipleColumns}
                                                type={el.itemType}
                                                values={el.values}
                                            />
                                        }
                                    </Cell>
                                </InnerRow>
                            )
                        );
                    })}
                </BenchmarkSection>
            );
        }
    };

    return (
        <Wrapper>
            <SectionTitleCover style={getSmallSizeOverrideStyle('280px')} isBenchmarkSection={hasMultipleColumns} />
            <TablesWrapper isBenchmarkSection={hasMultipleColumns}>
                <MainTableWrapper isBenchmarkSection={hasMultipleColumns}>
                    {/*This will be used on the ADSComparasion page, if filters are selected*/}
                    {kpis &&
                        kpis.length > 0 &&
                        kpis.map(
                            (table, rowID) =>
                                table.kpiID !== LEGEND_ID && (
                                    <Row key={rowID + table.kpiID}>
                                        <SideHeader>
                                            <div>{table.kpiLabel}</div>
                                        </SideHeader>
                                        <MainSection>
                                            {kpiLabelWithGroupPair.map((el, i) => {
                                                if (el.hasOwnProperty(table.kpiLabel)) {
                                                    return el[table.kpiLabel]?.map(groupDescription => {
                                                        return (
                                                            <InnerRow key={i + groupDescription}>
                                                                <Cell
                                                                    style={getSmallSizeOverrideStyle('auto')}
                                                                    descriptionScope
                                                                >
                                                                    {groupDescription}
                                                                </Cell>
                                                            </InnerRow>
                                                        );
                                                    });
                                                }
                                            })}
                                        </MainSection>
                                    </Row>
                                )
                        )}
                </MainTableWrapper>
                <SecondaryTableWrapper>
                    {kpis &&
                        kpis.length > 0 &&
                        kpis.map(
                            (table, rowID) =>
                                table.kpiID !== LEGEND_ID && (
                                    <Row key={rowID + table.kpiID}>
                                        {(!hasMultipleColumns || hasAverage) && (
                                            <MainSection>
                                                {table.items.map((el, i) => {
                                                    return (
                                                        el.itemType ===
                                                            `${hasMultipleColumns ? 'average' : 'main'}` && (
                                                            <InnerRow key={i + el.itemLabel}>
                                                                <Cell scoreScope>
                                                                    {el.isLowBase && (
                                                                        <img
                                                                            className="innerUpliftsWarningImage"
                                                                            src={`${basePath}/visuals/lowBaseWarning.png`}
                                                                        />
                                                                    )}
                                                                    {calculateUplifts(
                                                                        rowID === 0 && i === 0,
                                                                        el.values,
                                                                        el.itemLabel,
                                                                        hasMultipleColumns
                                                                    )}
                                                                </Cell>
                                                                <Cell chartScope>
                                                                    <ChartComponent
                                                                        hasMultipleColumns={hasMultipleColumns}
                                                                        values={el.values}
                                                                        type={el.itemType}
                                                                    />
                                                                </Cell>
                                                            </InnerRow>
                                                        )
                                                    );
                                                })}
                                            </MainSection>
                                        )}
                                        {renderBenchmarkSection(table, rowID)}
                                    </Row>
                                )
                        )}
                    {withLegendPerColumn && (
                        <LegendRow style={{ borderBottom: 'none' }}>
                            <InnerRow>
                                {kpis && kpis.length > 0
                                    ? (() => {
                                          const bases = extractBaseSizePerKpi();
                                          const data = kpis[0].items.map((item, i) => {
                                              const selectedItem = { ...bases[item.itemLabel] };
                                              delete bases[item.itemLabel];

                                              if (Object.keys(selectedItem).length > 0) {
                                                  return (
                                                      <Fragment key={i + item.itemLabel}>
                                                          <Cell scoreScope />
                                                          <Cell
                                                              style={{
                                                                  flexDirection: 'column',
                                                                  alignItems: 'flex-start',
                                                                  ...getSmallSizeOverrideStyle('145px'),
                                                              }}
                                                              chartScope
                                                              additionalWidth={
                                                                  Object.keys(extractBaseSizePerKpi()).length === 2
                                                              }
                                                          >
                                                              <BenchmarkSection>
                                                                  <LegendItem>
                                                                      <LegendItemColor
                                                                          color={selectedItem.recognizedSample.color}
                                                                          border={selectedItem.recognizedSample.border}
                                                                      />
                                                                      (n = {selectedItem.recognizedSample.value})
                                                                  </LegendItem>
                                                                  <LegendItem>
                                                                      <LegendItemColor
                                                                          color={selectedItem.unRecognizedSample.color}
                                                                          border={
                                                                              selectedItem.unRecognizedSample.border
                                                                          }
                                                                      />
                                                                      (n = {selectedItem.unRecognizedSample.value})
                                                                  </LegendItem>
                                                              </BenchmarkSection>
                                                          </Cell>
                                                      </Fragment>
                                                  );
                                              }
                                              return null;
                                          });
                                          return data;
                                      })()
                                    : null}
                            </InnerRow>
                        </LegendRow>
                    )}
                </SecondaryTableWrapper>
            </TablesWrapper>
            <Legend isBenchmark={true}>
                {LEGEND_MAP.map((el, i) => (
                    <LegendItem key={i + el.name}>
                        {el.name === LEGEND_MAP[0].name ? (
                            <>
                                {hasFilters && <LegendItemColor isUrl={`${basePath}/visuals/filtered.png`} />}{' '}
                                {hasMultipleColumns && hasAverage && (
                                    <LegendItemColor color={el.color[2]} border={el.color[2]} />
                                )}
                                <LegendItemColor color={el.color[1]} />{' '}
                            </>
                        ) : (
                            el.name !== LEGEND_MAP[2].name && (
                                <>
                                    {hasMultipleColumns && hasAverage && (
                                        <LegendItemColor color={el.color[0]} border={el.color[1]} />
                                    )}{' '}
                                    <LegendItemColor color={el.color[0]} />
                                </>
                            )
                        )}
                        {el.name === LEGEND_MAP[2].name ? (
                            isAnyLowBase && (
                                <span className={'isOuterLegend'}>
                                    {' '}
                                    <img
                                        className="innerLegendWarningImage"
                                        src={`${basePath}/visuals/lowBaseWarning.png`}
                                    />
                                    {el.name}
                                </span>
                            )
                        ) : (
                            <span>
                                {el.name} {legendData && !withLegendPerColumn && calculateLegend(legendData, 'main', i)}{' '}
                                {legendData && !withLegendPerColumn && calculateLegend(legendData, 'filtered', i)}
                            </span>
                        )}
                    </LegendItem>
                ))}
            </Legend>
        </Wrapper>
    );
};

export default UpliftsSection;
