import React, { useState, useRef } from 'react';
import {
    UserDropdownPanelWrapper,
    ContentWrapper,
    HiddenWrapper,
    MenuItem,
    MainContent,
    Arrow,
    Name,
    ProfileImage,
    Divider,
} from './UserDropdownPanel.styles';

import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/routes';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { useOnClickOutside } from '../../../../common/hooks/useOnClickOutside';
import { isUserAllowedAccess } from '../../../../utils/generalUtilities';
import { basePath } from '../../../../services/utils';

const UserDropdownPanel = ({ logoutActionCreator, userDetails }) => {
    const { userName, userImage, role } = userDetails;
    const [isOpen, setIsOpen] = useState(false);
    const isAdmin = isUserAllowedAccess(role, 'Admin');
    const isDashboard = location.pathname === routes.DASHBOARD_PAGE.path;

    const filterRef = useRef();
    useOnClickOutside(filterRef, () => setIsOpen(false));

    return (
        <UserDropdownPanelWrapper data-testid="UserDropdownPanel-wrapper" ref={filterRef} isOpen={isOpen}>
            <MainContent
                data-testid="UserDropdownPanel-main"
                onClick={() => setIsOpen(previous => !previous)}
                isDashboard={isDashboard}
            >
                <ContentWrapper data-testid="UserDropdownPanel-content">
                    <Arrow
                        data-testid="UserDropdownPanel-arrow"
                        src={`${basePath}/visuals/triangle.png`}
                        alt="Arrow Icon"
                        isOpen={isOpen}
                    />
                    <Name data-testid="UserDropdownPanel-name">{userName}</Name>
                </ContentWrapper>
                <ProfileImage data-testid="UserDropdownPanel-image" src={userImage} alt="User Image" />
            </MainContent>

            {isOpen && (
                <HiddenWrapper data-testid="UserDropdownPanel-hiddenWrapper">
                    {isAdmin && (
                        <>
                            <MenuItem className="adminSectionButton">
                                <Link to={routes.USER_MANAGEMENT_PAGE.path}>
                                    <img src={`${basePath}/visuals/profile_icon.png`} alt="Admin Button" />
                                    <span>{TRANSLATION_TEXT.ADMIN_TEXT}</span>
                                </Link>
                            </MenuItem>
                            <MenuItem className="backofficeSectionButton">
                                <Link to={routes.BACK_OFFICE_PAGE.path}>
                                    <img
                                        src={`${basePath}/visuals/backoffice-menu-icon.svg`}
                                        alt="BackOffice Button"
                                        style={{ width: '16px' }}
                                    />
                                    <span>{TRANSLATION_TEXT.BACK_OFFICE_TEXT}</span>
                                </Link>
                            </MenuItem>
                        </>
                    )}
                    <Divider className="secondSectionDivider" />
                    <MenuItem className="logoutSectionButton" onClick={logoutActionCreator}>
                        <a href="#">
                            <img src={`${basePath}/visuals/logout_icon.png`} alt="Log Out Button" />
                            <span>{TRANSLATION_TEXT.LOGOUT_TEXT}</span>
                        </a>
                    </MenuItem>
                </HiddenWrapper>
            )}
        </UserDropdownPanelWrapper>
    );
};

export default UserDropdownPanel;
