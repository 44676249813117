import styled from 'styled-components';

const CampaignDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 25px;
    width: 90%;
    margin: 20px 50px 20px auto;
    column-gap: 35px;
    @media screen and (max-width: 1300px) {
        padding: 25px 10px;
    }
    @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
        width: 85%;
        margin: 20px 35px 20px auto;
    }
`;

const CampaignDetailsElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 30%;
    margin: 25px 0px;
`;

const CampaignDetailsImg = styled.img`
    width: 190px;
    object-fit: cover;
    aspect-ratio: 16/9;
    cursor: pointer;
`;

const CampaignDetailsElementH3 = styled.h3`
    margin: 10px 0 0;
    color: ${({ theme }) => theme.blue[950]};
    font-size: 18px;
`;

const CampaignDetailsInfoBox = styled.div`
    h3 {
        font-size: 14px;
        color: ${({ theme }) => theme.blue[950]};
        font-weight: 700;
    }

    p {
        font-size: 14px;
        margin: 5px 0px;
        color: ${({ theme }) => theme.blue[950]};
    }
`;

export {
    CampaignDetailsWrapper,
    CampaignDetailsElementWrapper,
    CampaignDetailsImg,
    CampaignDetailsElementH3,
    CampaignDetailsInfoBox,
};
