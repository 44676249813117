import styled from 'styled-components';

export const AwarenessChartWrapper = styled.div`
    background-color: ${({ theme }) => theme.grey[50]};
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 480px;
    padding-top: 20px;
    border: 1px dashed ${({ theme }) => theme.blue[900]};
    &:first-child:nth-last-child(2) {
        //Selecting the first child when it is the nth element from it (so it has a sibling)
        margin-right: 20px;
    }
`;
export const AwarenessChartTitle = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.blue[900]};
    display: inline-block;
`;
export const AwarenessChartTitleLegend = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.blue[600]};
    margin-right: 5px;
    display: inline-block;
`;
export const AwarenessTitleWrapper = styled.div`
    height: 20px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
`;

export const BarChartWrapper = styled.div``;

export const Wrapper = styled.div`
    width: auto;
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 30px;
`;
