export const TOP_FILTERS_ON_CHANGE = '/common/topFilters/TOP_FILTERS_ON_CHANGE';
export const TOP_FILTER_ON_SELECT_ALL = '/common/topFilters/TOP_FILTER_ON_SELECT_ALL';

export const CREATE_TOP_FILTER_DEPENDENCY_ARRAY = '/common/topFilters/CREATE_TOP_FILTER_DEPENDENCY_ARRAY';
export const SET_TOP_FILTER_DEPENDENCY_ARRAY = '/common/topFilters/SET_TOP_FILTER_DEPENDENCY_ARRAY';

/* Payload for this action is based on a key-value structure. Key represents the path of the page and the value the actual data */
export const SET_SELECTED_TOP_FILTERS = '/common/topFilters/SET_SELECTED_TOP_FILTERS';
export const SET_SELECTED_TOP_OPTION = '/common/topFilters/SET_SELECTED_TOP_OPTION';
export const SET_TEMPORARY_SELECTED_TOP_OPTION = '/common/topFilters/SET_TEMPORARY_SELECTED_TOP_OPTION';

export const PROCESS_TOP_FILTERS_STARTED = '/common/topFilters/PROCESS_TOP_FILTERS_STARTED';
export const PROCESS_TOP_FILTERS_ENDED = '/common/topFilters/PROCESS_TOP_FILTERS_ENDED';
//Needed for storing the filters as they modify when the checkbox gets an onChange event
export const INIT_CACHE_LAST_APPLIED_TOP_FILTERS_OPTION =
    '/common/components/ExportButton/INIT_CACHE_LAST_APPLIED_TOP_FILTERS_OPTION';

export const CACHE_LAST_APPLIED_TOP_FILTERS_OPTION =
    '/common/components/ExportButton/CACHE_LAST_APPLIED_TOP_FILTERS_OPTION';

export const TRIGGER_FILTER_ROLLBACK_FROM_CACHE = '/common/topFilters/TRIGGER_FILTER_ROLLBACK_FROM_CACHE';

export const processTopFiltersStarted = () => ({
    type: PROCESS_TOP_FILTERS_STARTED,
});
export const processTopFiltersEnded = () => ({
    type: PROCESS_TOP_FILTERS_ENDED,
});

export const setSelectedTopFiltersActionCreator = filters => ({
    type: SET_SELECTED_TOP_FILTERS,
    payload: filters,
});

export const setSelectedTopOptionActionCreator = option => ({
    type: SET_SELECTED_TOP_OPTION,
    payload: option,
});

export const topFiltersOnChangeActionCreator = optionId => ({
    type: TOP_FILTERS_ON_CHANGE,
    payload: optionId,
});

export const topFilterOnSelectAllActionCreator = selectAllOption => ({
    type: TOP_FILTER_ON_SELECT_ALL,
    payload: selectAllOption,
});

export const createTopFilterDependencyArrayActionCreator = () => ({
    type: CREATE_TOP_FILTER_DEPENDENCY_ARRAY,
});
export const setTopFilterDependencyArrayActionCreator = dependencyArray => ({
    type: SET_TOP_FILTER_DEPENDENCY_ARRAY,
    payload: dependencyArray,
});

export const setTemporarySelectedTopOptionActionCreator = temporaryOption => ({
    type: SET_TEMPORARY_SELECTED_TOP_OPTION,
    payload: temporaryOption,
});

export const initCacheLastAppliedTopFilterOptionActionCreator = () => ({
    type: INIT_CACHE_LAST_APPLIED_TOP_FILTERS_OPTION,
});

export const cacheLastAppliedTopFilterOptionActionCreator = filters => ({
    type: CACHE_LAST_APPLIED_TOP_FILTERS_OPTION,
    payload: filters,
});

export const triggerFilterRollbackFromCacheActionCreator = payload => ({
    type: TRIGGER_FILTER_ROLLBACK_FROM_CACHE,
    payload,
});
