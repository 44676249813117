import { connect } from 'react-redux';
import Overview from './Overview';
import {
    getAdTesterOverviewDataActionCreator,
    setAdTesterOverviewSelectedCountryActionCreator,
    setSelectedAdsAdComparisonActionCreator,
} from './data/actions';
import { getAdTesterOverviewDataSelector, getAdTesterOverviewSelectedCountrySelector } from './data/selectors';
import { getMapDataWithSelectedCountries } from './data/computedSelectors';
import { setErrorNotificationDisplayActionCreator } from '../../../../errorHandling/data/actions';

const mapStateToProps = state => ({
    overviewData: getAdTesterOverviewDataSelector(state),
    countries: getMapDataWithSelectedCountries(state),
    selectedCountry: getAdTesterOverviewSelectedCountrySelector(state),
});

const mapDispatchToProps = {
    getOverviewData: getAdTesterOverviewDataActionCreator,
    onCountryApply: setAdTesterOverviewSelectedCountryActionCreator,
    clearSelected: setSelectedAdsAdComparisonActionCreator,
    onError: setErrorNotificationDisplayActionCreator,
};

const OverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);

export default OverviewContainer;
