import React, { useEffect, useCallback, useMemo } from 'react';
import CombinedBarLineChart from '../../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import LOBLegend from '../../../components/LOBLegend/LOBLegend';
import RankingTable from '../../../components/RankingTable/RankingTable';
import SectionsHead from '../../../../../../../common/components/SectionsHead/SectionsHead';
import { KPI_TYPES } from '../../../../../../../common/utils/utils';
import * as SC from './AdRecall.styles';
import * as TabLayout from '../../Tabs.styles';
import { useLineChartLOBs } from '../../hooks/useLineChartLOBs';
import { TRANSLATION_TEXT } from '../../../../../../../utils/translations';
import { customStyleLOBLegend } from './AdRecall.styles';
import TopLegend from '../../../../../../../common/components/TopLegend/TopLegend';
import EvolutionLegend from '../../../components/EvolutionLegend/EvolutionLegend';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';
import { CUSTOM_LINECHART_WIDTH_PAGE_FLAG, sectionsHeadColor } from '../../utils/utils';
import { arraysEqual } from '../../../../../../../utils/generalUtilities';
import { useCombinedLOBS } from '../../hooks/useCombinedLOBS';

const AdRecallTab = ({
    getBptPreferenceAnalysisAdRecallActionCreator,
    rankingTableData,
    adRecallData,
    combinedChartsData,
    lobsAsOptions,
    hasTopFilterOptionLoaded,
    topFiveLOBIDs,
    filtersThrownError,
    adRecallSectionIDS,
    appliedFilters,
}) => {
    useEffect(() => {
        if (hasTopFilterOptionLoaded) getBptPreferenceAnalysisAdRecallActionCreator();
    }, [hasTopFilterOptionLoaded]);
    const adRecallDataLoaded = () => adRecallData && adRecallData.hasOwnProperty('sections') && !filtersThrownError;

    const sectionIDWithFirstLOBS = adRecallSectionIDS?.[0];
    const sectionIDWithSecondaryLOBS = adRecallSectionIDS?.[1];
    const lobsForRankingTable = lobsAsOptions && lobsAsOptions[sectionIDWithFirstLOBS][KPI_TYPES.RANKING];
    const lobsForLineChart = lobsAsOptions && lobsAsOptions[sectionIDWithFirstLOBS][KPI_TYPES.COMBINED];

    //Used for select all / top 5 functionality charts
    const [legendLOBsOptions, onLOBCheckboxChange, getLineChartCheckedOptions, globalLOBUtilities] = useLineChartLOBs({
        lobsForLineChart,
    });
    //Used for charts without complex functionality and for which data that can be split along sectionIDS as identifier keys
    const [combinedChartLOBs, onCombinedLOBChange, getVisibleLOBs] = useCombinedLOBS({
        sectionIDS: adRecallSectionIDS,
        lobsAsOptions,
    });
    /* Custom LOB toggle functionality */
    const onlyTopFiveLOBsSelected = useMemo(() => {
        if (legendLOBsOptions.length > 0) {
            const idsFromLOBs = legendLOBsOptions.reduce((acc, curr) => {
                if (curr.isChecked) {
                    acc.push(curr.key);
                }
                return acc;
            }, []);
            return arraysEqual(topFiveLOBIDs, idsFromLOBs);
        }
        return false;
    }, [topFiveLOBIDs, legendLOBsOptions]);

    const toggleTopFiveLOBs = useCallback(() => {
        globalLOBUtilities.onLOBChangeByIds(topFiveLOBIDs);
    }, [topFiveLOBIDs, globalLOBUtilities]);

    const hasSideFiltersApplied = appliedFilters && appliedFilters?.length > 0;

    return (
        !filtersThrownError && (
            <>
                <TopLegend YTDIsVisbile={false} sigLegendIsVisible={!!sectionIDWithSecondaryLOBS} />
                {hasSideFiltersApplied && <EvolutionLegend />}

                {adRecallDataLoaded() && adRecallData.sections && adRecallData.sections.length > 0 ? (
                    adRecallData.sections.map(({ sectionID, sectionLabel }) => (
                        <SectionsHead
                            key={sectionID}
                            title={sectionLabel}
                            isResponsive={false}
                            customStyle={sectionsHeadColor}
                        >
                            <TabLayout.SectionRow data-testid={`AdRecall-Tab-LOBLegend-${sectionID}`}>
                                {legendLOBsOptions && legendLOBsOptions.length > 0 && combinedChartLOBs && (
                                    <LOBLegend
                                        LOBs={
                                            sectionID === sectionIDWithFirstLOBS
                                                ? legendLOBsOptions
                                                : combinedChartLOBs[sectionID]?.[KPI_TYPES.COMBINED]
                                        }
                                        sectionID={sectionID}
                                        onChangeHandler={
                                            sectionID === sectionIDWithFirstLOBS
                                                ? onLOBCheckboxChange
                                                : onCombinedLOBChange
                                        }
                                        styleObject={{
                                            _optionGroupWrapper: {
                                                ...customStyleLOBLegend,
                                                ...(sectionID === sectionIDWithSecondaryLOBS && {
                                                    justifyContent: 'center',
                                                    minHeight: '55px',
                                                }),
                                            },
                                            _legendWrapper: { width: '100%' },
                                        }}
                                        globalSelectionProps={
                                            sectionID === sectionIDWithFirstLOBS
                                                ? {
                                                      ...globalLOBUtilities,
                                                      /* Overwrite onLOBChangeByIds with a local function that has top five LOBs 'baked in' */
                                                      onLOBChangeByIds: toggleTopFiveLOBs,
                                                      customGlobalOptions: [
                                                          {
                                                              itemLabel: TRANSLATION_TEXT.BPT_LOB_LEGEND_TOP_FIVE,
                                                              isChecked: onlyTopFiveLOBsSelected,
                                                          },
                                                      ],
                                                  }
                                                : null
                                        }
                                    />
                                )}
                            </TabLayout.SectionRow>
                            {combinedChartsData && (
                                <TabLayout.SectionRow
                                    style={{ ...(sectionID === sectionIDWithFirstLOBS && { marginTop: '60px' }) }}
                                >
                                    <TabLayout.SectionItem style={{ justifyContent: 'flex-start' }}>
                                        <SC.ChartWrapper data-testid={`AdRecall-Tab-CombinedBarLineChart-${sectionID}`}>
                                            {combinedChartLOBs &&
                                                combinedChartsData[sectionID]?.map(d => (
                                                    <CombinedBarLineChart
                                                        key={sectionID + d.chartTitle}
                                                        visibleLOBs={
                                                            sectionID === sectionIDWithFirstLOBS
                                                                ? getLineChartCheckedOptions
                                                                : getVisibleLOBs(sectionID)
                                                        }
                                                        titleObject={
                                                            sectionIDWithSecondaryLOBS === sectionID
                                                                ? {
                                                                      customHTML: () => <span>{d.chartTitle}</span>,
                                                                      wrapperCustomStyle: {},
                                                                  }
                                                                : null
                                                        }
                                                        lineData={d.lineData}
                                                        barData={d.barData}
                                                        customWidthOnPage={CUSTOM_LINECHART_WIDTH_PAGE_FLAG.ADRECALL}
                                                        customHeight={
                                                            sectionIDWithSecondaryLOBS === sectionID ? 450 : 700
                                                        }
                                                        showSigTest={true}
                                                        showBrandName={true}
                                                        customLineTooltip={{ tooltipFontColor: defaultTheme.blue[900] }}
                                                        fluidWidth={sectionID === sectionIDWithSecondaryLOBS}
                                                        numberOfRespondents={
                                                            sectionID === 1 ? d.numberOfRespondents : []
                                                        }
                                                    />
                                                ))}
                                        </SC.ChartWrapper>
                                    </TabLayout.SectionItem>
                                    {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                        <TabLayout.SectionItem>
                                            <SC.RankTableWrapper data-testid={'AdRecall-Tab-RankingTable'}>
                                                <RankingTable
                                                    rankingData={[rankingTableData[sectionID][0]]}
                                                    lobOptions={lobsForRankingTable}
                                                    styleObject={{ margin: '-40px 0px 0px 20px' }}
                                                    hasNumberOfRespondents={true}
                                                    hasSideFiltersApplied={hasSideFiltersApplied}
                                                />
                                                <div style={{ position: 'relative', width: '95%', textAlign: 'end' }}>
                                                    <SC.RankingFooter>
                                                        {TRANSLATION_TEXT.RANKING_FOOTER_TEXT}
                                                    </SC.RankingFooter>
                                                </div>
                                            </SC.RankTableWrapper>
                                        </TabLayout.SectionItem>
                                    )}
                                </TabLayout.SectionRow>
                            )}
                        </SectionsHead>
                    ))
                ) : (
                    <SC.NoDataAdRecall>{TRANSLATION_TEXT.NO_DATA_AVAILABLE_TEXT}</SC.NoDataAdRecall>
                )}
            </>
        )
    );
};

export default AdRecallTab;
