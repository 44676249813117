import React, { useEffect } from 'react';
import LoginPage from './pages/login/login.container';
import AppRouter from './common/components/AppRouter/AppRouter';
import LoginStatusProvider from './pages/login/components/LoginStatus.provider';
import Loader from './common/components/Loader/components/Loader';
import GlobalStyle from './utils/globalStyle';
import DisabledComponents from './utils/disabledComponentsController';
import { basePath } from './services/utils';
import { injectAdditionalHTMLTags, isMaintenance } from './utils/generalUtilities';
import { useLocation } from 'react-router-dom';
import { MAINTENANCE_PAGE_OVERRIDE } from './utils/routes';
/* Application Entrypoint */
const AxaDashboard = () => {
    useEffect(() => {
        if (!basePath) return;
        injectAdditionalHTMLTags(basePath);
    }, [basePath]);

    const location = useLocation();
    const isMaintenanceOverride = location.pathname === MAINTENANCE_PAGE_OVERRIDE.path;

    return (
        <div className="mainWrapper">
            <GlobalStyle />
            <DisabledComponents>
                {isMaintenance && isMaintenanceOverride ? (
                    <LoginPage />
                ) : (
                    <LoginStatusProvider>
                        {props =>
                            props.isLoggedIn && props.isUserAllowedOnMaintenance ? (
                                <AppRouter />
                            ) : (
                                <LoginPage isMaintenance={isMaintenance} />
                            )
                        }
                    </LoginStatusProvider>
                )}
            </DisabledComponents>
            <Loader />
        </div>
    );
};

export default AxaDashboard;
