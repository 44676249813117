import React, { useState, useEffect } from 'react';
import { basePath } from '../../../../services/utils';
import { SideFilterSectionHeader, SideFilterSectionWrapper, SideFiltersSectionContent } from './SideFilters.styles';

const SideFiltersSection = ({
    title,
    children,
    isSubmenu,
    colorTheme,
    isLastItem,
    isOpen,
    onToggle,
    disabled,
    isSingleSectionSelection,
}) => {
    const [localIsOpen, setLocalIsOpen] = useState(isOpen || false);

    useEffect(() => {
        if (isOpen !== localIsOpen) {
            setLocalIsOpen(isOpen);
        }
    }, [isOpen]);

    useEffect(() => {
        if (disabled && isOpen) {
            onToggle();
        }
    }, [disabled, isOpen]);

    return (
        <SideFilterSectionWrapper isSubmenu={isSubmenu}>
            <SideFilterSectionHeader
                isOpen={localIsOpen}
                colorTheme={colorTheme}
                isSubmenu={isSubmenu}
                isLastItem={isLastItem}
                disabled={disabled}
                onClick={() => {
                    /* When only one section is open, the open status is controlled only via Redux */
                    !isSingleSectionSelection && setLocalIsOpen(prev => !prev);
                    onToggle();
                }}
            >
                <div>
                    <p>{title}</p>
                    <img src={`${basePath}/visuals/triangle.png`} alt="Triangle Icon" />
                </div>
            </SideFilterSectionHeader>
            {localIsOpen ? (
                <SideFiltersSectionContent colorTheme={colorTheme} isSubmenu={isSubmenu}>
                    {children}
                </SideFiltersSectionContent>
            ) : null}
        </SideFilterSectionWrapper>
    );
};

export default SideFiltersSection;
