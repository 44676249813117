import styled from 'styled-components';
import { defaultTheme } from '../../../../../../utils/defaultTheme';

export const SVGStyled = styled.svg`
    background: ${defaultTheme.grey[50]};
    border: 1px dashed ${defaultTheme.blue[900]};
    overflow: visible;
`;
export const Wrapper = styled.div`
    .tooltipInformation {
        width: 109px;
        height: 79px;
        box-shadow: 0px 0px 8px #00000026;
        display: flex;
        box-sizing: border-box;
        padding: 8px 0px;
        background: white;
        pointer-events: none;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: ${defaultTheme.blue[900]};
        flex-direction: column;
        align-items: center;
    }

    .tooltip-long {
        height: 95px;
    }

    .tooltipInformation:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 15px;
        width: 0;
        height: 0;
        border-top: 10px solid white;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }

    .tooltip-long:after {
        bottom: 0px;
    }

    .brandColor {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .yearLabel {
        margin: 0;
        font-size: 11px;
        color: ${defaultTheme.blue[300]};
    }
`;
