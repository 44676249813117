import styled from 'styled-components';

const ChartWrapper = styled.div`
    padding-bottom: 7rem;
    padding-top: 4rem;
`;

const LegendWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: 2rem auto;
    width: ${({ width }) => `${width}px` || '100%'};
`;

export { LegendWrapper, ChartWrapper };
