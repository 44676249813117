import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import { SVGStyled, Wrapper } from './LargeStackedChart.styles';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import { shortenYearLabel } from '../../../../utils/utils';

const PADDING_HORIZONTAL = 70;
const PADDING_VERTICAL = 40;

const LargeStackedChart = ({ data, brands, colors, calculateWidth, height, chartID }) => {
    const svgReg = useRef(null);
    const [width] = useWindowSize();

    const chartWidth = useMemo(() => calculateWidth(width), [width, calculateWidth]);

    const calculateDomain = () => d3.max(data, d => brands.reduce((acc, curValue) => acc + d[curValue] * 1.3, 0));
    const drawTooltip = (xPosition, yPosition, _data, brandData) => {
        const tooltipClass = brandData.brand.length >= 30 ? 'tooltipInformation tooltip-long' : 'tooltipInformation';
        const tooltipYOffset = brandData.brand.length >= 30 ? -17 : 0;

        const tooltip = d3.select(svgReg.current).select('.large-stacked-chart-tooltip');
        tooltip.attr('transform', 'translate(' + xPosition + ',' + (yPosition + tooltipYOffset) + ')');

        const textToShow = _data[1] - _data[0];
        const { shortenedLabel, shortenedYear } = shortenYearLabel(_data.data.name);

        tooltip.select('foreignObject').html(
            () =>
                `<div class="${tooltipClass}">
                    <div class="brandColor">
                      <div class='brandColor' style="width:10px;height:10px;background:${brandData.color}"></div>
                    </div>
                    <div>${brandData.brand}</div>
                    <div>
                        <p style="margin:0">${textToShow}</p>
                    </div> 
                    <div>
                        <p class="yearLabel">${shortenedLabel} ${shortenedYear}</p>
                    </div>    
            </div>`
        );
    };

    const drawStackedChart = (layers, xScale, yScale) => {
        layers
            .selectAll('rect')
            .data(d => d)
            .join('rect')
            .merge(layers)
            .attr('width', xScale.bandwidth() / 2)
            .attr('y', d => yScale(d[1]))
            .attr('x', d => xScale(d.data.name))
            .attr('height', d => yScale(d[0]) - yScale(d[1]))
            .on('mouseover', () => {
                d3.select(svgReg.current).select('.large-stacked-chart-tooltip').style('display', null);
            })
            .on('mouseout', () => {
                d3.select(svgReg.current).select('.large-stacked-chart-tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const xPosition = d3.pointer(d)[0] - 54;
                const yPosition = d3.pointer(d)[1] - 83;

                /* Brand color can also be interpeted as brand ID in this situation.
                   Extract color from UI and search the brand with that color.
                */
                const brandColorFromRect = d3
                    .color(d3.select(this).style('fill') || d3.select(this.parentNode).style('fill'))
                    .hex();
                const brandColorIndex = colors.findIndex(c => c.toUpperCase() === brandColorFromRect.toUpperCase());

                const brandData = {
                    color: brandColorFromRect,
                    brand: brands[brandColorIndex],
                };
                drawTooltip(xPosition, yPosition, datum, brandData);
            });
    };

    useEffect(() => {
        const svg = d3.select(svgReg.current);
        svg.selectAll('*').remove();

        /* Initialize the data groups */
        let stackGen = d3.stack().keys(brands);
        let stackedSeries = stackGen(data);
        let colorScale = d3.scaleOrdinal().domain(brands).range(colors);

        /* Create scales and axis */
        let xScale = d3
            .scaleBand()
            .range([PADDING_HORIZONTAL, chartWidth])
            .domain(data.map(d => d.name))
            .padding(0.2);

        const xAxisElement = svg
            .append('g')
            .attr('transform', 'translate(0,' + (height - 30) + ')')
            .call(d3.axisBottom(xScale).tickSizeOuter(0).tickSizeInner(0));
        xAxisElement.selectAll('.tick line').remove();
        xAxisElement.selectAll('path').remove();

        /* Replace ticks with foreign objects to allow HTML editing */
        xAxisElement.selectAll('text').remove();
        xAxisElement
            .selectAll('.tick')
            .append('foreignObject')
            .attr('class', d => 'bar-label-' + d)
            .attr('width', 60)
            .attr('height', 90)
            .style('word-wrap', 'break-word')
            .style('font-size', 11)
            .style('text-align', 'center')
            .style('font-weight', 600)
            .style('color', defaultTheme.blue[950])
            .style('transform', `translate(-38px, 0px)`)
            .html(d => {
                const { shortenedLabel, shortenedYear } = shortenYearLabel(d);
                return `<div><p style="margin:0">${shortenedLabel}</p><p style="margin:0">${shortenedYear}</p></div>`;
            });

        let yScale = d3
            .scaleLinear()
            .domain([0, calculateDomain()])
            .range([height - PADDING_VERTICAL, PADDING_VERTICAL]);

        const yAxisElement = svg
            .append('g')
            .attr('transform', `translate(40,0)`)
            .style('stroke-dasharray', '3 3')
            .attr('class', 'axis')
            .style('color', defaultTheme.grey[250])
            .call(d3.axisLeft().scale(yScale).tickFormat(d3.format('d')).tickSizeOuter(0).tickSizeInner(-chartWidth));
        yAxisElement.selectAll('text').style('fill', defaultTheme.blue[900]).style('font-weight', '600');
        yAxisElement.select('.tick').remove();
        yAxisElement.selectAll('path').remove();

        /* Create the groups for the bars */
        let layers = svg
            .selectAll('g.series')
            .data(stackedSeries)
            .join('g')
            .classed('series', true)
            .style('fill', d => colorScale(d.key));

        /* Draw the stacked bars */
        drawStackedChart(layers, xScale, yScale);
    }, [data, chartWidth]);

    /* Add the tooltip */
    useEffect(() => {
        const svg = d3.select(svgReg.current);
        svg.select('.large-stacked-chart-tooltip').remove();
        let tooltip = svg.append('g').attr('class', 'large-stacked-chart-tooltip').style('display', 'none');

        tooltip.append('foreignObject').attr('width', 110).attr('height', 102).style('pointer-events', 'none');
    }, [data, chartWidth]);

    return (
        <Wrapper data-testid={`LargeStackedChart-${chartID}`}>
            <SVGStyled width={chartWidth} height={height} ref={svgReg}>
                <g />
            </SVGStyled>
        </Wrapper>
    );
};

export default LargeStackedChart;
