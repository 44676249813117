import { combineReducers } from 'redux';
import {
    GET_ADTESTER_OVERVIEW_DATA_ASYNC,
    SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY,
    SET_SELECTED_ADS_COMPARISON_ADS,
} from './actions';

const overviewReducer = (state = null, action) => {
    switch (action.type) {
        case GET_ADTESTER_OVERVIEW_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

const selectedCountryReducer = (state = null, action) => {
    switch (action.type) {
        case SET_AD_TESTER_OVERVIEW_SELECTED_COUNTRY:
            return action.payload;
        default:
            return state;
    }
};

const selectedComparisonAdsReducer = (state = [], action) => {
    switch (action.type) {
        case SET_SELECTED_ADS_COMPARISON_ADS:
            return action.payload;
        default:
            return state;
    }
};
export const adTesterOverviewReducer = combineReducers({
    data: overviewReducer,
    selectedCountry: selectedCountryReducer,
    selectedComparisonAds: selectedComparisonAdsReducer,
});
