import styled from 'styled-components';

export const BlurBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    background-color: ${({ theme }) => theme.blue['400-4d']};
`;

export const ModalWrapper = styled.div`
    background-color: ${({ theme }) => theme.white.ff};
    position: relative;
    width: ${props => (props.modalWidth ? props.modalWidth : '721px')};
    height: ${props => (props.modalHeight ? props.modalHeight : '284px')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0 3px 8px ${({ theme }) => theme.black[29]};
`;

export const CloseButton = styled.button`
    display: ${props => (props.isAbleToDismiss ? 'block' : 'none')};
    position: absolute;
    top: ${props => (props.closeButtonTopPosition ? props.closeButtonTopPosition : '20px')};
    right: ${props => (props.closeButtonRightPosition ? props.closeButtonRightPosition : '14px')};
    background-color: transparent;
    border: none;

    outline: none;
    cursor: pointer;

    img {
        border-radius: 50%;
        &:hover {
            background-color: ${({ theme }) => theme.grey[50]};
        }
    }
`;

export const TextWrapper = styled.div`
    margin-top: ${({ marginTopText }) => (marginTopText ? marginTopText : '68px')};
    overflow-y: ${({ overflowText }) => overflowText && overflowText};
`;

export const Text = styled.p`
    text-align: center;
    font-size: ${({ textFontSize }) => (textFontSize ? textFontSize : '20px')};
    color: ${({ theme }) => theme.blue[900]};
    margin: ${({ textMargin }) => (textMargin ? textMargin : '20px')};
    text-align: ${({ textAlign }) => textAlign};
    word-break: break-word;
`;
export const Title = styled.p`
    text-align: center;
    font-size: ${({ titleFontSize }) => (titleFontSize ? titleFontSize : '22px')};
    font-weight: 600;
    position: ${({ titleAsHeader }) => titleAsHeader && 'absolute'};
    top: ${({ titleAsHeader }) => titleAsHeader && '9px'};
    left: ${({ titleAsHeader }) => titleAsHeader && '48%'};
    transform: ${({ titleAsHeader }) => titleAsHeader && 'translateX(-50%)'};
    color: ${props => (props.titleColor ? props.titleColor : props.theme.blue[900])};
    margin: 15px;
`;
export const TextBorder = styled.div`
    display: ${({ titleAsHeader }) => (titleAsHeader ? 'block' : 'none')};
    width: 100%;
    height: 2px;
    position: absolute;
    top: 65px;
    border-bottom: solid 1px ${({ theme }) => theme.grey[200]};
    &:after {
        position: absolute;
        content: '';
        width: 95%;
        top: ${({ modalHeight }) => (modalHeight ? modalHeight : '284px')};
        margin-top: -160px;
        box-shadow: 13px -1px 15px 8px ${({ theme }) => theme.white.ff};
    }
`;
const Button = styled.button`
    width: 104px;
    height: 47px;
    border: 1px solid ${({ theme }) => theme.blue[900]};
    font-size: 16px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

export const PrimaryButton = styled(Button)`
    background-color: ${({ theme, buttonBackground }) => (buttonBackground ? buttonBackground : theme.blue[600])};
    width: ${({ primaryButtonWidth }) => primaryButtonWidth};
    color: ${({ theme, buttonBackground }) => (buttonBackground ? theme.blue[900] : theme.white.ff)};
    margin: ${props => (props.singleButton ? '0 auto' : '0')};
    font-family: inherit;
`;

export const SecondaryButton = styled(Button)`
    display: ${props => (props.singleButton ? 'none' : 'inline')};
    width: ${({ secondaryButtonWidth }) => secondaryButtonWidth};
    background-color: ${({ theme }) => theme.white.ff};
    color: ${({ theme }) => theme.blue[900]};
    font-family: inherit;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: ${({ buttonAlign }) => (buttonAlign ? buttonAlign : 'space-between')};
    margin: ${({ buttonMargin }) => (buttonMargin ? buttonMargin : '0 80px 50px 80px')};
    z-index: 1;
`;
