import React from 'react';
import * as SC from './LOBLegend.styles';
import PropTypes from 'prop-types';
import Checkbox from '../../../../../../common/components/Checkbox/Checkbox';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import Tooltip from '../Tooltip/Tooltip';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';

const LOBLegend = ({ LOBs, onChangeHandler, sectionID, styleObject, globalSelectionProps }) => {
    return (
        <SC.LegendWrapper
            data-testid={`Awareness-Tab-Section-LOB-Legend-Wrapper-${sectionID}`}
            styleObject={styleObject?._legendWrapper}
        >
            {globalSelectionProps && (
                <SC.GlobalOptionsWrapper>
                    <SC.OptionGroupWrapper style={{ justifyContent: 'start' }}>
                        {/* Select All Checkbox */}
                        <SC.OptionWrapper
                            labelWidth={'108px'}
                            data-testid={`LOBLegend-Custom-Checkbox-SelectAll-Wrapper`}
                        >
                            <Checkbox
                                value={globalSelectionProps.isAllSelected}
                                label={''}
                                onClick={globalSelectionProps.onLOBChangeAll}
                                padding="10px 4px"
                                textColor={defaultTheme.blue[900]}
                                key={''}
                            />
                            <div>
                                <Tooltip tooltipText={TRANSLATION_TEXT.BPT_LOB_LEGEND_TOGGLE_ALL}>
                                    <label
                                        style={{ marginBottom: '-3px' }}
                                        title={TRANSLATION_TEXT.BPT_LOB_LEGEND_TOGGLE_ALL}
                                    >
                                        {TRANSLATION_TEXT.BPT_LOB_LEGEND_TOGGLE_ALL}
                                    </label>
                                </Tooltip>
                            </div>
                        </SC.OptionWrapper>
                        {/* Custom checkbox options which can be passed from customGlobalOptions prop */}
                        {globalSelectionProps.hasOwnProperty('customGlobalOptions') &&
                            globalSelectionProps.customGlobalOptions.map(globalOption => (
                                <SC.OptionWrapper
                                    key={`${globalOption.key}-globalOption`}
                                    data-testid={`LOBLegend-Custom-Checkbox-Wrapper-${globalOption.itemLabel}`}
                                >
                                    <SC.OptionWrapper labelWidth={'108px'}>
                                        <Checkbox
                                            value={globalOption.isChecked}
                                            label={''}
                                            onClick={globalSelectionProps.onLOBChangeByIds}
                                            padding="10px 4px"
                                            textColor={defaultTheme.blue[900]}
                                            key={''}
                                        />
                                        <div>
                                            <Tooltip tooltipText={globalOption.itemLabel}>
                                                <label style={{ marginBottom: '-3px' }} title={globalOption.itemLabel}>
                                                    {globalOption.itemLabel}
                                                </label>
                                            </Tooltip>
                                        </div>
                                    </SC.OptionWrapper>
                                </SC.OptionWrapper>
                            ))}
                    </SC.OptionGroupWrapper>
                </SC.GlobalOptionsWrapper>
            )}
            <SC.OptionGroupWrapper styleObject={styleObject?._optionGroupWrapper}>
                {LOBs?.map(lob => (
                    <SC.OptionWrapper key={lob.key} data-testid={`LOBLegend-Checkbox-Wrapper-${lob.itemLabel}`}>
                        <Checkbox
                            value={lob.isChecked}
                            label={''}
                            onClick={() => onChangeHandler(lob.key, !lob.isChecked, sectionID)}
                            padding="10px 4px"
                            textColor={defaultTheme.blue[900]}
                            key={lob.key}
                        />
                        <div>
                            <SC.LegendLine legendColor={lob.color}>
                                <span />
                            </SC.LegendLine>
                            <Tooltip tooltipText={lob.itemLabel}>
                                <label style={{ marginBottom: '-3px' }} title={lob.itemLabel}>
                                    {lob.itemLabel}
                                </label>
                            </Tooltip>
                        </div>
                    </SC.OptionWrapper>
                ))}
            </SC.OptionGroupWrapper>
        </SC.LegendWrapper>
    );
};

LOBLegend.propTypes = {
    LOBs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number,
            itemLabel: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            isChecked: PropTypes.bool.isRequired,
        })
    ).isRequired,
    sectionID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    globalSelectionProps: PropTypes.shape({
        isAllSelected: PropTypes.bool.isRequired,
        onLOBChangeAll: PropTypes.func.isRequired,
        onLOBChangeByIds: PropTypes.func,
    }),
};

LOBLegend.defaultProps = {
    LOBS: [],
    globalSelectionProps: null,
};

export default LOBLegend;
