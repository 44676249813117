import { connect } from 'react-redux';
import SessionExpiredModal from './SessionExpiredModal';
import { sessionExpiredModalStatusSelector } from './data/selectors';
import { logoutActionCreator } from '../../../pages/login/data/actions';
import { toggleSessionExpiredModalActionCreator } from './data/actions';
const mapStateToProps = state => ({
    isModalActive: sessionExpiredModalStatusSelector(state),
});

const mapDispatchToProps = {
    onApplyAction: logoutActionCreator,
    toggleModal: toggleSessionExpiredModalActionCreator,
};

const SessionExpiredModalContainer = connect(mapStateToProps, mapDispatchToProps)(SessionExpiredModal);

export default SessionExpiredModalContainer;
