import React from 'react';
import { PropTypes } from 'prop-types';
import Legend from './../../../../../../common/components/Legend/Legend';
import SectionInfo from './../../../../components/SectionInfo';
import SectionsHead from '../../../../../../common/components/SectionsHead/SectionsHead';
import { calculateWidthLargeCombinedChartMarketOverviewCompetitive } from './../../../../../../utils/responsiveness';
import * as SC from './../../CompetitiveMedia.styles';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import CombinedStackedBarLineChart from '../../../../../../common/components/CombinedStackedBarLineChart/CombinedStackedBarLineChart';
import { sectionsHeadColor } from '../../../../utils/utils';

const MarketOverviewGroup = ({ marketOverviewData }) => {
    const { stackedData, color, chartTitle, legend, lineData, sectionSideNote, sectionInfo, sectionLabel } =
        marketOverviewData || {};
    return (
        <SectionsHead title={sectionLabel?.toUpperCase()} isResponsive={true} customStyle={sectionsHeadColor}>
            <SC.SectionRow>
                <SC.SectionItems>
                    <SC.SectionSingularItem>
                        <CombinedStackedBarLineChart
                            barData={stackedData}
                            lineData={lineData}
                            color={color}
                            chartTitle={chartTitle}
                            customHeight={400}
                            hideGridLines
                            lineScalingOn
                            customWidthOnPage={calculateWidthLargeCombinedChartMarketOverviewCompetitive}
                            leftLabel={TRANSLATION_TEXT.CM_AGGREGATOR_STACKEDBARCHART_YAXIS_TITLE}
                            bypassLOBFiltering
                            showValuesWhenNoSpace
                            customBarFont={{
                                fontSize: 13,
                                color: d => (d.data.key === 'H1' ? color[1].color : defaultTheme.black.ff),
                            }}
                            customTicks={{
                                barChart: {
                                    ticksCustomLabel: '',
                                },
                            }}
                        />
                        <Legend
                            customStyle={{
                                _wrapper: { marginLeft: '20px' },
                                _individualItem: { marginRight: '20px', marginTop: 0 },
                                _legendShape: [
                                    {
                                        _legendIndex: 0,
                                        _legendStyle: {
                                            width: 40,
                                            height: 10,
                                            borderRadius: 0,
                                        },
                                    },
                                    {
                                        _legendIndex: 1,
                                        _legendStyle: {
                                            width: 40,
                                            height: 10,
                                            borderRadius: 0,
                                        },
                                    },
                                ],
                            }}
                            displayDirection={'row'}
                            legendItems={legend}
                        />
                    </SC.SectionSingularItem>
                </SC.SectionItems>
                <SC.SectionSingularItem>
                    <SectionInfo
                        customStyle={{
                            _topSection: { marginTop: 25 },
                            _bottomSection: { marginBottom: 45 },
                        }}
                        sectionData={sectionInfo}
                        sectionSideNote={sectionSideNote}
                    />
                </SC.SectionSingularItem>
            </SC.SectionRow>
        </SectionsHead>
    );
};

MarketOverviewGroup.propTypes = {
    marketOverviewData: PropTypes.object.isRequired,
};

MarketOverviewGroup.defaultProps = {
    marketOverviewData: null,
};

export default MarketOverviewGroup;
