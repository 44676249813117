import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { getBPTPreferenceAnalysisDataUrl } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { BPT_PAGE, PREFERENCE_ANALYSIS_TABS } from '../../../../../utils/routes';
import { getLocationSelector } from '../../../../../common/components/TopFilter/data/selectors';
import {
    GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC,
    GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_DATA,
} from '../tabs/awareness/data/actions';
import {
    GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC,
    GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_DATA,
} from '../tabs/adRecall/data/actions';
import {
    GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC,
    GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_DATA,
} from '../tabs/consideration/data/actions';
import {
    GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC,
    GET_BPT_PREFERENCE_ANALYSIS_IMAGE_DATA,
} from '../tabs/image/data/actions';
import {
    GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC,
    GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_DATA,
} from '../tabs/valueformoney/data/actions';
import { getFiltersActionCreator } from '../../../../../common/data/actions';
import { getFiltersDetailedThrownError } from '../../../../../common/data/selectors';
import { FILTERS_CONFIG } from '../../../../../common/utils/utils';
import { getParentPathOnPagesWithSubTabs } from '../../../../../common/components/TopFilter/utils/utils';
import { getComputedFiltersMultipleParametersForRequest } from '../../../../../common/data/computedSelectors';

function* getBPTPreferenceAnalysisUrl(action) {
    const currentPath = yield select(getLocationSelector);
    try {
        const filtersToRequest = yield select(getComputedFiltersMultipleParametersForRequest);
        const filtersHadPreviouslyError = yield select(getFiltersDetailedThrownError);
        const previuslyLocationThatThrewErr = getParentPathOnPagesWithSubTabs(filtersHadPreviouslyError.errorOnPath);
        const currentPathParent = getParentPathOnPagesWithSubTabs(currentPath);
        //If the error we had previously was a token related one, do not send any request.
        //The centralizedAsyncErrorHandle will dispatch a retryAction to GET the filters again.
        if (
            filtersHadPreviouslyError &&
            filtersHadPreviouslyError.status !== 401 &&
            previuslyLocationThatThrewErr === currentPathParent
        ) {
            yield put(getFiltersActionCreator(FILTERS_CONFIG[BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path]));
        }
        switch (currentPath.toLowerCase()) {
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.dispatchers.started());
                const dataAwareness = yield call(getBPTPreferenceAnalysisDataUrl, currentPath, {
                    filtersList: filtersToRequest,
                });
                yield put(GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.dispatchers.succeeded(dataAwareness));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.dispatchers.started());
                const dataConsideration = yield call(getBPTPreferenceAnalysisDataUrl, currentPath, {
                    filtersList: filtersToRequest,
                });
                yield put(GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.dispatchers.succeeded(dataConsideration));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.dispatchers.started());
                const dataImage = yield call(getBPTPreferenceAnalysisDataUrl, currentPath, {
                    filtersList: filtersToRequest,
                });
                yield put(GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.dispatchers.succeeded(dataImage));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.dispatchers.started());
                const dataAdRecall = yield call(getBPTPreferenceAnalysisDataUrl, currentPath, {
                    filtersList: filtersToRequest,
                });
                yield put(GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.dispatchers.succeeded(dataAdRecall));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.VALUE_FOR_MONEY.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.dispatchers.started());
                const dataValueForMoney = yield call(getBPTPreferenceAnalysisDataUrl, currentPath, {
                    filtersList: filtersToRequest,
                });
                yield put(GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.dispatchers.succeeded(dataValueForMoney));
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
        switch (currentPath.toLowerCase()) {
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AWARENESS.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC.dispatchers.failed(error));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.CONSIDERATION.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC.dispatchers.failed(error));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.IMAGE.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_IMAGE_ASYNC.dispatchers.failed(error));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.AD_RECALL.path.toLowerCase():
                yield put(GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_ASYNC.dispatchers.failed(error));
                break;
            case PREFERENCE_ANALYSIS_TABS.TAB_ITEMS.VALUE_FOR_MONEY.path.toLowerCase():
                /* Reset the state of the data on error */
                yield put(GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.dispatchers.succeeded(null));
                yield put(GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC.dispatchers.failed(error));
                break;
            default:
                break;
        }
    }
}

export default function* bptPreferenceAnalysisSaga() {
    yield all([
        yield takeLatest(GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_DATA, getBPTPreferenceAnalysisUrl),
        yield takeLatest(GET_BPT_PREFERENCE_ANALYSIS_ADRECALL_DATA, getBPTPreferenceAnalysisUrl),
        yield takeLatest(GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_DATA, getBPTPreferenceAnalysisUrl),
        yield takeLatest(GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_DATA, getBPTPreferenceAnalysisUrl),
        yield takeLatest(GET_BPT_PREFERENCE_ANALYSIS_IMAGE_DATA, getBPTPreferenceAnalysisUrl),
    ]);
}
