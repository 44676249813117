import React from 'react';
import { TabsContainer } from '../../../../common/components/Navigation/SubMenuNavigation.styles';
import { NavSubmenuItem } from '../../../../common/components/Navigation/SubMenuNavigation.styles';
import PropTypes from 'prop-types';
const NavLinkItems = props => {
    const { id, to, name } = props;
    return (
        <NavSubmenuItem exact key={id} to={to} activeClassName={'subMenuLinkActive'}>
            <p>{name}</p>
        </NavSubmenuItem>
    );
};

const PreferenceAnalysisTabs = ({ tabsComponents }) => {
    const NavigationItems = Object.keys(tabsComponents).map(key => {
        const { id, path, name } = tabsComponents[key];
        return <NavLinkItems key={id} id={id} to={path} name={name} />;
    });
    return <TabsContainer style={{ marginTop: '-15px' }}>{NavigationItems}</TabsContainer>;
};
NavLinkItems.propTypes = {
    id: PropTypes.number.isRequired,
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
PreferenceAnalysisTabs.propTypes = {
    tabsComponents: PropTypes.object.isRequired,
};
export default PreferenceAnalysisTabs;
