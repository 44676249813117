import * as routes from '../../../../utils/routes';
import { matchPath } from 'react-router-dom';
export const getParentPathOnPagesWithSubTabs = currentLocation => {
    let returnedLocation = currentLocation;
    //Can be extented for each path in the 'parentPagesPathsWithSubtabs' that has subtabs
    //The same logic will be applied and it will return the parrent path which is stored in the array.
    //If we are not reffering to a path that has subtabs, it will return the current location provided initially as an argument
    const parentPagesPathsWithSubtabs = [routes.BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path];

    parentPagesPathsWithSubtabs.forEach(path => {
        const match = matchPath(currentLocation, {
            path: `${path}/:slug?`,
        });
        if (match) {
            returnedLocation = path;
        }
    });
    return returnedLocation;
};
