import { createSelector } from 'reselect';
import { getTopFiltersSelector } from '../../SideFilters/data/selectors';
import { TOP_FILTERS_OPTION_BE_KEY } from '../utils/constants';
import { getParentPathOnPagesWithSubTabs } from '../utils/utils';
import {
    getSelectedOptionByPageSelector,
    getSelectedTopFiltersByPageSelector,
    getLocationSelector,
    getCachedLastAppliedTopFiltersOptionSelector,
} from './selectors';
import { ADTESTER_PAGE, COMPETITIVE_MEDIA, TRANSVERSAL_VIEW_PAGE } from '../../../../utils/routes';
import { adsComparisonSelectedOptionsValidator } from '../../../../pages/adTester/subpages/AdsComparison/utils';

const checkForAdditionalValidationsForBackendOptions = (location, options) => {
    switch (location) {
        case ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path:
            return adsComparisonSelectedOptionsValidator(options);
        default:
            /* Implicit true, no validations required to non-specified locations */
            return true;
    }
};

const areAllDependentParentsCheckedForSelectedBackendOption = (
    location,
    copyNames,
    currentCopyIDs,
    currentSelectedCountry
) => {
    switch (location) {
        case COMPETITIVE_MEDIA.path:
        case TRANSVERSAL_VIEW_PAGE.path:
            return true;
        default:
            return copyNames?.options
                ?.filter(el => currentCopyIDs.includes(el.id))
                ?.reduce((prev, el) => {
                    return prev && el.parents.some(parentEl => parentEl === currentSelectedCountry);
                }, true);
    }
};

export const getPageHasOptionLoadedComputedSelector = createSelector(
    [getSelectedOptionByPageSelector, getLocationSelector],
    (selectedOptionsByPage, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        return selectedOptionsByPage.hasOwnProperty(location);
    }
);

export const getComputedSelectedTopFiltersFromPage = createSelector(
    [getSelectedTopFiltersByPageSelector, getLocationSelector],
    (selectedFilters, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        return selectedFilters[location] || [];
    }
);

export const getComputedTopFiltersSelector = createSelector(
    [getTopFiltersSelector, getSelectedTopFiltersByPageSelector, getSelectedOptionByPageSelector, getLocationSelector],
    (topFilters, topFiltersByPage, optionByPage, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        const verifyCheckStatus = (selectedFilters, selectedOption, option) =>
            !!selectedFilters.some(e => e.id === option.id) || selectedOption?.id === option.id;

        return topFilters.map(filter => {
            const selectedTopFilters = topFiltersByPage.hasOwnProperty(location) ? topFiltersByPage[location] : [];
            const selectedOption = optionByPage.hasOwnProperty(location) ? optionByPage[location] : {};

            let options = filter.options.reduce((updatedOptions, option) => {
                /*
                IMPORTANT NOTE :
                    The last option in the filters has an AND operator. All its parents needs to be in the selectedFilters list
                 */
                const COPY_NAME_ID = 127;
                if (filter.id === COPY_NAME_ID) {
                    if (option.parents.every(id => selectedTopFilters.some(e => e.id === id))) {
                        const isChecked = verifyCheckStatus(selectedTopFilters, selectedOption, option);
                        updatedOptions.push({
                            ...option,
                            // If the option id is within the selected IDs list, check it
                            isChecked: isChecked,
                        });
                    }
                }
                // The other options in the filters have an OR operator. Only some of its parents needs to be in the selectedFilters list
                else if (
                    option.parents.some(id => selectedTopFilters.some(e => e.id === id)) ||
                    option.parents.length === 0
                ) {
                    /* The item is checked if it appears in the selected items list,
                         or if there is a previously selected adTester
                    */
                    const isChecked = verifyCheckStatus(selectedTopFilters, selectedOption, option);
                    updatedOptions.push({
                        ...option,
                        // If the option id is within the selected IDs list, check it
                        isChecked: isChecked,
                    });
                }
                return updatedOptions;
            }, []);

            return { ...filter, options };
        });
    }
);

export const getComputedTopFiltersIsAllYearsSelected = createSelector(
    [getTopFiltersSelector, getSelectedTopFiltersByPageSelector, getLocationSelector],
    (topFilters, topFiltersByPage, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        const selectedTopFilters = topFiltersByPage.hasOwnProperty(location) ? topFiltersByPage[location] : [];

        if (topFilters) {
            const selectedYears = selectedTopFilters.filter(e => e.selectType === 'multi');
            const allYears = topFilters.find(e => e.selectType === 'multi');

            if (allYears && selectedYears) {
                return allYears.options.every(e => selectedYears.some(option => option.id === e.id));
            }
            return false;
        }
        return false;
    }
);

export const getComputedTopFiltersGetCountry = createSelector(
    [getTopFiltersSelector, getCachedLastAppliedTopFiltersOptionSelector, getLocationSelector],
    (topFilters, cachedTopFilters, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        const selectedTopFilters = cachedTopFilters.hasOwnProperty(location) ? cachedTopFilters[location] : [];

        if (topFilters) {
            const currentSelectedCountryId = selectedTopFilters?.find(el => el.group === 'Country')?.id;
            const topFiltersCountries = topFilters.find(e => e.group === 'Country');
            return topFiltersCountries?.options?.find(el => el.id === currentSelectedCountryId)?.label;
        }
    }
);

export const areSelectedOptionsValidAsBackEndParametersComputedSelector = createSelector(
    [getTopFiltersSelector, getSelectedTopFiltersByPageSelector, getSelectedOptionByPageSelector, getLocationSelector],
    (topFilters, selectedFiltersByPage, selectedOptionByPage, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        const selectedOptions = selectedOptionByPage[location];
        /* Extract only the IDs from the selected options */
        const selectedCopyIDs = selectedOptions ? selectedOptions?.map(el => el.id) : null;
        let areDependentParentsChecked = false;

        if (selectedCopyIDs && selectedCopyIDs?.length > 0) {
            let allCopyNames = topFilters.find(el =>
                el.label.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
            );
            const currentSelectedCountry = selectedFiltersByPage[location]?.find(el => el.group === 'Country')?.id;

            /* For the back-end options to be valid, the parents need to be selected as well */
            areDependentParentsChecked = areAllDependentParentsCheckedForSelectedBackendOption(
                location,
                allCopyNames,
                selectedCopyIDs,
                currentSelectedCountry
            );
        }

        /* Additional validation to enable/disable the Apply button based on selected copy names */
        const additionalValidationsPassed = checkForAdditionalValidationsForBackendOptions(location, selectedCopyIDs);
        return areDependentParentsChecked && additionalValidationsPassed;
    }
);

export const getCachedSelectedFiltersDataComputedSelector = createSelector(
    [getTopFiltersSelector, getCachedLastAppliedTopFiltersOptionSelector, getLocationSelector],
    (topFilters, cachedTopFilters, unprocessedLocation) => {
        const location = getParentPathOnPagesWithSubTabs(unprocessedLocation);
        let cachedCopyNames;
        if (topFilters.length) {
            cachedCopyNames = cachedTopFilters[location]?.filter(el =>
                el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
            );

            return {
                cachedFilters: cachedTopFilters[location],
                cachedOptions: cachedCopyNames
                    ? cachedCopyNames.map(copy => ({ ...copy, groupId: cachedCopyNames?.id }))
                    : null,
            };
        }
    }
);
