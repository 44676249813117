import React from 'react';
import { ReportOptionWrapper } from '../ReportOption/ReportOption.styles';
import styled from 'styled-components';

const SVG = styled.svg`
    transform: scale(0.8);
    .ribbon-left,
    .ribbon-right,
    .star-1,
    .star-2,
    .star-3,
    .main-circle,
    .flower-petals {
        transition: all 0.15s ease-in-out;
    }
    .flower-lines {
        transition: all 0.1s ease-in-out;
    }
    .bpta,
    .ribbon-left,
    .ribbon-right,
    .star-1,
    .star-2,
    .star-3,
    .main-circle {
        fill: ${({ theme }) => theme.white.ff};
    }
    .bptb {
        fill: ${({ theme }) => theme.violet[400]};
    }
    .ribbon-left,
    .ribbon-right,
    .star-1,
    .star-2,
    .star-3 {
        stroke: ${({ theme }) => theme.violet[400]};
        stroke-width: 1.5px;
    }
    .ribbon-left,
    .ribbon-right {
        stroke-linecap: round;
    }
    .bptc {
        stroke-linejoin: round;
    }
    .star-1,
    .star-2,
    .star-3 {
        stroke-miterlimit: 10;
    }
    .flower-petals {
        transform-origin: 39px 39px;
    }
    .flower-lines {
        transform-origin: 39px 39px;
    }

    ${ReportOptionWrapper}:hover & {
        .main-circle {
            fill: ${({ theme }) => theme.violet[100]};
        }
        .star-1 {
            transform: scale(0.5) translateX(20px) translateY(10px);
            stroke-width: 2.5px;
        }
        .star-2 {
            transform: scale(0.5) translateX(10px) translateY(10px);
            stroke-width: 2.5px;
        }
        .star-3 {
            transform: scale(2) translateX(-6px) translateY(-7px);
            stroke-width: 0.7px;
        }
        .ribbon-left {
            transform: translate(17px, 60px) rotate(20deg);
        }
        .ribbon-right {
            transform: translate(40px, 69px) rotate(-20deg) scaleX(0.9);
        }
        .flower-petals {
            transform: translate(34.576px, 23.998px) rotate(-45deg);
        }
        .flower-lines {
            transform: rotate(180deg) translateY(25px) translateX(24px);
        }
    }
`;

const BptComponent = () => (
    <SVG width={152.206} height={141.11} viewBox="0 0 152.206 141.11">
        <g transform="translate(2.398)">
            <g transform="translate(0 0)">
                <g transform="translate(2.24 0)">
                    <path
                        className="main-circle"
                        d="M70.469,147.778a69.749,69.749,0,1,1,69.749-69.749,69.749,69.749,0,0,1-69.749,69.749Z"
                        transform="translate(0.086 -7.406)"
                    />
                    <path
                        className="bptb"
                        d="M70.555,9.18A68.909,68.909,0,1,1,1.68,78.122,68.909,68.909,0,0,1,70.555,9.18m0-1.68A70.555,70.555,0,1,0,141.11,78.055,70.555,70.555,0,0,0,70.555,7.5Z"
                        transform="translate(0 -7.5)"
                    />
                </g>
                <g transform="translate(0 21.526)">
                    <g transform="translate(34.276 2.643)">
                        <path
                            className="bptc ribbon-left"
                            d="M0,0V33.525l12.353-5.617L24.58,33.525V0Z"
                            transform="translate(16.762 50.876) rotate(30)"
                        />
                        <path
                            className="bptc ribbon-right"
                            d="M0,0V33.525l12.353-5.617L24.58,33.525V0Z"
                            transform="translate(40.971 63.166) rotate(-30)"
                        />
                    </g>
                    <g transform="translate(117.257 7.056)">
                        <g transform="translate(0 0)">
                            <path
                                className="star-1"
                                d="M21.27,14.357,32.688,18.2,21.27,22.035,17.589,33.942,14,21.937,2.49,18.2l11.436-3.817L17.589,2.45Z"
                                transform="translate(-2.49 -2.45)"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 66.857)">
                        <g transform="translate(0 0)">
                            <path
                                className="star-2"
                                d="M18.068,12.327l9.471,3.184L18.068,18.7l-3.053,9.877-2.973-9.958-9.551-3.1,9.486-3.166,3.038-9.9Z"
                                transform="translate(-2.49 -2.45)"
                            />
                        </g>
                    </g>
                    <g transform="translate(12.413 0)">
                        <g transform="translate(0 0)">
                            <path
                                className="star-3"
                                d="M10.986,7.837l5.165,1.737L10.986,11.31,9.321,16.7,7.7,11.266,2.49,9.573,7.664,7.847l1.657-5.4Z"
                                transform="translate(-2.49 -2.45)"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g transform="translate(34.576 23.998)" className="flower-petals">
                <path
                    className="bpte"
                    d="M44.68,25.8a13.34,13.34,0,0,1-11.06,0,13.36,13.36,0,0,1-3.06,4.76,13.61,13.61,0,0,1-4.76,3.07,13.32,13.32,0,0,1,0,11,13.45,13.45,0,0,1,4.76,3.06,13.61,13.61,0,0,1,3.07,4.76,13.32,13.32,0,0,1,11,0,13.61,13.61,0,0,1,3.06-4.76,13.45,13.45,0,0,1,4.76-3.06,13.32,13.32,0,0,1,0-11,13.37,13.37,0,0,1-7.82-7.83Z"
                />
                <path
                    className="bpta"
                    d="M34.51,21a13.79,13.79,0,0,1-.43,3.35,11.87,11.87,0,0,0,10.15,0A13.38,13.38,0,0,1,50.42,9.41a12,12,0,0,0-22.53,0,13.81,13.81,0,0,1,2.67,2.06A13.4,13.4,0,0,1,34.51,21Z"
                />
                <path
                    className="bpta"
                    d="M48.8,12.53a12,12,0,0,0,0,17,12.2,12.2,0,0,0,4.4,2.79,13.48,13.48,0,0,1,11.6-6.64,13.26,13.26,0,0,1,3.35.44A12,12,0,0,0,48.8,12.53Z"
                />
                <path
                    className="bpta"
                    d="M64.8,27.15A12,12,0,0,0,53.94,44.22a13.79,13.79,0,0,1,3.35-.43,13.38,13.38,0,0,1,11.6,6.63A12,12,0,0,0,64.8,27.15Z"
                />
                <path
                    className="bptb"
                    d="M78.3,39.15a13.52,13.52,0,0,0-8.71-12.61A13.48,13.48,0,0,0,51.75,8.71a13.47,13.47,0,0,0-25.2,0A13.48,13.48,0,0,0,8.71,26.55a13.47,13.47,0,0,0,0,25.2A13.477,13.477,0,0,0,26.54,69.56a13.48,13.48,0,0,0,25.22,0,13.62,13.62,0,0,0,5.53,1.19,13.48,13.48,0,0,0,12.3-19,13.52,13.52,0,0,0,8.71-12.6ZM57.29,9A12,12,0,0,1,68.15,26.09a13.26,13.26,0,0,0-3.35-.44,13.48,13.48,0,0,0-11.6,6.64,12.2,12.2,0,0,1-4.4-2.79A12,12,0,0,1,57.29,9ZM52.5,33.63a13.32,13.32,0,0,0,0,11,13.45,13.45,0,0,0-4.76,3.06,13.61,13.61,0,0,0-3.06,4.76,13.32,13.32,0,0,0-11,0,13.61,13.61,0,0,0-3.07-4.76,13.45,13.45,0,0,0-4.76-3.06,13.32,13.32,0,0,0,0-11,13.61,13.61,0,0,0,4.76-3.07,13.36,13.36,0,0,0,3.06-4.76,13.34,13.34,0,0,0,11.06,0,13.37,13.37,0,0,0,7.82,7.83ZM39.15,1.5A12,12,0,0,1,50.42,9.41a13.38,13.38,0,0,0-6.19,15,11.87,11.87,0,0,1-10.15,0A13.79,13.79,0,0,0,34.51,21a13.4,13.4,0,0,0-3.95-9.54A13.81,13.81,0,0,0,27.89,9.4,12,12,0,0,1,39.15,1.5ZM9,21a12,12,0,1,1,3.52,8.49A12,12,0,0,1,9,21ZM1.5,39.15A12,12,0,0,1,9.41,27.89,13.41,13.41,0,0,0,21,34.51a13.79,13.79,0,0,0,3.35-.43A12,12,0,1,1,1.5,39.15ZM21,69.29A12,12,0,0,1,10.15,52.21a13.2,13.2,0,0,0,3.35.44A13.49,13.49,0,0,0,25.1,46,12.008,12.008,0,0,1,21,69.29ZM39.15,76.8a12,12,0,0,1-11.27-7.91,13.12,13.12,0,0,0,2.68-2.06,13.39,13.39,0,0,0,3.95-9.54,13.86,13.86,0,0,0-.43-3.35A12,12,0,1,1,39.15,76.8Zm26.62-11a12,12,0,0,1-13.56,2.38,13.2,13.2,0,0,0,.44-3.35A13.51,13.51,0,0,0,46,53.2,11.991,11.991,0,1,1,65.75,65.77Zm3.12-15.35a13.38,13.38,0,0,0-11.6-6.63,13.79,13.79,0,0,0-3.35.43,12,12,0,1,1,15,6.2Z"
                />
                <path
                    className="bpta"
                    d="M65.77,48.8A11.967,11.967,0,0,0,46,53.2a13.51,13.51,0,0,1,6.63,11.6,13.2,13.2,0,0,1-.44,3.35A12,12,0,0,0,65.77,48.8Z"
                />
                <path
                    className="bpta"
                    d="M51.15,64.8A12,12,0,0,0,34.08,53.94a13.86,13.86,0,0,1,.43,3.35,13.39,13.39,0,0,1-3.95,9.54,13.12,13.12,0,0,1-2.68,2.06A12,12,0,0,0,51.15,64.8Z"
                />
                <path
                    className="bpta"
                    d="M29.5,65.77A12,12,0,0,0,25.1,46a13.49,13.49,0,0,1-11.6,6.63,13.2,13.2,0,0,1-3.35-.44A12,12,0,0,0,29.5,65.77Z"
                />
                <path
                    className="bpta"
                    d="M13.5,51.15A12,12,0,0,0,24.36,34.08a13.79,13.79,0,0,1-3.35.43,13.41,13.41,0,0,1-11.6-6.62A12,12,0,0,0,13.5,51.15Z"
                />
                <path className="bpta" d="M12.53,29.5A12,12,0,1,0,9,21,12,12,0,0,0,12.53,29.5Z" />
            </g>
            <g transform="translate(46.58 36)">
                <path
                    className="bpta"
                    d="M26.88,1.5A25.38,25.38,0,1,0,52.26,26.88,25.38,25.38,0,0,0,26.88,1.5ZM17,11a18.68,18.68,0,0,0-1.47,30.73.77.77,0,0,1,.18,1.05.73.73,0,0"
                />
                <path
                    className="bptb"
                    d="M26.88,0A26.88,26.88,0,1,0,53.76,26.88,26.88,26.88,0,0,0,26.88,0Zm0,52.26A25.38,25.38,0,1,1,52.26,26.88,25.38,25.38,0,0,1,26.88,52.26Z"
                />
                <g className="flower-lines">
                    <path
                        className="bptb"
                        d="M26.88,6.66a19.76,19.76,0,0,0-6.09,1,.71.71,0,0,0-.42,1,.78.78,0,0,0,.95.41,18.59,18.59,0,0,1,5.56-.87.75.75,0,1,0,0-1.5Z"
                    />
                    <path
                        className="bptb"
                        d="M16.28,9.68A20.17,20.17,0,0,0,14.66,43a.73.73,0,0,0,1-.18.77.77,0,0,0-.18-1.05A18.68,18.68,0,0,1,17,11a.78.78,0,0,0,.24-1.11A.71.71,0,0,0,16.28,9.68Z"
                    />
                </g>
            </g>
        </g>
    </SVG>
);

export default BptComponent;
