import { asyncActionCreator } from '../../../../../../../utils/generalUtilities';

export const GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC = asyncActionCreator(
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_ASYNC'
);
export const GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_DATA =
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_DATA';

export const getBptPreferenceAnalysisConsiderationActionCreator = payload => ({
    type: GET_BPT_PREFERENCE_ANALYSIS_CONSIDERATION_DATA,
    payload,
});
