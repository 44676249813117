// The Input Processing service is specific for each project the AdminDashboard is included in
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function InputProcessingServiceCreator() {
    function processUserListData(userData) {
        return userData.map(user => {
            try {
                let newUserObject = {};

                Object.keys(user).forEach(key => {
                    const value = user[key];
                    if (typeof value !== 'object' || !Array.isArray(value)) {
                        newUserObject[capitalizeFirstLetter(key)] = value;
                    } else if (key === 'permissions') {
                        const dashboards = user[key]
                            .filter(obj => (obj === null ? false : obj.type === 2 && obj.isChecked === true))
                            .map(e => e.name);
                        const countries = user[key]
                            .filter(obj => (obj === null ? false : obj.type === 3 && obj.isChecked === true))
                            .map(e => e.name);

                        newUserObject.Country = countries.join('/');
                        newUserObject.Dashboards = dashboards.join('/');
                    }
                });
                return newUserObject;
            } catch (error) {}
        });
    }

    function processUserData(userData) {
        let newUserObject = {};
        Object.keys(userData).forEach(key => {
            newUserObject[capitalizeFirstLetter(key)] = userData[key];
        });
        return newUserObject;
    }

    return {
        processUserListData,
        processUserData,
    };
}

const InputProcessingService = InputProcessingServiceCreator();
export default InputProcessingService;
