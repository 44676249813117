import styled from 'styled-components';

export const HomeSectionWrapper = styled.div`
    background: ${({ theme }) => theme.white.ff};
    margin-top: 55px;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
`;

export const Header = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 36px;
        height: 90px;
        transform: translateY(-50%);
    }

    h3 {
        padding: 8px 6px 8px 140px;
        color: ${({ color }) => color};
        text-transform: uppercase;
        font-size: 16px;
    }
`;

export const Content = styled.div`
    padding: 36px;
    display: flex;
    align-items: center;
    flex-direction: ${({ isImageOnRight }) => (isImageOnRight ? 'row' : 'row-reverse')};
    .mainList,
    ul {
        padding: 0;
        list-style: none;
        margin-top: 0;
    }
    .mainList li p {
        margin: 0;
        position: relative;
        display: inline;
        font-weight: 600;
    }
    .mainList > li:before {
        content: '•';
        display: inline-block;
        font-size: 12px;
        padding-right: 10px;
        vertical-align: top;
        padding-top: 2px;
    }
    .nestedList li {
        list-style: none;
        margin-left: 16px;
    }
    @media (max-width: 1150px) {
        flex-direction: column;
    }

    img {
        max-width: 40%;

        @media (max-width: 1150px) {
            max-width: 100%;
            margin-bottom: 30px;
        }
    }

    div {
        margin: ${({ isImageOnRight }) => (isImageOnRight ? '0 0 0 36px' : '0 36px 0 0 ')};

        @media (max-width: 1150px) {
            margin: 0;
        }

        p {
            margin: 0;
            color: ${({ theme }) => theme.blue[900]};
        }
    }

    .mainBpt {
        padding: 0px 10px 10px 10px;
        h1 {
            background-color: ${({ theme }) => theme.violet[100]};
            width: 80px;
            text-align: center;
            padding: 5px;
            text-transform: uppercase;
            font-size: 16px;
            color: ${({ theme }) => theme.violet[400]};
        }
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            color: ${({ theme }) => theme.blue[900]};
            font-size: 16px;
            line-height: 25px;
            position: relative;
            &:before {
                content: '\\23F5';
                width: 10px;
                height: 10px;
                position: absolute;
                left: -18px;
                color: ${({ theme }) => theme.violet[100]};
            }
        }
    }

    .mainCM {
        line-height: 1.6;
        ol {
            margin-top: 10px;
        }
        ol > li {
            padding-left: 10px;
        }
        ul {
            list-style-type: disc;
            padding-left: 40px;
            line-height: normal;
        }
        p span {
            margin-right: 5px;
        }
    }
`;

export const OuterContent = styled.div`
    .secondaryBpt {
        display: flex;
        justify-content: center;

        .target,
        .sample,
        .interviews {
            width: 360px;
            margin: 10px 20px 50px 20px;
            height: 225px;
            @media screen and (max-width: 1150px) {
                height: 245px;
            }
            text-align: center;
            font-size: 16px;
            color: ${({ theme }) => theme.blue[950]};
            box-shadow: ${({ theme }) => `0px 0px 14px ${theme.black[29]}`};
            border-radius: 4px;
            p {
                padding: 5px 30px 5px 30px;
                margin: 0;
                line-height: 25px;
            }
            h1 {
                text-transform: uppercase;
                font-size: 17px;
                margin-top: 33px;
            }
        }
        span {
            background-repeat: no-repeat;
            background-size: auto;
            width: 200px;
            position: absolute;
            top: -38px;
            transform: translateX(-34px);
            height: 100px;
        }
        .target {
            position: relative;
            span {
                background-image: url('${({ innerIcons }) => innerIcons?.find(el => el.name === 'target').path}');
            }
        }
        .interviews {
            position: relative;
            span {
                background-image: url('${({ innerIcons }) => innerIcons?.find(el => el.name === 'interviews').path}');
            }
        }
        .sample {
            position: relative;
            span {
                background-image: url('${({ innerIcons }) => innerIcons?.find(el => el.name === 'sample').path}');
            }
        }
    }
`;
