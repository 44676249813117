import React from 'react';
import { basePath } from '../../../../services/utils';
import { AD_TESTER_HOMEPAGE_JSX } from '../../../../utils/translations';
import { Wrapper, SectionBody, Description, ContentImage } from './Homepage.styles';

const Homepage = () => {
    return (
        <Wrapper>
            <SectionBody>
                <ContentImage src={`${basePath}/visuals/adtester_section.png`} />
                <Description>{AD_TESTER_HOMEPAGE_JSX}</Description>
            </SectionBody>
        </Wrapper>
    );
};

export default Homepage;
