import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './TopLegend.styles';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import { basePath } from '../../../services/utils';

const TopLegend = ({ YTDIsVisbile, sigLegendIsVisible, rankingLegendIsVisible, customStyle }) => {
    return (
        <SC.LegendWrapper customStyle={customStyle?._wrapper}>
            <SC.LegendRowContainer>
                <SC.LegendTooltipContainer sigLegendIsVisible={sigLegendIsVisible}>
                    <SC.LegendTooltipItem>
                        <SC.LegendTooltipImage
                            src={`${basePath}/visuals/greenTooltip.svg`}
                            alt="Legend Green Tooltip"
                        />
                        <SC.LegendTooltipLabel>
                            {TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_HIGHER}
                        </SC.LegendTooltipLabel>
                    </SC.LegendTooltipItem>
                    <SC.LegendTooltipItem>
                        <SC.LegendTooltipImage
                            src={`${basePath}/visuals/yellowTooltip.svg`}
                            alt="Legend Yellow Tooltip"
                        />
                        <SC.LegendTooltipLabel>
                            {TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_LOWER}
                        </SC.LegendTooltipLabel>
                    </SC.LegendTooltipItem>
                </SC.LegendTooltipContainer>
                <SC.LegendTooltipContainer sigLegendIsVisible={sigLegendIsVisible}>
                    <SC.LegendRankSortContainer rankingLegendIsVisible={rankingLegendIsVisible}>
                        <SC.LegendRankGreenArrow src={`${basePath}/visuals/greenArrow.svg`} alt="Green Arrow" />
                        <SC.LegendRankRedArrow src={`${basePath}/visuals/redArrow.svg`} alt="Red Arrow" />
                        <SC.LegendRankLabel>{TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_RANK}</SC.LegendRankLabel>
                    </SC.LegendRankSortContainer>
                </SC.LegendTooltipContainer>
            </SC.LegendRowContainer>
            <SC.TooltipContainer YTDIsVisbile={YTDIsVisbile}>
                <SC.InfoPic style={{ marginRight: '5px' }}>
                    <img src={`${basePath}/visuals/InfoIconYTD.png`} alt={'InfoIconYTD'} />
                </SC.InfoPic>
                <SC.TooltipText>{TRANSLATION_TEXT.INFO_PICTOGRAM_TEXT}</SC.TooltipText>
            </SC.TooltipContainer>
        </SC.LegendWrapper>
    );
};
TopLegend.propTypes = {
    sigLegendIsVisible: PropTypes.bool,
    rankingLegendIsVisible: PropTypes.bool,
    YTDIsVisbile: PropTypes.bool,
    customStyle: PropTypes.object,
};
TopLegend.defaultProps = {
    sigLegendIsVisible: true,
    rankingLegendIsVisible: true,
    YTDIsVisbile: true,
};
export default TopLegend;
