import { createSelector } from 'reselect';
import { getLineChartsDataSelector } from './selectors';
import {
    getCachedLastAppliedTopFiltersOptionSelector,
    getLocationSelector,
    getSelectedOptionByPageSelector,
    getSelectedTopFiltersByPageSelector,
} from '../../../../../common/components/TopFilter/data/selectors';
import { ADTESTER_PAGE } from '../../../../../utils/routes';
import { adsComparisonSelectedOptionsValidator } from '../utils';
import { TOP_FILTERS_OPTION_BE_KEY } from '../../../../../common/components/TopFilter/utils/constants';
import { getAdsComparisonCampaignDiagnosticsSelector, getAdTesterCampaignDetailsSelector } from './selectors';
import { getUpliftsComputedDataSelector } from '../../../data/computedSelectors';
import { getUserDetailsSelector } from '../../../../login/data/selectors';

const ComputedDataInstance = (() => {
    const getCombinedChartOptionsComputedSelector = createSelector(getLineChartsDataSelector, kpiSection => {
        let accumulatedData = {};
        let proccessedLineData = [];
        let proccessedBarData = {};
        const optionsObject = {};

        if (kpiSection) {
            kpiSection.kpis.forEach(elem => {
                const { items } = elem;

                items.forEach(item => {
                    if (item.itemId === 0) {
                        //Average
                        proccessedBarData = {
                            name: item.itemLabel,
                            values: item.values.map(({ color, value, label, sigtest, tooltip }) => ({
                                name: item.itemLabel,
                                color,
                                value,
                                group: label,
                                sigtest,
                                image: tooltip,
                            })),
                        };
                    } else {
                        const key = item.itemLabel.replace(/\s/g, '');
                        if (!optionsObject.hasOwnProperty(key)) {
                            optionsObject[key] = {
                                name: item.itemLabel,
                                color: item.values[0].color,
                                values: item.values.map(({ value, label, sigtest, tooltip }) => ({
                                    value,
                                    group: label,
                                    sigtest,
                                    image: tooltip,
                                })),
                            };
                        }
                    }
                });
            });
            /* Turn object back into an array */
            Object.keys(optionsObject).forEach(key => {
                proccessedLineData.push({ ...optionsObject[key] });
            });

            accumulatedData = {
                barData: proccessedBarData,
                lineData: proccessedLineData,
            };
        }
        return accumulatedData;
    });

    const getCombinedChartLegendComputedSelector = createSelector(getLineChartsDataSelector, kpiSection => {
        const processLegend = items => {
            return items.map(eachItem => {
                return {
                    name: eachItem.itemLabel,
                    color: eachItem.values[0].color,
                };
            });
        };

        if (!kpiSection?.kpis) return;

        return kpiSection?.kpis.reduce((data, currentKpy) => {
            data = processLegend(currentKpy.items);
            return data;
        }, []);
    });

    const getAdsComparisonExportDisabledComputedSelector = createSelector(
        [getLocationSelector, getCachedLastAppliedTopFiltersOptionSelector],
        (location, appliedOptions) => {
            if (appliedOptions && location) {
                const appliedTopFilters = appliedOptions[location];
                const appliedTopOptions = appliedTopFilters?.filter(el =>
                    el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[location])
                );
                const isValid = adsComparisonSelectedOptionsValidator(appliedTopOptions);
                return !isValid;
            }
            return true;
        }
    );

    const combinedSelectors = createSelector(
        [
            getCombinedChartOptionsComputedSelector,
            getCombinedChartLegendComputedSelector,
            getAdsComparisonExportDisabledComputedSelector,
            getAdsComparisonCampaignDiagnosticsSelector,
            getAdTesterCampaignDetailsSelector,
            getUpliftsComputedDataSelector,
        ],
        (
            combinedChartData,
            combinedChartsLegendData,
            exportDisabled,
            campaignDiagnosticsData,
            adTesterData,
            upliftsData
        ) => {
            return {
                combinedChartData,
                combinedChartsLegendData,
                exportDisabled,
                campaignDiagnosticsData,
                adTesterData,
                upliftsData,
            };
        }
    );

    return combinedSelectors;
})();

export const hasValidAdsComparisonSelectedOptionsComputedSelector = createSelector(
    getSelectedOptionByPageSelector,
    selectedTopFiltersByPage => {
        const adsComparisonOptions = selectedTopFiltersByPage[ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path];

        return adsComparisonSelectedOptionsValidator(adsComparisonOptions);
    }
);
export const getSelectedCountryFromTopFiltersComputedSelectorAdsComparison = createSelector(
    [getSelectedTopFiltersByPageSelector, getLocationSelector, getUserDetailsSelector],
    (selectedFilters, location, userDetails) => {
        const COUNTRY_GROUP_ID = 2;
        if (selectedFilters) {
            /* If filters have loaded it means that we have a property with the page path in the selected filters */
            if (
                Object.keys(selectedFilters).find(el => el.includes(ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path))
            ) {
                const currentCountry = selectedFilters[ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path].find(
                    el => el.groupId === COUNTRY_GROUP_ID
                );
                return { countryId: currentCountry.id };
            } else {
                /* If filters have not loaded yet, get the user's country from userInformation */
                return { countryId: userDetails.countryId[0] };
            }
        }
        return {};
    }
);
export default ComputedDataInstance;
