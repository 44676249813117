import React from 'react';
import { PropTypes } from 'prop-types';
import CombinedBarLineChart from './../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import Legend from './../../../../../../common/components/Legend/Legend';
import SectionInfo from './../../../../components/SectionInfo';
import SectionsHead from '../../../../../../common/components/SectionsHead/SectionsHead';
import { basePath } from './../../../../../../services/utils';
import { defaultTheme } from './../../../../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from './../../../../../../utils/translations';
import * as SC from './../../CompetitiveMedia.styles';
import { calculateWidthCombinedMarketInvestmentCompetitive } from './../../../../../../utils/responsiveness';
import { HashPatternJSX } from './utils/utils';
import CombinedStackedBarLineChart from '../../../../../../common/components/CombinedStackedBarLineChart/CombinedStackedBarLineChart';
import { sectionsHeadColor } from '../../../../utils/utils';

const MarketInvestmentGroup = ({ marketInvestmentData }) => {
    const { combinedChart, stackedBarChartMidValues, sectionSideNote, sectionInfo, sectionLabel } =
        marketInvestmentData || {};
    const renderStakedChart = () => {
        return (
            <>
                <SC.ChartTitle>{stackedBarChartMidValues?.chartTitle}</SC.ChartTitle>

                <CombinedStackedBarLineChart
                    barData={stackedBarChartMidValues?.chart}
                    lineData={[]}
                    color={stackedBarChartMidValues?.color}
                    customHeight={360}
                    customBarPadding={0.5}
                    customPattern={HashPatternJSX}
                    customWidthOnPage={calculateWidthCombinedMarketInvestmentCompetitive}
                    leftLabel={TRANSLATION_TEXT.CM_AGGREGATOR_STACKEDBARCHART_YAXIS_TITLE}
                    bypassLOBFiltering
                    customMidValues={{
                        enabled: true,
                        midValuesFontColorsInOrder: [defaultTheme.white.ff, defaultTheme.blue[900]],
                        midValueBackgroundColorsInOrder: [
                            stackedBarChartMidValues.color[0].color,
                            defaultTheme.green[100],
                        ],
                    }}
                    customBarFont={{
                        fontSize: 13,
                        color: d => (d.data.key === 'Aggregators' ? defaultTheme.blue[900] : defaultTheme.white.ff),
                    }}
                    customGridStyle={{
                        paddingLeft: 25,
                    }}
                    customTicks={{
                        barChart: {
                            ticksNumber: 10,
                            ticksCustomLabel: '',
                        },
                    }}
                />
                <Legend
                    customStyle={{
                        _wrapper: { marginLeft: '20px' },
                        _individualItem: {
                            marginRight: '23px',
                            marginTop: 0,
                            fontSize: '11px',
                        },
                        _legendShape: [
                            {
                                _legendIndex: 0,
                                _legendStyle: {
                                    width: 30,
                                    height: 10,
                                    borderRadius: 0,
                                },
                            },
                            {
                                _legendIndex: 1,
                                _legendStyle: {
                                    width: 30,
                                    height: 10,
                                    borderRadius: 0,
                                    background: `url(${basePath}/visuals/aggregatorLegendBg.png)`,
                                    backgroundSize: 'cover',
                                },
                            },
                        ],
                    }}
                    displayDirection={'row'}
                    legendItems={stackedBarChartMidValues?.legend}
                />
            </>
        );
    };

    const renderCombineBarLineChart = () => {
        return (
            <>
                <SC.ChartTitle> {combinedChart?.chartTitle} </SC.ChartTitle>
                <CombinedBarLineChart
                    bypassLOBFiltering
                    lineData={combinedChart?.lineData}
                    barData={combinedChart?.barData}
                    customWidthOnPage={calculateWidthCombinedMarketInvestmentCompetitive}
                    hideLeftLabel
                    barLabelFormatter={''}
                    customHeight={360}
                />
                <Legend
                    customStyle={{
                        _wrapper: { marginLeft: '20px' },
                        _individualItem: {
                            marginRight: '23px',
                            marginTop: 0,
                            fontSize: '11px',
                        },
                        _legendShape: [
                            {
                                _legendIndex: 0,
                                _legendStyle: {
                                    width: 30,
                                    height: 10,
                                    borderRadius: 0,
                                },
                            },
                        ],
                    }}
                    displayDirection={'row'}
                    legendItems={combinedChart?.legend}
                />
            </>
        );
    };

    return (
        <SectionsHead title={sectionLabel?.toUpperCase()} isResponsive={true} customStyle={sectionsHeadColor}>
            <SC.SectionRow>
                <SC.SectionItems>
                    <SC.SectionSingularItem style={{ marginRight: '5px' }}>
                        {renderStakedChart()}
                    </SC.SectionSingularItem>
                    <SC.SectionSingularItem>{renderCombineBarLineChart()}</SC.SectionSingularItem>
                </SC.SectionItems>
                <SC.SectionSingularItem>
                    <SectionInfo
                        customStyle={{
                            _topSection: { marginTop: 25 },
                            _bottomSection: { marginBottom: 40 },
                        }}
                        sectionData={sectionInfo}
                        sectionSideNote={sectionSideNote}
                    />
                </SC.SectionSingularItem>
            </SC.SectionRow>
        </SectionsHead>
    );
};

MarketInvestmentGroup.propTypes = {
    marketInvestmentData: PropTypes.shape({
        combinedChart: PropTypes.object.isRequired,
        sectionInfo: PropTypes.object.isRequired,
        stackedBarChartMidValues: PropTypes.object.isRequired,
    }),
};

MarketInvestmentGroup.defaultProps = {
    marketInvestmentData: null,
};

export default MarketInvestmentGroup;
