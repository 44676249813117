import { connect } from 'react-redux';
import ImageTab from './Image.tab';
import { getBptPreferenceAnalysisImageActionCreator } from '../data/actions';
import { getBptPreferenceAnalysisImageDataSelector } from '../../../data/selectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../../../../common/components/TopFilter/data/computedSelectors';
import {
    getLineChartDataWithoutSubsectionComputed,
    getBptPreferenceAnalysisDataComputed,
    getCombinedChartDataSelector,
    getLOBAsOptionsComputedSelector,
    getRankingTableDataSelectorComputed,
} from '../../../data/computedSelectors';
import {
    getSectionIDToShowTableComputed,
    getImageTabTableComputed,
    getLineChartLegendWithoutSubsectionComputed,
    getLineChartDescriptionKpi,
} from '../data/computedSelectors';
import { getFiltersThrownError } from '../../../../../../../common/data/selectors';
import { getSelectedFiltersComputedSelector } from './../../../../../../../common/components/SelectedFilters/data/computedSelectors';

const mapStateToProps = state => ({
    imageData: getBptPreferenceAnalysisImageDataSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    lineChartData: getLineChartDataWithoutSubsectionComputed(state),
    combinedChartsData: getCombinedChartDataSelector(state),
    imageSectionIDs: getBptPreferenceAnalysisDataComputed(state),
    lobsAsOptions: getLOBAsOptionsComputedSelector(state),
    combinedBarLineChartLegend: getLineChartLegendWithoutSubsectionComputed(state),
    rankingTableData: getRankingTableDataSelectorComputed(state),
    tableHeaderAndData: getImageTabTableComputed(state),
    showTableOnSection: getSectionIDToShowTableComputed(state),
    lineChartDescription: getLineChartDescriptionKpi(state),
    filtersThrownError: getFiltersThrownError(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
});

const mapDispatchToProps = {
    getBptPreferenceAnalysisImageActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageTab);
