import React from 'react';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import { Title, Subtitle } from '../login.styles';
import { pathnamesPagesMatcher } from '../utils/pageConfig';

const ResetSuccess = () => {
    const locationFromWindowPathname = window.location.pathname;
    const pathnamePageMatch = pathnamesPagesMatcher(locationFromWindowPathname);
    return (
        <>
            <Title>{TRANSLATION_TEXT.RESET_PASSWORD_SUCCESS_TEXT[pathnamePageMatch]}</Title>
            <Subtitle>{TRANSLATION_TEXT.RESET_PASSWORD_SUCCESS_TEXT_SECONDARY}</Subtitle>
        </>
    );
};

export default ResetSuccess;
