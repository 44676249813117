import { useState, useEffect } from 'react';

export const useLineChartLOBs = ({ lobsForLineChart }) => {
    const [legendLOBsOptions, setlegendLOBOptions] = useState([]);

    const isAllSelected = legendLOBsOptions.every(option => option.isChecked === true);

    useEffect(() => {
        if (lobsForLineChart !== null) {
            setlegendLOBOptions(lobsForLineChart);
        }
    }, [lobsForLineChart]);

    const onLOBCheckboxChange = optionId => {
        const newOptions = legendLOBsOptions.map(oldOption => {
            if (optionId === oldOption.key) {
                return { ...oldOption, isChecked: !oldOption.isChecked };
            }
            return { ...oldOption };
        });
        setlegendLOBOptions(newOptions);
    };

    const getLineChartCheckedOptions = legendLOBsOptions
        ? legendLOBsOptions.filter(e => e.isChecked && e.itemLabel).map(e => e.itemLabel)
        : [];

    /* Global state changers */
    const onLOBChangeAll = () => {
        const newOptions = legendLOBsOptions.map(oldOption => ({ ...oldOption, isChecked: !isAllSelected }));
        setlegendLOBOptions(newOptions);
    };
    const onLOBChangeByIds = ids => {
        const newOptions = legendLOBsOptions.map(oldOption => ({
            ...oldOption,
            isChecked: ids.includes(oldOption.key),
        }));
        setlegendLOBOptions(newOptions);
    };

    const globalLOBChangeUtilities = {
        isAllSelected,
        onLOBChangeAll,
        onLOBChangeByIds,
    };

    return [legendLOBsOptions, onLOBCheckboxChange, getLineChartCheckedOptions, globalLOBChangeUtilities];
};
