import { connect } from 'react-redux';
import FieldworkOverviewTable from './FieldworkOverviewTable';
import { getFieldworkTableScopeSelector } from '../data/selectors';
import {
    getComputedFieldWorkOverviewTableData,
    getComputedFieldworkOverviewTableHead,
} from '../data/computedSelectors';

const mapStateToProps = state => ({
    data: getComputedFieldWorkOverviewTableData(state),
    tableScope: getFieldworkTableScopeSelector(state),
    tableHead: getComputedFieldworkOverviewTableHead(state),
});

const FieldworkOverviewTableContainer = connect(mapStateToProps, null)(FieldworkOverviewTable);

export default FieldworkOverviewTableContainer;
