import React from 'react';
import { StyledButtonFileUpload,ButtonContentWrapper,ButtonIconWrapper,ButtonTextWrapper } from '../FileUpload.styles';
import { Icon }  from '@toluna-ui-toolkit/icon';

export const ButtonFileUpload = props => {
    const {fileInputRef,browseText,buttonStyle,iconName,iconType,buttonCustomStyle} = props;

    return(
        <StyledButtonFileUpload buttonStyle={buttonStyle} customStyle={buttonCustomStyle}  onClick={()=>{fileInputRef.current.click();}}>
            <ButtonContentWrapper buttonStyle={buttonStyle}>
                {iconName && iconType && (
                    <ButtonIconWrapper iconType={iconType}>
                        <Icon name={iconName} type={iconType} onClick={()=> {}}/>
                    </ButtonIconWrapper>
                )}
                <ButtonTextWrapper buttonStyle={buttonStyle}>
                    {browseText}
                </ButtonTextWrapper>
            </ButtonContentWrapper>
        </StyledButtonFileUpload>
    );
};
