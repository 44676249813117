import { asyncActionCreator } from '../../../../utils/generalUtilities';

export const GET_EXPORT_LINK = '/common/components/ExportButton/EXPORT_DATA';
export const GET_EXPORT_LINK_ASYNC = asyncActionCreator('/common/components/ExportButton/EXPORT_DATA');
export const CLEAR_EXPORT_LINK = '/common/components/ExportButton/CLEAR_EXPORT_LINK';

export const getExportLinkActionCreator = ({ path, exportTypeOverwrite }) => ({
    type: GET_EXPORT_LINK,
    payload: { path, exportTypeOverwrite },
});

export const clearExportLinkActionCreator = () => ({
    type: CLEAR_EXPORT_LINK,
});
