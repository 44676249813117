import { connect } from 'react-redux';
import ToggleSection from './ToggleSection';
import { changeFieldOverviewScope } from '../data/actions';
import { getFieldworkTableScopeSelector } from '../data/selectors';

const mapStateToProps = state => ({
    tableScope: getFieldworkTableScopeSelector(state),
});

const mapDispatchToProps = {
    changeScope: changeFieldOverviewScope,
};

const ToggleSectionContainer = connect(mapStateToProps, mapDispatchToProps)(ToggleSection);

export default ToggleSectionContainer;
