import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import style from 'react-input-range/lib/css/index.css';
import { SideFilterSliderWrapper, SliderInputWrapper, SliderWrapper } from './SideFilters.styles';

const SideFiltersSlider = ({ minScaleValue, maxScaleValue, onChangeCompleted, colorTheme, values }) => {
    const [value, setValue] = useState({
        min: minScaleValue,
        max: maxScaleValue,
    });
    const [sliderValue, setSliderValue] = useState({
        min: minScaleValue,
        max: maxScaleValue,
    });

    const handleSliderChange = newValues => {
        setSliderValue(newValues);
        setValue(newValues);
    };

    useEffect(() => {
        if (values.min !== sliderValue.min) {
            setSliderValue(oldValue => {
                return { ...oldValue, min: values.min };
            });
            setValue(oldValue => {
                return { ...oldValue, min: values.min };
            });
        }
        if (values.max !== sliderValue.max) {
            setSliderValue(oldValue => {
                return { ...oldValue, max: values.max };
            });
            setValue(oldValue => {
                return { ...oldValue, max: values.max };
            });
        }
    }, [values]);

    const handleMinInputChangeComplete = () => {
        if (value.min >= minScaleValue && value.min <= sliderValue.max && value.min !== sliderValue.min) {
            setSliderValue(oldValue => {
                return { ...oldValue, min: value.min };
            });
            onChangeCompleted({ min: value.min, max: sliderValue.max });
        } else {
            setValue(oldValue => {
                return { ...oldValue, min: sliderValue.min };
            });
        }
    };

    const handleMaxInputChangeComplete = () => {
        if (value.max <= maxScaleValue && value.max >= sliderValue.min && value.max !== sliderValue.max) {
            setSliderValue(oldValue => {
                return { ...oldValue, max: value.max };
            });
            onChangeCompleted({ min: sliderValue.min, max: value.max });
        } else {
            setValue(oldValue => {
                return { ...oldValue, max: sliderValue.max };
            });
        }
    };
    return (
        <SideFilterSliderWrapper>
            <SliderInputWrapper colorTheme={colorTheme}>
                <input
                    type="number"
                    value={value.min}
                    min={minScaleValue}
                    max={maxScaleValue}
                    onChange={e => {
                        setValue(oldState => {
                            return { ...oldState, min: +e.target.value };
                        });
                    }}
                    onBlur={handleMinInputChangeComplete}
                    onKeyPress={e => {
                        if (e.key === 'Enter') handleMinInputChangeComplete();
                    }}
                />
                <input
                    type="number"
                    value={value.max}
                    min={minScaleValue}
                    max={maxScaleValue}
                    onChange={e => {
                        setValue(oldState => {
                            return { ...oldState, max: +e.target.value };
                        });
                    }}
                    onBlur={handleMaxInputChangeComplete}
                    onKeyPress={e => {
                        if (e.key === 'Enter') handleMaxInputChangeComplete();
                    }}
                />
            </SliderInputWrapper>
            <SliderWrapper colorTheme={colorTheme}>
                <InputRange
                    style={{ ...style }}
                    minValue={minScaleValue}
                    maxValue={maxScaleValue}
                    allowSameValues={true}
                    value={sliderValue}
                    step={1}
                    onChange={handleSliderChange}
                    onChangeComplete={newValue => onChangeCompleted(newValue)}
                />
            </SliderWrapper>
        </SideFilterSliderWrapper>
    );
};

export default SideFiltersSlider;
