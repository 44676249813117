import React, { useEffect } from 'react';

import { OverviewPageWrapper } from './components/overview.styles';
import ExportButtonContainer from '../../../../common/components/ExportButton/components/ExportButtonContainer';
import ExportButton from '../../../../common/components/ExportButton/components/ExportButton.container';
import { EXPORT_TYPES } from '../../../../common/components/ExportButton/components/ExportButton';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import AdTesterOverviewTableContainer from './components/AdTesterOverview.container';
import SingleCountryDropdown from '../../../../common/components/CountriesDropdown/components/SingleCountryDropdown';
import WorldMap from '../../../../common/components/WorldMap/component/WorldMap';

/*
    onError, selectedCountry, onCountryApply props passed from the page container to the [Dropdown, WorldMap] components to
    allow decoupling of logic between the reusable components and page specific implementation
 */
const Overview = ({
    getOverviewData,
    overviewData,
    countries,
    selectedCountry,
    onCountryApply,
    onError,
    clearSelected,
}) => {
    useEffect(() => {
        getOverviewData();
    }, [getOverviewData]);

    const onCountryApplyDecorator = countryData => {
        const { countryId } = countryData;
        onCountryApply(countryId);
        clearSelected([]);
    };

    return (
        <OverviewPageWrapper>
            <ExportButtonContainer text={TRANSLATION_TEXT.EXPORT_TEXT}>
                <ExportButton type={EXPORT_TYPES.EXCEL} />
            </ExportButtonContainer>
            <SingleCountryDropdown
                onApply={id => {
                    clearSelected([]);
                    onCountryApply(id);
                }}
                selectedCountry={selectedCountry}
                countries={countries}
            />
            <WorldMap
                overviewData={overviewData}
                selectedCountries={[selectedCountry]}
                countries={countries}
                onError={onError}
                onCountryClick={onCountryApplyDecorator}
            />
            <AdTesterOverviewTableContainer />
        </OverviewPageWrapper>
    );
};

export default Overview;
