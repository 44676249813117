import styled from 'styled-components';

const TrendWrapper = styled.div`
    width: 166px;
    height: 30px;
    background: darkseagreen;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    span {
        color: ${({ theme }) => theme.blue[900]};
        font-weight: 600;
        font-size: 16px;
    }

    img {
        margin-left: 5px;
    }

    ${props => props.customStyle};
`;

export { TrendWrapper };
