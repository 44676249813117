import { asyncActionCreator } from '../../../../../../../utils/generalUtilities';

export const GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC = asyncActionCreator(
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_ASYNC'
);

export const GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_DATA =
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_DATA';

export const getBptPreferenceAnalysisValueForMoneyActionCreator = payload => ({
    type: GET_BPT_PREFERENCE_ANALYSIS_VALUEFORMONEY_DATA,
    payload,
});
