import { connect } from 'react-redux';
import SideFilters from './SideFilters';
import {
    updateSideFiltersActionCreator,
    setSideFiltersPageActionCreator,
    clearFiltersActionCreator,
    toggleSideFiltersSectionActionCreator,
} from '../data/actions';
import {
    getSelectedSideFiltersSelector,
    getSideFiltersSelector,
    getSideFilterSectionUiSelector,
} from '../data/selectors';
import { getApplyButtonStateComputedSelector, getClearButtonStateComputedSelector } from '../data/computedSelectors';
import { getIsGlobalLoadingSelector } from '../../Loader/data/selectors';
import { applyFiltersActionCreator } from '../../../data/actions';
const mapStateToProps = state => ({
    sideFilters: getSideFiltersSelector(state),
    selectedSideFilters: getSelectedSideFiltersSelector(state),
    appliedButtonDisabled: getApplyButtonStateComputedSelector(state),
    clearButtonDisabled: getClearButtonStateComputedSelector(state),
    sideFiltersSectionUi: getSideFilterSectionUiSelector(state),
    isLoading: getIsGlobalLoadingSelector(state),
});

const mapDispatchToProps = {
    updateSideFiltersActionCreator,
    setSideFiltersPageActionCreator,
    clearFiltersActionCreator,
    applyFiltersActionCreator,
    toggleSideFiltersSectionActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideFilters);
