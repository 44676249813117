import React from 'react';
import PropTypes from 'prop-types';
import {ThemeConsumer} from "@toluna-ui-toolkit/theme";
import {Spinner} from "./Loader.styles";

export const Loader = (props) => {
    const {outterColor, innerColor, customStyle , ...rest} = props;
    return (
        <ThemeConsumer>
            {value => {
                const loaderProps = {
                    theme: value,
                    outterColor,
                    innerColor,
                    customStyle,
                    ...rest
                };
                return (
                    <Spinner {...loaderProps}/>
                )
            }}
        </ThemeConsumer>
    )
};

Loader.propTypes = {
    /** Determines the outter color of the loader */
    outterColor: PropTypes.string,
    /** Determines the inner color of the loader*/
    innerColor: PropTypes.string,
    /** Custom styles */
    customStyle: PropTypes.object,
};
    

