import { asyncActionCreator } from '../../../../../../../utils/generalUtilities';

export const GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC = asyncActionCreator(
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_ASYNC'
);
export const GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_DATA =
    '/pages/brandPreferenceTracker/GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_DATA';

export const getBptPreferenceAnalysisAwarenessActionCreator = payload => ({
    type: GET_BPT_PREFERENCE_ANALYSIS_AWARENESS_DATA,
    payload,
});
