/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components';

const SvgPatternWrapper = styled.svg`
    height: 0;
    .filtered-lines-pattern {
        fill: ${({ theme }) => theme.white.ff};
    }
    .filtered-lines-b {
        fill: ${({ theme }) => theme.green[100]};
    }
    .filtered-lines-b,
    .filtered-lines-e,
    .filtered-lines-f {
        stroke-width: 1px;
        stroke: #166e5b;
    }
    .filtered-lines-c {
        opacity: 1;
        clip-path: url(#a);
    }
    .filtered-lines-d {
        isolation: isolate;
    }
    .filtered-lines-e,
    .filtered-lines-f,
    .filtered-lines-h {
        fill: none;
    }
    .filtered-lines-e {
        stroke-linejoin: round;
    }
    .filtered-lines-e,
    .filtered-lines-f {
        stroke-dasharray: 5 2 6;
    }
    .filtered-lines-g {
        stroke: none;
    }
`;

export const HashPatternJSX = (
    <SvgPatternWrapper>
        <defs>
            <pattern
                id="filtered-lines-pattern"
                patternUnits="userSpaceOnUse"
                width="50"
                height="10"
                patternTransform="scale(1) rotate(0)"
            >
                <g className="filtered-lines-b" transform="translate(0 18.609) rotate(-90)">
                    <rect className="filtered-lines-g" width="18.609" height="50.354" />
                </g>
                <g className="filtered-lines-c">
                    <g className="d" transform="translate(1.693 -0.345)">
                        <path
                            className="filtered-lines-e"
                            d="M-10283.55-8369.407l-3.821,3.174-45.091,37.448"
                            transform="translate(10332.547 8369.407)"
                        />
                        <path
                            className="filtered-lines-e"
                            d="M-10278.487-8369.407l-3.821,3.174-33.863,28.145"
                            transform="translate(10327.484 8378.648)"
                        />
                        <path
                            className="filtered-lines-e"
                            d="M-10273.234-8369.407l-3.821,3.174-22.214,18.445"
                            transform="translate(10322.231 8388.297)"
                        />
                        <path
                            className="filtered-lines-f"
                            d="M-10268.975-8369.407l-16.586,13.5"
                            transform="translate(10317.972 8396.568)"
                        />
                        <path
                            className="filtered-lines-e"
                            d="M-10264.445-8369.407l-3.821,3.174-2.721,2.158"
                            transform="translate(10313.442 8404.839)"
                        />
                        <path
                            className="filtered-lines-f"
                            d="M-10278.553-8369.407l-37.831,31.419"
                            transform="translate(10316.522 8369.407)"
                        />
                        <path
                            className="filtered-lines-f"
                            d="M-10274.246-8369.407l-28.277,23.484"
                            transform="translate(10302.565 8369.407)"
                        />
                        <path
                            className="filtered-lines-f"
                            d="M-10269.594-8369.407l-17.96,14.916"
                            transform="translate(10287.575 8369.407)"
                        />
                        <path
                            className="filtered-lines-f"
                            d="M-10264.942-8369.407l-7.643,6.348"
                            transform="translate(10272.585 8369.407)"
                        />
                    </g>
                </g>
            </pattern>
        </defs>
    </SvgPatternWrapper>
);
