import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import React from 'react';

export const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`;

export const NavigationItems = styled.div`
    display: flex;
    height: inherit;
    justify-content: center;
    width: 100%;
`;

export const NavIcon = styled.div`
    background-repeat: no-repeat;
    width: 35px;
    height: 50px;
    background-position: center;
    background-size: 32px 32px;
    @media (max-width: 1220px) {
        width: 35px;
    }
`;
export const NavItem = styled(({ primaryColor, iconOn, icon, ...rest }) => <NavLink {...rest} />)`
    text-transform: uppercase;
    border-bottom: solid 4px transparent;
    margin: 0 30px;

    color: ${({ theme }) => theme.blue[950]};

    &:active {
        color: ${({ primaryColor }) => primaryColor};
    }
    &:hover {
        color: ${({ primaryColor }) => primaryColor};
    }

    transition: width 0.3s;
    display: flex;
    text-decoration: none;
    text-align: center;
    position: relative;
    p {
        font-size: 16px;
        font-weight: 600;
        padding: 25px 10px;
        @media (max-width: 1045px) {
            padding: 6px;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        display: block;
        left: 0;
        top: 100.2%;
        margin: 0 auto;
        height: 4px;
        background-color: ${({ primaryColor }) => primaryColor};
        width: 0%;
        opacity: 0;
        transition: width cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s, opacity 0.3s;
    }
    &:hover {
        ${NavIcon} {
            background-image: url('${({ iconOn }) => iconOn}');
            background-size: 34px 34px;
        }
        span {
            width: 100%;
            opacity: 1;
        }
    }

    ${NavIcon} {
        background-image: url('${({ icon }) => icon}');
        padding: 0 4px;
        @media only screen and (max-width: 1400px) {
            display: none;
        }
    }
    &.activeLink {
        border-bottom: solid 4px ${({ primaryColor }) => primaryColor};
        color: ${({ primaryColor }) => primaryColor};
        ${NavIcon} {
            background-image: url('${({ iconOn }) => iconOn}');
        }
    }
`;
export const NavDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
