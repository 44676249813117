import styled from 'styled-components';

const SvgWrapper = styled.svg`
    background: ${props => props.theme.grey[50]};
    border: 1px dashed ${props => props.theme.blue[900]};
    overflow: visible;
    padding: ${props => (props.hasNumberOfRespondents ? '5px 0' : '0')};
    .axis text {
        fill: ${props => props.theme.blue[900]};
        font-size: ${props => (props.width <= 300 ? `${props.xAxisResponsiveSize}px` : '12px')};
    }

    .axis line {
        stroke-dasharray: 3.3;
        stroke: ${props => props.theme.grey[250]};
        stroke-width: 1;
    }

    .bar-chart-top-value {
        fill: ${props => props.theme.blue[900]};
        font-size: 12px;
    }
    .sigTestContentDelimiter {
        display: none;
        border-bottom: 1px solid rgba(69, 69, 69, 0.1); // equivalant to grey[800] from default theme but with opacity
        border-width: thin;
        padding: 3px 5px;
        color: ${props => props.theme.blue[900]};
    }
    .tooltipContent {
        display: none;
        padding-top: 27px;
    }
    .brandName {
        color: white;
        z-index: 100;
        width: 62px;
        word-wrap: break-word;
        height: auto;
        text-align: center;
        border: solid 1px red;
        color: ${props => props.theme.blue[900]};
        background-color: white;
        border-radius: 2px;
        border-width: 2px;
        font-size: 12px;
    }
    .axis foreignObject .numberOfRespondents {
        font-family: Source Sans Pro, Open Sans;
        color: ${props => props.theme.blue[900]};
        font-size: ${props => (props.width <= 300 ? `${props.xAxisResponsiveSize - 1.5}px` : '10px')};
        opacity: 0.8;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;
const Title = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: ${props => props.theme.blue[900]};
    margin-bottom: 20px;
    margin-top: 30px;

    transition: opacity 0.15s ease-in;

    ${props => props.customStyle};
`;
export { SvgWrapper, Title };
