import styled from '@emotion/styled';

export const StyledCheckBox = styled.div`
    display: inline-flex;
    align-items: flex-start;
    ${props => (props.customStyle && props.customStyle.wrapper ? props.customStyle.wrapper : '')}
`;

export const InputCheckBox = styled.input`
    visibility: hidden;
    margin: 0;
    position: absolute;
`;

export const StyledIcon = styled.span`
    width: 18px;
    height: 18px;
    min-width: 18px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 11px;
    color: ${props => (props.checked ? 'white' : 'transparent')};
    background-color: ${props => (props.checked ? props.theme.lightColor : '#f1f2f7')};
    border: 1px solid ${props => (props.checked ? props.theme.lightColor : '#c8c8c8')};
    border-radius: 3px;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
    :hover {
        ${props => !props.checked && 'border-color: #b8b8b8'}
    }
    :focus {
        ${props => (props.enableKeyboardNavigation ? getKeyboardNavigationStyling(props.checked) : '')}
    }
    i {
        line-height: unset;
    }
    ${props => (props.customStyle && props.customStyle.icon ? props.customStyle.icon : '')}
`;

const getKeyboardNavigationStyling = checked => {
    if (checked) {
        return `
            outline: none;
            box-shadow: 0 0 0 9px #D9E1F0;
        `;
    } else {
        return `
            outline: none;
            box-shadow: 0 0 0 9px #e7e7e7;
        `;
    }
};

export const TextLabel = styled.label`
    display: inline-flex;
    height: 100%;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    font-family: 'MuseoSans-300';
    color: #8292a8;
    margin-bottom: 0;
    margin-left: 10px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
    ${props => (props.customStyle && props.customStyle.label ? props.customStyle.label : '')}
`;
