import { basePath } from '../services/utils';
import { defaultTheme } from './defaultTheme';

export const DASHBOARD_PAGE = {
    path: '/Dashboard',
    name: 'Axa Dashboard',
    exact: true,
    requiredPermission: null,
    config: {
        primaryColor: defaultTheme.blue[900],
        secondaryColor: defaultTheme.blue[50],
    },
};
export const SURVEY_FIELDWORK_PAGE = {
    path: '/Dashboard/SurveyFieldwork',
    order: 1,
    name: 'Survey Fieldwork',
    requiredPermission: 'Survey Fieldwork',
    config: {
        primaryColor: defaultTheme.blue[900],
        secondaryColor: defaultTheme.blue[50],
        icon: `${basePath}/visuals/survey_fieldwork_icon.svg`,
        iconON: `${basePath}/visuals/survey_fieldwork_icon_on.svg`,
    },
    SUB_ROUTES: {
        OVERVIEW_PAGE: {
            id: 1,
            path: '/Dashboard/SurveyFieldwork',
            name: 'Overview',
            isDefault: true,
            activeFlag: true,
        },
    },
};

export const ADTESTER_PAGE = {
    path: '/Dashboard/AdTester',
    order: 2,
    name: 'AdTester',
    requiredPermission: 'Ad Tester',
    config: {
        primaryColor: defaultTheme.orange[700],
        secondaryColor: defaultTheme.red[100],
        icon: `${basePath}/visuals/adtester_icon.svg`,
        iconON: `${basePath}/visuals/adtester_icon_on.svg`,
    },
    SUB_ROUTES: {
        HOME_PAGE: {
            id: 1,
            path: '/Dashboard/AdTester/Homepage',
            name: 'Homepage',
            activeFlag: true,
        },
        OVERVIEW_PAGE: {
            id: 2,
            path: '/Dashboard/AdTester',
            name: 'Overview',
            isDefault: true,
            activeFlag: true,
        },
        AD_DETAILS_METRICS_PAGE: {
            id: 3,
            path: '/Dashboard/AdTester/AdDetailsMetrics',
            name: 'Ad Details and Metrics',
            activeFlag: true,
        },
        ADS_COMPARISON_PAGE: {
            id: 4,
            path: '/Dashboard/AdTester/AdsComparison',
            name: 'Ads Comparison',
            activeFlag: true,
        },
    },
};
export const BPT_PAGE = {
    path: '/Dashboard/BrandPreferenceTracker',
    order: 3,
    name: 'Brand Preference Tracker',
    requiredPermission: 'BPT',
    config: {
        primaryColor: defaultTheme.violet[400],
        secondaryColor: defaultTheme.violet[100],
        icon: `${basePath}/visuals/bpt_icon.svg`,
        iconON: `${basePath}/visuals/bpt_icon_on.svg`,
    },
    SUB_ROUTES: {
        HOME_PAGE: {
            id: 1,
            path: '/Dashboard/BrandPreferenceTracker',
            name: 'Homepage',
            isDefault: true,
            activeFlag: true,
        },
        PREFERENCE_ANALYSIS_PAGE: {
            id: 2,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis',
            name: 'Preference Analysis',
            activeFlag: false,
        },
        TOP_LINES_PAGE: {
            id: 3,
            path: '/Dashboard/BrandPreferenceTracker/TopLines',
            name: 'Top Lines',
            activeFlag: true,
        },
        FLAT_FILE_PAGE: {
            id: 4,
            path: '/Dashboard/BrandPreferenceTracker/FlatFile',
            name: 'Flat File',
            activeFlag: true,
        },
    },
};

export const PREFERENCE_ANALYSIS_TABS = {
    TAB_ITEMS: {
        AWARENESS: {
            id: 1,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis',
            name: 'Awareness',
        },
        CONSIDERATION: {
            id: 2,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis/Consideration',
            name: 'Consideration/ Intention to buy',
        },
        IMAGE: {
            id: 3,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis/Image',
            name: 'Image',
        },
        AD_RECALL: {
            id: 4,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis/AdRecall',
            name: 'Ad Recall & Buzz',
        },
        VALUE_FOR_MONEY: {
            id: 5,
            path: '/Dashboard/BrandPreferenceTracker/PreferenceAnalysis/ValueForMoney',
            name: 'Value for money',
        },
    },
};

export const FILTER_SEGMENT_LIBRARY_PAGE = {
    path: '/Dashboard/FilterSegmentLibrary',
    name: 'Filter Segment Library',
    requiredPermission: 'Segment Library',
    config: {
        primaryColor: defaultTheme.green[700],
        secondaryColor: defaultTheme.green[100],
        icon: `${basePath}/visuals/filter_segment_icon.svg`,
        iconON: `${basePath}/visuals/filter_segment_icon_on.svg`,
    },
};

export const COMPETITIVE_MEDIA = {
    path: '/Dashboard/CompetitiveMedia',
    name: 'Competitive Media',
    order: 4,
    // TODO : When we get page permissions from BE replace BPT with CompetitiveMedia
    requiredPermission: 'Competitive Media',
    config: {
        primaryColor: defaultTheme.green[700],
        secondaryColor: defaultTheme.green[100],
        icon: `${basePath}/visuals/competitiveMedia_icon.svg`,
        iconON: `${basePath}/visuals/competitiveMedia_icon_on.svg`,
    },
};

export const USER_MANAGEMENT_PAGE = {
    path: '/UserManagement',
    name: 'User Management',
};

export const BACK_OFFICE_PAGE = {
    path: '/BackOffice',
    name: 'Back Office',
};

export const TRANSVERSAL_VIEW_PAGE = {
    path: '/Dashboard/TransversalView',
    name: 'Transversal View',
    order: 5,
    requiredPermission: 'Transversal View',
    config: {
        primaryColor: defaultTheme.brown[400],
        secondaryColor: defaultTheme.brown[100],
        icon: `${basePath}/visuals/Inactive-icon-tranvsersal-view.svg`,
        iconON: `${basePath}/visuals/Active-Icon-tranvsersal-view.svg`,
    },
};

export const ROUTES_TO_WATCH_SESSION = [
    {
        PATH: DASHBOARD_PAGE.path,
    },
];

export const ROUTES_TO_USE_LOGGING = [
    {
        PATH: SURVEY_FIELDWORK_PAGE.path,
        LOG_ID: 1,
    },
    {
        PATH: ADTESTER_PAGE.path,
        LOG_ID: 2,
    },
    {
        PATH: ADTESTER_PAGE.SUB_ROUTES.OVERVIEW_PAGE.path,
        LOG_ID: 3,
    },
    {
        PATH: ADTESTER_PAGE.SUB_ROUTES.AD_DETAILS_METRICS_PAGE.path,
        LOG_ID: 4,
    },
    {
        PATH: ADTESTER_PAGE.SUB_ROUTES.ADS_COMPARISON_PAGE.path,
        LOG_ID: 9,
    },
    {
        PATH: BPT_PAGE.SUB_ROUTES.PREFERENCE_ANALYSIS_PAGE.path,
        LOG_ID: 5,
    },
    {
        PATH: USER_MANAGEMENT_PAGE.path,
        LOG_ID: 6,
    },
    {
        PATH: BACK_OFFICE_PAGE.path,
        LOG_ID: 7,
    },
    {
        PATH: COMPETITIVE_MEDIA.path,
        LOG_ID: 8,
    },
    {
        PATH: DASHBOARD_PAGE.path,
        LOG_ID: 10,
    },
    {
        PATH: TRANSVERSAL_VIEW_PAGE.path,
        LOG_ID: 11,
    },
];

export const MAINTENANCE_PAGE_OVERRIDE = {
    path: '/bdlogin',
    name: 'Login',
};
