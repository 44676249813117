import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ErrorWrapper = styled.div`
    color: ${({ theme }) => theme.red[400]};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.customStyle};
`;
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        /* Log the error to a service here */
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorWrapper customStyle={this.props.customStyle}>
                    <span>{this.props.text}</span>
                </ErrorWrapper>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    customStyle: PropTypes.object,
    text: PropTypes.string.isRequired,
};
