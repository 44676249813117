import styled, { css } from 'styled-components';
import { dropdownLegendTemplate } from '../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/components/RankingTable/RankingTable.styles';

export const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: ${({ displayDirection }) => displayDirection};
    ${({ customStyle }) => customStyle}
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: flex-end;
    ${dropdownLegendTemplate}
    span {
        display: ${({ displayDirection }) => displayDirection !== 'column' && 'block'};
        min-width: 20px;
        border: ${({ legendBorderColor }) => (legendBorderColor ? `1px solid ${legendBorderColor}` : '')};
        ${({ customStyle }) => customStyle?._legendStyle}
    }
    margin-top: 3px;
    font-size: 14px;
    color: ${({ theme }) => theme.blue[950]};
    ${({ displayDirection }) =>
        displayDirection !== 'row' &&
        css`
            & div {
                display: flex;
                align-items: center;
            }
        `};
    ${({ customStyle }) => customStyle}
`;
