import { connect } from 'react-redux';
import ValueForMoneyTab from './ValueForMoney.tab';
import { getBptPreferenceAnalysisValueForMoneyActionCreator } from '../data/actions';
import { getBptPreferenceAnalysisValueForMoneySelector } from '../data/selector';
import {
    getValueForMoneyHorizontalChartsComputedSelector,
    getCombinedStackedBarChartDataSelector,
} from '../data/computedSelectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../../../../common/components/TopFilter/data/computedSelectors';
import { getFiltersThrownError } from '../../../../../../../common/data/selectors';
import { getSelectedFiltersComputedSelector } from './../../../../../../../common/components/SelectedFilters/data/computedSelectors';

const mapStateToProps = state => ({
    combineStackedChartsData: getCombinedStackedBarChartDataSelector(state),
    data: getBptPreferenceAnalysisValueForMoneySelector(state),
    horizontalChartsData: getValueForMoneyHorizontalChartsComputedSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
});

const mapDispatchToProps = {
    valueForMoneyFetchData: getBptPreferenceAnalysisValueForMoneyActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValueForMoneyTab);
