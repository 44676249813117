import React from 'react';
import { FOOTER_JSX } from '../../../utils/translations';
import { FooterWrapper, FooterContent } from './Footer.styles';

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterContent>{FOOTER_JSX}</FooterContent>
        </FooterWrapper>
    );
};

export default Footer;
