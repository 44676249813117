import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 0;
    box-sizing: border-box;
    background-image: url(${({ background }) => background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: ${({ theme }) => theme.blue[700]};
    overflow-y: auto;
    position: relative;
`;

export const LoginWrapper = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ theme }) => theme.white.ff};
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[78]};
    border-radius: 2px;
    padding: 50px 0;
`;
export const Branding = styled.div`
    height: 94px;
    width: 170px;
    background-image: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    border-radius: 2px;
`;
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    gap: 50px;
    width: 80%;
`;
export const Input = styled.input`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px;
    color: ${({ theme, withError }) => (withError ? theme.red[400] : theme.blue[600])};
    background-color: ${({ theme }) => theme.white.ff};
    font-size: 19px;
    border: none;
    border-bottom: solid 1px ${({ theme }) => theme.blue[700]};
    outline: none;
    filter: none;
    font-family: Source Sans Pro, Open Sans;
    &:-webkit-autofill {
        -webkit-text-fill-color: ${({ theme }) => theme.blue[600]}!important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:autofill {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: ${({ theme }) => theme.blue[600]}!important;
    }
    &:-webkit-autofill::first-line {
        font-size: 19px;
        font-family: Source Sans Pro, Open Sans;
    }
    &:autofill::first-line {
        font-family: Source Sans Pro, Open Sans;
        font-size: 21px;
    }
    &::placeholder {
        color: ${({ theme }) => theme.blue[600]};
        opacity: 0.3;
    }

    &:focus,
    &:not(:placeholder-shown) {
        border-bottom: solid 1px ${({ theme }) => theme.blue[600]};
    }
`;
export const SubmitButton = styled.button`
    margin: 0 auto;
    height: 59px;
    width: 100%;
    background-color: ${({ theme }) => theme.blue[600]};
    border: solid 1px ${({ theme }) => theme.blue[700]};
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 8px;
    font-family: inherit;
    &:hover {
        box-shadow: 0px 0px 8px rgb(135, 149, 177);
    }
`;
export const SecondaryAction = styled.button`
    color: ${({ theme }) => theme.blue[600]};
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: 0.4rem;
    font-weight: 500;
    cursor: pointer;
    font-family: inherit;
`;
export const InputWrapper = styled.div`
    width: 100%;
    position: relative;
`;
export const ErrorLabel = styled.label`
    position: absolute;
    top: ${({ isAboveInputs }) => isAboveInputs && '-30px'};
    padding-left: ${({ validation }) => (validation ? '15px' : '0px')};
    opacity: ${({ validation }) => (validation ? '0' : '1')};
    visibility: ${({ validation }) => (validation ? 'hidden' : 'visible')};
    transition: visibility 0.2s linear, opacity 0.2s linear, padding 0.3s linear;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.red[400]};
    width: 100%;
    p {
        padding: 2px 6px;
        margin: 0;
    }
`;

export const Title = styled.p`
    margin: 0;
    font-size: 18px;
`;

export const Subtitle = styled.p`
    margin: 10px 0px 13px 0px;
    max-width: 76%;
    text-align: center;
    font-size: 14px;

    p {
        margin: 0;
        padding: 0;
    }
`;

export const Email = styled.p`
    margin: 2px 0 32px 0;
    color: ${({ theme }) => theme.blue[600]};
    font-weight: 700;
`;

export const ButtonAsLink = styled.button`
    padding: 0 0 0 0;
    margin: 0 3px;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    color: ${({ theme }) => theme.blue[600]};
    cursor: pointer;
`;
