import React from 'react';
import OverviewContainer from './subpages/Overview/Overview.container';
import {SurveyFieldworkPageWrapper} from './surveyFieldwork.styles';

const SurveyFieldworkPage = ()=> {
    return(
        <SurveyFieldworkPageWrapper>
           <OverviewContainer/>
        </SurveyFieldworkPageWrapper>
    )
} 
export default SurveyFieldworkPage;