import {
    SectionWrapper,
    FlexColumnWrapper,
    DoubleInputSeparator,
    ReusableInput,
    ModalBuilder,
    MComponent,
} from '@toluna-dashboarding/usermanagement';
import ExternalUserTypeFilter from '../external-components/ExternalUserTypeFilter';
import { defaultTheme } from '../../../../../utils/defaultTheme';

const additionalFiltersLeftSpecs = new ModalBuilder('Additional Filters');
const additionalFiltersRightSpecs = new ModalBuilder('AdditionalFilters');

const leftSide = new MComponent(SectionWrapper, 'additional-filters-left-side-wrapper').setProps({
    title: 'Number of Logins',
    overwriteStyleObject: {
        width: '490px',
        height: '173.5px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 6px',
        backgroundColor: defaultTheme.white.ff,
        color: defaultTheme.blue[950],
        opacity: 1,
        fontSize: '15px',
        borderRadius: '3px',
        margin: '35px 30px 0 0',
    },
});

const minInputComponent = new MComponent(ReusableInput).setProps({
    label: 'Min',
    id: 'LoginFrom',
    type: 'number',
    useFor: 'LoginFrom',
    width: '50px',
});
const maxInputComponent = new MComponent(ReusableInput).setProps({
    label: 'Max',
    id: 'LoginTo',
    type: 'number',
    useFor: 'LoginTo',
    width: '50px',
});
const doubleInputSeparator = new MComponent(DoubleInputSeparator, 'double-input-separator').setProps({
    connectorString: '-',
    id: 'dis-ric-connector',
    leftSide: minInputComponent,
    rightSide: maxInputComponent,
});

leftSide.addChild(doubleInputSeparator);
additionalFiltersLeftSpecs.addChild(leftSide);

const rightSide = new MComponent(FlexColumnWrapper, 'additional-filters-right-side-wrapper');
const sectionWrapper = new MComponent(SectionWrapper, 'additional-filters-right-side-section').setProps({
    title: 'UserType',
    overwriteStyleObject: {
        width: '650px',
        height: '382px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 6px',
        backgroundColor: defaultTheme.white.ff,
        color: defaultTheme.blue[950],
        opacity: 1,
        fontSize: '15px',
        borderRadius: '3px',
        marginTop: '35px',
    },
});
rightSide.addChild(sectionWrapper);
additionalFiltersRightSpecs.addChild(rightSide);

additionalFiltersRightSpecs
    .findChildById('additional-filters-right-side-section')
    .addChild(new MComponent(ExternalUserTypeFilter, 'external-filters-user-type'));

export const additionalFiltersConfig = {
    additionalFiltersLeftSpecs,
    additionalFiltersRightSpecs,
};
