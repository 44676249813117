import { createSelector } from 'reselect';
import { getLocationSelector } from '../../../common/components/TopFilter/data/selectors';
import { UPLIFTS_SELECTORS_BASED_ON_ROUTE } from '../utils/utils';
import { getUpliftsDataSelector as getAdDetailsAndMetricsUpliftsDataSelector } from '../subpages/AdDetailsMetrics/data/selectors';
import { getUpliftsDataSelector as getAdsComparisonUpliftsDataSelector } from '../subpages/AdsComparison/data/selectors';

export const getUpliftsSelectorsBasedOnpPageComputed = createSelector(
    [getAdDetailsAndMetricsUpliftsDataSelector, getAdsComparisonUpliftsDataSelector],
    (adDetailsData, adsCompData) => {
        return { adsCompData, adDetailsData };
    }
);

export const getUpliftsComputedDataSelector = createSelector(
    [getUpliftsSelectorsBasedOnpPageComputed, getLocationSelector],
    (storeData, currentLocation) => {
        const upliftsData = storeData[UPLIFTS_SELECTORS_BASED_ON_ROUTE[currentLocation]];
        if (upliftsData) {
            const { kpis } = upliftsData;
            let obj = {};
            kpis?.forEach(source => {
                if (source.kpiID === 3) return; // Legend object
                source.items.forEach(el => {
                    if (el.itemType === 'main') {
                        obj = {
                            ...obj,
                            [el.itemGroupDescription]: [...(obj[el.itemGroupDescription] || [])],
                        };
                        obj[el.itemGroupDescription].push({ ...el, kpiLabel: source.kpiLabel });
                    }
                });
            });
            if (Object.keys(obj).length > 0) {
                const maxSize = new Array(Object.keys(obj).map((el, _) => obj[el])[0].length).fill(null);
                let cols = maxSize.map(() => []);
                Object.keys(obj).forEach(el => {
                    maxSize.forEach((_, i) => {
                        cols[i].push(obj[el][i]);
                    });
                });

                const kpiLabelWithGroupPair = Object.keys(obj).reduce((acc, el) => {
                    const group = obj[el];
                    group.forEach(groupElement => {
                        const idx = acc.findIndex(accEl => Object.keys(accEl)[0] === groupElement.kpiLabel);

                        if (idx === -1) {
                            acc.push({ [groupElement.kpiLabel]: [el] });
                        }
                        if (idx !== -1) {
                            const groupElementExists = acc[idx][groupElement.kpiLabel].includes(el);
                            if (!groupElementExists) {
                                acc[idx][groupElement.kpiLabel].push(el);
                            }
                        }
                    });
                    return acc;
                }, []);
                return { restOfColumns: cols, sectionData: upliftsData, kpiLabelWithGroupPair };
            }
            return null;
        }
        return null;
    }
);
