const transformColor = (
  color,
  hueTransformation,
  saturationTransformation,
  lightnessTransformation
) => {
  const hsl = rgbToHsl(hexToRgb(color));

  const { h, s, l } = {
    h: hueTransformation(hsl.h),
    s: saturationTransformation(hsl.s),
    l: lightnessTransformation(hsl.l)
  };

  return `hsl(${h},${s}%,${l}%)`;
};

export const isHexColor = (hex) => {
    const regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    return regex.test(hex);
};


export const convertLightToLightestColor = color => {
  return transformColor(
    color,
    hue => hue,
    saturation => saturation + 29 / 52 * (100 - saturation),
    () => 98
  );
};

export const convertLightToLighterColor = color => {
  return transformColor(
    color,
    hue => hue,
    saturation => saturation - 6 / 52 * (100 - saturation),
    lightness => lightness + 3 / 4 * (100 - lightness)
  );
};

export const convertLightToMediumColor = color => {
  return transformColor(color, hue => hue, saturation => saturation, lightness => 0.85 * lightness);
};

export const convertLightToDarkColor = color => {
  return transformColor(color, hue => hue, saturation => saturation, lightness => 0.6 * lightness);
};

export const convertLightToDisabledColor = color => {
  return transformColor(color, hue => hue, saturation => 0.6 * saturation, () => 80);
};

export const hexToRgb = (hex) => {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export function rgbToHex({r, g, b}) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}


export function rgbToHsv (rgb) {
    if(!rgb) return false;
    let rr, gg, bb;
    let r = rgb.r / 255;
    let g = rgb.g / 255;
    let b = rgb.b / 255;
    let h, s;
    let v = Math.max(r, g, b),
        diff = v - Math.min(r, g, b),
        diffc = function(c){
            return (v - c) / 6 / diff + 1 / 2;
        };

    if (diff === 0) {
        h = s = 0;
    } else {
        s = diff / v;
        rr = diffc(r);
        gg = diffc(g);
        bb = diffc(b);

        if (r === v) {
            h = bb - gg;
        }else if (g === v) {
            h = (1 / 3) + rr - bb;
        }else if (b === v) {
            h = (2 / 3) + gg - rr;
        }
        if (h < 0) {
            h += 1;
        }else if (h > 1) {
            h -= 1;
        }
    }
    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        v: Math.round(v * 100)
    };
}


export function hsvToRgb({h, s, v}) {
    let r, g, b;
    let i;
    let f, p, q, t;

    // Make sure our arguments stay in-range
    h = Math.max(0, Math.min(360, h));
    s = Math.max(0, Math.min(100, s));
    v = Math.max(0, Math.min(100, v));

    // We accept saturation and value arguments from 0 to 100 because that's
    // how Photoshop represents those values. Internally, however, the
    // saturation and value are calculated from a range of 0 to 1. We make
    // That conversion here.
    s /= 100;
    v /= 100;

    if(s === 0) {
        // Achromatic (grey)
        r = g = b = v;
        return [
            Math.round(r * 255),
            Math.round(g * 255),
            Math.round(b * 255)
        ];
    }

    h /= 60; // sector 0 to 5
    i = Math.floor(h);
    f = h - i; // factorial part of h
    p = v * (1 - s);
    q = v * (1 - s * f);
    t = v * (1 - s * (1 - f));

    switch(i) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;

        case 1:
            r = q;
            g = v;
            b = p;
            break;

        case 2:
            r = p;
            g = v;
            b = t;
            break;

        case 3:
            r = p;
            g = q;
            b = v;
            break;

        case 4:
            r = t;
            g = p;
            b = v;
            break;

        default: // case 5:
            r = v;
            g = p;
            b = q;
    }

    return {
        r:Math.round(r * 255),
        g:Math.round(g * 255),
        b:Math.round(b * 255)
    };
}



export function rgbToHsl({ r, g, b }) {
  (r /= 255); (g /= 255); (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  };
}

export const convertHexToColorObject = (hex) => {
    const re = /[0-9A-Fa-f]{6}/g;
    const convert = (hex) => {
        const rgb = hexToRgb(hex);
        const hsv = rgbToHsv(rgb);
        const hsl = rgbToHsl(rgb);


        const newState = {
            hex,
            rgb,
            hsv,
            hsl
        };
        return newState;
    };
    if (hex.length === 4 || hex.length === 7 & re.test(hex)) {
        const result = convert(hex);
        return result;
    }

    return convert("#337ab7");
};