import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body{
    margin: 0;
    min-width:1011px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:Source Sans Pro, Open Sans;
}
label{
    font-family:Source Sans Pro, Open Sans !important;
}
input {
    font-family:Source Sans Pro, Open Sans !important;
}

code {
  font-family:Source Sans Pro, Open Sans;
}

/* LineGraph specific CSS Styling */
.axis {
  font-weight: 800;
}
.axis line{
  stroke: ${({ theme }) => theme.grey[300]};
  stroke-width: 0.5;
  shape-rendering: crispEdges;
}

.line-graph-border{
 fill:none;
}

.axis text {
  fill: ${({ theme }) => theme.grey[900]};
  font-family:Source Sans Pro, Open Sans;
  font-size: 120%;
}
.mainWrapper{
  background: ${({ theme }) => theme.grey[25]};
  width:100%;
    // we need a minimum width of 1024. But the width of the scrollbar needs to be taken in consideration (1024-13px scrollbarwidth), otherwise, the horizontal overflow scrollbar will appear at 1024+13px.
  min-width:1011px;
}

.hide-tick-class{
  display : none;
}
//**Scrollbar styling */

  &::-webkit-scrollbar {
    width: 13px;
    height: 15px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.grey[30]};
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.blue[950]};
    height: 30px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.blue[400]};
  }

  
  /* User Management table style overwrite */
  //Table header
  .kTviwc{
    background-color: transparent !important;
  }
  //Search Icon
  .dQjyoq.dQjyoq {
      margin-left: 22px;
      margin-top: 10px;
      width: 17px;
      height: 17px;
  }
  .ezIDCr{
    box-sizing: border-box;
  }
`;
