import { basePath } from '../../services/utils';
import { defaultTheme } from '../../utils/defaultTheme';
import {
    AD_TESTER_HOMEPAGE_JSX,
    BPT_SECTION_MAIN_JSX,
    BPT_SECTION_SECONDARY_JSX,
    COMPETITIVE_MEDIA_JSX,
    TRANSLATION_TEXT,
} from '../../utils/translations';

export const homeSections = [
    {
        sectionTitle: 'Brand Preference Tracker',
        sectionID: 'bpt-landing',
        color: defaultTheme.violet[400],
        bgColor: defaultTheme.violet[100],
        icon: `${basePath}/visuals/brand_report_icon.png`,
        sectionImage: `${basePath}/visuals/brand_section.png`,
        innerIcons: [
            {
                name: 'target',
                path: `${basePath}/visuals/target.png`,
            },
            {
                name: 'interviews',
                path: `${basePath}/visuals/interviews.png`,
            },
            {
                name: 'sample',
                path: `${basePath}/visuals/sample.png`,
            },
        ],
        paragraphs: [BPT_SECTION_MAIN_JSX],
        outerElements: [BPT_SECTION_SECONDARY_JSX],
    },
    {
        sectionTitle: 'Adtester',
        sectionID: 'adtester-landing',
        color: defaultTheme.orange[700],
        bgColor: defaultTheme.red[100],
        icon: `${basePath}/visuals/adtester_report_icon.png`,
        sectionImage: `${basePath}/visuals/adtester_section.png`,
        paragraphs: [AD_TESTER_HOMEPAGE_JSX],
    },
    {
        sectionTitle: 'Filter Segment Library',
        sectionID: 'fls-landing',
        color: defaultTheme.green[700],
        bgColor: defaultTheme.green[100],
        icon: `${basePath}/visuals/filter_report_icon.png`,
        sectionImage: `${basePath}/visuals/filter_section.png`,
        paragraphs: TRANSLATION_TEXT.FILTER_SEGMENT_LIBRARY_PARAGRAPHS_TEXT,
    },
    {
        sectionTitle: 'Competitive Media',
        sectionID: 'cm-landing',
        color: defaultTheme.green[700],
        bgColor: defaultTheme.green[100],
        icon: `${basePath}/visuals/cm_report_icon.png`,
        sectionImage: null,
        paragraphs: [COMPETITIVE_MEDIA_JSX],
    },

    // shall be updated when mock-up is approved
];
