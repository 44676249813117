import { connect } from "react-redux";
import LoginPage from "./login.page";
import { changeLoginPageActionCreator , isResetTokenValidActionCreator} from "./data/actions";
import { getCurrentLoginPageSelector } from "./data/selectors";

const mapStateToProps = (state) => ({
	currentPage: getCurrentLoginPageSelector(state),
});

const mapDispatchToProps = { changeLoginPageActionCreator , isResetTokenValidActionCreator};

const LoginPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginPage);

export default LoginPageContainer;
