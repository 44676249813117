import { defaultTheme } from '../../../utils/defaultTheme';

/*
    @@@ Keys prefixed with an _ allow as many CSS properties as you want.
*/
export const theme = {
    global: {
        fontFamily: 'Source Sans Pro, Open Sans',
    },
    _mainWrapper: {
        backgroundColor: defaultTheme.white.ff,
        width: '95%',
    },
    table: {
        tableWrapper: {
            borderBottom: `2px solid ${defaultTheme.blue[950]}`,
            borderLeft: `1px solid ${defaultTheme.white.ff}`,
            borderRight: `1px solid ${defaultTheme.white.ff}`,
            borderTop: `1px solid ${defaultTheme.white.ff}`,
        },
        tableHead: {
            backgroundColor: defaultTheme.white.ff,
            color: defaultTheme.blue[950],
            borderTop: `1px solid ${defaultTheme.white.ff}`,
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderBottom: `2px solid ${defaultTheme.blue[950]}`,
            borderLeft: 'none',
            borderRight: 'none',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Source Sans Pro, Open Sans',
        },
        bodyRow: {
            activeColor: defaultTheme.blue[55],
            hoverColor: defaultTheme.blue[55],
            backgroundColor: defaultTheme.white.ff,
            backgroundColorSecondary: defaultTheme.grey[50],
            fontFamily: 'Source Sans Pro, Open Sans',
        },
        column: {
            separatorColor: 'transparent',
        },
        global: {
            fontFamily: 'Source Sans Pro, Open Sans',
        },
        paginationWrapper: {
            border: 'none',
        },
        paginationDropdown: {
            container: {
                width: '80px',
                borderColor: defaultTheme.blue[950],
            },
            option: {
                fontSize: '14px',
                paddingLeft: '15px',
                color: defaultTheme.black.ff,
                selected: {
                    background: defaultTheme.blue[55],
                },
                focused: {
                    background: defaultTheme.grey[50],
                },
                selectedAndFocused: {
                    background: defaultTheme.grey[50],
                },
            },
        },
        paginationArrows: {
            normal: {
                border: `1px solid ${defaultTheme.blue[950]}`,
                color: defaultTheme.blue[950],
                background: defaultTheme.white.ff,
            },
            disabled: {
                color: defaultTheme.grey[300],
                border: `1px solid ${defaultTheme.grey[300]}`,
                background: defaultTheme.white.ff,
            },
            hover: {
                border: `1px solid ${defaultTheme.blue[950]}`,
                color: defaultTheme.blue[950],
                background: defaultTheme.grey[50],
            },
        },
    },
    modal: {
        _overlay: {
            backgroundColor: defaultTheme.blue['400-4d'],
        },
        _wrapper: {
            height: '80%',
            minWidth: '750px',
        },
        _header: {
            background: defaultTheme.white.ff,
            color: defaultTheme.blue[950],
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottom: `1px solid ${defaultTheme.grey[300]}`,
            fontSize: '18px',
            padding: '30px 0',
        },
        footer: {
            _wrapper: {
                borderTop: `1px solid ${defaultTheme.grey[300]}`,
                padding: '30px 50px',
                justifyContent: 'space-between',
                background: defaultTheme.white.ff,
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
            },
            // Overwrite the wrapper style for specific modals
            _createUser: {},
            _additionalFilters: {},
            _userProfile: {},
        },
        _body: {
            background: defaultTheme.white.ff,
            padding: '25px 50px 40px',
        },
        inputs: {
            _label: {
                fontSize: '15px',
                color: defaultTheme.blue[950],
            },
            inputField: {
                height: '35px',
                fontSize: '15px',
                fontWeight: 'normal',
                color: defaultTheme.blue[950],
                textAlign: 'left',
                focusColor: defaultTheme.white.ff,
                focusBorder: `1px solid ${defaultTheme.blue[950]}`,
            },
        },
        buttons: {
            cancel: {
                _wrapper: {
                    color: defaultTheme.blue[950],
                    backgroundColor: 'transparent',
                    border: `1px solid ${defaultTheme.blue[950]}`,
                    marginRight: '0px',
                    marginLeft: '0px',
                    width: '105px',
                    height: '45px',
                },
                _wrapperHover: {
                    background: defaultTheme.grey[50],
                    color: defaultTheme.blue[950],
                    border: `1px solid ${defaultTheme.blue[950]}`,
                },
                // Overwrite wrapper styles for specific modals
                createUser: { _default: {}, _hover: {} },
                additionalFilters: { _default: {}, _hover: {} },
                userProfile: { _default: {}, _hover: {} },
            },
            ok: {
                _wrapper: {
                    backgroundColor: defaultTheme.blue[600],
                    color: defaultTheme.white.ff,
                    border: `1px solid ${defaultTheme.blue[600]}`,
                    marginRight: '0px',
                    height: '45px',
                },
                _wrapperHover: {
                    background: defaultTheme.blue[400],
                    color: defaultTheme.white.ff,
                    border: `1px solid ${defaultTheme.blue[400]}`,
                },
                // Overwrite wrapper styles for specific modals
                createUser: {
                    _default: {
                        width: '250px',
                        marginLeft: '-285px',
                    },
                    _hover: {},
                    _disabled: {},
                },
                additionalFilters: { _default: { width: '105px' }, _hover: {}, _disabled: {} },
                userProfile: {
                    _default: { width: '105px' },
                    _hover: {},
                    _disabled: {
                        borderColor: defaultTheme.grey[250],
                        background: defaultTheme.grey[250],
                    },
                },
            },
            actions: {
                resetButton: {
                    _default: {
                        width: '140px',
                        height: '35px',
                        backgroundColor: defaultTheme.white.ff,
                        color: defaultTheme.blue[950],
                        border: `1px solid ${defaultTheme.blue[950]}`,
                        margin: '10px 5px',
                    },
                    _hover: {
                        backgroundColor: defaultTheme.grey[50],
                    },
                    _disabled: {
                        backgroundColor: defaultTheme.white.ff,
                        color: defaultTheme.grey[350],
                        border: `1px solid ${defaultTheme.grey[350]}`,
                        opacity: 0.5,
                    },
                },
                deleteButton: {
                    _default: {
                        backgroundColor: defaultTheme.white.ff,
                        color: defaultTheme.blue[950],
                        width: '110px',
                        height: '35px',
                        border: `1px solid ${defaultTheme.blue[950]}`,
                        margin: '10px 5px',
                    },
                    _hover: {
                        backgroundColor: defaultTheme.grey[50],
                    },
                    _disabled: {
                        backgroundColor: defaultTheme.white.ff,
                        color: defaultTheme.grey[350],
                        border: `1px solid ${defaultTheme.grey[350]}`,
                        opacity: 0.5,
                    },
                },
            },
            toggle: {
                _wrapper: {
                    marginLeft: '10px',
                    marginRight: '-15px',
                },
                _text: {
                    fontSize: '15px',
                    color: defaultTheme.blue[950],
                },
                activeColor: defaultTheme.blue[600],
                inactiveColor: defaultTheme.grey[250],
                width: '23px',
                height: '25px',
                button: {
                    activeColor: defaultTheme.white.ff,
                    inactiveColor: defaultTheme.white.ff,
                    boxShadow: '3px 3px 3px -3px rgba(0,0,0,0.5)',
                    width: '21x',
                    height: '21px',
                },
            },
        },
        sections: {
            wrapper: {
                _header: {
                    backgroundColor: 'transparent',
                    color: defaultTheme.blue[950],
                },
                _body: {
                    width: '343px',
                    height: '400px',
                    border: 'none',
                    boxShadow: 'none',
                },
            },
            additionalFiltersLastLoginSection: {
                _wrapper: {
                    width: '490px',
                    height: '173.5px',
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 6px',
                    opacity: '1',
                    marginTop: '35px',
                    color: defaultTheme.blue[950],
                },
                datePicker: {
                    input: {
                        _wrapper: {
                            width: '195px',
                            border: `1px solid ${defaultTheme.blue[950]}`,
                        },
                        _inputField: {
                            fontSize: '13px',
                            color: defaultTheme.blue[950],
                        },
                        _icon: {
                            color: defaultTheme.blue[950],
                            background: defaultTheme.white.ff,
                            padding: '0',
                            marginRight: '15px',
                        },
                    },
                    calendar: {
                        _wrapper: {
                            border: `1px solid ${defaultTheme.blue[950]}`,
                            color: defaultTheme.blue[950],
                            borderRadius: '2px',
                        },
                        triangleColor: defaultTheme.blue[950],
                        header: {
                            _wrapper: {
                                backgroundColor: defaultTheme.white.ff,
                                border: 'none',
                                marginTop: '8px',
                            },
                            _headerText: {
                                color: defaultTheme.blue[950],
                                fontFamily: 'Source Sans Pro, Open Sans',
                                fontSize: '15px',
                            },
                            navIconColor: defaultTheme.blue[950],
                            navIconHoverColor: defaultTheme.blue[400],
                            topPosition: '8px',
                        },
                        day: {
                            _default: {
                                color: defaultTheme.blue[950],
                                backgroundColor: defaultTheme.white.ff,
                                fontFamily: 'Source Sans Pro, Open Sans',
                                fontSize: '15px',
                            },
                            _hover: {
                                backgroundColor: defaultTheme.grey[50],
                            },
                            _selected: {
                                color: defaultTheme.white.ff,
                                backgroundColor: defaultTheme.blue[600],
                            },
                            _selectedHover: {
                                backgroundColor: defaultTheme.blue[400],
                            },
                            _disabled: {
                                color: defaultTheme.grey[300],
                            },
                        },
                        timeInput: {
                            _wrapper: {},
                            _colon: {},
                            select: {
                                border: `1px solid ${defaultTheme.blue[950]}`,
                                borderHoverColor: defaultTheme.blue[950],
                                backgroundColor: defaultTheme.grey[50],
                                color: defaultTheme.blue[950],
                                borderRadius: '2px',
                                fontSize: '12px',
                                fontFamily: 'Source Sans Pro, Open Sans',
                            },
                            option: {
                                border: 'none',
                                fontSize: '12px',
                                color: defaultTheme.blue[950],
                                backgroundColor: defaultTheme.white.ff,
                                hoverBackgroundColor: defaultTheme.grey[50],
                                selectedColor: defaultTheme.white.ff,
                                selectedBackgroundColor: defaultTheme.blue[600],
                                selectedHoverColor: defaultTheme.white.ff,
                                selectedHoverBackgroundColor: defaultTheme.blue[400],
                            },
                            slider: {
                                trackYWidth: 10,
                                trackYColor: defaultTheme.grey[30],
                                thumbYColor: defaultTheme.blue[950],
                                borderRadius: '0px',
                            },
                        },
                        _resetButton: {
                            backgroundColor: defaultTheme.grey[50],
                            border: 'none',
                        },
                    },
                },
            },
        },
    },
    adminPage: {
        button: {
            _default: {
                minWidth: '145px',
                height: '40px',
                borderRadius: '1px',
                color: defaultTheme.blue[950],
                background: defaultTheme.white.ff,
                border: `1px solid ${defaultTheme.blue[950]}`,
                marginRight: '10px',
                fontSize: '14px',
            },
            _hover: {
                background: defaultTheme.grey[50],
            },
        },
        additionalFiltersButton: {
            _default: {
                minWidth: '170px',
                background: defaultTheme.blue[600],
                color: defaultTheme.white.ff,
            },
            _hover: {
                background: defaultTheme.blue[400],
            },
        },
        createUserButton: { _default: {}, _hover: {} },
        deleteUserButton: { _default: {}, _hover: {} },
        sendInviteButton: {
            _default: {
                marginRight: '0px',
            },
            _hover: {},
        },
        exportButton: { _default: {}, _hover: {} },
        resetFiltersButton: {
            _default: {
                color: defaultTheme.blue[950],
                background: defaultTheme.white.ff,
                border: `1px solid ${defaultTheme.blue[950]}`,
            },
            _hover: {
                background: defaultTheme.grey[50],
            },
        },
        letters: {
            activeColor: '#FFF',
            inactiveColor: defaultTheme.blue[950],
            activeBgColor: defaultTheme.blue[600],
            inactiveBgColor: 'white',
            border: `1px solid ${defaultTheme.blue[950]}`,
            borderRadius: '2px',
        },
    },
    smallModal: {
        _footer: {
            justifyContent: 'space-around',
            borderTop: 'none',
            padding: '0 0 25px',
        },
        header: {
            _wrapper: {
                backgroundColor: defaultTheme.white.ff,
                border: 'none',
                justifyContent: 'center',
                alignItems: 'end',
                padding: '25px 50px 0',
            },
            _title: {
                color: defaultTheme.blue[950],
                fontSize: '22px',
                fontWeight: '600',
                fontFamily: 'Source Sans Pro, Open Sans',
                justifyContent: 'center',
                alignItems: 'center',
            },
            hasCloseButton: true,
        },
        text: {
            _wrapper: {
                height: '150px',
                fontFamily: 'Source Sans Pro, Open Sans',
                fontSize: '20px',
                color: defaultTheme.blue[950],
            },
        },
        buttons: {
            _cancel: {
                color: defaultTheme.blue[950],
                background: 'transparent',
                border: `1px solid ${defaultTheme.blue[950]}`,
                width: '130px',
                height: '50px',
                justifyContent: 'center',
                alignItems: 'center',
            },
            _cancelHover: {
                background: defaultTheme.grey[50],
                color: defaultTheme.blue[950],
                border: `1px solid ${defaultTheme.blue[950]}`,
            },
            _ok: {
                background: defaultTheme.blue[600],
                color: defaultTheme.white.ff,
                border: `1px solid ${defaultTheme.blue[600]}`,
                width: '130px',
                height: '50px',
                justifyContent: 'center',
                alignItems: 'center',
            },
            _okHover: {
                background: defaultTheme.blue[400],
                color: defaultTheme.white.ff,
                border: `1px solid ${defaultTheme.blue[400]}`,
            },
            _close: {
                background: defaultTheme.white.ff,
                position: 'relative',
            },
            _closeHover: {
                background: defaultTheme.grey[50],
            },
        },
    },
};
