import React from 'react';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import SimpleBarChart from './SimpleBarChart';
import * as SC from './SimpleBarChart.styles';
import * as SC_PA from '../../PreferanceAnalysis.styles';
const SimpleBarChartSection = ({ barChartDataYear, barChartDataPeriod, hasNumberOfRespondents }) => {
    const isSourceValidData = source => source && source.length;
    if (!isSourceValidData(barChartDataYear) && !isSourceValidData(barChartDataPeriod))
        return <SC_PA.NoDataText>{TRANSLATION_TEXT.NO_DATA_AVAILABLE_TEXT}</SC_PA.NoDataText>;
    return (
        <>
            <SC.Wrapper>
                {isSourceValidData(barChartDataYear) ? (
                    <SimpleBarChart
                        data={barChartDataYear}
                        title={barChartDataYear[0].title || ''}
                        hasNumberOfRespondents={hasNumberOfRespondents}
                    />
                ) : null}
                {isSourceValidData(barChartDataPeriod) ? (
                    <SimpleBarChart
                        data={barChartDataPeriod}
                        title={barChartDataPeriod[0].title || ''}
                        hasNumberOfRespondents={hasNumberOfRespondents}
                    />
                ) : null}
            </SC.Wrapper>
        </>
    );
};

export default SimpleBarChartSection;
