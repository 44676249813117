import { connect } from 'react-redux';
import CompetitiveMedia from './CompetitiveMedia';
import { getCompetitiveMediaActionCreator } from './data/actions';
import { getCompetitiveMediaDataSelector } from './data/selectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../common/components/TopFilter/data/computedSelectors';
import {
    getFiltersThrownError,
    getPageRelatedToFiltersSelector,
    getTopFiltersRawData,
} from '../../../../common/data/selectors';
import ComputedDataInstance from './data/computedSelectors';

const mapStateToProps = state => ({
    data: ComputedDataInstance(state),
    existingStoreDataUnprocessed: getCompetitiveMediaDataSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    topFiltersRequestSuccessful: getTopFiltersRawData(state).length > 0,
    filtersFor: getPageRelatedToFiltersSelector(state),
});

const mapDispatchToProps = {
    getData: getCompetitiveMediaActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitiveMedia);
