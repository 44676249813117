import React from 'react';
import UserDropdownPanelContainer from '../../../pages/landing/components/UserDropdownPanel/UserDropdownPanel.container';
import { Container, PanelWrapper, Branding, UserMenu } from './Header.styles';
import Navigation from '../Navigation/Navigation';
import { Link } from 'react-router-dom';
import * as routes from '../../../utils/routes';
import { useLocation } from 'react-router';
import { basePath } from '../../../services/utils';

const Header = () => {
    const { pathname } = useLocation();
    const isDashboard = pathname === routes.DASHBOARD_PAGE.path;

    return (
        <>
            {!isDashboard && (
                <Container>
                    <Link to={routes.DASHBOARD_PAGE.path}>
                        <Branding logo={`${basePath}/visuals/AXA_Logo.png`} />
                    </Link>
                    <Navigation />
                    <UserMenu>
                        <PanelWrapper>
                            <UserDropdownPanelContainer />
                        </PanelWrapper>
                    </UserMenu>
                </Container>
            )}
        </>
    );
};
export default Header;
