import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../../../common/hooks/useOnClickOutside';
import { basePath } from '../../../../../../services/utils';
import { TRANSLATION_TEXT } from '../../../../../../utils/translations';
import * as SC from './RankingTable.styles';
import PropTypes from 'prop-types';

const RankingTableDropdown = ({ options, selectedOption, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();
    useOnClickOutside(dropdownRef, () => setIsOpen(false));

    const selectedOptionName = options.find(el => el.key === selectedOption)?.itemLabel;
    const selectedOptionColor = options.find(el => el.key === selectedOption)?.color;
    const openIndicatorArrows = [`${basePath}/visuals/Icon_contry_down.png`, `${basePath}/visuals/Icon_contry_up.png`];

    const onChangeHandler = key => {
        onChange(key);
        setIsOpen(false);
    };

    return (
        <SC.DropdownWrapper data-testid={'RankingTableDropdown-Wrapper'} ref={dropdownRef}>
            <SC.DropdownHeader
                data-testid={'RankingTableDropdown-Header'}
                onClick={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
                legendColor={selectedOptionColor}
            >
                {isOpen ? (
                    <div>{TRANSLATION_TEXT.RANK_CHART_DROPDOWN_DEFAULT}</div>
                ) : (
                    <div data-testid={'RankingTableDropdown-OptionName'}>
                        <span />
                        {selectedOptionName}
                    </div>
                )}
                <img src={openIndicatorArrows[+isOpen]} />
            </SC.DropdownHeader>
            {isOpen && (
                <SC.DropdownMenu data-testid={'RankingTableDropdown-Menu'}>
                    <SC.DropdownItemsWrapper>
                        {options.map(el => (
                            <SC.DropdownItem
                                data-testid={'RankingTableDropdown-Item'}
                                key={el.key}
                                legendColor={el.color}
                                onClick={() => onChangeHandler(el.key)}
                            >
                                <span />
                                {el.itemLabel}
                            </SC.DropdownItem>
                        ))}
                    </SC.DropdownItemsWrapper>
                </SC.DropdownMenu>
            )}
        </SC.DropdownWrapper>
    );
};

const OptionsShape = PropTypes.shape({
    key: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    itemLabel: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
});

RankingTableDropdown.propTypes = {
    options: PropTypes.arrayOf(OptionsShape),
    selectedOption: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default RankingTableDropdown;
