import { connect } from 'react-redux';
import AwarenessTab from './Awareness.tab';
import { getBptPreferenceAnalysisAwarenessActionCreator } from '../data/actions';
import { getBptPreferenceAnalysisAwarenessDataSelector } from '../../../data/selectors';
import {
    getBptPreferenceAnalysisDataComputed,
    getCombinedChartDataSelector,
    getRankingTableDataSelectorComputed,
    getSimpleBarChartDataComputed,
} from '../../../data/computedSelectors';
import { getLOBAsOptionsComputedSelector } from '../../../data/computedSelectors';
import { getPageHasOptionLoadedComputedSelector } from '../../../../../../../common/components/TopFilter/data/computedSelectors';
import { getFiltersThrownError } from '../../../../../../../common/data/selectors';
import { getSelectedFiltersComputedSelector } from './../../../../../../../common/components/SelectedFilters/data/computedSelectors';

const mapStateToProps = state => ({
    awarenessData: getBptPreferenceAnalysisAwarenessDataSelector(state),
    awarenessSectionIDs: getBptPreferenceAnalysisDataComputed(state),
    rankingTableData: getRankingTableDataSelectorComputed(state),
    simpleBarChartData: getSimpleBarChartDataComputed(state),
    lobsAsOptions: getLOBAsOptionsComputedSelector(state),
    combinedChartsData: getCombinedChartDataSelector(state),
    hasTopFilterOptionLoaded: getPageHasOptionLoadedComputedSelector(state),
    filtersThrownError: getFiltersThrownError(state),
    appliedFilters: getSelectedFiltersComputedSelector(state),
});

const mapDispatchToProps = {
    getBptPreferenceAnalysisAwarenessActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AwarenessTab);
