import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FileUploadIcon, BrowseButton, UploadButton, FileUploadWrapper } from '../backOffice.styles';
import { connect } from 'react-redux';
import { getAWSUploadUrl, handleFileUploadToAWS } from '../../../services/apiServer.service';
import SmallLoader from '../../../common/components/Loader/components/SmallLoader';
import { setErrorNotificationDisplayActionCreator } from '../../../errorHandling/data/actions';
import { refreshTokenCall } from '../../../services/login.service';
import { setTokenActionCreator } from '../../login/data/actions';
import { getTokens } from '../../login/data/selector';
import {
    fileUploadProcessEndedctionCreator,
    fileUploadProcessStartedActionCreator,
    reloadTableDataActionCreator,
} from '../data/actions';
import { useOnClickOutside } from '../../../common/hooks/useOnClickOutside';
import ReusableModal from '../../../common/components/ReusableModal/ReusableModal';
import { defaultTheme } from '../../../utils/defaultTheme';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import { getSelectedFileContentType } from '../utils/utils';

export const FileUpload = ({
    tokens,
    requestTableData,
    setToken,
    tableToRequestDataFor,
    importTypeId,
    loggerFunction,
    type,
    iconUrl,
    showErrorNotification,
    customStyle,
    startFileUploadFullLoading,
    endFileUploadFullLoading,
    errorModalCallback,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [uploadUrl, setUploadUrl] = useState(null);
    const [urlLoadPending, setUrlLoadPending] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef();
    const modalRef = useRef();

    useEffect(() => {
        const unloadCallback = event => {
            event.preventDefault();
            return (event.returnValue = TRANSLATION_TEXT.FILE_IN_PROGRESS_TEXT);
        };
        // Only request a closing/refresh/navigation confirmation of the current tab if the file upload is still in progress
        if (urlLoadPending) {
            window.addEventListener('beforeunload', unloadCallback);
        }
        return () => window.removeEventListener('beforeunload', unloadCallback);
    }, [urlLoadPending]);

    const handleModalClose = () => {
        resetComponentData();
        setIsOpen(false);
    };
    const handleModalApply = () => {
        onSubmit();
        resetComponentData();
        setIsOpen(false);
    };

    useOnClickOutside(modalRef, handleModalClose);

    const onBrowseClick = () => {
        fileInputRef.current.click();
    };

    const resetComponentData = () => {
        setSelectedFile(null);
        setUploadUrl(null);
        fileInputRef.current.value = '';
    };

    const showSessionExpiredModal = () => {
        showErrorNotification(true);
        resetComponentData();
        errorModalCallback(TRANSLATION_TEXT.SESSION_EXPIRED_MODAL_BODY_TEXT, true); //2nd param force logout
    };
    const startUploadProcessStep = async operation => {
        try {
            const response = await operation.request();
            operation.resolve(response);

            if (operation.next) {
                await startUploadProcessStep(operation.next);
            }
        } catch (error) {
            if (error) {
                const status = error.hasOwnProperty('response') ? error.response.status : null;

                /* If token is expired attempt to refresh it and try to run the operation again */
                if (status === 401) {
                    try {
                        const newTokens = await refreshTokenCall(tokens.refresh_token);
                        setToken(newTokens.data.json);
                        await startUploadProcessStep(operation);
                    } catch (refreshError) {
                        showSessionExpiredModal();
                    }
                } else {
                    operation.reject();
                }
            } else {
                operation.reject();
            }
        }
    };
    const handlePreloadResponse = response => {
        const { isAnOverwrite, preSignedUrl } = response.data;
        setUploadUrl(preSignedUrl);
        if (isAnOverwrite) {
            setIsOpen(true);
        }
    };

    const tableRequestsHandler = handlersMap => {
        if (handlersMap instanceof Array) {
            handlersMap.forEach(tableRequestHandlersMap => {
                requestTableData(tableRequestHandlersMap);
            });
        } else {
            requestTableData(handlersMap);
        }
    };

    const handleChange = async e => {
        e.preventDefault();

        const fileUploaded = e.target.files[0];
        setSelectedFile(fileUploaded);
        setUrlLoadPending(true);
        startFileUploadFullLoading();
        const operations = {
            request: () => getAWSUploadUrl({ fileName: fileUploaded.name, importTypeId }),
            resolve: response => handlePreloadResponse(response),
            reject: () => {
                showErrorNotification(true);
                resetComponentData();
                errorModalCallback(TRANSLATION_TEXT.ERROR_MESSAGE_UPLOAD_URL);
            },
            next: null,
        };

        await startUploadProcessStep(operations);
        setUrlLoadPending(false);
        endFileUploadFullLoading();
    };
    const onSubmit = async () => {
        if (selectedFile && uploadUrl) {
            const contentType = getSelectedFileContentType(selectedFile);
            const operations = {
                request: () => {
                    startFileUploadFullLoading();
                    setUrlLoadPending(true);
                    return handleFileUploadToAWS({ url: uploadUrl, type: contentType, file: selectedFile });
                },
                resolve: () => {
                    resetComponentData();
                },
                reject: () => {
                    endFileUploadFullLoading();
                    setUrlLoadPending(false);
                    showErrorNotification(true);
                    resetComponentData();
                    errorModalCallback(TRANSLATION_TEXT.ERROR_MESSAGE_UPLOAD_FILE);
                },
                next: {
                    request: () => {
                        return loggerFunction(selectedFile.name);
                    },
                    resolve: () => {
                        setUrlLoadPending(false);
                        endFileUploadFullLoading();
                        tableToRequestDataFor && tableRequestsHandler(tableToRequestDataFor);
                    },
                    reject: () => {
                        setUrlLoadPending(false);
                        endFileUploadFullLoading();
                        showErrorNotification(true);
                    },
                    next: null,
                },
            };
            await startUploadProcessStep(operations);

            fileInputRef.current.value = '';
            resetComponentData();
        }
    };

    const renderModal = () => {
        return (
            <ReusableModal
                modalWrapperRef={modalRef}
                text={TRANSLATION_TEXT.OVERWRITE_BODY_TEXT}
                modalWidth={'600px'}
                overflowText={'auto'}
                titleColor={defaultTheme.blue[950]}
                onApply={() => handleModalApply()}
                onClose={() => handleModalClose()}
                isAbleToDismiss={true}
                yesText={TRANSLATION_TEXT.OVERWRITE_CONFIRM_TEXT}
                cancelText={TRANSLATION_TEXT.CANCEL_TEXT}
                title={TRANSLATION_TEXT.OVERWRITE_TITLE_TEXT}
                customStyle={{ primaryButtonWidth: '120px', marginTopText: '40px' }}
                singleButton={false}
            />
        );
    };
    return (
        <FileUploadWrapper {...customStyle}>
            {isOpen && renderModal()}
            <BrowseButton data-testid="FileUpload-BrowseButton" onClick={onBrowseClick}>
                <FileUploadIcon src={iconUrl} />
                <p>Browse</p>
            </BrowseButton>
            <UploadButton
                data-testid="FileUpload-UploadButton"
                disabled={selectedFile === null || urlLoadPending}
                onClick={onSubmit}
            >
                {urlLoadPending ? <SmallLoader /> : 'Submit'}
            </UploadButton>

            <input
                data-testid="FileUpload-UploadInput"
                type="file"
                accept={type}
                ref={fileInputRef}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </FileUploadWrapper>
    );
};

const mapStateToProps = state => ({
    tokens: getTokens(state),
});
const mapDispatchToProps = {
    showErrorNotification: setErrorNotificationDisplayActionCreator,
    setToken: setTokenActionCreator,
    requestTableData: reloadTableDataActionCreator,
    startFileUploadFullLoading: fileUploadProcessStartedActionCreator,
    endFileUploadFullLoading: fileUploadProcessEndedctionCreator,
};

const tableDataShapeValidator = (propValue, key, componentName, _, propFullName) => {
    let hasError;
    Object.keys(propValue[key]).forEach(el => {
        if (!/KEY|REQUEST|ASYNC_ACTION/.test(el)) {
            hasError = new Error(
                'Invalid prop `' + propFullName + '` supplied for' + ' `' + componentName + '`. Validation failed.'
            );
        }
    });
    return hasError;
};

FileUpload.propTypes = {
    tokens: PropTypes.object.isRequired,
    requestTableData: PropTypes.func,
    setToken: PropTypes.func,
    tableToRequestDataFor: PropTypes.oneOfType([
        PropTypes.shape({
            KEY: PropTypes.string.isRequired,
            REQUEST: PropTypes.func.isRequired,
            ASYNC_ACTION: PropTypes.object.isRequired,
        }),
        PropTypes.arrayOf(tableDataShapeValidator),
    ]),
    importTypeId: PropTypes.number,
    loggerFunction: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    showErrorNotification: PropTypes.func,
    customStyle: PropTypes.object,
    startFileUploadFullLoading: PropTypes.func,
    endFileUploadFullLoading: PropTypes.func,
    errorModalCallback: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
