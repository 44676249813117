import React, { useMemo, useEffect, useRef, useState } from 'react';
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from '@toluna-ui-toolkit/barchart';
import { defaultTheme } from '../../../../../../utils/defaultTheme';
import {
    AwarenessChartTitle,
    AwarenessChartTitleLegend,
    AwarenessChartWrapper,
    AwarenessTitleWrapper,
    BarChartWrapper,
} from './SimpleBarChart.styles';
import SigTestFlag from './SigTestFlag';
import { useWindowSize } from '../../../../../../common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import { calculateWidthAwarenessTabSection } from '../../../../../../utils/responsiveness';
import MetricBadge from './MetricBadge';
import NumberRespondentsTag from './NumberRespondentsTag';

const HEIGHT = 100;
const MARGIN_HEIGHT = 50;
const SimpleBarChart = ({ data, title, hasNumberOfRespondents }) => {
    const [barCoords, setBarCoords] = useState([]);
    const barChartRef = useRef();
    const [width] = useWindowSize();

    const chartTranslate = () => {
        switch (data.length) {
            case 1:
                return BAR_CHARTS_WIDTH / 2 / 1.85;
            case 2:
                return BAR_CHARTS_WIDTH / 2 / 3.75;
            case 3:
                return BAR_CHARTS_WIDTH / 2 / 7;
            case 4:
                return BAR_CHARTS_WIDTH / 2 / 10;
            case 5:
                return BAR_CHARTS_WIDTH / 2 / 20;
            default:
                return 0;
        }
    };

    const barChartBarWidth = () => {
        switch (data.length) {
            case 1:
            case 2:
            case 3:
                return 36;
            case 4:
                return 36;
            case 5:
            case 6:
                return 33;
            case 7:
            case 8:
                return 30;
            default:
                return 33;
        }
    };

    const barChartLabelMargin = () => {
        //On DEV & QA we have two decimals, so we need a little extra offset to center the values
        const areAllInt = data.every(values => values.value % 1 === 0);
        switch (data.length) {
            case 1:
            case 2:
            case 3:
                return areAllInt ? -3 : -9;
            case 4:
            case 5:
            case 6:
                return areAllInt ? -4 : -7;
            default:
                return areAllInt ? -5 : -9;
        }
    };

    const BAR_CHARTS_WIDTH = useMemo(() => {
        return calculateWidthAwarenessTabSection(width);
    }, [width]);

    useEffect(() => {
        if (barChartRef && barChartRef.current) {
            const calculateSigtestPositioning = () => {
                let parrentChildNodes = barChartRef.current?.childNodes; //childrens of the ref
                let barChartSVG;
                parrentChildNodes.forEach(el => {
                    if (el.nodeName === 'DIV') {
                        barChartSVG = el.firstChild;
                        setBarCoords([]); //cleanup of previous coords.
                    }
                });
                let groupElements = barChartSVG?.getElementsByTagName('rect');
                [].forEach.call(groupElements, (el, idx) => {
                    setBarCoords(prevState => {
                        return [...prevState, { id: idx, coords: el.getBBox() }];
                    });
                });
            };
            calculateSigtestPositioning();
        }
    }, [barChartRef, data, width]);

    return (
        <AwarenessChartWrapper>
            <AwarenessTitleWrapper>
                <AwarenessChartTitleLegend />
                <AwarenessChartTitle> {title} </AwarenessChartTitle>
            </AwarenessTitleWrapper>
            <BarChartWrapper ref={barChartRef}>
                <BarChart
                    key={BAR_CHARTS_WIDTH} //Triggers unmounting then re-mounting of the component when width changes
                    width={BAR_CHARTS_WIDTH}
                    height={HEIGHT}
                    marginHeight={MARGIN_HEIGHT}
                    data={data}
                    domain={100}
                    direction={BARCHART_DIRECTION.VERTICAL}
                    labelPosition={BARCHART_LABEL_POSITION.END}
                    withNames={true}
                    alwaysOnTooltips={true}
                    tooltipFormatter={e => e.value + '%'}
                    labelFormatter={e => e + '%'}
                    translateY={15}
                    translateX={chartTranslate()}
                    styling={{
                        barWidth: barChartBarWidth(),
                        barHeight: 100,
                        barFontSize: 12,
                        barLabelColor: defaultTheme.blue[950],
                        barLabelSecondaryAxisOffset: barChartLabelMargin(),
                        barLabelMainAxisOffset: -30,
                        barNamesMainAxisOffset: 15,
                        barNamesSecondaryAxisOffset: chartTranslate(),
                        barNamesFontSize: 12,
                        barNamesColor: defaultTheme.blue[950],
                    }}
                />
            </BarChartWrapper>
            <SigTestFlag
                sigTestCoords={barCoords}
                data={data}
                totalBarChartHeight={HEIGHT + MARGIN_HEIGHT}
                barTranslate={chartTranslate()}
            />
            <MetricBadge
                data={data}
                barCoords={barCoords}
                totalBarChartHeight={HEIGHT + MARGIN_HEIGHT}
                barTranslate={chartTranslate()}
            />
            {hasNumberOfRespondents && (
                <NumberRespondentsTag data={data} barCoords={barCoords} barTranslate={chartTranslate()} />
            )}
        </AwarenessChartWrapper>
    );
};

SimpleBarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            name: PropTypes.string,
            color: PropTypes.string,
            sigtest: PropTypes.number,
            order: PropTypes.number,
            tooltip: PropTypes.string,
        })
    ),
    title: PropTypes.string,
    hasNumberOfRespondents: PropTypes.bool,
};

SimpleBarChart.defaultProps = {
    data: [],
    hasNumberOfRespondents: false,
};

export default SimpleBarChart;
