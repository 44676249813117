import axios from 'axios';
import InputProcessingService from './inputDataProcessing.service';
import { createAxiosInstance } from '../../../../services/utils';
import OutputProcessingService from './outputDataProcessing.service';

let axiosInstance = null;
const IS_TOKEN_ENABLED = true;
if (IS_TOKEN_ENABLED) {
    axiosInstance = createAxiosInstance(IS_TOKEN_ENABLED);
} else {
    axiosInstance = axios.create();
}

export const apiBindings = {
    getAllUsers: UserFilter => {
        const processedFilters = OutputProcessingService.processUserListParameters(UserFilter);
        return axiosInstance.post(`User/GetAllUsers`, processedFilters).then(result => {
            const processedData = InputProcessingService.processUserListData(result.data);
            return { data: processedData };
        });
    },
    getUserDetails: userId => {
        return axiosInstance.post(`User/GetUserDetails`, userId).then(result => {
            const processedData = InputProcessingService.processUserData(result.data);
            return { data: processedData };
        });
    },
    createUser: user => {
        const data = OutputProcessingService.processCreateUserParameters(user);
        return axiosInstance.post('User/AddUser', data);
    },
    getRoles: () => {
        return axiosInstance.post('User/GetPermissions');
    },
    deleteUser: userIds => {
        return axiosInstance.post(`User/DeleteUser`, { iDs: userIds });
    },
    updateUser: userDetails => {
        const data = OutputProcessingService.processUpdateUserParameters(userDetails);
        return axiosInstance.post(`User/UpdateUser`, data);
    },
    sendInvitations: UserID => {
        return axiosInstance.post(`User/SendInvite`, { iDs: UserID });
    },
    createAndSendInvitation: user => {
        const data = OutputProcessingService.processCreateUserParameters(user);
        return axiosInstance.post('User/AddUserAndSendInvite', data);
    },
    resetPassword: userId => {
        return axiosInstance.post(`User/SendResetPasswordEmail`, userId);
    },
};
