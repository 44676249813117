import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyledFileUploadInput } from './FileUpload.styles';
import { DISPLAY_STYLE, BUTTON_STYLE } from './constants';
import { InputFileUpload } from './components/InputFileUpload';
import { ButtonFileUpload } from './components/ButtonFileUpload';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';



export const FileUpload = React.forwardRef((props, ref) => {
  const {file,onChange,displayStyle, ...rest} = props;
  const [fileSelected, setFileSelected] = useState(file?file:'');

  const fileInputRef = ref? ref : useRef(null);
  
  const generalOnChange = (e) => {
    const filesArray = e.target.files;

    let outputText = filesArray.length === 0 ? fileSelected : (filesArray.length > 1 ? filesArray.length + ' Files uploaded' : filesArray[0].name);
    setFileSelected(outputText);
    onChange && onChange(e);
  };

  const inputChangeHandler = (e) => {
    setFileSelected(e.target.value);
  };

  return (
      <ThemeConsumer>
         {value => {
              const fileUploadProps = {
                    theme: value,
                    ...rest
              };
              
              switch (displayStyle) {
                case DISPLAY_STYLE.INPUT:
                    return(
                      <div >
                         <InputFileUpload fileInputRef={fileInputRef} {...fileUploadProps} file={fileSelected} inputChangeHandler={inputChangeHandler}/>
                         <StyledFileUploadInput ref={fileInputRef} type="file" {...fileUploadProps} onChange={generalOnChange} />
                      </div>
                    );

                case DISPLAY_STYLE.BUTTON:
                    return(
                      <div>
                        <ButtonFileUpload fileInputRef={fileInputRef} {...fileUploadProps} file={fileSelected} inputChangeHandler={inputChangeHandler}/>
                        <StyledFileUploadInput ref={fileInputRef} type="file" {...fileUploadProps} onChange={generalOnChange} />
                      </div>
                    );

                default:
                    return(
                      <div >
                        <InputFileUpload fileInputRef={fileInputRef} {...fileUploadProps} file={fileSelected} inputChangeHandler={inputChangeHandler}/>
                        <StyledFileUploadInput  ref={fileInputRef} type="file" {...fileUploadProps} onChange={generalOnChange} />
                      </div>
                    );
                }
            }
          }
      </ThemeConsumer>
  )
});

FileUpload.propTypes = {
  /** Width property for the Wrapper. Valid value for FileUpload with displayType = "input" */
  width : PropTypes.string,
  /** Possible values: input, button */
  displayStyle: PropTypes.string,
  /** Name */
  name: PropTypes.string,
  /** ID */
  id: PropTypes.string,
  /** Placeholder */
  placeholder: PropTypes.string,
  /** Multiple */
  multiple: PropTypes.bool,
  /** Accept */
  accept: PropTypes.string,
  /** Required */
  required: PropTypes.string,
  /**File name*/
  file: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
  /** Label for Browse button */
  browseText: PropTypes.string,
  /** Determines button layout. Possible values: circled, oval */
  buttonStyle: PropTypes.string,
  /** Fontawesome as 'font-awesome' or Toluna font icon as 'toluna' */
  iconType: PropTypes.string,
  /** Icon name for both Fontawesome or Toluna font icon */
  iconName: PropTypes.string,
  /** Adjust input field style (check InputField customStyle format) */
  inputFieldCustomStyle: PropTypes.object,
  /** Adjust input field "Browse" label style */
  inputFieldLabelCustomStyle: PropTypes.object,
  /** Adjust button style */
  buttonCustomStyle: PropTypes.object
};

FileUpload.defaultProps = {
  width : '100%',
  multiple: false,
  displayStyle: DISPLAY_STYLE.INPUT,
  browseText: 'Browse',
  buttonStyle: BUTTON_STYLE.RECTANGLE
};
