// eslint-disable-next-line no-unused-vars
import React from 'react';
import { connect } from 'react-redux';
import { isMaintenance, isUserAllowedAccess } from '../../../utils/generalUtilities';
import { getTokens } from '../data/selector';
import { getUserDetailsSelector } from '../data/selectors';

export const LoginStatusProvider = props => {
    const { children, isLoggedIn, currentUser } = props;

    const isAllowedUser = isUserAllowedAccess(currentUser?.role || []) && currentUser?.userName === 'Harris';

    const childProps = {
        isLoggedIn,
        isUserAllowedOnMaintenance: isMaintenance ? isAllowedUser : true,
    };

    return children(childProps);
};

const mapStateToProps = state => ({
    isLoggedIn: Object.keys(getTokens(state)).length > 0,
    currentUser: getUserDetailsSelector(state),
});

export default connect(mapStateToProps, null)(LoginStatusProvider);
