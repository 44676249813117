import styled, { css } from 'styled-components';
import { PrimaryButton } from '../../SideFilters/components/SideFilters.styles';
import { DISPLAY_BUTTON_POSITION, TOP_FILTER_CARD_SIZE } from '../utils/constants';

/* Filters Group Styling */
export const TopFiltersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: ${({ size }) => (size === TOP_FILTER_CARD_SIZE.SMALL ? '0px' : '80px')};
    padding: ${({ size }) => (size === TOP_FILTER_CARD_SIZE.SMALL ? '0 45px 0px 45px' : '0 45px 50px 45px')};
`;

/* Top Filter Card */
export const FilterCardWrapper = styled.div`
    width: 297px;
    height: ${props => (props.size === TOP_FILTER_CARD_SIZE.LARGE ? '290px' : '178px')};
    border-radius: 2px;

    margin: 0px 10px 0px 10px;

    background: ${({ theme }) => theme.white.ff} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    box-sizing: border-box;
`;

export const TopFilterInputWrapper = styled.div`
    box-sizing: border-box;
    padding: ${props => (props.padding ? props.padding : '0')};
    border-bottom: ${props => (props.hasBorder ? `2px solid ${props.theme.grey[200]}` : 'none')};
    align-items: center;
    width: 100%;
    height: ${props => (props.size === TOP_FILTER_CARD_SIZE.LARGE ? '22%' : '64px')};
    display: flex;
    flex-direction: row;

    div,
    input {
        background-color: ${props => (props.isActive ? props.bgColor : `${props.theme.grey[50]}`)};
        height: 40px;
        transition: background-color 0.2s;
    }

    &:hover {
        div,
        input {
            background-color: ${props => props.bgColor};
        }
    }
`;

export const TopFilterInput = styled.input`
    width: 80%;
    border: none;
    padding: ${props => props.textPadding};
    box-sizing: border-box;
    font-size: ${props => props.fontSize};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
    color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};

    &:focus {
        outline: none;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
        opacity: ${props => (props.opacity ? props.opacity : '1')}; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
    }
`;

export const TopFilterSearchIcon = styled.div`
    width: 20%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TransparentBottomGradient = styled.div`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 10px;
        bottom: -3px;
        background-image: linear-gradient(to top, rgb(251 251 251), rgba(255, 255, 255, 0));
    }
`;

export const TopFilterHeaderText = styled.div`
    box-sizing: border-box;
    padding: ${props => (props.padding ? props.padding : '0')};
    border-bottom: 2px solid ${props => props.theme.grey[200]};
    width: 100%;
    height: ${props => (props.size === TOP_FILTER_CARD_SIZE.LARGE ? '22%' : '64px')};
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.blue[900]};
    padding: 0 23px;
`;

export const TopFilterOptionsWrapper = styled.div`
    height: ${props => (props.size === TOP_FILTER_CARD_SIZE.LARGE ? '75%' : '63%')};
    padding: 8px;
    box-sizing: border-box;
    overflow-y: auto;
`;
export const DisplayButtonWrapper = styled.div`
    position: relative;
`;
export const DisplayButtonAdditionalInfo = styled.div`
    position: absolute;
    right: 0;
    top: 80px;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.blue[900]};
`;
export const DisplayButton = styled.div`
    ${({ position }) =>
        position === DISPLAY_BUTTON_POSITION.RIGHT
            ? css`
                  position: absolute;
                  bottom: -2px;
                  right: -120px;
                  ${PrimaryButton} {
                      padding: 9px;
                  }
              `
            : css`
                  margin-top: 25px;
                  display: flex;
                  align-self: flex-end;
                  justify-self: flex-end;
                  float: right;
              `}
`;

/* Filtered data */

export const FilteredDataWrapper = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    font-size: 14px;
    width: 180px;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    img {
        vertical-align: top;
        margin-right: 5px;
        transition: all ease-in 300ms;
        &:hover {
            transform: scale(1.03);
        }
    }
    span {
        background-color: ${({ theme }) => theme.orange[700]};
        width: 42px;
        height: 19px;
        margin-right: 5px;
        display: inline-block;
    }
    ${props => props.customStyle};
`;
