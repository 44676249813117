import styled from 'styled-components';

const Wrapper = styled.div``;

const LOBAreaWrapper = styled.div`
    min-width: 100%;
    max-width: 942px;
    width: 54%;
`;
const CombinedChartsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
`;
const NoDataText = styled.p`
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 700;
    padding: 0;
    margin-bottom: 50px;
`;

const InfoPic = styled.div`
    margin-left: 5px;
    cursor: pointer;
    img {
        width: 20px;
        object-fit: contain;
    }
`;
export { Wrapper, LOBAreaWrapper, CombinedChartsWrapper, NoDataText, InfoPic };
