import React from 'react';
import PropTypes from 'prop-types';
import { HeaderTooltipCellWrapper, HeaderTooltipWrapper, TooltipHover } from './HeaderTooltip.styles';

const HeaderTooltip = ({ tooltip, children }) => {
    return (
        <HeaderTooltipCellWrapper>
            <span>{children}</span>
            <TooltipHover>(?)</TooltipHover>
            <HeaderTooltipWrapper>{tooltip}</HeaderTooltipWrapper>
        </HeaderTooltipCellWrapper>
    );
};

HeaderTooltip.propTypes = {
    tooltip: PropTypes.string.isRequired,
    children: PropTypes.string,
};

export default HeaderTooltip;
