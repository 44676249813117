import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';
// import Creatable from 'react-select/lib/Creatable';
import { customComponentsStyles } from './CustomStyles';
import {
    customMenu,
    customOption,
    customStyleDropdownIndicator,
    customDropdownIndicator,
    customValueContainer,
    customFakeSingleValue,
    customInput,
} from './customComponents';

export const SelectBox = props => {
    const selectRef = useRef(null);
    const scrollBarRef = useRef(null);
    const {
        options,
        onChange,
        value,
        isMulti,
        closeMenuOnSelect,
        hideSelectedOptions,
        disabled,
        withLinks,
        withCheckboxes,
        hasError,
        topContent,
        placeholder,
        selectedCount,
        customStyle,
        isOpen,
        customIndicator,
        scrollProps,
        matchFromStart,
        filterConfig,
        formatGroupLabel,
        texts,
        isClearable,
        optionFocusedAtStart,
        isSearchable,
        menuPlacement,
        searchInputProps,
    } = props;

    const [currentValue, setCurrentValue] = useState(value || (!isMulti && optionFocusedAtStart));
    useEffect(() => {
        if (!value && !!optionFocusedAtStart) {
            setCurrentValue(optionFocusedAtStart);
            setCurrentValueIsFake(true);
        } else {
            setCurrentValue(value);
            setCurrentValueIsFake(false);
        }
    }, [value, optionFocusedAtStart]);
    // current value is fake when we take the option focused at start as the current value
    const [currentValueIsFake, setCurrentValueIsFake] = useState(!isMulti && !value && !!optionFocusedAtStart);

    const customStylesProps = {
        hasError,
        customStyle,
        withCheckboxes,
        currentValueIsFake,
    };

    const translations = {
        noOptions: 'No options',
        selected: 'selected',
        all: 'all',
        view: 'View',
        ...texts,
    };

    const getCustomComponents = themeValue => {
        const customComponents = {
            MenuList: rsProps => customMenu(rsProps, scrollBarRef, { topContent, scrollProps }),
            Option: rsProps =>
                customOption(rsProps, {
                    withLinks,
                    withCheckboxes,
                    checkboxCustomStyles:
                        customStyle && customStyle.option && customStyle.option.checkbox
                            ? customStyle.option.checkbox
                            : {},
                    translations,
                    themeValue,
                }),
            DropdownIndicator: rsProps => customStyleDropdownIndicator(rsProps, {}),
            Input: rsProps => customInput(rsProps, searchInputProps),
        };

        if (customIndicator) {
            customComponents.DropdownIndicator = rsProps => customDropdownIndicator(rsProps, { customIndicator });
        }

        if (currentValueIsFake) {
            customComponents.SingleValue = rsProps => customFakeSingleValue(rsProps);
        }

        if (isMulti && selectedCount) {
            // solution for menu is not close on multi selection, we use MultiValue instead fo ValueContainer so we render only the first item
            customComponents.MultiValue = rsProps => customValueContainer(rsProps, { customStyle, translations });
        }

        return customComponents;
    };

    const handleKeyDown = () => {
        setTimeout(() => {
            checkFocusedOptionPosition();
        }, 0);
    };

    const handleOpenMenu = () => {
        setTimeout(() => {
            checkFocusedOptionPosition();
        }, 0);
    };

    const checkFocusedOptionPosition = event => {
        // console.log(selectRef);
        // Fix for createable - the select have another level of pros -
        // const focusedOptionRef = userCanCreateOption ? selectRef.current.select.select.focusedOptionRef : selectRef.current.select.focusedOptionRef;
        const focusedOptionRef = selectRef.current.select.focusedOptionRef;
        if (focusedOptionRef !== null) {
            const focusedStart = focusedOptionRef.getBoundingClientRect().top;
            const focusedEnd = focusedStart + focusedOptionRef.getBoundingClientRect().height;

            const scrollEl = scrollBarRef.current;
            const scrollElStart = scrollEl.wrapperElement.getBoundingClientRect().top;
            const scrollElEnd = scrollElStart + scrollEl.clientHeight;

            if (focusedStart < scrollElStart) {
                // console.log("Should scroll top");
                // scrollEl.scrollTop -= scrollElStart - focusedStart;
                scrollEl.scrollTo(0, scrollEl.scrollTop - (scrollElStart - focusedStart));
            } else if (focusedEnd > scrollElEnd) {
                // scrollEl.scrollTo(
                //   0,
                //   scrollEl.scrollTop + (focusedEnd - scrollElEnd)
                // );
                // console.log("Should scroll bottom");
                scrollEl.scrollTop += focusedEnd - scrollElEnd;
            }
        }
    };

    const getFilterOption = () => {
        if (filterConfig) {
            return filterConfig;
        }
        if (matchFromStart) {
            return {
                matchFrom: 'start',
            };
        }
        return {
            matchFrom: 'any',
        };
    };

    const onChangeMulti = (newValue, action) => {
        const scrollPosBeforeClick = scrollBarRef && scrollBarRef.current && scrollBarRef.current.scrollTop;
        onChange && onChange(newValue, action);
        if (scrollPosBeforeClick > 0) {
            setTimeout(() => {
                if (scrollBarRef && scrollBarRef.current) {
                    scrollBarRef.current.scrollTop = scrollPosBeforeClick;
                }
            }, 0);
        }
    };

    const getSelectProps = themeValue => {
        return {
            ref: selectRef,
            options,
            onChange: (newValue, action) => {
                setCurrentValueIsFake(false);
                setCurrentValue(newValue);
                if (isMulti) {
                    onChangeMulti(newValue, action);
                } else {
                    onChange && onChange(newValue);
                }
            },
            value: currentValue,
            isDisabled: disabled,
            isMulti,
            closeMenuOnSelect,
            hideSelectedOptions: hideSelectedOptions || false,
            menuIsOpen: isOpen,
            // if closeMenuOnSelect is false (usually on multiple choice) then we need to set blurInputOnSelect to false for mobile mode
            // source: https://github.com/JedWatson/react-select/issues/2771
            blurInputOnSelect: closeMenuOnSelect || false,
            placeholder,
            filterOption: createFilter(getFilterOption()),
            // menuIsOpen:true,
            isClearable,
            isSearchable,
            styles: customComponentsStyles(themeValue, customStylesProps),
            components: getCustomComponents(themeValue),
            formatGroupLabel,
            noOptionsMessage: () => translations.noOptions,
            menuPlacement,
        };
    };

    return (
        <ThemeConsumer>
            {themeValues => (
                <Select {...getSelectProps(themeValues)} onKeyDown={handleKeyDown} onMenuOpen={handleOpenMenu} />
            )}
            {/* {userCanCreateOption ?
            <Creatable {...selectProps} onKeyDown={handleKeyDown} onMenuOpen={handleOpenMenu} onCreateOption={onCreateOption}/>
            :
            <Select {...selectProps} onKeyDown={handleKeyDown} onMenuOpen={handleOpenMenu}/>
            } */}
        </ThemeConsumer>
    );
};

SelectBox.propTypes = {
    /** Custom style */
    customStyle: PropTypes.object,
    /** select options */
    options: PropTypes.array.isRequired,
    /** onChange handler*/
    onChange: PropTypes.func,
    /** Select options values array*/
    value: PropTypes.object,
    /** Enable multi select SelectBox*/
    isMulti: PropTypes.bool,
    /** Closes SelectBox upon choosing option*/
    closeMenuOnSelect: PropTypes.bool,
    /** Hides current selected value from options*/
    hideSelectedOptions: PropTypes.bool,
    /** Disables SelectBox*/
    disabled: PropTypes.bool,
    /** Enables links in options list*/
    withLinks: PropTypes.bool,
    /** Adds checkboxes to isMulti*/
    withCheckboxes: PropTypes.bool,
    /** Error indicator*/
    hasError: PropTypes.bool,
    /** Top content inside SelectBox, before options list*/
    topContent: PropTypes.func,
    /** Placeholder text*/
    placeholder: PropTypes.string,
    /** Displays amount of selected options for isMulti SelectBox*/
    selectedCount: PropTypes.bool,
    /** Displays options list*/
    isOpen: PropTypes.bool,
    /** Adds custom HTML indicator*/
    customInedcator: PropTypes.object,
    /** Adds scroll props to isOpen*/
    scrollProps: PropTypes.object,
    /** Sets options menu opening direction, one of auto, bottom, top*/
    menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
    /** Allows the user to set search input props*/
    searchInputProps: PropTypes.object,
};

SelectBox.defaultProps = {
    disabled: false,
    withLinks: false,
    withCheckboxes: false,
    hasError: false,
    isMulti: false,
    selectedCount: false,
    hideSelectedOptions: false,
    closeMenuOnSelect: true,
    menuPlacement: 'auto',
    placeholder: 'Select...',
    options: [],
};
