import React, { useRef, useEffect, useMemo } from 'react';
import * as SC from './CombinedBarLineChart.style';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TRANSLATION_TEXT } from '../../../utils/translations';
import { defaultTheme } from '../../../utils/defaultTheme';
import {
    calculateWidthAwarenessTabSection,
    calculateWidthLargeLineChartAdRecall,
    calculateWidthLargeLineChartImage,
    calculateWidthLargeLineChartAdComparison,
} from '../../../utils/responsiveness';
import {
    CUSTOM_LINECHART_WIDTH_PAGE_FLAG,
    METRICS_VALUE_COLORS_MAP,
    numFormatterExceptZero,
} from '../../../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/tabs/utils/utils';
import { createIdFromGroup } from '../../../utils/generalUtilities';
import { createClassName } from '../../utils/utils';

const DEFAULT_HEIGHT = 288;
const SIG_TEST_WIDTH = 160;
const PADDING = {
    TOP: 34,
    LEFT: 40,
    RIGHT: 15,
    BOTTOM: 34,
};
const DIMENSIONS = {
    TOOLTIP: {
        width: 36,
        height: 23,
    },
    METRIC_BADGE: {
        width: 40,
        height: 21,
    },
    METRIC_BADGE_DOT: {
        width: 12,
        height: 12,
    },
};
const ELLIPSIS_SHOW_LENGTH = 20;
const AXIS_PADDING = 30;
const METRIC_OFFSET = 25;
const MIN_BAR_WIDTH_METRIC_BADGE = 14;
/*
    Combined Chart supports showing line data only, in this case supply an empty array to the barData prop
 */

const CombinedBarLineChart = ({
    lineData,
    barData,
    visibleLOBs,
    customDomain,
    customBarPadding,
    bypassLOBFiltering,
    customWidthOnPage,
    customGridStyle,
    customLineTooltip,
    customBarTooltip,
    customHeight,
    alwaysOnLineTooltip,
    lineScalingOn,
    showSigTest,
    showDiffs,
    showBrandName,
    fluidWidth,
    foreignObjects,
    hideGridLines,
    titleObject,
    leftLabel,
    bottomLabel,
    hideLeftLabel,
    barLabelFormatter,
    hideBarLabel,
    hideTitleOnSigTestHover,
    leftAxisLabelFormatter,
    numberOfRespondents,
}) => {
    const [width] = useWindowSize();

    const WIDTH = useMemo(() => {
        if (typeof customWidthOnPage === 'function') return customWidthOnPage(width);
        switch (customWidthOnPage) {
            case CUSTOM_LINECHART_WIDTH_PAGE_FLAG.ADRECALL:
                return calculateWidthLargeLineChartAdRecall(width, fluidWidth);
            case CUSTOM_LINECHART_WIDTH_PAGE_FLAG.IMAGE:
                return calculateWidthLargeLineChartImage(width);
            case CUSTOM_LINECHART_WIDTH_PAGE_FLAG.ADCOMPARISON:
                return calculateWidthLargeLineChartAdComparison(width);
            default:
                return calculateWidthAwarenessTabSection(width);
        }
    }, [width]);

    const xAxisFontSizeValues = numberOfChartColumns => {
        switch (numberOfChartColumns) {
            case 8:
            case 7:
                return 10;
            case 6:
            case 5:
                return 11;
            default:
                return 12;
        }
    };

    const xAxisResponsiveSize = () => {
        if (barData && barData.length > 0) {
            const numberOfBars = barData?.values.length;
            return xAxisFontSizeValues(numberOfBars);
        } else {
            const numberOfLines = lineData[0]?.values.length;
            return xAxisFontSizeValues(numberOfLines);
        }
    };

    const chartRef = useRef(null);
    const titleRef = useRef(null);

    const HEIGHT = customHeight ? customHeight : DEFAULT_HEIGHT;

    const addLineHoverEffect = d3Element => {
        const svg = d3.select(chartRef.current);
        if (alwaysOnLineTooltip) return;
        d3Element
            .on('mouseover', function (_, datum) {
                svg.selectAll('.data-line').style('opacity', 0.2);
                svg.selectAll('.data-line-' + createClassName(datum.name)).style('opacity', 1);
                svg.selectAll('.data-tooltip-' + createClassName(datum.name)).style('display', 'block');
                svg.selectAll('.data-brand-' + createClassName(datum.name)).style('visibility', 'visible');

                svg.selectAll('.data-circle.data-line-' + createClassName(datum.name)).attr('r', 5);
            })
            .on('mouseout', function (_, datum) {
                svg.selectAll('.data-line').style('opacity', 1);
                svg.selectAll('.data-tooltip-' + createClassName(datum.name)).style('display', 'none');
                svg.selectAll('.data-brand-' + createClassName(datum.name)).style('visibility', 'hidden');

                svg.selectAll('.data-circle').attr('r', 2);
            });
    };

    const returnValueBasedOnSigTest = (d, fulfillValue, defaultValue) => {
        if (d.hasOwnProperty('sigtest')) {
            if (d.sigtest !== 0) {
                return fulfillValue;
            }
        }
        return defaultValue;
    };

    const hideUnnecessaryTickStyle = (d3Element, selector) => {
        if (Array.isArray(d3Element)) {
            d3Element.forEach(el => el.selectAll(selector).attr('opacity', 0));
            return;
        }
        d3Element.selectAll(selector).attr('opacity', 0);
    };
    const addBarTooltipHoverEffect = (d3Element, xScale, yScale, dataDomain) => {
        const svg = d3.select(chartRef.current);

        const yValueOnHover = WIDTH < 450 ? 70 : 60;
        const heightValueOnHover = WIDTH < 450 ? '60px' : '50px';

        d3Element
            .on('mouseover', function (_, datum) {
                if (hideBarLabel) {
                    svg.select('.bar-' + createClassName(datum.group) + ' text').attr('opacity', 1);
                    svg.select('.bar-tooltip-wrapper-' + createClassName(datum.group)).attr('opacity', 1);
                    svg.select('.bar-tooltip-arrow-' + createClassName(datum.group)).attr('opacity', 1);
                }

                svg.select('.bar-' + createClassName(datum.group)).raise();
                if (!hideBarLabel) {
                    svg.selectAll('.lines').raise();
                }
                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group) + ' span')
                    .raise()
                    .style('display', 'block');
                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group) + ' .barTooltipDivWrapp')
                    .style('height', heightValueOnHover)
                    .style('padding', '2px 5px');

                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group))
                    //The formula for centering is the following : xScale of that group - (the width of the wrapper / 2 - the width of a band /2) - half of the padding of 5px)
                    .attr(
                        'x',
                        d =>
                            xScale(d.group) -
                            ((customBarTooltip?.width || SIG_TEST_WIDTH) / 2 - xScale.bandwidth() / 2) -
                            2.5
                    )
                    .attr('y', d => yScale(d.value) - yValueOnHover)
                    .attr('width', customBarTooltip?.width || SIG_TEST_WIDTH)
                    .style('box-shadow', customBarTooltip?.boxShadow || `none`)
                    .attr('height', heightValueOnHover);

                if (hideTitleOnSigTestHover && titleRef && titleRef.current) {
                    /* Hide the title if the significance test is overlapping (or close to overlapping) the title. */
                    if (datum.value > 0.5 * dataDomain) {
                        titleRef.current.style.opacity = 0;
                    }
                }
            })

            .on('mouseout', function (_, datum) {
                if (hideBarLabel) {
                    svg.select('.bar-' + createClassName(datum.group) + ' text').attr('opacity', 0);
                    svg.select('.bar-tooltip-wrapper-' + createClassName(datum.group)).attr('opacity', 0);
                    svg.select('.bar-tooltip-arrow-' + createClassName(datum.group)).attr('opacity', 0);
                }
                svg.select('.bar-' + createClassName(datum.group)).lower();
                svg.selectAll('.axis').lower();
                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group) + ' span').style(
                    'display',
                    'none'
                );
                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group) + ' .barTooltipDivWrapp')
                    .style('height', '20px')
                    .style('padding', '10px 0')
                    .style('margin-right', '0px');
                svg.selectAll('.bar-tooltip-wrapper-' + createClassName(datum.group))
                    .attr('x', d => xScale(d.group) - 3)
                    .attr('y', d => yScale(d.value) - 28)
                    .attr('width', xScale.bandwidth() + 6)
                    .attr('height', '20px');

                if (hideTitleOnSigTestHover && titleRef && titleRef.current) {
                    titleRef.current.style.opacity = 1;
                }
            });
    };

    useEffect(() => {
        if (barData && lineData) {
            const svg = d3.select(chartRef.current);
            svg.selectAll('*').remove();

            let internalLineData;
            if (!bypassLOBFiltering) {
                internalLineData = lineData.filter(
                    ld => visibleLOBs.includes(ld.name) || visibleLOBs.includes(ld.name.toLowerCase())
                );
            } else {
                internalLineData = lineData;
            }

            const calculateMaxValue = (d, val) => (d.value === 0 ? 25 : d.value + d.value / val);

            // Fallback to getting data domain from line charts in case of missing bar data
            let dataDomain, groupDomain, lineDataDomain;
            if (barData.length === 0) {
                dataDomain = d3.max(lineData, line => d3.max(line.values, d => calculateMaxValue(d, 4)));
                groupDomain = lineData[0].values.map(d => d.group);
            } else if (lineData.length === 0) {
                dataDomain = customDomain
                    ? customDomain
                    : d3.max([d3.max(barData.values, d => calculateMaxValue(d, 4)), 0]);
                groupDomain = barData.values.map(d => d.group);
            } else {
                lineDataDomain = 0;
                if (lineData.length > 0) {
                    lineDataDomain = customDomain
                        ? customDomain
                        : d3.max(lineData, line =>
                              d3.max(line.values, d => calculateMaxValue(d, lineScalingOn ? 1 : 4))
                          );
                }

                dataDomain = d3.max([d3.max(barData.values, d => calculateMaxValue(d, 4)), lineDataDomain]);
                groupDomain = barData.values.map(d => d.group);
            }
            // Create the scales
            const xScale = d3
                .scaleBand()
                .rangeRound([hideLeftLabel ? 0 : PADDING.LEFT, WIDTH - (hideLeftLabel ? 0 : PADDING.RIGHT)])
                .padding(customBarPadding)
                .domain(groupDomain);
            const yScale = d3
                .scaleLinear()
                .rangeRound([HEIGHT - PADDING.TOP, PADDING.BOTTOM])
                .domain([0, dataDomain]);
            const yScaleLine = d3
                .scaleLinear()
                .rangeRound([HEIGHT - PADDING.TOP, PADDING.BOTTOM])
                .domain([0, lineDataDomain]);

            const getBandMiddle = val => xScale(val) + xScale.bandwidth() / 2;
            const getLineScalingFunction = val => (lineScalingOn ? yScaleLine(val) : yScale(val));
            const isBarWidthLow = xScale.bandwidth() < MIN_BAR_WIDTH_METRIC_BADGE;
            /* Create the axis and lengthen the ticks to the size of the chart */
            const buildTicks = () => {
                const yaxisLeft = d3
                    .axisLeft()
                    .scale(yScale)
                    .ticks(5)
                    .tickFormat(d => d + leftAxisLabelFormatter || d + '%');

                if (lineScalingOn) {
                    const yaxisRight = d3
                        .axisLeft() //Even though it points to right, is ok to leave it axisLeft as we set the stroke-opacity to 0, hiding domain line
                        .scale(yScaleLine)
                        .ticks(10)
                        .tickFormat(d => d + '%');

                    const xAxisElementRight = svg
                        .append('g')
                        .attr('class', 'axis')
                        .attr('transform', 'translate(0' + (WIDTH - AXIS_PADDING - 5) + ',0)')
                        .call(yaxisRight);

                    xAxisElementRight
                        .selectAll('text')
                        .attr('x', () => AXIS_PADDING)
                        .attr('opacity', 0.8);
                    hideUnnecessaryTickStyle(xAxisElementRight, 'line, .domain');
                }

                const xaxis = d3.axisBottom().scale(xScale);

                const yAxisElement = svg
                    .append('g')
                    .attr('class', 'axis')
                    .attr('transform', 'translate(0,' + (HEIGHT + 5) + ')')
                    .call(xaxis);
                const xAxisElementLeft = svg
                    .append('g')
                    .attr('class', 'axis')
                    .attr('transform', 'translate(0,0)')
                    .call(yaxisLeft);

                if (foreignObjects) {
                    yAxisElement.selectAll('text').remove();
                    yAxisElement
                        .selectAll('.tick')
                        .append('foreignObject')
                        .attr('class', d => 'bar-label-' + d)
                        .attr('width', foreignObjects.width || 60)
                        .attr('height', foreignObjects.height || 90)
                        .style('word-wrap', 'break-word')
                        .style('font-size', 11)
                        .style('text-align', 'center')
                        .style('font-weight', 600)
                        .style('color', defaultTheme.blue[950])
                        .style(
                            'transform',
                            `translate(${foreignObjects.translateX || '-30'}px,${foreignObjects.translateY || '0'}px)`
                        )
                        .html(d => {
                            const textLength = d.length;
                            const elementId = createIdFromGroup('ellipsed', d);

                            if (textLength >= ELLIPSIS_SHOW_LENGTH) {
                                return `
                                    <div>
                                        <p id=${elementId}> ${d.substring(0, 17)}...</p>
                                    </div>            
                                `;
                            } else {
                                return `<p>${d}</p>`;
                            }
                        })
                        .on('mouseenter', (_, dt) => {
                            if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                                const ellipsedId = createIdFromGroup('ellipsed', dt);
                                const imageId = createIdFromGroup('emotionGroup', dt);

                                d3.select(`#${ellipsedId}`).text(dt);
                                d3.select(`#${imageId}`).transition().style('opacity', 0.1);
                            }
                        })
                        .on('mouseleave', (_, dt) => {
                            if (dt.length >= ELLIPSIS_SHOW_LENGTH) {
                                const text = dt.length >= 20 ? dt.substring(0, 17) + '...' : dt;
                                const ellipsedId = createIdFromGroup('ellipsed', dt);
                                const imageId = createIdFromGroup('emotionGroup', dt);

                                d3.select(`#${ellipsedId}`).text(text);
                                d3.select(`#${imageId}`).transition().style('opacity', 1);
                            }
                        });

                    const groups = barData.values.map(d => ({ group: d.group, image: d.image }));

                    yAxisElement
                        .selectAll('.tick')
                        .data(groups)
                        .append('svg:image')
                        .attr('xlink:href', function (d) {
                            return d.image;
                        })
                        .attr('id', d => createIdFromGroup('emotionGroup', d.group))
                        .attr('width', 50)
                        .attr('height', 43)
                        .attr('x', -25)
                        .attr('y', 55)
                        .style('box-shadow', '0px 2px 2px #00000029');

                    yAxisElement
                        .selectAll('line')
                        .attr('y1', -(HEIGHT - PADDING.TOP))
                        .attr('y2', -(PADDING.BOTTOM + 6))
                        .style('stroke-dasharray', '2, 3');
                } else {
                    yAxisElement
                        .selectAll('text')
                        .attr('y', () => -AXIS_PADDING)
                        .attr('opacity', 0.8);
                }
                if (!hideGridLines) {
                    let { paddingLeft, paddingRight } = customGridStyle;

                    xAxisElementLeft
                        .selectAll('line')
                        .attr('x2', () => WIDTH - PADDING.RIGHT + (paddingRight || 0))
                        .attr('x1', (hideLeftLabel ? PADDING.LEFT - 26 : PADDING.LEFT) + (paddingLeft || 0))
                        .style('stroke-dasharray', '2, 3');
                }

                xAxisElementLeft
                    .selectAll('text')
                    .attr('x', () => (leftLabel ? AXIS_PADDING + 30 : AXIS_PADDING))
                    .attr('opacity', hideLeftLabel ? 0 : 0.8);

                if (leftLabel) {
                    xAxisElementLeft
                        .append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 5)
                        .attr('x', 0 - HEIGHT / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(leftLabel);
                }

                if (bottomLabel) {
                    yAxisElement
                        .append('text')
                        .attr('y', -21.5)
                        .attr('x', WIDTH / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(bottomLabel);
                }

                if (numberOfRespondents && numberOfRespondents.length > 0) {
                    yAxisElement
                        .selectAll('g')
                        .append('foreignObject')
                        .attr('width', xAxisResponsiveSize() !== 12 ? (WIDTH <= 300 ? 20 : 30) : 40)
                        .attr('height', 15)
                        .attr('y', -17)
                        .attr('x', xAxisResponsiveSize() !== 12 ? (WIDTH <= 300 ? -10 : -15) : -20)
                        .html(d => {
                            let numberOfRespondentsObj = numberOfRespondents.find(data => data.group === d);
                            if (numberOfRespondentsObj) {
                                const nrRespondents = 'n=' + numberOfRespondentsObj.numberOfRespondents;
                                return `<div class="numberOfRespondents" title=${nrRespondents}>${nrRespondents}</div>`;
                            } else return '';
                        });
                }
                hideUnnecessaryTickStyle(xAxisElementLeft, '.domain');
                hideUnnecessaryTickStyle(yAxisElement, 'line, .domain');
            };

            buildTicks(lineScalingOn);

            const bar = svg
                .selectAll('rect')
                .data(barData.values)
                .enter()
                .append('g')
                .attr('class', d => 'bar-' + createClassName(d.group));

            const indexOfName = visibleLOBs?.findIndex(element => {
                return element.toLowerCase() === barData.name?.toLowerCase();
            });

            const getNumberWithSign = number => (number === 0 ? number : (number < 0 ? '-' : '+') + Math.abs(number));

            const drawDifferenceLabels = () => {
                if (barData.length === 0) return;
                if (barData.values.every(el => el.value === 0)) return;

                const difference = svg.append('g').attr('class', 'diffs');

                difference
                    .selectAll('g')
                    .data(barData.values)
                    .enter()
                    .filter((_, i) => i < barData.values.length - 1)
                    .append('g')
                    .attr('class', d => 'difference-line-' + createClassName(d.group));

                difference
                    .selectAll('g')
                    .append('rect')
                    .attr('x', d => xScale(d.group) + xScale.bandwidth() + xScale.bandwidth() / 1.5 - 13)
                    .attr('y', d => yScale(d.value) - 14)
                    .attr('width', '30px')
                    .attr('height', '20px')
                    .attr('fill', (_, i) => {
                        const value = barData.values[i + 1]?.midValue;
                        return value === 0
                            ? 'transparent'
                            : value > 0
                            ? defaultTheme.green[200]
                            : defaultTheme.red[550];
                    })
                    .attr('rx', 10)
                    .attr('ry', 10)
                    .style('stroke-width', 2)
                    .style('stroke', (_, i) => {
                        const value = barData.values[i + 1]?.midValue;
                        return value === 0 ? defaultTheme.black[29] : 'transparent';
                    });

                difference
                    .selectAll('g')
                    .append('text')
                    .attr('x', d => xScale(d.group) + xScale.bandwidth() + xScale.bandwidth() / 1.5 + 2)
                    .attr('y', d => yScale(d.value))
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '11px')
                    .text((d, i) => getNumberWithSign(barData.values[i + 1]?.midValue) + '%')
                    .attr('fill', (_, i) => {
                        const value = barData.values[i + 1]?.midValue;
                        return value !== 0 && (value > 0 ? defaultTheme.black.ff : defaultTheme.white.ff);
                    });
            };

            if (indexOfName !== -1 || bypassLOBFiltering) {
                // Create the bar charts
                bar.append('rect')
                    .attr('x', d => xScale(d.group))
                    .attr('y', d => yScale(d.value) - PADDING.TOP + AXIS_PADDING)
                    .attr('width', xScale.bandwidth())
                    .attr('height', d => (d.value === 0 ? 0 : HEIGHT - yScale(d.value) - AXIS_PADDING))
                    .style('fill', d => d.color);

                let barTooltip = bar.filter(d => hideBarLabel || [-1, 1].includes(d.sigtest)).append('foreignObject');

                if (showSigTest) {
                    barTooltip
                        .attr('class', d => 'bar-tooltip-wrapper-' + createClassName(d.group))
                        .attr('x', d => xScale(d.group) - 5)
                        .attr('y', d => yScale(d.value) - 28)
                        .attr('width', xScale.bandwidth() + 8)
                        .attr('opacity', hideBarLabel ? 0 : 1)
                        .attr('height', '20px')
                        .append('xhtml:div')
                        .attr('class', 'barTooltipDivWrapp')
                        .style('background-color', d => {
                            if (customBarTooltip && customBarTooltip.hasOwnProperty('backgroundColor'))
                                return customBarTooltip.backgroundColor;
                            return d.sigtest === 1 ? defaultTheme.green[200] : defaultTheme.yellow[500];
                        })
                        .style('text-align', 'center')
                        .style('font-size', '10px')
                        .style('padding', '10px 0')
                        .style('word-break', 'break-word')
                        .html(d => {
                            let tooltipText =
                                d.sigtest === 1
                                    ? TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_HIGHER
                                    : d.sigtest === -1
                                    ? TRANSLATION_TEXT.BPT_AWARENESS_LEGEND_SIGNIFICANTLY_LOWER
                                    : '';
                            if (customBarTooltip && customBarTooltip.hasOwnProperty('textContentForKey'))
                                tooltipText = d[customBarTooltip.textContentForKey];
                            return `<span class=${
                                [1, -1].includes(d.sigtest) ? 'sigTestContentDelimiter' : 'tooltipContent'
                            }>
                                ${tooltipText}
                            </span>            
                        `;
                        });

                    const barTooltipArrow = bar
                        .filter(d => hideBarLabel || [-1, 1].includes(d.sigtest))
                        .append('foreignObject');
                    barTooltipArrow
                        .attr('class', d => 'bar-tooltip-arrow-' + createClassName(d.group))
                        .attr('x', d => xScale(d.group) - 3)
                        .attr('y', d => yScale(d.value) - 10)
                        .attr('width', xScale.bandwidth() + 6)
                        .attr('height', '20px')
                        .attr('opacity', hideBarLabel ? 0 : 1)
                        .append('xhtml:div')
                        .style('width', '1px')
                        .style('border-top', d => {
                            if (customBarTooltip && customBarTooltip.hasOwnProperty('backgroundColor'))
                                return `5px solid ${customBarTooltip.backgroundColor}`;
                            return d.sigtest === 1
                                ? '5px solid ' + defaultTheme.green[200]
                                : ' 5px solid ' + defaultTheme.yellow[500];
                        })
                        .style('border-right', '3px solid transparent')
                        .style('border-left', '3px solid transparent')
                        .style('border-bottom', 'none')
                        .style('margin', '0 auto');
                }

                const barTextValue = bar
                    .append('text')
                    .attr('class', 'bar-chart-top-value')
                    .attr('x', d => getBandMiddle(d.group))
                    .attr('y', d => yScale(d.value) - PADDING.TOP + AXIS_PADDING - (hideBarLabel ? 35 : 11))
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '7px')
                    .attr('fill', 'black')
                    .style('font-weight', 600)
                    .attr('opacity', hideBarLabel ? 0 : 1)
                    .text(d => d.value + barLabelFormatter || d.value + '%');

                //Metrics Badges
                const metricsGroup = svg.append('g').attr('class', 'metrics-group');

                const metricBadgeGroup = metricsGroup
                    .selectAll('g')
                    .data(barData.values)
                    .join('g')
                    .filter(d => Number.isFinite(d?.evolutionMetricValue))
                    .attr('class', d => 'badge-' + createClassName(d.group))
                    .lower();

                metricBadgeGroup
                    .on('mouseover', (_, datum) => {
                        if (xScale.bandwidth() >= MIN_BAR_WIDTH_METRIC_BADGE) return;
                        const ANIM_TEXT_OFFSET = 5;
                        let metricBadgeTextSelection = svg
                            .select('.bar-chart-metric-badge-text-' + createClassName(datum.group))
                            .transition()
                            .delay(150)
                            .ease(d3.easeLinear)
                            .duration(100);
                        let metricBadgeRectSelection = svg
                            .select('.bar-chart-metric-badge-' + createClassName(datum.group))
                            .transition()
                            .ease(d3.easeLinear)
                            .duration(200);

                        metricBadgeTextSelection
                            .attr('opacity', 1)
                            .attr(
                                'y',
                                d =>
                                    yScale(d.value) -
                                    PADDING.TOP +
                                    AXIS_PADDING -
                                    METRIC_OFFSET +
                                    ANIM_TEXT_OFFSET -
                                    (hideBarLabel ? 35 : 11)
                            );
                        metricBadgeRectSelection
                            .attr('width', DIMENSIONS.METRIC_BADGE.width)
                            .attr('height', DIMENSIONS.METRIC_BADGE.height);
                        metricBadgeRectSelection
                            .attr(
                                'y',
                                d =>
                                    yScale(d.value) -
                                    PADDING.TOP +
                                    AXIS_PADDING -
                                    METRIC_OFFSET +
                                    ANIM_TEXT_OFFSET -
                                    (hideBarLabel ? 35 : 11)
                            )
                            .attr(
                                'transform',
                                `translate(-${DIMENSIONS.METRIC_BADGE.width / 2},-${
                                    DIMENSIONS.METRIC_BADGE.height / 1.5
                                })`
                            );
                    })
                    .on('mouseleave', (_, datum) => {
                        if (xScale.bandwidth() >= MIN_BAR_WIDTH_METRIC_BADGE) return;
                        const ANIM_TEXT_OFFSET = 0;
                        let metricBadgeTextSelection = svg
                            .select('.bar-chart-metric-badge-text-' + createClassName(datum.group))
                            .transition()
                            .delay(0)
                            .ease(d3.easeLinear)
                            .duration(50);
                        let metricBadgeRectSelection = svg
                            .select('.bar-chart-metric-badge-' + createClassName(datum.group))
                            .transition()
                            .ease(d3.easeLinear)
                            .duration(200);
                        metricBadgeTextSelection
                            .attr('opacity', 0)
                            .attr(
                                'y',
                                d =>
                                    yScale(d.value) -
                                    PADDING.TOP +
                                    AXIS_PADDING -
                                    METRIC_OFFSET +
                                    ANIM_TEXT_OFFSET -
                                    (hideBarLabel ? 35 : 11)
                            );
                        metricBadgeRectSelection.attr('width', 12);
                        metricBadgeRectSelection.attr('height', 12);
                        metricBadgeRectSelection.attr(
                            'y',
                            d =>
                                yScale(d.value) -
                                PADDING.TOP +
                                AXIS_PADDING -
                                METRIC_OFFSET -
                                (isBarWidthLow ? -MIN_BAR_WIDTH_METRIC_BADGE : 0) -
                                (hideBarLabel ? 35 : 11)
                        );
                        metricBadgeRectSelection.attr(
                            'transform',
                            `translate(-${
                                isBarWidthLow
                                    ? DIMENSIONS.METRIC_BADGE_DOT.width / 2
                                    : DIMENSIONS.METRIC_BADGE.width / 2
                            },-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
                        );
                    });

                metricBadgeGroup
                    .append('rect')
                    .attr('class', d => 'bar-chart-metric-badge-' + createClassName(d.group))
                    .attr('x', d => getBandMiddle(d.group))
                    .attr(
                        'y',
                        d =>
                            yScale(d.value) -
                            PADDING.TOP +
                            AXIS_PADDING -
                            METRIC_OFFSET -
                            (isBarWidthLow ? -MIN_BAR_WIDTH_METRIC_BADGE + 2 : 0) -
                            (hideBarLabel ? 35 : 11)
                    )
                    .attr(
                        'transform',
                        `translate(-${
                            isBarWidthLow ? MIN_BAR_WIDTH_METRIC_BADGE / 2 : DIMENSIONS.METRIC_BADGE.width / 2
                        },-${DIMENSIONS.METRIC_BADGE.height / 1.5})`
                    )
                    .attr('fill', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].background)
                    .attr('stroke', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].border)
                    .attr('cursor', 'pointer')
                    .attr('stroke-width', 1)
                    .attr('rx', 10)
                    .attr('width', isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.width : DIMENSIONS.METRIC_BADGE.width)
                    .attr(
                        'height',
                        isBarWidthLow ? DIMENSIONS.METRIC_BADGE_DOT.height : DIMENSIONS.METRIC_BADGE.height
                    );

                metricBadgeGroup
                    .append('text')
                    .attr('class', d => 'bar-chart-metric-badge-text-' + createClassName(d.group))
                    .attr('x', d => getBandMiddle(d.group))
                    .attr(
                        'y',
                        d => yScale(d.value) - PADDING.TOP + AXIS_PADDING - METRIC_OFFSET - (hideBarLabel ? 35 : 11)
                    )
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '12px')
                    .attr('fill', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].textColor)
                    .style('font-weight', 600)
                    .attr('opacity', isBarWidthLow ? 0 : 1)
                    .attr('cursor', 'pointer')
                    .text(
                        d =>
                            numFormatterExceptZero(d.evolutionMetricValue) +
                            ' ' +
                            TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL
                    );

                if (hideBarLabel) {
                    addBarTooltipHoverEffect(bar, xScale, yScale, dataDomain);
                }
                addBarTooltipHoverEffect(barTextValue, xScale, yScale, dataDomain);
                addBarTooltipHoverEffect(barTooltip, xScale, yScale, dataDomain);
            }

            //Create the lines
            const lineCreator = d3
                .line()
                .x(d => getBandMiddle(d.group))
                .y(d => getLineScalingFunction(d.value));

            const lineGroups = svg.selectAll('lines').data(internalLineData).join('g').attr('class', 'lines');

            const lines = lineGroups
                .append('path')
                .attr('class', d => 'data-line data-line-' + createClassName(d.name))
                .attr('d', d => lineCreator(d.values))
                .style('fill', 'none')
                .style('stroke', d => d.color)
                .style('stroke-width', 2);
            addLineHoverEffect(lines);

            const lineGroupExtendedHover = lineGroups
                .append('path')
                .attr('d', d => lineCreator(d.values))
                .style('fill', 'none')
                .style('stroke', 'transparent')
                .style('stroke-width', 7);
            addLineHoverEffect(lineGroupExtendedHover);

            const circles = lineGroups
                .append('g')
                .selectAll('circle')
                .data(d => d.values.map(e => ({ ...e, color: d.color, name: d.name })))
                .enter()
                .append('circle')
                .attr('class', d => 'data-circle data-line data-line-' + createClassName(d.name))
                .attr('r', 2)
                .attr('cx', d => getBandMiddle(d.group))
                .attr('cy', d => getLineScalingFunction(d.value))
                .attr('fill', d => d.color);
            addLineHoverEffect(circles);

            const lineMetricBadgeGroup = lineGroups.append('g');
            lineMetricBadgeGroup
                .selectAll('rect')
                .data(d =>
                    d.values.flatMap(e => (Number.isFinite(e?.evolutionMetricValue) ? { ...e, name: d.name } : []))
                )
                .join('rect')
                .attr('class', d => 'metric-badge data-tooltip-' + createClassName(d.name))
                .attr('x', d => getBandMiddle(d.group))
                .attr('y', d => getLineScalingFunction(d.value))
                .attr(
                    'transform',
                    `translate(-${DIMENSIONS.METRIC_BADGE.width / 2},-${DIMENSIONS.METRIC_BADGE.height / 1.5 + 45})`
                )
                .attr('fill', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].background)
                .attr('stroke', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].border)
                .attr('cursor', 'pointer')
                .attr('stroke-width', 1)
                .attr('rx', 10)
                .attr('width', DIMENSIONS.METRIC_BADGE.width)
                .attr('height', DIMENSIONS.METRIC_BADGE.height)
                .style('display', alwaysOnLineTooltip ? 'block' : 'none');

            lineMetricBadgeGroup
                .selectAll('text')
                .data(d =>
                    d.values.flatMap(e => (Number.isFinite(e?.evolutionMetricValue) ? { ...e, name: d.name } : []))
                )
                .join('text')
                .attr('class', d => 'metric-badge data-tooltip-' + createClassName(d.name))
                .attr('x', d => getBandMiddle(d.group))
                .attr('y', d => getLineScalingFunction(d.value) - 45)
                .attr('text-anchor', 'middle')
                .attr('font-size', '12px') // 12
                .attr('fill', d => METRICS_VALUE_COLORS_MAP[Math.sign(d.evolutionMetricValue)].textColor)
                .style('font-weight', 600)
                .text(
                    d =>
                        numFormatterExceptZero(d.evolutionMetricValue) +
                        ' ' +
                        TRANSLATION_TEXT.BPT_EVOLUTION_METRIC_SYMBOL
                )
                .style('display', alwaysOnLineTooltip ? 'block' : 'none');
            addLineHoverEffect(lineMetricBadgeGroup);

            if (showBrandName) {
                const brandName = lineGroups
                    .append('foreignObject')
                    .attr('class', d => 'data-brand-' + createClassName(d.name))
                    .style('visibility', 'hidden');

                brandName
                    .attr('width', '75px')
                    .attr('x', d => getBandMiddle(d.values[0].group) - 88)
                    .append('xhtml:div')
                    .html(d => `<div class="brandName" style="border-color:${d.color}">${d.name}</div>`)
                    .each(function (d) {
                        svg.selectAll('.data-brand-' + createClassName(d.name))
                            .attr('y', yScale(d.values[0].value) - this.offsetHeight / 2) //Brand name always on center with the line, no matter the text height.
                            .attr('height', this.offsetHeight + 5);
                    });
            }

            //Create the tooltip
            const tooltipGroup = lineGroups
                .append('g')
                .attr('class', d => 'data-tooltip-' + createClassName(d.name))
                .style('display', alwaysOnLineTooltip ? 'block' : 'none');
            tooltipGroup
                .selectAll('rect')
                .data(d => d.values.map(e => ({ ...e, color: d.color })))
                .enter()
                .append('rect')
                .attr('x', d => getBandMiddle(d.group) - (customLineTooltip?.width || DIMENSIONS.TOOLTIP.width) / 2)
                .attr('y', d => getLineScalingFunction(d.value) - 32)
                .attr('rx', 2)
                .attr('ry', 2)
                .attr('width', customLineTooltip?.width || DIMENSIONS.TOOLTIP.width)
                .attr('height', customLineTooltip?.height || DIMENSIONS.TOOLTIP.height)
                .attr('stroke', d => d.color)
                .attr('stroke-width', d => returnValueBasedOnSigTest(d, 0, 2))
                .attr('stroke-opacity', customLineTooltip?.strokeOpacity)
                .attr('fill', d => {
                    if (customLineTooltip && customLineTooltip.hasOwnProperty('backgroundColor'))
                        return customLineTooltip.backgroundColor;

                    if (d.hasOwnProperty('sigtest')) {
                        switch (d.sigtest) {
                            case -1:
                                return defaultTheme.yellow[500];
                            case 0:
                                return defaultTheme.white.ff;
                            case 1:
                                return defaultTheme.green[200];
                            default:
                                return defaultTheme.white.ff;
                        }
                    } else {
                        return defaultTheme.white.ff;
                    }
                });

            tooltipGroup
                .selectAll('text')
                .data(d => d.values.map(e => ({ ...e, color: d.color })))
                .enter()
                .append('text')
                .attr('x', d => getBandMiddle(d.group))
                .attr('y', d => getLineScalingFunction(d.value) - 16)
                .attr('fill', d => {
                    if (customLineTooltip && customLineTooltip.hasOwnProperty('textColor'))
                        return customLineTooltip.textColor;
                    return returnValueBasedOnSigTest(
                        d,
                        defaultTheme.blue[900],
                        customLineTooltip?.tooltipFontColor || d.color
                    );
                })
                .attr('text-anchor', 'middle')
                .attr('font-size', customLineTooltip?.fontSize || '13px')
                .style('font-weight', 600)
                .text(d => customLineTooltip?.labelFormatter + d.value || d.value + '%');

            if ((indexOfName !== -1 || bypassLOBFiltering) && showDiffs) {
                drawDifferenceLabels();
            }
        }
    }, [lineData, barData, WIDTH, visibleLOBs, bypassLOBFiltering]);
    return (
        <div>
            {titleObject && (
                <SC.Title ref={titleRef} customStyle={titleObject.wrapperCustomStyle}>
                    {titleObject.customHTML()}
                </SC.Title>
            )}
            <SC.SvgWrapper
                ref={chartRef}
                width={WIDTH}
                height={HEIGHT}
                showBrandName={showBrandName}
                hasNumberOfRespondents={numberOfRespondents && numberOfRespondents.length > 0}
                xAxisResponsiveSize={xAxisResponsiveSize()}
            />
        </div>
    );
};

const ChartValueType = PropTypes.shape({
    value: PropTypes.number.isRequired,
    group: PropTypes.string.isRequired,
});

CombinedBarLineChart.propTypes = {
    lineData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string,
            values: PropTypes.arrayOf(ChartValueType),
        })
    ),
    barData: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(ChartValueType),
        }),
        PropTypes.array,
    ]),
    customDomain: PropTypes.number,
    customBarPadding: PropTypes.number,
    visibleLOBs: PropTypes.arrayOf(PropTypes.string),
    bypassLOBFiltering: PropTypes.bool,
    showSigTest: PropTypes.bool,
    showBrandName: PropTypes.bool,
    fluidWidth: PropTypes.bool,
    showDiffs: PropTypes.bool,
    alwaysOnLineTooltip: PropTypes.bool,
    lineScalingOn: PropTypes.bool,
    hideGridLines: PropTypes.bool,
    leftLabel: PropTypes.string,
    bottomLabel: PropTypes.string,
    hideLeftLabel: PropTypes.bool,
    leftAxisLabelFormatter: PropTypes.string,
    foreignObjects: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            translateX: PropTypes.number,
            translateY: PropTypes.number,
        }),
    ]),
    customGridStyle: PropTypes.shape({
        paddingLeft: PropTypes.number,
        paddingRight: PropTypes.number,
    }),
    hideBarLabel: PropTypes.bool,
    customLineTooltip: PropTypes.shape({
        width: PropTypes.number,
        fontSize: PropTypes.number,
        labelFormatter: PropTypes.string,
        strokeOpacity: PropTypes.number,
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        tooltipFontColor: PropTypes.string,
    }),
    customBarTooltip: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        backgroundColor: PropTypes.string,
        boxShadow: PropTypes.string,
        textContentForKey: PropTypes.string,
    }),
    titleObject: PropTypes.shape({
        customHTML: PropTypes.func,
        wrapperCustomStyle: PropTypes.object,
    }),
    hideTitleOnSigTestHover: PropTypes.bool,
    numberOfRespondents: PropTypes.array,
};

CombinedBarLineChart.defaultProps = {
    barData: null,
    lineData: null,
    customWidthOnPage: '',
    visibleLOBs: [],
    showSigTest: true,
    showDiffs: false,
    fluidWidth: false,
    foreignObjects: false,
    customGridStyle: { paddingLeft: 0, paddingRight: 0 },
    titleObject: null,
    customDomain: null,
    customBarPadding: 0.6,
    numberOfRespondents: [],
};

export default CombinedBarLineChart;
