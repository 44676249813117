import { React, useState } from 'react';
import { HomeSectionWrapper, Header } from '../HomeSection/HomeSection.styles';
import {
    MenuItem,
    MenuHeader,
    MenuItemImage,
    MenuItemText,
    UseContent,
    ToolBoxInnerWrapper,
    LinkToDocument,
    ToolBoxOuterWrapper,
} from './UsageOfThisTool.styles';
import { usageOfThisToolSections, toolBoxUtilities } from './usageOfThisToolSections.config';
import HomePageVideo from './HomePageVideo';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { useDispatch } from 'react-redux';
import { getToolboxUrlActionCreator } from '../../data/actions';

const renderToolBoxSection = () => {
    const dispatch = useDispatch();

    const generateSecureUrl = id => {
        dispatch(getToolboxUrlActionCreator(id));
    };

    return (
        <ToolBoxOuterWrapper>
            <h1>{TRANSLATION_TEXT.TOOLBOX_TEXT}</h1>

            <ToolBoxInnerWrapper>
                {toolBoxUtilities.map((el, idx) => (
                    <LinkToDocument onClick={() => generateSecureUrl(el.id)} key={idx} href={el.path} target="_blank">
                        <img src={el.icon} />
                        {el.title}
                    </LinkToDocument>
                ))}
            </ToolBoxInnerWrapper>
        </ToolBoxOuterWrapper>
    );
};

const UsageOfThisTool = props => {
    const { icon, sectionTitle, color, bgColor } = props;

    // eslint-disable-next-line no-unused-vars
    const [items, setItems] = useState(usageOfThisToolSections);

    // eslint-disable-next-line no-unused-vars
    const [selectedItems, setSelectedItems] = useState(usageOfThisToolSections[0].title);

    const displayedVideoOrDocument = () => {
        return items.map(i => {
            if (i.isActive) {
                if (i.videoSource === '') {
                    return <HomePageVideo />;
                    /*
                        Disabled until we have tool usage documents
                        return <UseThisToolDoc src={i.documentLink} key={i.id} />;
                     */
                } else {
                    return <HomePageVideo />;
                }
            }
        });
    };

    const onMenuItemClick = title => {
        const temp = [...items];
        temp.forEach(j => {
            j.isActive = false;
            if (j.title === title) {
                j.isActive = true;
                setSelectedItems(j);
            }
        });
    };

    return (
        <HomeSectionWrapper>
            <Header color={color} bgColor={bgColor}>
                <img src={icon} alt="How To Use Icon" />
                <h3>{sectionTitle}</h3>
            </Header>
            <UseContent>
                <MenuHeader>
                    {items.map(section => (
                        <MenuItem
                            onClick={() => onMenuItemClick(section.title)}
                            key={section.id}
                            className={section.isActive ? 'isActive' : null}
                        >
                            <MenuItemImage src={section.icon} width="50px" height="40px" alt={section.title} />
                            <MenuItemText>{section.title}</MenuItemText>
                        </MenuItem>
                    ))}
                </MenuHeader>
                {displayedVideoOrDocument()}
                {renderToolBoxSection()}
            </UseContent>
        </HomeSectionWrapper>
    );
};

export default UsageOfThisTool;
