import styled from 'styled-components';

export const OverviewImage = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    cursor: ${props => props.cursor};
    position: ${props => props.position};
    right: ${props => props.right};
    top: ${props => props.top};
    left: ${props => props.left};
    display: ${props => props.display};
    justify-content: ${props => props.justifyContent};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
export const ComparisonWrapper = styled.div`
    visibility: hidden;
    margin: 0;
    position: absolute;
`;

export const OverviewPageWrapper = styled.div`
    padding: 11px;
    @media (max-width: 1680px) {
        padding: 4px;
    }
`;

export const CountryLabel = styled.div`
    color: ${({ theme }) => theme.blue[950]};
    position: relative;
    left: 21px;
    bottom: 2px;
    font-weight: ${props => props.fontWeight};
`;

export const CompareAds = styled.div`
    background-color: ${({ theme }) => theme.blue[600]};
    color: ${({ theme }) => theme.white.ff};
    width: 60px;
    height: 54px;
    display: ${props => props.display};
    position: absolute;
    right: -100px;
    margin-top: -40px;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    opacity: 1;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    @media (max-width: 1845px) {
        right: -98px;
    }
    @media (max-width: 1680px) {
        right: -88px;
    }
    @media (max-width: 1590px) {
        right: -85px;
    }
    @media (max-width: 1490px) {
        right: -80px;
    }
    @media (max-width: 1300px) {
        display: none;
    }
`;

export const CompareAdsSmallScreenWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
`;

export const CompareAdsSmallScreen = styled.div`
    background-color: ${({ theme }) => theme.blue[600]};
    color: ${({ theme }) => theme.white.ff};
    width: 128px;
    height: 45px;
    padding-top: 7px;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.black[29]};
    opacity: 1;
    font-size: 13px;
    cursor: pointer;
    text-align: center;

    @media (min-width: 1301px) {
        display: none;
    }
`;

export const DropDownWrapper = styled.div`
    background: ${({ theme }) => theme.white.ff};
    width: 178px;
    position: absolute;
    text-align: center;
    top: 118px;
    left: 40px;
    box-shadow: ${props => (props.showDropDownMenu ? `0px 0px 6px ${props.theme.black['29']}` : 'none')};
    opacity: 1;
    cursor: pointer;
    padding: 10px 20px;
    border: ${props => (props.showDropDownMenu ? 'none' : `1px solid ${props.theme.blue[950]}`)};

    @media (max-width: 1487px) {
        width: 123px;
    }

    @media (max-width: 1259px) {
        width: 100px;
    }

    @media (max-width: 1020px) {
        width: 88px;
    }
    ${({ customStyle }) => customStyle}
`;

export const FieldworkHeaderItemWrapper = styled.div`
    width: 59px;
    margin: auto;

    @media (max-width: 1291px) {
        width: 63px;
    }
`;

export const ToggleSectionWrapper = styled.div`
    position: absolute;
    top: 574px;
    text-align: center;
    left: 43%;
`;
export const ToggleSectionOptions = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.blue[950]};
`;

export const FieldworkTitle = styled.div`
    position: absolute;
    top: 42px;
    text-align: center;
    color: ${({ theme }) => theme.blue[950]};
    font-size: 16px;
    font-weight: bold;
    left: 46%;
`;

export const WarningInfoText = styled.div`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.blue[900]};
`;
export const OverviewAdsComparisonWrapper = styled.div`
    width: auto;
    height: auto;
    position: absolute;
    overflow: visible;
`;
export const OverviewTableWrapper = styled.div`
    div[role='table'] > div:first-child {
        font-size: 14px;
        user-select: none;
        text-align: center;
        @media (max-width: 1330px) {
            font-size: 12px;
        }
    }
    div[role='table'] > div:first-child > div[role='row'] {
    }
    div[role='cell']:nth-child(1) > span {
        @media (max-width: 1420px) {
            white-space: nowrap;
            display: block;
        }
    }
    div[role='cell'] {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        padding: 10px 8px;
    }
    div[role='cell'] > img.innerCampaignWarningImage {
        width: 15px;
    }
    div[role='cell'] > span {
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        white-space: pre-wrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    div[role='table'] {
        border: none;
        box-shadow: none;
    }

    div[role='columnheader'] {
        padding: 5px;
        position: relative;
        display: flex;
        align-items: baseline;
        text-align: left;
        justify-content: center;
        font-size: 13px;
        @media (max-width: 1500px) {
            font-size: 13px;
            padding: 5px;
        }
        @media (max-width: 1460px) {
            display: block;
            padding: 2px;
            font-size: 12px;
        }
    }
    div[role='columnheader'] > div:first-child {
        @media (max-width: 1370px) {
            width: 100%;
        }
    }

    div[role='row'] > div[role='columnheader']:nth-last-child(-n + 3) > div {
        visibility: hidden;
        @media (max-width: 1300px) {
            display: none;
        }
    }

    div[role='rowgroup'] div[role='row'] {
        div:nth-child(8) {
            border-right: 1px dashed ${({ theme }) => theme.blue[950]};
        }

        div:nth-child(14) {
            border-right: 1px dashed ${({ theme }) => theme.blue[950]};
        }
    }
    div[role='cell']:nth-child(2) {
        max-width: 65px;
        padding: 10px 5px;
        word-break: break-word;
    }
    div[role='columnheader']:nth-child(2) {
        max-width: 65px;
    }
    div[role='cell']:nth-child(4) {
        span {
            word-break: break-word;
        }
    }
    div[role='cell']:nth-child(5) {
        span {
            word-break: break-word;
        }
    }
    div[role='columnheader']:nth-child(7) {
        max-width: 70px;
    }
    div[role='cell']:nth-child(7) {
        max-width: 70px;
    }
    div[role='columnheader']:nth-child(8) {
        text-align: center;
        max-width: 70px;
    }
    div[role='cell']:nth-child(8) {
        max-width: 70px;
    }
    div[role='cell']:nth-child(12) {
        max-width: 70px;
    }
    div[role='columnheader']:nth-child(12) {
        max-width: 70px;
        @media (max-width: 1460px) {
            text-align: center;
        }
    }
    div[role='columnheader']:nth-child(14) {
        max-width: 70px;
    }
    div[role='cell']:nth-child(14) {
        max-width: 70px;
    }
    div[role='columnheader']:nth-child(15) {
        max-width: 40px;
    }
    div[role='cell']:nth-child(15) {
        max-width: 40px;
    }
    div[role='columnheader']:nth-child(16) {
        max-width: 70px;
    }
    div[role='cell']:nth-child(16) {
        max-width: 70px;
    }
    div[role='columnheader']:nth-child(17) {
        max-width: 70px;
        @media (max-width: 1300px) {
            word-break: break-word;
        }
    }
    div[role='cell']:nth-child(17) {
        max-width: 70px;
    }
`;
export const BaseSizeLowWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    img {
        width: 17px;
    }
    .itemLabel {
        font-size: 14px;
        color: ${({ theme }) => theme.blue[950]};
        margin: 0px 0px 0px 7px;
        @media screen and (max-width: 1100px) {
            margin: 0px 0px 12px 29px;
        }
        @media screen and (max-width: 1420px) and (min-width: 1100px) {
            margin: 0px 0px 12px 15px;
        }
    }
`;
