import styled from '@emotion/styled';
import { ThemeProvider } from '@toluna-ui-toolkit/theme';

export const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
`;

export const Slider = styled.span`
    padding-right: ${props => (props.checked  ? '0px' : props.width)};
    padding-left: ${props => (props.checked  ? props.width : '0px')};
  display: inline-flex;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 20px;
  box-shadow: inset 0 0 1px #ECF0F1;
  height: 14px;
  margin-right: 4px;
  align-self: center;
  ${props => props.disabled ? `
    background-color: #ECF0F1;
  ` : `
    background-color: ${props.checked  ? props.theme.lighterColor : '#ECF0F1'};
    cursor:pointer;
  `};
  width:21px;
    &:before {
        content: '';
    width: 21px;
    height: 21px;
    border-radius: 50%;
    align-self: center;
    ${props => props.disabled ? `
        background-color: #D8D8D8;
      ` : `
        background-color: ${props.checked  ? props.theme.lightColor : '#c8c8c8'};
      `};
      transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
      ${props => props.customStyle && props.customStyle.button ? props.customStyle.button : ''}
    }
        ${props => props.customStyle && props.customStyle.slider ? props.customStyle.slider : ''}
`;

export const Label = styled.span`
    display: inline-flex;
    font-weight: normal;
    color: rgba(130, 146, 168, ${props => props.disabled ? '0.5' : '1'});
    font-size: 14px;
    margin-left: 10px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    font-family: 'MuseoSans-300';
    ${props => props.customStyle && props.customStyle.label ? props.customStyle.label : ''}
`;
