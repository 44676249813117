import { all, call, put, takeLatest, takeEvery, select, take, race } from 'redux-saga/effects';
import {
    changeLoginPageActionCreator,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ASYNC,
    RESET_PASSWORD,
    RESET_PASSWORD_ASYNC,
    setEmailRecipientActionCreator,
    LOGIN_ACTION_GROUP,
    LOGIN_INIT,
    LOGOUT,
    setLoggedUserDetailsActionCreator,
    setTokenActionCreator,
    hasOnLoginErrorActionCreator,
    REFRESH_TOKEN_ASYNC_GROUP,
    IS_RESET_TOKEN_VALID_ASYNC,
    IS_RESET_TOKEN_VALID,
} from './actions';
import {
    sendForgotPasswordEmail,
    submitResetPassword,
    onRefreshTokenCall,
    checkIsResetTokenValid,
} from '../../../services/login.service';
import { loginPages } from '../utils/pageConfig';
import { onLoginSubmit } from '../../../services/login.service';
import { decodeAccessToken, isMaintenance } from '../../../utils/generalUtilities';
import { push, replace } from 'connected-react-router';
import { addAsyncErrorActionCreator, TOKEN_EXPIRED } from '../../../errorHandling/data/actions';
import { getAuthTokensSelector, getIsPendingTokenRefreshingSelector } from './selectors';
import { toggleSessionExpiredModalActionCreator } from '../../../common/components/SessionExpiredModal/data/actions';
import { globalOverviewSelectedCountriesSaveActionCreator } from '../../../common/data/actions';

import { TRANSLATION_TEXT } from '../../../utils/translations';
import { setAdTesterOverviewSelectedCountryActionCreator } from '../../adTester/subpages/Overview/data/actions';
import * as routes from '../../../utils/routes';

function* loginFunctionalitySaga(action) {
    try {
        const userDetailsObj = {
            username: action.payload.username,
            password: action.payload.password,
        };

        yield put(LOGIN_ACTION_GROUP.dispatchers.started());
        const { data } = yield call(onLoginSubmit, userDetailsObj);

        const { json } = data;

        const decodedToken = decodeAccessToken(json.access_token);

        const userDetails = {
            userName: decodedToken.name,
            role: decodedToken.PermissionsDashboards,
            userImage: decodedToken.image,
            country: decodedToken.PermissionsCountry,
            countryId: decodedToken.PermissionsDefaultCountryId,
        };
        yield put(setLoggedUserDetailsActionCreator(userDetails));

        /* TODO changed the country to something different based on user's login info */
        yield put(globalOverviewSelectedCountriesSaveActionCreator([8]));
        yield put(setAdTesterOverviewSelectedCountryActionCreator(8));
        //Perform token decode and save decoded user details
        if (isMaintenance) {
            yield put(replace(routes.DASHBOARD_PAGE.path));
        }
        yield put(LOGIN_ACTION_GROUP.dispatchers.succeeded(json));
    } catch (error) {
        const hasStatusCode = TRANSLATION_TEXT.ERROR_TEXT_LOGIN[error.response.status];
        if (hasStatusCode) {
            yield put(hasOnLoginErrorActionCreator(hasStatusCode));
        }
        yield put(LOGIN_ACTION_GROUP.dispatchers.failed(error));
    }
}

function* logoutSaga() {
    try {
        yield put(toggleSessionExpiredModalActionCreator(false));
        yield put(setTokenActionCreator({}));
        yield put(push('/'));
        localStorage.clear();
        window.location.reload();
    } catch (error) {}
}

function* attemptRefreshTokenSaga(action) {
    /* The action that previously caused a token refresh call */
    const actionRequestToRetry = action.payload;
    const isRefreshingPending = yield select(getIsPendingTokenRefreshingSelector);
    const tokens = yield select(getAuthTokensSelector);

    /* Check so that only one pending token refresh is in progress */
    if (!isRefreshingPending && Object.keys(tokens).length > 0) {
        yield put(REFRESH_TOKEN_ASYNC_GROUP.dispatchers.started());
        try {
            const { newToken } = yield race({
                newToken: yield call(onRefreshTokenCall, tokens.refresh_token),
                logout: take(LOGOUT),
            });

            const { json } = newToken.data;

            if (json) {
                yield put(setTokenActionCreator(json));
                yield put(REFRESH_TOKEN_ASYNC_GROUP.dispatchers.succeeded());
                //There might be a case where there is no actionRequestToRetry. Especially in the session Watcher hook. Better to check, there is no harm and avoids the Saga throwing an error.
                if (actionRequestToRetry) {
                    yield put(actionRequestToRetry);
                }
            }
        } catch (error) {
            //If refreshing results in an error, logout user
            const status = error.response.status;

            if (status === 403 || status === 400) {
                yield put(toggleSessionExpiredModalActionCreator(true));
            }
            yield addAsyncErrorActionCreator({ error });
            yield put(REFRESH_TOKEN_ASYNC_GROUP.dispatchers.failed());
        }
    } else {
        /* Wait for a refresh success before retrying the previous request */
        yield take(REFRESH_TOKEN_ASYNC_GROUP.actions.SUCCEEDED);
        yield put(actionRequestToRetry);
    }
}

function* forgotPassword(action) {
    try {
        yield put(FORGOT_PASSWORD_ASYNC.dispatchers.started());
        yield call(sendForgotPasswordEmail, action.payload);
        yield put(FORGOT_PASSWORD_ASYNC.dispatchers.succeeded());
        yield put(setEmailRecipientActionCreator(action.payload));
        yield put(changeLoginPageActionCreator(loginPages.EMAIL_SENT));
    } catch (error) {
        yield put(FORGOT_PASSWORD_ASYNC.dispatchers.failed(error));
    }
}

function* resetPassword(action) {
    try {
        yield put(RESET_PASSWORD_ASYNC.dispatchers.started());
        yield call(submitResetPassword, action.payload);
        yield put(RESET_PASSWORD_ASYNC.dispatchers.succeeded());
        yield put(changeLoginPageActionCreator(loginPages.RESET_SUCCESS));
    } catch (error) {
        yield put(RESET_PASSWORD_ASYNC.dispatchers.failed(error));
    }
}

function* isResetTokenValid(action) {
    try {
        yield put(IS_RESET_TOKEN_VALID_ASYNC.dispatchers.started());
        yield call(checkIsResetTokenValid, action.payload);
        yield put(IS_RESET_TOKEN_VALID_ASYNC.dispatchers.succeeded());
        yield put(changeLoginPageActionCreator(loginPages.RESET_PASSWORD));
    } catch (error) {
        yield put(IS_RESET_TOKEN_VALID_ASYNC.dispatchers.failed(error));
        yield put(changeLoginPageActionCreator(loginPages.LINK_EXPIRED));
    }
}

export default function* loginPageSaga() {
    yield all([
        takeLatest(LOGIN_INIT, loginFunctionalitySaga),
        takeLatest(LOGOUT, logoutSaga),
        takeLatest(FORGOT_PASSWORD, forgotPassword),
        takeLatest(RESET_PASSWORD, resetPassword),
        takeEvery(TOKEN_EXPIRED, attemptRefreshTokenSaga),
        takeEvery(IS_RESET_TOKEN_VALID, isResetTokenValid),
    ]);
}
