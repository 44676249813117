import { getPropertyValueOrDefault } from '../../../../../utils/generalUtilities';
import { SECTIONS_IDS } from './../utils/utils';
import { getSectionBasedOnSectionID } from '../../../../../utils/generalUtilities';
const get = state => state.adTesterAdDetailsMetrics;

export const getLineChartsDataSelector = state => {
    const sections = getPropertyValueOrDefault(get(state).metricsData, 'sections', null);
    return getSectionBasedOnSectionID(sections, SECTIONS_IDS.LINE_CHART); //To be changed if the section id is not 4.
};
export const getUpliftsDataSelector = state => {
    const sections = getPropertyValueOrDefault(get(state).metricsData, 'sections', null);
    return getSectionBasedOnSectionID(sections, SECTIONS_IDS.UPLIFTS);
};
export const getAdTesterCampaignDetailsSelector = state => {
    const details = getPropertyValueOrDefault(get(state).metricsData, 'campaignDetails', null);
    return details ? details[0] : details;
};

export const getMetricsDataSectionsSelector = state => {
    return getPropertyValueOrDefault(get(state).metricsData, 'sections', null);
};
