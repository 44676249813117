const get = state => state.login;

export const getCurrentLoginPageSelector = state => get(state).currentLoginPage;
export const getEmailRecipientSelector = state => get(state).emailRecipient;
export const getOnLoginErrorSelector = state => get(state).onLoginError;

/* Authentication object selectors */
const getAuth = state => state.authenticationInfo;
export const getAuthTokensSelector = state => getAuth(state).tokens;
export const getIsPendingTokenRefreshingSelector = state => getAuth(state).isPendingTokenRefreshing;
export const getUserDetailsSelector = state => getAuth(state).userInformation;
