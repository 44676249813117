import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
    GET_TRANSVERSALVIEW_DATA,
    GET_TRANSVERSALVIEW_DATA_ASYNC,
    ON_TRANSVERSAL_SECTIONS_TOGGLE,
    setTransversalSectionToggleActionCreator,
    OnTransversalSectionToggleActionCreator,
} from './actions';
import { getTransversalViewData } from '../../../../../services/apiServer.service';
import { addAsyncErrorActionCreator } from '../../../../../errorHandling/data/actions';
import { getAuthTokensSelector } from '../../../../login/data/selectors';
import { getComputedFiltersMultipleParametersForRequest } from '../../../../../common/data/computedSelectors';
import { getTransversalViewDataSectionIdComputedSelector } from './computedSelectors';
import { getKPYSTransversalDataSelector } from './selectors';

function* getTransversalViewDataSaga(action) {
    const tokens = yield select(getAuthTokensSelector);

    if (Object.keys(tokens).length > 0) {
        yield put(GET_TRANSVERSALVIEW_DATA_ASYNC.dispatchers.started());
        try {
            const filtersAsRequestParameters = yield select(getComputedFiltersMultipleParametersForRequest);
            const transversalViewData = yield call(getTransversalViewData, {
                filtersList: filtersAsRequestParameters,
            });
            yield put(GET_TRANSVERSALVIEW_DATA_ASYNC.dispatchers.succeeded(transversalViewData));
            yield put(OnTransversalSectionToggleActionCreator()); // initial function call
        } catch (error) {
            yield put(addAsyncErrorActionCreator({ error, retryAction: action }));
            yield put(GET_TRANSVERSALVIEW_DATA_ASYNC.dispatchers.failed(error));
        }
    }
}

function* KPIsTransversalViewFunctionSaga(action) {
    //recieve data from check in action.payload
    const kpisBefore = yield select(getKPYSTransversalDataSelector); //take data from Store
    if (!kpisBefore.length) {
        //if I don't have data  i take them from ComputedSelector
        const transversalSectionsIDs = yield select(getTransversalViewDataSectionIdComputedSelector); //select data from computedSelector
        yield put(setTransversalSectionToggleActionCreator(transversalSectionsIDs)); //set KPIs in store
    }
    const kpis = yield select(getKPYSTransversalDataSelector); //read again data from store with KPIs
    const founded = kpis.includes(action.payload); //looking for a KPIs send by check button
    if (!founded && action.payload !== undefined) {
        //if I don't find it , I will add it otherwise I delete it
        yield put(setTransversalSectionToggleActionCreator([...kpis, action.payload]));
    } else yield put(setTransversalSectionToggleActionCreator(kpis.filter(elem => elem !== action.payload)));
}

export default function* transversalViewSaga() {
    yield all([
        yield takeLatest(ON_TRANSVERSAL_SECTIONS_TOGGLE, KPIsTransversalViewFunctionSaga),
        yield takeLatest(GET_TRANSVERSALVIEW_DATA, getTransversalViewDataSaga),
    ]);
}
