import { useEffect, useRef, useState } from 'react';

export function useStackedBarsSpacing(data) {
    const [barsHeight, setBarsHeight] = useState(0);
    const chartRef = useRef();

    useEffect(() => {
        // In the case of stacked bar charts, we need labels at the end of every chart to represent a desired value.
        // On multiple stacked charts, we have slightly different positioning (height of each chart) between them because of a "padding" property applied on the library
        // that padding represents a % from the height and the number of total charts in that given height.
        // Those heights differences between our fixed labels height and barcharts add up on multiple charts and we are in a situation where the labels are missplaced
        // So the function below gets the height between two barcharts, it does not matter which one, because they are rendered equaliy on a given scale ( Y axis in this case)
        if (chartRef && chartRef.current) {
            const calcHorizontalPositioning = () => {
                let parrentChildNodes = chartRef.current?.childNodes;
                let svgElement;
                parrentChildNodes.forEach(el => {
                    if (el.nodeName === 'DIV') {
                        svgElement = el.firstChild;
                    }
                });
                let groupElements = svgElement?.getElementsByTagName('rect');
                const heightBetweenTwoStackedCharts = groupElements[0]?.getBBox()?.y - groupElements[1]?.getBBox()?.y;
                return heightBetweenTwoStackedCharts;
            };
            const heightForLabels = calcHorizontalPositioning();
            setBarsHeight(() => heightForLabels);
        }
    }, [chartRef, data]);

    return [barsHeight, chartRef];
}
