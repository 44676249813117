import moment from 'moment';

function OutputDataProcessingCreator() {
    function processUserListParameters(rawData) {
        let permissionsIDS = [];
        if (rawData.hasOwnProperty('UserType')) {
            permissionsIDS = rawData?.UserType.Permissions.filter(e => e.isChecked).map(e => e.id);
        }

        return {
            dateFrom: rawData.DateFrom ? moment(rawData.DateFrom).format() : null,
            dateTo: rawData.DateTo ? moment(rawData.DateTo).format() : null,
            loginFrom: rawData.LoginFrom === 0 ? -1 : rawData.LoginFrom,
            loginTo: rawData.LoginTo === 0 ? -1 : rawData.LoginTo,
            permissions: permissionsIDS,
        };
    }

    function processUpdateUserParameters(rawData) {
        return {
            userID: rawData.customData.UserID,
            firstName: rawData.inputs.first_name_input.value,
            lastName: rawData.inputs.last_name_input.value,
            email: rawData.inputs.email_input.value,
            permissions: rawData?.customData.UserType.Permissions.filter(el => el.isChecked).map(el => el.id),
        };
    }

    function processCreateUserParameters(rawData) {
        return {
            firstName: rawData.inputs.first_name_input.value,
            lastName: rawData.inputs.last_name_input.value,
            email: rawData.inputs.email_input.value,
            permissions: rawData.customData.UserType.Permissions.filter(el => el.isChecked).map(el => el.id),
        };
    }

    // Return usable functions
    return {
        processUserListParameters,
        processUpdateUserParameters,
        processCreateUserParameters,
    };
}

const OutputProcessingService = OutputDataProcessingCreator();
export default OutputProcessingService;
