export const INPUT_TYPES = {
    SLIDER: 'slider',
    MULTI_CHECKBOX: 'multi',
    MULTI_CHECKBOX_SELECT_ALL: 'multi_select_all',
};

// these are the page names sent to BE to fetch the filters
export const SIDE_FILTERS_SUB_PAGES = {
    adTesterDetailsAndMetrics: 'adTesterDetailsAndMetrics',
    adTesterComparison: 'adTesterComparison',
    preferenceAnalysis: 'bptpreferenceanalysis',
    // add other pages here
};

// add other groups like BPT or survey fieldwork if they include side filters
// these are used to keep the filters for each page in storage/redux
export const SIDE_FILTERS_PAGES = {
    adTester: 'adTester',
    adTesterComparison: 'adTesterComparison',
    brandPreferenceTracker: 'bptpreferenceanalysis',
};
