import { getPropertyValueOrDefault } from '../../../../../utils/generalUtilities';
import { getSectionBasedOnSectionID } from '../../../../../utils/generalUtilities';
import { SECTIONS_IDS } from '../../AdDetailsMetrics/utils/utils';
const get = state => state.adComparison;

export const getAdComparisonDataSelector = state => state.adComparison;

export const getLineChartsDataSelector = state => {
    const sections = getPropertyValueOrDefault(get(state), 'sections', null);
    return getSectionBasedOnSectionID(sections, SECTIONS_IDS.COPY_RATINGS);
};

export const getUpliftsDataSelector = state => {
    const sections = getPropertyValueOrDefault(get(state), 'sections', null);
    return getSectionBasedOnSectionID(sections, SECTIONS_IDS.UPLIFTS);
};

export const getAdTesterCampaignDetailsSelector = state => getAdComparisonDataSelector(state).campaignDetails;

export const getAdsComparisonCampaignDiagnosticsSelector = state => {
    const sections = getPropertyValueOrDefault(get(state), 'sections', null);
    return getSectionBasedOnSectionID(sections, SECTIONS_IDS.CAMPAIGN_DIAGNOSTICS);
};
