import { TRANSLATION_TEXT } from '../../../../../../utils/translations';

export const STATE_KEY = 'UserType';
export const BPT_DEPENDENT_OPTION_ID = 7;
export const TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID = 96;
export const isBPTToggle = 4;
export const isCountry = 3;
export const isDashboard = 2;
export const isNotUsedAnymore = 1;
export const SHOW_NOTIFICATIONS_ON_CODE = [400, 404, 500, 501, 502, 503];

export const DEPENDENT_OPTIONS = [BPT_DEPENDENT_OPTION_ID, TRANSVERSAL_VIEW_DEPENDENT_OPTION_ID];
export const VALIDATION_MESSAGES_ADDITIONS = [
    { type: isCountry, additionalText: TRANSLATION_TEXT.COUNTRY_TEXT },
    { type: isDashboard, additionalText: TRANSLATION_TEXT.DASHBOARD },
    { type: isBPTToggle, additionalText: TRANSLATION_TEXT.DEPENDENT_BPT_TEXT },
];

export const composeAndReturnMessage = txt => TRANSLATION_TEXT.VALIDATION_MESSAGE(txt);
export const getAdditionalText = type => VALIDATION_MESSAGES_ADDITIONS.find(el => el.type === type)?.additionalText;
