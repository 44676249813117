import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_FIELDWORK_OVERVIEW_DATA_ACTION_CREATOR =
    '/pages/fieldworkOverview/GET_FIELDWORK_OVERVIEW_DATA_ACTION_CREATOR';
export const GET_FIELDWORK_OVERVIEW_DATA_ASYNC = asyncActionCreator(
    '/pages/fieldworkOverview/GET_FIELDWORK_OVERVIEW_DATA_ASYNC'
);
export const CHANGE_FIELDWORK_OVERVIEW_SCOPE = '/pages/fieldworkOverview/CHANGE_FIELDWORK_OVERVIEW_SCOPE';

export const getFieldworkOverviewDataActionCreator = () => ({
    type: GET_FIELDWORK_OVERVIEW_DATA_ACTION_CREATOR,
});

export const changeFieldOverviewScope = value => ({
    type: CHANGE_FIELDWORK_OVERVIEW_SCOPE,
    payload: value,
});
