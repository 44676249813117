import React from 'react';
import { Input, CheckboxWrapper, Label, StyledInput, StyledIcon } from './Checkbox.styles';
import PropTypes from 'prop-types';
const Checkbox = ({
    label,
    onClick,
    value,
    isRound,
    textColor,
    hoverColor,
    isTransparent,
    padding,
    labelFontWeight,
    checkedAndApplied,
    isSelectedBold,
    disabled,
}) => {
    return (
        <CheckboxWrapper
            hoverColor={hoverColor}
            padding={padding}
            checkedAndApplied={checkedAndApplied}
            checked={value}
        >
            <Input
                onChange={() => {
                    onClick && onClick();
                }}
                type="checkbox"
                checked={value}
                isRound={isRound}
                checkedAndApplied={checkedAndApplied}
            />
            <StyledInput disabled={disabled} onClick={onClick} checked={value} checkedAndApplied={checkedAndApplied}>
                {<StyledIcon disabled={disabled} checked={value} checkedAndApplied={checkedAndApplied} />}
            </StyledInput>
            <Label
                onClick={() => {
                    onClick && onClick();
                }}
                isTransparent={isTransparent}
                textColor={textColor}
                isSelectedBold={isSelectedBold}
                checked={value}
                labelFontWeight={labelFontWeight}
            >
                {label}
            </Label>
        </CheckboxWrapper>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    isRound: PropTypes.bool,
    textColor: PropTypes.string,
    hoverColor: PropTypes.string,
    isTransparent: PropTypes.bool,
    padding: PropTypes.string,
    labelFontWeight: PropTypes.string,
    checkedAndApplied: PropTypes.bool.isRequired,
    isSelectedBold: PropTypes.bool,
    disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
    checkedAndApplied: true,
    value: false,
};

export default Checkbox;
