import React from 'react';
import { Container } from './SubMenuNavigation.styles';
import { NavItem } from './SubMenuNavigation.styles';
import PropTypes from 'prop-types';
const NavLinkItems = props => {
    const { id, to, name, primaryColor, secondaryColor, activeFlag } = props;
    return (
        <NavItem
            exact={activeFlag}
            key={id}
            to={to}
            activeClassName={'subMenuLinkActive'}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
        >
            <p>{name}</p>
        </NavItem>
    );
};

const SubMenuNavigation = ({ subRouteWithComponents, themeConfig }) => {
    const { primaryColor, secondaryColor } = themeConfig;
    const NavigationItems = Object.keys(subRouteWithComponents).map(key => {
        const { id, path, name, activeFlag } = subRouteWithComponents[key];
        return (
            <NavLinkItems
                key={id}
                id={id}
                to={path}
                activeFlag={activeFlag}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                name={name}
            />
        );
    });
    return <Container>{NavigationItems}</Container>;
};
NavLinkItems.propTypes = {
    id: PropTypes.number.isRequired,
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    activeFlag: PropTypes.bool.isRequired,
};
SubMenuNavigation.propTypes = {
    subRouteWithComponents: PropTypes.object.isRequired,
    themeConfig: PropTypes.object.isRequired,
};
export default SubMenuNavigation;
