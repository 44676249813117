import styled from 'styled-components';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';

export const StackedBarContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

export const GraphBox = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

export const ValueForMoneyChartLabel = styled.div`
    border: 1px solid ${defaultTheme.blue[900]};
    color: ${defaultTheme.blue[900]};
    height: 38px;
    box-sizing: border-box;
    text-align: center;
    padding: 3px 32px;
    line-height: 28px;
    width: max-content;
    font-weight: 600;
    margin: 10px 0px;
    display: flex;
`;

export const LegendBox = styled.div``;

export const LegendItem = styled.div`
    color: ${defaultTheme.blue[900]};
    margin: 14px 14px 8px 0px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    span {
        width: 14px;
        height: 14px;
        display: inline-block;
        background: ${props => props.barColor};
        margin-right: 6px;
    }
`;

export const StackedChartGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const StackedChartsBox = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StackedChartLegend = styled.div`
    display: flex;
`;

export const SectionTitle = styled.div`
    margin-top: 50px;
    color: ${({ theme }) => theme.blue[950]};
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
`;

export const SeparatorLine = styled.hr`
    border-top: 1px solid ${defaultTheme.blue[25]};
    margin: 40px 0 80px 0;
    border-bottom: 0;
    width: 97%;
`;
