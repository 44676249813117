import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import React from 'react';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

export const NavItem = styled(({ primaryColor, secondaryColor, iconOn, icon, ...rest }) => <NavLink {...rest} />)`
    min-width: 232px;
    height: 53px;
    border-radius: 2px;
    margin: 5px 10px 5px 10px;
    border: solid 1px transparent;
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    color: ${({ theme }) => theme.blue[950]};
    text-decoration: none;
    text-align: center;

    &:active {
        color: ${({ primaryColor }) => primaryColor};
        box-shadow: 0px 0px 5px ${({ theme }) => theme.black[29]};
    }

    &:hover {
        color: ${({ primaryColor }) => primaryColor};
        border: solid 1px ${({ secondaryColor }) => secondaryColor};
    }
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
    p {
        padding: 0;
        margin: 14px 0;
        font-size: 18px;

        @media (max-width: 1385px) {
            font-size: 14px;
        }
        @media (max-width: 1045px) {
            margin: 10px 0;
            font-size: 12px;
        }
    }
    &.subMenuLinkActive {
        border: solid 1px ${({ primaryColor }) => primaryColor};
    }
    &.subMenuLinkActive p {
        color: ${({ primaryColor }) => primaryColor};
        font-weight: 600;
    }
    @media (max-width: 1385px) {
        height: auto;
    }
    @media (max-width: 1045px) {
        min-width: 215px;
        height: auto;
    }
`;

export const NavSubmenuItem = styled(NavLink)`
    width: 164px;
    height: 50px;
    border-radius: 1px;
    margin: 5px 10px 5px 10px;
    border: solid 1px transparent;
    background: #f8e4fc;
    color: #a157cc;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
        box-shadow: 0px 0px 5px ${({ theme }) => theme.black[29]};
    }

    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

    p {
        font-size: 16px;
        line-height: 15px;

        @media (max-width: 1385px) {
            font-size: 14px;
        }
        @media (max-width: 1045px) {
            margin: 10px 0;
            font-size: 12px;
        }
    }
    &.subMenuLinkActive {
        background: #a157cc;
        color: #ffffff;
        letter-spacing: 0px;
        box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    }
    @media (max-width: 1385px) {
        height: auto;
    }
    @media (max-width: 1045px) {
        min-width: 162px;
        height: auto;
    }
`;
