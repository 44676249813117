import { asyncActionCreator } from '../../utils/generalUtilities';

export const ON_GLOBAL_OVERVIEW_COUNTRIES_APPLY = '/common/global/ON_GLOBAL_OVERVIEW_COUNTRIES_APPLY';
export const GLOBAL_OVERVIEW_COUNTRIES_SAVE = '/common/global/GLOBAL_OVERVIEW_COUNTRIES_SAVE';
export const APPLY_FILTERS = '/common/global/APPLY_FILTERS';
export const GET_FILTERS = '/common/global/GET_FILTERS';
export const INIT_FILTERS_FOR_SUBTABS = '/common/global/INIT_FILTERS_FOR_SUBTABS';
export const SET_RELATED_PAGE_FOR_FILTERS = '/common/global/SET_RELATED_PAGE_FOR_FILTERS';

export const GET_FILTERS_ASYNC = asyncActionCreator('/common/global/GET_FILTERS_ASYNC');

export const applyFiltersActionCreator = payload => ({ type: APPLY_FILTERS, payload });

export const initFiltersForSubTabs = () => ({ type: INIT_FILTERS_FOR_SUBTABS });

export const onGlobalOverviewSelectedCountriesApplyActionCreator = changedOptions => ({
    type: ON_GLOBAL_OVERVIEW_COUNTRIES_APPLY,
    payload: changedOptions,
});

export const globalOverviewSelectedCountriesSaveActionCreator = countries => ({
    type: GLOBAL_OVERVIEW_COUNTRIES_SAVE,
    payload: countries,
});

export const getFiltersActionCreator = currentPage => ({
    type: GET_FILTERS,
    payload: currentPage,
});

export const setRelatedPageForFiltersActionCreator = page => ({
    type: SET_RELATED_PAGE_FOR_FILTERS,
    payload: page,
});
