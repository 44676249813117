import { createSelector } from 'reselect';
import { getTransversalViewDataSelector } from './selectors';
import { KPI_TYPES } from '../../../../../common/utils/utils';
import { TRANSLATION_TEXT } from './../../../../../utils/translations';

export const getTransversalViewDataSectionIdComputedSelector = createSelector(
    [getTransversalViewDataSelector],
    transversalViewData => {
        if (transversalViewData && transversalViewData.length && transversalViewData[0].hasOwnProperty('sectionLabel'))
            return transversalViewData.map(s => s.sectionId);

        return [];
    }
);

export const getTransversalViewInfoTextComputedSelector = createSelector(
    [getTransversalViewDataSelector],
    transversalViewData => {
        if (transversalViewData && transversalViewData.length && transversalViewData[0].hasOwnProperty('sectionLabel'))
            return transversalViewData[0].countrySections[0].infoText;
        return TRANSLATION_TEXT.INFO_TEXT_ERROR;
    }
);

const outputBarChartFromRawData = rawData => {
    const { itemLabel, values } = rawData;
    /* Add any additional properties in the destructuring if you need more data from the rawData */
    return {
        name: itemLabel,
        values: values.map(({ color, value, label, sigtest }) => ({
            color,
            value,
            group: label,
            sigtest,
        })),
    };
};

export const getTransversalViewDataComputedSelector = createSelector([getTransversalViewDataSelector], data => {
    if (data && data.length > 0 && data[0].hasOwnProperty('sectionLabel')) {
        /* Iterate through all the section */
        return data.map(sectionElement => {
            let sectionData = {};
            /* Add the meta-data data for each section  */
            const { sectionId, sectionLabel, sectionOrder } = sectionElement;
            sectionData = {
                ...sectionData,
                sectionId,
                sectionLabel,
                sectionOrder,
                countries: [],
            };

            /* Iterate through each country of that section in particular */
            sectionElement.countrySections.forEach(country => {
                /* Setup basic object for that country. This object will be used by the components to display the data */
                const countryObject = {
                    overallYear: null,
                    overallQuarter: null,
                    overall: null,
                    countryData: null,
                    trend: null,
                };

                /* Add the data for the country's trend */
                const { countryMetricsText = '', countryMetricsValue = 0 } = country;
                countryObject.trend = {
                    trendText: countryMetricsText,
                    trendValue: countryMetricsValue,
                };

                /* Add the meta-data for the country */
                const { countryFlag = '', countryName } = country;
                countryObject.countryData = {
                    countryFlag,
                    countryName,
                };

                /* Iterate through each kpi of the country and process the data, then save it into an easily to read format */
                country.kpi.forEach(element => {
                    const { kpiID, kpiLabel, kpiDescription, kpiType, items, kpiOrder } = element;

                    /* Meta-Data for the chart */
                    const chartObject = {
                        kpiID,
                        kpiOrder,
                        kpiDescription,
                        kpiLabel,
                        data: null,
                        lineData: null,
                        items: null,
                    };

                    /* Differentiate between the different types of charts */
                    if (kpiType === KPI_TYPES.SIMPLE_BARCHART) {
                        chartObject.data = outputBarChartFromRawData(items[0]);

                        /* Save in different keys based on order */
                        if (kpiOrder === 1) countryObject.overallYear = chartObject;
                        else if (kpiOrder === 2) countryObject.overallQuarter = chartObject;
                    } else if (kpiType === KPI_TYPES.RANKING) {
                        chartObject.items = JSON.parse(JSON.stringify(items));

                        /* TODO : Remove this when we have proper average kpi. Correcting an error from BE data here */
                        const values = chartObject.items[0].values;
                        if (sectionId === 4) values[values.length - 1].type = 'average';

                        countryObject.overall = chartObject;
                        /** The checks for AdRecall are needed because the data format is different in AdRecall section */
                    } else if (kpiType === KPI_TYPES.COMBINED && sectionLabel !== 'AdRecall') {
                        if (items !== null) {
                            chartObject.data = outputBarChartFromRawData(items[0]);
                            if (items[1] !== undefined) {
                                const { itemLabel, values, itemId } = items[1];
                                chartObject.lineData = {
                                    itemId,
                                    color: values[0].color,
                                    name: itemLabel,
                                    values: values.map(v => ({ value: v.value, group: v.label })),
                                };
                            }
                            /* Save in different keys based on order */
                            if (kpiOrder === 1) countryObject.overallYear = chartObject;
                            else if (kpiOrder === 2) countryObject.overallQuarter = chartObject;
                        }
                    } else if (kpiType === KPI_TYPES.COMBINED && sectionLabel === 'AdRecall') {
                        if (items !== null) {
                            chartObject.data = outputBarChartFromRawData(items[0]);
                            const lineDataArray = [];

                            items.forEach(item => {
                                if (item.values !== undefined) {
                                    const { itemLabel, values, itemId } = item;
                                    const auxChartObject = {
                                        itemId,
                                        color: values[0].color,
                                        name: itemLabel,
                                        values: values.map(v => ({ value: v.value, group: v.label })),
                                    };

                                    lineDataArray.push(auxChartObject);
                                }
                            });
                            chartObject.lineData = lineDataArray;
                            /* Save in different keys based on order */
                            if (kpiOrder === 1) countryObject.overallAdRecall = chartObject;
                        }
                    }
                });
                /* Add the processed country data to the pool of countries */
                sectionData.countries.push(countryObject);
            });

            return sectionData;
        });
    }
    return [];
});
