import styled from 'styled-components';

export const UserDropdownPanelWrapper = styled.div`
    width: ${({ isOpen }) => (isOpen ? '180px' : 'auto')};
    box-shadow: ${({ isOpen, theme }) => (isOpen ? ` 0px 0px 6px ${theme.black[29]}` : 'none')};

    @media (max-width: 1280px) {
        font-size: 14px;
    }
`;

export const MainContent = styled.div`
    user-select: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.white.ff};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px 12px 13px;
    box-shadow: ${props => (props.isDashboard ? '0px 0px 4px #00000029' : 'none')};

    @media (max-width: 1280px) {
        padding: 10px 14px 10px 11px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Name = styled.div`
    padding: 0 20px 0 4px;
    color: ${({ theme }) => theme.blue[900]};

    @media (max-width: 1400px) {
        padding: 0 16px 0 4px;
    }
`;

export const Arrow = styled.img`
    padding: ${({ isOpen }) => (isOpen ? ' 18px 8px 14px 8px' : ' 14px 8px 18px 8px')};
    cursor: pointer;
    transform: rotate(${({ isOpen }) => (isOpen ? '0deg' : '180deg')});
`;

export const ProfileImage = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 22px;

    @media (max-width: 1280px) {
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`;

export const HiddenWrapper = styled.div`
    background-color: ${({ theme }) => theme.white.ff};
    padding: 3px 0;

    @media (max-width: 1280px) {
        padding: 3px 0;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 18px;

    :hover {
        background-color: ${({ theme }) => theme.grey[50]};
    }

    img {
        padding: 1px 1px 0 0;
    }

    a {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.blue[900]};
        text-decoration: none;

        span {
            padding: 0 8px;
        }
    }
`;

export const Divider = styled.div`
    height: 1px;
    background: ${({ theme }) => theme.grey[200]};
    margin: 6px;
`;
