import { basePath } from '../../../../services/utils';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
/*TODO : change the video and image link to proper one after getting the data */

export const usageOfThisToolSections = [
    {
        id: 1,
        title: 'Tool overview',
        icon: `${basePath}/visuals/use_this_tool.svg`,
        videoSource: 'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_2mb.mp4',
        poster: `${basePath}/visuals/AXA-WIP-Image.png`,
        documentLink: '',
        isActive: true,
    },
    {
        id: 2,
        title: 'Adtester',
        icon: `${basePath}/visuals/use_this_tool.svg`,
        videoSource: 'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_2mb.mp4',
        poster: `${basePath}/visuals/AXA-WIP-Image.png`,
        documentLink: '',
        isActive: false,
    },
    {
        id: 3,
        title: 'Brand Preference Tracker',
        icon: `${basePath}/visuals/use_this_tool.svg`,
        videoSource: 'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_2mb.mp4',
        poster: `${basePath}/visuals/AXA-WIP-Image.png`,
        documentLink: '',
        isActive: false,
    },
    {
        id: 4,
        title: 'Filter & compare',
        icon: `${basePath}/visuals/use_this_tool.svg`,
        videoSource: 'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_2mb.mp4',
        poster: `${basePath}/visuals/AXA-WIP-Image.png`,
        documentLink: '',
        isActive: false,
    },
    {
        id: 5,
        title: 'Online Dashboard User Guide',
        icon: `${basePath}/visuals/use_this_tool_second.svg`,
        videoSource: '',
        poster: `${basePath}/visuals/AXA-WIP-Image.png`,
        documentLink: 'https://sample-videos.com/text/Sample-text-file-10kb.txt',
        isActive: false,
    },
];

export const toolBoxUtilities = [
    {
        title: TRANSLATION_TEXT.TEMPLATE_ADTESTER_QUESTIONNAIRE_TEXT,
        path: `${basePath}/toolboxDocuments/Template%20AdTester_1%20copy.pdf`,
        icon: `${basePath}/visuals/open_pdf.png`,
        id: 1,
    },
    {
        title: TRANSLATION_TEXT.AXA_BPT_TEXT,
        path: `${basePath}/toolboxDocuments/Axa%20BPT%20Value%20For%20Money%20matrix.pdf`,
        icon: `${basePath}/visuals/open_pdf.png`,
        id: 2,
    },
    {
        title: TRANSLATION_TEXT.AXA_BPT_METHODOLOGY_TEXT,
        path: `${basePath}/toolboxDocuments/AXA%20BPT%20Methodology%20deck%20June%202021.pdf`,
        icon: `${basePath}/visuals/open_pdf.png`,
        id: 3,
    },
];
