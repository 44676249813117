import { asyncActionCreator } from '../../../../../utils/generalUtilities';

export const GET_TRANSVERSALVIEW_DATA = '/pages/transversalView/GET_TRANSVERSALVIEW_DATA';
export const GET_TRANSVERSALVIEW_DATA_ASYNC = asyncActionCreator(
    '/pages/transversalView/GET_TRANSVERSALVIEW_DATA_ASYNC'
);
export const getTransversalViewActionCreator = () => ({
    type: GET_TRANSVERSALVIEW_DATA,
});

export const ON_TRANSVERSAL_SECTIONS_TOGGLE = '/pages/transversalView/ON_TRANSVERSAL_SECTIONS_TOGGLE';
export const SET_ON_TRANSVERSAL_SECTIONS_TOGGLE = '/pages/transversalView/SET_ON_TRANSVERSAL_SECTIONS_TOGGLE';

export const setTransversalSectionToggleActionCreator = payload => ({
    type: SET_ON_TRANSVERSAL_SECTIONS_TOGGLE,
    payload: payload,
});
export const OnTransversalSectionToggleActionCreator = payload => ({
    type: ON_TRANSVERSAL_SECTIONS_TOGGLE,
    payload: payload,
});
