import styled from 'styled-components';

export const CopyRatingsWrapper = styled.div`
    margin: 30px 0px;
    overflow-x: auto;
`;

export const SVG = styled.svg`
    height: 0px;
    .filtered-lines-pattern {
        fill: ${({ theme }) => theme.white.ff};
    }
    .filtered-lines-b {
        fill: ${({ theme }) => theme.blue[100]};
    }
    .filtered-lines-b,
    .filtered-lines-e,
    .filtered-lines-f {
        stroke: ${({ theme }) => theme.blue[600]};
        stroke-linecap: round;
    }
    .filtered-lines-c {
        opacity: 0.2;
        clip-path: url(#a);
    }
    .filtered-lines-d {
        isolation: isolate;
    }
    .filtered-lines-e,
    .filtered-lines-f,
    .filtered-lines-h {
        fill: none;
    }
    .filtered-lines-e {
        stroke-linejoin: round;
    }
    .filtered-lines-e,
    .filtered-lines-f {
        stroke-dasharray: 4 3 6;
    }
    .filtered-lines-g {
        stroke: none;
    }
`;
