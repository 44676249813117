import styled from 'styled-components';
import { MEDIA_QUERY_SCREEN_SIZE } from '../../utils/responsiveness';

const UserManagementPageWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    box-shadow: 0px 0px 6px ${({ theme }) => theme.black[29]};
    opacity: 1;
    position: relative;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.white.ff};
    padding: 40px 0;

    // Selecting the second div that has no class and his child which is the 'Loading wrapper'
    // And we set left to 0, otherwise, the layout has left margin from the 'margin auto' above
    // which has been set due to the width constrains;
    div:not([class]):nth-child(2) div {
        left: 0 !important;
    }

    @media screen and (max-width: ${MEDIA_QUERY_SCREEN_SIZE.SMALL}px) {
        min-width: auto;
        width: auto;
        max-width: 100%;
    }
`;

const UserManagementPageTitle = styled.label`
    margin: 0 2.5%;
    text-transform: uppercase;
    font-size: 16px;
    color: ${({ theme }) => theme.blue[900]};
`;

const UserManagementSearchInputWrapper = styled.div`
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding: 3px 0px;
    height: 35px;
    border: 6px solid rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 0px 4px;
    border-radius: 2px;
`;

export { UserManagementPageWrapper, UserManagementPageTitle, UserManagementSearchInputWrapper };
