import React from 'react';
import * as SC from '../../common/General.styles';
import TransversalView from './subpages/TransversalView/TransversalView.container';

export default function TransversalViewPage() {
    return (
        <SC.PageMainWrapper>
            <SC.PageWrapper>
                <div>
                    <TransversalView />
                </div>
            </SC.PageWrapper>
        </SC.PageMainWrapper>
    );
}
