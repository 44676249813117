import { GET_COMPETITIVE_MEDIA_DATA_ASYNC } from './actions';

const competitiveMediaReducer = (state = null, action) => {
    switch (action.type) {
        case GET_COMPETITIVE_MEDIA_DATA_ASYNC.actions.SUCCEEDED:
            return action.payload;
        default:
            return state;
    }
};

export default competitiveMediaReducer;
