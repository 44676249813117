import React from 'react';
import { HomeSectionWrapper, Header, Content, OuterContent } from './HomeSection.styles';

const HomeSection = props => {
    const {
        icon,
        sectionTitle,
        sectionID,
        color,
        bgColor,
        innerIcons,
        sectionImage,
        paragraphs,
        outerElements,
        isImageOnRight,
    } = props;
    return (
        <HomeSectionWrapper id={sectionID}>
            <Header color={color} bgColor={bgColor}>
                <img src={icon} alt="Section Icon" />
                <h3>{sectionTitle}</h3>
            </Header>
            <Content isImageOnRight={isImageOnRight}>
                {sectionImage && <img src={sectionImage} alt={sectionTitle} />}
                <div>
                    {paragraphs.map((text, index) => (
                        <p key={index}>{text}</p>
                    ))}
                </div>
            </Content>
            <OuterContent innerIcons={innerIcons}>{outerElements}</OuterContent>
        </HomeSectionWrapper>
    );
};

export default HomeSection;
