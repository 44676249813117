import React, { useEffect, useMemo } from 'react';
import SectionsHead from '../../../../../../../common/components/SectionsHead/SectionsHead';
import TopLegend from '../../../../../../../common/components/TopLegend/TopLegend';
import EvolutionLegend from '../../../components/EvolutionLegend/EvolutionLegend';
import * as SC_PA from '../../../PreferanceAnalysis.styles';
import CombinedBarLineChart from '../../../../../../../common/components/CombinedBarLineChart/CombinedBarLineChart';
import RankingTable from '../../../components/RankingTable/RankingTable';
import * as SC from './Image.styles';
import { useCombinedLOBS } from '../../hooks/useCombinedLOBS';
import { DataTable } from '@toluna-ui-toolkit/data-table';
import { defaultTheme } from '../../../../../../../utils/defaultTheme';
import * as TabLayout from '../../Tabs.styles';
import { CUSTOM_LINECHART_WIDTH_PAGE_FLAG, sectionsHeadColor } from '../../utils/utils';
import NoDataAvailable from '../../../../../../../common/components/NoDataAvailable/NoDataAvailable';
import Legend from '../../../../../../../common/components/Legend/Legend';

const ImageTab = ({
    imageSectionIDs,
    combinedChartsData,
    lobsAsOptions,
    combinedBarLineChartLegend,
    rankingTableData,
    imageData,
    hasTopFilterOptionLoaded,
    lineChartData,
    getBptPreferenceAnalysisImageActionCreator,
    showTableOnSection,
    tableHeaderAndData,
    lineChartDescription,
    filtersThrownError,
    appliedFilters,
}) => {
    const { tableHeader, tableData } = tableHeaderAndData;
    const [combinedChartLOBs, , getVisibleLOBs] = useCombinedLOBS({
        sectionIDS: imageSectionIDs,
        lobsAsOptions,
    });
    useEffect(() => {
        if (hasTopFilterOptionLoaded) getBptPreferenceAnalysisImageActionCreator();
    }, [hasTopFilterOptionLoaded]);

    const imageDataLoaded = () =>
        imageData && imageData.hasOwnProperty('sections') && imageData.sections && !filtersThrownError;
    const getLineChartLOBS = (sectionID, index) => {
        return lineChartData[sectionID][index]?.lineData.map(line => line.name);
    };

    const renderLegendForTableIndex = 0;

    const tableHeaderMemoized = useMemo(() => {
        if (tableHeader && tableHeader.length) {
            return [...tableHeader];
        }
        return [];
    }, [tableData]);

    const TABLE_STYLE = {
        global: {
            fontFamily: 'Source Sans Pro,Open Sans',
        },
        tableHead: {
            backgroundColor: 'null',
            borderBottom: `2px solid ${defaultTheme.blue[750]}`,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            fontSize: '8px',
            color: defaultTheme.blue[750],
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',
        },
        bodyRow: {
            activeColor: null,
            backgroundColor: defaultTheme.white.ff,
            backgroundColorSecondary: defaultTheme.grey[50],
            hoverColor: defaultTheme.blue[50],
            borderTop: 'none',
        },
        column: { separatorColor: 'none' },
    };

    const hasSideFiltersApplied = appliedFilters && appliedFilters?.length > 0;

    return (
        <>
            {!filtersThrownError && <TopLegend />}
            {!filtersThrownError && hasSideFiltersApplied && <EvolutionLegend />}
            <NoDataAvailable show={!imageDataLoaded() && !filtersThrownError} />

            {imageDataLoaded() &&
                imageData.sections.map(({ sectionID, sectionLabel }) => (
                    <SectionsHead
                        key={sectionID}
                        title={sectionLabel}
                        isResponsive={false}
                        customStyle={sectionsHeadColor}
                    >
                        {combinedChartsData && combinedChartLOBs && (
                            <TabLayout.SectionRow style={{ margin: sectionID === 1 ? '70px 0px 0px 0px' : '0px' }}>
                                <TabLayout.SectionItem>
                                    <SC.CombinedBarLineChartWrapper>
                                        <SC_PA.CombinedChartsWrapper>
                                            {combinedChartsData[sectionID] &&
                                                combinedChartsData[sectionID].map((d, index) => (
                                                    <SC.CombinedBarLineChartWrapper
                                                        key={sectionID + index}
                                                        data-testid={`Image-Tab-CombinedBarLineChart-${sectionID}`}
                                                    >
                                                        <CombinedBarLineChart
                                                            visibleLOBs={getVisibleLOBs(sectionID)}
                                                            lineData={d.lineData}
                                                            barData={d.barData}
                                                            numberOfRespondents={d?.numberOfRespondents || []}
                                                            customLineTooltip={{
                                                                tooltipFontColor: defaultTheme.blue[900],
                                                            }}
                                                        />
                                                        <>
                                                            {combinedBarLineChartLegend &&
                                                                index === renderLegendForTableIndex && (
                                                                    <Legend
                                                                        customStyle={{
                                                                            _wrapper: { marginLeft: '20px' },
                                                                            _individualItem: { marginRight: '20px' },
                                                                        }}
                                                                        displayDirection={'row'}
                                                                        legendItems={
                                                                            combinedBarLineChartLegend[sectionID]
                                                                        }
                                                                    />
                                                                )}
                                                        </>
                                                    </SC.CombinedBarLineChartWrapper>
                                                ))}
                                        </SC_PA.CombinedChartsWrapper>
                                    </SC.CombinedBarLineChartWrapper>
                                </TabLayout.SectionItem>

                                {rankingTableData[sectionID] && rankingTableData[sectionID].length > 0 && (
                                    <TabLayout.SectionItem data-testid={'Image-Tab-RankingTable'}>
                                        <RankingTable
                                            rankingData={[rankingTableData[sectionID][0]]}
                                            lobOptions={[]}
                                            styleObject={{ margin: '-45px 0px 40px 20px' }}
                                            hasNumberOfRespondents={true}
                                            hasSideFiltersApplied={hasSideFiltersApplied}
                                        />
                                    </TabLayout.SectionItem>
                                )}
                            </TabLayout.SectionRow>
                        )}
                        {lineChartData && (
                            <TabLayout.SectionRow>
                                <TabLayout.SectionItem style={{ justifyContent: 'flex-start' }}>
                                    <SC_PA.CombinedChartsWrapper
                                        style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}
                                    >
                                        {lineChartData[sectionID]?.map((d, index) => (
                                            <SC.LineChartWrapper key={sectionID + index}>
                                                {sectionID === 4 && (
                                                    <SC.LineChartDescription>
                                                        {lineChartDescription[sectionID][index]}
                                                    </SC.LineChartDescription>
                                                )}
                                                <SC.InnerLineChartWrapper
                                                    data-testid={`Image-Tab-LineChart-${sectionID}`}
                                                >
                                                    <CombinedBarLineChart
                                                        visibleLOBs={getLineChartLOBS(sectionID, index)}
                                                        lineData={d.lineData}
                                                        barData={[]}
                                                        numberOfRespondents={
                                                            hasSideFiltersApplied ? d?.numberOfRespondents : []
                                                        }
                                                        customWidthOnPage={CUSTOM_LINECHART_WIDTH_PAGE_FLAG.IMAGE}
                                                    />

                                                    {combinedBarLineChartLegend && index % 2 === 0 && (
                                                        <Legend
                                                            customStyle={{
                                                                _wrapper: { margin: '20px 0 20px 20px' },
                                                                _individualItem: { marginRight: '20px' },
                                                            }}
                                                            displayDirection={'row'}
                                                            legendItems={combinedBarLineChartLegend[sectionID]}
                                                        />
                                                    )}
                                                </SC.InnerLineChartWrapper>
                                            </SC.LineChartWrapper>
                                        ))}
                                    </SC_PA.CombinedChartsWrapper>
                                </TabLayout.SectionItem>
                            </TabLayout.SectionRow>
                        )}
                        {showTableOnSection && showTableOnSection === sectionID && (
                            <TabLayout.SectionRow>
                                <TabLayout.SectionItem>
                                    <SC.TableWrapper>
                                        <DataTable
                                            data={tableData}
                                            columns={tableHeaderMemoized}
                                            isScrollable={false}
                                            isPaginated={false}
                                            styles={TABLE_STYLE}
                                        />
                                    </SC.TableWrapper>
                                </TabLayout.SectionItem>
                            </TabLayout.SectionRow>
                        )}
                    </SectionsHead>
                ))}
        </>
    );
};

export default ImageTab;
