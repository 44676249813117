import React from 'react';
import { Input, RoundCheckboxWrapper, Label, StyledInput, StyledIcon } from './RoundCheckbox.styles';
import PropTypes from 'prop-types';
const RoundCheckbox = ({
    label,
    onClick,
    value,
    isRound,
    textColor,
    hoverColor,
    isTransparent,
    padding,
    labelFontWeight,
    checkedAndApplied,
    isSelectedBold,
    disabled,
}) => {
    return (
        <RoundCheckboxWrapper
            hoverColor={hoverColor}
            padding={padding}
            checkedAndApplied={checkedAndApplied}
            checked={value}
            disabled={disabled}
        >
            <Input type="radio" defaultChecked={value} checkedAndApplied={checkedAndApplied} />
            <StyledInput
                data-testid="RadioCheckbox StyledInput"
                disabled={disabled}
                onClick={onClick}
                checked={value}
                checkedAndApplied={checkedAndApplied}
            >
                <StyledIcon disabled={disabled} checked={value} checkedAndApplied={checkedAndApplied} />
            </StyledInput>
            <Label
                data-testid="RadioCheckbox Label"
                onClick={() => {
                    onClick && onClick();
                }}
                isTransparent={isTransparent}
                textColor={textColor}
                isSelectedBold={isSelectedBold}
                checked={value}
                labelFontWeight={labelFontWeight}
            >
                {label}
            </Label>
        </RoundCheckboxWrapper>
    );
};

RoundCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    isRound: PropTypes.bool,
    textColor: PropTypes.string,
    hoverColor: PropTypes.string,
    isTransparent: PropTypes.bool,
    padding: PropTypes.string,
    labelFontWeight: PropTypes.string,
    checkedAndApplied: PropTypes.bool.isRequired,
    isSelectedBold: PropTypes.bool,
    disabled: PropTypes.bool,
};
RoundCheckbox.defaultProps = {
    checkedAndApplied: true,
    value: false,
};

export default RoundCheckbox;
