import styled from 'styled-components';

const CombinedBarLineChartWrapper = styled.div``;

const CombinedBarLineChartTitle = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    color: ${props => props.theme.blue[900]};
`;

const TableWrapper = styled.div`
    width: 100%;
    div[role='table'] {
        border: none;
        box-shadow: none;
    }

    div [role='row'] {
        align-items: center;
    }

    div[role='cell'] {
        //Each row cell
        padding-top: 20px;
        padding-bottom: 20px;
        &:not(:first-of-type) {
            //All row cells except the first column
            span {
                width: 30px;
                text-align: center;
            }
        }
    }
`;

const LineChartDescription = styled.div`
    color: ${props => props.theme.blue[900]};
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    padding: 0 0 15px;
`;
const InnerLineChartWrapper = styled.div``;

const LineChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
export {
    CombinedBarLineChartWrapper,
    CombinedBarLineChartTitle,
    TableWrapper,
    InnerLineChartWrapper,
    LineChartDescription,
    LineChartWrapper,
};
