export const ADD_ASYNC_ERROR = '/@@/errorHandling/ADD_ASYNC_ERROR';
export const TOKEN_EXPIRED = '/@@/errorHandling/TOKEN_EXPIRED';
export const SET_NOTIFICATION_ERROR_DISPLAY = '/@@/errorHandling/SET_NOTIFICATION_ERROR_DISPLAY';

export const addAsyncErrorActionCreator = error => ({
    type: ADD_ASYNC_ERROR,
    payload: error,
});

export const tokenExpiredActionCreator = failedRequestAction => ({
    type: TOKEN_EXPIRED,
    payload: failedRequestAction,
});

export const setErrorNotificationDisplayActionCreator = display => ({
    type: SET_NOTIFICATION_ERROR_DISPLAY,
    payload: display,
});
