import React from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from '@toluna-ui-toolkit/theme';
import { InputCheckBox, StyledCheckBox, StyledIcon, TextLabel } from './CheckBox.styles';

export const CheckBox = React.forwardRef((props, ref) => {
    const {
        checked,
        onChange,
        customStyle,
        customIcon,
        disabled,
        name,
        label,
        enableKeyboardNavigation,
        ...rest
    } = props;

    const onClick = () => {
        !disabled && onChange && onChange();
    };

    const handleKeyDown = event => {
        if (enableKeyboardNavigation && !disabled) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                onClick();
            }
        }
    };

    return (
        <ThemeConsumer>
            {value => {
                return (
                    <StyledCheckBox customStyle={customStyle} {...rest}>
                        {/*
                        Adding empty onChange function because of React warning
                        Warning: Failed prop type: You provided a `checked` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultChecked`. Otherwise, set either `onChange` or `readOnly`.
                        */}
                        <InputCheckBox
                            type={'checkbox'}
                            ref={ref}
                            name={name}
                            checked={checked}
                            onChange={() => {}}
                            value={checked}
                        />
                        <StyledIcon
                            theme={value}
                            checked={checked}
                            disabled={disabled}
                            onClick={onClick}
                            enableKeyboardNavigation={enableKeyboardNavigation}
                            tabIndex={enableKeyboardNavigation && !disabled ? '0' : '-1'}
                            onKeyDown={handleKeyDown}
                            customStyle={customStyle}
                        >
                            {customIcon || <i className="fas fa-check" />}
                        </StyledIcon>
                        <TextLabel onClick={onClick} disabled={disabled} customStyle={customStyle}>
                            {label}
                        </TextLabel>
                    </StyledCheckBox>
                );
            }}
        </ThemeConsumer>
    );
});

CheckBox.propTypes = {
    /** Is checked */
    checked: PropTypes.bool,
    /** Is disabled */
    disabled: PropTypes.bool,
    /** custom style */
    customStyle: PropTypes.object,
    /** on change function */
    onChange: PropTypes.func,
    /** Checkbox label */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /** Custom icon */
    customIcon: PropTypes.node,
};

CheckBox.defaultProps = {
    checked: false,
    disabled: false,
    label: '',
};
