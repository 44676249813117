import React from 'react';
import { TRANSLATION_TEXT } from '../../../../utils/translations';
import { ClearAllButton, SelectedFilter, SelectedFiltersWrapper } from './SelectedFilters.styles';
import PropTypes from 'prop-types';
const SelectedFilters = ({
    appliedFilters,
    clearAllSelectedFiltersActionCreator,
    clearSingleSelectedFilterActionCreator,
}) => {
    if (!appliedFilters || !appliedFilters?.length) return null;

    return (
        <SelectedFiltersWrapper data-testid="SelectedFilters-wrapper">
            <span>
                {appliedFilters[0].groupName}
                {': '}
            </span>
            {appliedFilters.map((filter, i) => (
                <SelectedFilter key={i + filter.filterText}>
                    <span>{filter.filterText}</span>
                    <button onClick={() => clearSingleSelectedFilterActionCreator(filter)}>X</button>
                </SelectedFilter>
            ))}

            <ClearAllButton onClick={clearAllSelectedFiltersActionCreator}>
                {TRANSLATION_TEXT.SIDE_FILTERS_CLEAR_TEXT}
            </ClearAllButton>
        </SelectedFiltersWrapper>
    );
};
SelectedFilter.propTypes = {
    appliedFilters: PropTypes.array.isRequired,
    clearAllSelectedFiltersActionCreator: PropTypes.func.isRequired,
    clearSingleSelectedFilterActionCreator: PropTypes.func.isRequired,
};

SelectedFilter.defaultProps = {
    appliedFilters: [],
    clearAllSelectedFiltersActionCreator: () => {},
    clearSingleSelectedFilterActionCreator: () => {},
};
export default SelectedFilters;
