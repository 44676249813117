import styled from 'styled-components';

export const Wrapper = styled.div``;

export const FiltersSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 25px;
`;
export const FilterTitle = styled.div`
    text-align: left;
    border-bottom: solid 1px ${({ theme }) => theme.grey[200]};
    box-shadow: 0px 5px 5px -5px ${({ theme }) => theme.grey[200]};
    p {
        padding: 10px;
        margin: 0;
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
export const FilterInner = styled.div`
    overflow-y: scroll;
    height: 230px;
    padding: 2px 8px;
    position: relative;
    p {
        color: ${({ theme }) => theme.blue[950]};
        font-style: italic;
        padding: 0;
        margin: 100px 0 0 0;
        position: absolute;
        top: 0;
        width: 160px;
    }
`;

export const FiltersOuter = styled.div`
    box-shadow: 0px 0px 8px ${({ theme }) => theme.black[29]};
    height: 280px;
    width: 215px;
    position: relative;
    &:after {
        content: '';
        width: 85%;
        z-index: 10;
        height: 1;
        position: absolute;
        box-shadow: 8px -1px 10px 3px white;
        bottom: 2px;
    }
`;

export const FiltersValidation = styled.div`
    p {
        text-align: center;
        padding: 0;
        margin: 5px 0 0 20px;
        font-family: Source Sans Pro, Open Sans;
        font-size: 14px;
        color: ${({ theme }) => theme.red[500]};
    }
`;
