import styled, { keyframes } from 'styled-components';

const unfold = keyframes`
    0% {
      transform:scaleY(0.005) scaleX(0);
    }
    50% {
      transform:scaleY(0.005) scaleX(1);
    }
    100% {
      transform:scaleY(1) scaleX(1);
    }
`;

const zoom = keyframes`
    0% {
      transform:scale(0);
    }
    100% {
      transform:scale(1);
    }
`;

const BlurBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 150;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    background-color: ${({ theme }) => theme.blue['400-4d']};
    transform: scale(0);
    animation: ${unfold} 1s cubic-bezier(0.15, 0.85, 0.45, 1) forwards;
    animation-timing-function: linear;
`;

const VideoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 15px;
    transform: scale(0);
    animation: ${zoom} 0.5s 0.85s cubic-bezier(0.15, 0.85, 0.45, 1) forwards;
    animation-timing-function: linear;
`;

const VideoCloseButton = styled.img`
    background-color: ${({ theme }) => theme.blue[950]};
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
`;

const MissingVideoImg = styled.img`
    width: 1200px;
`;

export { BlurBackground, VideoWrapper, VideoCloseButton, MissingVideoImg };
