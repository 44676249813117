import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container as HeaderContainer } from '../common/components/Header/Header.styles';
import { Container as SubMenuContainerAdTester } from '../common/components/Navigation/SubMenuNavigation.styles';
import { LandingPageWrapper, ReportOptionsWrapper } from '../pages/landing/LandingPage.styles';
import { useLocation, useHistory } from 'react-router-dom';
import { HiddenWrapper } from '../pages/landing/components/UserDropdownPanel/UserDropdownPanel.styles';

export const DisabledComponentsWrapper = styled.div`
    /* Landing page */
    ${ReportOptionsWrapper} {
        a[href='/Dashboard/FilterSegmentLibrary'] {
            display: none;
        }
    }
    ${LandingPageWrapper} {
        #fls-landing {
            display: none;
        }
    }
    /* Header */
    ${HeaderContainer} {
        div {
            a[href='/Dashboard/FilterSegmentLibrary'] {
                display: none;
            }
        }
    }
    /* Sub-menu Ad Tester*/
    ${SubMenuContainerAdTester} {
        a[href='/Dashboard/BrandPreferenceTracker'] {
            display: none;
        }
        a[href='/Dashboard/BrandPreferenceTracker/PreferenceAnalysis'] {
            display: none;
        }
        a[href='/Dashboard/BrandPreferenceTracker/TopLines'] {
            display: none;
        }
        a[href='/Dashboard/BrandPreferenceTracker/FlatFile'] {
            display: none;
        }
    }

    /* User Menu. NOTE HERE: when these items will be visible, please add display:flex instead of display:block, otherwise it won't be vertically centered. */
    ${HiddenWrapper} {
        .adminSectionButton {
            //The admin btn
            display: flex;
        }
        .backofficeSectionButton {
            //The backoffice btn
            display: flex;
        }
    }
`;

const DisabledComponents = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        location.pathname === '/Dashboard/BrandPreferenceTracker' &&
            history.replace('/Dashboard/BrandPreferenceTracker/PreferenceAnalysis');
    }, [location]);
    return <DisabledComponentsWrapper>{children}</DisabledComponentsWrapper>;
};

export default DisabledComponents;
