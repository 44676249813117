import { connect } from "react-redux";
import { getEmailRecipientSelector } from "../../data/selectors";
import EmailSent from "./EmailSent";

const mapStateToProps = (state) => ({
	emailRecipient: getEmailRecipientSelector(state),
});

const EmailSentContainer = connect(mapStateToProps)(EmailSent);

export default EmailSentContainer;
