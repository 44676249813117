import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalWrapper,
    CloseButton,
    TextWrapper,
    Text,
    TextBorder,
    Title,
    PrimaryButton,
    SecondaryButton,
    ButtonWrapper,
    BlurBackground,
} from './ReusableModal.styles';
import { basePath } from '../../../services/utils';

const ReusableModal = ({
    title,
    text,
    yesText,
    cancelText,
    onApply,
    onClose,
    singleButton,
    modalHeight,
    modalWidth,
    textBackdrop,
    titleColor,
    modalWrapperRef,
    isAbleToDismiss,
    titleAsHeader,
    customStyle,
}) => {
    return (
        <BlurBackground data-testid="ReusableModal-blur">
            <ModalWrapper
                data-testid="ReusableModal-wrapper"
                ref={modalWrapperRef}
                modalHeight={modalHeight}
                modalWidth={modalWidth}
                {...customStyle}
            >
                <CloseButton
                    data-testid="ReusableModal-closeBtn"
                    isAbleToDismiss={isAbleToDismiss}
                    onClick={onClose}
                    {...customStyle}
                >
                    <img src={`${basePath}/visuals/close-icon.svg`} alt="X" />
                </CloseButton>
                <TextWrapper data-testid="ReusableModal-textWrapper" textBackdrop={textBackdrop} {...customStyle}>
                    <Title
                        data-testid="ReusableModal-title"
                        titleAsHeader={titleAsHeader}
                        titleColor={titleColor}
                        {...customStyle}
                    >
                        {title}
                    </Title>
                    <Text data-testid="ReusableModal-text" {...customStyle}>
                        {text}
                    </Text>
                    <TextBorder modalHeight={modalHeight} titleAsHeader={titleAsHeader} />
                </TextWrapper>
                <ButtonWrapper {...customStyle}>
                    <SecondaryButton
                        data-testid="ReusableModal-secondBtn"
                        onClick={onClose}
                        singleButton={singleButton}
                        {...customStyle}
                    >
                        {cancelText}
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="ReusableModal-primaryBtn"
                        onClick={onApply}
                        singleButton={singleButton}
                        {...customStyle}
                    >
                        {yesText}
                    </PrimaryButton>
                </ButtonWrapper>
            </ModalWrapper>
        </BlurBackground>
    );
};
export default ReusableModal;

ReusableModal.propTypes = {
    /* Modal title */
    title: PropTypes.string,
    /* Title as a header */
    titleAsHeader: PropTypes.bool,
    /* Modal text */
    text: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
    /* OK/Yes button text */
    yesText: PropTypes.string,
    /* Cancel button text */
    cancelText: PropTypes.string,
    /* OnClick OK button  */
    onApply: PropTypes.func,
    /* OnClick Cancel buttin*/
    onClose: PropTypes.func,
    /* Show OK/Yes button or both Cancel and OK/Yes buttons  */
    singleButton: PropTypes.bool,
    /* Height of the modal */
    modalHeight: PropTypes.string,
    /* Width of the modal */
    modalWidth: PropTypes.string,
    /* Color of the title */
    titleColor: PropTypes.string,
    /* Modal ref*/
    modalWrapperRef: PropTypes.object,
    /* Close Buton */
    isAbleToDismiss: PropTypes.bool,
    /* Backdrop */
    textBackdrop: PropTypes.bool,
    /* Custom style */
    customStyle: PropTypes.object,
};
