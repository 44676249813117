import styled from 'styled-components';

export const SearchInputIcon = styled.div`
    width: 20%;
    cursor: ${props => (props.isForTable ? `default` : 'pointer')};
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
`;
export const SearchInputWrapper = styled.div`
    box-sizing: border-box;
    padding: ${props => (props.padding ? props.padding : '0')};
    border-bottom: ${props => (props.hasBorder ? `2px solid ${props.theme.grey[200]}` : 'none')};
    align-items: center;
    width: 100%;
    height: ${props => (props.height ? props.height : '22%')};
    display: flex;
    flex-direction: row;

    div,
    input {
        background-color: ${props => (props.isActive ? props.bgColor : props.theme.grey[50])};
        height: 40px;
        transition: background-color 0.2s;
    }

    &:hover {
        div,
        input {
            background-color: ${props => props.bgColor};
        }
    }
`;

export const Input = styled.input`
    width: 80%;
    border: none;
    padding: ${props => props.textPadding};
    box-sizing: border-box;
    font-size: ${props => props.fontSize};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
    color: ${props => (props.textColor ? props.textColor : props.theme.red[500])};

    &:focus {
        outline: none;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
        opacity: ${props => (props.opacity ? props.opacity : '1')}; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${props => (props.textColor ? props.textColor : props.theme.black.ff)};
    }
`;
