import { all } from 'redux-saga/effects';
import loginPageSaga from '../pages/login/data/saga';
import adTesterOverviewSaga from '../pages/adTester/subpages/Overview/data/saga';
import errorHandlingSaga from '../errorHandling/data/saga';
import filtersSaga from '../common/components/SideFilters/data/saga';
import adDetailsMetricsSaga from '../pages/adTester/subpages/AdDetailsMetrics/data/saga';
import adComparisonSaga from '../pages/adTester/subpages/AdsComparison/data/saga';
import selectedFiltersSaga from '../common/components/SelectedFilters/data/saga';
import surveyFieldworkOverviewSaga from '../pages/surveyFieldwork/subpages/Overview/data/saga';
import bptPreferenceAnalysisSaga from '../pages/brandPreferenceTracker/subpages/PreferenceAnalysis/data/saga';
import topFiltersSaga from '../common/components/TopFilter/data/saga';
import exportDataSaga from '../common/components/ExportButton/data/saga';
import globalOverviewSelectedCountriesSaga from '../common/data/saga';
import backOfficeFileUploadSaga from '../pages/backOffice/data/saga';
import userWatcherSaga from '../common/hooks/data/saga';
import competitiveMediaSaga from '../pages/competitiveMedia/subpages/CompetitiveMedia/data/saga';
import landingPageSaga from '../pages/landing/data/saga';
import transversalViewSaga from '../pages/transversalView/subpages/TransversalView/data/saga';

export default function* saga() {
    yield all([
        loginPageSaga(),
        errorHandlingSaga(),
        adTesterOverviewSaga(),
        filtersSaga(),
        adDetailsMetricsSaga(),
        adComparisonSaga(),
        selectedFiltersSaga(),
        surveyFieldworkOverviewSaga(),
        topFiltersSaga(),
        exportDataSaga(),
        globalOverviewSelectedCountriesSaga(),
        bptPreferenceAnalysisSaga(),
        backOfficeFileUploadSaga(),
        userWatcherSaga(),
        competitiveMediaSaga(),
        landingPageSaga(),
        transversalViewSaga(),
    ]);
}
