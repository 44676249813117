import { createSelector } from 'reselect';
import { getGlobalOverviewSelectedCountriesSelector } from '../../../data/selectors';
import { getFieldworkTableScopeSelector } from '../../../../pages/surveyFieldwork/subpages/Overview/data/selectors';
import { getAdTesterOverviewSelectedCountrySelector } from '../../../../pages/adTester/subpages/Overview/data/selectors';
import {
    getAppliedSideFiltersSelector,
    getCurrentFilterPageSelector,
    getTopFiltersSelector,
} from '../../SideFilters/data/selectors';
import { getLocationSelector, getCachedLastAppliedTopFiltersOptionSelector } from '../../TopFilter/data/selectors';
import { TOP_FILTERS_OPTION_BE_KEY } from '../../TopFilter/utils/constants';
import { getParentPathOnPagesWithSubTabs } from '../../TopFilter/utils/utils';
import { FILTERS_CONFIG } from '../../../utils/utils';

export const getSurveyFieldworkOverviewExportDataComputed = createSelector(
    [getGlobalOverviewSelectedCountriesSelector, getFieldworkTableScopeSelector],
    (selectedCountries, tableScope) => {
        return {
            exportType: 'excel',
            countries: selectedCountries,
            filters: {
                filtersList: [],
            },
            scope: tableScope,
        };
    }
);

export const getAdTesterOverviewExportDataComputed = createSelector(
    [getAdTesterOverviewSelectedCountrySelector],
    selectedCountries => {
        return {
            exportType: 'excel',
            countries: [selectedCountries],
            filters: {
                filtersList: [],
            },
            scope: '',
        };
    }
);

export const getAdTesterExportDataComputed = createSelector(
    [
        getTopFiltersSelector,
        getCachedLastAppliedTopFiltersOptionSelector,
        getAppliedSideFiltersSelector,
        getLocationSelector,
    ],
    (topFilters, appliedTopOption, appliedSideFilters, currentPage) => {
        let filtersList = [];
        const topFiltersApplied = appliedTopOption[currentPage]?.filter(el =>
            el.group.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[currentPage])
        );
        const currentGroupID = topFilters.find(el =>
            el.label.toLowerCase().includes(TOP_FILTERS_OPTION_BE_KEY[currentPage])
        );
        if (topFiltersApplied) {
            filtersList.push({
                id: currentGroupID?.id,
                list: topFiltersApplied.map(filter => filter.id),
            });
        }

        const sideFiltersApplied = appliedSideFilters.adTester;

        for (const section in sideFiltersApplied) {
            const appliedFiltersForEachSection = sideFiltersApplied[section];
            for (const key in appliedFiltersForEachSection) {
                if (!appliedFiltersForEachSection[key].length) {
                    continue;
                }
                filtersList.push({
                    id: +key,
                    list: appliedFiltersForEachSection[key],
                });
            }
        }
        // if (sideFiltersApplied) {
        //     for (const key in sideFiltersApplied) {
        //         if (key === '4' && sideFiltersApplied['4'].includes(18) && sideFiltersApplied['4'].includes(80)) {
        //             continue;
        //         }
        //         if (!sideFiltersApplied[key].length) {
        //             continue;
        //         }
        //         filtersList.push({
        //             id: +key,
        //             list: sideFiltersApplied[key],
        //         });
        //     }
        //  }

        return {
            exportType: 'excel',
            countries: [],
            filters: {
                filtersList: filtersList,
            },
            scope: '',
        };
    }
);

export const getExportDataBptAndCompetitiveAndTransversal = createSelector(
    [
        getTopFiltersSelector,
        getCachedLastAppliedTopFiltersOptionSelector,
        getLocationSelector,
        getAppliedSideFiltersSelector,
        getCurrentFilterPageSelector,
    ],
    (topFilters, appliedTopOption, location, appliedSideFilters, currentFiltersPage) => {
        const currentLocation = getParentPathOnPagesWithSubTabs(location);
        const filtersList = [];
        const processFilters = eachFilter => {
            const listItem = appliedTopOption[currentLocation]?.find(
                el => el.group.toLowerCase() === eachFilter.group.toLowerCase()
            );
            filtersList.push({
                id: eachFilter.id,
                list: [listItem.id],
            });
        };

        topFilters.forEach(eachFilter => {
            processFilters(eachFilter);
        });

        const sideFiltersApplied = appliedSideFilters[currentFiltersPage];
        const isCurrentPageWithSidefilters = FILTERS_CONFIG[currentLocation]?.requestPageName === currentFiltersPage;
        if (sideFiltersApplied && isCurrentPageWithSidefilters) {
            for (const section in sideFiltersApplied) {
                const appliedFiltersForEachSection = sideFiltersApplied[section];
                for (const key in appliedFiltersForEachSection) {
                    if (!appliedFiltersForEachSection[key].length) {
                        continue;
                    }
                    filtersList.push({
                        id: +key,
                        list: appliedFiltersForEachSection[key],
                    });
                }
            }
        }
        return {
            exportType: 'ppt',
            countries: [],
            filters: {
                filtersList: filtersList,
            },
            scope: '',
        };
    }
);
